import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';


@Pipe({ name: 'shortDateFormatNoUTC' })
export class ShortDateFormatNoUTC implements PipeTransform {
    transform(value: number, args: any[] = []) {
        if(!value)
            return '';
        else
            return  moment(value, 'YYYY-MM-DD').format("DD/MM/YYYY");
    }
}