import { Component, OnInit, Input } from '@angular/core';

@Component({
	selector: 'app-loading-inline',
	templateUrl: './loading-inline.component.html',
	styleUrls: ['./loading-inline.component.css']
})
export class LoadingInlineComponent implements OnInit {
	@Input('msg') msg: string = 'Carregando...';
	loading = true;
	constructor() { }
	
	ngOnInit() {
	}

}
