import { Injectable } from '@angular/core';
import {Observable} from 'rxjs/Rx';
import * as moment from 'moment';
import {ResponseFormat} from '@app/types/response-format';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse  } from '@angular/common/http';


@Injectable()
export class ReportAutenticcoReservationsService {

  constructor(private http : HttpClient) { }

  appendParams(_params): HttpParams{
		let params = new HttpParams();
		Object.keys(_params).map((key, index) => {
			params = params.append(key, _params[key])
		})
		return params;
  }
  

  filterReport(_params){
    return this.http.get<ResponseFormat>('vivakey/rest/report/autenticco/reservation', {
			params: this.appendParams(_params)
		})
  }

  getExcelReport = (_params) => {
		return this.http.get<any>(`vivakey/rest/report/autenticco/reservation`, {
			observe: 'response',
			params: this.appendParams(_params),
		} )
	}

	getCompanyes = () =>
	{
		return this.http.get<ResponseFormat>('vivakey/rest/company/getCompanies');
  	}
}
