import * as moment from "moment";
export const required = (value) => {
  return value && value.length > 0
}
export const validateDate = (value, format = 'DD/MM/YYYYY') =>  {
  return moment(value, format, true).isValid()
}
export const validatePhoneAndCelphone = (value) => {
  value = value.replace(/\D/g, '')
  return value.length === 10 || value.length === 11
}
export const validateCEP = (value) => {
  value = value.replace(/\D/g, '')
  return value.length === 8
}
export const validateCPF = (cpf) =>  {

  cpf = cpf.replace(/\D/g,'');

  if(cpf.length !== 11) {
    return false;
  }

  if (cpf == "00000000000") {
    return false;
  }

  var sum;
  var rest;

  sum = 0;

  for (let i = 1; i <= 9; i++) {
    sum = sum + parseInt(cpf.substring(i-1, i)) * (11 - i);
  }
  
  rest = (sum * 10) % 11;

  if ((rest == 10) || (rest == 11)) {
    rest = 0;
  }

  if (rest != parseInt(cpf.substring(9, 10))) {
    return false;
  }

  sum = 0;

  for (let i = 1; i <= 10; i++) {
    sum = sum + parseInt(cpf.substring(i-1, i)) * (12 - i);
  }
  
  rest = (sum * 10) % 11;

  if ((rest == 10) || (rest == 11)) {
    rest = 0
  }

  if (rest != parseInt(cpf.substring(10, 11))) {
    return false
  }
  return true;
}

export const validateCNPJ = (cnpj) =>  {

  if(!cnpj) 
    return false;

  cnpj = cnpj.replace(/\D/g,'');

  if(cnpj.length !== 14) {
    return false;
  }
  15391652000171

  if (cnpj == "00000000000000" || 
        cnpj == "11111111111111" || 
        cnpj == "22222222222222" || 
        cnpj == "33333333333333" || 
        cnpj == "44444444444444" || 
        cnpj == "55555555555555" || 
        cnpj == "66666666666666" || 
        cnpj == "77777777777777" || 
        cnpj == "88888888888888" || 
        cnpj == "99999999999999") {
        return false;
      }

      // CNPJ sum validation
      let size = cnpj.length - 2
      let numbers = cnpj.substring(0,size);
      let digits = cnpj.substring(size);
      let sum = 0;
      let pos = size - 7;
      for (let i = size; i >= 1; i--) {
        sum += numbers.charAt(size - i) * pos--;
        if (pos < 2)
          pos = 9;
      }
      let result = sum % 11 < 2 ? 0 : 11 - sum % 11;
      if (result != digits.charAt(0))
        return false;

      size = size + 1;
      numbers = cnpj.substring(0,size);
      sum = 0;
      pos = size - 7;
      for (let i = size; i >= 1; i--) {
        sum += numbers.charAt(size - i) * pos--;
        if (pos < 2)
          pos = 9;
      }
      result = sum % 11 < 2 ? 0 : 11 - sum % 11;
      if (result != digits.charAt(1))
        return false;

      return true;
      // end of CNPJ sum validation
}

export const validateEmail = (email) => {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}