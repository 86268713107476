import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from "@angular/core";
import { CheckinFormService } from "./service/checkin-form.service";
import { NotifierService } from "angular-notifier";
import { translateDataToConfirmData } from "../../../utils/translators";
import masks from "../../../utils/masks";
import { validateEmail, validateCPF } from "../../../utils/validators";
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from "@angular/material";
import { AppStateService } from "@src/app/layouts/app-layout.service";

@Component({
  selector: "app-checkin-form",
  templateUrl: "./checkin-form.component.html",
  styleUrls: ["./checkin-form.component.scss"]
})
export class CheckinFormComponent implements OnInit {
  @Input("reservation") reservation: any;

  @Input("addDependentMode")
  addDependentMode: boolean = false;

  @Input("addDependentForm")
  addDependentForm: boolean = false;


  @Output() onFinish: EventEmitter<any> = new EventEmitter();
  @Output() onAddDependentOK: EventEmitter<any> = new EventEmitter();

  currentStep: number = 0;
  currentSubStep: number = 0;

  // Models
  isForeign: boolean;
  document: string;

 
  @Input("mainDependent")
  mainDependent: any;

  dependentIsForeign: boolean;
  dependentDocument: string;
  invoiceId: number;

  currentDependent: any;
  currentEstablishment: any;

  dependents: any = [];

  useMainGuestData: boolean = true;
  invoiceData: any = null;

  magikeyPassword: string = '';

  embratur_data: any;
  autoSendDoc: boolean;

  hasCheckinValidator: boolean;

  // UI/UX
  masks = masks;
  loading: boolean = false;

  @ViewChild('cpfInput') cpfInput;
  @ViewChild('emailInput') emailInput;

  constructor(
    public translate: TranslateService,
    private service: CheckinFormService,
    private notifier: NotifierService,
    public dialog: MatDialog,
    private appStateService: AppStateService
  ) { }

  ngOnInit() {
    if(this.addDependentMode == true) {
      this.addDependentForm = true;
      this.currentStep = 5;//Pular ao passo de adicionar dependendente.
    }
    this.currentEstablishment = this.appStateService.currentEstablishment;
    this.hasCheckinValidator = this.currentEstablishment.establishmentConfig.hasCheckinValidator;
  }

  selectedOption() {
    setTimeout(() => {
      if (this.isForeign === true) {
        this.emailInput.nativeElement.focus();
      } else {
        this.cpfInput.nativeElement.focus();
      }
    }, 500);
  }

  onDocumentKeyUp(event) {
    if (this.isForeign == null) { return; }

    if (event.keyCode == 13) {
      this.goToNextStep();
    }
  }

  goToNextStep() {
    switch (this.currentStep) {
      case 0:
        if (this.document.length > 0) {
          if (this.isForeign) {
            if (!validateEmail(this.document)) {
              this.notifier.notify("error",  this.translate.instant('error_msg.invalid_email'));
              return;
            }
          } else {
            if(!validateCPF(this.document)) {
              this.notifier.notify("error",  this.translate.instant('error_msg.invalid_cpf'));
              return;
            }
          }


          this.loading = true;
          this.service.getMainGuest(this.document).subscribe(
            data => {
              this.loading = false;

              if (data.object) {
                this.currentStep = 2;
                this.mainDependent = translateDataToConfirmData(data.object);
              } else {
                this.currentStep = 1;
              }

              console.log("data", data);
            },
            error => {
              this.loading = false;
              console.log("error", error);
              this.currentStep = 1;
            }
          );
        }
        break;

      case 3:
            {          
                //Perguntar Envio da Nota Fiscal
                this.getInfoAutoSend();
                break;
            }

      default:
        this.currentStep++;
    }
  }

  goToNextSubStep() {
    switch (this.currentSubStep) {
      case 0:
        if (this.dependentDocument.length > 0) {
          if (this.dependentIsForeign) {
            if (!validateEmail(this.dependentDocument)) {
              this.notifier.notify("error", this.translate.instant('error_msg.invalid_email'));
              return;
            }
          }
          this.loading = true;
          this.service
            .getDependent(
              this.mainDependent.mainGuestId,
              this.dependentDocument
            )
            .subscribe(
              data => {
                this.loading = false;

                if (data.object) {
                  this.currentSubStep = 2;
                  this.currentDependent = translateDataToConfirmData(
                    data.object
                  );
                } else {
                  this.currentSubStep = 1;
                }

                console.log("data", data);
              },
              error => {
                this.loading = false;
                console.log("error", error);
                this.currentSubStep = 1;
              }
            );
        }
        break;
      default:
        this.currentSubStep++;
    }
  }


  getInfoAutoSend(){
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn'
      },
      buttonsStyling: false
    })
    
    swalWithBootstrapButtons.fire({
      position: 'top',
      title: this.translate.instant('chekin_form.sendNF_question'),
      text: "",
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: this.translate.instant('chekin_form.sendNF_yes'),
      cancelButtonText: this.translate.instant('chekin_form.sendNF_no'),
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        this.autoSendDoc = true;
        this.currentStep = 4;
      } else if (
        result.dismiss === Swal.DismissReason.cancel
      ) {
        this.autoSendDoc = false;
        this.currentStep = 4;
      }
    })
  }


  onAddMainDependent(data) {
    this.mainDependent = translateDataToConfirmData(data);
    console.log("addedMainDependent");
    this.currentStep = 2;
  }

  onAddDependent(data) {
    this.currentDependent = translateDataToConfirmData(data);
    this.currentSubStep = 2;
  }

  onSaveMainDependent(data) {
    this.mainDependent = data;
  }

  onSaveDependent(data) {
    this.currentDependent = data;
  }

  onSaveInvoiceData(data) {
    this.invoiceData = data;
    this.goToNextStep();
  }

  onSaveNationalData(data) {
    this.embratur_data = data.embratur_data;

    if(this.currentStep === 3)
      this.goToNextStep();
  }

  goInitialStep() {
    this.currentStep = 0;
    this.document = "";
    this.isForeign = null;
  }

  cancelDependentForm() {
    this.currentSubStep = 0;
    this.dependentDocument = "";
    this.dependentIsForeign = null;
    this.addDependentForm = false;
    this.currentDependent = null;
  }

  cancelAddDependent() {
    this.cancelDependentForm();
    this.onAddDependentOK.emit(null);
  }
  
  addCurrentDependent() {
    this.dependents.push(this.currentDependent);

    if(this.addDependentMode == true) {
      
      this.dependents.map(dependent => {
        this.service
          .addDependentToReservation({
            dependentId: dependent.dependentId,
            reservationId: this.reservation.reservationId
          })
          .subscribe(
            data => { 
              if(this.addDependentMode == true) {
                this.onAddDependentOK.emit(data);
              }
            },
            error => {
              this.loading = false;
              console.log("error", error);
            }
          );
      });

    }

    this.cancelDependentForm();
  }

  finish() {
    this.loading = true;

    this.dependents.map(dependent => {
      this.service
        .addDependentToReservation({
          dependentId: dependent.dependentId,
          reservationId: this.reservation.reservationId
        })
        .subscribe(
          data => { 

          },
          error => {
            this.loading = false;
            console.log("error", error);
          }
        );
    });

    const mainGuest = this.mainDependent;

    this.service
      .addMainGuestToReservation({
        mainGuestId: this.mainDependent.mainGuestId,
        reservationId: this.reservation.reservationId
      })
      .subscribe(
        data => {
          let invoiceData = null;

          if (this.useMainGuestData) {
            invoiceData = {
              documentType: mainGuest.documentType,
              documentNumber: mainGuest.documentNumber,
              name: mainGuest.name,
              zipCode: mainGuest.zipcode,
              state: mainGuest.state,
              city: mainGuest.city,
              country: mainGuest.country,
              street: mainGuest.street,
              number: mainGuest.number,
              neighborhood:
                mainGuest.neighborhood && mainGuest.neighborhood.length > 0
                  ? mainGuest.neighborhood
                  : "N/A",
              autoSend: this.autoSendDoc
            };

            if(mainGuest.contribuinteIcms !== null) {
              invoiceData["contribuinteIcms"] = mainGuest.contribuinteIcms;
            }
            if(mainGuest.optanteSimplesNacional !== null) {
              invoiceData["optanteSimplesNacional"] = mainGuest.optanteSimplesNacional;
            }

          } else {
            invoiceData = {
              documentType: this.invoiceData.documentType,
              documentNumber: this.invoiceData.documentNumber,
              name: this.invoiceData.name,
              zipCode: this.invoiceData.zipcode,
              state: this.invoiceData.state,
              city: this.invoiceData.city,
              country: this.invoiceData.country,
              street: this.invoiceData.street,
              number: this.invoiceData.number,
              neighborhood:
                this.invoiceData.complement &&
                  this.invoiceData.complement.length > 0
                  ? this.invoiceData.complement
                  : "N/A",
              autoSend: this.autoSendDoc
            };

            if(this.invoiceData.contribuinteIcms !== null) {
              invoiceData["contribuinteIcms"] = this.invoiceData.contribuinteIcms;
            }
            if(this.invoiceData.optanteSimplesNacional !== null) {
              invoiceData["optanteSimplesNacional"] = this.invoiceData.optanteSimplesNacional;
            }
          }

          this.saveData(invoiceData)

        },
        error => {
          this.loading = false;
          console.log("error", error);
        }
      );
  }

  checkInvoiceToSave(invoiceData) {

    this.service
      .getInvoice(this.reservation.reservationId).subscribe((data: any) => {

        if (data !== null && data.id !== null) {
          this.invoiceId = data.id
          invoiceData.id = data.id;
          console.log(this.translate.instant('chekin_form.already_invoice'));
        }


        this.saveData(invoiceData);

      });
  }


  saveData(invoiceData) {

    this.service
      .addInvoiceDataToReservation(
        this.reservation.reservationId,
        invoiceData
      )
      .subscribe(
        data => {
          this.service
            .postInvite(
              this.mainDependent.mainGuestId,
              this.reservation.reservationId
            )
            .subscribe(
              data => {

                if (data.code === 270) {
                  Swal.fire('Oops...', this.translate.instant('reservation_payment.invite_already_sent'), 'error');
                  this.loading = false;
                  return;
                }

                console.log('data.object', data.object);

                this.magikeyPassword = data.object;

                console.log('magikeyPassword', this.magikeyPassword);


                this.service
                  .setCheckinMobileTrue(
                    this.reservation.reservationId,
                    encodeURIComponent(this.magikeyPassword),
                    this.magikeyPassword ? true : false
                  )
                  .subscribe(
                    data => {
                      this.onFinish.emit();
                      this.loading = false;
                      this.currentStep++;
                    },
                    error => {
                      this.loading = false;
                      console.log("error", error);
                    }
                  );


              },
              error => {
                this.loading = false;
                console.log("error", error);
              }
            );
        },
        error => {
          this.loading = false;
          console.log("error", error);
        }
      );

      
  }





}
