import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { AppStateService, ReloadCompanyEventCmd } from '@app/layouts/app-layout.service';

@Component({
  selector: 'app-dashboard-app',
  templateUrl: './dashboard-app.component.html',
  styleUrls: [
  './dashboard-app.component.scss',
  ]
})
export class DashboardAppComponent implements OnInit {

  constructor(public appStateService: AppStateService,  public route: ActivatedRoute,  private router: Router) {


    // this.router.events.subscribe(event => {

    //   if (event instanceof NavigationStart) {
    //     console.log('NavigationStart', event);
    //   }

    // });


    this.route.params.subscribe(params => {

      var establishmentId = params['establishmentId'];

           
      //console.log('params', params);
      if(establishmentId){
        var cmd = new ReloadCompanyEventCmd();
        cmd.establishmentId = establishmentId;
        this.appStateService.currentEstablishmentId = establishmentId;
        this.appStateService.onSelecEstabishment.emit(cmd);

      }





    });
  }

  ngOnInit() {
  }

}
