import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-phone-input',
  templateUrl: './phone-input.component.html',
  styleUrls: ['./phone-input.component.scss']
})
export class PhoneInputComponent implements OnInit {

  @Input() 
  readOnly: boolean;

  @Input() invalid: boolean;
  @Output() invalidChange = new EventEmitter<boolean>(); 


  @Input()
  label: string;

  @Input() 
  appearance: any;

  @Input() value: string;
  @Output() valueChange = new EventEmitter<string>(); 

  
  updateValue(value){
    // this.invalid= invalid;
    this.invalidChange.emit(this.invalid);
    this.value = value;
    this.valueChange.emit(value);
  }

  updateInvalidChange(event){
    this.invalid = event;
    this.invalidChange.emit(event);
  }
  
  constructor() { }

  ngOnInit() {
  }

}
