import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Rx';
import * as moment from 'moment';
import { ResponseFormat } from '@app/types/response-format';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Http, Response, Headers, RequestOptions } from '@angular/http';


@Injectable()
export class ReportSalesService {

	constructor(private http: HttpClient) { }

	appendParams(_params): HttpParams{
		let params = new HttpParams();
		Object.keys(_params).map((key, index) => {
			params = params.append(key, _params[key])
		})
		return params;
	}

	getCreated(_params) {
		return this.http.get<ResponseFormat>('vivakey/rest/report/reservation/created', {
			params: this.appendParams(_params)
		})
	}

	getReleases(_params) {
		return this.http.get<ResponseFormat>('vivakey/rest/report/releases', {
			params: this.appendParams(_params)
		})
	}

	getCanceled(_params) {
		return this.http.get<ResponseFormat>('vivakey/rest/report/reservation/canceled', {
			params: this.appendParams(_params)
		})
	}

	getExcelReport = (_params) => {
		return this.http.get<any>(`vivakey/rest/report/reservation/dailyReservationReport`, {
			observe: 'response',
			params: this.appendParams(_params),
		} )
	}

	setIsCheckoutReport = (establishmentId, active) => {
		return this.http.put<ResponseFormat>(`vivakey/rest/establishmentConfig/establishment/${establishmentId}/ownerinvoice/${active}`, {});
	}

}
