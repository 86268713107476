import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ResponseFormat } from '@src/app/types/response-format';

@Injectable()
export class CheckinFormService {

  constructor(private http: HttpClient) { }

  getMainGuest = (document) => {
    return this.http.get<ResponseFormat>(`vivakey/rest/mainguest?document=${document}`)
  }

  getDependent = (mainGuestId, _document) => {
    return this.http.get<ResponseFormat>(`vivakey/rest/dependent/${mainGuestId}?document=${_document}`)
  }

  addDependentToReservation(body) {
    return this.http.put<ResponseFormat>(`vivakey/rest/reservation/dependent`, body)
  }

  removeDependentToReservation(reservationId, dependentId) {
    return this.http.delete<ResponseFormat>(`vivakey/rest/reservation/${reservationId}/dependent/${dependentId}`)
  }

  addMainGuestToReservation(body) {
    return this.http.put<ResponseFormat>(`vivakey/rest/reservation/mainGuest`, body)
  }
  addInvoiceDataToReservation(reservationId, body) {
    return this.http.put<ResponseFormat>(`vivakey/rest/reservation/${reservationId}/invoice`, body)
  }

  getInvoice(reservationId) {
    return this.http.get<ResponseFormat>(`vivakey/rest/reservation/${reservationId}/invoice`)
  }


  generateMagikeyPassword(reservationId) {
    return this.http.get<ResponseFormat>(`vivakey/rest/reservation/generateMagikey/${reservationId}`)
  }
  getMagikeyPassword(reservationId) {
    return this.http.get<ResponseFormat>(`vivakey/rest/reservation/getMagikeyPassword/${reservationId}`)
  }

  postInvite(mainGuestId, reservationId) {
    return this.http.post<ResponseFormat>(`vivakey/rest/reservation/${reservationId}/postInvitee/${mainGuestId}`, {})
  }

  setCheckinMobileTrue(reservationId, magikeyPassword, hasMagikeyDevice) {
    if (hasMagikeyDevice === true) {
      return this.http.post<ResponseFormat>(`vivakey/rest/reservation/${reservationId}/setCheckinMobileTrue/` + magikeyPassword, { reservationId: reservationId, magikeyPassword: magikeyPassword })
    }
    else {
      return this.http.post<ResponseFormat>(`vivakey/rest/reservation/${reservationId}/setCheckinMobileTrue`, { })
    }
  }


}
