import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { ResponseFormat } from '@app/types/response-format'
import { AppStateService } from '@src/app/layouts/app-layout.service';

@Injectable({
  providedIn: 'root'
})
export class ConfigAirBnbService {

  constructor(private http: HttpClient, private appStateService: AppStateService,) { }


  getBanks = () => {
    const establishment = this.appStateService.currentEstablishment;
    return this.http.get<ResponseFormat>('vivakey/rest/bank/getBanks/' + establishment.establishmentId);
  }

  getBankPaymentType(establishmentId, bankId, otaType: OtaType, isAutomaticPayment : boolean){
    var paramName = '';
    if(otaType == OtaType.Airbnb) {
      paramName = `&isAirbnbAutomaticPayment=${isAutomaticPayment}`;
    } else if (otaType == OtaType.Booking) {
      paramName = `&isBookingAutomaticPayment=${isAutomaticPayment}`;
    }
    return this.http.get<ResponseFormat>(`vivakey/rest/bankPaymentType/getBankPaymentTypes/${establishmentId}?bankId=${bankId}${paramName}`)
  }

  saveBankPaymentType = (body) => {
    if (body.bankPaymentTypeId != null)
      return this.http.post<ResponseFormat>(`vivakey/rest/bankPaymentType`, body);
    else
      return this.http.post<ResponseFormat>(`vivakey/rest/bankPaymentType`, body);

  }

  activateGateway = (establishmentId, bankId, otaType: OtaType) => {
    var name = '';
    if(otaType == OtaType.Airbnb) {
      name = 'airbnbautopayment'
    } else if (otaType == OtaType.Booking) {
      name = 'bookingautopayment';
    }
    return this.http.put<ResponseFormat>(`vivakey/rest/establishmentConfig/establishment/${establishmentId}/${name}/true/bank/${bankId}`, {});
  }
}

export enum OtaType {
  Airbnb = 0,
  Booking = 1
}
