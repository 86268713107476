import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse  } from '@angular/common/http';
import {ResponseFormat} from '@app/types/response-format';

@Injectable()
export class EstablishmentPolicyService {

  constructor(private http : HttpClient) { }

    getPolitics(establishmentId){
    	return this.http.get<ResponseFormat>(`vivakey/rest/establishment/getPolitics/${establishmentId}`)
    }

    savePolitics(policy, establishmentId){
	    var param = {
			"politics": policy,
			"establishmentId": establishmentId
		}
	    return this.http.put<ResponseFormat>('vivakey/rest/establishment/politics', param);
    }

}
