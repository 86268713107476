import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SelfRegistrateService } from '@pages/self-registrate/self-registrate.service'


@Component({
  selector: 'app-activate-user',
  templateUrl: './activate-user.component.html',
  styleUrls: ['../../components/views/edit-user-form/edit-user-form.component.css',
  '../self-registrate/self-registrate.component.css' ,
  './activate-user.component.css']
})
export class ActivateUserComponent implements OnInit {
  token : string;
  step : any = 0;
  constructor(private route: ActivatedRoute, private selfRegistrateService: SelfRegistrateService) { }

  ngOnInit() {
  	this.route.params.subscribe(params => {
       this.token = params['token'];
       this.selfRegistrateService.activateUser(this.token).subscribe(data => { 
       	console.log(data);
       	switch (data.code) {
  	 			case 200:
          			this.step = 1;
  	 			break;
  	 			default:
  	 			break;
  	 		}
       },  
       (error:any) =>{
        console.log("Error");
    	}) 
    });
  }

}
