import { Component, OnInit, TemplateRef } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { PropertyOwnerService } from './property-owner.service';
import { strLimit } from '@utils/helpers'
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { MatDialog, MatDialogRef } from '@angular/material';
import Swal from 'sweetalert2';
import { AppStateService } from '@src/app/layouts/app-layout.service';

declare var $: any;

@Component({
    selector: 'app-property-owner',
    templateUrl: './property-owner.component.html',
    styleUrls: [
        './property-owner.component.css'
    ]
})

export class PropertyOwnerComponent implements OnInit {
    dataList: any = [];
    filtro: any = {};
    establishment: any;
    selectedItem: any = {};

    loading: boolean = false
    strLimit = strLimit;

    cpfMask : any = [/\d/, /\d/,/\d/,'.',/\d/,/\d/,/\d/,'.',/\d/, /\d/,/\d/, '-',/\d/,/\d/];
    cnpjMask: any = [/\d/, /\d/, ".", /\d/, /\d/, /\d/, ".", /\d/, /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/];


    filterNameDebTime = new Subject<string>();


    constructor(
        private dataService: PropertyOwnerService,
        private notifier: NotifierService,
        public translate: TranslateService,
        public dialog: MatDialog,
        private appStateService: AppStateService,
    ) {
        this.filtro = { name: '', category: 'todos', type: 'todos' };

        this.filterNameDebTime.pipe(
            debounceTime(900),
            distinctUntilChanged())
            .subscribe(value => {
                this.filtro.name = value;
                this.getData();
            });
    }

    async ngOnInit() {
        this.establishment = this.appStateService.currentEstablishment;

        this.getData()
    }


    copyClipboard(inputElement) {
        inputElement.select();
        document.execCommand('copy');
        //inputElement.setSelectionRange(0, 0);
        Swal.fire(this.translate.instant('hotel.token_automatic_copied'), '', 'success');
    }



    getData = () => {
        var filtros = this.filtro;
        console.log("Filtros: ", filtros);

        this.loading = true
        this.dataService.getData(this.establishment.establishmentId).subscribe(data => {
            console.log(data);
            this.loading = false
            switch (data.code) {
                case 200:
                    this.dataList = data.object;
                    break;
                case 219:
                    $("#modalSearchError").modal("show");
                    break;
            }
        }, error => console.log("Error", error));
    }

    addEdit_dialogRef: MatDialogRef<any>;
    openDialogWithTemplateRef(templateRef: TemplateRef<any>) {
       

        this.addEdit_dialogRef = this.dialog.open(templateRef, {
            panelClass: 'mat-dialog-fix',
            disableClose: false,
            data : { }
        });

        //this.createCompanyDialogRef.componentInstance.title = 'Excluír?';

        this.addEdit_dialogRef.afterClosed().subscribe(result => {
            this.addEdit_dialogRef = null;
        });
    }

    addItem(dialogTemplate) {
        this.selectedItem = { propertyOwnerId: 0, establishmentId: this.establishment.establishmentId };
        this.openDialogWithTemplateRef(dialogTemplate);
    }

    editItem(item, dialogTemplate) {
        this.selectedItem = item;
        this.openDialogWithTemplateRef(dialogTemplate);
    }

    authModeChange() {

    }

    saveItem = () => {

        //this.notifier.notify('error', this.translate.instant('xxx'))


        this.loading = true
        this.dataService.saveData(this.selectedItem).subscribe(data => {
            console.log(data);
            switch (data.code) {
                case 201:

                    this.getData();
                    this.addEdit_dialogRef.close();

                    if (this.selectedItem.propertyOwnerId) {
                        this.notifier.notify('success', this.translate.instant('saved'))
                    } else {
                        this.notifier.notify('success', this.translate.instant('created'))
                    }
                    break;

                default:
                    this.notifier.notify('error', this.translate.instant('error_msg.error_undefined'))
                    break;
            }
            this.loading = false;
        }, error => {
            this.loading = false;
        });
    }


    confirmDelete() {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn'
            },
            buttonsStyling: false
        })

        swalWithBootstrapButtons.fire({
            position: 'center',
            title: this.translate.instant('confirm_delete'),
            text: this.translate.instant('occupation.confirm_checkin_checkout_info'),
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: this.translate.instant('buildings.delete_ok'),
            cancelButtonText: this.translate.instant('no'),
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                this.deleteUnit();
            } else if (result.dismiss === Swal.DismissReason.cancel) {

            }
        });
    }


    deleteUnit = () => {
        this.loading = true;
        this.dataService.deleteData(this.selectedItem.propertyOwnerId).subscribe(data => {
            this.loading = false

            console.log(data);
            switch (data.code) {
                case 200:
                    this.getData();
                    this.notifier.notify('success', this.translate.instant('deleted'))
                    this.addEdit_dialogRef.close();
                    break;
                default:
                    this.notifier.notify('error', this.translate.instant('error_msg.error_undefined'))
                    break;
            }

        }, error => {
            this.loading = false
            this.notifier.notify('error', this.translate.instant('error_msg.error_undefined'))
        });
    }


}
