import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { ChoseCompanyService } from "../chose-company/chose-company.service";
import { ReservationsService } from "../reservations/reservations.service";
import { ReportSalesService } from "./report-sales.service";
import moment from "moment-timezone";
import { NotifierService } from 'angular-notifier';
import { AppStateService } from "@src/app/layouts/app-layout.service";
import { TranslateService } from '@ngx-translate/core';

const salesData = require("../../utils/datas/sales-report.json");

@Component({
    selector: "app-report-sales",
    templateUrl: "./report-sales.component.html",
    styleUrls: ["./report-sales.component.css"]
})
export class ReportSalesComponent implements OnInit {

    loading: boolean = true;
    reportData: any = {};
    params = {};
    selectStartDate: any;
    selectFinishDate: any;
    establishment: any;
    reportMode: number = 0;
    step: any = 0;
    showFilters: boolean = false;
    emptyDateWarn: boolean = false;
    loadingData: boolean = false;
    isCheckoutReport: boolean = false;
    selectedEstablishment: any;
    typeReport: any;

    constructor(
        private appStateService: AppStateService,
        private router: Router,
        private notifier: NotifierService,
        private reportFinancialService: ReportSalesService,
        private translate: TranslateService
    ) { }


    ngOnInit() {
        this.establishment = this.appStateService.currentEstablishment;

        //this.selectStartDate = moment(moment().subtract("1", "days").format("YYYY-MM-DD"));
        this.selectStartDate = moment(moment().format("YYYY-MM-DD"));
        this.selectFinishDate = moment(moment().format("YYYY-MM-DD"));
        this.isCheckoutReport = this.isCheckoutReport;
        this.typeReport = "ALL";
    }

    test() {
        console.log("selectStartDate", this.selectStartDate);
    }


    // Download the excel file
    downloadReport() {

        this.loadingData = true

        this.updateParams();

        var reportParams = {
            initDate: this.selectStartDate.format('YYYY-MM-DD'),
            endDate: this.selectFinishDate.format('YYYY-MM-DD'),
            establishmentId: this.establishment.establishmentId,
            isCheckoutReport: this.isCheckoutReport,
            report: true,
            typeReport: this.typeReport
        };

        this.reportFinancialService
            .getExcelReport(reportParams)
            .subscribe(
                resp => {
                    const keys = resp.headers.keys();
                    var headers = keys.map(key => `${key}: ${resp.headers.get(key)}`);
                    console.log("headers: ", headers);
                    var reporturl =  resp.headers.get('reporturl');

                    var message = resp.body.message;
                    if (message) {
                        window.location.href = message.replace('reporturl: ', '');
                    } else {
                        this.notifier.notify('default', 'Houve um problema ao obter o arquivo.')
                    }
                    this.loadingData = false
                },
                error => {
                    this.loadingData = false
                    this.notifier.notify('error', 'Houve um erro interno, tente novamente mais tarde.')
                }
            );

    }

    updateParams() {
        this.params = {
            initDate: this.selectStartDate.format('YYYY-MM-DD'),
            endDate: this.selectFinishDate.format('YYYY-MM-DD'),
            establishmentId: this.establishment.establishmentId,
            isCheckoutReport: this.isCheckoutReport
        };
        // if(this.selectCategory.categoryId !== 0){
        //     this.params["categoryId"] = this.selectCategory.categoryId
        // }
    }

    getTotalValue(data, field = 'price'){
        var retValue: number = 0;
        if(data != null){
            (data as any[]).forEach(element => {
                retValue += Number(element[field]);
            });
        }
        return retValue;
    }
       
    createdReservations_hasOwners: boolean = false;
    releasesReservations_hasOwners: boolean = false;
    canceledReservations_hasOwners: boolean = false;

    createdReservations_buildingName: boolean = false;
    releasesReservations_buildingName: boolean = false;
    canceledReservations_buildingName: boolean = false;


    filterReport = async () => {
        if (!this.selectStartDate || !this.selectFinishDate) {
            // this.emptyDateWarn = true;
        } else {
            this.loadingData = true;
            this.emptyDateWarn = false;

            this.updateParams();

            try {

                var createdReservations_Resp = await this.reportFinancialService.getCreated(this.params).toPromise();

                debugger;
                if (createdReservations_Resp.object) {

                    this.reportData.createdReservations = createdReservations_Resp.object;            
                    this.reportData.createdReservations.forEach(element => {

                        if(element.unitOwner && element.unitOwner.length)
                        {
                            element.unitOwner.forEach(uo => {
                                if(uo.employee && uo.employee.employeeId){
                                    this.createdReservations_hasOwners = true;
                                }
                            });
                        }

                        if(element.buildingName)
                            this.createdReservations_buildingName = true;

                    });
                }


                var releasesReservations_Resp = await this.reportFinancialService.getReleases(this.params).toPromise();
                if (releasesReservations_Resp.object) {
                    this.reportData.releasesReservations = releasesReservations_Resp.object;

                    this.reportData.releasesReservations.forEach(element => {

                        if(element.unitOwner && element.unitOwner.length)
                        {
                            element.unitOwner.forEach(uo => {
                                if(uo.employee && uo.employee.employeeId){
                                    this.releasesReservations_hasOwners = true;
                                }
                            });
                        }

                        if(element.buildingName)
                            this.releasesReservations_buildingName = true;
                            
                    });
                }


                var canceledReservations_Resp = await this.reportFinancialService.getCanceled(this.params).toPromise();
                if (canceledReservations_Resp.object) {
                    this.reportData.canceledReservations = canceledReservations_Resp.object;

                    this.reportData.canceledReservations.forEach(element => {

                        if(element.unitOwner && element.unitOwner.length)
                        {
                            element.unitOwner.forEach(uo => {
                                if(uo.employee && uo.employee.employeeId){
                                    this.canceledReservations_hasOwners = true;
                                }
                            });
                        }

                        if(element.buildingName)
                            this.canceledReservations_buildingName = true;
                        
                    });

                }

                this.loadingData = false;

                
            } catch (error) {
                this.loadingData = false;
            }
            

  
        }
    };

    toggleReportMode = report => {
        this.reportMode = report;
        return this.filterReport();
    };

    closeWarningDate = () => (this.emptyDateWarn = false);
}
