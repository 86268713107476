import { Component, OnInit, TemplateRef } from '@angular/core';
import { MatDialog } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { BuildingsService } from '../buildings/buildings.service';
import { CategoriesService, Category } from './categories.service';
declare var $: any;
import Swal from 'sweetalert2';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AppStateService } from '@src/app/layouts/app-layout.service';


@Component({
    selector: 'app-categories',
    templateUrl: './categories.component.html',
    styleUrls: [
        '../portador/portador.component.css',
        './categories.component.css'
    ]
})

export class CategoriesComponent implements OnInit 
{
    loading: boolean = false;

    categories: any = [];
    buildings: any[] = [];
    types: any = [{}];
    establishment: any;

    selectedItem: Category;
    categoryUnits: any = [];
    filtro: any;

    deleteError: boolean = false;

    filterNameDebTime = new Subject<string>();

    constructor(
        private appStateService: AppStateService,
        private categoriesService: CategoriesService,
        private buildingsService: BuildingsService,
        private translate: TranslateService,
        private dialog: MatDialog,) { 
            this.filtro = { name: '', buildingId: 0 };

            this.filterNameDebTime.pipe(
                debounceTime(900),
                distinctUntilChanged())
                .subscribe(value => {
                    this.filtro.name = value;
                    this.getCategories();
                });
    }

    ngOnInit() 
    {
        this.establishment = this.appStateService.currentEstablishment;
        this.loadAllData();
    }


    async loadAllData(){
        await this.getBuildings().then();
        await this.getCategories().then();
        await this.getTypes().then();
    }

    getBuildings(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.loading = true;
            this.buildingsService.get(this.establishment.establishmentId).subscribe(resp => {
                this.loading = false;
                switch (resp.code) {
                    case 200:
                        {
                            this.buildings = resp.object;
                            resolve(resp.object);
                            break;
                        }
                    default:
                        reject();
                }
            }, err => {
                this.loading = false;
                reject();
            })
        });
    }


    getTypes(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.loading = true;
            this.categoriesService.getTypes().subscribe(resp => {
                this.loading = false;
                switch (resp.code) {
                    case 200:
                        {
                            this.types = resp.object;
                            resolve(resp.object);
                            break;
                        }
                    default:
                        reject();
                }
            }, err => {
                this.loading = false;
                reject();
            })
        });
    }


    getCategories(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.loading = true;
            this.categoriesService.getBuildingCategories(this.establishment.establishmentId, this.filtro).subscribe(resp =>
            {
                this.loading = false;
                switch (resp.code) {
                    case 200:
                        {
                            this.categories = resp.object;
                            resolve(resp.object);
                            break;
                        }
                    default:
                        reject();
                }
            }, err => {
                this.loading = false;
                reject();
            })
        });
    }


    addItem(dialogTemplate) {
        this.selectedItem = new Category();
        this.openDialogWithTemplateRef(dialogTemplate);
    }

    editItem(item, dialogTemplate) {
        this.selectedItem = item;
        this.selectedItem.unitTypeId = item.unitType.unitTypeId;
        this.selectedItem.buildingId = item.building ? item.building.buildingId : null;
        this.selectedItem.cleaningFee = item.cleaningFee ? item.cleaningFee : null;
        this.selectedItem.minimalPrice = item.minimalPrice ? item.minimalPrice : null;


        this.categoriesService.getUnits(this.establishment.establishmentId, this.selectedItem.categoryId).subscribe(data => 
            {
                console.log(data);
                switch (data.code) 
                {
                    case 200:
                        this.categoryUnits = data.object;
                        break;
                    default: 
                        this.categoryUnits = [];
                        break;
                }
            }, error => console.log("Error", error));

        this.openDialogWithTemplateRef(dialogTemplate);
    }

    openDialogWithTemplateRef(templateRef: TemplateRef<any>) {
        this.dialog.open(templateRef);
    }


    save(){
        if(this.selectedItem && this.selectedItem.categoryId){
            this.postEditCategory();
        } else {
            this.saveCategory();
        }
    }

    saveCategory = () =>
    {
        const body =  {
            establishmentId: this.establishment.establishmentId,
            unitTypeId:  this.selectedItem.unitTypeId,
            name: this.selectedItem.name,
            description: this.selectedItem.description,
            peopleQuantity : this.selectedItem.peopleQuantity,
            cleaningFee: this.selectedItem.cleaningFee,
            minimalPrice: this.selectedItem.minimalPrice,
            buildingId: this.selectedItem.buildingId
        }

        console.log('about to save', body);
        
        this.categoriesService.postCategory(body).subscribe(data => 
        {
            console.log(data);
            switch (data.code) 
            {
                case 200:
                    console.log('inserted');
                    this.getCategories();
                    this.dialog.closeAll();
                    break;
            }
        }, error => console.log("Error", error));
    }

    

    confirmDelete(){
        const swalWithBootstrapButtons = Swal.mixin({
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn'
          },
          buttonsStyling: false
        })
      
        swalWithBootstrapButtons.fire({
          position: 'center',
          title: this.translate.instant('categories.confirm_delete'),
          text: this.translate.instant('occupation.confirm_checkin_checkout_info'),
          icon: 'question',
          showCancelButton: true,
          confirmButtonText: this.translate.instant('buildings.delete_ok'),
          cancelButtonText: this.translate.instant('no'),
          reverseButtons: true
        }).then((result) => {
          if (result.value) {
            this.deleteCategory();
          } else if (result.dismiss === Swal.DismissReason.cancel) {
      
          }
        });
      }

    deleteCategory = () => 
    {
        this.categoriesService.deleteCategory(this.selectedItem.categoryId).subscribe(data => 
        {
            console.log(data);
        
            switch (data.code) 
            {
            case 200:
                console.log('deleted');

                setTimeout(function () {
                    $('#modalCategoryDeleteSuccess').modal('show')
                }, 500);

                this.getCategories();
                this.dialog.closeAll();

            break;
            case 246:
                console.log('not deleted');

                setTimeout(function () {
                    $('#modalCategoryDeleteError').modal('show')
                }, 500);

                this.getCategories();
                this.dialog.closeAll();

                break;
            default: 
            break;
            }
        },  
        (error:any) => console.log("Error"));
    }

  postEditCategory(){
    var body =  {
      "categoryId" : this.selectedItem.categoryId,
      "establishmentId": this.establishment.establishmentId,
      "unitTypeId":  this.selectedItem.unitTypeId,
      "name": this.selectedItem.name,
      "description": this.selectedItem.description,
      "peopleQuantity" : this.selectedItem.peopleQuantity,
      "cleaningFee" : this.selectedItem.cleaningFee,
      "minimalPrice" : this.selectedItem.minimalPrice,
      "buildingId": this.selectedItem.buildingId
    }
    console.log('about to save', body);
    this.categoriesService.postCategory(body).subscribe(data => {
      console.log(data);
      switch (data.code) {
        case 200:
          console.log('edited');
          this.getCategories();
          this.dialog.closeAll();
        break;
        default: 
        break;
     }
     },  
    (error:any) =>{
    console.log("Error");
    });
  }

  filtrarCategorias(){
      this.getCategories();
  }
}
