import { Component, Input, OnInit } from '@angular/core';
import { CreateHotelService } from '@src/app/pages/create-hotel/create-hotel.service';
import { NotifierService } from 'angular-notifier';
import { validateCNPJ } from '../../../utils/validators'
import { MatDialogRef } from '@angular/material';

import { removeChars } from '@src/app/utils/helpers';
import { TranslateService } from '@ngx-translate/core';
import { ConfigAirBnbService, OtaType } from './config-AirBnb.service';
import { AppStateService } from '@src/app/layouts/app-layout.service';


@Component({
  selector: 'app-config-airbnb-modal',
  templateUrl: './config-airbnb-modal.component.html',
  styleUrls: ['./config-airbnb-modal.component.scss']
})
export class ConfigureAirBnbModalComponent implements OnInit {

  loading: boolean = true;
  bankList: any;
  selectedBank: any;
  selectedEstablishment: any;
  bankPaymentType_list = [];
  hasSavePaymentGateway = false;

  @Input() otaType: OtaType = OtaType.Airbnb;

  otaTitle = '';

  constructor(
    private appStateService: AppStateService,
    private service: ConfigAirBnbService,
    private notifier: NotifierService,
    public dialogRef: MatDialogRef<ConfigureAirBnbModalComponent>,
    private translate: TranslateService,) { }

  ngOnInit() {
    this.selectedEstablishment = this.appStateService.currentEstablishment;
    this.getBanks();

    switch (this.otaType) {
      case OtaType.Airbnb:
        this.otaTitle = 'Airbnb';
        break;
      case OtaType.Booking:
        this.otaTitle = 'Booking';
        break;
    }

  }


  initializePaymentTypes() {
    this.bankPaymentType_list = [];

    var typeDesc = '';
    switch (this.otaType) {
      case OtaType.Airbnb:
        typeDesc = 'Airbnb';
        break;
      case OtaType.Booking:
        typeDesc = 'Booking';
        break;
    }

    this.bankPaymentType_list.push({ bankPaymentType: null, paymentTypeId: 1, name: `Deposito - ${typeDesc}`, type: typeDesc });

  }


  configureItem(bankPayment) {
    bankPayment.bankPaymentType = {
      bankPaymentTypeId: null,
      bankId: this.selectedBank.bankId,
      paymentTypeId: bankPayment.paymentTypeId,
      name: bankPayment.name,
      brand: 'NONE',
      cardTax: 0,
      cardTax2to6: 0,
      cardTax7to12: 0,
      status: "1",
      cashFlow: '0',
      isPaymentGateway: false,
      isAirbnbAutomaticPayment: this.otaType == OtaType.Airbnb,
      isBookingAutomaticPayment: this.otaType == OtaType.Booking
    };
    setTimeout(() => {
      bankPayment.postPending = true;
      this.saveBankPaymentType(bankPayment.bankPaymentType);
    }, 50);
  }

  editBankPaymentType(bankPayment) {
    if (bankPayment.bankPaymentType != null && bankPayment.bankPaymentType.bankPaymentTypeId != null) {
      setTimeout(() => {
        bankPayment.postPending = true;
        console.log('bankPayment', bankPayment);
      },
        50);
    }
  }

  getBanks() {
    console.log("get banks");
    this.loading = true;
    this.service.getBanks().subscribe(data => {
      this.loading = false
      if (data.object) {
        this.bankList = data.object;
      }
      this.loading = false
    }, error => {
      this.loading = false
      this.notifier.notify('error', this.translate.instant('error_msg.error_getdata'))
    });
  }


  onSelectBank(event) {
    this.initializePaymentTypes();
    this.loading = true;
    this.service.getBankPaymentType(this.selectedEstablishment.establishmentId, event.bankId, this.otaType, true).subscribe(data => {
      this.loading = false
      if (data.object) {
        console.log(data.object)
        data.object.forEach(item => {

          var statusFlag: boolean = null;

          switch (this.otaType) {
              case OtaType.Airbnb:
                  statusFlag = item.isAirbnbAutomaticPayment;
                  break;
              
              case OtaType.Booking:
                  statusFlag = item.isBookingAutomaticPayment;
                  break;
          
              default:
                  break;
          }

          if (statusFlag === true) {
            var pType = this.bankPaymentType_list.find(i => i.paymentTypeId == item.paymentType.paymentTypeId);
            if (pType != null) {
              pType.bankPaymentType = item;
            }
          }
        });

        var total_configured = this.bankPaymentType_list.filter(i => i.bankPaymentType != null).length;
        this.hasSavePaymentGateway = total_configured >= this.bankPaymentType_list.length ? true : false;
      }
      this.loading = false
    }, error => {
      this.loading = false
      this.notifier.notify('error', this.translate.instant('error_msg.error_getdata'))
      console.log("Error", error)
    });
  }

  saveBankPaymentType(item) {
    var bankPaymentType = item;

    if (bankPaymentType.bankPaymentTypeId != null) {
      bankPaymentType = {
        bankPaymentTypeId: bankPaymentType.bankPaymentTypeId,
        bankId: bankPaymentType.bank.bankId,
        paymentTypeId: bankPaymentType.paymentType.paymentTypeId,
        name: bankPaymentType.name,
        brand: bankPaymentType.brand,
        cardTax: bankPaymentType.cardTax,
        cardTax2to6: bankPaymentType.cardTax2to6,
        cardTax7to12: bankPaymentType.cardTax7to12,
        status: bankPaymentType.status == 'ACTIVE' ? '1' : '0',
        cashFlow: bankPaymentType.cashFlow == 'YES' ? '1' : '0',
        isPaymentGateway: false,
        isAirbnbAutomaticPayment: this.otaType == OtaType.Airbnb,
        isBookingAutomaticPayment: this.otaType == OtaType.Booking
      }
    }

    this.loading = true;
    this.service.saveBankPaymentType(bankPaymentType).subscribe((data) => {
      this.loading = false
      this.onSelectBank(this.selectedBank);
    }, error => {
      this.loading = false
      this.notifier.notify('error', this.translate.instant('payment-gateway.error_save_payment_type'))
    });
  }

  activateGateway() {
    this.loading = true
    this.service.activateGateway(this.selectedEstablishment.establishmentId, this.selectedBank.bankId, this.otaType).subscribe((data) => {
      this.notifier.notify('success', this.translate.instant('payment-gateway.activated_gateway'));
      this.loading = false
      this.dialogRef.close(true);
    }, error => {
      this.loading = false
      this.notifier.notify('error', this.translate.instant('payment-gateway.error_activate_gateway'))
    });
  }

}
