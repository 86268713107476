import { Injectable } from '@angular/core';
import {Observable} from 'rxjs/Rx';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse  } from '@angular/common/http';
import {ResponseFormat} from '@app/types/response-format';

// Import RxJs required methods
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';

@Injectable()
export class DashboardMenuService 
{
	menuItems: any

    constructor(private http : HttpClient) { }

    getCategories = (establishmentId) => this.http.get<ResponseFormat>(`vivakey/rest/employee/getMenu/${establishmentId}`)
}   
