import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { ChoseCompanyService } from "../chose-company/chose-company.service";
import { ReservationsService } from "../reservations/reservations.service";
import { ReportFinancialService } from "./report-financial.service";
import moment from "moment-timezone";
import { NotifierService } from 'angular-notifier';
import { TranslateService } from '@ngx-translate/core';
import { GroupByPipe } from '@src/app/pipes/group-itens-pipe';
import { MatDialogRef, MatDialog } from '@angular/material';
import { ReportFinancialDetailsModalComponent } from '@src/app/components/views/report-financial-details-modal/report-financial-details-modal.component';
declare var $: any;

import { translateOrigin, translatePaymentType } from '@utils/helpers'
import { timeInterval, timeout } from "rxjs/operators";
import { AppStateService } from "@src/app/layouts/app-layout.service";


@Component({
    selector: "app-report-financial",
    templateUrl: "./report-financial.component.html",
    styleUrls: ["./report-financial.component.css"]
})
export class ReportFinancialComponent implements OnInit {
    categories: any = [
        {
            name: this.translate.instant('all'),
            categoryId: 0
        }
    ];
    units: any = [
        {
            name: this.translate.instant('all'),
            unitId: 0
        }
    ];
    financialDataTypes: any = [];
    financialConciliated : any = [] ;

    selectCategory: any;
    selectUnit: any;

    cashData: any[] = [];
    repData: GroupFinRpt[] = [];

    params = {};
    selectStartDate: any;
    selectFinishDate: any;
    selectDataType: any;
    selectConciliation: any;
    
    establishment: any;
    reportMode: number = 0;
    step: any = 0;
    showFilters: boolean = false;
    emptyDateWarn: boolean = false;
    loadingData: boolean = false;
    displayedColumns = ["date", "client", "cat_uni", "tipo_pagamento", "valor"];

    totalGrossValue: number = 0;
    totalNetValue: number = 0;

    selectedPayments: any[] = [];
    selectedGroup: GroupFinRpt;



    
    reportFinancialDetailsModalComponent: MatDialogRef<ReportFinancialDetailsModalComponent>;

    constructor(
        private appStateService: AppStateService,
        private router: Router,
        private notifier: NotifierService,
        private choseCompanyService: ChoseCompanyService,
        private reservationsService: ReservationsService,
        private reportFinancialService: ReportFinancialService,
        private translate: TranslateService,
        public dialog: MatDialog
    ) {
        //this.selectDataType = this.financialDataTypes[0];

        setTimeout(() => {

            this.financialDataTypes = [
                {
                    type: "paymentDate",
                    name: this.translate.instant('report-financial.paymentDate'),
                },
                {
                    type: "effectivePaymentDate",
                    name: this.translate.instant('report-financial.effectivePaymentDate')
                }
            ];
            
            this.financialConciliated = [
                {
                    value: null,
                    name: this.translate.instant('all')
                },
                {
                    value: true,
                    name: this.translate.instant('report-financial.conciliated'),
                },
                {
                    value: false,
                    name: this.translate.instant('report-financial.not_conciliated')
                }
            ];

        }, 200);

        
    }

    ngOnInit() {

        setTimeout(() => {

            this.selectDataType = this.financialDataTypes[0];
            this.selectConciliation = this.financialConciliated[0];
            this.selectCategory = this.categories[0];
            this.selectUnit = this.units[0];

            this.establishment = this.appStateService.currentEstablishment;

            this.generateFilter();
            this.selectStartDate = moment(moment().subtract("1", "months").format("YYYY-MM-DD"));
            this.selectFinishDate = moment(moment().format("YYYY-MM-DD"));
            this.filterReport();
            
        }, 500);
        
       
    }

    test() {
        console.log("selectStartDate", this.selectStartDate);
    }


    // Download the excel file
	downloadReport() {

		this.loadingData = true

		this.updateParams();

		this.reportFinancialService
			.getExcelReport(this.establishment.establishmentId,  moment(this.selectStartDate).format(), moment(this.selectFinishDate).format(), 0, this.params)
			.subscribe(
				data => {
					console.log("Download link", data);
					if(data.code === 200 && data.object) {
						window.location.href = data.object 					
					} else {
						this.notifier.notify('error', this.translate.instant('error_msg.error_getdata' ))
					}
					this.loadingData = false
				},
				error => {
					this.loadingData = false
					this.notifier.notify('error', this.translate.instant('error_msg.error_getdata' ))
				}
            );
            
        this.filterReport();
	}

    generateFilter = () => {
        this.reservationsService
            .getCategories(this.establishment.establishmentId)
            .subscribe(
                data => {
                    switch (data.code) {
                        case 200:
                            this.categories = [
                                {
                                    name: this.translate.instant('all'),
                                    categoryId: 0
                                }
                            ];
                            for (let i = data.object.length - 1; i >= 0; i--) {
                                this.categories.push(data.object[i]);
                            }
                            this.selectCategory = this.categories[0];
                            this.step = 2;
                            break;
                        case 218:
                            console.log("nao possui categorias");
                            break;
                        default:
                            break;
                    }
                },
                error => console.log("Error")
            );

        this.reservationsService
            .getUnits(
                null,
                this.establishment.establishmentId,
                this.selectCategory.categoryId
            )
            .subscribe(
                data => {
                    switch (data.code) {
                        case 200:
                            this.units = [
                                {
                                    name: this.translate.instant('all'),
                                    unitId: 0
                                }
                            ];
                            for (let i = data.object.length - 1; i >= 0; i--) {
                                this.units.push(data.object[i]);
                            }
                            this.selectUnit = this.units[0];
                            this.step = 2;
                            break;
                        case 218:
                            console.log("nao possui categorias");
                            break;
                        default:
                            break;
                    }
                },
                error => console.log("Error")
            );
    };

    changeCategorySelect = () => {
        this.reservationsService
            .getUnits(
                null,
                this.establishment.establishmentId,
                this.selectCategory.categoryId
            )
            .subscribe(
                data => {
                    switch (data.code) {
                        case 200:
                            this.units = [
                                {
                                    name: this.translate.instant('all'),
                                    unitId: 0
                                }
                            ];
                            for (let i = data.object.length - 1; i >= 0; i--) {
                                this.units.push(data.object[i]);
                            }
                            this.selectUnit = this.units[0];
                            this.step = 2;
                            break;
                        case 218:
                            console.log("nao possui categorias");
                            break;
                        default:
                            break;
                    }
                },
                error => console.log("Error")
            );

            this.filterReport();
    };

    updateParams(){
            this.params = {};
            if(this.selectCategory.categoryId !== 0){
                this.params["categoryId"] = this.selectCategory.categoryId;
            }

            if(this.selectUnit.unitId !== 0){
                this.params["unitId"] = this.selectUnit.unitId;
            }

            if(this.selectDataType){
                this.params["dateType"] = this.selectDataType.type;
            }

            if(this.selectConciliation && this.selectConciliation.value != null){
                this.params["conciliated"] = this.selectConciliation.value;
            }


            console.log('this.selectDataType', this.selectDataType);
    }

    filterReport = () => {
        if (!this.selectStartDate || !this.selectFinishDate) {
            // this.emptyDateWarn = true;
        } else {
            this.loadingData = true;
            this.emptyDateWarn = false;

            this.totalGrossValue = 0;
            this.totalNetValue = 0;

            this.updateParams();

            this.reportFinancialService
                .filterReport(
                    this.establishment.establishmentId,
                    this.selectStartDate.format('YYYY-MM-DD'),
                    this.selectFinishDate.format('YYYY-MM-DD'),
                    this.reportMode, 
                    this.params
                )
                .subscribe(
                    data => {
                        console.log(data);
                        switch (data.code) {
                            case 200:
                                this.cashData = [];
                                this.repData = [];
                                this.showFilters = true;
                                let auxData;
                                if (data.object.cashFlow) {
                                    for (
                                        let i = 0;
                                        i < data.object.cashFlow.length;
                                        i++
                                    ) {
                                        auxData = data.object.cashFlow[i].paymentDate
                                            .split("T")[0]
                                            .split("-");
                                        data.object.cashFlow[i].dateFormated =
                                            auxData[2] +
                                            "/" +
                                            auxData[1] +
                                            "/" +
                                            auxData[0];

                                      
                                        this.totalGrossValue += (data.object.cashFlow[i].grossValue);
                                        this.totalNetValue += (data.object.cashFlow[i].netValue);

                                    }
                                    this.cashData = data.object.cashFlow;
                                }
                                if (data.object.cashFlowProvisions) {
                                    for (
                                        let i = 0;
                                        i <
                                        data.object.cashFlowProvisions.length;
                                        i++
                                    ) {
                                        auxData = data.object.cashFlowProvisions[
                                            i
                                        ].date
                                            .split("T")[0]
                                            .split("-");
                                        data.object.cashFlowProvisions[
                                            i
                                        ].dateFormated =
                                            auxData[2] +
                                            "/" +
                                            auxData[1] +
                                            "/" +
                                            auxData[0];
                                    }
                                    this.cashData =
                                        data.object.cashFlowProvisions;
                                }

                                var itensGrouped: GroupFinRpt[] = [];
                                this.cashData.forEach(element => {
                                    var gname = element.groupPaymentId.toString() + '-' + element.paymentDate.toString() ;
                                    var actualGroup = itensGrouped.find(i=>i.gname == gname);
                                    if(actualGroup){
                                        actualGroup.grossValue += element.grossValue;
                                        actualGroup.netValue += element.netValue;  
                                        actualGroup.payments.push(element);
                                    }
                                    else {
                                        actualGroup = new GroupFinRpt();
                                        actualGroup.gname = gname;
                                        actualGroup.payments = [];
                                        actualGroup.parcelNumber = element.parcelNumber;
                                        actualGroup.parcelTotalNumber = element.parcelTotalNumber;
                                        actualGroup.grossValue = 0;
                                        actualGroup.grossValue += element.grossValue;
                                        actualGroup.netValue = 0;  
                                        actualGroup.netValue += element.netValue;
                                        actualGroup.groupPaymentId = element.groupPaymentId;
                                        actualGroup.paymentDate = element.paymentDate;
                                        actualGroup.releaseDate = element.releaseDate;
                                        actualGroup.paymentType = element.paymentType;
                                        actualGroup.brand = (element.brand !== 'NONE' ? element.brand : null);
                                        actualGroup.authorizationId = element.authorizationId;
                                        actualGroup.bank = element.bank;
                                        actualGroup.groupPaymentType = translatePaymentType(element.groupPaymentType);
                                        actualGroup.salesChannel = translateOrigin(element.salesChannel);
                                        actualGroup.groupPaymentStatus = element.groupPaymentStatus;
                                        
                                        if(element.receivable){
                                            actualGroup.receivableId = element.receivable.receivableId;
                                            actualGroup.effectiveValue = element.receivable.effectiveValue;
                                            actualGroup.effectivePaymentDate = element.receivable.effectivePaymentDate;
                                            actualGroup.receivableHasModified = element.receivable.hasModified;
                                            actualGroup.receivableToken = element.receivable.receivableToken;
                                        }

                                        actualGroup.payments.push(element);
                                        itensGrouped.push(actualGroup);
                                    }
                                });
                                

                                this.repData = itensGrouped;
                                console.log("this.repData", this.repData);


                                break;
                            case 218:
                                console.log("nao possui categorias");
                                break;
                            default:
                                break;
                        }
                        this.loadingData = false;
                    },
                    error => {
                        console.log("Error");
                        this.notifier.notify('error', this.translate.instant('error_msg.error_getdata' ))
                        this.loadingData = false;
                    }
                );
        }
    };


   
    getReservationFullPaymentInfo = (group) => {
        this.selectedPayments = group.payments;
        this.selectedGroup = group;
        
        
        this.reportFinancialDetailsModalComponent = this.dialog.open(ReportFinancialDetailsModalComponent, {
            //panelClass: 'mat-dialog-large',
            disableClose: false,
            data : { group: group }
        });


        this.reportFinancialDetailsModalComponent.afterClosed().subscribe(result => {
            if (this.reportFinancialDetailsModalComponent.componentInstance.resultDialog === 'reverse'
            || this.reportFinancialDetailsModalComponent.componentInstance.resultDialog === 'conciliated') {
                //this.reversalReason = '';
                //$('#reversalModal').modal('show');
                this.filterReport();
            }
            this.reportFinancialDetailsModalComponent = null;
        });

    }


    clearCategoryFilter = () => {
        this.selectCategory = this.categories[0];
        return this.filterReport();
    };

    clearUnitFilter = () => {
        this.selectUnit = this.units[0];
        return this.filterReport();
    };

    toggleReportMode = report => {
        this.reportMode = report;
        return this.filterReport();
    };

    closeWarningDate = () => (this.emptyDateWarn = false);
}


export class GroupFinRpt {
    gname = '';
    groupPaymentId;
    releaseDate;
    paymentDate;
    paymentType;
    brand;
    grossValue: number = 0;
    netValue: number = 0;
    parcelNumber;
    parcelTotalNumber;
    authorizationId;
    bank;
    groupPaymentType;
    groupPaymentStatus;
    salesChannel;
    payments: any[] = [];

    effectivePaymentDate:any;
    effectiveValue:any = 0;
    receivableId: any;
    receivableHasModified: any
    receivableToken: string
}