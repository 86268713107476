import { Component, OnInit, TemplateRef } from '@angular/core';
import { UsersService } from './users.service';
import { EmailValidator } from '@tools/Validator';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
declare var $ :any;
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material';
import { AppStateService } from '@src/app/layouts/app-layout.service';
import { NotifierService } from 'angular-notifier';

@Component({
    selector: 'app-users',
    templateUrl: './users.component.html',
    styleUrls: [
        './users.component.css'
    ]
})

export class UsersComponent implements OnInit 
{
    filtro : any = {};
    jobsList : any = [];
    jobsListWithUserJobs : any = [];
    usersList : any = [];
    selectedUser : any = {};
    cpfMask : any = [/\d/, /\d/,/\d/,'.',/\d/,/\d/,/\d/,'.',/\d/, /\d/,/\d/, '-',/\d/,/\d/];
    userCpf : any = '';
    selectedEstablishment : any = {};
    addUserDetailsModel : any = {};
    validEmail: boolean = true;
    cpfNotFound : boolean = false;
    feedback : any = {};
    loading: boolean = true;

    mode = 'owners'; // owners  |  users
    filterNameDebTime = new Subject<string>();

    constructor(private usersService: UsersService,
        private translate: TranslateService,
        private dialog: MatDialog,
        private appStateService: AppStateService,
        private notifier: NotifierService) { }

    ngOnInit() 
    {
        this.getUsers(null);
        this.getJobs();
        this.feedback.hasFeedBack = false;
        this.selectedEstablishment = this.appStateService.currentEstablishment;
        this.filtro.job = 'todos';

        this.filterNameDebTime.pipe(
            debounceTime(900),
            distinctUntilChanged())
            .subscribe(value => {
                this.filtro.name = value;
                this.getUsers(this.filtro);
            });
    }

    getUsers = filtro =>
    {
        this.loading = true
        this.usersService.getUsers(filtro).subscribe(data => 
        {
            console.log(data);
            switch (data.code) 
            {
                case 200:
                    this.usersList = data.object;
                    break;
            }
            this.loading = false
        }, error => {
            this.loading = false
            console.log("Error", error)
        });
    }

    getJobs = () =>
    {
        this.usersService.getJobs().subscribe(data => 
        {
            switch (data.code) 
            {
                case 200:
                    data.object.forEach(element => {
                        if(element.name.indexOf('Propriet') >= 0) {
                            element.name = 'Proprietário';
                        }
                    });
                    this.jobsList = data.object;
                    this.jobsListWithUserJobs = data.object; 
                    break;
            }
        }, error => console.log("Error", error));
    }


    editItem(user, dialogTemplate) {
        this.selectedUser = user;

        this.mode = '';

        user.establishmentJobTitles.forEach(element => {
            
            if(element.name == 'Proprietário') {
                this.mode = 'owners';
                console.log( 'IsOwners');
            }

            this.jobsListWithUserJobs.forEach(job => {
                
                //limpa para garantir que estamos no usuario selecionado
                job.userHasJob = false;
                if (job.establishmentJobTitleId ==  element.establishmentJobTitleId)
                {  
                    job.userHasJob = true;
                }

            });

        });
        
        


        this.openDialogWithTemplateRef(dialogTemplate);
    }

    openDialogWithTemplateRef(templateRef: TemplateRef<any>) {
        this.dialog.open(templateRef);
    }

    closeUserInfoBox =  () =>
    {
        this.selectedUser = {};
        this.feedback.hasFeedBack = false;
    }

    openAddUser = (addOwners) =>
    {
        this.userCpf = '';
        this.cpfNotFound = false;
        this.mode = addOwners == true ? 'owners' : 'users';
        $('#addUserModal').modal('show'); 
    }

    addNewUserDetails = () =>
    {
        this.addUserDetailsModel = {};
        $('#addUserModal').modal('hide')
        setTimeout(() => $('#addUserDetailsModal').modal('show'), 500);
    }

    selectJobId = job =>
    {
        if (job == this.addUserDetailsModel.job)
            this.addUserDetailsModel.job = {};
        else 
            this.addUserDetailsModel.job = job;
    }

    sendNewUser = () =>
    {
        if (!this.addUserDetailsModel.name || !this.addUserDetailsModel.cpf || !this.addUserDetailsModel.email)
            return console.log('missing fields');

        // Verifica se já existe esse funcionario
        this.loading = true
        this.usersService.searchByCpf(this.addUserDetailsModel.cpf).subscribe(data => 
        {
            console.log(data);
            switch (data.code) 
            {
                case 200:
                    const body = {
                        username: this.addUserDetailsModel.cpf,
                        email: this.addUserDetailsModel.email,
                        name: this.addUserDetailsModel.name,
                        establishmentJobTitleId:  this.addUserDetailsModel.job.establishmentJobTitleId
                    } as any;
                    
                    // Se ja existe, ele adiciona no body o employee =Id
                    if (data.object.length > 0)
                        body.employeeId = data.object[0].employeeId;

                    this.usersService.sendNewUser(body).subscribe(data =>
                    {
                        console.log(data);
                        switch (data.code) 
                        {
                            case 200:
                                this.getUsers(null);
                                $('#addUserDetailsModal').modal('hide')
                                setTimeout(() => $('#modalCadastroSucesso').modal('show'), 500);
                                break;
                            default:
                                $('#addUserDetailsModal').modal('hide')
                                setTimeout(() => $('#modalCadastroError').modal('show'), 500);
                                break;
                        }
                        this.loading = false
                    }, error => {
                            this.loading = false
                            var body = JSON.parse(error._body);
                            if(body && body.message) 
                            {
                                this.notifier.notify('error', body.message);
                            }   
                        });
                    break;
                    default:
                    this.loading = false
            }
        }, error => {
            this.loading = false
            console.log("Error", error)
        });
    }
    
    onChangeEmail = () => this.validEmail = EmailValidator(this.addUserDetailsModel.email);

    changeUserJobId = job =>
    {
        this.selectedUser.job = job;
        
        for (let j = this.jobsListWithUserJobs.length - 1; j >= 0; j--) 
        {
            //limpa para garantir que estamos no usuario selecionado
            this.jobsListWithUserJobs[j].userHasJob = false;
            if (this.jobsListWithUserJobs[j].establishmentJobTitleId ==  job.establishmentJobTitleId)
            this.jobsListWithUserJobs[j].userHasJob = true;
        }
    }

    updateUser = () =>
    {
        if (this.selectedUser.job) 
        {
            const payload = {
                employeeId: this.selectedUser.employeeId,
                username: this.selectedUser.username,
                email: this.selectedUser.email,
                name: this.selectedUser.name,
                establishmentJobTitleId:  this.selectedUser.job.establishmentJobTitleId
            };

            this.loading = true

            this.usersService.udateUser(payload).subscribe(data => 
            {
                switch (data.code) 
                {
                    case 200:
                        console.log(data);
                        this.feedback.hasFeedBack = true;
                        this.feedback.type = 'alterar_cargo';
                        this.dialog.closeAll();
                        this.getUsers(null);
                        break;
                }
                this.loading = false
            },  
            error => {
                this.loading = false
                console.log("Error")
            });
        }
        else console.log('missing fields');
    }

    confirmUserDelete = () =>
    {
        this.feedback.hasFeedBack = true;
        this.feedback.type = 'confirm_delete';
    }

    
    confirmDelete(){
        const swalWithBootstrapButtons = Swal.mixin({
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn'
          },
          buttonsStyling: false
        })
      
        swalWithBootstrapButtons.fire({
          position: 'center',
          title: this.translate.instant('users.confirm_delete'),
          text: this.translate.instant('occupation.confirm_checkin_checkout_info'),
          icon: 'question',
          showCancelButton: true,
          confirmButtonText: this.translate.instant('buildings.delete_ok'),
          cancelButtonText: this.translate.instant('no'),
          reverseButtons: true
        }).then((result) => {
          if (result.value) {
            this.deleteUser();
          } else if (result.dismiss === Swal.DismissReason.cancel) {
      
          }
        });
      }

    deleteUser = () =>
    {
        this.loading = true
        this.usersService.deleteUser(this.selectedUser).subscribe(data => 
        {
            switch (data.code) 
            {
                case 200:
                    this.getUsers(null);
                    this.dialog.closeAll();
                    this.feedback.type = 'delete_success';
                    break;
            }
            this.loading = false
        }, error => {
            this.loading = false
            console.log("Error", error)
        });
    }

    searchByCpf = (callback?: () => void) =>
    {
        if (!(this.userCpf.length > 10 && this.userCpf.slice(-1) != '_'))
            return 
        
        console.log(this.userCpf);
        this.loading = true
        this.usersService.searchByCpf(this.userCpf).subscribe(data => 
        {
            console.log(data);
            switch (data.code) 
            {
                case 200:
                    if (data.object.length == 0)
                        this.cpfNotFound = true;
                    else 
                    {
                        data.object[0].cpf = data.object[0].username;
                        this.addUserDetailsModel = data.object[0];
                        $('#addUserModal').modal('hide');
                        setTimeout(() => $('#addUserDetailsModal').modal('show'), 500);
                    }
                    break;
            }
            this.loading = false
        }, error => {
            this.loading = false
            console.log("Error", error)
        });
    }

    filtrarUsuarios = () => this.getUsers(this.filtro);

    reenviarEmail = () =>
    {
        this.loading = true
        this.usersService.reenviarEmail(this.selectedUser.username).subscribe(data => 
        {
            console.log(data);
            switch (data.code) 
            {
                case 200:
                    this.feedback.hasFeedBack = true;
                    this.feedback.type = 'email_reenviado';
                    this.dialog.closeAll();
                    setTimeout(() => $('#modalEmailAtivacaoEnvidado').modal('show'), 500);
                    break;
            }
            this.loading = false
        },  
        error => {
            this.loading = false
            console.log("Error", error)
        });
    }
}
