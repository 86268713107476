import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { ResponseFormat } from '@app/types/response-format'

@Injectable({
  providedIn: 'root'
})
export class FixResErrorsService {

  constructor(private http: HttpClient) { }

  getInvoice = (reservationId) => {
    return this.http.get<ResponseFormat>(`vivakey/rest/invoice/${reservationId}`);
  }

  checkZipCode = (zipCode) => {
    return this.http.get<any>(`https://viacep.com.br/ws/${zipCode}/json`);
  }

  saveOmieError(omieOsErrorId){
		return this.http.put<ResponseFormat>(`vivakey/rest/omieerro/sethasrevised/${omieOsErrorId}`, {});
  }
  
  saveInvoice(invoice, reservationId, establishmentId){
		return this.http.put<ResponseFormat>(`vivakey/rest/invoice/${reservationId}/${establishmentId}`, invoice);
  }
}
