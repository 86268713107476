import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ReservationSummaryService } from './reservation-summary.service';
import { translateDataToConfirmData } from '@utils/translators'
import { activities } from '../report-activity/activities'; // data
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import moment from 'moment-timezone';
import { NotifierService } from "angular-notifier";
import { ResendEmailModalComponent } from '@src/app/components/views/resend-email-modal/resend-email-modal.component';
import { MatDialogRef, MatDialog } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { EditCardModalComponent } from '@src/app/components/views/edit-card-modal/edit-card-modal.component';
import { HotelSettingsPageService } from '../hotel-settings/hotel-settings-page.service';
import Swal from 'sweetalert2';
import { FixResErrorsModalComponent } from '@src/app/components/views/fix-reservation-error-modal/fix-reservation-error-modal.component';
import { translateOrigin } from '@src/app/utils/helpers';
import { SharedAnimations } from '@src/app/shared/animations/shared-animations';
import { AppStateService } from '@src/app/layouts/app-layout.service';

declare var $: any;


@Component({
    selector: 'app-reservation-summary',
    templateUrl: './reservation-summary.component.html',
    styleUrls: [
        '../reservation-responsable/reservation-responsable.component.css',
        '../reservation-payment/reservation-payment.component.css',
        './reservation-summary.component.css'
    ],
    animations: [SharedAnimations]
})

export class ReservationSummaryComponent implements OnInit
{
    constructor(
        private appStateService: AppStateService,
        private router: Router,
        public route: ActivatedRoute,
        private reservationSummaryService: ReservationSummaryService,
        private hotelSettingsService: HotelSettingsPageService,
        private notifier: NotifierService,
        public dialog: MatDialog,
        public dialogView: MatDialog,
        public translate: TranslateService,
    ) { 
        
    }

    @Output()
    onExitClicked: EventEmitter<any> = new EventEmitter();

    
    @Output()
    onPreviousClicked: EventEmitter<any> = new EventEmitter<any>();

    @Output()
    onNextClicked: EventEmitter<any> = new EventEmitter<any>();

    @Output()
    onValidatedClicked: EventEmitter<any> = new EventEmitter<any>();

    @Input()
    validateCheckin : boolean;
    timezone: any;

    selectedEstablishment: any;
    hasInvoiceOmie: boolean = false;
    hasHotelInvoiceOmieAutoSend: boolean = false;

    @Input()
    currentReservation: any;
    
    reservationSummaryModel: any = {};
    reservationDetails: any = {};
    sendingObservation: string = '';
    pendingReservationsErrors: any[] = [];
    hasNfPending: boolean = false;

    editTime: string = null
    loading = true

    timeValue: string;
    dateValue: string;

    logs: any;

    resendEmail_DialogRef: MatDialogRef<ResendEmailModalComponent>;
    editCardModalDialogRef: MatDialogRef<EditCardModalComponent>;
    editDateTimeDialogRef: MatDialogRef<any>;
    addDependentDialogRef: MatDialogRef<any>;

    ckeditor_config = {
        language: this.translate.currentLang == 'pt_br' ? 'pt-br ' : 'en-us',
        removePlugins: 'elementspath,save',
        toolbar: [
            {name: 'styles', items: ['Font', 'FontSize']},
            {name: 'basicstyles', items: ['Bold', 'Italic', 'Underline', '-']}] 
        };

    translateOrigin = translateOrigin;

    @ViewChild("modalCheckinInfo")
    modalCheckinInfo: any;

	ngOnInit()
    {

        this.appStateService.reloadEstablishment().then(establishment=> {
            this.selectedEstablishment = establishment;
            this.timezone = this.selectedEstablishment.establishmentConfig.timeZone
            

            this.hasInvoiceOmie = this.selectedEstablishment.establishmentConfig.omieConfig.hasInvoiceOmie;
            this.hasHotelInvoiceOmieAutoSend = this.selectedEstablishment.establishmentConfig.omieConfig.hasHotelInvoiceOmieAutoSend;
            this.getReservationSummaryInfo(this.currentReservation.reservationId).then(ok => {
                setTimeout(() => {
                    if (this.validateCheckin === true) {
                        this.openDetailsModal(this.modalCheckinInfo);
                    }
                }, 50);
            });
            this.getInvoicePendingErrors(this.currentReservation.reservationId);
        });

        var refreshinterval =  setInterval(() =>
        {
            // Se não estiver na summary, para o interval
            if (window.location.pathname != "/reservations/summary")
             return clearInterval(refreshinterval);

            this.getSelfReservationSummaryInfo();

        }, 600000);      
    }

    openDetails(dialogTemplate){
        this.dialogView.open(dialogTemplate);
    }

    getColorValue(reservationStatus) {
        switch (reservationStatus) {
            case 'FULL_PAYMENT':
              return '#40c96a';
            case 'NO_PAYMENT':
              return '#e85454';
            case 'PENDING_PAYMENT':
              return '#f1e74c';
          }
    }

    
    openEditTime(time, editTimeTemplate) {
        this.editTime = time
        this.timeValue = time === 'checkin' ? this.reservationSummaryModel.checkinTime : this.reservationSummaryModel.checkoutTime;
        this.editDateTimeDialogRef = this.dialog.open(editTimeTemplate);
    }

    onChangeTime(value) {
        this.timeValue = value;
    }

    updateCheckinCheckout() {
        this.loading = true;
        let checkin = this.editTime === 'checkin' ? this.timeValue : this.reservationSummaryModel.checkinTime
        let checkout = this.editTime === 'checkout' ? this.timeValue : this.reservationSummaryModel.checkoutTime
        this.reservationSummaryService
        .updateCheckinCheckout(this.currentReservation.reservationId, checkin, checkout)
        .subscribe(data => {
            this.loading = false;
            if(data.code === 200) {
                $('#editTime').modal('hide');
                this.notifier.notify('success', `${this.translate.instant('time_of')} ${this.editTime} ${this.translate.instant('success_changed')}`)
                if(this.editTime === 'checkin') {
                    this.reservationSummaryModel.checkinTime = this.timeValue
                } else {
                    this.reservationSummaryModel.checkoutTime = this.timeValue
                }

                this.getSelfReservationSummaryInfo();
                this.editDateTimeDialogRef.close();
                
            }
        }, error => {
            this.loading = false;
            if(error.error && error.error.message) {
                this.notifier.notify('error', error.error.message)
            }
            else {
                this.notifier.notify('error', `${this.translate.instant('error_msg.error_change_time')} ${this.editTime}`)
            }   
        })
    }


    openEditDate(time, editDateTemplate) {
        this.editTime = time
        this.dateValue = moment(time === 'checkin' ? this.reservationSummaryModel.dataInicial : this.reservationSummaryModel.dataFinal, 'DD/MM/YYYY');
        this.editDateTimeDialogRef = this.dialog.open(editDateTemplate);
        console.log('this.dateValue:', this.dateValue);
    } 

    onChangeDate(value) {
        console.log('this.dateValue:', value);
        this.dateValue = moment(value, 'DD/MM/YYYY');
    }


    updateCheckinCheckout_Date() {
        this.loading = true;
        let checkin = this.editTime === 'checkin' ? moment(this.dateValue).format('YYYY-MM-DD') : this.reservationSummaryModel.dataInicial
        let checkout = this.editTime === 'checkout' ? moment(this.dateValue).format('YYYY-MM-DD') : this.reservationSummaryModel.dataFinal

        this.callResize(this.currentReservation.reservationId, checkin, checkout, this.editTime)
       
    }


    callResize(reservationId, checkinTime, checkoutTime, editTime, resizeWithoutReversing = false) {
        this.loading = true;
        this.reservationSummaryService.updateCheckinCheckout_Date(reservationId, checkinTime, checkoutTime, editTime, resizeWithoutReversing).subscribe(ret => {
            this.loading = false;
            if (ret.code === 200) {
                $('#editDate').modal('hide');
                this.notifier.notify('success', `${this.translate.instant('date_of')} ${this.editTime} ${this.translate.instant('success_changed')}`)
                if(this.editTime === 'checkin') {
                    this.reservationSummaryModel.dataInicial =  moment(this.dateValue).format('DD/MM/YYYY');
                } else {
                    this.reservationSummaryModel.dataFinal = moment(this.dateValue).format('DD/MM/YYYY');
                }

                this.getSelfReservationSummaryInfo();
                this.editDateTimeDialogRef.close();
            }
        }, error => {
            this.loading = false;

            if (!error.error) {
                this.notifier.notify('error', `${this.translate.instant('error_msg.error_change_date')} ${editTime}`)
                return;
            }

            if (error.error.object && error.error.object.resizeOnlyNotReversal === true) {
                this.confirmMinimizeResize(reservationId, checkinTime, checkoutTime, editTime);
            }

        });
    }

    confirmMinimizeResize(reservationId, checkinTime, checkoutTime, editTime) {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn'
            },
            buttonsStyling: false
        })
        swalWithBootstrapButtons.fire({
            position: 'center',
            title: this.translate.instant('resize_modal.confirm_text'),
            html:  this.translate.instant('resize_modal.info'),
            icon: 'question',
            showCancelButton: true,
            confirmButtonText:this.translate.instant('resize_modal.yes'),
            cancelButtonText: this.translate.instant('no'),
            reverseButtons: true
        }).then((result) => {
            if (result.value == true) {
                this.callResize(reservationId, checkinTime, checkoutTime, editTime, true);
            } else if (
                result.dismiss === Swal.DismissReason.cancel) {
            }
        });
    }


    confirmGenerateNF() {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn'
            },
            buttonsStyling: false
          })
        
          swalWithBootstrapButtons.fire({
            position: 'center',
            title: this.translate.instant('reservation_summary.generate_nf'),
            text: this.translate.instant('reservation_summary.generate_nf_quest'),
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: this.translate.instant('reservation_summary.generate_nf_yes'),
            cancelButtonText: this.translate.instant('no'),
            reverseButtons: true
          }).then((result) => {
            if (result.value) {
              this.generateNF();
            } else if (result.dismiss === Swal.DismissReason.cancel) {
        
            }
          });
    }

    generateNF() {
        this.loading = true;
        this.reservationSummaryService
            .generateNF(this.reservationSummaryModel.reservationId, 
                this.selectedEstablishment.establishmentId, 
                this.selectedEstablishment.establishmentConfig.omieConfig.appKey,
                this.selectedEstablishment.establishmentConfig.omieConfig.appSecret
                )
            .subscribe(
                data => {
                    this.loading = false;
                    switch (data.code) {
                        case 200:
                            {
                                $('#modalGenerateNF').modal('hide');
                                this.getSelfReservationSummaryInfo();
                                this.getInvoicePendingErrors(this.currentReservation.reservationId);
                                Swal.fire(this.translate.instant('reservation_summary.nf_successfully_created'), '', 'success');
                            }
                        default:
                    }
                },
                error => {
                    this.loading = false;
                    console.log("erro");
                    if(error.error && error.error.message){
                        Swal.fire(this.translate.instant('reservation_summary.nf_error'), error.error.message, 'error');
                        $('#modalGenerateNF').modal('hide');
                        this.getInvoicePendingErrors(this.currentReservation.reservationId);
                    }
                },
                () => {
                    this.loading = false;
                }
            );
    }


    getSelfReservationSummaryInfo = () => {
        this.getReservationSummaryInfo(this.currentReservation.reservationId)
    }
    
    getReservationSummaryInfo = reservationId =>
    {

        return new Promise(resolve => {
            

            this.reservationSummaryService.getReservationSummaryInfo(reservationId).subscribe(data =>
                {
                    this.loading = false 
                    console.log("DETALHES", data);
                      switch (data.code)
                    {
                        case 200:
                            let unitStr = '';
        
                            let peopleQuantity = 0;
        
                            for (let i = 0; i < data.object.units.length; i++)
                            {
                                peopleQuantity += data.object.units[i].category.peopleQuantity
                                if (unitStr == '')
                                    unitStr += data.object.units[i].name;
                                else
                                    unitStr += ', '+data.object.units[i].name;
                            }
        
                            console.log('unitStr', unitStr);
                            this.reservationDetails = data.object;

                            this.reservationSummaryModel = {
                                ...data.object,
                                additionalFeePrice: data.object.additionalFeePrice,
                                childrenQuantity: data.object.childrenQuantity,
                                documentNumber: data.object.documentNumber,
                                email: data.object.email,
                                guestsQuantity: data.object.guestsQuantity,
                                instalmentQuantity: data.object.instalmentQuantity,
                                phone: data.object.phone,
                                price: data.object.price,
                                quantityOfDays: data.object.quantityOfDays,
                                reservationId: data.object.reservationId,
                                reservationPrice: data.object.reservationPrice,
                                responsibleName: data.object.responsibleName,
                                observation: data.object.observation == "null \n " ? "" : data.object.observation || "",
                                country: data.object.country || "Brasil",
                                dataInicial: moment(data.object.checkinDate, 'YYYY-MM-DD').format("DD/MM/YYYY"),
        
                                dataFinal: moment(data.object.checkoutDate, 'YYYY-MM-DD').format("DD/MM/YYYY"),
                                // TODO: Find the correct return and Test
                                checkinTime: data.object.checkinTime ? data.object.checkinTime : '-',
                                checkoutTime: data.object.checkoutTime ? data.object.checkoutTime : '-', 
                                // -
                                unit: unitStr,
                                unidade: data.object.units[0].category.name + ' - ' + unitStr,
                                categoryName: data.object.units[0].category.name,
                                datetimeReserva: moment(data.object.reservationDate).format("DD/MM/YYYY HH:mm"),
                                dataVencimento: moment(data.object.firstInstalmentMaturityDate, 'YYYY-MM-DD').format("DD/MM/YYYY"),
                                buklyId: data.object.buklyId || null,
                                localizer: data.object.localizer,
                                mainGuestTranslated: data.object.mainGuest ? translateDataToConfirmData(data.object.mainGuest) : null,
                                fnrhId: data.object.fnrhId,
                                isCanceled: data.object.reservationStatus && data.object.reservationStatus !== 'CANCELED' ? false : true,
                                cancelReason: data.object.cancelReason,
                                checkinMobile: data.object.checkinMobile,
                                peopleQuantity,
                                osData: {
                                    cnumOS: data.object.cnumOS,
                                    ncodOS: data.object.ncodOS,
                                    ncodCli: data.object.ncodCli,
                                    hasCreateFaturamento: data.object.hasCreateFaturamento || false,
                                    hasCreateOs: data.object.hasCreateOs || false
                                },
                                shouldExecuteCheckin:  moment.tz(this.timezone).isSameOrAfter(moment(data.object.checkinDate)) 
                                                        && data.object.checkinMobile
                                                        && !data.object.checkinActionDate
                            };


                            this.hasNfPending = (this.reservationSummaryModel.osData && this.reservationSummaryModel.osData.hasCreateOs == true && this.reservationSummaryModel.osData.hasCreateFaturamento == false);

                            if (this.reservationSummaryModel.observation) {
                                this.sendingObservation = this.reservationSummaryModel.observation;
                            }

                            if(data.object.checkinActionDate) {
                                var checkinActionDate = data.object.checkinActionDate.substring(0, 19) ;
                                checkinActionDate = moment.parseZone(checkinActionDate)
                                this.reservationSummaryModel.checkinActionDate_Formated = checkinActionDate.utcOffset(this.selectedEstablishment.establishmentConfig.zoneId).format("DD/MM/YYYY HH:mm");
                            }

                            if(data.object.checkoutActionDate) {
                                var checkoutActionDate = data.object.checkoutActionDate.substring(0, 19) ;
                                checkoutActionDate = moment.parseZone(checkoutActionDate)
                                this.reservationSummaryModel.checkoutActionDate_Formated = checkoutActionDate.utcOffset(this.selectedEstablishment.establishmentConfig.zoneId).format("DD/MM/YYYY HH:mm");
                            }

                            console.log("this.reservationSummaryModel", this.reservationSummaryModel);

                            

                            this.getDependents();

                        break;
                    }

                    
                    resolve("OK");

                },
                (error: any) => {
                    this.loading = false 
                    console.log("Error");

                    resolve("ERROR");
                });
        });
  
    }

    getInvoicePendingErrors = (reservationId) =>
    {
        this.pendingReservationsErrors = [];
        this.reservationSummaryService.getInvoicePendingErrors(this.selectedEstablishment.establishmentId).subscribe(data =>
        {
            switch (data.code)
            {
                case 200:
                    data.object.forEach(element => {
                        if(element.reservationId == reservationId){
                            this.pendingReservationsErrors.push(element);
                        }
                    });
                console.log('pendingReservationsErrors',  this.pendingReservationsErrors);
                break;
            }
        }, error => console.log("Error", error));
    }

    fixResErrorsModalComponentDialogRef: MatDialogRef<FixResErrorsModalComponent>;
    fixInvoiceError(item) {
        this.fixResErrorsModalComponentDialogRef = this.dialog.open(FixResErrorsModalComponent, {
            disableClose: false,
            data : { item: item}
        });

        this.fixResErrorsModalComponentDialogRef.afterClosed().subscribe(result => {
            this.getInvoicePendingErrors(this.currentReservation.reservationId);
            this.fixResErrorsModalComponentDialogRef = null;
        });
    }
    
    getDependents() {
        this.loading = true;
        this.reservationSummaryService.getDependents(this.currentReservation.reservationId).subscribe(
            data => {
                this.loading = false
                if(data.code == 200){
                    this.reservationSummaryModel.dependents = data.object;
                }
            },
            error => {
                this.loading = false
        })
    }

    finishReservation = () => this.openContainer('#finish_reservation');

    editObservation(observationModalTemplate) {

        this.getReservationSummaryInfo(this.currentReservation.reservationId).then((value) => {
            setTimeout(() => {
                this.dialog.open(observationModalTemplate);
            }, 200);
        });
        
    }

    sendObservation = () =>
    {
        this.reservationSummaryService.sendObservation(
            this.currentReservation.reservationId,
            this.sendingObservation).subscribe(data => 
        {
            console.log(data);
            switch (data.code)
            {
                case 200:
                    this.dialog.closeAll();
                    this.getReservationSummaryInfo(this.currentReservation.reservationId);
                break;
            }
        }, error => console.log("Error", error));
    }

    goToStep2 = () => {
        if(this.reservationSummaryModel && !this.reservationSummaryModel.isCanceled) {
            this.onPreviousClicked.emit();
        }
    }


    closeContainer = containerId =>
    {
        if (containerId == '#finish_reservation')
        {
            $("html").css("overflow-y", "visible");
            return this.onExitClicked.emit();
        }

        return $(containerId).fadeOut(() => $("html").css("overflow-y", "visible"));
    }

    cancelReservation = () =>
    {
        $('#modalConfirmLeaveReservation').modal('hide');
        return this.onExitClicked.emit();
    }

    openContainer = containerId =>
    {
        $(containerId).fadeIn(() => $("html").css("overflow-y", "hidden"));
        setTimeout(() => 
        {
            this.closeContainer('#finish_reservation');
            this.onExitClicked.emit();
        }, 3000);
    }


    public dialogLogs: MatDialogRef<any>
    openLogModal(modalLogs) {
        this.loading = true
        let initialDate = moment.tz('America/Sao_Paulo').subtract(1, 'months').format('YYYY-MM-DD') + 'T00:00:00'
        let endDate = moment.tz('America/Sao_Paulo').format('YYYY-MM-DD') + 'T00:00:00'
        this.reservationSummaryService.getLogs(this.currentReservation.reservationId, initialDate, endDate).subscribe(
            data => {
                this.loading = false
                this.logs = data.object.map((log) => {
                    log.logDate = moment(log.logDate);
                    log.logMethodFormatted = log.logDescription;
                    return log
                })
                console.log('logs', this.logs)
                this.dialogLogs = this.dialog.open(modalLogs);
            },
            error => {
                this.loading = false
            })
    }

    formatLog = (logMethod, name) =>
    {
        
        for(let i = 0; i < activities.length; i++)
        {
            if (logMethod == activities[i].method)
                return activities[i].formatted.replace("{nome}", name); 
        }

        return logMethod;
    }

    openCheckinModal(dialogTemplate) {
        console.log('dialogTemplate', dialogTemplate);
        this.dialog.open(dialogTemplate, {
            //panelClass: 'mat-dialog-large',
            disableClose: true
        });
    }

    openDetailsModal(modalCheckinInfo) {
        this.openDetails(modalCheckinInfo);
    }

    editDependent(dependent) {
        this.editCardModalDialogRef = this.dialog.open(EditCardModalComponent, {
			panelClass: 'mat-dialog-edit-card',
			disableClose: false,
			data : { type: "dependent", personId: dependent.dependentId, editData: null }
		  });
	  
		  //this.editCardModalDialogRef.componentInstance.units = this.units;
	  
		  this.editCardModalDialogRef.afterClosed().subscribe(result => {
			this.getDependents();
			this.editCardModalDialogRef = null;
		  });
    }

    editMainGuest(mainGuest) {
        this.editCardModalDialogRef = this.dialog.open(EditCardModalComponent, {
			panelClass: 'l1-content-modal',
			disableClose: false,
			data : { type: "mainGuest", personId: null, editData: mainGuest }
		  });
	  
		  //this.editCardModalDialogRef.componentInstance.units = this.units;
	  
		  this.editCardModalDialogRef.afterClosed().subscribe(result => {
            this.getReservationSummaryInfo(this.currentReservation.reservationId);
			this.editCardModalDialogRef = null;
		  });
    }

    openResendEmail(){
        this.resendEmail_DialogRef = this.dialog.open(ResendEmailModalComponent, {
            panelClass: 'mat-dialog-fix',
            disableClose: false,
            data : { reservation: this.reservationDetails }
        });
      
          //this.reportProblemModalComponentDialogRef.componentInstance.units = this.units;
      
        this.resendEmail_DialogRef.afterClosed().subscribe(result => {
            if (result === true) {
                this.getReservationSummaryInfo(this.currentReservation.reservationId);
            }
            this.resendEmail_DialogRef = null;
        });
    }

    resendPassword() {
        this.loading = true;

        this.reservationSummaryService.resendPassword(this.currentReservation.reservationId).subscribe(data =>
            {
                this.loading = false 
                if(data.code === 200) {
                    this.notifier.notify('success', this.translate.instant('email_sended'));
                }
            },
            (error: any) => {
                this.loading = false 
                this.notifier.notify('error', this.translate.instant('error_msg.error_send_email'));
            });
    }

    confirmValidateCheckin(){
        const swalWithBootstrapButtons = Swal.mixin({
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn'
          },
          buttonsStyling: false
        })
      
        swalWithBootstrapButtons.fire({
          position: 'center',
          title: this.translate.instant('validate_checkin.confirm_validate_title'),
          text: this.translate.instant('validate_checkin.confirm_validate'),
          icon: 'question',
          showCancelButton: true,
          confirmButtonText: this.translate.instant('validate_checkin.yes_confirm'),
          cancelButtonText: this.translate.instant('no'),
          reverseButtons: true
        }).then((result) => {
          if (result.value) {
            this.validateCheckinService();
          } else if (result.dismiss === Swal.DismissReason.cancel) {
      
          }
        });
      }

    validateCheckinService() {
        this.loading = true;

        this.reservationSummaryService.validateCheckin(this.currentReservation.reservationId).subscribe(data =>
            {
                this.loading = false 
                if(data.object) {
                    this.dialog.closeAll();
                    this.notifier.notify('success', this.translate.instant('validate_checkin.validated_ok'));
                    this.onValidatedClicked.emit();
                    this.onExitClicked.emit();
                }
            },
            (error: any) => {
                this.loading = false 
                this.notifier.notify('error', this.translate.instant('validate_checkin.validate_error'));
            });
    }

    addGuest(modalAddDependent) {
        this.addDependentDialogRef = this.dialog.open(modalAddDependent,  { disableClose: true });
    }

    onSaveMainDependent(data) {
        this.getSelfReservationSummaryInfo();
    }

    onAddDependentOK() {
        this.addDependentDialogRef.close();
        this.getSelfReservationSummaryInfo();
    }



    confirmRemoveDependentn(dependentId, reservationId){
        const swalWithBootstrapButtons = Swal.mixin({
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn'
          },
          buttonsStyling: false
        })
      
        swalWithBootstrapButtons.fire({
          position: 'center',
          title: this.translate.instant('reservation_summary.remove_rependent') + '?',
          text: this.translate.instant('reservation_summary.confirm_remove_rependent'),
          icon: 'question',
          showCancelButton: true,
          confirmButtonText: this.translate.instant('yes'),
          cancelButtonText: this.translate.instant('no'),
          reverseButtons: true
        }).then((result) => {
          if (result.value) {
            this.removeDependent(dependentId, reservationId);
          } else if (result.dismiss === Swal.DismissReason.cancel) {
      
          }
        });
      }



    removeDependent(dependentId, reservationId){
        this.loading = true;
        this.reservationSummaryService.removeDependentToReservation(reservationId, dependentId).subscribe(data =>
            {
                this.loading = false 
                if(data.code == 200) {
                    this.notifier.notify('success', this.translate.instant('reservation_summary.remove_dependent_ok'));
                    this.getSelfReservationSummaryInfo();
                }
            },
            (error: any) => {
                this.loading = false 
                this.notifier.notify('error', this.translate.instant('reservation_summary.remove_dependent_error'));
            });
    }


    async makeReservationCheckin(reservation){

        if(!reservation.shouldExecuteCheckin)
            return;
            
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn'
            },
            buttonsStyling: false
        })

       var result = await swalWithBootstrapButtons.fire({
            position: 'center',
            title: this.translate.instant('occupation.confirm_checkin'),
            text: this.translate.instant('occupation.confirm_checkin_checkout_info'),
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: this.translate.instant('occupation.ok_confirm'),
            cancelButtonText: this.translate.instant('cancel'),
            reverseButtons: true
        }).then();

        if (result.value) {

            this.reservationSummaryService.makeCheckin(reservation.reservationId).subscribe(
                (data) => {
                    if (data.code === 200) {
                        this.getReservationSummaryInfo(reservation.reservationId);
                        if (data.object && data.object.code == 422) {
                            Swal.fire(`<b>Checkin Realizado!<br>Alertas Retornados Abaixo: </b>`, data.object.message, 'info');
                        }
                    }
                    else if (data.message) {
                        this.notifier.notify('error', data.message)
                    } else {
                        this.notifier.notify('error', `${this.translate.instant('error_msg.error_edit')}`)
                    }
                },
                (error) => {
                    console.log(error);
                    if (error.error.message) {
                        this.notifier.notify('error', error.error.message)
                    } else {
                        this.notifier.notify('error', `${this.translate.instant('error_msg.error_edit')}`)
                    }
                })

        }
    }



}