import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse  } from '@angular/common/http';
import {ResponseFormat} from '@app/types/response-format';
import { AppStateService } from '@src/app/layouts/app-layout.service';

@Injectable()
export class TipoPagamentoService 
{

    constructor(private http : HttpClient, private appStateService: AppStateService) { }

    getPaymentWays = filtro =>
    {
        console.log('filtro', filtro);
        const portador = JSON.parse(sessionStorage.getItem('currentPortador'));
        const establishment = this.appStateService.currentEstablishment;
            
        let queryParam = '?bankId=' + portador.bankId;
        
        if (filtro && filtro.name)
            queryParam += '&name=' + filtro.name;
            
        if (filtro && filtro.paymentType != 'todos')
            queryParam += '&paymentTypeId=' + filtro.paymentType.paymentTypeId;
            
        if (filtro && filtro.status != 'todos')
            queryParam += '&status=' + filtro.status;

        return this.http.get<ResponseFormat>('vivakey/rest/bankPaymentType/getBankPaymentTypes/' + establishment.establishmentId + queryParam);
    }

	getPaymentWaysTypes = () =>  this.http.get<ResponseFormat>('vivakey/rest/paymentType/getPaymentTypes/');

	savePaymentWay = (paymentWay) =>
	{
		const portador = JSON.parse(sessionStorage.getItem('currentPortador'));
		const editOrAdd = (paymentWay.bankPaymentTypeId) ? paymentWay.bankPaymentTypeId : null;
		const fluxo = (paymentWay.cashFlow) ? 0 : 1;
		var brand = (paymentWay.brand) ? paymentWay.brand : '';
        const cardtax = (paymentWay.cardTax) ? paymentWay.cardTax : 0;
        
        brand = paymentWay.paymentType.taxes == false ? 'NONE' : paymentWay.brand;
        paymentWay.cardTax2to6 =  paymentWay.paymentType.agreesInstalment == true ? paymentWay.cardTax2to6 : 0;
        paymentWay.cardTax7to12 =  paymentWay.paymentType.agreesInstalment == true ? paymentWay.cardTax7to12 : 0;

		const body = {
            bankPaymentTypeId: editOrAdd,
            bankId: portador.bankId,
            paymentTypeId: paymentWay.paymentType.paymentTypeId,
            name: paymentWay.name || "",
            brand: brand || "",
            cardTax: cardtax,
            cardTax2to6: paymentWay.cardTax2to6 ? paymentWay.cardTax2to6 : 0,
            cardTax7to12: paymentWay.cardTax7to12 ? paymentWay.cardTax7to12 : 0,
            status: paymentWay.situacao,
            cashFlow: fluxo,
            numberDaysForPayment: paymentWay.numberDaysForPayment
        };

        // if(((paymentWay.cardTax2to6 || 0) > 0)){
        //     body["cardTax2to6"] = paymentWay.cardTax2to6;
        // }

        // if(((paymentWay.cardTax7to12 || 0) > 0)){
        //     body["cardTax7to12"] = paymentWay.cardTax7to12;
        // }
        
		console.log('saving...', body);
		return this.http.post<ResponseFormat>('vivakey/rest/bankPaymentType', body);
	}

	deletePaymentWay = id => this.http.delete<ResponseFormat>(`vivakey/rest/bankPaymentType/${id}`);
}
