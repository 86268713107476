import { Component, OnInit, Inject, ViewChild, TemplateRef } from '@angular/core';
import { CreateHotelService } from '@src/app/pages/create-hotel/create-hotel.service';
import { NotifierService } from 'angular-notifier';
import { validateCNPJ } from '../../../utils/validators'
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { removeChars } from '@src/app/utils/helpers';
import { TranslateService } from '@ngx-translate/core';
import { DependentFormService } from '../dependent-form/service/dependent-form.service';

import { translateDataToConfirmData } from '@utils/translators'
import { ConfirmCardComponent } from '../confirm-card/confirm-card.component';
declare var $: any;

@Component({
  selector: 'app-edit-card-modal',
  templateUrl: './edit-card-modal.component.html',
  styleUrls: ['./edit-card-modal.component.scss']
})
export class EditCardModalComponent implements OnInit {

  @ViewChild("confirmCard") confirmCard: ConfirmCardComponent;

  loading: boolean = false;
  personId: any;
  type = "";
  currentData: any;
  title = "";

  mainGuestLogData: any[] = [];

  public dialogLogs: MatDialogRef<any>


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private service: DependentFormService,
    private notifier: NotifierService,
    public dialogRef: MatDialogRef<EditCardModalComponent>,
    private translate: TranslateService,
    public dialog: MatDialog, ) { }

  ngOnInit() {

    this.type = this.data.type;
    this.personId = this.data.personId;
    this.currentData = this.data.editData;

    //this.currentData = this.data.editData ? translateDataToConfirmData(this.data.editData) : null;

    switch (this.type) {
      case 'mainGuest':
        this.title = this.translate.instant('main_guest');
        break;

      case 'dependent':
        this.title = this.translate.instant('dependent');
        break;

      case 'responsible':
        this.title = this.translate.instant('responsible');
        break;

      default:
        this.title = "";
    }

    if (!this.currentData) {
      setTimeout(() => {
        this.getData();
      }, 50);

      
    }

  }


  getData() {
    this.loading = true;
    this.service.getPersonData(this.personId, this.type).subscribe(data => {
      console.log("data:", data.object);
      this.loading = false
      if (data.object) {
        

        if(this.type == "responsible") {
          this.getResponsibleAdreess(data.object);
        } 
        else{
          this.currentData = translateDataToConfirmData(data.object);
          this.confirmCard.initialize();
        }
        
      }
    }, error => {
      this.loading = false
      this.notifier.notify('error', this.translate.instant('error_msg.error_data_loading'))
      console.log("Error", error)
    });
  }

  
  getResponsibleAdreess(responsible) {
    this.loading = true;
    this.service.getResponsibleAdreess(responsible.responsibleId).subscribe(data => {
      this.loading = false
      if (data.object) {
        this.currentData = translateDataToConfirmData({...responsible, ...data.object});
        this.confirmCard.initialize();
      }
      else{
        this.currentData = translateDataToConfirmData(responsible);
        this.confirmCard.initialize();
      }
    }, error => {
      this.loading = false
      this.notifier.notify('error', this.translate.instant('error_msg.error_data_loading'))
      console.log("Error", error)
    });
  }

  getMainGuestLog(modalLogs) {
		this.mainGuestLogData = [];
		this.loading = true;
		this.service.getMainGuestLog(this.currentData.mainGuestId).subscribe(
			data => {
        this.loading = false;
				if (data.code === 200 && data.object) {
          this.mainGuestLogData = data.object;
          this.dialogLogs = this.dialog.open(modalLogs);
				}
			},
			error => {
				this.loading = false;
				this.notifier.notify('error', this.translate.instant('error_msg.error_data_loading'))
			}
		);
  }
  

  onSaveData(data) {
    this.currentData = data;
  }

}
