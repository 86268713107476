import { Component, OnInit } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { CreateHotelService } from './create-hotel.service'
import { Router, ActivatedRoute } from "@angular/router";
import { TranslateService } from '@ngx-translate/core';
import { AppStateService } from '@src/app/layouts/app-layout.service';

@Component({
	selector: 'app-create-hotel',
	templateUrl: './create-hotel.component.html',
	styleUrls: ['./create-hotel.component.scss']
})
export class CreateHotelComponent implements OnInit {

	managersList: any = [];
	companyesList: any = [];

	name: string = "";
	documentNumber: string = "";
	manager: any;
	company: any;

	loading: boolean = false

	selectedCompany: any

	constructor(
        public appStateService: AppStateService,
        private service: CreateHotelService,
        private notifier: NotifierService, 
        private translate: TranslateService,
		private router: Router) { }

	ngOnInit() {
		this.selectedCompany = this.appStateService.currentCompany;
		this.getEmployees();
		this.getCompanyes();
	}

	query() {
		const { name, documentNumber, manager, company } = this
		if(name && documentNumber && manager !== null && company !== null && name.length > 0 && documentNumber.length > 0) {
			let body = {
				"name": name,
				"documentNumber": documentNumber,
				"companyId": this.company.companyId,
				"managerId": this.manager.employeeId
			}

			this.loading = true

			this.service.createHotel(body).subscribe((data) => {
				this.notifier.notify('success', this.translate.instant('create-hotel.success_saved'))
				this.loading = false
				this.router.navigate(['/home'])
			}, error => {
				this.loading = false
				this.notifier.notify('error', this.translate.instant('create-hotel.error_save'))
			})
		}
	}



	getEmployees()
    {
        this.service.getEmployees().subscribe(data =>
        {
            console.log("GetEmployees", data.object);
            this.loading = false
            switch (data.code)
            {
                case 200:
                this.managersList = data.object;
                break;
                case 219:
                console.log('no permission');
                break;
            }
            this.loading = false
        }, error => {
            this.loading = false
            this.notifier.notify('error', this.translate.instant('create-hotel.error_load_employes'))
            console.log("Error", error)
        });
    }


	getCompanyes()
    {
        this.service.getCompanyes().subscribe(data =>
        {
            console.log("getCompanyes", data.object);
            this.loading = false
            switch (data.code)
            {
                case 200:
                this.companyesList = data.object;
                break;
                case 219:
                console.log('no permission');
                break;
            }
            this.loading = false
        }, error => {
            this.loading = false
            this.notifier.notify('error', this.translate.instant('create-hotel.error_load_companies'))
            console.log("Error", error)
        });
	}
	



}
