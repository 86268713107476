import { Component, OnInit, TemplateRef } from '@angular/core';
import { OtasService } from './otas-config.service';
import { NotifierService } from 'angular-notifier';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog, MatTableDataSource } from '@angular/material';
import Swal from 'sweetalert2';
import { timeout } from 'rxjs-compat/operator/timeout';
import { SelectionModel } from '@angular/cdk/collections';
import { CommonService } from '@src/app/common-service';


@Component({
  selector: 'app-otaconfig',
  templateUrl: './otas-config.component.html',
  styleUrls: ['./otas-config.component.scss']
})
export class OtasConfigComponent implements OnInit {

  loading: boolean = false;

  data: any[] = [];
  dataBuildings: any[] = [];
  editContractData: any[] = [];

  selectedItem: any;
  selectedItemBuilding: any;

  selectedBuilding: any;

  selectedTabIndex = 0;
  

  percentualConfig = { prefix: '', suffix:'%', thousands: '.', decimal: ',', align : 'left', allowNegative : 'false' };

  constructor(
    public dataService: OtasService,
    private notifier: NotifierService,
    private translate: TranslateService,
    private dialog: MatDialog,) {

  }

  ngOnInit() {
    this.dataService.loadDependencesData().then(ok => {
      this.loadData();
    });
  }

  editItem(item, dialogTemplate) {
    this.selectedItem = item;
    this.selectedItem.otaContractId = (item.otaContract ? item.otaContract.otaContractId : null);
    
    this.filterContracts(item.ota.otaId);
    this.openDialogWithTemplateRef(dialogTemplate);
  }

  editBuildingOTA(item, dialogTemplate) {
    this.selectedItemBuilding = item;
    this.selectedItemBuilding.otaContractId = (item.otaContract ? item.otaContract.otaContractId : null);
    
    this.filterContracts(item.ota.otaId);
    this.openDialogWithTemplateRef(dialogTemplate);
  }

  filterContracts(otaId) {
    this.editContractData = [];  
    var contractsOfOta = this.dataService.otaRestrictsContract.filter(i=>i.otaId == otaId);

    contractsOfOta.forEach(element => {
      var contract = this.dataService.otaContract.find(i=>i.otaContractId == element.otaContractId);
      if(contract){
        this.editContractData.push(contract);
      }
    });

  }

  openDialogWithTemplateRef(templateRef: TemplateRef<any>) {
    this.dialog.open(templateRef);
  }

  onSelectBuilding(event) {
    this.dataService.loadBuildingFees(this.selectedBuilding.buildingId)
      .then(data => { });
  }

  loadData() {
    setTimeout(() => this.selectedItem = null, 100);

    console.log('this.selectedTabIndex', this.selectedTabIndex);

    if(this.selectedTabIndex == 0) {
      this.loading = true;
      this.dataService.loadOtasFees().then(ok => {
        this.loading = false;
      });
    }

    if((this.selectedTabIndex == 1) && this.selectedBuilding != null) {
      this.loading = true;
      this.dataService.loadBuildingFees(this.selectedBuilding.buildingId).then(ok => {
        this.loading = false;
      });
    }
    
  }



  updateEstOta() {

    if (this.selectedItem && this.selectedItem.otaContractId) {

      this.loading = true;
      this.dataService.updatetEstablishment_Otas(this.selectedItem.otaEstablishmentConfigId, 
        {
          otaContractId: this.selectedItem.otaContractId,
          otaRate: this.selectedItem.otaRate
        }).subscribe(resp => {

        this.loading = false;
        if (resp.code == 200) {
          this.loadData();
          this.dialog.closeAll();
        }
      }, error => {
        this.loading = false;
        this.notifier.notify('error', this.translate.instant('error_msg.error_undefined'))
      });
    }

  }


  
  updateBuildingOta() {

    if (this.selectedItemBuilding && this.selectedItemBuilding.otaContractId) {

      this.loading = true;
      this.dataService.updatetBuildingsConfig_Otas(this.selectedItemBuilding.otaBuildingConfigId, 
        {
          otaContractId: this.selectedItemBuilding.otaContractId,
          otaRate: this.selectedItemBuilding.otaRate,
          active: this.selectedItemBuilding.active,
        }).subscribe(resp => {

        this.loading = false;
        if (resp.code == 200) {
          this.dataService.loadBuildingFees(this.selectedBuilding.buildingId).then(data => { });
          this.dialog.closeAll();
        }
      }, error => {
        this.loading = false;
        this.notifier.notify('error', this.translate.instant('error_msg.error_undefined'))
      });
    }


  }


}
