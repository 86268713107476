import { Injectable } from '@angular/core';
import {Observable} from 'rxjs/Rx';
import {ResponseFormat} from '@app/types/response-format';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse  } from '@angular/common/http';
import { CPFValidator } from '@tools/Validator';

@Injectable()
export class ReservationResponsableService
{

    constructor(private http : HttpClient) { }

    private baseUrl = "vivakey/rest/responsible";

    getReservationResponsables = (reservationId, docType, docNumber) =>
    {
        let queryParam = '';

        if (reservationId || docType || docNumber)
            queryParam += '?';

        if (reservationId)
            queryParam +=  '&reservationId=' + reservationId;

        if (docNumber)
            queryParam +=  '&documentNumber=' + docNumber;
            // docNumber = docNumber.replace(/\D/g,'');

        if (docType)
            queryParam +=  '&documentType=' + docType;

        return this.http.get<ResponseFormat>(`${this.baseUrl}/getResponsibles` + queryParam)
    }

    getReservationResponsible = (documentType, fieldValue) => this.http.get<ResponseFormat>(`${this.baseUrl}/getResponsible/${documentType}/${fieldValue}`);

    createResponsible = responsible =>
    {
        if (responsible.responsibleId)
            responsible.id = responsible.responsibleId;

        console.log("Send responsible: ", responsible);
        return this.http.put<ResponseFormat>(this.baseUrl, responsible);
    }

    assignResponsableToReservation = model => this.http.put<ResponseFormat>('vivakey/rest/reservation/responsible', model);

    updateGuestsQuantity = model => this.http.put<ResponseFormat>('vivakey/rest/reservation/guestsQuantity', model);

    validaCPF = cpf => CPFValidator(cpf);

    getResponsibleAddress = responsibleId => this.http.get<ResponseFormat>(`vivakey/rest/address/getByResponsibleId/${responsibleId}`)
}
