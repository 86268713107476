import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TokenService } from '@app/token.service';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit 
{
  constructor(private route: ActivatedRoute, private tokenservice: TokenService, private notifier: NotifierService,) { }

  private sub: any;
  token: string;
  step: any;
  model: any = {};
  passwordsDontMatch : any;
  invalidInput: any;
  invalidPassword: any;
  
  ngOnInit() 
  {
        this.passwordsDontMatch = false;
        this.invalidPassword = false;

        this.sub = this.route.params.subscribe(params => 
        {
            this.token = params['token']; 
            console.log(this.token);
            this.tokenservice.authToken(this.token).subscribe(data => 
            { 
                console.log(data);
                if (data.code == 200)
                    this.step = 1;
                else
                    this.step = 0;
            }, error => console.log("Error", error)) 
        });
  }

    ngOnDestroy() 
    {
        this.sub.unsubscribe();
    }

    validatePass() {
        const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[$*&@#])[0-9a-zA-Z$*&@#]{8,255}$/;
        this.invalidPassword = !regex.test(this.model.newPassword);
        console.log(this.invalidPassword);
    }

    resetPassword = () => {
        if (this.model.newPassword == this.model.newPasswordConfirmation)
            this.tokenservice.ResetPasswordService(this.model.newPassword, this.token).subscribe(data => {
                console.log(data)

                if (data.code == 200)
                    this.step = 3;
                else
                    this.step = 0;
            }, error => {
                var body = JSON.parse(error._body);
                if(body && body.message) 
                {
                    this.notifier.notify('error', body.message);
                }   
            });
        else
            this.passwordsDontMatch = true;
    }

    togglePassword = confirmacao =>
    {
        let aux = "";

        if (confirmacao)
            aux = document.getElementById("newPasswordconfirmation_input").getAttribute("type");
        
        if (aux == "text")
            document.getElementById("newPasswordconfirmation_input").setAttribute("type","password");
            
        if (aux == "password")
            document.getElementById("newPasswordconfirmation_input").setAttribute("type","text");
        else
        {
            const aux = document.getElementById("newPassword_input").getAttribute("type");
            
            if (aux == "text")
                document.getElementById("newPassword_input").setAttribute("type","password");
            
            if (aux == "password")
                document.getElementById("newPassword_input").setAttribute("type","text");
        }
    }  

    }
