import { Component, OnInit, Input, ViewChild, Output, EventEmitter, ComponentFactoryResolver, Type } from '@angular/core';
import { DayPilotSchedulerComponent, DayPilot } from 'daypilot-pro-angular';
import moment from 'moment-timezone';
import { CurrencyPipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { FormEditDirective } from './form-container.directive';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';

@Component({
  selector: 'app-scheduler',
  templateUrl: './scheduler.component.html',
  styleUrls: ['./scheduler.component.scss']
})
export class SchedulerComponent implements OnInit {

  @Input() selectStartDate: any;
  @Input() quantityDays: any;
  @Input() resources: any[] = [];
  @Input() events: any[] = [];
  @Output() onEventClicked: EventEmitter<any> = new EventEmitter();
  @Output() onTimeRangeSelected: EventEmitter<any> = new EventEmitter();
  @Output() onEventResized: EventEmitter<any> = new EventEmitter();
  @Output() onEventMoved: EventEmitter<any> = new EventEmitter();

  public scheduler: DayPilotSchedulerComponent;
  public configAsInit: boolean = false;


  isSmallScreen = false;

  @ViewChild(FormEditDirective) adHost: FormEditDirective;

  
  constructor(public translate: TranslateService, private componentFactoryResolver: ComponentFactoryResolver, public breakpointObserver: BreakpointObserver,) { 
    this.isSmallScreen = breakpointObserver.isMatched('(max-width: 599px)');
    
  }


  configScheduler: any = {
    theme: "test",
    rowHeaderWidthAutoFit: false,
    rowHeaderWidth: 150,
    heightSpec: "Fixed",
    height: 300,
    // locale: this.translate.currentLang == 'pt_br' ? 'pt-br ' : 'en-us',
    scale: "Manual",
    //infiniteScrollingEnabled: true,
    //scale: "CellDuration",
    //cellDuration: 1440,
    timeline: this.getTimeline(),
    timeHeaders: [
      { groupBy: 'Month', format: 'MMMM yyyy' },
      { groupBy: 'Day', format: 'ddd dd' },
     // { groupBy: 'Day', format: 'dd' }
    ],
    days: this.quantityDays || 10,
    //startDate: moment(this.selectStartDate).subtract(3, 'days').format("YYYY-MM-DD"),
    crosshairType: "Full",
    //crosshairType: "Full", "Header",
    treeEnabled: true,
    cellWidthSpec: "Fixed",
    //cellWidthSpec: "Auto",
    cellWidth: 60,
    showNonBusiness: true,
    businessWeekends: false,
    floatingEvents: true,
    eventHeight: 35,
    eventMovingStartEndEnabled: false,
    eventResizingStartEndEnabled: false,
    timeRangeSelectingStartEndEnabled: false,
    groupConcurrentEvents: false,
    eventStackingLineHeight: 100,
    allowEventOverlap: false,
    timeRangeSelectedHandling: "Enabled",

    onBeforeEventRender: args => {
      console.log('args:', args);
      args.data.barColor = args.data.color;
      if (args.data.unitLocked) {
        var colorText = '#0d0d0d';
        args.data.areas = [
          {
            top: 3,
            bottom: 3,
            left: 3,
            right: 3,
            //height: 35,
            html: "<div style='background-color:" + args.data.color + "; height: 100%; width: 100%;  border-radius: 3px; border: 0.5px solid #303030; '> </div>"
          },
        ];
      }

      if (args.data.reservation) {

        var colorText = this.getTextColor(args.data.reservation);

        args.data.areas = [
          {
            top: 3,
            bottom: 3,
            left: 3,
            right: 3,
            //height: 35,
            html: "<div style='background-color:" + args.data.color + "; height: 100%; width: 100%;  border-radius: 3px; border: 0.5px solid #303030; '> <span style='vertical-align: middle; margin: 4px; color: " + colorText + "'>" + args.data.reservation.reservationId + "</span> </div>"
          },
        ];

        if (args.data.reservation.checkin == true || args.data.reservation.checkinMobile == true) {
          args.data.areas.push(
            {
              left: 18,
              //bottom: 15, 
              height: 8,
              top: 22,
              html:
                `<div style='
                  height: 8px;
                  width: 8px;
                  border-radius: 100%;
                  background: #4bccff;
                  position: absolute;
                  border: 1px solid #67d4ff;
                  z-index: 10;
                  -webkit-box-shadow: 1px 1px 7px 0 rgba(0, 0, 0, 0.4);
                  box-shadow: 1px 1px 7px 0 rgba(0, 0, 0, 0.4);
                  right: 3px;'>
                </div>`
            },
          );
        }
      }

    },

    onBeforeRowHeaderRender: args => {

      args.row.areas = [];

      if(args.row.data && args.row.data.category != null && args.row.data.category.name.length > 18){
        args.row.toolTip = args.row.data.category.name;
      }

      if(args.row.data && args.row.data.unity != null && args.row.data.unity.name.length > 18){
        args.row.toolTip = args.row.data.unity.name;
      }

 
      if(args.row.data && args.row.data.building != null && args.row.data.building.nameBuilding.length > 18){
        args.row.toolTip = args.row.data.building.nameBuilding;
      }

      if (args.row.data.excludedUnit === true || args.row.data.unavailable == true) {
        var dv = `<div style="display: flex;">`;

        if (args.row.data.excludedUnit === true){
          dv +=  `<span class="glyphicon glyphicon-remove" style="font-size: 9px"></span>`;
        }

        if (args.row.data.unavailable === true){
          dv +=  `<span class="glyphicon glyphicon-lock" style="font-size: 9px; margin-left: 4px"></span>`;
        }

        dv += '</div>';

        args.row.areas.push(
          {
            right: 4,
            top: 10,
            html: dv,
          },
        );

      }
     

      if (args.row.data.isGroup) {
        if(args.row.data.building != null){
          args.row.backColor = '#676565';
          args.row.fontColor = '#ffffff';

          if(this.isSmallScreen) {
            args.row.html = '<div class="test_rowheader_inner_text" style="color: white; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; max-width: 55px; font-size: 10px">' + args.row.data.building.nameBuilding + '</div>';

          } else {
            args.row.html = '<div class="test_rowheader_inner_text" style="color: white; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">' + args.row.data.building.nameBuilding + '</div>';

          }
          
        } else if (args.row.data.category != null) {

          if(this.isSmallScreen) {
            args.row.html = '<div class="test_rowheader_inner_text" style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis; max-width: 55px; font-size: 10px">' + args.row.data.category.name + '</div>';

          } else {
            args.row.html = '<div class="test_rowheader_inner_text" style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">' + args.row.data.category.name + '</div>';

          }

        } 
        
        else {
          args.row.backColor = '#b3b1b1';
          
        }
        
      }

    },

    onBeforeCellRender: args => {
      // console.log('this.scheduler.control.rows', this.scheduler.control.rows);
      // console.log('args.cell.resource', args.cell.resource);

      if(args.cell.resource == undefined){
        return;
      }

      const row = this.scheduler.control.rows.find(args.cell.resource);
      if (row.data.isGroup) {
        //args.cell.disabled = true;
        args.cell.backColor = '#f5f5f5';

        if(row.data.building != null){
          args.cell.backColor = '#676565';
        }

        var resource;

        if(this.resources.find(i=>i.building != null) != null){

          this.resources.forEach(element => {
            element.children.forEach(cat => {
              if(cat.id == args.cell.resource){
                resource = cat;
              }
            });
          });

        } else {
          resource = this.resources.find(i => i.id == args.cell.resource);
        }

        if (resource) {
          var simCurr = this.translate.currentLang == 'pt_br' ? 'R$ ' : '$';
          var tariff = resource.tariffs.find(i => i.date.substring(0, 10) == args.cell.start.value.substring(0, 10));
          if (tariff) {
            args.cell.areas = [];
            args.cell.areas.push(
              {
                left: 2,
                right: 2,
                bottom: 2,
                //height: 8, 
                top: 2,
                html:
                  `<div style='display: flex; justify-content: center; align-items: center; color: #737373; background-color: #fff; border: solid 1px #818181; border-radius: 0px; font-size: 10px; height: 100%' 
                class="days_header noTariff"
                [ngClass]="{ 'category_first' : first , 'hasTariff' : day.hasTariff }">
                ${simCurr} ${tariff.minValue}
                /<br>
                ${simCurr} ${tariff.maxValue}
              </div>`,
                onClicked: (cl) => {
                  console.log(cl);

                }
              },
            );
          }
          else {
            args.cell.backColor = '#faf4f4';
            args.cell.disabled = true;
          }
        }
      }
      else {

        if (this.tariffsNotFound(args.cell.resource, args.cell.start.value.substring(0, 10))) {
          args.cell.backColor = '#faf4f4';
          args.cell.disabled = true;
        }

      }

      if (row.data.unavailable) {
        args.cell.disabled = true;
        if (row.data.unity != null) {
          args.cell.backColor = '#faf4f4';
        }
        else {
          //args.cell.backColor = '#ffe6e6';
        }

      }

    },

    onBeforeTimeHeaderRender: args => {
      if (args.header.level === 1) {

        if(this.translate.currentLang == 'pt_br')
          args.header.html = moment(args.header.start.value).locale('pt-br').format('ddd<BR>DD');
        else
          args.header.html = moment(args.header.start.value).format('ddd<BR>DD');

          args.header.html = '<span style="text-align: center;">' + args.header.html + '</span>';
        

        var dayOfWeek = args.header.start.getDayOfWeek();
        if (dayOfWeek === 0 || dayOfWeek === 6) {
          args.header.backColor = "#b3b1b1";
          args.header.cssClass = "weekend_header";
        }
      }
    },

    onEventResized: args => {
      console.log('onEventResized: ', args);
      this.onEventResized.emit({
        data: args.e.data,
        newStart: args.newStart.value,
        newEnd: args.newEnd.value
      });
    },

    onEventClicked: args => {
      console.log('onEventClicked: ', args);
      this.onEventClicked.emit(args.e.data);
    },

    eventClickHandling: args => {
      console.log('eventClickHandling: ', args);
    },

    onTimeRangeSelected: args => {
      console.log('onTimeRangeSelected: ', args);
      
      if(args.resource.indexOf('UN') >= 0) {
        var infoResource = this.getInfoResource(args.resource);
        console.log('infoResource', infoResource);

        this.onTimeRangeSelected.emit({ start: args.start.value, end: args.end.value, unitId: args.resource, infoResource: infoResource });
      }

      if( args.resource.indexOf('CAT') >= 0 ) {

        var infoResource = this.getInfoResource(args.resource);

        this.onTimeRangeSelected.emit({ start: args.start.value, end: args.end.value, category: infoResource.category });
      }
      
      args.control.clearSelection();
    },

    onEventMoved: args => {
      console.log('onEventMoved: ', args);
      if (args.e.data.reservation) { }
      this.onEventMoved.emit({
        data: args.e.data,
        newStart: args.newStart.value,
        newEnd: args.newEnd.value,
        newUnitId: args.newResource,
        newCategory: this.getParentFromUnitId(args.newResource),
        newUnit: this.getParentFromUnitId(args.newResource, 'unit'),
      });
    },

  };


 
  ngOnInit() { 

    

  }

  ngAfterViewInit(): void { }

  public initializeScheduler() {

    //console.log(this.translate.currentLang);
    

    setTimeout(() => {

      const innerHeight= window.innerHeight;


      this.configScheduler.days = this.quantityDays;
      this.configScheduler.startDate = moment(this.selectStartDate).subtract(1, 'days').format("YYYY-MM-DD");
      this.configScheduler.timeline = this.getTimeline();
      this.configScheduler.resources = this.resources;
      this.configScheduler.locale = this.translate.currentLang == 'pt_br' ? 'pt-br' : 'en-us';

      //console.log('res,', this.resources);
      // this.resources.forEach(element => {
      //   if(element.building) {
      //     if(element.building.name.length > 25) {

      //     }

      //   }
      // });
      this.configScheduler.rowHeaderWidth = (this.isSmallScreen ? 95 : 160)
      this.configScheduler.height = (this.isSmallScreen ? innerHeight - 250 : innerHeight - 330 );

      //console.log('innerHeight', innerHeight);

      this.configAsInit = true;
     

      if(this.scheduler == null){
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(DayPilotSchedulerComponent);
        const viewContainerRef = this.adHost.viewContainerRef;
        viewContainerRef.clear();
        const componentRef = viewContainerRef.createComponent(componentFactory);
        this.scheduler = (<DayPilotSchedulerComponent>componentRef.instance);
      }

      this.scheduler.config = this.configScheduler;
      this.scheduler.events = this.events;

   
    }, 10);


  }

  getTimeline(date?: DayPilot.Date | string): any[] {
    var date = date || new DayPilot.Date(moment(this.selectStartDate).subtract(1, 'days').format("YYYY-MM-DD"));
    var start = new DayPilot.Date(date);//new DayPilot.Date(date).firstDayOfMonth();
    var days = this.quantityDays || 10;// start.daysInMonth();
    var timeline = [];
    var checkin = 12;
    var checkout = 12;

    for (var i = 0; i < days; i++) {
      var day = start.addDays(i);
      timeline.push({ start: day.addHours(checkin), end: day.addDays(1).addHours(checkout) });
    }

    return timeline;
  }

  getParentFromUnitId(unitId, parentType = 'cat'): any {
    var retValue = null;

    this.resources.forEach(firstItem => {

      if(firstItem.id.indexOf('BUI') == 0) {

        firstItem.children.forEach(category => {
          //Se encontrar a unidade dentro dessa categoria
          var unit = category.children.find(i => i.id == unitId);
          if (unit != null) {
            retValue =  (parentType == 'cat') ? category : unit;
            return;
          }
        });


      } else {

        //Se encontrar a unidade dentro dessa categoria
        var unit = firstItem.children.find(i => i.id == unitId);
        if (unit != null) {
          retValue =  (parentType == 'cat') ? firstItem : unit;
          return;
        }

      }
     
    });


    return retValue;
  }


  tariffsNotFound(unitId, start): boolean {
    var returnValue = false;
    var category = this.getParentFromUnitId(unitId);
    if (category !== null) {
      if (category.tariffs) {
        var tariff = category.tariffs.find(i => i.date.substring(0, 10) == start.substring(0, 10));
        if (tariff == null) {
          //No chanells
          return true;
        }
      }
    }
    return false;
  }


  getTextColor(res) {
    switch (res.reservationStatus) {
      case 'PRE_RESERVED':
        return '#262626';
      case 'FULL_PAYMENT':
        return '#262626';
      case 'NO_PAYMENT':
        return '#262626';
      case 'PENDING_PAYMENT':
        return '#262626';
      default:
        return '#262626';
    }
  }

  getInfoResource(parentResource) {

    //unitId = 'CAT' + unitId.toString();

    var unit;
    console.log('this.resources', this.resources);
    this.resources.forEach(firstItem => {

      if(firstItem.id.indexOf('BUI') == 0) {

        console.log('has building...');
        console.log('parentResource..', parentResource);

        if(parentResource.indexOf('CAT') >= 0) {
          var categoryLocalized = firstItem.children.find(i => i.id == parentResource);
          if (categoryLocalized != null) {
            unit = {
              categoryId: categoryLocalized.category.categoryId,
              category: categoryLocalized.category,
              unity: null
            }
          }
        }

        if(parentResource.indexOf('UN') >= 0) {

          firstItem.children.forEach(secItem => {

            var unitLocalized = secItem.children.find(i => i.id == parentResource);
            if (unitLocalized != null) {
              unit = {
                categoryId: unitLocalized.categoryId,
                unity: unitLocalized.unity
              }
            }
  
          });

        }


        

      } else {

        if(parentResource.indexOf('CAT') >= 0) {
          if(firstItem.id == parentResource) {
            console.log('firstItem', firstItem);
            unit = {
              categoryId: firstItem.category.categoryId,
              category: firstItem.category,
              unity: null
            }
          }
        }

        if(parentResource.indexOf('UN') >= 0) {
          var unitLocalized = firstItem.children.find(i => i.id == parentResource);
          if (unitLocalized != null) {
            unit = {
              categoryId: unitLocalized.categoryId,
              category: unitLocalized.category,
              unity: unitLocalized.unity
            }
          }
        }

        
      }

      
    });
    return unit;
  }

}

