import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from "@angular/core";
import * as moment from "moment";
import { ReservationResponsableService } from "./reservation-responsable.service";
import { ActivatedRoute, Router } from "@angular/router";
import { ReservationsService } from "../reservations/reservations.service";
import { CPFValidator, EmailValidator, CNPJValidator } from "@tools/Validator";
import { ReservationPost } from '@app/types/Reservation'; // Type
import { translateOrigin, translatedOriginToCode } from '@utils/helpers'
import { NotifierService } from 'angular-notifier';
import masks from '@utils/masks'
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from '@src/app/common-service';
import Swal from 'sweetalert2';
import { MatDialog } from "@angular/material";
import { SharedAnimations } from "@src/app/shared/animations/shared-animations";
import { CountryCode } from 'libphonenumber-js'
import { AppStateService } from "@src/app/layouts/app-layout.service";

const countries = require('@utils/datas/countries.json')

declare var $: any;

const initialResponsibleAddress = {
        addressId: null,
        zipcode: '',
        country: null,
        city: '',
        state: '',
        street: '',
    };



interface Responsible
{
    id?: number;
    responsibleId?: number,
    name: string;
    documentNumber: string;
    email: string;
    phoneNumber: string;
    birthDate?: string;
    foundationDate?: string;
    documentType: any;
    documentTypeName?: string;
    docTypeStr?: any 
}

@Component({
    selector: "app-reservation-responsable",
    templateUrl: "./reservation-responsable.component.html",
    styleUrls: [
        "./reservation-responsable.component.css",
    ],
    animations: [SharedAnimations]
})
export class ReservationResponsableComponent implements OnInit
{
    loading: boolean = false;

    currentTemplateModal: any;

    @Input()
    currentReservation: any;

    @Input()
    responsableAutoEdit: boolean;

    @Input() 
    @Output()
    effect: boolean;

    @Output()
    onExitClicked: EventEmitter<any> = new EventEmitter();

    @Output()
    onNextClicked: EventEmitter<any> = new EventEmitter<any>();

    @Output()
    onSaveResponsible: EventEmitter<any> = new EventEmitter();

    @ViewChild("createResponsableModal")
    createResponsableModal: any;

    translateOrigin = translateOrigin;

    reservationResponsible: Responsible = {
        id: 0,
        name: "",
        documentNumber: "",
        email: "",
        phoneNumber: "",
        birthDate: "",
        foundationDate: "",
        documentType: 0,
        docTypeStr: ""
    };

    // Address
    responsibleAddress = Object.assign({}, initialResponsibleAddress)
    countries = countries

    newReservationResponsible: Responsible = {
        name: "",
        documentNumber: "",
        email: "",
        phoneNumber: "",
        documentType: 0,
    };
    responsableSearch: any = null;

    //  FILTROS BUSCA DE RESPONSAVEIS
    documentTypes: any[];
    nacionalityOptions: any;
    docNumber: any;
    selectDocType: any;
    selectNacionality: any;

    addResponsableDocType: any;

    // MODELS DOS FORMULARIOS DE ADD GUEST
    addUserCpfFormModel: any = {};
    addUserCnpjFormModel: any = {};
    addUserForeignFormModel: any = {};
    invalidResponsible: boolean;
    formStep2: any;
    selectedResponsable: any;
    readOnly: any;
    searchEmail: any;
    isEditing: any;
    alreadySearched: any;
    emailExists: any;
    emailValid: boolean;
    documentExists: boolean = false;
    invalidCpf: boolean = false;
    invalidEmail: boolean = false;
    invalidSearchCpf: boolean = false;
    cpfMask: any = [/\d/,/\d/,/\d/,".",/\d/,/\d/,/\d/,".",/\d/,/\d/,/\d/,"-",/\d/,/\d/];
    cnpjMask: any = [/\d/,/\d/,".",/\d/,/\d/,/\d/,".",/\d/,/\d/,/\d/,"/",/\d/,/\d/,/\d/,/\d/,"-",/\d/,/\d/];
    passaportMask: any = [/[a-zA-Z]/,/[a-zA-Z]/,/\d/,/\d/,/\d/,/\d/,/\d/,/\d/];
    masks = masks
    celMask = masks.phone
    enteringContactData: boolean = false;
    contactAlredyLong: boolean = false;
    cepAutoComplete: any;
    disableAddResponsible: boolean = false;
    loadingInitialData: boolean = true;

    @ViewChild('phoneSelect') phoneSelect;

    constructor(
        private appStateService: AppStateService,
        private reservationsService: ReservationsService,
        private reservationRespService: ReservationResponsableService,
        private notifier: NotifierService,
        private router: Router,
        public route: ActivatedRoute,
        public translate: TranslateService,
        private commonService: CommonService,
        public dialog: MatDialog,
        public dialogView: MatDialog,
    ) {    
        this.route.params.subscribe(params => { 

            var reservationId = params['reservationId'];
            if(!this.currentReservation && reservationId){
                this.loadReservation(reservationId);
            }

        });
    }

    ngOnInit()
    {
        // this.currentReservation = JSON.parse(sessionStorage.getItem("currentReservation"));
        // this.currentReservation = JSON.parse(sessionStorage.getItem("currentReservation"));        


        this.formStep2 = false;
        this.readOnly = false;
        this.isEditing = false;
        this.alreadySearched = false;
        this.emailExists = false;
        this.emailValid = true;

        this.documentTypes = [
            {
                value: 0,
                label: "CPF"
            },
            {
                value: 1,
                label: "CNPJ"
            },
            {
                value: 2,
                label: this.translate.instant('passport')
            },
            // {
            //     value: 3,
            //     label: "Sem documento"
            // },
            {
                value: 4,
                label: "E-mail"
            }
        ];

        if (this.currentReservation && this.currentReservation.reservationId)
        {
            this.loadReservation(this.currentReservation.reservationId);
            this.getReservationResponsables(this.currentReservation.reservationId);
        } else {
            this.loadingInitialData = false;
        }

    }

    noDocument: boolean = false;

    loadReservation(reservationId){
        this.loading = true;
            this.reservationsService.getReservationInfo(reservationId).subscribe(data =>
            {
                this.loading = false;
                console.log("GetReservationInfo", data.object);
                switch (data.code)
                {
                    case 200:
                        this.currentReservation = data.object;
                        this.currentReservation = {
                            reservationId: data.object.reservationId,
                            checkin: moment(data.object.checkinDate, 'YYYY-MM-DD').format("DD/MM/YYYY"),
                            checkout: moment(data.object.checkoutDate, 'YYYY-MM-DD').format("DD/MM/YYYY"),
                            finalDate: moment(data.object.finalDates[0], 'YYYY-MM-DD').format("DD/MM/YYYY"),
                            price: data.object.price,
                            category: data.object.units[0].category,
                            unit: data.object.units[0].name,
                            channel: data.object.channel,
                            salesChannel: data.object.salesChannel,
                            guestQuantities: data.object.guestsQuantity,
                            documentType: data.object.responsible ? data.object.responsible.documentType : null,
                        };

                        this.noDocument = this.currentReservation.documentType == 'NO_DOCUMENT';
                        break;
                }
            }, error => { console.log("Error"); this.loading = false; } );
    }

    closeCurrentTemplate(){
        this.currentTemplateModal = null;
        window.scroll(0,0);
    }

    onDocumentTypeChange(newReservationResponsible) {
        //this.noDocument = newReservationResponsible.documentType == 'NO_DOCUMENT';
        //debugger;
    }

    getReservationResponsables = reservationId =>
    {
        this.loading = true;
        this.reservationRespService.getReservationResponsables(reservationId, false, false).subscribe(data =>
            {
                console.log('getReservationResponsables', data);
                switch (data.code)
                {
                    case 200:
                        this.reservationResponsible = data.object;

                        this.noDocument = this.reservationResponsible.documentType == 'NO_DOCUMENT';

                        if (this.reservationResponsible.birthDate)
                            this.reservationResponsible.birthDate = moment(this.reservationResponsible.birthDate).format("YYYY-MM-DD");
                        if (this.reservationResponsible.foundationDate)
                            this.reservationResponsible.foundationDate = moment(this.reservationResponsible.foundationDate).format("YYYY-MM-DD")

                        this.reservationRespService.getResponsibleAddress(this.reservationResponsible.responsibleId).subscribe(
                            data => {
                                console.log('address', data)
                                if(data.object) {
                                    let address = data.object
                                    this.responsibleAddress = address;
                                }
                                else{
                                    this.responsibleAddress = initialResponsibleAddress;
                                }
                            }, error => {
                            })

                        if(this.responsableAutoEdit == true) {
                            this.editResponsableDetails(this.createResponsableModal);
                        }

                        break;
                }
                this.loadingInitialData = false;
                this.loading = false;
            }, error => {
                this.loadingInitialData = false;
                this.loading = false;
                console.log("Error");
                }
            );
    };

    addNewResponsable() {
        // TODO:
        // Check if this method have some behaviour
    }

    resetResponsableSearch(){
        this.responsableSearch = null;
        this.alreadySearched = false;
    }

    openSearchResponsable = (templateRef: TemplateRef<any>) =>
    {
        this.selectDocType = "todos";
        this.selectNacionality = "todos";
        this.loading = false;
        this.responsableSearch = null;
        this.alreadySearched = false;
        this.invalidSearchCpf = false;
        this.docNumber = "";
        this.disableAddResponsible = false;

        this.currentTemplateModal = templateRef;
        window.scroll(0,0);
    };

    openDialogWithTemplateRef(templateRef: TemplateRef<any>) {
        this.dialog.open(templateRef);
    }

    
    openDetails(dialogTemplate){
        this.dialogView.open(dialogTemplate);
    }

    cancelSearch = () => this.responsableSearch = [];


    onDocumentKeyUp(event) {

        if(!this.docNumber || this.selectDocType == 'todos' || !this.emailValid){
            return;
        }

        if (event.keyCode == 13) {
          this.searchReponsable();
        }
    }


    searchReponsable = () =>
    {
        this.invalidSearchCpf = false;
        this.selectedResponsable = false;

        if (this.selectDocType == 0 && !CPFValidator(this.docNumber))
            return this.invalidSearchCpf = true;

        if (this.selectDocType != "todos" && this.docNumber)
        {
            // Replace mask if it is CNPJ or CPF document
            if (this.selectDocType < 2)
                this.docNumber = this.docNumber.replace(".", "").replace(".", "").replace("-", "").replace("/", "");
            
            console.log("DocType", this.selectDocType);
            console.log("Docnumber", this.docNumber);

            this.loading = true;

            this.reservationRespService.getReservationResponsible(this.selectDocType, this.docNumber).subscribe(data =>
            {
                console.log("Responsabel Search Result: ", data);
                switch (data.code)
                {
                    case 200:
                        this.loading = false;
                        this.responsableSearch = data.object;
                        this.alreadySearched = true;
                        break;
                    default:
                        this.loading = false;
                        this.responsableSearch = null;
                        this.alreadySearched = true;
                        break;
                }
            }, error => console.log("Error", error));
        }
        else
        {
            this.loading = false;
            this.alreadySearched = false;
            this.responsableSearch = null;
        }
    };

    createResponsable = (createResponsableModal) =>
    {
        this.newReservationResponsible = {
            name: "",
            documentNumber: "",
            email: "",
            phoneNumber: "",
            documentType: this.selectDocType != 'todos' ? this.selectDocType : 0
        };

        this.readOnly = false;
        this.isEditing = false;
        this.emailExists = false;
        this.formStep2 = false;
        this.addUserCpfFormModel = {};
        this.addUserCnpjFormModel = {};
        this.invalidCpf = false;
        this.invalidResponsible = false;
        this.invalidEmail = false;
        this.disableAddResponsible = false;
        this.loading = false;

        this.newReservationResponsible.documentNumber = this.docNumber;
        this.responsibleAddress = Object.assign({}, initialResponsibleAddress)
        this.currentTemplateModal = createResponsableModal;
        window.scroll(0,0);

        //this.phoneSelect.
    };

    AddSelectDocType = docType =>
    {
        this.addResponsableDocType = docType;
        this.formStep2 = false;
    };

    saveResponsable = () =>
    {
        if (this.readOnly)
            return this.dialog.closeAll();

        this.disableAddResponsible = true;

        let invalid = false;

        const { documentNumber, documentType, email, name, phoneNumber } = this.newReservationResponsible;

        // Replace mask if it is CNPJ or CPF document
        // if (documentType < 2)
        //     this.newReservationResponsible.documentNumber = documentNumber.replace(".", "").replace(".", "").replace("-", "").replace("/", "");
        
        if (documentType == 4)
            this.newReservationResponsible.email = documentNumber;

        if (documentType == 0 && !CPFValidator(documentNumber))
        {
            this.invalidCpf = true;
            invalid = true;
        }
        else if (documentType == 1 && !CNPJValidator(documentNumber))
        {
            this.invalidCpf = true;
            invalid = true;
        }
        else if (documentType == 4 && !EmailValidator(documentNumber))
        {
            this.invalidEmail = true;
            invalid = true;
        }

        if (documentType < 4 && !EmailValidator(email))
        {
            this.invalidEmail = true;
            invalid = true;
        }

        if (name == '' || phoneNumber == '')
        {
            this.invalidResponsible = true;
            invalid = true;
        }

        if (invalid){
            this.disableAddResponsible = false;
            return;
        }

        this.loading = true;

        let body;
        if(this.responsibleAddress.country) {
            body = {
                ...this.newReservationResponsible,
                address: {
                    ...this.responsibleAddress
                }
            }
        } else {
            body = this.newReservationResponsible
        }

        body.phoneNumber = body.phoneNumber.replace(/\s/g, "")

        console.log('the body', body)

        this.reservationRespService.createResponsible(body).subscribe(data =>
        {
            this.disableAddResponsible = false;
            console.log(data);
            //this.currentReservation = JSON.parse(sessionStorage.getItem("currentReservation"));
            // 144
            switch (data.code)
            {
                case 200:
                
                    this.closeCurrentTemplate();
                    
                    this.noDocument = false;
                    if(this.isEditing) {
                        setTimeout(() => {
                            Swal.fire(
                                this.translate.instant('reservation_responsable.responsable_edit'),
                                this.translate.instant('reservation_responsable.edit_responsable_success'),
                                'success');
                        }, 500);

                        this.onSaveResponsible.emit();
                    }
                    else{

                        // Swal.fire(
                        //     this.translate.instant('reservation_responsable.responsable_create'),
                        //     this.translate.instant('reservation_responsable.add_responsable_success'),
                        //     'success');

                        if (this.reservationResponsible.id != 0)
                            this.assignResponsibleToReservation(data.object.responsibleId);
                        else if (this.effect == true)
                            this.confirmPreReservation(data.object.responsibleId);
                        else
                            this.makeReservation(data.object.responsibleId);

                    }
                   
                    this.disableAddResponsible = false;
                    break;
                case 209:
                    this.loading = false;
                    this.emailExists = true;
                    break;
                case 240:
                    this.loading = false;
                    this.documentExists = true;
                    break;
                }
                
            }, error => {
                this.loading = false;
                console.log("Error", error)
            }
        );
    };

    enableButton = () =>
    {
        this.emailExists = false;
        this.invalidCpf = false;
        this.invalidResponsible = false;
        this.invalidEmail = false;
        this.disableAddResponsible = false;
    }

    goToFormStep2 = () => this.formStep2 = true;

    backToStep1 = () => this.formStep2 = false;

    showSelectResponsableSucesss(){
        Swal.fire(
            this.translate.instant('reservation_responsable.dados_do_responsavel_financeiro'),
            this.translate.instant('reservation_responsable.select_responsable_success'),
            'success');
    }


    makeReservation = responsibleId =>
    {
        const reservation: ReservationPost = {
            initialDate: moment(this.currentReservation.checkin, "DD/MM/YYYY").format("YYYY-MM-DDTHH:mm:ss"),
            finalDate: moment(this.currentReservation.finalDate, "DD/MM/YYYY").format("YYYY-MM-DDTHH:mm:ss"),
            unitIds: this.currentReservation.unitIds,
            guestQuantities: [ this.currentReservation.guestQuantities ],
            channelId: this.currentReservation.channel.channelId,
            reservationPrice: this.currentReservation.price,
            responsibleId: responsibleId,
            salesChannel: this.currentReservation.salesChannel
        };

        console.log("sending", reservation);

        this.loading = true;
        this.reservationsService.makeReservation(reservation).subscribe(
            (data:any) =>
        {
            this.loading = false;
            console.log("Criando Reserva", data);
            switch (data.code)
            {
                case 200:
                    //aqui devo verificar multi reserva
                    if (data.object.length == 1)
                    {
                        sessionStorage.setItem("currentReservation", JSON.stringify(data.object[0]));
                        this.currentReservation.reservationId = data.object[0].reservationId;
                        this.getReservationResponsables(data.object[0].reservationId);
                        this.currentReservation.reservationId = data.object[0].reservationId;
                        this.closeCurrentTemplate();


                        setTimeout(() => this.showSelectResponsableSucesss(), 500);
                    }
                    else
                    {  
                        setTimeout(() =>
                        { 
                            this.showSelectResponsableSucesss();

                            setTimeout(() =>
                            {
                                this.closeCurrentTemplate();
                                //this.router.navigate([`app/${this.appStateService.currentEstablishmentId}/reservations`]);
                                this.onExitClicked.emit();
                            }, 1000);
                        }, 500);
                    }
                    this.disableAddResponsible = false;
                    break;
                case 256:

                    this.closeCurrentTemplate();
                    setTimeout(() => $("#modalWarningOTA").modal("show"), 500);
                    break;

                case 232:
                    this.closeCurrentTemplate();
                    setTimeout(() => $("#modalWarningDates").modal("show"), 500);
                    break;
                default:
                this.notifier.notify('error', data.message ? data.message : 'Erro inesperado')
                this.loading = false;
            }
            this.loading = false;
        }, error => {
            this.loading = false;
            console.log("Error")
    });
    }

    updateReservation = callback =>
    {
        console.log(this.currentReservation);
        const reservation = {
            reservationId: this.currentReservation.reservationId,
            dependentQuantity: this.currentReservation.guestQuantities,
        };

        console.log("sending", reservation);

        this.loading = true;
        this.reservationRespService.updateGuestsQuantity(reservation).subscribe(data =>
        {
            this.loading = false;
            console.log(data);
            switch (data.code)
            {
                case 200:
                    this.currentReservation.guestQuantities = this.currentReservation.guestQuantities;
                    sessionStorage.setItem("currentReservation", JSON.stringify(this.currentReservation));
                    callback();
                    break;
            }
        }, error => { console.log("Error"); this.loading = false;} );
    }

    assignResponsibleToReservation = responsibleId =>
    {
        const payload = {
            responsibleId: responsibleId,
            reservationId: this.currentReservation.reservationId
        };

        return this.reservationRespService.assignResponsableToReservation(payload).subscribe(data =>
        {
            switch (data.code)
            {
                case 200:
                    this.getReservationResponsables(this.currentReservation.reservationId);
                    this.closeCurrentTemplate();
                    setTimeout(() => this.showSelectResponsableSucesss(), 500);
                    this.disableAddResponsible = false;
                break;
            }
            this.loading = false;
        }, error => {
            this.loading = false;
            console.log("Error")
        });
    }

    setThisResponsable = responsibleId => this.selectedResponsable = responsibleId;

    selectResponsable = selectedResponsibleId =>
    {
        //troca o responsavel
        console.log("Selecionando responsável");

        this.disableAddResponsible = true;
        this.loading = false;

        if (this.reservationResponsible.id != 0)
            return this.assignResponsibleToReservation(selectedResponsibleId);
        else if (this.effect == true)
            return this.confirmPreReservation(selectedResponsibleId);
        else
            return this.makeReservation(selectedResponsibleId);
    };

    confirmPreReservation = responsibleId =>
    {
        this.loading = true;
        return this.reservationsService.confirmReservation(this.currentReservation.reservationId, responsibleId).subscribe(data =>
        {
            this.loading = false;
            console.log(data);
            switch (data.code)
            {
                case 200:
                    this.getReservationResponsables(this.currentReservation.reservationId);
                    this.effect = false;
                    sessionStorage.setItem("currentReservation", JSON.stringify(this.currentReservation));
                    this.closeCurrentTemplate();
                    setTimeout(() => {
                        Swal.fire(
                            this.translate.instant('reservation_responsable.effect'),
                            this.translate.instant('reservation_responsable.effect_success'),
                            'success');
                    }, 500);
                    this.disableAddResponsible = false;
                    break;
            }
        }, error => { console.log("Error"); this.loading = false; });
    }

    showResponsableDetails = (createResponsableModal) =>
    {
        this.loading = false;
        this.newReservationResponsible = this.reservationResponsible;
        const { documentType } = this.newReservationResponsible;

        if (documentType == 0 || documentType as any == "CPF")
            this.newReservationResponsible.documentTypeName = "CPF";
        else if (documentType == 1 || documentType as any == "CNPJ")
            this.newReservationResponsible.documentTypeName = "CNPJ";
        // else if (documentType == 2 || documentType as any == "FOREIGN")
        //     this.newReservationResponsible.documentTypeName = "Passaporte";
        else if (documentType == 2 || documentType as any == "FOREIGN")
            this.newReservationResponsible.documentTypeName = "E-mail";

        this.readOnly = true;
 
        this.currentTemplateModal = createResponsableModal;    
        window.scroll(0,0); 

        // switch(this.reservationResponsible.documentType)
        // {
        //     case 0:
        //         this.addUserCpfFormModel = this.reservationResponsible;
        //         this.addResponsableDocType = "cpf";
        //         break;
        //     case 1:
        //         this.addUserCnpjFormModel = this.reservationResponsible;
        //         this.addResponsableDocType = "cnpj";
        //         break;
        //     case 2:
        //         this.addUserForeignFormModel = this.reservationResponsible;
        //         this.addResponsableDocType = "estrangeiro";
        //         break;

        // }
    };

    editResponsableDetails = (createResponsableModal) =>
    {
        console.log(this.reservationResponsible);

        this.newReservationResponsible = this.reservationResponsible;
        this.newReservationResponsible.documentTypeName = this.newReservationResponsible.documentType as any;
        this.readOnly = false;
        this.isEditing = true;
        this.loading = false;

        if (this.newReservationResponsible.documentTypeName == "CPF")
            this.newReservationResponsible.documentType = 0;

        if (this.newReservationResponsible.documentTypeName == "CNPJ")
            this.newReservationResponsible.documentType = 1;
        
        if (this.newReservationResponsible.documentTypeName == "FOREIGN")
            this.newReservationResponsible.documentType = 2;

        if (this.newReservationResponsible.documentTypeName == "EMAIL")
            this.newReservationResponsible.documentType = 4;

        this.currentTemplateModal = createResponsableModal;
        window.scroll(0,0);
    };

    goBack = () => this.onExitClicked.emit();

    goToPaymentStep = () => this.updateReservation(() => this.onNextClicked.emit(this.currentReservation));

    clearDocNumber = () =>
    {
        this.docNumber = "";
        this.emailValid = true;
    };

    cepAutocomplete = cep =>
    {
        cep = cep.replace(/\D/g, '')
        if (cep && cep.length == 8)
        {
            this.commonService.checkZipCode(cep).subscribe(ret => {
				console.log(ret);
				this.loading = false;
				if (ret.erro == true) {
					console.log('CEP Inválido!');
				} else {
					this.responsibleAddress.city = ret.localidade;
					this.responsibleAddress.state = ret.uf;
					this.responsibleAddress.street = `${ret.logradouro}, ${ret.bairro}`;
				}
			}, err => {
				this.loading = false;
				this.translate.instant('error_msg.cep_not_found');
			});
        }
    };

    // cancelReservation = () =>
    // {
    //     sessionStorage.setItem("currentReservation", null);
    //     $("#modalConfirmLeaveReservation").modal("hide");
    //     return this.router.navigate([`app/${this.appStateService.currentEstablishmentId}/reservations`]);
    // };

    cancelReservation(){
        this.onExitClicked.emit();
    }


    callValidaCPF = cpf => (this.invalidCpf = this.reservationRespService.validaCPF(cpf));

    validEmail = () =>
    {
        if (this.selectDocType != 4) return true;

        const regex = /^(([^<>()\[\]\\.,;:\s@`]+(\.[^<>()\[\]\\.,;:\s@`]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        this.emailValid = regex.test($("#input_email").val());
    };

    reValidEmailData = () =>
    {
        if(this.invalidEmail == true){
            this.invalidEmail = !EmailValidator($("#emailData").val());
        }
        
    };

    validContact = () =>
    {
        // const input = $("#responsible-contact");
        // const inputLength = input.val().replace(/\D+/g, '').length;

        this.enteringContactData = true;

        setTimeout(() => $("#responsible-contact").focus(), 300);
    }
}
