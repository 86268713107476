import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ResponseFormat } from '@src/app/types/response-format';
import { Observable } from 'rxjs';

@Injectable()
export class NatDataService {

	constructor(private http: HttpClient) {
	}

	getCities(searchText: string): Observable<any> {
		return this.http.get(`vivakey/rest/embratur/ibgecity?_offset=0&_limit=20&likename=${searchText}`);
	}


	getReasons(searchText: string): Observable<any> {
		return this.http.get(`vivakey/rest/embratur/reason`);
	}

	getTransports(searchText: string): Observable<any> {
		return this.http.get(`vivakey/rest/embratur/transport`);
	}

	getCountries(searchText: string): Observable<any> {
		return this.http.get(`vivakey/rest/embratur/cbcountry`);
	}


	postEmbraturData(embratur_data) {
		return this.http.post<ResponseFormat>(`vivakey/rest/embratur`, embratur_data)
	}

}
