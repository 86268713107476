import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ResponseFormat } from '@src/app/types/response-format';

@Injectable()
export class ReservationsListService
{

	constructor(private http: HttpClient) 
	{ 
	}

	mapParams(_params) {
		let params = new HttpParams();
		Object.keys(_params).map((key, index) => {
			params = params.append(key, _params[key])
		})
		return params;
	}

	get = (establishmentId, _params) => {
		return this.http.get<ResponseFormat>(`vivakey/rest/client-wallet/by/establishment/${establishmentId}`, {
			params: this.mapParams(_params)
		})
	}

	getReservations( _params) {
		//?establishmentId=1&checkinDate=2019-09-25&responsibleName=Mario&mainGuest=Mario
		return this.http.get<ResponseFormat>(`vivakey/rest/reservation/byCheckinResponsibleOrMainGuest`, {
			params: this.mapParams(_params)
		})
	}

	getReservationsByAuthorizationId(establishmentId, filters) {
		return this.http.get<ResponseFormat>(`vivakey/rest/reservation/findByReservationOrBuklyId?establishmentId=${establishmentId}&filters=${filters}`)
	}


	getReservationSummaryInfo(reservationId ) {
		return this.http.get<ResponseFormat>('vivakey/rest/reservation/getReservation/details/' + reservationId);
	}


	saveOptionalEmail(reservationId, establishmentId, emailOptional){
		return this.http.put<ResponseFormat>(`vivakey/rest/responsible/reservation/${reservationId}/${establishmentId}`, { emailOptional: emailOptional });
	}

	sendEmail(reservationId, establishmentId){
		return this.http.post<ResponseFormat>(`vivakey/rest/reservation/${reservationId}/${establishmentId}/sendEmailLocator`, {});
	}

}
