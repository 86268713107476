import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { gData } from './g-data';

@Pipe({ name: 'groupBy' })
export class GroupByPipe implements PipeTransform {
    constructor(public translate: TranslateService) { }
    transform(data: any[], args: any): gData[] {
        var ret: gData[] = [];
        if (data && args) {
            var groupName = args;
            data.forEach(element => {
                var g = ret.find(i => i.name == element[groupName]);
                if (g == null) {
                    g = new gData();
                    g.name = element[groupName];
                    g.itens = [];
                    g.itens.push(element);
                    ret.push(g);
                }
                else {
                    if (g.itens == null) {
                        g.itens = [];
                    }
                    g.itens.push(element);
                }
            });
            return ret;
        }
        return ret;
    }
}