import { Component, OnInit, Input, ViewChild, TemplateRef, Output, EventEmitter } from '@angular/core';
import { ReservationPaymentService } from './reservation-payment.service';
import { Router } from '@angular/router';
import { ReservationsService } from '../reservations/reservations.service';
import { NotifierService } from "angular-notifier";
import * as moment from 'moment';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { GroupReservationForPay, paymentInstalments, GroupForPay, GroupForReversal, paymentInstalments_Reversal } from '@src/app/types/GroupReservation';
import { MatDialogRef, MatDialog } from '@angular/material';
import { ReservationPaymentModalComponent } from '@src/app/components/views/reservation-payment-modal/reservation-payment-modal.component';
import { ReservationPaymentReversalModalComponent } from '@src/app/components/views/reservation-payment-reversal-modal/reservation-payment-reversal-modal.component';
import { UsersService } from '../users/users.service';
import { error } from 'protractor';
import { translateOrigin, translatedOriginToCode } from '@utils/helpers'
import { SharedAnimations } from '@src/app/shared/animations/shared-animations';
import { AppStateService } from '@src/app/layouts/app-layout.service';


declare var $ :any;

@Component({
    selector: 'app-reservation-payment',
    templateUrl: './reservation-payment.component.html',
    styleUrls: [
        './reservation-payment.component.css'
    ],
    animations: [SharedAnimations]
})

export class ReservationPaymentComponent implements OnInit
{
    constructor(
        private appStateService: AppStateService,
        public translate: TranslateService,
        private reservationsService: ReservationsService,
        private reservationPaymentService: ReservationPaymentService,
        private notifier: NotifierService,
        private router: Router,
        public dialog: MatDialog,
        public usersService: UsersService
    ) {
        this.currMask =  { prefix: translate.currentLang == 'en' ? '$' : 'R$', suffix: '', thousands: '.', decimal: ',', align : 'left', allowNegative : 'false' };
     }


    @Output()
    onExitClicked: EventEmitter<any> = new EventEmitter();

    
    @Output()
    onPreviousClicked: EventEmitter<any> = new EventEmitter<any>();

    @Output()
    onNextClicked: EventEmitter<any> = new EventEmitter<any>();


    @Input()
    groupReservationForPay: GroupReservationForPay;
    payments_AllList: any[] = [];
    payments_AllList_Reversal: any[] = [];

    @Input()
    currentReservation : any;

    curentReservationModel : any = {};
    priceAditionalFee : any = [];

    selectedAdditionalFee: any;
    selectedAdditionalFeeId: any;
    
    @ViewChild("txtPriceAditionalFee")
    txtPriceAditionalFee;

    descriptionAditionalFee : any = [];

    reservationValue: number = 0;
    //parcelas
    //reservationInstalments : any = [];
    parcelableInstalmentsList : any = [];
    //reservationAddFee : any[] = [];

    payments_showInfo: any[] = [];

    additionalFees: any[] = [];
    banksArray: any = [];
    payingStalments : any = [];
    payingExtraFee : any = [];
    selectedSomethingToPay : number = 0;
    sendingValue : number = 0;
    reversalValue : number = 0;
    firstNotPaiedInstalment : number = 0;
    bankSelectWarn : boolean = false;
    outValue : boolean = false;
    disabledInstallment: boolean = false;
    disabledButton: boolean;

    loading: boolean = false;

    accessCode: string = null
    hasMagikeyDevice: boolean;
    isManager: boolean = false;

    translateOrigin = translateOrigin;

    currMask;

    ckeditor_config = {
        language: this.translate.currentLang == 'pt_br' ? 'pt-br ' : 'en-us',
        removePlugins: 'elementspath,save',
        toolbar: [] 
        };


    @ViewChild("reservationPaymentModal") reservationPaymentModal: ReservationPaymentModalComponent;
    @ViewChild("reservationReversalModal") reservationReversalModal: ReservationPaymentReversalModalComponent;


    currentTemplateModal: any;

    ngOnInit()
    {
        this.curentReservationModel.Totalprice = 0;
        this.isManager = this.usersService.isManager();

        this.reservationsService.getReservationInfo(this.currentReservation.reservationId).subscribe(data =>
        {
            switch (data.code)
            {
			    case 200:
                    this.currentReservation = data.object;
                    this.selectReservation(data.object);

                    console.log('groupReservation:', data.object.groupReservation);
                    this.setReservations(data.object.groupReservation);
                    this.loadFullPaymentInfo();
                    break;
			}
        }, error => console.log("Error"));

		const establishment = this.appStateService.currentEstablishment;
		this.getBanks(establishment.establishmentId);
		this.getReservationPaymentInfo();
		//this.getReservationFullPaymentInfo();
        this.getInstalmentModalInfo();


        var refreshPaymentsInterval =  setInterval(() =>
        {
            // Se não estiver na reserva, para o interval
            if (window.location.pathname != "/reservations/payment")
             return clearInterval(refreshPaymentsInterval);

             if(this.dialog.openDialogs.length <= 0){
                this.loadPaymentsInfo();
                this.getReservationPaymentInfo();
             }

        }, 600000);
    }

    openDialog(templateRef: TemplateRef<any>) {
        this.dialog.open(templateRef, { disableClose: true });
    }
    
    loadPaymentsInfo(){
        this.payments_showInfo = [];
        this.reservationPaymentService.getPaymentInfo(this.groupReservationForPay.groupReservationId).subscribe(data =>
        {
            this.setPaymentsInfo(data);
        }, 
        error =>{});  
    }

    setPaymentsInfo(data) {
        if (data.object) {
            this.groupReservationForPay.reservations.forEach(res => {
                var element = data.object[res.reservationId];
                if (element.paymentAdditionalFee) {
                    element.paymentAdditionalFee.forEach(payment => {
                        if (payment.groupPaymentStatus === 3) {
                            //Pagamento Recusado, adicionar às lista de avisos
                            this.payments_showInfo.push(payment);
                        }
                    });
                }

                if (element.paymentInstalments) {
                    element.paymentInstalments.forEach(payment => {
                        if (payment.groupPaymentStatus === 3) {
                            //Pagamento Recusado, adicionar às lista de avisos
                            this.payments_showInfo.push(payment);
                        }
                    });
                }
            });
        }
    }

    setReservations(groupReservation) {

        this.groupReservationForPay = new GroupReservationForPay();
        this.groupReservationForPay.groupReservationId = groupReservation.groupReservationId;
        this.groupReservationForPay.buklyId = groupReservation.buklyId;
        this.groupReservationForPay.virtualCard = groupReservation.virtualCard;
        this.groupReservationForPay.hasPendingIssues = groupReservation.hasPendingIssues;
        this.groupReservationForPay.cardBlocked = groupReservation.cardBlocked;
        this.groupReservationForPay.isPaid = groupReservation.isPaid;
        this.groupReservationForPay.authorizationId = groupReservation.authorizationId;





        this.groupReservationForPay.reservations = groupReservation.reservations;
        this.groupReservationForPay.multipleReservations = groupReservation.reservations.length > 1 ? true : false;

        
    }

    selectReservation(data) {
        console.log("ReservationInfo::", data);
        const checkin = data.checkinDate.split("T")[0].split("-");
        const checkout = data.checkoutDate.split("T")[0].split("-");
        //somando um dia como enviamos um a menos
        // checkout = moment(checkout, "YYYY-MM-DD").add(1, 'day').format("YYYY-MM-DD");
        let unitStr = '';

        for (let i = 0; i < data.units.length; i++)
        {
            if (unitStr == '')
                unitStr += data.units[i].name;
            else
                unitStr += ', '+ data.units[i].name;
        }

        console.log('unitStr',unitStr);
        this.curentReservationModel.reservationId = data.reservationId;
        this.curentReservationModel.unit = unitStr;
        this.curentReservationModel.checkout = checkout[2]+'/'+checkout[1]+'/'+checkout[0];
        this.curentReservationModel.checkin = checkin[2]+'/'+checkin[1]+'/'+checkin[0];
        this.curentReservationModel.checkinResumed = checkin[2]+'/'+checkin[1];
        this.curentReservationModel.reserv = data.units[0].category.name+' - '+this.curentReservationModel.unit;
        this.curentReservationModel.categoryName = data.units[0].category.name;
        this.curentReservationModel.channel = data.channel.name;
        this.curentReservationModel.email = data.responsible.email;
        this.curentReservationModel.responsible = data.responsible;
        this.curentReservationModel.category = data.units[0].category;
        this.curentReservationModel.salesChannel = data.salesChannel;
        this.curentReservationModel.guestsQuantity = data.guestsQuantity;
    }

    // getAccountsCount(): Promise<any> {

    //     return new Promise((resolve, reject) => {

    getReservationPaymentInfo(): Promise<any>
    { 
        return new Promise((resolve, reject) => {
            this.loading = true;
            this.reservationPaymentService.getReservationPaymentInfo(this.currentReservation.reservationId).subscribe(data => {
                this.loading = false;
                switch (data.code) {
                    case 200:
                        var aditionalFeeValue = 0;
                        console.log(data);
                        this.curentReservationModel.pos = data.object.pos;
                        if (data.object.pos != undefined) {
                            for (let i = data.object.pos.length - 1; i >= 0; i--) {
                                for (let j = data.object.pos[i].additionalFees.length - 1; j >= 0; j--) {
                                    this.additionalFees.push(data.object.pos[i].additionalFees[j]);

                                    if (data.object.pos[i].additionalFees[j].paymentValue) {
                                        this.priceAditionalFee[data.object.pos[i].additionalFees[j].additionalFeeId] = data.object.pos[i].additionalFees[j].paymentValue;
                                        aditionalFeeValue += data.object.pos[i].additionalFees[j].paymentValue;
                                    }
                                    else {
                                        this.priceAditionalFee[data.object.pos[i].additionalFees[j].additionalFeeId] = 0;
                                    }

                                    if (data.object.pos[i].additionalFees[j].additionalDescription) {
                                        this.descriptionAditionalFee[data.object.pos[i].additionalFees[j].additionalFeeId] = data.object.pos[i].additionalFees[j].additionalDescription;
                                    }
                                    else {
                                        this.descriptionAditionalFee[data.object.pos[i].additionalFees[j].additionalFeeId] = '';
                                    }


                                }
                            }
                        }

                        if (data.object.unit !== null && (data.object.unit.hasTuyaDevices || data.object.unit.magikey || data.object.unit.smartlock)) { 
                            this.hasMagikeyDevice = true;
                        }

                        this.disabledInstallment = data.object.price == data.object.paidAmount;
                        this.curentReservationModel.guestQtd = data.object.guestNumber;
                        this.curentReservationModel.price = data.object.price;
                        this.curentReservationModel.Totalprice = data.object.price + aditionalFeeValue;
                        //tornando o valor pago negativo
                        this.curentReservationModel.paidAmount = -Math.abs(data.object.paidAmount);
                        this.curentReservationModel.paidAmountAbsolute = Math.abs(data.object.paidAmount);
                        this.curentReservationModel.aboutToPay = this.curentReservationModel.Totalprice + this.curentReservationModel.paidAmount;
                        break;
                }

                resolve("OK");
            }, error => {
                this.loading = false;
                console.log("Error", error);
                reject(error);
            });

        });
	}

    getBanks = establishmentId =>
    {
        this.reservationPaymentService.getBanks(establishmentId).subscribe(data =>
        {
            console.log("Informações", data.object);
            switch (data.code)
            {
				case 200:
                    for (let i = 0; i < data.object.length; i++)
                    {
                        const brand = (data.object[i].brand != "") ? ` - ${data.object[i].brand}` : "";
						const aux = {
							bankPaymentTypeId: data.object[i].bankPaymentTypeId,
							desc: data.object[i].bank.name + ' - ' + data.object[i].paymentType.name + brand,
							agreesInstalment:  data.object[i].paymentType.agreesInstalment
						}
						this.banksArray.push(aux);
					}
				    break;
			}
		}, error => console.log("Error", error));
	}

    additionalDescription(additionalFeeId) {
        if(this.payments_AllList){
            var additionalFee = this.payments_AllList.find(i=>i.additionalFeeId == additionalFeeId && i.reservationId == this.currentReservation.reservationId);
            return additionalFee && additionalFee.additionalDescription ? additionalFee.additionalDescription : '';
        }
        return '';
    }

    additionalFee_HasExtrasOnLine(additionalFeeId) {
        var additionalFee = this.additionalFees.find(i=>i.additionalFeeId == additionalFeeId);
        return additionalFee && additionalFee.extrasOnline == true ? true : false;
    }

    insertReservationInstalment(item: any) {

        item.online = item.online || false;

        if(this.groupReservationForPay.paymentInstalments == null){
            this.groupReservationForPay.paymentInstalments = [];
        }

        var name = '';
        var groupName = '';
        var paymentGroup: paymentInstalments;


        if(this.groupReservationForPay.reservations.length > 1) {
            groupName = item.online ? 'On-Line' : 'Balcão';
            name = groupName;

            if(item.online){
                paymentGroup = this.groupReservationForPay.paymentInstalments.find(i=>i.paymentStatus == item.paymentStatus && i.online == item.online);
            }
            else{
                if(item.instalmentId != null ) {
                    groupName = 'Valor da Reserva';
                    name = groupName;
                    groupName = groupName + item.instalmentId;
                }
                else {
                    groupName = item.name;
                    name = item.name;
                    groupName = groupName + item.reservationId + item.additionalFeeId;
                }
                
                paymentGroup = this.groupReservationForPay.paymentInstalments.find(i=>i.paymentStatus == item.paymentStatus && i.groupName == groupName);
            }
        }
        else {

            if(item.online){
                paymentGroup = this.groupReservationForPay.paymentInstalments.find(i=>i.paymentStatus == item.paymentStatus && i.online == item.online);
            }
            else{
                if(item.instalmentId != null ) {
                    groupName = 'Valor';
                    name = groupName;
                    groupName = groupName + item.instalmentId;
                }
                else {
                    groupName = item.name;
                    name = item.name;
                    groupName = groupName + item.additionalFeeId;
                }
            }
            
            paymentGroup = this.groupReservationForPay.paymentInstalments.find(i=>i.paymentStatus == item.paymentStatus && i.groupName == groupName);
        }

  
        if(paymentGroup == null){
            paymentGroup = new paymentInstalments(); 
            paymentGroup.payments = [];
            paymentGroup.groupName = groupName;
            paymentGroup.name = name;
            paymentGroup.releaseDate = item.releaseDate;
            paymentGroup.reservationId = item.reservationId;
            paymentGroup.instalmentNumber = item.instalmentNumber ? item.instalmentNumber : 1;

            paymentGroup.paymentStatus = item.paymentStatus;
            paymentGroup.online = item.online;
            paymentGroup.groupPaymentId = item.groupPaymentId;
            paymentGroup.billingLinkId = item.billingLinkId;
            paymentGroup.groupPaymentStatus = item.groupPaymentStatus;

            if(item.groupPaymentStatus == 4 || item.groupPaymentStatus == 6){ //4 = CANCELED  -  6 = CANCELED_BY_ATTEMPTS 
                //Apagar Grupo de pagmento para liberar novo pagamento
                paymentGroup.groupPaymentId = undefined;
                paymentGroup.groupPaymentStatus = undefined;
            }

            paymentGroup.payments.push(item);
            paymentGroup.totalPaymentValue = paymentGroup.totalPaymentValue + Number.parseFloat(item.paymentValue)

           
            if(item.instalmentId != null) {
                paymentGroup.instalment = this.countInstalment(item.reservationId) + 1;
            }

            if(item.instalmentId != null){
                paymentGroup.totalInstalmentsValue = paymentGroup.totalInstalmentsValue + item.paymentValue;
            } else {
                paymentGroup.totalAditionalFeeValue = paymentGroup.totalAditionalFeeValue + item.paymentValue;
            }

            if(item.bankPaymentType != null)
            {
                paymentGroup.selectedWayOfPayment = {
                    bankPaymentTypeId: item.bankPaymentType.bankPaymentTypeId,
                };
            }
            
            this.groupReservationForPay.paymentInstalments.push(paymentGroup);
        }
        else{

            if(item.instalmentId != null){
                paymentGroup.totalInstalmentsValue = paymentGroup.totalInstalmentsValue + item.paymentValue;
            } else {
                paymentGroup.totalAditionalFeeValue = paymentGroup.totalAditionalFeeValue + item.paymentValue;
            }

            if(paymentGroup.selectedWayOfPayment == null && item.bankPaymentType != null)
            {
                paymentGroup.selectedWayOfPayment = {
                    bankPaymentTypeId: item.bankPaymentType.bankPaymentTypeId,
                };
            }
            


            paymentGroup.totalPaymentValue = paymentGroup.totalPaymentValue + Number.parseFloat(item.paymentValue)
            paymentGroup.payments.push(item);
        }
    }


    

    insertReservationInstalment_Reversal(item: any) {

        item.online = item.online || false;


        var name = '';
        var groupName = '';
        var paymentGroup: paymentInstalments_Reversal;


        groupName = item.online ? 'On-Line' : 'Balcão';
            name = groupName;

            if(item.online){
                paymentGroup = this.groupReservationForPay.paymentInstalmentsReversal.find(i=>i.online == item.online);
            }
            else{
                groupName = item.groupPaymentId;
                
                paymentGroup = this.groupReservationForPay.paymentInstalmentsReversal.find(i=>i.groupPaymentId == item.groupPaymentId && i.groupName == groupName);
            }
   
        if(paymentGroup == null){
            paymentGroup = new paymentInstalments_Reversal(); 
            paymentGroup.payments = [];
            paymentGroup.groupName = groupName;
            paymentGroup.releaseDate = item.releaseDate;
            paymentGroup.online = item.online;
            paymentGroup.groupPaymentId = item.groupPaymentId;
            

            paymentGroup.payments.push(item);
            paymentGroup.totalPaymentValue = paymentGroup.totalPaymentValue + Number.parseFloat(item.paymentValue)

            if(item.instalmentId != null){
                paymentGroup.totalInstalmentsValue = paymentGroup.totalInstalmentsValue + item.paymentValue;
            } else {
                paymentGroup.totalAditionalFeeValue = paymentGroup.totalAditionalFeeValue + item.paymentValue;
            }

          
            
            this.groupReservationForPay.paymentInstalmentsReversal.push(paymentGroup);
        }
        else{

            if(item.instalmentId != null){
                paymentGroup.totalInstalmentsValue = paymentGroup.totalInstalmentsValue + item.paymentValue;
            } else {
                paymentGroup.totalAditionalFeeValue = paymentGroup.totalAditionalFeeValue + item.paymentValue;
            }

            paymentGroup.totalPaymentValue = paymentGroup.totalPaymentValue + Number.parseFloat(item.paymentValue)
            paymentGroup.payments.push(item);
        }
    }


 

    countInstalment(reservationId){
        var instalments = 0;
        this.groupReservationForPay.paymentInstalments.forEach(item => {
            if(item.instalment != null && item.reservationId == reservationId) {
                instalments = instalments +1;
            }
        });

        return instalments;
    }


    loadFullPaymentInfo (){

        this.groupReservationForPay.paymentInstalments = [];
        this.groupReservationForPay.paymentInstalmentsReversal = [];
        
        this.groupReservationForPay.dataGroup = new GroupForPay();
        this.groupReservationForPay.dataGroup.balconyPayments = [];
        this.groupReservationForPay.dataGroup.onlinePayments = [];
        this.groupReservationForPay.dataGroup.qtdPAIED_onlinePayments = 0;
        this.groupReservationForPay.dataGroup.qtdPAIED_balconyPayments = 0;
        this.groupReservationForPay.dataGroup.paidAmountAbsolute = 0;


        this.groupReservationForPay.dataGroupForReversal = new GroupForReversal();
        this.groupReservationForPay.dataGroupForReversal.balconyPayments = [];
        this.groupReservationForPay.dataGroupForReversal.onlinePayments = [];
        this.groupReservationForPay.dataGroupForReversal.paidAmountAbsolute = 0;


        this.payments_AllList = [];
        this.payments_AllList_Reversal = [];
        this.reservationValue = 0;

       
        this.getReservationFullPaymentInfo(this.groupReservationForPay.groupReservationId);

    }

    getReservationFullPaymentInfo = (groupId) =>
    {
        if(this.loading == true && this.openModal == true) return;

        this.loading = true;
        this.reservationPaymentService.getPaymentInfo(groupId).subscribe(data =>
        {
            this.loading = false;
            switch (data.code)
            {
				case 200:
                console.log(data);

                    this.groupReservationForPay.reservations.forEach(resevation => {

                        this.reservationValue = this.reservationValue + Number.parseFloat(resevation.price);

                        var reservationInstalments = data.object[resevation.reservationId].paymentInstalments;
                        var reservationAddFee = data.object[resevation.reservationId].paymentAdditionalFee;

                        if (reservationInstalments)
                        {
                            var instalmentNumber = 1;

                            for (let i = reservationInstalments.length - 1; i >= 0; i--)
                            {
                                reservationInstalments[i].reservationId = resevation.reservationId;
    
                                if(reservationInstalments[i].releaseDate) {
                                    reservationInstalments[i].releaseDate = moment(reservationInstalments[i].releaseDate, 'YYYY-MM-DD');
                                }
                                if (reservationInstalments[i].paymentDate)
                                {
                                    var date = reservationInstalments[i].paymentDate.split("T")[0].split('-');
                                    reservationInstalments[i].paymentDate = date[2]+'/'+date[1]+'/'+date[0];
                                    reservationInstalments[i].payedDate = date[2]+'/'+date[1]+'/'+date[0];
                                }
                                if (reservationInstalments[i].bankPaymentType)
                                {
                                    for (let j = this.banksArray.length - 1; j >= 0; j--) {
                                        if (this.banksArray[j].bankPaymentTypeId &&
                                            reservationInstalments[i].bankPaymentType &&
                                            this.banksArray[j].bankPaymentTypeId == reservationInstalments[i].bankPaymentType.bankPaymentTypeId
                                        )
                                            reservationInstalments[i].selectedWayOfPayment = this.banksArray[j];
                                    }
                                }
    
                                if(reservationInstalments[i].instalmentId){
                                    reservationInstalments[i].instalment = instalmentNumber;
                                    instalmentNumber++;
                                }

                                this.payments_AllList.push(reservationInstalments[i]);

                                if(reservationInstalments[i].paymentStatus == 'PAIED' && reservationInstalments[i].groupPaymentStatus != 5){  // 5 = WaitingCancel
                                    this.payments_AllList_Reversal.push(reservationInstalments[i]);
                                }

                            }                   
                        }


                        if (reservationAddFee)
                        {
                            for (let i = reservationAddFee.length - 1; i >= 0; i--)
                            {
                                reservationAddFee[i].reservationId = resevation.reservationId;
    
                                if(reservationAddFee[i].releaseDate) {
                                    reservationAddFee[i].releaseDate = moment(reservationAddFee[i].releaseDate, 'YYYY-MM-DD');
                                }
    
                                if (reservationAddFee[i].paymentDate)
                                {
                                    const date = reservationAddFee[i].paymentDate.split("T")[0].split('-');
                                    reservationAddFee[i].paymentDate = date[2]+'/'+date[1]+'/'+date[0];
                                    reservationAddFee[i].payedDate = date[2]+'/'+date[1]+'/'+date[0];
                                }
    
                                if (reservationAddFee[i].bankPaymentType){
                                    reservationAddFee[i].bankPaymentTypeId = reservationAddFee[i].bankPaymentType.bankPaymentTypeId;
                                    reservationAddFee[i].selectedWayOfPayment = reservationAddFee[i].bankPaymentType.bank;
                                }
    
                                if (!reservationAddFee[i].instalmentNumber) {
                                    reservationAddFee[i].instalmentNumber = 1;
                                }
    
                                if (reservationAddFee[i].additionalFeeNumber) {
                                    reservationAddFee[i].instalmentNumber = reservationAddFee[i].additionalFeeNumber; 
                                }
    
                                this.payments_AllList.push(reservationAddFee[i]);

                                if(reservationAddFee[i].paymentStatus == 'PAIED' && reservationAddFee[i].groupPaymentStatus != 5 ){ // 5 = WaitingCancel
                                    this.payments_AllList_Reversal.push(reservationAddFee[i]);
                                }

                            }
                        }
    

                    
                    });

                   
                    this.payments_AllList.forEach(item => {
                        if(item.paymentStatus == 'NO_PAIED' && item.groupPaymentId){
                            console.log('## groupPaymentId has ignored..');
                            //item.groupPaymentId = null;
                        }
                        this.insertReservationInstalment(item);
                    });

                    this.payments_AllList_Reversal.forEach(item => {
                        if(item.paymentStatus == 'NO_PAIED' && item.groupPaymentId){
                            console.log('## groupPaymentId has ignored..');
                            //item.groupPaymentId = null;
                        }
                        this.insertReservationInstalment_Reversal(item);
                    });


                    this.groupReservationForPay.paymentInstalmentsReversal.forEach(payment => {
                        
                        this.groupReservationForPay.dataGroupForReversal.paidAmountAbsolute += payment.totalPaymentValue;

                        if(payment.online == true)
                        {
                            this.groupReservationForPay.dataGroupForReversal.onlinePayments.push(payment);  
                        }
                        else {
                            this.groupReservationForPay.dataGroupForReversal.balconyPayments.push(payment);
                        }
                        
                    });



                    this.groupReservationForPay.paymentInstalments.forEach(payment => {
                        
                        if(payment.online == true)
                        {
                            this.groupReservationForPay.dataGroup.onlinePayments.push(payment);
                            if(payment.paymentStatus == 'PAIED')
                            {
                                this.groupReservationForPay.dataGroup.qtdPAIED_onlinePayments++;
                                this.groupReservationForPay.dataGroup.paidAmountAbsolute += payment.totalPaymentValue;
                            }
                        }
                        else {
                            this.groupReservationForPay.dataGroup.balconyPayments.push(payment);
                            if(payment.paymentStatus == 'PAIED')
                            {
                                this.groupReservationForPay.dataGroup.qtdPAIED_balconyPayments++;
                                this.groupReservationForPay.dataGroup.paidAmountAbsolute += payment.totalPaymentValue;
                            }
                        }
                        
                    });

                    console.log('groupReservationForPay:', this.groupReservationForPay);

                    if(this.openModal == true) {
                        this.openModal = false;
                        
                        //this.openDialog(this.paymentModal);
                        this.currentTemplateModal = this.paymentModal;

                        setTimeout(() => {
                            this.reservationPaymentModal.step = 1;
                            this.reservationPaymentModal.bankSelectWarn = false;
                            this.reservationPaymentModal.selectedMode = '';
                            this.reservationPaymentModal.sendingValue = 0;
                            this.reservationPaymentModal.initializeData();
                            this.bankSelectWarn = false;
                        }, 300);



                    }
                    
                    if(this.openRevModal == true){
                        this.openRevModal = false;
                        this.bankSelectWarn = false;
                        this.reversalValue = 0;
                        this.selectedSomethingToPay = 0;
                        //$('#reversalModal').modal('show');
                        this.openDialog(this.reversalModal);

                        setTimeout(() => {
                            this.reservationReversalModal.reversalValue = 0;
                            this.reservationReversalModal.reversalReason = '';
                            this.reservationReversalModal.posted = false;
                            this.reservationReversalModal.warnNoSelected = false;
                        }, 300);

                        
                    }
                    
			  	    break;
			}
		}, error => {
            this.loading = false;
            console.log("Error")
        });
	}

    getInstalmentModalInfo = () =>
    {
        this.loading = true;
        this.curentReservationModel.totalOnLine = 0;
        this.curentReservationModel.totalParcelable = 0;
        this.reservationPaymentService.getReservationFullPaymentInfo(this.currentReservation.reservationId).subscribe(data =>
        {
            this.loading = false;
            switch (data.code)
            {
                case 200:
                    this.parcelableInstalmentsList = [];
                    if (data.object.paymentInstalments)
                    {
                        data.object.paymentInstalments.forEach(element => {
                            
                            if(element.groupPaymentStatus == 4 || element.groupPaymentStatus == 6) { //4 = CANCELED  -  6 = CANCELED_BY_ATTEMPTS 
                                //mudar para status NO_PAIED e remover groupPaymentStatus 
                                //para liberar o reparcelamento
                                element.paymentStatus = "NO_PAIED";
                                element.groupPaymentStatus = null;
                                element.groupPaymentId = null;
                            }

                            if(element.online == true)
                            {
                                this.curentReservationModel.totalOnLine = this.curentReservationModel.totalOnLine  + element.paymentValue;
                            }
 
                            if((element.online == false || element.online == null) && element.groupPaymentStatus == null)
                            {
                                this.curentReservationModel.totalParcelable += element.paymentValue;

                                this.parcelableInstalmentsList.push(element);
                            }

                            

                        });

                        this.parcelableInstalmentsList.forEach(element => {
                            if (element.paymentStatus == "NO_PAIED")
                            {
                                this.firstNotPaiedInstalment = element.instalmentId;
                            }  
                        });
                    }
				    break;
			}
        }, error => {
            this.loading = false;
            console.log("Error", error);
        });
	}

    addIntalment = () =>
    {
        var HasOnline: boolean;
        var HasgroupPaymentId: boolean = false;
        this.parcelableInstalmentsList.forEach(element => {
            if(element.online == true){
                HasOnline = true;
            }
            if(element.groupPaymentId != null){
                HasgroupPaymentId = true;
                element.paymentStatus = 'PAIED';
            }
        });

        if(HasOnline == true){
            Swal.fire('Oops...', this.translate.instant('reservation_payment.error_donotsplitparcels'), 'warning');
            return;
        }


        let total = 0;    
		let pago = 0;
        let qtdParcelasNew = 0;
        let restante = 0;

        for (let i = this.parcelableInstalmentsList.length - 1; i >= 0; i--)
        {
            total = Number.parseFloat((total + this.parcelableInstalmentsList[i].paymentValue).toFixed(2));

		 	if (this.parcelableInstalmentsList[i].paymentStatus == 'PAIED')
		 		pago = pago + this.parcelableInstalmentsList[i].paymentValue;
             else
             {
                 qtdParcelasNew++;
                 restante = Number.parseFloat((restante + this.parcelableInstalmentsList[i].paymentValue).toFixed(2));
             }
		}
    
        if(restante <= 0) {
            return;
        }

		const aux = {
			instalmentNumber: 1,
			paymentStatus: "NO_PAIED"
        };

		this.parcelableInstalmentsList.push(aux);
        qtdParcelasNew++;
        
        var novo_valor_parcela = Number.parseFloat((restante / qtdParcelasNew).toFixed(2));      
        var dif = restante - Number.parseFloat((novo_valor_parcela * qtdParcelasNew).toFixed(2));
        dif = Number.parseFloat(dif.toFixed(2));
        const ultimaparcela = novo_valor_parcela + dif;

        for (let i = this.parcelableInstalmentsList.length - 1; i >= 0; i--)
        {
             if (this.parcelableInstalmentsList[i].paymentStatus  != 'PAIED')
             {
                 this.parcelableInstalmentsList[i].paymentValue = novo_valor_parcela;
                 if(i >= this.parcelableInstalmentsList.length - 1){
                    this.parcelableInstalmentsList[i].paymentValue = ultimaparcela;
                 }
             }
		}
	}

    openInstalmentModal = (instalmentModal) =>
    {
    	this.outValue = false;
    	this.getInstalmentModalInfo();
    	this.openDialog(instalmentModal);
    }

    openModal = false;
    paymentModal;
    openPaymentModal = (modal) =>
    {
        this.paymentModal = modal;
        this.openModal = true;
        this.currentTemplateModal = null;
    	this.loadFullPaymentInfo();
    }


    openRevModal = false;
    reversalModal;
    openReversalModal = (reversalModal) =>
    {
        this.reversalModal = reversalModal;
        this.openRevModal = true;
        this.loadFullPaymentInfo();
    }

    postOpenDoor() {
        this.loading = true
        this.reservationPaymentService.postOpenDoor(this.currentReservation.reservationId).subscribe(
            (data) => {
                if(data.code == 200) {
                    this.notifier.notify("success", this.translate.instant('reservation_payment.success_opendoor'));
                } else {
                    this.notifier.notify("error", this.translate.instant('reservation_payment.error_post_opendoor'));
                }
                this.loading = false
            },
            (error) => {
                this.notifier.notify("error", this.translate.instant('reservation_payment.error_post_opendoor'));
                this.loading = false
            })
    }

    openAccessCodeModal = () =>
    {
        this.getAccessCode()
    	$('#accessCodeModal').modal('show');
    }

    getAccessCode() {
        this.loading = true
        this.reservationPaymentService.getAccessCode(this.currentReservation.reservationId).subscribe(
            (data) => {
                if(data.code === 200) {
                    if(data.object) {
                        this.accessCode = data.object
                    } else {
                        this.accessCode = null
                    }
                } else {
                    this.notifier.notify("error", this.translate.instant('reservation_payment.error_get_code'));
                }
                this.loading = false
            },
            (error) => {
                this.notifier.notify("error", this.translate.instant('reservation_payment.error_get_code'));
                this.loading = false
            })
    }

    cancelAccessCode() {
        this.loading = true
        this.reservationPaymentService.deleteAccessCode(this.currentReservation.reservationId).subscribe(
            (data) => {
                if(data.code === 200) {
                    this.accessCode = null
                    this.notifier.notify("success",  this.translate.instant('reservation_payment.access_code_deleted'));
                } else {
                    this.notifier.notify("error", this.translate.instant('reservation_payment.access_code_deleted'));
                }
                this.loading = false
            },
            (error) => {
                this.notifier.notify("error",  this.translate.instant('reservation_payment.error_delete_acces_code'));
                this.loading = false
            })
    }

    deleteInstallment = position =>
    {
        let total = 0;
        let pago = 0;
        let qtdParcelasPagas = 0;


        for (let i = this.parcelableInstalmentsList.length - 1; i >= 0; i--)
        {
            total = total + this.parcelableInstalmentsList[i].paymentValue;
		 	if (this.parcelableInstalmentsList[i].paymentStatus == 'PAIED')
            {
                pago = pago + this.parcelableInstalmentsList[i].paymentValue;
                qtdParcelasPagas++;
            }
		}

        this.parcelableInstalmentsList.splice(position, 1);
        let qtdParcelasNew = this.parcelableInstalmentsList.length - qtdParcelasPagas;

		const restante = total - pago;

        var novo_valor_parcela = Number.parseFloat((restante / qtdParcelasNew).toFixed(2));      
        var dif = restante - Number.parseFloat((novo_valor_parcela * qtdParcelasNew).toFixed(2));
        dif = Number.parseFloat(dif.toFixed(2));
        const ultimaparcela = novo_valor_parcela + dif;

        for (let i = this.parcelableInstalmentsList.length - 1; i >= 0; i--)
        {
             if (this.parcelableInstalmentsList[i].paymentStatus  != 'PAIED')
             {
                 this.parcelableInstalmentsList[i].paymentValue = novo_valor_parcela;
                 if(i >= this.parcelableInstalmentsList.length - 1){
                    this.parcelableInstalmentsList[i].paymentValue = ultimaparcela;
                 }
             }
        }
        
	}

    setInstalments = () =>
    {
		const body = [];
		this.outValue = false;
		let instalmentsSum = 0;

        for (let i = 0; i < this.parcelableInstalmentsList.length; i++)
        {
            if (this.parcelableInstalmentsList[i].instalmentId)
            {
			 	const auxEditingInstalment = {
			 		instalmentId: this.parcelableInstalmentsList[i].instalmentId,
			 		reservationId: this.currentReservation.reservationId,
			 		instalmentValue: this.parcelableInstalmentsList[i].paymentValue
                };

                instalmentsSum += Number.parseFloat((this.parcelableInstalmentsList[i].paymentValue).toFixed(2));
			 	body.push(auxEditingInstalment);
            }
            else
            {
		 		const auxCreatingNewInstalment = {
			 		reservationId: this.currentReservation.reservationId,
			 		instalmentValue: this.parcelableInstalmentsList[i].paymentValue
                };

                instalmentsSum += Number.parseFloat((this.parcelableInstalmentsList[i].paymentValue).toFixed(2));
			 	body.push(auxCreatingNewInstalment);
		 	}
        }

		//verifica se o valor das parcelas bate com o total da reserva
		if (instalmentsSum != this.curentReservationModel.totalParcelable)
			this.outValue = true;

		console.log(body);

        if (!this.outValue)
        {
            this.reservationPaymentService.setInstalments(body).subscribe(data =>
            {
				console.log(data);
                switch (data.code)
                {
					case 200:
						console.log(data);
						this.getInstalmentModalInfo();
                        
                        //this.getReservationFullPaymentInfo();
                        this.loadFullPaymentInfo();

						this.getReservationPaymentInfo();
                        this.openContainer('#instalment_success');
                        this.dialog.closeAll();
					    break;
				}
			}, error => console.log("Error"));
		} else {
            console.log('instalmentsSum', instalmentsSum);
            console.log('totalParcelable', this.curentReservationModel.totalParcelable);
        }
	}

    selectThisInstalment = parcela =>
    {
        if (parcela.selected != true)
        {
			parcela.selected = true;
            parcela.paymentDate = moment();
			parcela.releaseDate = moment();
			this.sendingValue += parcela.totalPaymentValue;
			this.reversalValue += parcela.totalPaymentValue;
			this.selectedSomethingToPay++;
		}
        else
        {
			parcela.selected = false;
			parcela.paymentDate = '';
            parcela.releaseDate = '';
			this.sendingValue -= parcela.totalPaymentValue;
			this.reversalValue -= parcela.totalPaymentValue;
			this.selectedSomethingToPay--;
		}
    }
    
    selectThisInstalmentToReversal = parcela =>
    {
        if (parcela.selected != true)
        {
			parcela.selected = true;
			parcela.reversalDate = moment();
			this.sendingValue += parcela.totalPaymentValue;
			this.reversalValue += parcela.totalPaymentValue;
			this.selectedSomethingToPay++;
		}
        else
        {
			parcela.selected = false;
			parcela.reversalDate = '';
			this.sendingValue -= parcela.totalPaymentValue;
			this.reversalValue -= parcela.totalPaymentValue;
			this.selectedSomethingToPay--;
		}
    }
    

    selectThisExtraTax = tax =>
    {
        if (tax.selected != true)
        {
			tax.selected = true;
			tax.paymentDate = moment();
            tax.releaseDate = moment();
			this.sendingValue += tax.totalPaymentValue;
			this.reversalValue += tax.totalPaymentValue;
			this.selectedSomethingToPay++;
		}
        else
        {
			tax.selected = false;
			tax.paymentDate = '';
            tax.releaseDate = '';
			this.sendingValue -= tax.totalPaymentValue;
			this.reversalValue -= tax.totalPaymentValue;
			this.selectedSomethingToPay--;
		}
    }

    selectExtraTaxToReversal = tax =>
    {
        if (tax.selected != true)
        {
			tax.selected = true;
			tax.reversalDate = moment();
            this.sendingValue += tax.totalPaymentValue;
			this.reversalValue += tax.totalPaymentValue;
			this.selectedSomethingToPay++;
		}
        else
        {
			tax.selected = false;
			tax.reversalDate = '';
			this.sendingValue -= tax.totalPaymentValue;
			this.reversalValue -= tax.totalPaymentValue;
			this.selectedSomethingToPay--;
		}
    }


    cancelTax = additionalFeeId =>
    {
        this.priceAditionalFee[additionalFeeId] = 0;
        this.descriptionAditionalFee[additionalFeeId] = '';
        this.dialog.closeAll();
	}

    
    onAditionalFeeKeyUp(event: KeyboardEvent, additionalFeeId){
        if(event.keyCode == 13){
            this.setAditionalFeeValue(additionalFeeId);
        }
    }

    openSetAditionalFeeModal(aditionalFeeModal, additionalFee) {
        this.selectedAdditionalFee = additionalFee;
        this.selectedAdditionalFeeId = additionalFee.additionalFeeId;
        this.selectedAdditionalFee.additionalDescription = this.additionalDescription(additionalFee.additionalFeeId);
        this.openDialog(aditionalFeeModal);
        setTimeout(() => {
            this.txtPriceAditionalFee.nativeElement.focus();
        }, 300);
    }

    previewAdFee (previewAditionalFeeModal, additionalFee){
        this.selectedAdditionalFee = additionalFee;
        this.selectedAdditionalFee.additionalDescription = this.additionalDescription(additionalFee.additionalFeeId);
        console.log(additionalFee);
        this.selectedAdditionalFeeId = additionalFee.additionalFeeId;
        this.openDialog(previewAditionalFeeModal);
    }

    setAditionalFeeValue = additionalFeeId => {
        if (this.priceAditionalFee[additionalFeeId] <= 0)
            return;

        this.loading = true;
        this.reservationPaymentService.setAditionalFeeValue(additionalFeeId, this.currentReservation.reservationId, this.priceAditionalFee[additionalFeeId], this.descriptionAditionalFee[additionalFeeId]).subscribe(data => {
            this.loading = false;
            console.log(data);
            switch (data.code) {
                case 200:
                    console.log(data);
                    this.getReservationPaymentInfo();
                    //this.getReservationFullPaymentInfo();
                    this.loadFullPaymentInfo();
                    this.dialog.closeAll();
                    break;
            }
        }, error => {
            this.loading = false;
            if (error.status == 422)
                this.notifier.notify('error', error.error.message)
            else
                this.notifier.notify('error', this.translate.instant('error_msg.error_undefined'))


        });
    }

    deleteExtra = additionalFeeId =>
    {
        this.loading = true;
        this.reservationPaymentService.deleteExtra(additionalFeeId,this.currentReservation.reservationId).subscribe(data =>
        {
            this.loading = false;
			console.log(data);
            switch (data.code)
            {
				case 200:
					this.getReservationPaymentInfo();
                    //this.getReservationFullPaymentInfo();
                    this.loadFullPaymentInfo();
			    	break;
			}
		}, error => {
            this.loading = false;
            if (error.status == 422)
                this.notifier.notify('error', error.error.message)
            else
                this.notifier.notify('error', this.translate.instant('error_msg.error_undefined'))


        });
	}


    paymentModalReloadData(){

        this.loading = true;
        setTimeout(() => {
            this.loading = false;
            this.openModal = true;

            this.reservationsService.getReservationInfo(this.currentReservation.reservationId).subscribe(data =>
                {
                    switch (data.code)
                    {
                        case 200:
                            this.selectReservation(data.object);
                            console.log('groupReservation:', data.object.groupReservation);
                            this.setReservations(data.object.groupReservation);

                            this.getReservationPaymentInfo().then(()=>{
                                this.loadFullPaymentInfo();
                            })

                            break;
                    }
                }, error => console.log("Error"));

           
          }, 1000)

      
    }

    paymentModalOnSaved(){
        this.currentTemplateModal = null;
        this.openContainer('#payment_success');
        this.getReservationPaymentInfo();
        this.loadPaymentsInfo();
        window.scroll(0,0);
    }

    paymentModalOnSaved_Reversal(){
        this.dialog.closeAll();
        this.openContainer('#reverse_success');
        this.getReservationPaymentInfo();
        this.loadPaymentsInfo();
        window.scroll(0,0);
    }

    sendReverse = () =>
    {	   
        var datapost = this.groupReservationForPay.dataGroupForReversal.onlinePayments.concat(this.groupReservationForPay.dataGroupForReversal.balconyPayments);

        this.groupReservationForPay.paymentInstalmentsReversal.forEach(group => { 

            const parcelas = [];
            const extras = [];

            datapost.forEach(payment => {
                if(payment.selected == true) {
                    payment.payments.forEach(item => {

                        if(item.instalmentId != null && item.groupPaymentId == group.groupPaymentId) {
                            parcelas.push(
                                {
                                    instalmentId: item.instalmentId,
                                    value: item.paymentValue 
                                });
                        }
    
                        if(item.additionalFeeId != null && item.groupPaymentId == group.groupPaymentId) {
                            extras.push(
                            {
                                additionalFeeId: item.additionalFeeId,
                                value: item.paymentValue 
                            });
                        }
                    });
                }
            });
    
    
            this.loading = true;
            this.reservationPaymentService.sendReverse02(group.groupPaymentId, '', parcelas, extras).subscribe(data => {
                this.loading = false;
                switch (data.code) {
                    case 200:
                        this.getReservationPaymentInfo();

                        //this.getReservationFullPaymentInfo();
                        this.loadFullPaymentInfo();

                        this.getInstalmentModalInfo();
                        this.openContainer('#reverse_success');
                        this.dialog.closeAll();
                        break;
                }
            }, error => {
                this.loading = false;
                if (error.status == 422)
                    this.notifier.notify('error', error.error.message)
                else
                    this.notifier.notify('error', this.translate.instant('error_msg.error_undefined'))

            });


        });

	}

	goToStep1 = () => this.onPreviousClicked.emit();

	goToStep3 = () => this.onNextClicked.emit();


    cancelReservation(){
       this.onExitClicked.emit();
    }


    checkIfagreesInstalment = parcela =>
    {
		if (parcela.selectedWayOfPayment.agreesInstalment == false)
			parcela.instalmentNumber = 1;
    }
    
    taxChanged = tax =>
    {
       console.log(' tax.bankPaymentType:',  tax.bankPaymentType);

		
    }

    closeContainer = containerId =>
    {
		$(containerId).fadeOut();
		$("html").css("overflow-y", "visible");
		this.dialog.closeAll();
	}

    openContainer = containerId =>
    {
        $(containerId).fadeIn();
        $("html").css("overflow-y", "hidden");
        setTimeout(() => 
        { 
            $(containerId).fadeOut();
            $("html").css("overflow-y", "auto");
        }, 1500);
  }

    generateMagikey = () => 
    {
        this.disabledButton = true;

        this.reservationPaymentService.generateMagikey(this.currentReservation.reservationId).subscribe(data => 
        {
            switch (data.code) 
            {
                case 200:
                    this.openContainer('#generateMagikey_success');
                    this.getAccessCode()
                    break;
                case 270:
                    Swal.fire('Oops...', this.translate.instant('reservation_payment.invite_already_sent'), 'error');
                    break;      
                case 271:
                    Swal.fire('Oops...', this.translate.instant('reservation_payment.generate_magikey_nounit'), 'error');
                    break;  
            }

            this.disabledButton = false;
        }, 
        error => 
        {
            this.disabledButton = false;
            console.log('Error', error);
        });
    }
}
