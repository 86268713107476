import { Component, OnInit } from '@angular/core';
import { LoginService } from './login.service';
import { Router } from '@angular/router';
import { CPFValidator } from '@tools/Validator';
import { TranslateService } from '@ngx-translate/core';
declare var $: any;

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit
{
	model: any = {};
    loginRequest: any;
    invalidCpf: boolean;
    notFoundCpf: boolean;
    invalidPassword: boolean;
    userNoActive: boolean;
    loading: boolean = false;
    aux: any;
    cpfMask: any = [/\d/, /\d/,/\d/,'.',/\d/,/\d/,/\d/,'.',/\d/, /\d/,/\d/, '-',/\d/,/\d/];

    constructor(private loginservice: LoginService, private router: Router, public translate: TranslateService) { }

    ngOnInit() { }

    login = () => 
    {
        $(".modal").modal("hide");

        this.invalidCpf = !CPFValidator(this.model.username);

        if (this.invalidCpf) return;

        this.notFoundCpf = false;
        this.invalidPassword = false;
        this.userNoActive = false;
        this.loading = true;

        this.loginservice.tryToAuth(this.model.username, this.model.password).subscribe(data =>
        {
            if (data.code === 200) 
            {
                this.loginservice.setUserLoggedIn();
                localStorage.setItem('currentUser', JSON.stringify(data));
                sessionStorage.removeItem('reservation_filter');

                this.loginservice.getUserInfo().subscribe(data => 
                {
                    data.object.employee = {
                        ...data.object.employee,
                        establishmentJobTitles: data.object.establishmentJobTitle,
                        companyJobTitles: data.object.companyJobTitle,
                    }
                    localStorage.setItem('currentUserInfo', JSON.stringify(data.object.employee));

                    this.router.navigate(['chose-company']);
                    
                }, error => console.log("Error"));

                
            } 
            else 
            {
                this.invalidPassword = (data.code === 202);
                this.notFoundCpf = (data.code === 201 || data.code === 245 || data.code === 248);
                this.userNoActive = (data.code === 263 || data.code === 264);
            }
            this.loading = false;
        }, error => {
            this.loading = false;
    });        
    }

    logout = () => this.loginservice.logout();

    togglePassword = () =>
    {
        const aux = document.getElementById("password_input").getAttribute("type");

        if (aux == "text")
            document.getElementById("password_input").setAttribute("type","password");

        if (aux == "password")
            document.getElementById("password_input").setAttribute("type","text");
    }
    
    setLanguage(lang) {
        this.translate.setDefaultLang(lang);
        this.translate.use(lang);
        localStorage.setItem('currLang', lang);
    }

}
