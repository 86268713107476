import { Component, OnInit, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
	selector: 'app-select',
	templateUrl: './select.component.html',
	styleUrls: ['./select.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => SelectComponent),
			multi:true
		}
	]
})
export class SelectComponent implements ControlValueAccessor {

	@Input('label') label: string = 'Seletor';
	@Input('options') options: any = [];
	@Input('disabled') disabled: boolean = false;
	@Input('showChevron') showChevron: boolean = false;
	@Input('defaultText') defaultText : string;

	selectedValue: any;
	propagateChange = (_: any) => {}
	open: boolean = false;

	constructor() { }

	ngOnInit() {
	}

	writeValue(value: any) {
		if(value !== undefined) {
			this.selectedValue = value;
		}
	}

	registerOnChange(fn) {
		this.propagateChange = fn;
	}

	registerOnTouched() {}

	changeOption(option) {
		if(this.disabled) {
			return;
		}
		this.selectedValue = option
		this.propagateChange(option)
		this.open = false

		const el = <HTMLElement>document.activeElement;
		el.blur()
	}

	toggleOpen() {
		if(this.disabled) {
			return;
		}
		this.open = !this.open
	}

	onOpen() {
		if(this.disabled) {
			return;
		}
		this.open = true
	}

	onClose() {
		if(this.disabled) {
			return;
		}
		this.open = false
	}

}


