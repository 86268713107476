import { Component, OnInit } from '@angular/core';
import { AppStateService } from '../app-layout.service';
import { LoginService } from '@src/app/pages/login/login.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
declare var jquery: any;
declare var $: any;


@Component({
  selector: 'my-app-header',
  styleUrls: [
    './header.component.scss',
  ],
  templateUrl: './header.component.html'
})

export class AppHeaderComponent implements OnInit {
  public AppConfig: any;
  public toggledMenu: boolean = false;

  userInfo: any;
  loading: boolean = false;

  constructor(public appStateService: AppStateService,
    private loginService: LoginService,
    public translate: TranslateService) {
    this.AppConfig = appStateService.AppConfig;
  }



  selectUnity(event) {
    // this.selectedUnit = this.userService.planInfo.unities.find(o => o.id == this.selectedUnitId);
    // this.userService.setUnity(this.selectedUnit);
    //window.location.reload();
  }

  toggledmenu() {
    this.toggledMenu = false;
    setTimeout(() => {
      this.toggledMenu = true;
    }, 1000);
  }

  ngOnInit() {
    setTimeout(() => {

    }, 50);

    this.userInfo = JSON.parse(localStorage.getItem('currentUserInfo'));
    this.translate.currentLang
  }

  get currentTheme(): string {
    return this.appStateService.currentTheme
  }

  signout() {
    //this.userService.logout();
  }

  

  toggleProfileDropdown() {

    var pfd = $('#profile-dropdown');

    if(pfd.is(":visible"))
      pfd.fadeOut(500);
    else
      pfd.fadeIn(200)
  
  }

  openEditUserInfo = () => {
    this.loading = true;
    setTimeout(() => this.loading = false, 1000);
    const vph = $(document).height()
    $('#editUserInfo').css({ 'height': vph + 'px' });
    $("#editUserInfo").fadeIn();
  }

  openChangeCompany = () => {
    this.loading = true;
    setTimeout(() => this.loading = false, 1000);
    const vph = $(document).height()
    $('#changeCompany').css({ 'height': vph + 'px' });
    $("#changeCompany").fadeIn();
    this.appStateService.onEnterChangeCompanies.emit({});
  }

  logout = () => this.loginService.logout();


}
