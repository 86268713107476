import { Directive, ElementRef, AfterViewInit } from '@angular/core';
declare const $ :any;

// off-canvas sidebar for mobile, and this is the trigger
@Directive({ selector: '[myToggleOffcanvasNav]' })
export class ToggleOffcanvasNavDirective implements AfterViewInit {
  el: ElementRef;
  constructor(el: ElementRef) {
    this.el = el;
  }

  ngAfterViewInit() {

    const $navToggler = $(this.el.nativeElement);
    const $body = $('#body');

    $navToggler.on('click', (e) => {
      // _sidebar.scss, _page-container.scss
      $body.toggleClass('sidebar-mobile-open');

      if($body.hasClass('sidebar-mobile-open')){
        $navToggler.empty();
        $navToggler.append('<span class="mat-button-wrapper"><i class="material-icons">arrow_back</i></span>');
      }else {
        $navToggler.empty();
        $navToggler.append('<span class="mat-button-wrapper"><i class="material-icons">menu</i></span>');
      }
      

      e.preventDefault();
    });
  }
}
