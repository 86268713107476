import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse  } from '@angular/common/http';
import {ResponseFormat} from '@app/types/response-format';
import {Observable} from 'rxjs/Rx';

// Import RxJs required methods
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';

@Injectable()
export class LoginService 
{
    private isUserLoggedIn;
    private cpf;	
    private result;

    constructor(private http : HttpClient, private router : Router) 
    { 
        this.isUserLoggedIn = (localStorage.getItem('currentUser')) ? true : false;
    }

    setUserLoggedIn = () => this.isUserLoggedIn = true;

    getUserLoggedIn = () => this.isUserLoggedIn;

    tryToAuth = (username, password) =>
    {
        const param = {
            grantType: 'client_credentials',
            username : username,
            password: password,
            clientId: "1",
            clientSecret: "123456",
            refreshToken: ""
        };
        return this.http.post<ResponseFormat>('vivakey/rest/oauth', param);
    }

    getUserInfo = () => this.http.get<ResponseFormat>('vivakey/rest/employee');

    getCurrentUserInfo = () => JSON.parse(localStorage.getItem('currentUserInfo'));

    getDashboard(establishmentId, buildingId?, unitId?, categoryId?){
        var filter = '';

        if(buildingId)
            filter = `?buildingId=${buildingId}`;

        else if(unitId)
            filter = `?unitId=${unitId}`;
        
        else if (categoryId)
            filter = `?categoryId=${categoryId}`;
        
        return this.http.get<ResponseFormat>(`vivakey/rest/reservation/dashboard/${establishmentId}${filter}`);
    }


    getDashboardUnits(establishmentId){
        return this.http.get<ResponseFormat>(`vivakey/rest/unit/getUnits?establishmentId=${establishmentId}`);
    }

    getDashboardBuildings(establishmentId){
        return this.http.get<ResponseFormat>(`vivakey/rest/building/getBuildings?establishmentId=${establishmentId}`);
    }

    getDashboardCategories(establishmentId){
        return this.http.get<ResponseFormat>(`vivakey/rest/category/getCategories/${establishmentId}`);
    }


    getOwnerDashboard = (establishmentId, _params?) =>
    {
        let params = new HttpParams();
		Object.keys(_params).map((key, index) => {
            if(_params[key] != undefined && _params[key] != '')
			    params = params.append(key, _params[key])
		})

        return this.http.get<ResponseFormat>(`vivakey/rest/reservation/dashboardowner/${establishmentId}`, {
			params: params
        });
    }

    getOwnerDashboardUnits(establishmentId){
        return this.http.get<ResponseFormat>(`vivakey/rest/unit/getUnits?hasMenuOwner=true&establishmentId=${establishmentId}`);
    }

    getOwnerDashboardBuildings(establishmentId){
        return this.http.get<ResponseFormat>(`vivakey/rest/building/getbuildingsowner?establishmentId=${establishmentId}`);
    }


    recoveryPassword = (username) => this.http.get<ResponseFormat>('vivakey/rest/employee/forgotPassword/' + username);

    logout = () =>
    {
        localStorage.removeItem('currentUser');
        sessionStorage.removeItem('reservation_filter');
        sessionStorage.removeItem('currentMenu');
        sessionStorage.removeItem('currentMenuCategory');
        this.isUserLoggedIn = false;
        this.router.navigate(['login']);
    }
}