import { Component, OnInit } from "@angular/core";
import { AppStateService } from "@src/app/layouts/app-layout.service";
import { PdvService } from "./pdv.service";
declare var jquery: any;
declare var $: any;

@Component({
    selector: "app-pdv",
    templateUrl: "./pdv.component.html",
    styleUrls: ["./pdv.component.css"]
})
export class PdvComponent implements OnInit {
    taxModel: any = {};
    pdvModel: any = {};
    listaServicos: any = [];
    deletable: any = {};
    readOnly: boolean = false;
    filtro: any = {};
    hasPdv: any = [];
    establishment: any = {};
    loading: boolean = true;
    constructor(private pdvSerivce: PdvService, private appStateService: AppStateService) {}

    ngOnInit() {
        this.filtro.status = "todos";
        this.establishment = this.appStateService.currentEstablishment;

        this.pdvSerivce.getPos(this.establishment.establishmentId).subscribe(
            data => {
                console.log(data);
                switch (data.code) {
                    case 200:
                        this.hasPdv = data.object;
                        if (data.object.length >= 1)
                            this.getTaxes(data.object[0].posId, this.filtro);
                        break;
                    default:
                        this.loading = false;
                        break;
                }
            },
            error => {
                this.loading = false;
                console.log("Error");
            }
        );
    }

    showDetails = (elementPosition, taxType) => {
        this.readOnly = true;
        const copy = Object.assign({}, this.listaServicos[elementPosition]);

        if (copy.status == "ACTIVE") copy.situacao = 1;
        else copy.situacao = 0;

        this.taxModel = copy;
        this.taxModel.taxType = taxType;
        this.taxModel.action = "show";
        $("#createTaxModal").modal("show");
    };

    openEditForm = (elementPosition, taxType) => {
        this.readOnly = false;
        const copy = Object.assign({}, this.listaServicos[elementPosition]);

        if (copy.status == "ACTIVE") copy.situacao = 1;
        else copy.situacao = 0;

        this.taxModel = copy;
        this.taxModel.taxType = taxType;
        this.taxModel.action = "edit";
        $("#createTaxModal").modal("show");
    };

    openAddForm = taxType => {
        this.readOnly = false;
        this.taxModel = {};
        this.taxModel.situacao = 1;
        this.taxModel.taxType = taxType;
        this.taxModel.action = "add";
        $("#createTaxModal").modal("show");
    };

    openAddPdvForm = () => {
        this.pdvModel = {};
        this.pdvModel.situacao = 1;
        $("#createPdvModal").modal("show");
    };

    savePdv = () => {
        this.loading = true;
        this.pdvSerivce
            .savePdv(this.establishment.establishmentId, this.pdvModel)
            .subscribe(
                data => {
                    console.log(data);
                    switch (data.code) {
                        case 200:
                            const auxArr = [];
                            auxArr.push(data.object);
                            this.hasPdv = auxArr;
                            this.getTaxes(data.object.posId, this.filtro);
                            $("#createPdvModal").modal("hide");
                            setTimeout(
                                () =>
                                    $("#modalCadastroPdvSucesso").modal("show"),
                                500
                            );
                            break;
                            default:
                            this.loading = false;
                            break;
                    }
                },
                error => {
                    this.loading = false;
                    console.log("Error")
                }
            );
    };

    saveTax = () => {
        this.loading = true;
        this.pdvSerivce.saveTax(this.hasPdv[0].posId, this.taxModel).subscribe(
            data => {
                console.log(data);
                switch (data.code) {
                    case 200:
                        this.getTaxes(this.hasPdv[0].posId, this.filtro);
                        $("#createTaxModal").modal("hide");
                        setTimeout(
                            () => $("#modalCadastroSucesso").modal("show"),
                            500
                        );
                        break;
                        default:
                        this.loading = false;
                        break;
                }
            },
            error => {
                this.loading = false;
                console.log("Error")
            }
        );
    };

    getTaxes = (posId, filtro) => {
        this.loading = true;
        this.pdvSerivce.getTaxes(posId, this.filtro).subscribe(
            data => {
                console.log(data);
                switch (data.code) {
                    case 200:
                        for (let i = data.object.length - 1; i >= 0; i--) {
                            if (data.object[i].status == "ACTIVE")
                                data.object[i].statusPush = true;
                            else data.object[i].statusPush = false;
                        }
                        this.loading = false;
                        this.listaServicos = data.object;
                        break;
                    default:
                        this.loading = false;
                        break;
                }
            },
            error => {
                this.loading = false;
                console.log("Error");
            }
        );
    };

    toDelete = elementPosition => {
        const copy = Object.assign({}, this.listaServicos[elementPosition]);
        this.deletable = copy;
    };

    deleteService = () => {
        this.loading = true;
        this.pdvSerivce.deleteTax(this.deletable).subscribe(
            data => {
                console.log(data);
                switch (data.code) {
                    case 200:
                        this.getTaxes(this.hasPdv[0].posId, this.filtro);
                        $("#modalConfirmDelete").modal("hide");
                        setTimeout(
                            () => $("#modalDeleteSucesso").modal("show"),
                            500
                        );
                        break;
                    case 246:
                        this.getTaxes(this.hasPdv[0].posId, this.filtro);
                        $("#modalConfirmDelete").modal("hide");
                        setTimeout(
                            () => $("#modalDeleteError").modal("show"),
                            500
                        );
                        break;
                    default:
                        this.loading = false;
                        break;
                }
            },
            error => {
                this.loading = false;
                console.log("Error");
            }
        );
    };

    pushedButton = elementPosition => {
        const copy = Object.assign({}, this.listaServicos[elementPosition]);
        copy.situacao = 0;
        if (copy.statusPush) copy.situacao = 1;

        this.loading = true;
        this.pdvSerivce.saveTax(this.hasPdv[0].posId, copy).subscribe(
            data => {
                switch (data.code) {
                    case 200:
                        this.getTaxes(this.hasPdv[0].posId, this.filtro);
                        break;
                    default:
                        this.loading = false;
                        break;
                }
            },
            error => {
                this.loading = false;
                console.log("Error");
            }
        );
    };

    search = () => this.getTaxes(this.hasPdv[0].posId, this.filtro);
}
