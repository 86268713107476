import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { ResponseFormat } from '@app/types/response-format';

@Injectable()
export class BuildingsService {
    constructor(private http: HttpClient) { }

    getOwnerEmployees = (establishmentId) => this.http.get<ResponseFormat>(`vivakey/rest/building/getOwnerEmployees?establishmentId=${establishmentId}`);

    getPropertyOwner = (establishmentId) => this.http.get<ResponseFormat>(`vivakey/rest/building/getpropertyowner?establishmentId=${establishmentId}`);

    getServices = () => this.http.get<ResponseFormat>(`vivakey/rest/serviceProvided/getServicesProvided`)
    getProvidedServices = (buildingId) => this.http.get<ResponseFormat>(`vivakey/rest/serviceProvided/getServicesProvidedByBuilding/${buildingId}`)
    putServices = (buildingId, body) => this.http.put<ResponseFormat>(`vivakey/rest/serviceProvided/servicesProvidedByBuilding/${buildingId}/update`, body)


    get = (establishmentId) => this.http.get<ResponseFormat>(`vivakey/rest/building/getBuildings?establishmentId=${establishmentId}`);

    getBuilding = (establishmentId, buildingId) => this.http.get<ResponseFormat>(`vivakey/rest/building/getBuildings?establishmentId=${establishmentId}&buildingId=${buildingId}`);

    getPolicy = (buildingId) => this.http.get<ResponseFormat>(`vivakey/rest/building/getbuildingpolitics/${buildingId}`);

    updatePolicy = (data) => this.http.put<ResponseFormat>(`vivakey/rest/building/politicsbuilding`, data);

    save(item, establishmentId) {

        if (item.buildingId)
            return this.update(item);
        else
            return this.insert(item, establishmentId);
    }

    insert = (item, establishmentId) => this.http.post<ResponseFormat>(`vivakey/rest/building?establishmentId=${establishmentId}`, item);

    update = (item) => this.http.put<ResponseFormat>(`vivakey/rest/building/changeBuilding?buildingId=${item.buildingId}`, item);

    delete = (id) => this.http.delete<ResponseFormat>(`vivakey/rest/building/${id}`);

    refreshTokenAutomaticCheckin = (buildingid) => {
        return this.http.put<ResponseFormat>(`vivakey/rest/building/renewsautomatictokencheckinbuilding/${buildingid}`, {});
    }

}
