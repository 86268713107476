export type InitialCofig = {
    buklyHotelId: number,
    establishmentId: number,
    user: string,
    password: string,
    hotelCode: string
};

export type RoomConfig = {
    categories: Categorie[],
    channels: Channel[],
    roomStays: RoomStay[],
    existentConfigurations: ExistentConfig[]
};

export type UnitType = {
    unitTypeId: number,
    name: string,
    descripton: string
};

export type Categorie = {
    categoryId: number,
    name: string,
    description: string,
    unitType: UnitType,
};

export type Channel = {
    channelId: number,
    name: string,
    description: string,
    siroName: string
};

export class RoomType {
    roomTypeCode: string;
    roomDescription: {
        name: string
    };
    plans: RatePlan[];
};

export type RatePlan = {
    ratePlanCode: string,
    ratePlanDescription: {
        name: string
    }
};

export type RoomStay = {
    roomTypes: { roomType: RoomType[] },
    ratePlans: { ratePlan: RatePlan[] }
};

export class ExistentConfig {
    buklyHotelId: number;
    categoryName?: string;
    categoryId: number;
    channelName?: string;
    channelId: number;
    roomTypeCode: string;
    roomTypeName?: string;
    ratePlanCode: string;
    ratePlanName?: string;
};


    
  