import createNumberMask from 'text-mask-addons/dist/createNumberMask'

const masks = {
	cpf: [ /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/],
	cnpj: [ /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/,/\d/],
	date: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/],
	phone_with_ddd: ['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
	cep: [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/],
	phone: (rawValue) => {
		var length = rawValue.replace(/\D/g, '').length

		console.log("rawValue.indexOf('+'): ", rawValue.indexOf('+'));

		//if(length >= 11) {

			// if(rawValue.indexOf('+') > -1)
			// 	return ["+",/\d/,/\d/," ", "(",/\d/,/\d/,")"," ",/\d/,/\d/,/\d/,/\d/,/\d/,"-",/\d/,/\d/,/\d/,/\d/];
			// else
				return ["(",/\d/,/\d/,")"," ",/\d/,/\d/,/\d/,/\d/,/\d/,"-",/\d/,/\d/,/\d/,/\d/];
		//}
		

		// if(rawValue.indexOf('+') > -1)
		// 	return ["+",/\d/,/\d/," ", "(",/\d/,/\d/,")"," ",/\d/,/\d/,/\d/,/\d/,"-",/\d/,/\d/,/\d/,/\d/];
		// else
		// 	return ["(",/\d/,/\d/,")"," ",/\d/,/\d/,/\d/,/\d/,"-",/\d/,/\d/,/\d/,/\d/];
	}
}

export default masks