import { Component, OnInit } from '@angular/core';
import { ReservationsService } from '../reservations/reservations.service';
import { AvailabilityService } from './availability.service';
import * as moment from 'moment';
import { Channel } from '@app/types/Channel';
import { Categorie } from '@app/types/Categorie';
import { strLimit } from '@utils/helpers'
import { TranslateService } from '@ngx-translate/core';
import { AppStateService } from '@src/app/layouts/app-layout.service';

@Component({
    selector: 'app-availability',
    templateUrl: './availability.component.html',
    styleUrls: [
        './availability.component.css'
    ]
})

export class AvailabilityComponent implements OnInit
{
	selectStartDate: any;
	quantityDays: any;
	selectCategoria: any;
	selectChannel	: any;
	categories: Categorie[] = [];
	channels: Channel[] = [];
	availability: any = [];
	categoryCounter: any = [];
	counter: any = {};
    loading: boolean = true

    strLimit = strLimit

    constructor(
        private reservationsService: ReservationsService,
        private availabilityService: AvailabilityService,
        private translate: TranslateService,
        private appStateService: AppStateService
    ) { }

    ngOnInit()
    {
        //inicializando o filtro com a opção Todos e setando o original como todos

        const channel: Channel = {
            channelId: 0,
            name: this.translate.instant('all'),
            description: '',
            siroName: undefined
        }
        this.channels.push(channel);

        const categorie: Categorie = {
            name: this.translate.instant('all'),
            categoryId: 0,
            description: '',
            establishmentId: 0,
            peopleQuantity: 0,
            unitTypeId: '0'
        }
        this.categories.push(categorie);

        this.selectChannel = 0;
        this.selectCategoria = 0;
        this.selectStartDate = moment()
        this.quantityDays = 11;
        this.initMap(false);

        const establishment = this.appStateService.currentEstablishment;

        //busca dos servicos para o filtro select
        this.reservationsService.getChannels(establishment.establishmentId).subscribe(data =>
        {
            switch (data.code)
            {
                case 200:
                    for (let i = data.object.length - 1; i >= 0; i--)
                    {
                        this.channels.push(data.object[i]);
                    }
                    break;
            }
        }, error => {
            console.log("Error")
        });


        this.reservationsService.getCategories(establishment.establishmentId).subscribe(data =>
        {
            console.log(data);
            switch (data.code)
            {
                case 200:
                    for (let i = data.object.length - 1; i >= 0; i--)
                    {
                        this.categories.push(data.object[i]);
                    }
                    break;

            }
        }, error => console.log("Error"));
    }

    initMap = startDate =>
    {
        //busca reservas (montando o objeto) para o mapa de ocupacao
        this.loading = true
        this.availabilityService.getReservations(this.selectStartDate.format('YYYY-MM-DD'), this.quantityDays, this.selectCategoria, this.selectChannel).subscribe(data =>
        {
            console.log("Reservations", data.object);
            switch (data.code)
            {
                case 200:
                    this.mountMap(data.object, startDate);
                    break;
                case 219:
                    console.log('no permission');
                    break;
            }
            this.loading = false
        }, error => {
            this.loading = false
            console.log('Error')
        });
    }

    mountMap = (reservations, startDate) => 
    {
        // percorre o retorno do servico de reservas
        for (let i = 0; i < reservations.length; i++) 
        {
            // adicionar um array com os dias a serem exibidos
            // (linha na frente do nome da categoria, com tarifarios)
            reservations[i].daysToShow = this.availabilityService.generateDaysToShow(this.quantityDays, startDate);

            const units = reservations[i].units;

            if (units)
            {
                // percorre os quartos para gerar o array de dias dentro de cada um
                for (let j = 0; j < units.length; j++) 
                {
                    const unity = units[j];
                    let unityIsActive = false;

                    if (unity.unity && unity.unity.status)
                        unityIsActive = 'ACTIVE' === unity.unity.status;

                    unity.daysToShow = this.availabilityService.generateDaysToShow(this.quantityDays, startDate);

                    unity.daysToShow = this.availabilityService.getDayTariffary(unity.daysToShow, reservations[i].tariffs, reservations[i], unityIsActive);

                    // caso tenha reserva percorre para preencher
                    if (unity.reservation) {
                        unity.daysToShow = this.availabilityService.getDaysReservations(
                            unity.daysToShow, 
                            unity.reservation, 
                            reservations[i],
                            unityIsActive
                            );
                    }

                    // in case of locked units
                    if(unity.unity.unitLockeds && unity.unity.unitLockeds.length > 0) {
                        unity.daysToShow = this.availabilityService.calcLockedDays(unity, reservations[i])
                    }
                }
            }
        }

        this.availability = reservations;
    }

    filtrarMapa = () => this.initMap(this.selectStartDate);
}
