import { Component, OnInit } from "@angular/core";
import { PortadorService } from "../portador/portador.service";
import { Router, ActivatedRoute } from "@angular/router";
import Swal from 'sweetalert2';
import { NotifierService } from 'angular-notifier';
import { TranslateService } from '@ngx-translate/core';
import { HotelSettingsPageService } from '../hotel-settings/hotel-settings-page.service';
import { AppStateService } from "@src/app/layouts/app-layout.service";
declare var jquery: any;
declare var $: any;

@Component({
    selector: "app-portador",
    templateUrl: "./portador.component.html",
    styleUrls: ["./portador.component.css"]
})
export class PortadorComponent implements OnInit {
    portadorModel: any = {};
    listaPortadores: any = [];
    readOnly: boolean = false;
    deletable: any = {};
    invalidInput: boolean = false;
    loading: boolean = true;
    establishment: any;

    hasInvoiceOmie = () => {
        return this.appStateService.currentEstablishment.establishmentConfig.omieConfig ? this.appStateService.currentEstablishment.establishmentConfig.omieConfig.hasInvoiceOmie : false;
    };

    constructor(
        private appStateService: AppStateService,
        private router: Router,
        private portadorService: PortadorService,
        private hotelSettingsService: HotelSettingsPageService,
        private notifier: NotifierService,
        private translate: TranslateService,
    ) {

    }

    ngOnInit() {
        this.establishment =this.appStateService.currentEstablishment;
        this.getBanks();
    }

    openAddForm = () => {
        this.invalidInput = false;
        this.readOnly = false;
        this.portadorModel = {};
        $("#createPortadorModal").modal("show");

        if(this.hasInvoiceOmie() == true){
            this.getAccounts();
        }
    };

    openEditForm = elementPosition => {
        this.invalidInput = false;
        this.readOnly = false;
        var copy = Object.assign({}, this.listaPortadores[elementPosition]);
        this.portadorModel = copy;
        $("#createPortadorModal").modal("show");

        if(this.hasInvoiceOmie() == true){
            this.getAccounts();
        }
    };

    showDetails = elementPosition => {
        this.invalidInput = false;
        this.readOnly = true;
        var copy = Object.assign({}, this.listaPortadores[elementPosition]);
        this.portadorModel = copy;
        $("#createPortadorModal").modal("show");
    };

    goToPaymentWay = elementPosition => {
        sessionStorage.setItem(
            "currentPortador",
            JSON.stringify(this.listaPortadores[elementPosition])
        );
        this.router.navigate([`/app/${this.appStateService.currentEstablishmentId}/tipo-pagamento`]);
    };

    toDelete = elementPosition => {
        const copy = Object.assign({}, this.listaPortadores[elementPosition]);
        this.deletable = copy;
    };


    onSelectOmieCC(bank, event){
        bank.hasNCodCcManual = true;
    }

    save(){

        console.log('this.portadorModel', this.portadorModel);
        var err_existOmieCC;

        if(this.hasInvoiceOmie() == true){
            if(this.portadorModel.ncodCc == null){
                this.notifier.notify('error', this.translate.instant('portador.error_set_omie_config'))
                return;
            }

            this.listaPortadores.forEach(element => {
                if((element.bankId != this.portadorModel.bankId)  && element.ncodCc == this.portadorModel.ncodCc ){
                    err_existOmieCC = true;
                }
            });

            if(err_existOmieCC == true){
                this.notifier.notify('error', this.translate.instant('portador.error_exist_omie_id'))
            }
        }

        this.savePortador();
    }

    savePortador = () => {
        if (
            !this.portadorModel.name ||
            !this.portadorModel.code ||
            !this.portadorModel.agency ||
            !this.portadorModel.digit ||
            !this.portadorModel.countNumber
        )
            this.invalidInput = true;

        if (!this.invalidInput) {
            this.loading = true;
            this.portadorService.savePortador(this.portadorModel).subscribe(
                data => {
                    console.log(data);
                    switch (data.code) {
                        case 200:
                            this.getBanks();
                            if (this.portadorModel.bankId) {
                                $("#createPortadorModal").modal("hide");
                                setTimeout(
                                    () =>
                                        $("#modalEditarSucesso").modal("show"),
                                    500
                                );
                            } else {
                                $("#createPortadorModal").modal("hide");
                                setTimeout(
                                    () =>
                                        $("#modalCadastroSucesso").modal(
                                            "show"
                                        ),
                                    500
                                );
                            }
                            break;
                        default:
                            this.loading = false;
                            break;
                    }
                },
                error => {
                    this.loading = false;
                    console.log("Error");
                }
            );
        }
    };

    deletePortador = () => {
        this.loading = true;
        this.portadorService.deletePortador(this.deletable).subscribe(
            data => {
                console.log(data);
                switch (data.code) {
                    case 200:
                        this.getBanks();
                        $("#modalConfirmDelete").modal("hide");
                        setTimeout(
                            () => $("#modalDeleteSucesso").modal("show"),
                            500
                        );
                        break;
                    case 246:
                        $("#modalConfirmDelete").modal("hide");
                        setTimeout(
                            () => $("#modalDeleteBankInUse").modal("show"),
                            500
                        );
                        this.loading = false;
                        break;
                    default:
                        this.loading = false;
                        break;
                }
            },
            error => {
                this.loading = false;
                console.log("Error");
            }
        );
    };

    getBanks = () => {
        this.loading = true;
        this.portadorService.getBanks().subscribe(
            data => {
                console.log(data);
                this.loading = false;
                switch (data.code) {
                    case 200:
                        this.listaPortadores = data.object;
                        console.log("listaPortadores", this.listaPortadores);
                        break;
                    default:
                        break;
                }
            },
            error => {
                this.loading = false;
                console.log("Error");
            }
        );
    };



    getAccountsCount(): Promise<any> {

        var appKey = this.establishment.establishmentConfig.omieConfig.appKey;
        var appSecret = this.establishment.establishmentConfig.omieConfig.appSecret;

        return new Promise((resolve, reject) => {
            this.loading = true;
            this.portadorService.getAccounts(appKey, appSecret, this.accountListCurrPage, 100).subscribe(ret => {
                this.loading = false;
                if (ret.code == 200 && ret.object) {
                    resolve(ret);
                }
                else {
                    reject();
                }
            }, err => {
                this.loading = false;
                reject();
            })
        });
    }

    accountList: any[] = [];
    accountListCurrPage: number = 1;
    getAccounts() {
        this.getAccountsCount().then((ret) => {
            if (ret.object.conta_corrente_lista) {
                this.accountList = this.accountList.concat(ret.object.conta_corrente_lista);
                if (this.accountList.length >= ret.object.total_de_registros) {
                    var b = this.portadorModel;
                    var omieAccount = this.accountList.find(i => i.conta_corrente == b.countNumber && i.codigo_banco == b.code && i.codigo_agencia == b.agency);
                    if(omieAccount && this.portadorModel.ncodCc == null){
                        this.portadorModel.ncodCc = omieAccount.nCodCC;
                    }
                    else{
                        //this.portadorModel.ncodCc = null;
                        //this.notifier.notify('error', this.translate.instant('portador.error_set_omie_config'))
                    }
                }
            }
            if (this.accountListCurrPage < ret.object.total_de_paginas) {
                this.accountListCurrPage++;
                this.getAccounts();
            }
        })
    }


}
