import { Component, OnInit } from "@angular/core";
import { ReservationsListService } from "./reservation-list.service";
import { NotifierService } from 'angular-notifier';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { AppStateService } from "@src/app/layouts/app-layout.service";

declare var $: any;

function onlyUnique(value, index, self) {
	return self.indexOf(value) === index;
}

@Component({
	selector: "app-reservation-list",
	templateUrl: "./reservation-list.component.html",
	styleUrls: [
		"../../app.component.css",
		"../dashboard/dashboard.component.css",
		"./reservation-list.component.css"
	]
})
export class ReservationsListComponent implements OnInit {

	selectedEstablishment: any;
	reservations: any = null;

	// UI/UX
	loading: boolean = false

	// Filters
	responsibleName: string = '';
	mainGuestName: string = '';
	checkinDate: any;

	authorizationId: string = '';
	filterMode:number;

	constructor(
		private appStateService: AppStateService,
		private service: ReservationsListService,
		private router: Router,
		private notifier: NotifierService,
		private translate: TranslateService,) {

			this.checkinDate = moment();
	}

	ngOnInit() {
		this.selectedEstablishment = this.appStateService.currentEstablishment;

		this.query();
	}

	query() {
		if(this.filterMode == 1)
			this.queryAuthorizationId();
		else
			this.queryResp();
	}

	queryResp() {
		this.loading = true
		this.service
			.getReservations(
				{
					establishmentId: this.selectedEstablishment.establishmentId,
					checkinDate: this.checkinDate.format('YYYY-MM-DD'),
					name: this.responsibleName,
				})
			.subscribe(
				data => {
					if (data.object) {
						this.reservations = data.object
					} else {
						this.notifier.notify('default', this.translate.instant('error_msg.error_getdata' ))
					}
					this.loading = false
				},
				error => {
					this.loading = false
					this.notifier.notify('error', this.translate.instant('error_msg.error_getdata' ))
				}
			);
	}

	queryAuthorizationId() {
		this.loading = true
		this.service
			.getReservationsByAuthorizationId(this.selectedEstablishment.establishmentId, this.authorizationId)
			.subscribe(
				data => {
					if (data.object) {
						this.reservations = data.object
					} else {
						this.notifier.notify('default', this.translate.instant('error_msg.error_getdata' ))
					}
					this.loading = false
				},
				error => {
					this.loading = false
					this.notifier.notify('error', this.translate.instant('error_msg.error_getdata' ))
				}
			);
	}


	goToReservationSummaryInfo = reservationId =>
    {
		this.router.navigate([`app/${this.appStateService.currentEstablishmentId}/reservations/summary/${reservationId}`]);
		
        // this.service.getReservationSummaryInfo(reservationId).subscribe(data =>
        // {
        //     this.loading = false 
		// 	console.log("DETALHES", data);
		//   	switch (data.code)
        //     {
        //         case 200:

		// 			var reservation = data.object;
		// 			console.log('reservation', reservation);

		// 			sessionStorage.setItem('currentReservation', JSON.stringify(reservation));
					

     
		// 	    break;
		// 	}
		// },
		// (error: any) => {
        //     this.loading = false 
        //     console.log("Error")
        // });
	}



}
