import { Injectable } from '@angular/core';
import {ResponseFormat} from '@app/types/response-format';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse  } from '@angular/common/http';

@Injectable()
export class PdvService {

  constructor(private http : HttpClient) { }

  getPos(estabId){
  		return this.http.get<ResponseFormat>('vivakey/rest/pos/getPoses/'+estabId)
  }

  getTaxes(posId, filtro){
    var queryparam = '';
    if (filtro.name  && filtro.status != 'todos') { 
        queryparam = "?name="+filtro.name+"&status="+filtro.status; 
    } else {
      if (filtro.name)
         queryparam = "?name="+filtro.name;
      if ( filtro.status != 'todos')    
         queryparam = "?status="+filtro.status; 
    } 
  		return this.http.get<ResponseFormat>('vivakey/rest/additionalFee/getAdditionalFeees/'+posId+queryparam)
  }

  savePdv(estabId, pdvModel){
  	var body = {
	  "posId": null,
	  "establishmentId": estabId,
	  "name": pdvModel.name,
	  "status": pdvModel.situacao
  	}
	return this.http.post<ResponseFormat>('vivakey/rest/pos', body)
  }  

  saveTax(posId, taxModel){
  	console.log(taxModel);
  	var id = null;
  	if (taxModel.additionalFeeId)
        id = taxModel.additionalFeeId;
  	var body = {
	  "additionalFeeId": id,
	  "posId": posId,
	  "name": taxModel.name,
	  "status": taxModel.situacao
  	}
	return this.http.post<ResponseFormat>('vivakey/rest/additionalFee', body)
  }

  deleteTax(taxModel) {
  	return this.http.delete<ResponseFormat>('vivakey/rest/additionalFee/'+taxModel.additionalFeeId)
  }

}
