import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse  } from '@angular/common/http';
import {ResponseFormat} from '@app/types/response-format';
import { AppStateService } from '@src/app/layouts/app-layout.service';

@Injectable()
export class UsersService 
{

    constructor(private http : HttpClient, private appStateService: AppStateService,) { }
    
    getUsers = filtros =>
    {

        console.log(filtros);

        let establishment = this.appStateService.currentEstablishment;

        let baseUrl = 'vivakey/rest/employee/getEmployees/establishment/' + establishment.establishmentId;

        if (!filtros  || (filtros.job == 'todos' && !filtros.name)) {
            return this.http.get<ResponseFormat>(baseUrl);
        } else {
            baseUrl = 'vivakey/rest/employee/getEmployees/establishment?establishmentId=' + establishment.establishmentId
        }
        
        if (filtros.job.establishmentJobTitleId && filtros.name)
            return this.http.get<ResponseFormat>(baseUrl + '&establishmentJobTitleId=' + filtros.job.establishmentJobTitleId + '&name=' + filtros.name);
        
        if (filtros.job.establishmentJobTitleId)
            return this.http.get<ResponseFormat>(baseUrl + '&establishmentJobTitleId=' + filtros.job.establishmentJobTitleId);
        
        if (filtros.name)
            return this.http.get<ResponseFormat>(baseUrl + '&name=' + filtros.name);
    }

    getUsersFromCompany = () =>
    {

        let establishment = this.appStateService.currentEstablishment;

        return this.http.get<ResponseFormat>(`vivakey/rest/employee/getEmployees/establishment/${establishment.establishmentId}?getCompanyUsers`)
    }

    getJobs = () => {
        let establishment = this.appStateService.currentEstablishment;
        return this.http.get<ResponseFormat>('vivakey/rest/jobTitle/getEstablishmentJobTitles/' + establishment.establishmentId);
    }

    sendNewUser = user => this.http.post<ResponseFormat>('vivakey/rest/employee', user);

    udateUser = user => this.http.post<ResponseFormat>('vivakey/rest/employee', user);

    deleteUser = user => this.http.delete<ResponseFormat>('vivakey/rest/employee/' + user.employeeId);

    searchByCpf = cpf => {
        let establishment = this.appStateService.currentEstablishment;
        return this.http.get<ResponseFormat>('vivakey/rest/employee/getEmployeesGeneral?establishment='+ establishment.establishmentId + '&username=' + cpf);
    }

    reenviarEmail = cpf => this.http.get<ResponseFormat>('vivakey/rest/employee/resendActivationEmail/' + cpf);


    isManager = () => {

        var selectedCompany = this.appStateService.currentCompany;
        var selectedEstablishment = this.appStateService.currentEstablishment;

        var userInfo = JSON.parse(localStorage.getItem('currentUserInfo'));

        if(userInfo && selectedCompany) {

            if(userInfo.companyJobTitles instanceof Array) {

                // Check if user is manager at current company
                let jobTitle = userInfo.companyJobTitles.find(
                    job => job.companyJobTitle.company.companyId == selectedCompany.companyId
                    )

                if(jobTitle) {
                    if(jobTitle.companyJobTitle.manager) {
                        return true;
                    }
                }
            }

            if(userInfo.establishmentJobTitles instanceof Array) {

                // Check if user is manager at current establishment
                let jobTitle = userInfo.establishmentJobTitles.find(
                    job => job.establishmentJobTitle.establishment.establishmentId == selectedEstablishment.establishmentId
                    )

                if(jobTitle) {
                    if(jobTitle.establishmentJobTitle.manager) {
                        return true;
                    }
                }
            }
        }

        return false;

    }


    public isManagerFromCompanyId = (companyId) => {

        var isManager = false;
        var userInfo = JSON.parse(localStorage.getItem('currentUserInfo'));

        if(userInfo) {

            if(userInfo.companyJobTitles instanceof Array) {
                // Check if user is manager at current company
                userInfo.companyJobTitles.forEach(jobTitle => {
                    if(jobTitle.companyJobTitle.company.companyId == companyId
                    && jobTitle.companyJobTitle.manager) {
                        isManager = true;
                    }
                });
            }

            if(userInfo.establishmentJobTitles instanceof Array) {
                // Check if user is manager at current establishment
                userInfo.establishmentJobTitles.forEach(jobTitle => {
                    if(jobTitle.establishmentJobTitle.establishment.company.companyId == companyId
                    && jobTitle.establishmentJobTitle.manager) {
                        isManager = true;
                    }
                });
            }
        }

        return isManager;

    }

}
