import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.css']
})
export class LoadingComponent implements OnInit {
  loading = true;
  constructor() { }
  
  ngOnInit() {
  	 var that = this;
    setTimeout(function () {
            that.loading = false;
    }, 1000); 
  }

}
