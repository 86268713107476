import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse  } from '@angular/common/http';
import {ResponseFormat} from '@app/types/response-format';


export class Category {
    categoryId: number;
    buklyRoomTypeCode: number;
    unitTypeId: number;
    buildingId: number;
    description: string = '';
    name: string = '';
    peopleQuantity: number = 1;
    cleaningFee: number;
    minimalPrice: number;
    unitType: any;
}

@Injectable()
export class CategoriesService
{
    constructor(private http : HttpClient) { }

    getCategories = (establishmentId, _params?) =>
    {
        let params = new HttpParams();
		Object.keys(_params).map((key, index) => {
            if(_params[key] != undefined && _params[key] != '')
			    params = params.append(key, _params[key])
		})

        return this.http.get<ResponseFormat>('vivakey/rest/category/getCategories/'+establishmentId+'/',  {
			params: params
        });
    }

    getBuildingCategories = (establishmentId, _params?) =>
    {
        let params = new HttpParams();
		Object.keys(_params).map((key, index) => {
            if(_params[key] != undefined && _params[key] != '')
			    params = params.append(key, _params[key])
        })
        
        return this.http.get<ResponseFormat>(`vivakey/rest/category/getBuildingCategories?establishmentId=${establishmentId}`,  {
			params: params
        });
    }

    getTypes = () => this.http.get<ResponseFormat>('vivakey/rest/unitType/getUnitTypes');

    postCategory = body => this.http.post<ResponseFormat>('vivakey/rest/category',body);

    getUnits = (establishmentId, categoryId) => this.http.get<ResponseFormat>('vivakey/rest/unit/getUnits/?establishmentId='+establishmentId+'&categoryId='+categoryId);

    deleteCategory = (categoryId) => this.http.delete<ResponseFormat>('vivakey/rest/category/'+categoryId);

    getDisponibleCategories = (establishmentId, channelId, reservationId?, checkin?, checkout?) =>
    {
        console.log("establishmentId", establishmentId);
        console.log("channelId", channelId);
        console.log("reservationId", reservationId);
        console.log("checkinDate", checkin);
        console.log("checkoutDate", checkout);

        let query = "?";

        if (reservationId) 
        {
            query += "reservationId=" + reservationId;

            if (checkin) query += "&checkInDate=" + checkin;
            if (checkout) query += "&checkOutDate=" + checkout;
        }
        else
        {
            if (checkin) query += "checkInDate=" + checkin;
            if (checkout) query += "&checkOutDate=" + checkout;
        }
        
        return this.http.get<ResponseFormat>(`vivakey/rest/category/getDisponibleCategory/${establishmentId}/${channelId}${query}`);
    }
}
