import { Component, OnInit, Inject } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import masks from "../../../utils/masks";

import Swal from 'sweetalert2';
import { FixResErrorsService } from './fix-reservation-error.service';
import { AppStateService } from '@src/app/layouts/app-layout.service';

@Component({
  selector: 'app-fix-reservation-error-modal',
  templateUrl: './fix-reservation-error-modal.component.html',
  styleUrls: ['./fix-reservation-error-modal.component.scss']
})
export class FixResErrorsModalComponent implements OnInit {

  loading: boolean = false;
	masks = masks;
  selectedEstablishment: any;
  omieError: any;
  invoice: any;
  hasAddressError: boolean = false;
  hasPendingCorrection: boolean = true;
  dataList: any[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private appStateService: AppStateService,
    private notifier: NotifierService,
    public service: FixResErrorsService,
    public dialogRef: MatDialogRef<FixResErrorsModalComponent>,
    private translate: TranslateService,) {

  }

  ngOnInit() {
    this.selectedEstablishment = this.appStateService.currentEstablishment;
    this.omieError = this.data.item;

    if (this.omieError.reservationId && this.omieError.code == 2) {
      this.hasAddressError = true;
      this.hasPendingCorrection = true;
      this.getInvoice(this.omieError.reservationId).then(ret => {
        //Se existir uma invoice, mostrar os dados para alterar
      })
    } else {
      this.hasPendingCorrection = false;
      this.getInvoice(this.omieError.reservationId).then(ret => {
        this.hasAddressError = true;
        //Se existir uma invoice, mostrar os dados para alterar
      })
    }
  }


  getInvoice(reservationId): Promise<any> {
    return new Promise((resolve, reject) => {
      this.loading = true;
      this.service.getInvoice(reservationId).subscribe(ret => {
        this.loading = false;
        switch (ret.code) {
          case 200:
            {
              this.invoice = ret.object;
              resolve(ret.object);
              break;
            }
          default:
            reject();
        }
      }, err => {
        this.loading = false;
        reject();
      })
    });
  }

  checkAndFixZipCode() {
    this.loading = true;
    this.service.checkZipCode(this.invoice.zipCode).subscribe(ret => {
      console.log(ret);
      this.loading = false;

      if (ret.erro == true) {
        console.log('CEP Inválido!');
      } else {
        console.log('CEP Encontrado!');
        this.confirmAdress(ret);
      }

    }, err => {
      this.loading = false;
    })
  }

  confirmAdress(item){
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn'
      },
      buttonsStyling: false
    })
  
    var html = `
    <b>Logradouro:</b>${item.logradouro}
    <br>
    <b>Bairro:</b>${item.bairro}
    <br>
    <b>${item.localidade} - ${item.uf}</b>
    `;

    swalWithBootstrapButtons.fire({
      position: 'center',
      title: 'Consulta do CEP:',
      html: html,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: this.translate.instant('confirmar'),
      cancelButtonText: this.translate.instant('cancel'),
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        this.saveInvoice();
      } else if (result.dismiss === Swal.DismissReason.cancel) {
  
      }
    });
  }

  saveInvoice() {
    this.loading = true;
    this.service.saveInvoice(this.invoice, this.omieError.reservationId, this.selectedEstablishment.establishmentId).subscribe(ret => {
      this.loading = false;
      if (ret.code == 200) {
        console.log('CEP Atualizado!');

        Swal.fire(this.translate.instant('fix_res_errors.zipCode_confirmed'), '', 'success');

        this.hasPendingCorrection = false;
      }
    }, err => {
      this.loading = false;
    })
  }


  save() {

    this.service.saveOmieError(this.omieError.omieOsErrorId).subscribe(ret => {

      this.dialogRef.close();

    }, err => {
      
    })

  }

}
