import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpRequest, HttpEventType } from '@angular/common/http';
import { ResponseFormat } from './types/response-format';
import { dataURItoBlob } from './utils/helpers';
import { AppStateService } from './layouts/app-layout.service';



@Injectable()
export class CommonService {

    constructor(private http: HttpClient, private appStateService: AppStateService) {
    }



    getImage(ID) {
        return this.http.get<any>(
            `vivakey/rest/mediacontent/${ID}`);
    }


    checkZipCode = (zipCode) => {
        return this.http.get<any>(`https://viacep.com.br/ws/${zipCode}/json`);
    }

    saveImage = (body, typeUrl) => {

        var imgBlob = dataURItoBlob(body.data);

        console.log("imgBlob: ", JSON.stringify(imgBlob));

        const formData = new FormData();
        formData.append('file', imgBlob);
        formData.append('type', body.contentType);



        return this.http.post<ResponseFormat>(
            'vivakey/rest/mediacontent/' + typeUrl,
            formData
        )
    }


    editProfilePhoto = (image, mainGuestId) => {

        var selectedEstablishment = this.appStateService.currentEstablishment;

        var imgBlob = dataURItoBlob(image);
        console.log("imgBlob: ", JSON.stringify(imgBlob));

        const formData = new FormData();
        formData.append('file', imgBlob);
        formData.append('type', 'PROFILE_PHOTO');

        return this.http.post<ResponseFormat>(
            `vivakey/rest/mediacontent/editprofilephoto/${selectedEstablishment.establishmentId}/${mainGuestId}`,
            formData
        )
    }


    editDocumentPhoto = (image, mainGuestId) => {

        var selectedEstablishment = this.appStateService.currentEstablishment;

        var imgBlob = dataURItoBlob(image);
        console.log("imgBlob: ", JSON.stringify(imgBlob));

        const formData = new FormData();
        formData.append('file', imgBlob);
        formData.append('type', 'DOCUMENT_PHOTO');

        return this.http.post<ResponseFormat>(
            `vivakey/rest/mediacontent/editdocumentphoto/${selectedEstablishment.establishmentId}/${mainGuestId}`,
            formData
        )
    }

}