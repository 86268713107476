import { Component, Inject, OnInit } from '@angular/core';
import { CreateHotelService } from '@src/app/pages/create-hotel/create-hotel.service';
import { NotifierService } from 'angular-notifier';
import { validateCNPJ } from '../../../utils/validators'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { removeChars } from '@src/app/utils/helpers';
import { TranslateService } from '@ngx-translate/core';
import { ConfigPaymentGatewayService } from './config-payment-gateway.service';
import { AppStateService } from '@src/app/layouts/app-layout.service';


@Component({
  selector: 'app-config-payment-gateway',
  templateUrl: './config-payment-gateway-modal.component.html',
  styleUrls: ['./config-payment-gateway-modal.component.scss']
})
export class ConfigurePaymentGatewayModalComponent implements OnInit {

  loading: boolean = true;
  bankList: any;
  selectedBank: any;
  selectedEstablishment: any;
  bankPaymentType_list = [];
  hasSavePaymentGateway = false;

  sellerId: string = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private appStateService: AppStateService,
    private service: ConfigPaymentGatewayService,
    private notifier: NotifierService,
    public dialogRef: MatDialogRef<ConfigurePaymentGatewayModalComponent>,
    private translate: TranslateService, ) {

      this.sellerId = data.sellerId;

    }

  ngOnInit() {
    this.selectedEstablishment = this.appStateService.currentEstablishment;
    this.getBanks();
  }


  initializePaymentTypes() {
    this.bankPaymentType_list = [];

    //this.bankPaymentType_list.push({ bankPaymentType: null, type: "D", paymentTypeId: 3, brand: "VISA" });
    this.bankPaymentType_list.push({ bankPaymentType: null, type: "C", paymentTypeId: 4, brand: "VISA" });

    //this.bankPaymentType_list.push({ bankPaymentType: null, type: "D", paymentTypeId: 3, brand: "MASTERCARD" });
    this.bankPaymentType_list.push({ bankPaymentType: null, type: "C", paymentTypeId: 4, brand: "MASTERCARD" });

    //this.bankPaymentType_list.push({ bankPaymentType: null, type: "D", paymentTypeId: 3, brand: "AMERICAN EXPRESS" });
    this.bankPaymentType_list.push({ bankPaymentType: null, type: "C", paymentTypeId: 4, brand: "AMERICAN EXPRESS" });

    //this.bankPaymentType_list.push({ bankPaymentType: null, type: "D", paymentTypeId: 3, brand: "HIPERCARD" });
    this.bankPaymentType_list.push({ bankPaymentType: null, type: "C", paymentTypeId: 4, brand: "HIPERCARD" });

    //this.bankPaymentType_list.push({ bankPaymentType: null, type: "D", paymentTypeId: 3, brand: "ELO" });
    this.bankPaymentType_list.push({ bankPaymentType: null, type: "C", paymentTypeId: 4, brand: "ELO" });

    //this.bankPaymentType_list.push({ bankPaymentType: null, type: "D", paymentTypeId: 3, brand: "SOROCRED" });
    this.bankPaymentType_list.push({ bankPaymentType: null, type: "C", paymentTypeId: 4, brand: "SOROCRED" });
  
    //this.bankPaymentType_list.push({ bankPaymentType: null, type: "D", paymentTypeId: 3, brand: "AGIPLAN" });
    this.bankPaymentType_list.push({ bankPaymentType: null, type: "C", paymentTypeId: 4, brand: "AGIPLAN" });

    //this.bankPaymentType_list.push({ bankPaymentType: null, type: "D", paymentTypeId: 3, brand: "BANESCARD" });
    this.bankPaymentType_list.push({ bankPaymentType: null, type: "C", paymentTypeId: 4, brand: "BANESCARD" });
  
    //this.bankPaymentType_list.push({ bankPaymentType: null, type: "D", paymentTypeId: 3, brand: "CABAL" });
    this.bankPaymentType_list.push({ bankPaymentType: null, type: "C", paymentTypeId: 4, brand: "CABAL" });
  
    //this.bankPaymentType_list.push({ bankPaymentType: null, type: "D", paymentTypeId: 3, brand: "CREDSYSTEM" });
    this.bankPaymentType_list.push({ bankPaymentType: null, type: "C", paymentTypeId: 4, brand: "CREDSYSTEM" });
  
    //this.bankPaymentType_list.push({ bankPaymentType: null, type: "D", paymentTypeId: 3, brand: "CREDZ" });
    this.bankPaymentType_list.push({ bankPaymentType: null, type: "C", paymentTypeId: 4, brand: "CREDZ" });
  
    //this.bankPaymentType_list.push({ bankPaymentType: null, type: "D", paymentTypeId: 3, brand: "DINERSCLUB" });
    this.bankPaymentType_list.push({ bankPaymentType: null, type: "C", paymentTypeId: 4, brand: "DINERSCLUB" });
    
    //this.bankPaymentType_list.push({ bankPaymentType: null, type: "D", paymentTypeId: 3, brand: "JCB" });
    this.bankPaymentType_list.push({ bankPaymentType: null, type: "C", paymentTypeId: 4, brand: "JCB" });
  
}


  parceTypeDesc(type) {
    switch (type) {
      case 'D':
        return 'Débito';
      case 'C':
        return 'Crédito';
      default:
        return type;
    }
  }

  configureItem(bankPayment) {
    bankPayment.bankPaymentType = {
      bankPaymentTypeId: null,
      bankId: this.selectedBank.bankId,
      paymentTypeId: bankPayment.paymentTypeId,
      name: `${this.parceTypeDesc(bankPayment.type)} - Gateway`,
      brand: bankPayment.brand,
      cardTax: 0,
      cardTax2to6: 0,
      cardTax7to12: 0,
      status: "1",
      cashFlow: 0,
      isPaymentGateway: true
    };

    if((bankPayment.bankPaymentType.numberDaysForPayment || 0) <= 0 ) {
      var itemHasday = this.bankPaymentType_list.find(i=> i.bankPaymentType != null && i.bankPaymentType.numberDaysForPayment > 0);
      if(itemHasday) {
        console.log('itemHasday', itemHasday);
        bankPayment.numberDaysForPayment = itemHasday.bankPaymentType.numberDaysForPayment;
        bankPayment.bankPaymentType.numberDaysForPayment = itemHasday.bankPaymentType.numberDaysForPayment;
      }
    }

    setTimeout(() => bankPayment.postPending = true, 50);
  }

  editBankPaymentType(bankPayment) {
    if (bankPayment.bankPaymentType != null && bankPayment.bankPaymentType.bankPaymentTypeId != null) {
      setTimeout(() => { 
        bankPayment.postPending = true;
        
        console.log('bankPayment', bankPayment);

        if(bankPayment.bankPaymentType.numberDaysForPayment <= 0 ) {
          var itemHasday = this.bankPaymentType_list.find(i=>i.bankPaymentType.numberDaysForPayment > 0);
          if(itemHasday) {
            console.log('itemHasday', itemHasday);
            bankPayment.numberDaysForPayment = itemHasday.bankPaymentType.numberDaysForPayment;
            bankPayment.bankPaymentType.numberDaysForPayment = itemHasday.bankPaymentType.numberDaysForPayment;
          }
        }

      },
      50);
    }
  }

  getBanks() {
    console.log("get banks");
    this.loading = true;
    this.service.getBanks().subscribe(data => {
      this.loading = false
      if (data.object) {
        this.bankList = data.object;
      }
      this.loading = false
    }, error => {
      this.loading = false
      this.notifier.notify('error', this.translate.instant('error_msg.error_getdata'))
    });
  }



  onSelectBank(event) {
    this.initializePaymentTypes();
    this.loading = true;
    this.service.getBankPaymentType(this.selectedEstablishment.establishmentId, event.bankId, true).subscribe(data => {
      this.loading = false
      if (data.object) {
        console.log(data.object)
        data.object.forEach(item => {

          item.brand =  item.brand == "AMEX" ? "AMERICAN EXPRESS" :  item.brand;

          var pType = this.bankPaymentType_list.find(i => i.paymentTypeId == item.paymentType.paymentTypeId && i.brand == item.brand);
          if (pType != null) {
            pType.bankPaymentType = item;
          }
        });

        var total_configured = this.bankPaymentType_list.filter(i => i.bankPaymentType != null).length;
        this.hasSavePaymentGateway = total_configured >= this.bankPaymentType_list.length ? true : false;
      }
      this.loading = false
    }, error => {
      this.loading = false
      this.notifier.notify('error', this.translate.instant('error_msg.error_getdata'))
      console.log("Error", error)
    });
  }

  saveBankPaymentType(item) {
    var bankPaymentType = item;

    if (bankPaymentType.cardTax <= 0) {
      this.notifier.notify('error', this.translate.instant('payment-gateway.enter_cardtax'));
      return;
    }

    if (bankPaymentType.bankPaymentTypeId != null) {
      bankPaymentType = {
        bankPaymentTypeId: bankPaymentType.bankPaymentTypeId,
        bankId: bankPaymentType.bank.bankId,
        paymentTypeId: bankPaymentType.paymentType.paymentTypeId,
        name: bankPaymentType.name,
        brand: bankPaymentType.brand,
        cardTax: bankPaymentType.cardTax,
        cardTax2to6: bankPaymentType.cardTax2to6,
        cardTax7to12: bankPaymentType.cardTax7to12,
        status: bankPaymentType.status == 'ACTIVE' ? '1' : '0',
        cashFlow: bankPaymentType.cashFlow == 'YES' ? '1' : '0',
        isPaymentGateway: true,
        numberDaysForPayment: bankPaymentType.numberDaysForPayment
      }
    }

    this.loading = true;
    this.service.saveBankPaymentType(bankPaymentType).subscribe((data) => {
      this.loading = false
      this.onSelectBank(this.selectedBank);
    }, error => {
      this.loading = false
      this.notifier.notify('error', this.translate.instant('payment-gateway.error_save_payment_type'))
    });
  }

  activateGateway() {
    this.loading = true
    this.service.activateGateway(this.selectedEstablishment.establishmentId, this.selectedBank.bankId).subscribe((data) => {
      this.notifier.notify('success', this.translate.instant('payment-gateway.activated_gateway'));
      this.loading = false
      this.dialogRef.close({sellerId: this.sellerId});
    }, error => {
      this.loading = false
      this.notifier.notify('error', this.translate.instant('payment-gateway.error_activate_gateway'))
    });
  }

}
