

export class GroupForPay {
    onlinePayments: paymentInstalments[] = [];
    balconyPayments: paymentInstalments[] = [];

    qtdPAIED_onlinePayments: number = 0;
    qtdPAIED_balconyPayments: number = 0;

    paidAmountAbsolute: number = 0;

    hasOnlineSelected: boolean;
    hasBalconySelected: boolean;

    selectedPayments: paymentInstalments[] = [];
}


export class GroupForReversal {
    onlinePayments: paymentInstalments_Reversal[] = [];
    balconyPayments: paymentInstalments_Reversal[] = [];

    paidAmountAbsolute: number = 0;

    hasOnlineSelected: boolean;
    hasBalconySelected: boolean;
    
    selectedPayments: paymentInstalments_Reversal[] = [];
}




export class GroupReservationForPay {

    groupReservationId: number;
    buklyId: string;
    virtualCard: boolean;
    isPaid: boolean;
    hasPendingIssues: boolean;
    cardBlocked: boolean = false;
    authorizationId: string;


    reservations: any[] = [];
    multipleReservations = false;

    
    
    dataGroup: GroupForPay;
    paymentInstalments: paymentInstalments[] = [];

    dataGroupForReversal: GroupForReversal;
    paymentInstalmentsReversal: paymentInstalments_Reversal[] = [];

}

export class paymentInstalments {
    reservationId: number;
    groupPaymentId: number;

    payments: any[] = [];

    paymentStatus: string;
    online: boolean;

    name: string;  
    groupName: string;
    releaseDate: any;
    reversalDate: any;
    instalmentNumber: any = 1;
    selectedWayOfPayment: any;
    manualSaveMode: string;

    groupPaymentStatus: number;
    billingLinkId: string;


    totalInstalmentsValue: number = 0.0;
    totalAditionalFeeValue: number = 0.0;

    totalPaymentValue: number = 0.0;
    authorizationId: string;

    selected: boolean = false;

    instalment: number = 0;

}



export class paymentInstalments_Reversal {
  
    groupPaymentId: number;
    payments: any[] = [];

    online: boolean;
 
    groupName: string;
    releaseDate: any;
    reversalDate: any;
    
    totalInstalmentsValue: number = 0.0;
    totalAditionalFeeValue: number = 0.0;
    totalPaymentValue: number = 0.0;

    selected: boolean = false;

    payment_method: string;

    loading: boolean = false;
}