import { Component, OnInit, Inject, Input, Output, EventEmitter } from '@angular/core';
import { CreateHotelService } from '@src/app/pages/create-hotel/create-hotel.service';
import { NotifierService } from 'angular-notifier';
import { validateCNPJ } from '../../../utils/validators'
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { removeChars } from '@src/app/utils/helpers';
import { TranslateService } from '@ngx-translate/core';
import { ReservationPaymentService } from '@src/app/pages/reservation-payment/reservation-payment.service';
import { GroupReservationForPay } from '@src/app/types/GroupReservation';
import { ReservationsService } from '@src/app/pages/reservations/reservations.service';
import * as moment from 'moment';
import Swal from 'sweetalert2';
import { AppStateService } from '@src/app/layouts/app-layout.service';


@Component({
  selector: 'app-reservation-payment-reversal-modal',
  templateUrl: './reservation-payment-reversal-modal.component.html',
  styleUrls: ['./reservation-payment-reversal-modal.component.scss']
})
export class ReservationPaymentReversalModalComponent implements OnInit {

  loading: boolean = false;

  selectedEstablishment: any;

  @Output() onSaved: EventEmitter<any> = new EventEmitter();

  @Input()
  currentReservation: any;
  @Input()
  groupReservationForPay: GroupReservationForPay;

  @Input()
  viewMode: string = '';// 'reverse' | 'preview'


  reversalValue: number = 0;
  reversalReason = '';
  posted: boolean = false;
  warnNoSelected = false;

  currencyConfig;

  constructor(
    private appStateService: AppStateService,
    private reservationPaymentService: ReservationPaymentService,
    private notifier: NotifierService,
    private translate: TranslateService,
    public dialog: MatDialog,) {
      this.currencyConfig =  { prefix: translate.currentLang == 'en' ? '$' : 'R$', suffix:'', thousands: '.', decimal: ',', align : 'left', allowNegative : 'false' };

  }

  ngOnInit() {
    this.selectedEstablishment = this.appStateService.currentEstablishment;
    this.reversalValue = 0;
    this.loadAnotherInfo();
  }

  loadAnotherInfo (){

    if(this.groupReservationForPay.dataGroupForReversal 
    && this.groupReservationForPay.dataGroupForReversal.onlinePayments){
     
      this.groupReservationForPay.dataGroupForReversal.onlinePayments.forEach(group => {

        group.loading = true;
        this.reservationPaymentService.cardHolder(group.payments[0].authorizationId).subscribe(resp=>{
          group.loading = false;

          if(resp.object) {
            group.payment_method = resp.object.payment_method;
            debugger;
          }
        });
        
      });
  
    }



    if(this.groupReservationForPay.dataGroupForReversal 
      && this.groupReservationForPay.dataGroupForReversal.balconyPayments){
       
        this.groupReservationForPay.dataGroupForReversal.balconyPayments.forEach(group => {
  
          group.loading = true;
          this.reservationPaymentService.cardHolder(group.payments[0].authorizationId).subscribe(resp=>{
            group.loading = false;

            if(resp.object) {
              group.payment_method = resp.object.payment_method;
              debugger;
            }
          });
          
        });
    
      }
   

   
   
  }

  checkIfagreesInstalment = parcela => {
    if (parcela.selectedWayOfPayment.agreesInstalment == false)
      parcela.instalmentNumber = 1;
  }

  next() {

    this.setSelectedPayments();

    if (this.groupReservationForPay.dataGroupForReversal.selectedPayments.length <= 0) {
      return;
    } else {
      console.log('selectedPayments', this.groupReservationForPay.dataGroupForReversal.selectedPayments);

      this.groupReservationForPay.dataGroupForReversal.selectedPayments.forEach(element => {
        element.reversalDate = moment();
      });
      this.sendReversal();
    }

  }


  setSelectedPayments(){

    this.warnNoSelected = false;

    this.groupReservationForPay.dataGroupForReversal.hasOnlineSelected = this.groupReservationForPay.dataGroupForReversal.onlinePayments.find(i => i.selected == true) != null ? true : false;
    this.groupReservationForPay.dataGroupForReversal.hasBalconySelected = this.groupReservationForPay.dataGroupForReversal.balconyPayments.find(i => i.selected == true) != null ? true : false;

    this.groupReservationForPay.dataGroupForReversal.selectedPayments = [];

    this.groupReservationForPay.dataGroupForReversal.onlinePayments.forEach(element => {
      if (element.selected == true) {
        this.groupReservationForPay.dataGroupForReversal.selectedPayments.push(element);
      }
    });

    this.groupReservationForPay.dataGroupForReversal.balconyPayments.forEach(element => {
      if (element.selected == true) {
        this.groupReservationForPay.dataGroupForReversal.selectedPayments.push(element);
      }
    });

    if (this.groupReservationForPay.dataGroupForReversal.selectedPayments.length <= 0)
    {
      this.warnNoSelected = true;
    } 
  }

  onSelectMode(event) {
    console.log('event', event);
  }


  sendReversal = () => {

    this.posted = true;

    if(this.reversalReason == ''){
      return;
    }


    var datapost = this.groupReservationForPay.dataGroupForReversal.selectedPayments;
    console.log('datapost', datapost);

    this.groupReservationForPay.paymentInstalmentsReversal.forEach(group => {

      const parcelas = [];
      const extras = [];

      datapost.forEach(payment => {

        if (payment.selected == true) {
          payment.payments.forEach(item => {

            if (item.instalmentId != null && item.groupPaymentId == group.groupPaymentId) {

              parcelas.push(
                {
                  instalmentId: item.instalmentId,
                  value: item.paymentValue
                });


            }

            if (item.additionalFeeId != null && item.groupPaymentId == group.groupPaymentId) {

              extras.push(
                {
                  additionalFeeId: item.additionalFeeId,
                  reservationId: item.reservationId,
                  value: item.paymentValue
                });

            }

          });
        }

      });


      if (parcelas.length > 0 || extras.length > 0) {

        this.loading = true
        this.reservationPaymentService.sendReverse02(group.groupPaymentId, this.reversalReason, parcelas, extras).subscribe(data => {
          this.loading = false
          switch (data.code) {
            case 200:
              this.onSaved.emit();
              break;
          }
        }, error => {
          this.loading = false;

        });

      }


    });




  }

  selectThisInstalment = parcela => {

    if (parcela.selected != true) {

      this.groupReservationForPay.dataGroupForReversal.onlinePayments.forEach(element => {
        element.selected = false;
        element.reversalDate = '';

        element.payments.forEach(item => {
          item.selected = false;
          item.reversalDate = '';
        });
      });

      this.groupReservationForPay.dataGroupForReversal.balconyPayments.forEach(element => {
        element.selected = false;
        element.reversalDate = '';

        element.payments.forEach(item => {
          item.selected = false;
          item.reversalDate = '';
        });

      });

      parcela.selected = true;
      parcela.reversalDate = moment();

      parcela.payments.forEach(item => {
        item.selected = true;
        item.reversalDate = moment();
      });

    }
    else {
      parcela.selected = false;
      parcela.reversalDate = '';
    }


    this.sumValues();
    this.setSelectedPayments();
  }

  sumValues() {
    this.reversalValue = 0;
    this.groupReservationForPay.dataGroupForReversal.onlinePayments.forEach(element => {
      if (element.selected == true)
        this.reversalValue += element.totalPaymentValue;
    });

    this.groupReservationForPay.dataGroupForReversal.balconyPayments.forEach(element => {
      if (element.selected == true)
        this.reversalValue += element.totalPaymentValue;
    });
  }

}
