import * as moment from "moment";

function dataURItoBlob(dataURI) {
	// convert base64/URLEncoded data component to raw binary data held in a string
	var byteString;
	if (dataURI.split(",")[0].indexOf("base64") >= 0) {
		byteString = atob(dataURI.split(",")[1]);
	} else {
		byteString = unescape(dataURI.split(",")[1]);
	}

	// separate out the mime component
	var mimeString = dataURI
		.split(",")[0]
		.split(":")[1]
		.split(";")[0];

	// write the bytes of the string to a typed array
	var ia = new Uint8Array(byteString.length);
	for (var i = 0; i < byteString.length; i++) {
		ia[i] = byteString.charCodeAt(i);
	}

	return new Blob([ia], { type: mimeString });
}

const generateFormData = (file, type) => {
	const formData: FormData = new FormData();

	let fileData = dataURItoBlob(file);
	formData.append("file", fileData);
	formData.append("type", type);

	return formData;
};

export const handleRegister = _this => {
	_this.loading = true;

	// Validate all fields before upload photos
	const birthday = moment(_this.f(0).birthday.value, "DD/MM/YYYY");
	if (!birthday.isValid() || birthday.isAfter()) {
		_this.notifier.notify("default", _this.translate.instant('error_msg.invalid_birthdate'));
		_this.loading = false;
		_this.currentStep = 0;
		_this.submitted = true;
		return;
	}

	let body = null;
	try {
		body = {
			documentType: _this.isForeign ? 2 : 0,
			documentNumber: _this.f(0).documentNumber.value,
			rg: _this.f(0).identityNumber.value,
			name: _this.f(0).name.value,
			birthDate: birthday.format("YYYY-MM-DD"),
			gender: _this.f(0).gender.value,
			profession: _this.f(0).vocation.value,
			citizenship: _this.f(0).nationality.value,

			email: _this.f(1).email.value,

			zipCode: _this.f(2).zipcode.value,
			country: _this.f(2).country.value,
			state: _this.f(2).state.value,
			city: _this.f(2).city.value,
			street: _this.f(2).street.value,
			number: _this.f(2).number.value
		};

		if (_this.type == "dependent") {
			body = {
				...body,
				mobilePhoneNumber: _this.f(1).phone.value.replace(/\s/g, ""),
				expeditor: _this.f(0).emitter.value,
				neighborhood: _this.f(2).complement.value
					? _this.f(2).complement.value
					: "n/a"
			};
		} else {
			body = {
				...body,
				phoneNumber: _this.f(1).phone.value.replace(/\s/g, ""),
				dispatcher: _this.f(0).emitter.value,
				complement: _this.f(2).complement.value
					? _this.f(2).complement.value
					: "n/a"
			};
		}
	} catch (err) {
		console.log(err);
	}

	if (!body) {
		_this.notifier.notify("default", _this.translate.instant('error_msg.error_undefined'));
		return;
	}

	if (_this.type === "dependent") {
		_this.service.addDependent(_this.mainGuestId, body).subscribe(
			data => {
				// TODO: Should get the token and attach it
				_this.loading = false;
				_this.onRegister.emit({
					dependentId: data.object.dependentId,
					...data.object
				});
				// _this.auth.setAuthData(data)
				// _this.router.navigate(['/register'])
			},
			error => {
				_this.loading = false;
				if(error.error && error.error.message){
					_this.notifier.notify("error", error.error.message);
				}else {
					_this.notifier.notify(
						"default",
						_this.translate.instant('dependent-form.error_saving')
					);
				}
			}
		);
	} else {
		if (_this.profileImage && _this.documentImage) {
			let documentPhotoId, profilePhotoId
			_this.service
				.saveImage({ data: _this.profileImage, contentType: "PROFILE_PHOTO" }, "profilePhoto" )
				.subscribe(
					data => {
						profilePhotoId = data.object
						_this.service
							.saveImage( { data: _this.documentImage, contentType: "DOCUMENT_PHOTO" }, "documentPhoto" )
							.subscribe(
								data => {
									documentPhotoId = data.object
									_this.service.addMainGuest({...body,
										profilePhotoId: profilePhotoId,
										documentPhotoId: documentPhotoId,
									}).subscribe(
										data => {
											// TODO: Should get the token and attach it
											_this.loading = false;
											_this.onRegister.emit({
												mainGuestId: data.object.mainGuestId,
												...data.object
											});
											// _this.auth.setAuthData(data)
											// _this.router.navigate(['/register'])
										},
										error => {
											_this.loading = false;
	
											if(error.error && error.error.message){
												_this.notifier.notify("error", error.error.message);
											}else {
												_this.notifier.notify(
													"default",
													_this.translate.instant('dependent-form.error_saving')
												);
											}
											
										}
									);
								},
								error => {
									_this.loading = false;
									_this.notifier.notify(
										"default",
										_this.translate.instant('dependent-form.error_saving_document_photo')
									);
								}
							);
					},
					error => {
						_this.loading = false;
						_this.notifier.notify(
							"default",
							_this.translate.instant('dependent-form.error_saving_profile_photo')
						);
					}
				);
		} else {
			_this.service.addMainGuest({...body}).subscribe(
				data => {
					// TODO: Should get the token and attach it
					_this.loading = false;
					_this.onRegister.emit({
						mainGuestId: data.object.mainGuestId,
						...data.object
					});
					// _this.auth.setAuthData(data)
					// _this.router.navigate(['/register'])
				},
				error => {
					_this.loading = false;

					if(error.error && error.error.message){
						_this.notifier.notify("error", error.error.message);
					}else {
						_this.notifier.notify(
							"default",
							_this.translate.instant('dependent-form.error_saving')
						);
					}
					
				}
			);
		}
		
	}
};
