import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({ name: 'orignFormatPipe' })
export class OrignFormatPipe implements PipeTransform {

    //constructor(public translate: TranslateService) { }

    transform(value: string, args: any[] = []) {
        switch (value) {
            case 'ABN':
                return 'Airbnb';

            case 'EXP':
                return 'Expedia';

            case 'BDC':
                return 'Booking';

            case 'BKY':
                return 'Bukly';

            case 'BALCAO':
                return 'Balcão';

            default:
                return value
        }
    }
}