import { Component, OnInit, Inject } from '@angular/core';
import { CreateHotelService } from '@src/app/pages/create-hotel/create-hotel.service';
import { NotifierService } from 'angular-notifier';
import { validateCNPJ } from '../../../utils/validators'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { removeChars } from '@src/app/utils/helpers';
import { ReservationsService } from '@src/app/pages/reservations/reservations.service';
import { UnitProblemPageService } from '@src/app/pages/unit-problem-page/unit-problem-page.service';
import { TranslateService } from '@ngx-translate/core';
import { AppStateService } from '@src/app/layouts/app-layout.service';




@Component({
  selector: 'app-report-problem-modal',
  templateUrl: './report-problem-modal.component.html',
  styleUrls: ['./report-problem-modal.component.scss']
})
export class ReportProblemModalComponent implements OnInit {

  loading: boolean = false;
  establishment: any;

  units: any[];
  selectedUnit: any;
  unitParced: boolean = false;

  problemName: string = '';
  problemDesc: string = '';


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private appStateService: AppStateService,
    private reservationsService: ReservationsService,
    private service: UnitProblemPageService,
    private notifier: NotifierService,
    public dialogRef: MatDialogRef<ReportProblemModalComponent>, 
    private translate: TranslateService,) { }

  ngOnInit() {

    console.log('this.data', this.data);

    this.establishment = this.appStateService.currentEstablishment;
    this.units = this.data.units;
    this.selectedUnit = this.data.selectedUnit;
    this.unitParced = (this.selectedUnit === undefined ) ? true : false;

    console.log('this.selectedUnit', this.selectedUnit);

    if (this.units == null) {
      this.getUnits();
    }
   
    this.loading = false

  }


  getUnits() {
    this.reservationsService.getUnits(null, this.establishment.establishmentId, 0)
      .subscribe(data => {
        this.units = [{
          name: "Todas",
          unitId: 0
        }];
        this.loading = false

        for (let i = data.object.length - 1; i >= 0; i--)
          this.units.push(data.object[i])

      }, error => {
        this.loading = false

      })
  }


  reportProblem() {
    if (this.selectedUnit && this.problemName && this.problemDesc && this.problemName.length > 0 && this.problemDesc.length > 0) {
      this.loading = true;
      let body = {
        "title": this.problemName,
        "description": this.problemDesc,
        "unitId": this.selectedUnit.unitId,
      }
      this.service.reportProblem(body).subscribe((data) => {
        this.loading = false
        switch (data.code) {
          case 200:
           
            this.notifier.notify('success', this.translate.instant('report_problem.reported_msg'))
            this.dialogRef.close(true);
            this.problemName = ''
            this.problemDesc = ''
            break;
          case 210:
            console.log('error', data);
            this.notifier.notify('error', data.message)
            break;
        }

       
      }, error => {
        this.loading = false
        this.notifier.notify('error', this.translate.instant('error_msg.error_undefined'))
      })
    }
  }

}
