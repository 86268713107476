import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpRequest, HttpEventType } from '@angular/common/http';
import { example } from './hotel-settings-page.types'; // Types
import { dataURItoBlob } from '../../../app/utils/helpers';
import { ResponseFormat } from '@src/app/types/response-format';
import { OtaType } from '@src/app/components/views/config-airbnb/config-AirBnb.service';


@Injectable()
export class HotelSettingsPageService
{
	public onLogoSaved = new EventEmitter<any>();


	constructor(private http: HttpClient) 
	{ 
	}

	
	getEstablishments = (companyId) => this.http.get<ResponseFormat>(`vivakey/rest/establishment/getEstablishments/${companyId}`)

	getSettings = (establishmentId) => this.http.get<ResponseFormat>(`vivakey/rest/establishmentConfig/${establishmentId}`)

	postSettings = (establishmentId, body) => this.http.post<ResponseFormat>(`vivakey/rest/establishmentConfig/establishment/${establishmentId}/save`, body)

	// About channels
	getChannels = (establishmentId) => this.http.get<ResponseFormat>(`vivakey/rest/channel/channelByEstablishment/${establishmentId}`)
	deleteChannel = (channelId) => this.http.delete<ResponseFormat>(`vivakey/rest/channel/channelByEstablishment/${channelId}/remove`)
	updateChannel = (channelId, body) => this.http.put<ResponseFormat>(`vivakey/rest/channel/channelByEstablishment/${channelId}/update`, body)
	saveChannel = (establishmentId, body) => this.http.post<ResponseFormat>(`vivakey/rest/channel/channelByEstablishment/${establishmentId}/save`, body)

	// About services
	getServices = () => this.http.get<ResponseFormat>(`vivakey/rest/serviceProvided/getServicesProvided`)
	getAvailableServices = (establishmentId) => this.http.get<ResponseFormat>(`vivakey/rest/serviceProvided/getAvailableServicesProvided/${establishmentId}`)
	getProvidedServices = (establishmentId) => this.http.get<ResponseFormat>(`vivakey/rest/serviceProvided/getServicesProvidedByEstablishment/${establishmentId}`)
	putServices = (establishmentId, body) => this.http.put<ResponseFormat>(`vivakey/rest/serviceProvided/servicesProvidedByEstablishment/${establishmentId}/update`, body)

	setPaymentGateway = (establishmentId, status) => this.http.put<ResponseFormat>(`vivakey/rest/establishmentConfig/establishment/${establishmentId}/paymentgateway/${status}`, {})

	getBanksNoPaymentGateway = (establishmentId) => this.http.get<ResponseFormat>(`vivakey/rest/bankPaymentType/getBankPaymentTypes/${establishmentId}?isPaymentGateway=false`)


	deactivateGateway = (establishmentId) => {
		return this.http.put<ResponseFormat>(`vivakey/rest/establishmentConfig/establishment/${establishmentId}/paymentgateway/false`, {});
	}

	setBuklybookingautopayment = (establishmentId, value) => {
		return this.http.put<ResponseFormat>(`vivakey/rest/establishmentConfig/establishment/${establishmentId}/buklybookingautopayment/${value}`, {});
	}


	deactivateAirbnbAutomaticPayment = (establishmentId, otaType: OtaType) => {

		var name = '';
		switch (otaType) {
            case OtaType.Airbnb:
                name = 'airbnbautopayment';
                break;
            
            case OtaType.Booking:
				name = 'bookingautopayment';
                break;
        
            default:
                break;
        }

		return this.http.put<ResponseFormat>(`vivakey/rest/establishmentConfig/establishment/${establishmentId}/${name}/false`, {});
	}

	getBanks = (establishmentId) => {
		return this.http.get<ResponseFormat>('vivakey/rest/bank/getBanks/' + establishmentId);
	}

	saveOmieConfig = (establishmentId, omieConfig, active) => {
		return this.http.put<ResponseFormat>(`vivakey/rest/establishmentConfig/establishment/${establishmentId}/omie/${active}`, omieConfig);
	}

	saveOmieConfig02 = (establishmentId, omieConfig, active) => {
		omieConfig.hasInvoiceOmie = active;
		//http://localhost:8080/vivakey/rest/establishmentConfig/establishment/{establishmentId}/invoiceomie/{hasInvoiceOmie}
		return this.http.put<ResponseFormat>(`vivakey/rest/establishmentConfig/establishment/${establishmentId}/invoiceomie/${active}`, {omieConfig: omieConfig});
	}

	setOwnerInvoice = (establishmentId, active) => {
		return this.http.put<ResponseFormat>(`vivakey/rest/establishmentConfig/establishment/${establishmentId}/ownerinvoice/${active}`, {});
	}

	setAutomaticPrice = (establishmentId, active) => {
		return this.http.put<ResponseFormat>(`vivakey/rest/establishmentConfig/establishment/${establishmentId}/automaticprice/${active}`, {});
	}

	refreshTokenAutomaticCheckin = (establishmentId) => {
		return this.http.put<ResponseFormat>(`vivakey/rest/establishment/renewsautomatictokencheckinestablishment/${establishmentId}`, {});
	}

}
