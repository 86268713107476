import { Component, OnInit } from '@angular/core';
import { LoginService } from '../login/login.service';
import Chart from 'chart.js';
import CountUp from 'countup.js';
import { TranslateService } from '@ngx-translate/core';
import { AppStateService } from '@src/app/layouts/app-layout.service';
import { DashOwnerFilterMode } from './dash-owner-filter-type';
import * as moment from 'moment';
import { last12Months } from '@src/app/utils/helpers';

declare var $: any;

const countUpOptions = {
    useEasing: true,
    useGrouping: true,
    separator: '.',
    decimal: ',',
};

@Component({
    selector: 'app-dashboard-owner',
    templateUrl: './dashboard-owner.component.html',
    styleUrls: [
        './dashboard-owner.component.css',
    ]
})

export class OwnerDashboardComponent implements OnInit {
    selectedCompany: any;
    selectedEstablishment: any;
    userInfo: any;
    loading: boolean = true;
    tabSelectedIndex: number;

    filterMode: DashOwnerFilterMode = DashOwnerFilterMode.Todos;

    buildings: any[];
    units: any[];

    buildingId: any;
    unitId: any;

    periodList: any[] = [];
    selectedMonth: any;


    public dashboardData: any;

    remainingPerc = '';

    constructor(private loginservice : LoginService,
        private translate: TranslateService, public appStateService: AppStateService) { 
            this.remainingPerc = this.translate.instant('dashboard.remaining_perc' );
        }

    ngOnInit() 
    {   
        this.periodList.push({
			start: null,
			end: null,
			month: 'Nenhum'
		});
        this.periodList = this.periodList.concat(last12Months(true));

        this.selectedCompany = this.appStateService.currentCompany;
        this.selectedEstablishment = this.appStateService.currentEstablishment;
        this.userInfo = JSON.parse(localStorage.getItem('currentUserInfo'));
        let lastOwnerDashboardData = sessionStorage.getItem('lastOwnerDashboardData');

        setTimeout(() => {

            if (lastOwnerDashboardData) {
                try {
                    lastOwnerDashboardData = JSON.parse(lastOwnerDashboardData)
                    this.dashboardData = lastOwnerDashboardData
                    this.setup()
                    this.loading = false
                } catch (err) {
                    this.query()
                }
            } else {
                this.query()
            }
            
        }, 100);
       

    }


    tabChangeEvent(event) {
        if (event.index == 0) {
            
        }
    }

    getFilterData() {

        this.buildingId = null;
        this.unitId = null;

        switch (this.filterMode) {

            case DashOwnerFilterMode.Todos:
                this.query();
                break;

            case DashOwnerFilterMode.Building:
                if(this.buildings) return;
                this.loginservice.getOwnerDashboardBuildings(this.selectedEstablishment.establishmentId).subscribe(resp=>{
                    this.buildings = resp.object;
                });
                break;

            case DashOwnerFilterMode.Unit:
                if(this.units) return;
                this.loginservice.getOwnerDashboardUnits(this.selectedEstablishment.establishmentId).subscribe(resp=>{
                    this.units = resp.object;
                });
                break;

            default:
                break;
        }

    }

    query() {

        switch (this.filterMode) {

            case DashOwnerFilterMode.Todos:
                this.buildingId = null;
                this.unitId = null;
                break;

            case DashOwnerFilterMode.Building:
                this.unitId = null;
                break;

            case DashOwnerFilterMode.Unit:
                this.buildingId = null;
                break;

            default:
                break;
        }

        switch (this.tabSelectedIndex) {
            case 0:
                this.selectedMonth = null;
                break;
        
            case 1:
                if(!this.selectedMonth || (this.selectedMonth && !this.selectedMonth.start))
                    return;
                
                break;

            default:
                break;
        }

      

        this.loading = true
        let params = {
            buildingId: this.buildingId,
            unitId: this.unitId,
            initialDate: (this.selectedMonth && this.selectedMonth.start) ? this.selectedMonth.start : null,
            finalDate: (this.selectedMonth && this.selectedMonth.end) ? this.selectedMonth.end : null
        };


        this.loginservice.getOwnerDashboard(this.selectedEstablishment.establishmentId, params).subscribe(
            (data) => {
                switch (data.code) {
                    case 200:
                        this.dashboardData = data.object;
                        this.dashboardData.last_month = this.dashboardData.lastMonth;
                        this.dashboardData.past_month = this.dashboardData.pastMonth;
                        //this.dashboardData.lastMonth = null;


                        if (this.dashboardData.month) {
                            this.dashboardData.month.billing.value = this.dashboardData.month.billing.value ? this.dashboardData.month.billing.value : 0;
                            this.dashboardData.month.billing.netValue = this.dashboardData.month.billing.value - this.dashboardData.month.billing.valueDiscount;
                        }

                        if (this.dashboardData.last_month) {
                            this.dashboardData.last_month.billing.value = this.dashboardData.last_month.billing.value ? this.dashboardData.last_month.billing.value : 0;
                            this.dashboardData.last_month.billing.netValue = this.dashboardData.last_month.billing.value - this.dashboardData.last_month.billing.valueDiscount;
                        }

                        if (this.dashboardData.today) {
                            this.dashboardData.today.billing.value = this.dashboardData.today.billing.value ? this.dashboardData.today.billing.value : 0;
                            this.dashboardData.today.billing.netValue = this.dashboardData.today.billing.value - this.dashboardData.today.billing.valueDiscount;
                        }

                        if (this.dashboardData.past_month) {
                            this.dashboardData.past_month.billing.value = this.dashboardData.past_month.billing.value ? this.dashboardData.past_month.billing.value : 0;
                            this.dashboardData.past_month.billing.netValue = this.dashboardData.past_month.billing.value - this.dashboardData.past_month.billing.valueDiscount;
                        }

                        if(!this.dashboardData.pastMonth)
                            sessionStorage.setItem('lastOwnerDashboardData', JSON.stringify(this.dashboardData));
                            
                        this.setup();
                        break;
                    default:
                    // TODO:
                    // Handle errors
                }
                this.loading = false
            },
            (error) => {
                // TODO:
                // Handle errors
                console.log('erro')
                this.loading = false;
            },
            () => {
                this.loading = false;
            });
    }

    startcountUp(id, value) {
        let countup = new CountUp(id, 0, value, 2, 2.5, countUpOptions);
        countup.start()
    }

    setup() {

        const dashboardData = this.dashboardData
        console.log('dashboardData', dashboardData)

        if(dashboardData.today){
            let percent = dashboardData.today.billing.occupancyRate
            this.assignDoughnutChart('chart1', {
                data: [percent, 100 - percent],
                colors: ['#ec73b3', '#E8E8E8'],
                labels: [this.translate.instant('dashboard.occupancy_rate_day'), this.remainingPerc],
            })
            this.startcountUp('chartnb1', percent)
        }



        if(dashboardData.month) {
            let percent = dashboardData.month.billing.occupancyRate
            this.assignDoughnutChart('chart2', {
                data: [percent, 100 - percent],
                colors: ['#7BC539', '#E8E8E8'],
                labels: [this.translate.instant('dashboard.occupancy_rate_month'), this.remainingPerc],
            })
            this.startcountUp('chartnb2', percent)
        }



        if(dashboardData.last_month) {
            let percent = dashboardData.last_month.billing.occupancyRate
            this.assignDoughnutChart('chart3', {
                data: [percent, 100 - percent],
                colors: ['#2343DB', '#E8E8E8'],
                labels: [this.translate.instant('dashboard.occupancy_rate_last_month'), this.remainingPerc],
            })
            this.startcountUp('chartnb3', percent)
        }

        if(dashboardData.past_month) {
            let percent = dashboardData.past_month.billing.occupancyRate
            this.assignDoughnutChart('chart4', {
                data: [percent, 100 - percent],
                colors: ['#2343DB', '#E8E8E8'],
                labels: [this.translate.instant('dashboard.past_month'), this.remainingPerc],
            })
            this.startcountUp('chartnb4', percent)
        }
        


        $('.countup').each(function (index) {
            let id = `countup_${index}`;
            $(this).attr('id', id);

            const period = $(this).attr('data-period');
            const getter = $(this).attr('data-get');

            if (dashboardData[period] == null) {
                console.log(period);
            } else {
                const value = dashboardData[period].billing[getter];

                let countup = new CountUp(id, 0, value, 2, 2.5, countUpOptions);
                countup.start();
            }

          
        });

    }

    assignDoughnutChart(elId, body) {
        let ctx = document.getElementById(elId);
        if(ctx){
            let chart = new Chart(ctx, {
                type: 'doughnut',
                data: {
                    datasets: [{
                        data: body.data,
                        backgroundColor: body.colors,
                        borderWidth: 0,
                    }],
                    labels: body.labels,
                },
                options: {
                    cutoutPercentage: 80,
                    legend: {
                        display: false,
                    }
                }
            });
        }
        
    }

    logout = () => this.loginservice.logout();
}