import { ThrowStmt } from '@angular/compiler';
import { Component, OnInit, Inject, Input, Output, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { GroupFinRpt } from '@src/app/pages/report-financial/report-financial.component';
import { ReportFinancialService } from '@src/app/pages/report-financial/report-financial.service';
import { NotifierService } from 'angular-notifier';
import * as moment from 'moment';
declare var $: any;

@Component({
  selector: 'app-report-financial-details-modal.component',
  templateUrl: './report-financial-details-modal.component.html',
  styleUrls: ['./report-financial-details-modal.component.scss']
})
export class ReportFinancialDetailsModalComponent implements OnInit {

  loading: boolean = false;

  @Input()
  selectedPayments: any;
  group: GroupFinRpt;

  reverseMode: boolean = false;
  conciliationMode: boolean = false;
  editPaymentDateMode: boolean = false;
  previewDate: any;
  reversalReason: '';
  public resultDialog: any;


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translate: TranslateService,
    private reportFinancialService: ReportFinancialService,
    public dialogRef: MatDialogRef<ReportFinancialDetailsModalComponent>,
    private notifier: NotifierService,) {

      this.selectedPayments = data.group.payments;
      this.group = data.group;

      this.selectedPayments.forEach(element => {
        if(element.additionalFeeName){
          element.desc = element.additionalFeeName;
        }
        else {
          element.desc = this.translate.instant('reservation_payment.reservation_value');
        }
      });

  }

  removeBR(value: string){
    return value.replace('<BR>', ' ');
  }

  ngOnInit() {
    
  }

  reverseOption() {
    //this.dialogRef.close('reverse');
    this.reverseMode = true;
    this.reversalReason = '';
    $('#reversalModal').modal('show');
  }

  conciliateOption(){
    this.conciliationMode = true;
    
  }

  reversePayment() {
    if (this.reversalReason == '') {
      return;
    }

    var instalments = [];
    var extras = [];

    this.group.payments.forEach(payment => {
      if (payment.instalmentId) {
        instalments.push(
          {
            instalmentId: payment.instalmentId,
            value: payment.grossValue
          });
      }

      if (payment.additionalFeeId) {
        extras.push(
          {
            additionalFeeId: payment.additionalFeeId,
            value: payment.grossValue
          });
      }
    });


    this.loading = true;
    this.reportFinancialService.sendReverse(this.group.groupPaymentId, this.reversalReason, instalments, extras).subscribe(ret => {
      this.loading = false;
      switch (ret.code) {
        case 200:
          //$('#reversalModal').modal('hide');
          this.reverseMode = false;
          this.resultDialog = 'reverse';
          this.dialogRef.close();

          break;
      }
    }, error => {
      this.loading = false;
    });

  }


  conciliatePayment() {
    // if (this.reversalReason == '') {
    //   return;
    // }

    // const inputedDate = moment(this.group.effectivePaymentDate, 'DD/MM/YYYY');
		// if(inputedDate.isValid() == false) {
    //   return;
    // }
		

    var payments = [];

    this.group.payments.forEach(payment => {
      payments.push(payment.paymentId);
    });

    var body = {
      receivable: {
        "receivableId": this.group.receivableId,
        "effectivePaymentDate": this.group.effectivePaymentDate,
        "effectiveValue": this.group.effectiveValue
      },
      paymentsIds: payments
    };


    this.reportFinancialService.saveConciliation(body).subscribe(ret => {

      switch (ret.code) {
        case 201:
          //$('#reversalModal').modal('hide');
          this.conciliationMode = false;
          this.resultDialog = 'conciliated';
          this.dialogRef.close();

          break;
      }
    }, error => console.log("Error"));

  }

  editExpectedDate(){
    var data = {
      paymentDate: moment(this.previewDate).format('YYYY-MM-DD'),
      paymentsIds: []
    };

    this.group.payments.forEach(element => {
      data.paymentsIds.push(element.paymentId);
    });

    this.reportFinancialService.editExpectedDate(data).subscribe(ret => {

      switch (ret.code) {
        case 200:
          //$('#reversalModal').modal('hide');
          this.conciliationMode = false;
          this.resultDialog = 'conciliated';
          this.dialogRef.close();
          break;
      }
    }, error => console.log("Error"));

  }

}
