import { Component, OnInit, NgModule } from '@angular/core'; // Angular
import { SettingsBuklyService } from './settings-bukly.service'; // Service
import { ReservationsService } from '../reservations/reservations.service'; // Service
import { 
    InitialCofig, 
    RoomConfig, 
    Channel, 
    Categorie,
    ExistentConfig, 
    RoomType, 
    RatePlan 
} from './setting-bukly.types'; // Type
import { NotifierService } from 'angular-notifier';
import { AppStateService } from '@src/app/layouts/app-layout.service';

declare var $ :any;

@Component({
    selector: 'app-settings-bukly',
    templateUrl: './settings-bukly.component.html',
    styleUrls: [
        './settings-bukly.component.css',
    ]
})

export class SettingsBuklyComponent implements OnInit 
{
    loading: boolean = false;
    editMode: boolean = false;
    configList: InitialCofig[] = [];
    initialConfig: InitialCofig;
    initialConfigValid: boolean = false;

    roomConfigs: RoomConfig;
    roomConfigValid: boolean;
    newConfig: ExistentConfig;
    newRoomConfigValid: boolean = true;
    categories: Categorie[];
    channels: Channel[];
    // roomStay: RoomStay[];
    roomStay: { 
        roomType: RoomType[]
    };
    ratePlansList: any[] = [];
    selectedRoomType: RoomType;
    existentConfigurations: any = null;

    constructor(
        private appStateService: AppStateService,
        private buklyService: SettingsBuklyService,
        private reservationsService: ReservationsService,
        private notifier: NotifierService,)
    {
        this.initialConfig = {
            buklyHotelId: null,
            user: "",
            password: "",
            hotelCode: "",
            establishmentId: undefined
        };

        this.newConfig = {
            buklyHotelId: null,
            categoryId: null,
            channelId: null,
            ratePlanCode: "",
            roomTypeCode: ""
        }

        this.roomStay = {
            roomType: []
        }
    }

    ngOnInit() 
    {
        this.reservationsService.getChannels(this.appStateService.currentEstablishment.establishmentId).subscribe(data =>
        {
            switch (data.code)
            {
                case 200:
                this.channels = []
                 data.object.forEach(channel => {
                    if(channel.buklyChannelCode) {
                        this.channels.push(channel)
                    }
                 });
                 this.getInitialConfig();
            }
        }, error => console.log("Error"));
    }

    onSelectRoom(event){
        console.log(event);
        this.selectedRoomType = this.roomStay.roomType.find(i=>i.roomTypeCode == event);
    }


    openConfigModal(config){
        this.initialConfig = config;
        this.editMode = true;
        this.getRoomConfig();
    }

    closeConfigModal(){
        this.editMode = false;
        this.getInitialConfig();
    }


    createNewConfig(){
        this.initialConfig = {
            buklyHotelId: null,
            user: "",
            password: "",
            hotelCode: "",
            establishmentId: undefined
        };

        this.initialConfigValid = false;
        this.editMode = true;
    }

    getInitialConfig = () =>
    {
        this.buklyService.getDataInitialConfig().subscribe(data => 
        {
            switch (data.code) 
            {
                case 200:
                    this.configList =  data.object.buklyHotels;
                    this.initialConfig =  this.configList[0];


                    if (this.initialConfig.user && this.initialConfig.user != ""
                        && this.initialConfig.password && this.initialConfig.password != ""
                        && this.initialConfig.hotelCode && this.initialConfig.hotelCode != "")
                        this.initialConfigValid = true;
                    else 
                        this.initialConfigValid = false;
                    
                    console.log(this.initialConfig.hotelCode);
                    console.log("Initial Config Valid", this.initialConfigValid);
                   
                    break;
            }
        });
    }

    getRoomConfig = () =>
    {
        this.existentConfigurations = [];
        this.ratePlansList = [];
        this.selectedRoomType = null;

        this.newConfig = {
            buklyHotelId: null,
            categoryId: null,
            channelId: null,
            ratePlanCode: "",
            roomTypeCode: ""
        }

        this.roomStay = {
            roomType: []
        }

        this.loading = true;
        this.buklyService.getDataRoomConfig(this.initialConfig.buklyHotelId).subscribe(data =>
        {
            this.loading = false;
            console.log("DATA ROOM CONFIG", data);
            
            switch (data.code)
            {
                case 200:            
                    this.roomConfigs = data.object;
                    this.categories = this.roomConfigs.categories;
                    
                    // this.roomStay = this.roomConfigs.roomStays;
                    var roomStays: any = this.roomConfigs.roomStays[this.initialConfig.buklyHotelId];
                    
                    this.roomStay.roomType = [];

                    roomStays.forEach(roomTypeDt => {
                        var roomType = roomTypeDt.roomTypes.roomType[0];

                        var n = new RoomType();
                        n.roomTypeCode = roomType.roomTypeCode;
                        n.roomDescription = roomType.roomDescription;

                        n.plans = roomTypeDt.ratePlans.ratePlan;
                        
                        this.ratePlansList = this.ratePlansList.concat(n.plans);

                        this.roomStay.roomType.push(n);
                    });

                    // Adiciona o name nas configs
                    this.roomConfigs.existentConfigurations.forEach(exConf => {

                        if (exConf.buklyHotelId == this.initialConfig.buklyHotelId) {
                            var categ = this.categories.find(c => c.categoryId == exConf.categoryId);
                            if (categ) {
                                exConf.categoryName = categ.name;
                            }

                            var channel = this.channels.find(c => c.channelId == exConf.channelId);
                            if (channel) {
                                exConf.channelName = channel.name;
                            }

                            var roomType = this.roomStay.roomType.find(c => c.roomTypeCode == exConf.roomTypeCode);
                            if (roomType) {
                                exConf.roomTypeName = roomType.roomDescription.name;
                            }

                            var ratePlan = this.ratePlansList.find(c => c.ratePlanCode == exConf.ratePlanCode);
                            if (ratePlan) {
                                exConf.ratePlanName = ratePlan.ratePlanDescription.name;
                            }

                            this.existentConfigurations.push(exConf);
                        }

                    });

                    console.log('existentConfigurations', this.existentConfigurations);
                    break
                default:
                    break;
            }
        }, error => { 
            this.loading = false;
            console.log('getDataRoomConfig error', error)
        });    
    }
  
    saveInitialConfiguration = () => 
    {
        console.log("Salvando Initial Configuration...");
        
        if (!this.initialConfig.user || !this.initialConfig.password || !this.initialConfig.hotelCode)
            return this.initialConfigValid = false;
  
        this.buklyService.saveInitialConfig(this.initialConfig).subscribe(data => 
        {
            console.log(data);
            switch (data.code) 
            {
                case 200:
                    $('#modalInitialConfigSucesso').modal('show');
                    this.initialConfigValid = true;
                    this.initialConfig = data.object;
                    this.editMode = true;
                    this.getRoomConfig();                  
                    break;
                default: 
                    break;
            }
        },  
        (error:any) => { 
            console.log("Error", error)
            if(error.error.message){
                this.notifier.notify('error',error.error.message);
            }   
        });
    }
  
    saveRoomConfiguration = () =>
    {
        console.log("Salvando Initial Configuration...");
        console.log(this.existentConfigurations);
        
        for (let i = 0; i < this.existentConfigurations.length; i++)
        {
            if (!this.existentConfigurations[i].categoryId 
                    || !this.existentConfigurations[i].channelId 
                    || !this.existentConfigurations[i].ratePlanCode
                    || !this.existentConfigurations[i].roomTypeCode)
                return this.roomConfigValid = false;
        }
        
        this.buklyService.saveRoomConfig(this.existentConfigurations).subscribe(data => 
        {
            console.log(data);
            switch (data.code) 
            {
                case 200:
                    $('#modalInitialConfigSucesso').modal('show');
                    this.roomConfigValid = true;   
                    this.getRoomConfig();                 
                    break;
                default: 
                    break;
            }
        },  
        (error:any) => console.log("Error"));
    } 

    addRoomConfiguration = () =>
    {
        console.log("Salvando New Room Configuration...");
        console.log(this.existentConfigurations);
        
        if (!this.newConfig.categoryId 
            || !this.newConfig.channelId 
            || !this.newConfig.ratePlanCode
            || !this.newConfig.roomTypeCode)
                return this.newRoomConfigValid = false;
    
        this.newConfig.buklyHotelId = this.initialConfig.buklyHotelId;
        const post = [
            this.newConfig
        ];

        this.buklyService.saveRoomConfig(post).subscribe(data => 
        {
            console.log(data);
            switch (data.code) 
            {
                case 200:
                    $('#modalInitialConfigSucesso').modal('show');
                    this.newRoomConfigValid = true;   
                    this.getRoomConfig();
                    this.newConfig = {
                        buklyHotelId: null,
                        categoryId: null,
                        channelId: null,
                        ratePlanCode: "",
                        roomTypeCode: ""
                    }              
                    break;
                default: 
                    break;
            }
        },  
        (error:any) => { 
            console.log("Error", error)
            if(error.error.message){
                this.notifier.notify('error',error.error.message);
            }   
        });
    }

    deleteRoomConfig = (categoryId, channelId) =>
    {
        this.buklyService.deleteRoomConfig(categoryId, channelId).subscribe(data => 
            {
                switch (data.code) 
                {
                    case 200:
                        $("#delete-success").modal("show");
                        this.getRoomConfig();
                        break
                    default:
                        $("#delete-error").modal("show");
                        break;
                }
            });
    }


    toDelete(config){
        this.initialConfig = config;
    }

    deleteConfig() {
        this.buklyService.deleteConfig(this.initialConfig.buklyHotelId).subscribe(data => {
            $("#modalConfirmDelete").modal("hide");
            this.getInitialConfig();
        });
    }

}
