import {AbstractControl} from '@angular/forms';
import * as moment from 'moment';
import { validateCPF, validateCNPJ } from '../validators' 
export class AppValidator {

	static date(format = 'DD/MM/YYYY') {
		return (ac: AbstractControl) => {
			if(ac && ac.value && !moment(ac.value, format, true).isValid()) {
				return {
					'date': true
				}
			}
			return null
		}
	}

	static phone(type = 0) {
		const digitsLength = 10
		return (ac: AbstractControl) => {
			if(ac && ac.value) {
				const value = ac.value.replace(/\D/g, '')
				if(value.length !== digitsLength) {
					return {
						'phone': true
					}
				}
			}
			return null
		}
	}

	static phoneAndCelphone() {
		return (ac: AbstractControl) => {
			if(ac && ac.value) {
				const value = ac.value.replace(/\D/g, '')
				if(value.length < 10 || value.length > 11) {
					return {
						'phoneAndCelphone': true
					}
				}
			}
			return null
		}
	}

	static cep(ac: AbstractControl) {
		if(ac && ac.value) {
			const value = ac.value.replace(/\D/g, '')
			if(value.length !== 8) {
				return {
					'cep': true
				}
			}
		}
		return null
	}

	static cpf(ac: AbstractControl) {
		if(ac && ac.value) {
			if(!validateCPF(ac.value)) {
				return {
					'cpf': true
				}
			}
		}
		return null
	}

	static cnpj(ac: AbstractControl) {
		if(ac && ac.value) {
			if(!validateCNPJ(ac.value)) {
				return {
					'cnpj': true
				}
			}
		}
		return null
	}
}