import { Component, OnInit } from "@angular/core";
import { ClientCardService } from "./client-card.service";
import { NotifierService } from 'angular-notifier';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MatDialogRef, MatDialog } from '@angular/material';
import { EditCardModalComponent } from '@src/app/components/views/edit-card-modal/edit-card-modal.component';
import { AppStateService } from "@src/app/layouts/app-layout.service";

declare var $: any;

function onlyUnique(value, index, self) { 
    return self.indexOf(value) === index;
}

@Component({
	selector: "app-client-card-page",
	templateUrl: "./client-card-page.component.html",
	styleUrls: [
		"../../app.component.css",
		"../dashboard/dashboard.component.css",
		"./client-card-page.component.css"
	]
})
export class ClientCardPageComponent implements OnInit {

	selectedEstablishment: any;
	data: any = null;
	reservations: any = null;

	// UI/UX
	loading: boolean = false

	// Filters
	name: string = '';
	documentTypes = [
		{value:'ALL', name: this.translate.instant('all')},
		{value: 0, name:'CPF'},
		{value: 1, name:'CNPJ'},
		{value: 4, name:'Email'},
		{value: 2, name: this.translate.instant('foreign')},
		{value: 3, name: this.translate.instant('no_document')},
	]
	documentType: any = this.documentTypes[0];
	userType: number = 0;
	currentUserType: number = 0;
	userTypes = [
		{ value: 0, name: this.translate.instant('responsible'), key: 'finalcialResponsibles'},
		{ value: 1, name: this.translate.instant('main_guest'), key: 'mainGuests' },
		{ value: 2, name: this.translate.instant('escort'), key: 'reservationDependents' }
	];
	page = 1
	maxResult = 10
	maxNavigationResult = 10

	photo: any = null

	editCardModalDialogRef: MatDialogRef<EditCardModalComponent>;

	constructor(
		private appStateService: AppStateService,
		private service: ClientCardService,
		private router: Router,
		private notifier: NotifierService,
		public dialog: MatDialog,
		private translate: TranslateService,) {}

	ngOnInit() {
		this.selectedEstablishment = this.appStateService.currentEstablishment;

		this.query(true);
	}

	pages() {
		if(!this.data) {
			return []
		} 
		var input = [];
		for (let i = 0; i < this.data.navPages.length; i++) {
			input.push(i);
		}
		return input;
	}

	goToPage(n) {
		if(n === -1) {
			return
		}
		this.page = n
		this.query()
	}
	goToNextPage = () => { this.page++;  this.query(); }
	goToBackPage = () => { this.page--; this.query(); }

	query(withReset = false) {
		this.loading = true

		if(withReset) {
			this.page = 1
			this.data = null
		}

		this.currentUserType = this.userType
		let extraParams = {}

		if(this.name.length > 0) {
			extraParams = {
				name: this.name
			}
		}

		if(this.documentType.value !== 'ALL') {
			extraParams = {
				documentType: this.documentType.value,
				...extraParams
			}
		}


		const params = {
			personType: this.userType,
			page: this.page,
			maxResult: this.maxResult,
			maxNavigationResult: this.maxNavigationResult,
			...extraParams,
		};

		this.service
			.get(this.selectedEstablishment.establishmentId, params)
			.subscribe(
				data => {
					console.log("Data from client card", data);
					if(data.code === 200 && data.object) {

						const resultKey = this.getCurrentUserType().key

						// resetting results when result comes with 1 length but null
						if(data.object[resultKey].length === 1 && data.object[resultKey][0] === null) {
							data.object[resultKey] = []
							data.object.navPages= [1]
						}

						let parsedResult = []
						data.object[resultKey].forEach(obj => {
							if(obj) {
								parsedResult.push(obj)
							}
						})

						data.object[resultKey] = parsedResult

						data.object.navPages = data.object.navPages.filter(onlyUnique)

						// data.object

						this.data = data.object
					} else {
						this.notifier.notify('default', this.translate.instant('error_msg.error_getdata'))
					}
					this.loading = false
				},
				error => {
					this.loading = false
					this.notifier.notify('error', this.translate.instant('error_msg.error_undefined'))
				}
			);
	}



	takeUserId(user) {
		switch(this.currentUserType) {
			case 0:
			return user.responsibleId
			case 1:
			return user.mainGuestId
			case 2:
			return user.dependentId
		}
	}

	reservationDetails = reservation =>
    {
        this.router.navigate([`app/${this.appStateService.currentEstablishmentId}/reservations/summary/${reservation.reservationId}`]);
    }

	getReservations(user) {
		this.loading = true

		this.service
			.getReservations(this.takeUserId(user), this.selectedEstablishment.establishmentId, this.currentUserType)
			.subscribe(
				data => {
					console.log("Data from reservation get", data);
					if(data.code === 200 && data.object) {
						$('#showReservationsModal').modal('show')
						this.reservations = data.object
					} else {
						this.notifier.notify('default', this.translate.instant('error_msg.error_getdata'))
					}
					this.loading = false
				},
				error => {
					this.loading = false
					this.notifier.notify('error', this.translate.instant('error_msg.error_undefined'))
				}
			);
	}

	// Download the excel file
	download() {
		this.loading = true

		let extraParams = {}

		if(this.name.length > 0) {
			extraParams = {
				name: this.name
			}
		}

		if(this.documentType.value !== 'ALL') {
			extraParams = {
				documentType: this.documentType.value,
				...extraParams
			}
		}

		const params = {
			personType: 3, //this.userType,
			maxResult: 100000,
			...extraParams,
		};

		this.service
			.getExcelReport(this.selectedEstablishment.establishmentId, params)
			.subscribe(
				data => {
					console.log("Download link", data);
					if(data.code === 200 && data.object) {
						window.location.href = data.object 
						
					} else {
						this.notifier.notify('default', this.translate.instant('error_msg.error_getdata'))
					}
					this.loading = false
				},
				error => {
					this.loading = false
					this.notifier.notify('error', this.translate.instant('error_msg.error_undefined'))
				}
			);
	}

	// UI/UX
	getCurrentUserType() {
		return this.userTypes.find(_ => _.value == this.currentUserType)
	}
	showDocumentOf(user) {
		switch(user.documentType) {

			case 'CPF':
			return `CPF: ${user.documentNumber}`

			case 'CNPJ':
			return `CNPJ: ${user.documentNumber}`

			case 'email':
			return `Email: ${user.documentNumber}`

			default:
			return this.translate.instant('no_document')
		}
	}

	showPhoto(id) {
		this.loading = true
		this.photo = null
		$('#showPhotoModal').modal('show')
		this.service.getMediaContent(id)
			.subscribe((data:any) => {
				if(data.content) {
					this.photo = 'data:image/jpeg;base64,' + data.content
				} else {
					$('#showPhotoModal').modal('hide')
				}
				this.loading = false
			}, error => {
				this.loading = false
					$('#showPhotoModal').modal('hide')
			})
	}

	editData(user) {
		console.log('user:',user);

		var editData:any;
		var personId:any;
		var type = "";

		if(user.mainGuestId)
		{	
			type = "mainGuest";
			personId = user.mainGuestId;
		}

		if(user.responsibleId)
		{	
			type = "responsible";
			personId = user.responsibleId;
		}

		if(user.dependentId)
		{	
			type = "dependent";
			personId = user.dependentId;
		}

		this.editCardModalDialogRef = this.dialog.open(EditCardModalComponent, {
			panelClass: 'mat-dialog-edit-card',
			disableClose: false,
			data : { type: type, personId: personId, editData: editData }
		  });
	  
		  //this.editCardModalDialogRef.componentInstance.units = this.units;
	  
		  this.editCardModalDialogRef.afterClosed().subscribe(result => {
			this.query();
			this.editCardModalDialogRef = null;
		  });
	}
}
