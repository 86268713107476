import { Component, OnInit, TemplateRef } from '@angular/core';
import { CategoriesService } from '../categories/categories.service';
import { NotifierService } from 'angular-notifier';
import { UnitsService } from './units.service';
import { strLimit } from '@utils/helpers'
import { TranslateService } from '@ngx-translate/core';
import { BuildingsService } from '../buildings/buildings.service';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { MatDialog, MatDialogRef } from '@angular/material';
import Swal from 'sweetalert2';
import { HotelSettingsPageService } from '../hotel-settings/hotel-settings-page.service';
import { AppStateService } from '@src/app/layouts/app-layout.service';

declare var $ :any;

@Component({
    selector: 'app-units',
    templateUrl: './units.component.html',
    styleUrls: [
        './units.component.css'
    ]
})

export class UnitsComponent implements OnInit 
{
    categories: any = [];
    types: any = [];
    unitsList: any = [];
    areasGroupsMagikey: any[];
    filtro: any = {};
    establishment: any;
    selectedItem: any = {};

    loading: boolean = false
    strLimit = strLimit;
    employees: any[] = [];
    receipt_employees: any[] = [];

    owners: any[] = [];
    filterNameDebTime = new Subject<string>();
    percentualConfig = { prefix: '', suffix:'%', thousands: '.', decimal: ',', align : 'left', allowNegative : 'false' };

    constructor(
        private categoriesService : CategoriesService, 
        private buildingsService: BuildingsService,
        private unitsService : UnitsService,
        private notifier: NotifierService,
        public translate: TranslateService,
        private hotelSettingsService: HotelSettingsPageService,
        public dialog: MatDialog,
        private appStateService: AppStateService,
    ) { 
        this.filtro = { name: '', category: 'todos', type: 'todos' };

        this.filterNameDebTime.pipe(
            debounceTime(900),
            distinctUntilChanged())
            .subscribe(value => {
                 this.filtro.name = value;
                this.getUnits();
            });
    }

    async ngOnInit() 
    {
        this.establishment = this.appStateService.currentEstablishment;

        this.categoriesService.getCategories(this.establishment.establishmentId, '').subscribe(data => 
        {
            console.log(data);
            switch (data.code) 
            {
                case 200:
                    console.log('cat', data.object);
                    this.categories = data.object;
                    break;
                default: 
                    break;
            }
        }, error => console.log("Error"));

        this.categoriesService.getTypes().subscribe(data => 
        {            
            console.log(data);
            switch (data.code) 
            {
                case 200:
                    this.types = data.object;
                    break;
            }
        }, error => console.log("Error", error));

        await this.getUnitOwner().then();
        await this.getOwnerEmployees().then();
        await this.getPropertyOwner().then();

        this.getUnits()   
    }


    copyClipboard(inputElement){
        inputElement.select();
        document.execCommand('copy');
        //inputElement.setSelectionRange(0, 0);
        Swal.fire(this.translate.instant('hotel.token_automatic_copied'), '', 'success');
      }

      
      
    getOwnerEmployees(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.loading = true;
            this.buildingsService.getOwnerEmployees(this.establishment.establishmentId).subscribe(resp => {
                this.loading = false;
                switch (resp.code) {
                    case 200:
                        {
                            this.employees = resp.object;
                            resolve(resp.object);
                            break;
                        }
                    default:
                        reject();
                }
            }, err => {
                this.loading = false;
                reject();
            })
        });
    }

        
    getPropertyOwner(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.loading = true;
            this.buildingsService.getPropertyOwner(this.establishment.establishmentId).subscribe(resp => {
                this.loading = false;
                switch (resp.code) {
                    case 200:
                        {
                            this.receipt_employees = resp.object;
                            resolve(resp.object);
                            break;
                        }
                    default:
                        reject();
                }
            }, err => {
                this.loading = false;
                reject();
            })
        });
    }


    getUnitOwner(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.loading = true;
            this.unitsService.getUnitOwner(this.establishment.establishmentId).subscribe(resp => {
                this.loading = false;
                switch (resp.code) {
                    case 200:
                        {
                            this.owners = resp.object;
                            resolve(resp.object);
                            break;
                        }
                    default:
                        reject();
                }
            }, err => {
                this.loading = false;
                reject();
            })
        });
    }

    getUnits = () =>
    {
        var filtros = this.filtro;
        console.log("Filtros: ", filtros);

        const categoryId = (filtros && filtros.category) ? filtros.category.categoryId : undefined;
        const unitTypeId = (filtros && filtros.type) ? filtros.type.unitTypeId : undefined;
        const name = (filtros && filtros.name) ? filtros.name : undefined;
        const ownerId = (filtros && filtros.owner) ? filtros.owner.employeeId : undefined;

        this.loading = true
        this.unitsService.getUnits(this.establishment.establishmentId, categoryId, unitTypeId, name, ownerId).subscribe(data => 
        {
            console.log(data);
            this.loading = false
            switch (data.code)
            {
                case 200:
                    this.unitsList = data.object;

                    this.unitsList.forEach(element => {
                        element.categoryId = element.category ? element.category.categoryId : null;
                        element.employeeId = element.employee ? element.employee.employeeId : null;
                        element.propertyOwnerId = element.propertyOwner ? element.propertyOwner.propertyOwnerId : null;
                        element.status_num = element.status == 'INACTIVE' ? 0 : 1
                    });
                   
                    console.log('lista', this.unitsList);
                    break;
                case 219:
                    $("#modalSearchError").modal("show");
                    break;
            }
        }, error => console.log("Error", error));
    }
   
    openDialogWithTemplateRef(templateRef: TemplateRef<any>) {
        this.dialog.open(templateRef);
    }

    propertyOwnerCrudModal_dialogRef: MatDialogRef<any>;
    openPropertyOwnerCrudModal(templateRef: TemplateRef<any>) {
        this.propertyOwnerCrudModal_dialogRef = this.dialog.open(templateRef, {
            panelClass: 'mat-dialog-fix',
            disableClose: false,
            data : { }
        });

        //this.createCompanyDialogRef.componentInstance.title = 'Excluír?';

        this.propertyOwnerCrudModal_dialogRef.afterClosed().subscribe(result => {
            this.getPropertyOwner();
            this.propertyOwnerCrudModal_dialogRef = null;
        });

    }

    addItem(dialogTemplate) {
        this.selectedItem = { auth_mode: 0 };  
        this.openDialogWithTemplateRef(dialogTemplate);
    }


    editItem(item, dialogTemplate) {
        this.selectedItem = item;

        this.selectedItem.auth_mode = (item.fixedPassword && item.fixedPassword.length > 0) ? 0 : 1;
       
        // this.selectedItem.unitId = item.unitId;
        // this.selectedItem.name = item.name;
        // this.selectedItem.type = item.category.unitType.unitTypeId;
        // this.selectedItem.category = item.category.categoryId;
        // this.selectedItem.magikey = item.magikey;
        // this.selectedItem.status = item.status == "INACTIVE" ? 0 : 1;
        // this.selectedItem.number = item.number ? item.number : '';
        // this.selectedItem.fixedPassword = item.fixedPassword ? item.fixedPassword : '';
        // this.selectedItem.excludedUnit = item.excludedUnit != null ? item.excludedUnit : false;
        // this.selectedItem.hideOnReservationMap = item.hideOnReservationMap != null ? item.hideOnReservationMap : false;;
        // this.selectedItem.employeeId = item.employee ? item.employee.employeeId : null;

        this.openDialogWithTemplateRef(dialogTemplate);
    }

    authModeChange() {
        
    }

    saveUnit = () =>
    {

        if(this.selectedItem.auth_mode == 1) {

            if(!this.establishment.magikeyToken && !this.selectedItem.magikeyToken) {

                this.notifier.notify('error', this.translate.instant('units.error_token' ))
                return;
            }

            this.selectedItem.fixedPassword = '';
        } else {
            this.selectedItem.magikey = null;
            this.selectedItem.smartlock = null;
            this.selectedItem.magikeyToken = null;
        }


        this.loading = true
        this.unitsService.saveUnit(this.selectedItem, this.establishment.establishmentId).subscribe(data => 
        {
            console.log(data);
            switch (data.code) 
            {
                case 200:
                    console.log('salvou');
                    this.getUnits();
                    this.dialog.closeAll();

                    if(this.selectedItem.unitId) {
                        this.notifier.notify('success', this.translate.instant('unity_saved' ))
                    } else {
                        this.notifier.notify('success', this.translate.instant('unity_inserted' ))
                    }
                    break;
                case 207:
                    $("#modalMagiKeyError").modal("show");
                default:
                this.notifier.notify('error', this.translate.instant('error_msg.error_undefined' ))
                    break;
            }
            this.loading = false;
        }, error => {
            this.loading = false;
            this.notifier.notify('error', this.translate.instant('error_msg.error_undefined' ))
        });   
    }


    confirmDelete(){
        const swalWithBootstrapButtons = Swal.mixin({
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn'
          },
          buttonsStyling: false
        })
      
        swalWithBootstrapButtons.fire({
          position: 'center',
          title: this.translate.instant('units.confirm_delete'),
          text: this.translate.instant('occupation.confirm_checkin_checkout_info'),
          icon: 'question',
          showCancelButton: true,
          confirmButtonText: this.translate.instant('buildings.delete_ok'),
          cancelButtonText: this.translate.instant('no'),
          reverseButtons: true
        }).then((result) => {
          if (result.value) {
            this.deleteUnit();
          } else if (result.dismiss === Swal.DismissReason.cancel) {
      
          }
        });
      }


    deleteUnit = () =>
    {
        this.loading = true;
        $('#modalConfirmUnitDelete').modal('hide');
        this.unitsService.deleteUnit(this.selectedItem.unitId).subscribe(data => 
        {
            this.loading = false
            
            console.log(data);
            switch (data.code) 
            {
                case 200:
                    console.log('deletou');
                    this.getUnits();
                    this.notifier.notify('success', this.translate.instant('unity_deleted' ))
                    this.dialog.closeAll();
                    break;
                case  246 :
                    this.notifier.notify('error', this.translate.instant('units.error_246' ))
                    break;
                default: 
                this.notifier.notify('error', this.translate.instant('error_msg.error_undefined' ))
                    break;
            }
           
        }, error => {
            this.loading = false
            this.notifier.notify('error', this.translate.instant('error_msg.error_undefined' ))
        });  
    }

    lockUnit = unit =>
    {
        this.loading = true
        this.unitsService.changeUnitStatus(unit.unitId, 0).subscribe(data => 
        {
            console.log(data);
            switch (data.code) 
            {
                case 200:
                    this.notifier.notify('success', 'Unidade interditada com sucesso')
                    this.getUnits();
                    break;
                default: 
                this.notifier.notify('error', this.translate.instant('error_msg.error_undefined' ))
                this.loading = false
                    break;
            }
        },  
        error => {
                this.loading = false
            this.notifier.notify('error', this.translate.instant('error_msg.error_undefined' ))
            console.log("Error")
        });  
    }

    unlockUnit = unit =>
    {
        this.loading = true
        this.unitsService.changeUnitStatus(unit.unitId, 1).subscribe(data => 
        {
            console.log(data);
            switch (data.code) 
            {
                case 200:
                    this.notifier.notify('success', this.translate.instant('unity_unblock_error' ))
                    this.getUnits();
                    break;
                default: 
                this.notifier.notify('error', this.translate.instant('error_msg.error_undefined' ))
                this.loading = false
                    break;
            }
        },  
        error => {
                this.loading = false
            this.notifier.notify('error', this.translate.instant('error_msg.error_undefined' ))
            console.log("Error")
        });  
    }


    updateReservationsMagikey(unitId){
        this.loading = true
        this.unitsService.updateReservationsMagikey(unitId).subscribe(data => 
        {
            this.loading = false
            this.notifier.notify('success', this.translate.instant('units.updated_reservations' ))
        },  
        error => {
            this.loading = false
            this.notifier.notify('error', this.translate.instant('error_msg.error_undefined' ))
        });  
    }

}
