import { Component, OnInit, Input } from '@angular/core';
import { CommonService } from '@src/app/common-service';
declare var $: any;


@Component({
	selector: 'app-shadowed-card',
	templateUrl: './shadowed-card.component.html',
	styleUrls: ['./shadowed-card.component.scss']
})
export class ShadowedCardComponent implements OnInit {
	@Input('data') data: any;


	photo: string;
	photoTitle: string = '';
	loading: boolean = false

	constructor(private commonService: CommonService) { }

	ngOnInit() {
		console.log('data:', this.data);
	}


	showPhoto(imageId) {
		if (this.data.profilePhotoId) {

			this.loading = true;
			this.commonService.getImage(imageId).subscribe(
				(imgData) => {
					this.photo = imgData.keyName;
				},
				(error) => {
					console.log('erro ao carregar foto !')
				},
				() => {
					this.loading = false;
				});
		}
	}

	

}
