import { Injectable } from '@angular/core';
import { Http, Headers, Response, RequestOptions } from '@angular/http';
import { Router, ActivatedRoute } from '@angular/router';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { Observable } from 'rxjs/Rx';
import { ServerEnv } from './utils/env-server';
declare const $: any;

@Injectable()
export class TokenService {

    private serverEnv = ServerEnv.PMS;

    get baseUrl(): string {

        switch (this.serverEnv) {

            case ServerEnv.PMS:
                return 'https://pms.vivakey.com.br:8081';

            case ServerEnv.SandBox:
                return 'https://sandbox.vivakey.com.br:8081';

            case ServerEnv.HomologAws:
                return 'https://audaar.vivakey.com.br:8081';

            case ServerEnv.S191:
                return 'http://191.234.176.13:8080';

            default:
                return 'http://localhost:8080';

        }
    }

    get baseUrl_liteFront(): string {

        switch (this.serverEnv) {

            case ServerEnv.PMS:
                return 'https://pms.vivakey.com.br/paymentlink';

            case ServerEnv.SandBox:
                return 'https://sandbox.vivakey.com.br/paymentlink';

            case ServerEnv.HomologAws:
                return 'https://homologaws.vivakey.com.br/paymentlink';

            case ServerEnv.S191:
                return 'http://191.234.176.13/paymentlink';

            default:
                return 'http://localhost:4201/paymentlink';

        }

    }


    constructor(private http: Http, private router: Router, public route: ActivatedRoute) { }

    public getBaseUrl = () => this.baseUrl;

    authToken = (token) => {
        const myHeaders = new Headers();
        myHeaders.append('token', token);

        const options = new RequestOptions({ headers: myHeaders });

        return this.http.get(`${this.baseUrl}/vivakey/rest/authentication`, options).map((response: Response) => response.json());
    }

    ResetPasswordService = (password, token) => {
        const myHeaders = new Headers();
        myHeaders.append('token', token);

        const options = new RequestOptions({ headers: myHeaders });

        const param = { "newPassword": password };

        return this.http.put(`${this.baseUrl}/vivakey/rest/employee/resetPassword`, param, options).map((response: Response) => response.json());
    }

    refreshToken = () => {
        console.log('refresh token service');
        const refreshToken = JSON.parse(localStorage.getItem('currentUser'));
        const param = {
            grantType: 'refresh_token',
            username: '',
            password: '',
            clientId: "1",
            clientSecret: "123456",
            refreshToken: refreshToken.object.refreshToken
        }

        return this.http.post(`${this.baseUrl}/vivakey/rest/oauth`, param)
            .map((response: Response) => {
                console.log("Response refreshToken", response);
                const result = response.json();

                if (result.code == 260 || result.code == 206) {
                    console.log('Redirect to login from error');
                    sessionStorage.removeItem('reservation_filter');
                    localStorage.removeItem('currentUser')
                    sessionStorage.removeItem('currentMenu');
                    sessionStorage.removeItem('currentMenuCategory');
                    $(".modal").modal("hide");
                    return this.router.navigate(['login']);
                }

                localStorage.setItem('currentUser', response.text());
                return result;
            })
            .catch((err: any, caught: Observable<{}>) => caught);
    }
}
