import { Component, OnInit, TemplateRef } from '@angular/core';
import { BuildingsService } from './buildings.service';
import { NotifierService } from 'angular-notifier';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog, MatTableDataSource } from '@angular/material';
import Swal from 'sweetalert2';
import { timeout } from 'rxjs-compat/operator/timeout';
import { SelectionModel } from '@angular/cdk/collections';
import { CommonService } from '@src/app/common-service';
import { error } from 'selenium-webdriver';
import { AppStateService } from '@src/app/layouts/app-layout.service';


@Component({
  selector: 'app-buildings',
  templateUrl: './buildings.component.html',
  styleUrls: ['./buildings.component.scss']
})
export class BuildingsComponent implements OnInit {

  loading: boolean = false;

  data: any[] = [];
  establishment: any;
  selectedItem: any;

  servicesLoaded: boolean = false;
  servicesDataSource = new MatTableDataSource<any>([]);
  servicesSelection = new SelectionModel<any>(true, []);
  servicesColumns: string[] = ["select", "image", "name", "description"];

  mediaContentList: any = [];
  establishmentMediaIds: any[] = [];

  currentLogo: string;
  currentEstPhoto: string;
  currentLogoId: number;
  currentEstPhotoId: number;

  policyContent: any;

  ckeditor_config = {
    language: this.translate.currentLang == 'pt_br' ? 'pt-br ' : 'en-us',
    removePlugins: 'elementspath,save',
    toolbar: [
      { name: 'styles', items: ['Font', 'FontSize'] },
      { name: 'basicstyles', items: ['Bold', 'Italic', 'Underline', '-'] }]
  };

  constructor(
    private appStateService: AppStateService,
    public dataService: BuildingsService,
    private commonService: CommonService,
    private notifier: NotifierService,
    private translate: TranslateService,
    private dialog: MatDialog,) {

    this.establishment = this.appStateService.currentEstablishment;

  }

  ngOnInit() {

    this.loadData();

  }

  addItem(dialogTemplate) {

    this.currentLogo = null;
    this.currentLogoId = null;
    this.currentEstPhoto = null;
    this.currentEstPhotoId = null;

    this.selectedItem = {
      addressBuilding: '',
      nameBuilding: ''
    };
    this.openDialogWithTemplateRef(dialogTemplate);
  }

  editItem(item, dialogTemplate) {
    this.selectedItem = item;
    this.mediaContentList = item.mediaContent;
    this.getLogo();
    this.openDialogWithTemplateRef(dialogTemplate);
  }

  editServices(item, dialogTemplate) {
    this.selectedItem = item;
    if (this.servicesLoaded == false) {
      this.getServices().then(ok => {
        this.getProvidedServices().then(ok => {
          this.openDialogWithTemplateRef(dialogTemplate);
        });
      });
    } else {
      this.getProvidedServices().then(ok => {
        this.openDialogWithTemplateRef(dialogTemplate);
      });
    }
  }

  openDialogWithTemplateRef(templateRef: TemplateRef<any>) {
    this.dialog.open(templateRef);
  }

  getServices(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.loading = true;
      this.dataService.getServices().subscribe(resp => {
        this.loading = false;
        switch (resp.code) {
          case 200:
            {
              this.servicesDataSource = new MatTableDataSource<any>(
                resp.object
              );
              this.servicesLoaded = true;
              resolve(resp.object);
              break;
            }
          default:
            reject();
        }
      }, err => {
        this.loading = false;
        reject();
      })
    });
  }


  getProvidedServices(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.servicesSelection = new SelectionModel<any>(true, []);
      this.loading = true;
      this.dataService.getProvidedServices(this.selectedItem.buildingId).subscribe(resp => {
        this.loading = false;
        switch (resp.code) {
          case 200:
            {
              if (
                resp.code === 200 &&
                resp.object &&
                resp.object.length > 0
              ) {
                this.servicesDataSource.data.forEach(row => {
                  let index = resp.object.findIndex(
                    _ => _.id == row.id
                  );
                  if (index > -1) {
                    this.servicesSelection.select(row);
                  }
                });
              }
              resolve(resp.object);
              break;
            }
          default:
            reject();
        }
      }, err => {
        this.loading = false;
        reject();
      })
    });
  }

  isAllServicesSelected() {
    const numSelected = this.servicesSelection.selected.length;
    const numRows = this.servicesDataSource.data.length;
    return numSelected === numRows;
  }
  masterToggle() {
    this.isAllServicesSelected()
      ? this.servicesSelection.clear()
      : this.servicesDataSource.data.forEach(row => this.servicesSelection.select(row));
  }

  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllServicesSelected() ? "select" : "deselect"} all`;
    }
    return `${this.servicesSelection.isSelected(row) ? "deselect" : "select"
      } row ${row.position + 1}`;
  }


  getLogo() {


    this.currentLogo = null;
    this.currentLogoId = null;

    this.currentEstPhoto = null;
    this.currentEstPhotoId = null;


    this.mediaContentList.sort((a, b) => (<number>a.vivakeyMediaContent > <number>b.vivakeyMediaContent) ? 1 : -1).forEach(element => {

      if (element.active == true) {
        this.commonService.getImage(element.vivakeyMediaContent).subscribe(
          (data) => {
            console.log('logoData:', data);

            if (data.type == "BUILDING_LOGO") {
              this.currentLogo = data.keyName;
              this.currentLogoId = data.id;
            }

            if (data.type == "BUILDING_PHOTO") {
              this.currentEstPhoto = data.keyName
              this.currentEstPhotoId = data.id;
            }
          },
          (error) => {
            console.log('erro ao carregar logo')
          },
          () => {
            this.loading = false;
          });
      }

    });


  }


  onChangeLogo(event) {
    console.log(event);
    if (event.image) {
      this.commonService.saveImage({ data: event.image, contentType: "BUILDING_LOGO" }, "buildingLogo").subscribe(
        (data) => {
          console.log(data.object);
          this.currentLogoId = data.object;
          this.establishmentMediaIds.push(data.object);
        },
        (error) => {
          console.log('erro ao carregar logo')
        },
        () => {
          this.loading = false;
        });
    }
  }

  onChangeEstPhoto(event) {
    console.log(event);
    if (event.image) {
      this.commonService.saveImage({ data: event.image, contentType: "BUILDING_PHOTO" }, "buildingPhoto").subscribe(
        (data) => {
          console.log(data.object);
          this.currentEstPhotoId = data.object;
          this.establishmentMediaIds.push(data.object);
        },
        (error) => {
          console.log('erro ao carregar foto')
        },
        () => {
          this.loading = false;
        });

    }
  }


  saveService() {
    const body = {
      servicesProvided: this.servicesSelection.selected
    };

    this.loading = true
    this.dataService
      .putServices(this.selectedItem.buildingId, body)
      .subscribe(data => {
        this.loading = false
        this.notifier.notify('success', this.translate.instant('channels.success_saved'))
        this.dialog.closeAll();
      }, error => {
        this.loading = false
        this.notifier.notify('error', this.translate.instant('channels.error_save'))
      });
  }


  loadData() {

    setTimeout(() => this.selectedItem = null, 100);

    this.loading = true;
    this.dataService.get(this.establishment.establishmentId).subscribe(resp => {
      this.loading = false;

      console.log('buildings:', resp);
      if (resp.code === 200 && resp.object) {
        this.data = resp.object;
      }

    }, error => {
      this.loading = false;
      this.notifier.notify('error', this.translate.instant('error_msg.error_getdata'))
    });

  }

  copyClipboard(inputElement) {
    inputElement.select();
    document.execCommand('copy');
    //inputElement.setSelectionRange(0, 0);
    Swal.fire(this.translate.instant('hotel.token_automatic_copied'), '', 'success');
  }

  refreshTokenAutomaticCheckin() {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn'
      },
      buttonsStyling: false
    })

    swalWithBootstrapButtons.fire({
      position: 'center',
      title: this.translate.instant('hotel.confirm_refresh_token'),
      text: this.translate.instant('occupation.confirm_checkin_checkout_info'),
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: this.translate.instant('yes'),
      cancelButtonText: this.translate.instant('no'),
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        this.loading = true;
        this.dataService.refreshTokenAutomaticCheckin(this.selectedItem.buildingId).subscribe(ret => {
          this.loading = false;
          if (ret.code == 200) {
            if (ret.object) {
              this.selectedItem.tokenAutomaticCheckin = ret.object;
            }
            //this.getSettings();
          }
        }, error => {
          this.loading = false;
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {

      }
    });

  }

  save() {

    if (this.selectedItem && this.selectedItem.nameBuilding && this.selectedItem.nameBuilding.length > 0) {

      this.establishmentMediaIds = [];
      if (this.currentLogoId) {
        this.establishmentMediaIds.push(this.currentLogoId);
      }

      if (this.currentEstPhotoId) {
        this.establishmentMediaIds.push(this.currentEstPhotoId);
      }

      this.selectedItem.buildingMediaIds = this.establishmentMediaIds;


      this.loading = true;
      this.dataService.save(this.selectedItem, this.establishment.establishmentId).subscribe(resp => {

        this.loading = false;
        if (resp.code == 200) {
          this.loadData();
          this.dialog.closeAll();
        }

      }, error => {
        this.loading = false;
        this.notifier.notify('error', this.translate.instant('error_msg.error_undefined'))
      });
    }


  }

  getPolicy(item, dialogTemplate) {
    this.selectedItem = item;
    this.loading = true;
    this.dataService.getPolicy(item.buildingId).subscribe(ret => {
      this.loading = false;
      if (ret.code == 200) {
        this.policyContent = ret.object.text;
        this.openDialogWithTemplateRef(dialogTemplate);
      }
    }, error => {
      this.loading = false;
    });
  }

  savePolicy() {
    this.loading = true;

    var data = {
      politicsBuilding: this.policyContent,
      buildingId: this.selectedItem.buildingId
    };

    this.dataService.updatePolicy(data).subscribe(ret => {
      this.loading = false;
      if (ret.code == 200) {
        this.dialog.closeAll();
      }
    }, error => {
      this.loading = false;
    });
  }


  confirmDelete() {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn'
      },
      buttonsStyling: false
    })

    swalWithBootstrapButtons.fire({
      position: 'center',
      title: this.translate.instant('buildings.delete_question'),
      text: this.translate.instant('occupation.confirm_checkin_checkout_info'),
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: this.translate.instant('buildings.delete_ok'),
      cancelButtonText: this.translate.instant('no'),
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        this.delete();
      } else if (result.dismiss === Swal.DismissReason.cancel) {

      }
    });
  }

  delete() {
    this.loading = true;
    this.dataService.delete(this.selectedItem.buildingId).subscribe(resp => {
      this.loading = false;
      if (resp.code == 200) {
        this.loadData();
        this.dialog.closeAll();
      }
    }, error => {
      this.loading = false;
      this.notifier.notify('error', this.translate.instant('error_msg.error_undefined'))
    });
  }

  close() {
    this.dialog.closeAll();
  }

}
