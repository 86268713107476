import { Component, OnInit } from '@angular/core';
import { CreateHotelService } from '@src/app/pages/create-hotel/create-hotel.service';
import { NotifierService } from 'angular-notifier';
import { validateCNPJ } from '../../../utils/validators'
import { MatDialogRef } from '@angular/material';
import moment from "moment-timezone";
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'create-establishment-modal.component',
  templateUrl: './create-establishment-modal.component.html',
  styleUrls: ['./create-establishment-modal.component.scss']
})
export class CreateEstablishmentModalComponent implements OnInit {

  loading: boolean = false;

  selectedCompany: any = {};

  employeesList: any;
  addEstablishmentFormModel: any = {};

  cnpjMask: any = [/\d/, /\d/, ".", /\d/, /\d/, /\d/, ".", /\d/, /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/];
  timezones: any;


  constructor(private hotelService: CreateHotelService,
    private notifier: NotifierService,
    public dialogRef: MatDialogRef<CreateEstablishmentModalComponent>, 
    private translate: TranslateService,) { }

  ngOnInit() {
    this.timezones = moment.tz.names();
    this.getEmployees();
  }


  onChangeCheckin(val) {
    this.addEstablishmentFormModel.checkIn = val;
  }

  onChangeCheckout(val) {
    this.addEstablishmentFormModel.checkOut = val;
  }

  getEmployees() {
    console.log("GetEmployees");


    this.hotelService.getEmployees().subscribe(data => {
      console.log("GetEmployees", data.object);
      this.loading = false
      switch (data.code) {
        case 200:
          this.employeesList = data.object;
          break;
        case 219:
          console.log('no permission');
          break;
      }
      this.loading = false
    }, error => {
      this.loading = false
      this.notifier.notify('error', this.translate.instant('error_msg.error_data_loading'))
      console.log("Error", error)
    });
  }


  save() {
    const { addEstablishmentFormModel } = this;

    if (!validateCNPJ(addEstablishmentFormModel.documentNumber)) {
      this.notifier.notify('error', this.translate.instant('error_msg.error_invalid_doc'))
      return;
    }

    if (this.selectedCompany && addEstablishmentFormModel.name && addEstablishmentFormModel.documentNumber && addEstablishmentFormModel.manager !== null && addEstablishmentFormModel.company !== null && addEstablishmentFormModel.name.length > 0 && addEstablishmentFormModel.documentNumber.length > 0) {
      let body = {
        "name": addEstablishmentFormModel.name,
        "documentNumber": addEstablishmentFormModel.documentNumber,
        "companyId": this.selectedCompany.companyId,
        "managerId": addEstablishmentFormModel.manager.employeeId,
        "checkin": this.addEstablishmentFormModel.checkIn,
        "checkout": this.addEstablishmentFormModel.checkOut,
        "timeZone": this.addEstablishmentFormModel.timezone,
        "magikeyToken": this.addEstablishmentFormModel.magikeyToken,
        "establishmentType": this.addEstablishmentFormModel.establishmentType
      }

      this.loading = true

      this.hotelService.createHotel(body).subscribe((data) => {
        this.loading = false
        switch (data.code) {
          case 200:
            this.notifier.notify('success', this.translate.instant('create-hotel.success'))
            this.dialogRef.close(true);
            break;
          case 210:
            console.log('error', data);
            break;
        }
      }, error => {
        this.loading = false
        switch (error.status) {
          case 409:
            this.notifier.notify('error', this.translate.instant('create-hotel.already_document'))
            break;
          default:
            this.notifier.notify('error', this.translate.instant('create-hotel.success'));
            break;
        }

        
      })
    }

  }

}
