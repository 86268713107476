import { Injectable } from '@angular/core';
import {ResponseFormat} from '@app/types/response-format';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse  } from '@angular/common/http';


@Injectable()
export class ReportInvoicesService {

  constructor(private http : HttpClient) { }

  filterReport(establishmentId, startDate, endDate, mainGuest, nameInvoice, DocumentInvoice, report = false){
	return this.http.get<ResponseFormat>(`vivakey/rest/report/invoices/issued
		?establishmentId=${establishmentId}
		&initDate=${startDate}
		&endDate=${endDate}
		&nameInvoice=${nameInvoice}
		&mainGuest=${mainGuest}
		&DocumentInvoice=${DocumentInvoice}
		&report=${report}`)
  }
}
