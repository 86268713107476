import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ResponseFormat } from '@app/types/response-format';

@Injectable()
export class ReportBillingService
{

	constructor(private http: HttpClient) 
	{ 
	}

	getSettings = (establishmentId) => this.http.get<ResponseFormat>(`vivakey/rest/establishmentConfig/${establishmentId}`)

	query = (establishmentId, startDate, endDate) => this.http.get<ResponseFormat>(`vivakey/rest/reservation/billingAndSales/${establishmentId}/${startDate}/${endDate}`)

	postSettings = (establishmentId, body) => this.http.post<ResponseFormat>(`vivakey/rest/establishmentConfig/establishment/${establishmentId}/save`, body)


}
