import * as moment from "moment";

function translateGender(gender) {
	if (gender === 0 || gender === 1) {
		return gender
	} else if (gender == 'MALE') {
		return 0
	} else if (gender == 'FEMALE') {
		return 1
	} else {
		return null
	}
}

export function translateDataToConfirmData(data) {
	return {
		mainGuestId: data.mainGuestId,
		dependentId: data.dependentId,
		responsibleId: data.responsibleId,
		addressId: data.addressId,
		name: data.name,
		documentType: data.documentType,
		documentNumber: data.documentNumber,
		documentTypeId: data.documentTypeId,
		documentTypeName: data.documentTypeName,
		emailOptional: data.emailOptional,
		birthday: data.birthDate
			? moment(data.birthDate.substr(0, 10), "YYYY-MM-DD")
			: null,
		email: data.email,
		identityNumber: data.rg,
		gender: translateGender(data.gender),
		phone: data.phoneNumber || data.mobilePhoneNumber,
		nationality: data.citizenship,
		vocation: data.profession,
		street: data.street,
		neighborhood: data.neighborhood,
		complement: data.complement,
		zipcode: data.zipCode,
		state: data.state,
		country: data.country,
		city: data.city,
		number: data.number,
		profilePhotoId: data.profilePhotoId,
		documentPhotoId: data.documentPhotoId
	};
}

export function translateConfirmDataToBody(data) {
	return {
		name: data.name,
		email: data.email,
		mobilePhoneNumber: data.phone,
		documentNumber: data.documentNumber,
		documentType: data.documentType === "CPF" ? 0 : 2,
		rg: data.identityNumber,
		birthDate: data.birthday ? data.birthday.format("YYYY-MM-DD") : null,
		gender: translateGender(data.gender),
		profession: data.vocation,
		citizenship: data.nationality,
		zipCode: data.zipcode,
		country: data.country,
		state: data.state,
		city: data.city,
		street: data.street,
		number: data.number,
		neighborhood: data.neighborhood,
		profilePhotoId: data.profilePhotoId,
		documentPhotoId: data.documentPhotoId
	};
}

export function translateConfirmDataToUpdateBody(data, type) {
	if (type == "mainGuest") {
		return {
			email: data.email,
			phoneNumber: data.phone.replace(/\s/g, ""),
			street: data.street,
			complement: data.neighborhood ? data.neighborhood : 'n/a',
			city: data.city,
			zipCode: data.zipcode,
			state: data.state,
			country: data.country,
			number: data.number,
			name: data.name,
			profilePhotoId: data.profilePhotoId,
			documentPhotoId: data.documentPhotoId
		};
	} else if (type == "dependent") {
		return {
			email: data.email,
			mobilePhoneNumber: data.phone.replace(/\s/g, ""),
			street: data.street,
			neighborhood: data.complement ? data.complement : 'n/a',
			city: data.city,
			zipCode: data.zipcode,
			state: data.state,
			country: data.country,
			number: data.number,
			name: data.name
		};
	} else if (type == "responsible") {
		return {
			id: data.responsibleId,
			responsibleId: data.responsibleId,
			documentNumber: data.documentNumber,
			phoneNumber: data.phone.replace(/\s/g, ""),
			email: data.email,
			emailOptional: data.emailOptional,
			name: data.name,
			documentType: data.documentTypeId ? (data.documentNumber != data.email ? 0 : data.documentTypeId) : 0,
			documentTypeName: data.documentTypeName,
			address: {
				addressId: data.addressId,
				street: data.street,
				neighborhood: data.complement ? data.complement : 'n/a',
				city: data.city,
				zipCode: data.zipcode,
				state: data.state,
				country: data.country,
			}
		};
	}
}

export function getAutomaticCalcInfo() {
	var currLang = localStorage.getItem("currLang");
	switch (currLang) {
		case 'pt_br':
			return `
<div style="padding: 20px; font-size: 13px;  text-align: left;">
<b>Valor faixa da taxa de ocupação maior 80% →</b>
<br>Valor tarifário = Valor base

<br><br>
<b>Valor faixa da taxa de ocupação de 60% a 80% →</b>
<br>Se taxa de desconto for em R$ => <b>Valor tarifário = Valor base - taxa de desconto</b>
<br>Se taxa de desconto for em % => <b>Valor tarifário = (1-taxa de desconto)*valor base</b>

<br><br>
<b>Valor faixa da taxa de ocupação de 40% a 60% → </b>
<br>Se taxa de desconto for em R$ => <b>Valor tarifário = Valor base - 2*taxa de desconto </b>
<br>Se taxa de desconto for em % => <b>Valor tarifário = (1-2*taxa desconto)*valor base</b>

<br><br>
<b>Valor faixa da taxa de ocupação de 0% a 40% →  </b>	
<br>Se taxa de desconto for em R$ => <b>Valor tarifário =  Valor base - 3*taxa de desconto </b>
<br>Se taxa de desconto for em % => <b>Valor tarifário = (1-3*taxa desconto)*valor base</b>
</div>
`

		case 'en':
			return `
<div style="padding: 20px;">
<b>Value range of the occupancy rate greater than 80% → </b>
<br>Tariff amount = Base amount

<br> <br>
<b>Value range of the occupancy rate from 60% to 80% → </b>
<br>If the discount rate is R $ => <b> Tariff amount = Base amount - discount rate </b>
<br>If the discount rate is in% => <b> Rate value = (1-discount rate) * base rate </b>

<br> <br>
<b>Value range of the occupancy rate from 40% to 60% → </b>
<br>If the discount rate is R $ => <b> Tariff amount = Base value - 2 * discount rate </b>
<br>If the discount rate is in% => <b> Rate value = (1-2 * discount rate) * base rate </b>

<br> <br>
<b>Value range of the occupancy rate from 0% to 40% → </b>
<br>If the discount rate is R $ => <b> Tariff amount = Base value - 3 * discount rate </b>
<br>If the discount rate is in% => <b> Rate value = (1-3 * discount rate) * base rate </b>
</div>
`

		default:
			return '';
	}

}