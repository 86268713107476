import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
	selector: 'app-image-picker',
	templateUrl: './image-picker.component.html',
	styleUrls: ['./image-picker.component.css']
})
export class ImagePickerComponent implements OnInit {

	@Input('image') image: any = null;
	@Output() onChange: EventEmitter<any> = new EventEmitter();

	constructor() { }

	ngOnInit() {
	}

	getStyle() {
		let noBgColor = !this.image || this.image === '' ? {} : {'background-color': 'transparent'}
		return {
			'background-image': `url('${this.image}')`,
			...noBgColor
		}
	}

	changeImageFile(event) {
		if (event.target.files && event.target.files[0]) {
			var reader = new FileReader();

			this.image = null;
			let file = event.target.files[0]

			reader.readAsDataURL(event.target.files[0]); // read file as data url

			reader.onload = (event) => { // called once readAsDataURL is completed
				this.image = reader.result;
				this.onChange.emit({image: this.image, filename: file.name});
			}
		}
	}
}
