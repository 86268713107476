import { Injectable } from "@angular/core";
import {
	CanActivate,
	ActivatedRouteSnapshot,
	RouterStateSnapshot
} from "@angular/router";
import { Observable } from "rxjs/Observable";
import { Router } from "@angular/router";
import { DashboardMenuService } from '../layouts/sidenav/sidenav-menu/dashboard-menu.service';


@Injectable()
export class DashboardPageGuard implements CanActivate {
	constructor(private menu: DashboardMenuService, private router: Router) {}
	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean> | Promise<boolean> | boolean {
		if(this.menu.menuItems) {
			let dashboardMenu = this.menu.menuItems.find(_ => _.name === 'dashboard')
			if(!dashboardMenu) {
				console.log('permission denied: ');
				this.router.navigate(['/home'])
				return false
			}
		}
		return true
	}
}


@Injectable()
export class OwnerDashboardPageGuard implements CanActivate {
	constructor(private menu: DashboardMenuService, private router: Router) {}
	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean> | Promise<boolean> | boolean {
		if(this.menu.menuItems) {
			console.log('menu',this.menu.menuItems);
			let dashboardMenu = this.menu.menuItems.find(_ => _.name === 'proprietario')
			if(!dashboardMenu) {
				console.log('permission denied: ');
				this.router.navigate(['/home'])
				return false
			}
		}
		return true
	}
}
