import { Injectable } from '@angular/core';
import {Observable} from 'rxjs/Rx';
import {ResponseFormat} from '@app/types/response-format';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse  } from '@angular/common/http';

@Injectable()
export class ChannelManagerService {

  constructor(private http : HttpClient) { }
  
  getParrUrl(establishmentId){
  	 return this.http.get<ResponseFormat>('vivakey/rest/siro/getSiros/'+establishmentId);
  }
  
  postParrUrl(postArr){
  	 return this.http.post<ResponseFormat>('vivakey/rest/siro',postArr);
  }



}
