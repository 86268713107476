import { Injectable } from '@angular/core';
import {Observable} from 'rxjs/Rx';
import * as moment from 'moment';
import {ResponseFormat} from '@app/types/response-format';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse  } from '@angular/common/http';

@Injectable()
export class ReservationSummaryService
{
    constructor(private http : HttpClient) { }

    getReservationSummaryInfo = reservationId => this.http.get<ResponseFormat>('vivakey/rest/reservation/getReservation/details/' + reservationId);

    updateCheckinCheckout = (reservationId, checkinTime, checkoutTime) => 
    this.http.put<ResponseFormat>(`vivakey/rest/reservation/updateCheckinCheckout/${reservationId}/${checkinTime}/${checkoutTime}`, {});

    makeCheckin = (reservationId) => {
        return this.http.put<ResponseFormat>(`vivakey/rest/reservation/checkinout/${reservationId}/checkin/true`, {});
    }

    updateCheckinCheckout_Date  = (reservationId, checkinTime, checkoutTime, editTime, resizeWithoutReversing) => {
        if(editTime == "checkin") {
            return this.http.put<ResponseFormat>(`vivakey/rest/reservation/resize/${reservationId}?newDateCheckin=${checkinTime}${ resizeWithoutReversing ? '&resizeWithoutReversing=true' : '' }`, {});
        }

        else {
            return this.http.put<ResponseFormat>(`vivakey/rest/reservation/resize/${reservationId}?newDateCheckout=${checkoutTime}${ resizeWithoutReversing ? '&resizeWithoutReversing=true' : '' }`, {});
        }
    }

    sendObservation = (reservationId, observation) =>
    {
        const body = {
            observation: observation,
            reservationId: reservationId
        };

        console.log("Enviando observação", body);
        return this.http.put<ResponseFormat>('vivakey/rest/reservation/observation', body);
    }

    getLogs(reservationId, initialDate, finalDate) {
        return this.http.get<ResponseFormat>(`vivakey/rest/log/getLogsByReservation/${reservationId}`)
    }

    getDependents(reservationId) {
        return this.http.get<ResponseFormat>(`vivakey/rest/reservation/${reservationId}/dependent`)
    }

    resendPassword(reservationId)
    {
        return this.http.post<ResponseFormat>(`vivakey/rest/reservation/${reservationId}/resendPassword`, {});
    }

    generateNF(reservationId, establishmentId, appKey, appSecretKey){
        return this.http.post<ResponseFormat>(`vivakey/rest/omie/createos/?establishmentId=${establishmentId}&reservationId=${reservationId}&appKey=${appKey}&appSecretKey=${appSecretKey}`, {});
    }

    getInvoicePendingErrors = establishmentId => this.http.get<ResponseFormat>('vivakey/rest/omieerro/'+establishmentId);

    validateCheckin(reservationId)
    {
        return this.http.put<ResponseFormat>(`vivakey/rest/reservation/updatevalidatedcheckin/${reservationId}`, {});
    }


    removeDependentToReservation(reservationId, dependentId) {
        return this.http.delete<ResponseFormat>(`vivakey/rest/reservation/${reservationId}/dependent/${dependentId}`)
    }
}
