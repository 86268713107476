import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Rx';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { ResponseFormat } from '@app/types/response-format';

// Import RxJs required methods
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';

@Injectable()
export class ChoseCompanyService {

	constructor(private http: HttpClient, private ResponseFormat: ResponseFormat) {

	}

	getEstablishment(id): Promise<any> {
		return new Promise((resolve, reject) => {

			this.http.get<ResponseFormat>(`vivakey/rest/establishment/getEstablishment/${id}`).subscribe(resp => {
				resolve(resp.object);
			}, err => {
				//Mostrar erro e redirecionar para página de login
				reject();
			});

		});

	}

	getCompanies() : Promise<any> {
		return new Promise((resolve, reject) => {

			this.http.get<ResponseFormat>('vivakey/rest/company/getCompanies/').subscribe(resp => {
				resolve(resp);
			}, err => {
				//Mostrar erro e redirecionar para página de login
				reject();
			});

		});

	}

	getEstablishments(companyId) : Promise<any> {
		return new Promise((resolve, reject) => {
			this.http.get<ResponseFormat>('vivakey/rest/establishment/getEstablishments/' + companyId).subscribe(resp => {
				resp.object.forEach(element => {
					element.companyId = companyId;
				});
				resolve(resp);
			}, err => {
				//Mostrar erro e redirecionar para página de login
				reject();
			});
		});
	}

	putEstablishment(establishmentId) {
		console.log('putEstablishment')
		return this.http.put<ResponseFormat>(`vivakey/rest/oauth/${establishmentId}`, {});
	}

}
