import { Component, OnInit } from '@angular/core';
import { CreateHotelService } from '@src/app/pages/create-hotel/create-hotel.service';
import { NotifierService } from 'angular-notifier';
import { validateCNPJ } from '../../../utils/validators'
import { MatDialogRef } from '@angular/material';

import { removeChars } from '@src/app/utils/helpers';
import { TranslateService } from '@ngx-translate/core';




@Component({
  selector: 'app-create-company-modal',
  templateUrl: './create-company-modal.component.html',
  styleUrls: ['./create-company-modal.component.scss']
})
export class CreateCompanyModalComponent implements OnInit {

  loading: boolean = false;
  employeesList: any;
  addCompanyFormModel: any = {};

  cnpjMask: any = [/\d/, /\d/, ".", /\d/, /\d/, /\d/, ".", /\d/, /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/];



  constructor(private hotelService: CreateHotelService, 
    private notifier: NotifierService,
    public dialogRef: MatDialogRef<CreateCompanyModalComponent>,
    private translate: TranslateService,) { }

  ngOnInit() {
    this.getEmployees();
  }


  getEmployees() {
    console.log("GetEmployees");


    this.hotelService.getEmployees().subscribe(data => {
      console.log("GetEmployees", data.object);
      this.loading = false
      switch (data.code) {
        case 200:
          this.employeesList = data.object;
          break;
        case 219:
          console.log('no permission');
          break;
      }
      this.loading = false
    }, error => {
      this.loading = false
      this.notifier.notify('error', this.translate.instant('error_msg.error_data_loading'))
      console.log("Error", error)
    });
  }


  saveCompany() {
    const { addCompanyFormModel } = this
    console.log(addCompanyFormModel);

    if (!validateCNPJ(addCompanyFormModel.documentNumber)) {
      this.notifier.notify('error',  this.translate.instant('error_msg.error_invalid_doc'));
      return;
    }

    if (addCompanyFormModel.documentNumber && addCompanyFormModel.description && addCompanyFormModel.name.length > 0 && addCompanyFormModel.documentNumber.length > 0 && addCompanyFormModel.description.length > 0 && addCompanyFormModel.director !== null) {
      let body = {
        "name": addCompanyFormModel.name,
        "documentNumber":  addCompanyFormModel.documentNumber.replace(/\D/g, ''),
        "description": addCompanyFormModel.description,
        "directorId": addCompanyFormModel.director.employeeId
      }

      this.loading = true

      this.hotelService.createCompany(body).subscribe((data) => {
        this.notifier.notify('success',  this.translate.instant('create-company.success'));
        this.loading = false
        // this.getCompanyes();
        // $('#createCompanyModal').modal('hide');
        this.dialogRef.close(true);

      }, error => {
        this.loading = false
        this.notifier.notify('error',  this.translate.instant('create-company.error'))
      })
    }
  }

}
