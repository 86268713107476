import { Injectable, Injector } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpResponse,
  HttpErrorResponse,
  HttpEvent,
  HttpInterceptor,
  HttpClient
} from '@angular/common/http';
import { Http, Headers, Response, RequestOptions } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import { TokenService } from './token.service';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/observable/fromPromise';
import { Router } from '@angular/router';
import { Subject } from 'rxjs/Subject';
import 'rxjs/add/observable/throw';
import { NotifierService } from 'angular-notifier';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class TokenInterceptor implements HttpInterceptor
{
    eventObs: any;
    refreshTokenInProgress = false;

    tokenRefreshedSource = new Subject();
    tokenRefreshed$ = this.tokenRefreshedSource.asObservable();

    constructor(private tokenService: TokenService, private injector: Injector, private router: Router, public translate: TranslateService, private notifier: NotifierService) {}

    intercept = (request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> =>
    {
        //ADICONA O TOKEN AOS HEADERS
        const token = JSON.parse(localStorage.getItem('currentUser'));

        if (token && request.url.indexOf('viacep.com.br') < 0)
            request = this.setToken(request, token.object.accessToken);

        //apend endpoint base url to vivakey urls
        if (request.url.substring(0, 7) == 'vivakey')
        {
            request = request = request.clone({
                url: `${this.tokenService.getBaseUrl()}/${request.url}`
            });
        }

        //VERIFICA A NECESSIDADE DE CHAMAR O REFRESH TOKEN
        return next.handle(request).catch(error =>
        {
            console.log(error);
            if (error.status === 440)
            {
                console.log('token expirado');
                return this.refreshToken()
                    .flatMap((token) =>
                    {
                        const tok = JSON.parse(localStorage.getItem('currentUser'));
                        const authReqRepeat = this.setToken(request, tok.object.accessToken);
                        console.log('*Repeating httpRequest*', authReqRepeat);
                        return next.handle(authReqRepeat);
                    });
            }
            else if ((error.status === 422 || error.error.code === 422) && error.error.message)
            {
                this.notifier.notify('error', error.error.message);
                return Observable.throw(error);
            }
            else if (error.status === 0)
            {
                console.log('try to redirect from error');
                //localStorage.removeItem('reservation_filter');
                // localStorage.removeItem('currentUser')
                // sessionStorage.removeItem('currentMenu');
                // sessionStorage.removeItem('currentMenuCategory');
                // this.router.navigate(['login']);
                this.notifier.notify('error', this.translate.instant('error_msg.server_error'))
                return Observable.throw(error);

            }
            else
            {
                return Observable.throw(error);
            }
        });

    }

    setToken = (request, token) =>
    {
        request = request.clone({
            setHeaders: {
                token: token
            }
        });
        return request;
    }

    refreshToken = () =>
    {
        if (this.refreshTokenInProgress)
        {
            return new Observable(observer =>
            {
                this.tokenRefreshed$.subscribe(() =>
                {
                    observer.next();
                    return observer.complete();
                });
            });
        }
        else
        {
            this.refreshTokenInProgress = true;
            return this.tokenService
                .refreshToken()
                .flatMap(() =>
                {
                    this.refreshTokenInProgress = false;
                    this.tokenRefreshedSource.next();
                    return new Observable(observer => {

                        return observer.next();
                        });
                });
        }
    }
}
