import { Component, Input, OnInit } from '@angular/core';
import { AppStateService } from '../app-layout.service';

@Component({
  selector: 'my-app-sidenav',
  styles: [],
  templateUrl: './sidenav.component.html'
})

export class AppSidenavComponent implements OnInit {
  public AppConfig: any;

  logo = "";
  
  constructor(public appStateService: AppStateService) {
    this.AppConfig = appStateService.AppConfig;

    this.appStateService.onCurrentEstablishmentLoaded.subscribe(establishment=>{

      //console.log('onCurrentEstablishmentLoaded in sidenav',  this.appStateService.currentCompany);

      if(this.appStateService.currentCompany.name.toUpperCase().indexOf('XTAY') > -1 ) {
        this.logo = "/assets/img/xtay.png";
      } 
      else if(this.appStateService.currentCompany.name.toUpperCase().indexOf('LOBIE') > -1 ) {
        this.logo = "/assets/img/lobie.png";
      }
      else {
        this.logo = "/assets/img/header_logo.svg";
      }
      
    });

  }
  ngOnInit() {
    

  }

  toggleCollapsedNav() {
    this.appStateService.AppConfig.navCollapsed = !this.appStateService.AppConfig.navCollapsed;
    this.appStateService.saveConfig();
  }
}
