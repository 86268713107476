import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse  } from '@angular/common/http';
import {ResponseFormat} from '@app/types/response-format';
import { AppStateService } from '@src/app/layouts/app-layout.service';

@Injectable()
export class PortadorService 
{

  constructor(private http : HttpClient, private appStateService: AppStateService) { }

	getBanks = () =>
	{
		const establishment = this.appStateService.currentEstablishment;
		return this.http.get<ResponseFormat>('vivakey/rest/bank/getBanks/'+establishment.establishmentId);
	}


	savePortador(portador){
		var establishment = this.appStateService.currentEstablishment;
		var editOrAdd = null;
		if (portador.bankId)
			editOrAdd = portador.bankId
		var body = {
		  "bankId": editOrAdd,
		  "establishmentId": establishment.establishmentId,
		  "name": portador.name,
		  "code": portador.code,
		  "agency": portador.agency,
		  "digit": portador.digit,
		  "countNumber": portador.countNumber,
		  "ncodCc": portador.ncodCc,
		  "hasNCodCcManual": portador.hasNCodCcManual
		}
		return this.http.post<ResponseFormat>('vivakey/rest/bank',body);
	}

	deletePortador(portador){
		return this.http.delete<ResponseFormat>('vivakey/rest/bank/'+portador.bankId);
	}

	getAccounts = (appKey, appSecretKey, page, maxRegs) => {
		return this.http.get<ResponseFormat>(`vivakey/rest/omie/cc?appKey=${appKey}&appSecretKey=${appSecretKey}&pagina=${page}&registrosPorPagina=${maxRegs}`);
	}
}
