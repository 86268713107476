import { Component } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import { AppStateService } from './layouts/app-layout.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: [
  './app.component.css',
  ]
})
export class AppComponent {
  title = 'app';

  public AppConfig: any;

   constructor(translate: TranslateService, public sharedInfo: AppStateService) {
    
    this.AppConfig = sharedInfo.AppConfig;

        var lang = localStorage.getItem('currLang');
        if(lang) {
          translate.setDefaultLang(lang);
          translate.use(lang);
        }
        else {
          const browserLang = translate.getBrowserLang();
          const defaultLang = browserLang.replace('pt','pt_br');
          console.log('defaultLang', defaultLang);

          translate.use(defaultLang.match(/en|pt/) ? defaultLang : 'en');
          localStorage.setItem('currLang', defaultLang);
        }
    }
}
