import { Component, OnInit, Inject } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { TariffService } from '@src/app/pages/tariff/tariff.service';
import Swal from 'sweetalert2';
import { getAutomaticCalcInfo } from '@src/app/utils/translators';
import { AppStateService } from '@src/app/layouts/app-layout.service';

@Component({
  selector: 'app-set-automatic-calc',
  templateUrl: './set-automatic-calc-modal.component.html',
  styleUrls: ['./set-automatic-calc-modal.component.scss']
})
export class SetAutomaticCalcModalComponent implements OnInit {

  loading: boolean = false;
  submitted: boolean = false;
  selectedEstablishment: any;
  tariff: any;

  model: any = {
    baseValue: 0,
    discountPriceValue: 0,
    discountPriceType: '', //DISCOUNTTYPE_VALUE  ||  DISCOUNTTYPE_PERCENTUAL
    lastMinuteValue: 0,
    overwrite: false,
    automaticTariff: true
  };


  constructor(
    private appStateService: AppStateService,
    private service: TariffService,
    private notifier: NotifierService,
    public dialogRef: MatDialogRef<SetAutomaticCalcModalComponent>,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any) {

    this.selectedEstablishment = this.appStateService.currentEstablishment;
    this.tariff = data.tariff;

  }

  ngOnInit() {
    var totalPrice: number = 0;
    this.tariff.days.forEach(element => {
      totalPrice += element.price;
    });
    //this.model.baseValue = totalPrice / this.tariff.days.length;
  }


  initializePaymentTypes() {

  }

  save() {

    var hasError: boolean;
    this.submitted = true;

    if (this.model.baseValue <= 0) {
      this.notifier.notify('error', this.translate.instant('set_automatic_calc.error_value'));
      hasError = true;
    }
    if (this.model.discountPriceType == '') {
      this.notifier.notify('error', this.translate.instant('set_automatic_calc.error_discount_type'));
      hasError = true;
    }

    if (hasError == true) {
      return;
    }

    this.loading = true;

    this.service.sendAutomaticCalc(this.tariff.channel.channelId,
      this.tariff.category.categoryId,
      this.selectedEstablishment.establishmentId,
      this.model).subscribe((data) => {
        this.loading = false
        console.log("response...", data);
        this.notifier.notify('success', this.translate.instant('set_automatic_calc.saved_successfully'));
        this.dialogRef.close(true);

      }, error => {
        this.loading = false
        this.notifier.notify('error', this.translate.instant('set_automatic_calc.error_saving'))
      });
  }

  showInfoCalc(){
    Swal.fire({
      title: '<strong><u>' + this.translate.instant('info') + '</u></strong>',
      html: getAutomaticCalcInfo(),
      width: 650,
      showCloseButton: true,
      showCancelButton: false,
      focusConfirm: false,
      confirmButtonText:
        '<i class="fa fa-thumbs-up"></i> OK!',
    })
  }

}
