export const MenuItems = [

    { name: "dashboard", location: "dashboard", icon: "fa fa-chart-pie" },
    { name: "dashboard_proprietario", location: "ownerdashboard", icon: "fa fa-chart-pie" },
    { name: "financeiro", location: "", icon: "fa fa-chart-line" },
    { name: "operacao", location: "", icon: "far fa-building" },
    { name: "disponibilidade", location: "", icon: "far fa-calendar-check" },
    { name: "reserva", location: "", icon: "fa fa-grip-horizontal" },
    { name: "clientes", location: "", icon: "far fa-user" },
    { name: "tarifario", location: "tariff", icon: "far fa-chart-bar" },
    { name: "unidade", location: "units", icon: "far fa-building" },
    { name: "log acao", location: "", icon: "far fa-file-alt" },
    { name: "funcionario", location: "employee", icon: "far fa-address-card" },
    { name: "channel manager", location: "channel-manager", icon: "fa fa-network-wired" },
    { name: "configuracao", location: "", icon: "fa fa-cog" },

    { name: "mapa_reserva", location: "reservations", icon: "" },
    { name: "find_reservation", location: "reservations/list", icon: "" },
    { name: "portador", location: "portador", icon: "" },
    { name: "mapa_disponibilidade", location: "availability", icon: "" },
    { name: "mapa_ocupacao", location: "occupation", icon: "" },
    { name: "unidade", location: "units", icon: "" },
    { name: "categoria_unidade", location: "categories", icon: "" },
    { name: "predio_unidade", location: "buildings", icon: "" },
    { name: "relatorio_financeiro", location: "report/financial", icon: "" },
    { name: "relatorio ocupacao", location: "report/occupation", icon: "" },

    { name: "closing_billing_report", location: "report/closing", icon: "" },
    { name: "reservation_report", location: "report/sales", icon: "" },
    { name: "reservation_notpayed_report", location: "report/nopay", icon: "" },

    { name: "log", location: "report/activity", icon: "" },
    { name: "pdv", location: "pdv", icon: "" },
    { name: "relatorio estorno", location: "report/reverse", icon: "" },
    { name: "bukly_manager", location: "channel-manager/bukly", icon: "" },
    { name: "configuracao_hotel", location: "settings/hotel", icon: "" },
    { name: "billing_report", location: "report/billing", icon: "" },
    { name: "carteira_clientes", location: "client/wallet", icon: "" },
    { name: "manutencao", location: "unit/problems", icon: "" },

    { name: "ota_fees", location: "/otafees", icon: "" },

    { name: "report_invoices_issued", location: "report/invoices", icon: "" },


    { name: "proprietario", location: "", icon: "fa fa-user-tag" },
    { name: "manutencao_proprietario", location: "unit/OwnerProblems", icon: "" },

];
