import { Injectable } from '@angular/core';
import {Observable} from 'rxjs/Rx';
import * as moment from 'moment';
import {ResponseFormat} from '@app/types/response-format';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse  } from '@angular/common/http';

@Injectable()
export class ReportOccupationService {

  constructor(private http : HttpClient) { }

  filterReport(establishmentId,categoryId,unitId,startDate, endDate){
    var queryparam = '';
    if (categoryId != 0 && unitId != 0) { 
        queryparam = "?categoryId="+categoryId+"&unitId="+unitId; 
    } else {
      if (categoryId != 0)
         queryparam = "?categoryId="+categoryId;
      if (unitId != 0)    
         queryparam = "?unitId="+unitId; 
    }
    return this.http.get<ResponseFormat>('vivakey/rest/reservation/getOccupationMap/report/'+establishmentId+'/'+startDate+'T00:00:00.000-0700/'+endDate+'T00:00:00.000-0700/'+queryparam)
  }
}
