import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit {

	userInfo: any

  constructor() { }

  ngOnInit() {
  	this.userInfo = JSON.parse(localStorage.getItem('currentUserInfo'));
  }

}
