import { Component, OnInit } from '@angular/core';
import { ReservationsService } from '../reservations/reservations.service';
import { ReportReverseService } from './report-reverse.service';
import { TranslateService } from '@ngx-translate/core';
import { NotifierService } from 'angular-notifier';
import { AppStateService } from '@src/app/layouts/app-layout.service';

@Component({
    selector: 'app-report-reverse',
    templateUrl: './report-reverse.component.html',
    styleUrls: [
        './report-reverse.component.css'
    ]
})

export class ReportReverseComponent implements OnInit 
{
	categories : any = [ {
		name : "Selecione",
		categoryId : 0
	}];
	units : any = [ {
		name : "Selecione",
		unitId : 0
	}];
	selectCategory  : any;
	selectUnit  : any;
	establishment : any;
	selectStartDate : any;
	selectFinishDate : any;
	step : any = 0;
	showFilters : boolean = false;			
	emptyDateWarn : boolean = false;
	reverseReport : any = [];
	expandedArr : any = [];

    constructor(
        private appStateService: AppStateService,
        private reservationsService: ReservationsService,
        private reportReverseService: ReportReverseService,
        private notifier: NotifierService,
        private translate: TranslateService,
    ) { }

    ngOnInit() 
    {
        this.selectCategory = this.categories[0];
        this.selectUnit = this.units[0];
        this.establishment = this.appStateService.currentEstablishment;
        this.generateFilter();
    }

    generateFilter = () =>
    {
        this.reservationsService.getCategories(this.establishment.establishmentId).subscribe(data => 
        {
            switch (data.code) 
            {
                case 200:
                    this.categories = [{
                        name : "Selecione",
                        categoryId : 0
                    }];

                    for (let i = data.object.length - 1; i >= 0; i--) 
                    {
                        this.categories.push(data.object[i])
                    }

                    this.selectCategory = this.categories[0];
                    this.step = 2;
                    break;
                case 218:
                    console.log('nao possui categorias');
                    break;
            }
        }, error => console.log("Error", error));

        this.reservationsService.getUnits(null,this.establishment.establishmentId,this.selectCategory.categoryId).subscribe(data => 
        {
            switch (data.code) 
            {
                case 200:
                    this.units = [ {
                        name : "Selecione",
                        unitId : 0
                    }];
                    
                    for (let i = data.object.length - 1; i >= 0; i--) 
                    {
                        this.units.push(data.object[i])
                    }
                    this.selectUnit = this.units[0];
                    this.step = 2;
                    break;
                case 218:
                    console.log('nao possui categorias');
                    break;
            }
        }, error => console.log("Error", error));  
    }

    changeUnitSelect = () =>
    {
        this.reservationsService.getUnits(null,this.establishment.establishmentId,this.selectCategory.categoryId).subscribe(data => 
        {
            switch (data.code) 
            {
	  	 		case 200:
	  	 			this.units = [{
						name : "Selecione",
						unitId : 0
					}];
                    
                    for (let i = data.object.length - 1; i >= 0; i--) 
                    {
	  	 		    	this.units.push(data.object[i])
	  	 		    }
                       
                    this.selectUnit = this.units[0];
	  	 		    this.step = 2;
	  	 			break;
	  	 		case 218:
	  	 			console.log('nao possui categorias');
	  	 			break;
	  	 	}
	    }, error => console.log("Error")); 
  	}

    filterReport = () =>
    {
  		if (!this.selectStartDate || !this.selectFinishDate)
  			this.emptyDateWarn = true;
        else 
        {
  			this.emptyDateWarn = false;
            this.reportReverseService.filterReport(this.establishment.establishmentId,this.selectCategory.categoryId,this.selectUnit.unitId,this.selectStartDate,this.selectFinishDate).subscribe(data => 
            {
	  			console.log(data);
                switch (data.code) 
                {
		  	 		case 200:
	  	 				this.showFilters = true;
	  	 				var auxData;
                        // for (let i = 0; i < data.object.length; i++) 
                        //{
	  	 				// 	auxData = data.object[i].date.split('T')[0].split('-');
	  	 				// 	data.object[i].dateFormated = auxData[2]+'/'+auxData[1]+'/'+auxData[0]; 
	  	 				// }	
	  	 				this.reverseReport =  data.object;
	  	 			    break;
		  	 		case 218:
		  	 			console.log('nao possui categorias');
		  	 			break;
			  	}
		    }, error => this.notifier.notify('error', this.translate.instant('error_msg.error_getdata' )));
	    } 
  	}

  	expandCard = cardId => this.expandedArr.push(cardId);

    recallCard = cardId =>
    {
  		const index = this.expandedArr.indexOf(cardId);
  		this.expandedArr.splice(index,1);
  	}

    clearCategoryFilter = () => 
    {
        this.selectCategory = this.categories[0]; 
        this.filterReport();
    }
      
    clearUnitFilter = () => 
    {
        this.selectUnit = this.units[0];
        this.filterReport();
    }

    closeWarningDate = () => this.emptyDateWarn = false;
}
