import { Injectable } from '@angular/core';
import {ResponseFormat} from '@app/types/response-format';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse  } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UnitProblemPageService {

  constructor(private http : HttpClient) { }

  getUnits = (establishmentId, hasMenuOwner = 'false') =>
  {
      let params = new HttpParams();
  
      params = params.append('hasMenuOwner', hasMenuOwner);
      params = params.append('establishmentId', establishmentId);
      //params = params.append('categoryId', '0');

      return this.http.get<ResponseFormat>('vivakey/rest/unit/getUnits?hasMenuOwner=' + hasMenuOwner, {
        params: params
      });
  }    

 
  getAllUnitProblems = (establishmentId, initialDate, finalDate,  _params) => {

    let params = new HttpParams();
		Object.keys(_params).map((key, index) => {
			params = params.append(key, _params[key])
    })

  	return this.http.get<ResponseFormat>(`vivakey/rest/unitProblem/getUnitProblems/${establishmentId}/${initialDate}/${finalDate}`,  {
			params: params
		});
  }

   reportProblem = (body) => {
  	return this.http.post<ResponseFormat>(`vivakey/rest/unitProblem`, body);
  }

  saveUnitProblem = (solved, body) => {
    return this.http.put<ResponseFormat>(`vivakey/rest/unitProblem/${body.unitProblemId}/${solved}`, body);
  }
}
