import { Component, OnInit } from '@angular/core';

import { NotifierService } from 'angular-notifier';
import { CreateHotelService } from '@pages/create-hotel/create-hotel.service'
import { Router, ActivatedRoute } from "@angular/router";
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-create-company',
  templateUrl: './create-company.component.html',
  styleUrls: ['./create-company.component.scss']
})
export class CreateCompanyComponent implements OnInit {

	name: string = "";
	documentNumber: string = "";
	description: string = "";
	director: any;

	employees: any;

	loading: boolean = false


	constructor(private service: CreateHotelService, private notifier: NotifierService, private translate: TranslateService,
		private router: Router) { }

	ngOnInit() {

		this.getEmployees();
	}

	query() {
		const { name, documentNumber, description, director } = this
		if(name && documentNumber && description && name.length > 0 && documentNumber.length > 0 && description.length > 0 && director !== null) {
			let body = {
				"name": name,
				"documentNumber": documentNumber,
				"description": description,
				"directorId": director.employeeId
			}

			this.loading = true

			this.service.createCompany(body).subscribe((data) => {
				this.notifier.notify('success', this.translate.instant('create-company.success_saved'))
				this.loading = false
				this.router.navigate(['/home'])
			}, error => {
				this.loading = false
				this.notifier.notify('error', this.translate.instant('create-company.error_save'))
			})
		}
	}


	getEmployees()
    {
		console.log("GetEmployees");


        this.service.getEmployees().subscribe(data =>
        {
            console.log("GetEmployees", data.object);
            this.loading = false
            switch (data.code)
            {
                case 200:
                this.employees = data.object;
                break;
                case 219:
                console.log('no permission');
                break;
            }
            this.loading = false
        }, error => {
            this.loading = false
            this.notifier.notify('error', this.translate.instant('create-company.error_load_employes'))
            console.log("Error", error)
        });
    }



}
