import { Component, OnInit } from '@angular/core';
import { LoginService } from '../login/login.service';
import Chart from 'chart.js';
import CountUp from 'countup.js';
import { TranslateService } from '@ngx-translate/core';
import { AppStateService } from '@src/app/layouts/app-layout.service';
import { DashFilterMode } from './dash-filter-type';

declare var $:any;

const countUpOptions = {
    useEasing: true,
    useGrouping: true,
    separator: '.',
    decimal: ',',
};

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: [
    './dashboard.component.css',
      ]
})

export class DashboardComponent implements OnInit 
{
    selectedCompany : any;
    selectedEstablishment : any;
    userInfo : any;
    loading: boolean = true;

    filterMode: DashFilterMode = DashFilterMode.Todos;

    buildings: any[];
    units: any[];
    categories: any[];

    buildingId: any;
    unitId: any;
    categoryId: any;

    public dashboardData: any;

    
    remainingPerc = '';

    constructor(
        public appStateService: AppStateService,
        private loginservice: LoginService,
        private translate: TranslateService,) {
        this.remainingPerc = this.translate.instant('dashboard.remaining_perc');
    }

    ngOnInit() 
    {
        this.selectedCompany = this.appStateService.currentCompany;
        this.selectedEstablishment = this.appStateService.currentEstablishment;
        this.userInfo = JSON.parse(localStorage.getItem('currentUserInfo'));
        let lastDashboardData = sessionStorage.getItem('lastDashboardData');
        if(lastDashboardData) {
            try {
                lastDashboardData = JSON.parse(lastDashboardData)
                this.dashboardData = lastDashboardData
                this.setup()
                this.loading = false
            }  catch(err) {
                this.query()
            }
        } else {
                this.query()
        }
       
    }


    getFilterData() {

        this.buildingId = null;
        this.unitId = null;
        this.categoryId = null;

        switch (this.filterMode) {

            case DashFilterMode.Todos:
                this.query();
                break;

            case DashFilterMode.Building:
                if (this.buildings) return;
                this.loginservice.getDashboardBuildings(this.selectedEstablishment.establishmentId).subscribe(resp => {
                    this.buildings = resp.object;
                });
                break;

            case DashFilterMode.Unit:
                if (this.units) return;
                this.loginservice.getDashboardUnits(this.selectedEstablishment.establishmentId).subscribe(resp => {
                    this.units = resp.object;
                });
                break;

            case DashFilterMode.Category:
                if (this.categories) return;
                this.loginservice.getDashboardCategories(this.selectedEstablishment.establishmentId).subscribe(resp => {
                    this.categories = resp.object;
                });
                break;

            default:
                break;
        }
    }

    query() {

        switch (this.filterMode) {

            case DashFilterMode.Todos:
                this.buildingId = null;
                this.unitId = null;
                this.categoryId = null;
                break;

            case DashFilterMode.Building:
                this.unitId = null;
                this.categoryId = null;
                break;

            case DashFilterMode.Unit:
                this.buildingId = null;
                this.categoryId = null;
                break;

            default:
                break;
        }

        this.loading = true
         this.loginservice.getDashboard(this.selectedEstablishment.establishmentId, this.buildingId, this.unitId, this.categoryId).subscribe(
            (data) => {
                switch (data.code) 
                {
                    case 200:
                    this.dashboardData = data.object
                    sessionStorage.setItem('lastDashboardData', JSON.stringify(data.object));
                    this.setup();
                    break;
                    default:
                    // TODO:
                    // Handle errors
                }
                this.loading = false
            },
            (error) => {
                // TODO:
                // Handle errors
                console.log('erro')
                this.loading = false;
            },
            () => {
                this.loading = false;
            });
    }

    startcountUp(id, value) {
        let countup = new CountUp(id, 0, value, 2, 2.5, countUpOptions);
        countup.start()
    }

    setup() {

        const dashboardData = this.dashboardData
        console.log('dashboardData', dashboardData)

        let percent = dashboardData.today.billing.occupancyRate

        this.assignDoughnutChart('chart1', {
            data: [percent, 100-percent],
            colors: [ '#ec73b3', '#E8E8E8' ],
            labels: [  this.translate.instant('dashboard.occupancy_rate_day'), this.remainingPerc],
        })
        this.startcountUp('chartnb1', percent)

        percent = dashboardData.month.billing.occupancyRate

        this.assignDoughnutChart('chart2', {
            data: [percent, 100-percent],
            colors: [ '#7BC539', '#E8E8E8' ],
            labels: [ this.translate.instant('dashboard.occupancy_rate_month'), this.remainingPerc],
        })
        this.startcountUp('chartnb2', percent)

        percent = dashboardData.year.billing.occupancyRate

        this.assignDoughnutChart('chart3', {
            data: [percent, 100-percent],
            colors: [ '#2343DB', '#E8E8E8' ],
            labels: [ this.translate.instant('dashboard.occupancy_rate_year'), this.remainingPerc],
        })
        this.startcountUp('chartnb3', percent)

        percent = dashboardData.next30Days.billing.occupancyRate

        this.assignDoughnutChart('chart4', {
            data: [percent, 100-percent],
            colors: [ '#FFE700', '#E8E8E8' ],
            labels: [ this.translate.instant('dashboard.occupancy_rate_30days'), this.remainingPerc],
        })
        this.startcountUp('chartnb4', percent)


        $('.countup').each(function (index) {
            let id = `countup_${index}`;
            $(this).attr('id', id)

            const period = $(this).attr('data-period')
            const getter = $(this).attr('data-get')
            const value = dashboardData[period].billing[getter]

            let countup = new CountUp(id, 0, value, 2, 2.5, countUpOptions);
            countup.start()
        });


    }

    assignDoughnutChart(elId, body) {
        let ctx = document.getElementById(elId);
        let chart = new Chart(ctx, {
            type: 'doughnut',
            data: {
                datasets: [{
                    data: body.data,
                    backgroundColor: body.colors,
                    borderWidth : 0,
                }],
                labels: body.labels,
            },
            options: {
                cutoutPercentage : 80,
                legend: {
                    display: false, 
                }
            }
        });
    }

    logout = () => this.loginservice.logout();
}
