import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ChoseCompanyService } from '../chose-company/chose-company.service';
import { ReservationsService } from '../reservations/reservations.service';
import { ReportOccupationService } from './report-occupation.service';
import { NotifierService } from 'angular-notifier';
import { TranslateService } from '@ngx-translate/core';
import { AppStateService } from '@src/app/layouts/app-layout.service';

@Component({
    selector: 'app-report-occupation',
    templateUrl: './report-occupation.component.html',
    styleUrls: [
        './report-occupation.component.css'
    ]
})
export class ReportOccupationComponent implements OnInit 
{
    categories: any = [{
        name: "Selecione",
        categoryId: 0
    }];
    units: any = [{
        name: "Selecione",
        unitId: 0
    }];
    selectCategory: any;
    selectUnit: any;
    daysQuantity: number;
    occupationReport: any = [];
    selectStartDate: any;
    selectFinishDate: any;
    establishment: any;
    step: any = 0;
    showFilters: boolean = false;
    emptyDateWarn: boolean = false;

    constructor(
        private appStateService: AppStateService,
        private router: Router, 
        private choseCompanyService: ChoseCompanyService, 
        private reservationsService: ReservationsService,
        private reportOccupationService: ReportOccupationService,
        private notifier: NotifierService,
        private translate: TranslateService,
    ) { }

    ngOnInit() 
    {
        this.selectCategory = this.categories[0];
        this.selectUnit = this.units[0];
        this.establishment = this.appStateService.currentEstablishment;
        this.generateFilters();
    }

    generateFilters = () =>
    {
        this.reservationsService.getCategories(this.establishment.establishmentId).subscribe(data => 
        {
            switch (data.code) 
            {
	  	 		case 200:
	  	 			this.categories = [{
						name: "Selecione",
						categoryId: 0
					}];
                       
                    for (let i = data.object.length - 1; i >= 0; i--)
	  	 		    	this.categories.push(data.object[i])
	  	 		    
	  	 		    this.selectCategory = this.categories[0];
	  	 		    this.step = 2;
	  	 			break;
	  	 		case 218:
	  	 			console.log('nao possui categorias');
	  	 			break;
	  	 	}
        }, error => console.log("Error"));
        
        this.reservationsService.getUnits(null,this.establishment.establishmentId,this.selectCategory.categoryId).subscribe(data => 
        {
            switch (data.code) 
            {
	  	 		case 200:
	  	 			this.units = [{
						name: "Selecione",
						unitId: 0
                    }];
                    
	  	 		    for (let i = data.object.length - 1; i >= 0; i--)
                       this.units.push(data.object[i])
                        
	  	 		    this.selectUnit = this.units[0];
	  	 		    this.step = 2;
	  	 			break;
	  	 		case 218:
	  	 			console.log('nao possui categorias');
	  	 			break;
	  	 	}
	    }, error => console.log("Error"));  
    }

    disableShowFilter = () => this.showFilters = false;

    changeUnitSelect = () =>
    {
    	this.showFilters = false;
        this.reservationsService.getUnits(null,this.establishment.establishmentId,this.selectCategory.categoryId).subscribe(data => 
        {
            switch (data.code) 
            {
	  	 		case 200:
	  	 			this.units = [{
						name: "Selecione",
						unitId: 0
					}];
                       
                    for (let i = data.object.length - 1; i >= 0; i--)
	  	 		    	this.units.push(data.object[i])
	  	 		    
	  	 		    this.selectUnit = this.units[0];
	  	 		    this.step = 2;
	  	 			break;
	  	 		case 218:
	  	 			console.log('nao possui categorias');
	  	 			break;
	  	 	}
        }, error => console.log("Error")); 
  	}

    filterReport = () =>
    {
  		if (!this.selectStartDate || !this.selectFinishDate)
  			this.emptyDateWarn = true;
        else 
        {
  			this.emptyDateWarn = false;
            this.reportOccupationService.filterReport(this.establishment.establishmentId,this.selectCategory.categoryId,this.selectUnit.unitId,this.selectStartDate,this.selectFinishDate).subscribe(data => 
            {
	  			console.log(data);
                switch (data.code) 
                {
		  	 		case 200:
                        this.showFilters = true;
                        this.daysQuantity = data.object.daysQuantity;
                        for (let i = data.object.units.length - 1; i >= 0; i--) 
                        {
                            data.object.units[i].width = 100 - (data.object.units[i].busyDaysQuantity * 100 / this.daysQuantity);
                            data.object.units[i].occupationPercent =   Math.round(data.object.units[i].busyDaysQuantity * 100 / this.daysQuantity)+ '%';
                            if (data.object.units[i].width > 50 )
                                data.object.units[i].color = '#ff2424';
                            if (data.object.units[i].width == 50 )
                                data.object.units[i].color = '#9e9da1';
                            if (data.object.units[i].width < 50 )
                                data.object.units[i].color = '#5037a2';
                        }
                        this.occupationReport =  data.object.units;
                        break;
		  	 		case 218:
		  	 			console.log('nao possui categorias');
		  	 			break;
			  	}
		    }, error => {
                this.notifier.notify('error', this.translate.instant('error_msg.error_getdata' ))
            });
	    } 
  	}

    clearCategoryFilter = () =>
    {
	 	this.selectCategory = this.categories[0];
	 	this.filterReport();
    }
      
    clearUnitFilter = () =>
    {
  	 	this.selectUnit = this.units[0];
	 	this.filterReport();
    }
    
    closeWarningDate = () => this.emptyDateWarn = false;
}
