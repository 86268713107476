import { Injectable } from '@angular/core';
import {Observable} from 'rxjs/Rx';
import * as moment from 'moment';
import {ResponseFormat} from '@app/types/response-format';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse  } from '@angular/common/http';

@Injectable()
export class ReservationPaymentService
{
    constructor(private http : HttpClient) { }

    getReservationPaymentInfo = reservationId => this.http.get<ResponseFormat>('vivakey/rest/reservation/getPayments/'+reservationId);

    getReservationFullPaymentInfo = reservationId => this.http.get<ResponseFormat>('vivakey/rest/payment/getInstalmentPaymentAndExtraRates/' + reservationId)
    
    getPaymentInfo = groupId => this.http.get<ResponseFormat>('vivakey/rest/payment/' + groupId)

    setInstalments = body => this.http.post<ResponseFormat>('vivakey/rest/instalment/', body);

    setAditionalFeeValue = (additionalFeeId, reservationId, price, descriptionAditionalFee: string) =>
    {
        const body = {
            additionalFeeId,
            reservationId,
            price
        };

        if(descriptionAditionalFee){
            body["description"] = descriptionAditionalFee.split("\n").join("<br>");;
        }

        return this.http.post<ResponseFormat>('vivakey/rest/reservation/additionalFee/', body);
    }

    getBanks = establishmentId => this.http.get<ResponseFormat>('vivakey/rest/bankPaymentType/getBankPaymentTypes/'+establishmentId+'?status=1');

    sendPayments = (groupPaymentType, authorizationId, parcelas, extras) =>
    {
        const body = {
            groupPaymentType: groupPaymentType,
            authorizationId: authorizationId,
            paymentInstalmentRegisterRequests: parcelas,
            paymentAdditionalFeeRequests: extras
        };

        return this.http.post<ResponseFormat>('vivakey/rest/payment', body);
    }

    sendPaymentsZoop = (parcelas, extras, sendToClient, establishmentId) =>
    {
        const body = {
            establishmentId: establishmentId,
            groupPaymentType: sendToClient == true ? 3 : 2,
            paymentInstalmentRegisterRequests: parcelas,
            paymentAdditionalFeeRequests: extras
        };

        return this.http.post<ResponseFormat>(`vivakey/rest/payment/zoop/billinglink`, body);
    }

    ressendEmailZoop = (groupPaymentId, email, responsibleName, establishmentId) =>
    {
        const body = {
            establishmentId: establishmentId,
            groupPaymentId: groupPaymentId,
            email : email,
            name: responsibleName
        };
        return this.http.post<ResponseFormat>(`vivakey/rest/payment/zoop/billinglink/email/`, body);
    }

    deleteZoopLink = (billingLinkId, establishmentId) => {
        return this.http.delete<ResponseFormat>(`vivakey/rest/billinglink/grouppayment/${billingLinkId}`);
    }

    deleteExtra = (additionalFeeId, currentReservation) => this.http.delete<ResponseFormat>('vivakey/rest/reservation/additionalFee/'+currentReservation+'/'+additionalFeeId);

    sendReverse = (reservationId, instalments, extras) =>
    {
        const body = {
            reservationId: reservationId,
            instalmentIds: instalments,
            additionalFeeIds: extras
        };

        return this.http.put<ResponseFormat>('vivakey/rest/payment/reversal', body);
    }


    sendReverse02 = (groupPaymentId, reversalReason, instalments, extras) =>
    {
        const body = {
            groupPaymentId: groupPaymentId,
            reason: reversalReason,
            paymentReversalInstalmentsRequests: instalments,
            paymentReversalAdditionalFeesRequests: extras
        };

        return this.http.put<ResponseFormat>('vivakey/rest/payment/reversal', body);
    }



    generateMagikey = reservationId => this.http.get<ResponseFormat>('vivakey/rest/reservation/generateMagikey/' + reservationId);

    postOpenDoor = (reservationId) =>
    {
        const body = {
            
        };
        return this.http.post<ResponseFormat>(`vivakey/rest/reservation/${reservationId}/opendoor`, body);
    }


    getAccessCode = reservationId => this.http.get<ResponseFormat>(`vivakey/rest/reservation/getMagikeyPassword/${reservationId}`);

    deleteAccessCode = (reservationId) => this.http.delete<ResponseFormat>(`vivakey/rest/reservation/removeMagikey/${reservationId}`);

    cardHolder(authorizationId){
        return this.http.get<ResponseFormat>('vivakey/rest/payment/cardholder/' + authorizationId);
    }
     
}
