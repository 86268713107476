import { Component, OnInit, Inject, Input, Output, EventEmitter } from '@angular/core';
import { CreateHotelService } from '@src/app/pages/create-hotel/create-hotel.service';
import { NotifierService } from 'angular-notifier';
import { validateCNPJ } from '../../../utils/validators'
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { removeChars } from '@src/app/utils/helpers';
import { TranslateService } from '@ngx-translate/core';
import { ReservationPaymentService } from '@src/app/pages/reservation-payment/reservation-payment.service';
import { GroupReservationForPay, paymentInstalments } from '@src/app/types/GroupReservation';
import { ReservationsService } from '@src/app/pages/reservations/reservations.service';
import * as moment from 'moment';
import Swal, { SweetAlertIcon } from 'sweetalert2';
import { HotelSettingsPageService } from '@src/app/pages/hotel-settings/hotel-settings-page.service';
import { TokenService } from '@src/app/token.service';
import { setTimeout } from 'timers';
import { AppStateService } from '@src/app/layouts/app-layout.service';


@Component({
  selector: 'app-reservation-payment-modal',
  templateUrl: './reservation-payment-modal.component.html',
  styleUrls: ['./reservation-payment-modal.component.scss']
})
export class ReservationPaymentModalComponent implements OnInit {

  loading: boolean = false;

  step = 1;

  selectedEstablishment: any;
  bankSelectWarn: boolean = false;
  paymentPosted = false;
  manualSaveModeWarn: boolean = false;
  banksArray: any = [];

  @Output() onSaved: EventEmitter<any> = new EventEmitter();
  @Output() onReloadData: EventEmitter<any> = new EventEmitter();
  @Output() onClose: EventEmitter<any> = new EventEmitter();

  @Input()
  currentReservation: any;

  @Input()
  responsible: any;

  @Input()
  groupReservationForPay: GroupReservationForPay;

  @Input()
  hasMagikeyDevice: boolean;


  sendingValue: number = 0;
  selectedMode = '';
  reversalSendEmailToPagCerto = false;

  currencyConfig;

  get showGenerateLink_PagCerto(): boolean {
        return (
                this.selectedEstablishment.establishmentConfig.hasPaymentGateway == true
                && this.selectedEstablishment.establishmentConfig.sellerId 
                && this.selectedEstablishment.establishmentConfig.sellerId.length > 0
                && this.selectedMode != 'GT'
              )
              ? true 
              : false;
  }

  windowFeatures = `menubar=0,
    location=0,
    resizable=1,
    scrollbars=1,
    status=0,
    width=500,`;

  constructor(
    private appStateService: AppStateService,
    private reservationPaymentService: ReservationPaymentService,
    private hotelSettingsService: HotelSettingsPageService,
    private notifier: NotifierService,
    private translate: TranslateService,
    private tokenService: TokenService,
    public dialog: MatDialog) {

      this.currencyConfig =  { prefix: translate.currentLang == 'en' ? '$' : 'R$', suffix:'', thousands: '.', decimal: ',', align : 'left', allowNegative : 'false' };

  }

  ngOnInit() {
    this.sendingValue = 0;
    this.selectedMode = '';
    this.selectedEstablishment = this.appStateService.currentEstablishment;
    this.getBanks(this.selectedEstablishment.establishmentId);
    console.log('ngOnInit payment modal...');
  }


  initializeData(){
    this.appStateService.reloadEstablishment().then(establishment=> {
      this.selectedEstablishment = establishment;
    });
  }

  closeCurrentModal(){
    if(this.onClose) { this.onClose.emit(); }
  }

  getBanks = establishmentId => {
    this.reservationPaymentService.getBanks(establishmentId).subscribe(data => {
      console.log("Informações", data.object);
      switch (data.code) {
        case 200:
          for (let i = 0; i < data.object.length; i++) {
            const brand = (data.object[i].brand != "" && data.object[i].brand != "NONE") ? ` - ${data.object[i].brand}` : "";
            const aux = {
              bankPaymentTypeId: data.object[i].bankPaymentTypeId,
              desc: data.object[i].bank.name + ' - ' + data.object[i].name + brand + ' - ' + data.object[i].paymentType.name ,
              agreesInstalment: data.object[i].paymentType.agreesInstalment
            }
            this.banksArray.push(aux);
          }
          break;
      }
    }, error => console.log("Error", error));
  }


  sumPaymentsGroup(itens){
    var total: number = 0;
    itens.forEach(element => {
      total += element.totalPaymentValue;
    });
    return total
  }

  checkIfagreesInstalment = parcela => {
    if (parcela.selectedWayOfPayment.agreesInstalment == false)
      parcela.instalmentNumber = 1;
  }

  openPagcertoLink(billingLinkId){
    var windowFeatures = "menubar=no,location=yes,resizable=yes,scrollbars=yes,status=yes";
    window.open(`https://billing.sandbox.pagcerto.com.br/v1/checkout/${billingLinkId}`, "CNN_WindowName", windowFeatures);
  }

  

  openZoopLink(groupPaymentId) {
    var urlPayment = this.tokenService.baseUrl_liteFront + '/pay/' + groupPaymentId;
    const win = window.open(urlPayment, "CNN_WindowName", this.windowFeatures);

    console.log('opened...');

    var timer = setInterval(() => {
      console.log(win.closed);
      if (win.closed === true) {
        clearInterval(timer);
        this.onReloadData.emit();
      }
    }, 1000);

  }


  deleteZoopLink(billingLinkId){
    this.loading = true;
    this.reservationPaymentService.deleteZoopLink(billingLinkId, this.selectedEstablishment.establishmentId).subscribe(ret => {
      this.loading = false;
      if(ret.code == 200){
        this.onReloadData.emit();
      }
    }, err =>{
      this.notifier.notify( "error", this.translate.instant('error_msg.error_undefined'));
      this.loading = false;
    });
    
  }

  next() {

    window.scroll(0,0);

    this.reversalSendEmailToPagCerto = false;
    
    switch (this.step) {
      case 1:
        {
          
          
          this.groupReservationForPay.dataGroup.hasOnlineSelected = this.groupReservationForPay.dataGroup.onlinePayments.find(i => i.selected == true) != null ? true : false;
          this.groupReservationForPay.dataGroup.hasBalconySelected = this.groupReservationForPay.dataGroup.balconyPayments.find(i => i.selected == true) != null ? true : false;
          this.groupReservationForPay.dataGroup.selectedPayments = [];

          if(this.groupReservationForPay.dataGroup.hasOnlineSelected == true){
            
            this.groupReservationForPay.dataGroup.onlinePayments.forEach(element => {

              if (element.selected == true) {   
                
                element.manualSaveMode = this.showGenerateLink_PagCerto == false ? 'MANUAL' : '';
                this.groupReservationForPay.dataGroup.selectedPayments.push(element);

                element.payments.forEach(payment => {
                  if(payment.reversalSendEmailToPagCerto == true){
                    this.reversalSendEmailToPagCerto = true;
                  }
                });

              }

            });
          }
         

          if (this.groupReservationForPay.dataGroup.hasBalconySelected == true) {

            var groupIntallment: paymentInstalments = new paymentInstalments();
            groupIntallment.selected = true;
            groupIntallment.online = false;
            groupIntallment.totalInstalmentsValue = 0;
            groupIntallment.totalAditionalFeeValue = 0;
            groupIntallment.totalPaymentValue = 0;
            groupIntallment.paymentStatus = 'NO_PAIED';
            groupIntallment.groupName = 'balconyPayments_Grouped';

            this.groupReservationForPay.dataGroup.balconyPayments.forEach(element => {
              if (element.selected == true) {

                element.payments.forEach(payment => {
                  groupIntallment.totalPaymentValue += payment.paymentValue;
                  groupIntallment.payments.push(payment);

                });
              }
            });

            groupIntallment.manualSaveMode = this.showGenerateLink_PagCerto == false ? 'MANUAL' : '';
            this.groupReservationForPay.dataGroup.selectedPayments.push(groupIntallment);

          }
          
          

          if( this.groupReservationForPay.dataGroup.selectedPayments.length <=0)
            break;


          if(this.groupReservationForPay.hasPendingIssues == true && this.groupReservationForPay.dataGroup.hasOnlineSelected == true){
            this.showMessage('Oops...', '<span style="font-size: 14px;">' 
                              + this.translate.instant('reservation_payment.error_pending_issues') + '<span>'
                              + '<span style="font-size: 16px; font-weight: bold"><BR><BR>BuklyID: ' + this.groupReservationForPay.buklyId + '<span>', 'error');

            break;
          }


          if(this.groupReservationForPay.cardBlocked == true && this.groupReservationForPay.dataGroup.hasOnlineSelected == true){
            this.selectedMode = 'VL';
            this.step = 3;
            console.log('cardBlocked = true');

            this.showMessage('Oops...', '<span style="font-size: 14px;">' 
                              + this.translate.instant('reservation_payment.error_card_blocked') + '<span>'
                              + '<span style="font-size: 16px; font-weight: bold"><BR><BR>BuklyID: ' + this.groupReservationForPay.buklyId + '<span>', 'error');

            break;
          }
          

          if(this.reversalSendEmailToPagCerto) {
            this.selectedMode = 'VL';
            this.step = 3;
            console.log('reversalSendEmailToPagCerto = true');

            this.showMessage('', '<span style="font-size: 14px;">' 
            + this.translate.instant('reservation_payment.error_has_reversal_email') + '<span>'
            + '<span style="font-size: 16px; font-weight: bold"><BR><BR>BuklyID: ' + this.groupReservationForPay.buklyId + '<span>');

            break;
          }

          if (this.groupReservationForPay.dataGroup.hasOnlineSelected == true
            && this.selectedEstablishment.establishmentConfig.hasPaymentGateway == true) {

            //Verificar se existe banco selecionado
            //se não existir, pular para pagamento manual
            var hasBank = false;
            this.groupReservationForPay.dataGroup.selectedPayments.forEach(element => {
              if (element.selectedWayOfPayment != null) {
                hasBank = true;
              }
            });
            if (hasBank == false) {
              this.selectedMode = 'VL';
              this.step = 3;
              console.log('hasBank = false');

              this.showMessage('', '<span style="font-size: 14px;">'
                + this.translate.instant('reservation_payment.error_no_bank_inonlinepayment')
                + '</span>');


              break;
            }

          }

          if(this.groupReservationForPay.dataGroup.hasBalconySelected == true) {
            this.selectedMode = 'VL';
            this.step = 3;
            console.log('hasBalconySelected = true');
            break;
          }

          if(this.groupReservationForPay.virtualCard === true){
            this.selectedMode = 'VL';
            this.step = 3;
            console.log('virtualCard = true');
            break;
          }

          if(this.selectedEstablishment.establishmentConfig.hasPaymentGateway != true){
            this.selectedMode = 'VL';
            this.step = 3;
            console.log('hasPaymentGateway = false');
            break;
          }

          this.step = 2;

          break;
        }

      case 2:
        {
          if (this.selectedMode != '') {

            this.groupReservationForPay.dataGroup.selectedPayments.forEach(element => {

              if(this.selectedMode == 'GT'){
                element.releaseDate = moment();
                element.manualSaveMode = 'MANUAL';
              } else {
                element.releaseDate = '';
                element.manualSaveMode = '';
              }

              if(element.selectedWayOfPayment != null) {
                var swp = this.banksArray.find(i=>i.bankPaymentTypeId == element.selectedWayOfPayment.bankPaymentTypeId);
                element.selectedWayOfPayment = swp;
              }
            });

            this.step = 3;
          }

          break;
        }
      case 3: {

        this.sendPayment();
        break;
      }

      default:
        break;
    }

  }

  showMessage(title, message, _icon: SweetAlertIcon = 'warning'){
    Swal.fire({
      position: 'top',
      icon: _icon,
      title: title,
      html:  message
    });
  }

  onSelectMode(event) {
    console.log('event', event);
  }

  goPaiedDetais(){

    this.groupReservationForPay.dataGroup.selectedPayments = [];
    this.groupReservationForPay.dataGroup.hasOnlineSelected = this.groupReservationForPay.dataGroup.onlinePayments.find(i => i.selected == true) != null ? true : false;
    this.groupReservationForPay.dataGroup.hasBalconySelected = this.groupReservationForPay.dataGroup.balconyPayments.find(i => i.selected == true) != null ? true : false;

    this.groupReservationForPay.dataGroup.onlinePayments.forEach(element => {
      if (element.paymentStatus == 'PAIED') {
        this.groupReservationForPay.dataGroup.selectedPayments.push(element);
      }
    });

    this.groupReservationForPay.dataGroup.balconyPayments.forEach(element => {
      if (element.paymentStatus == 'PAIED') {
        this.groupReservationForPay.dataGroup.selectedPayments.push(element);
      }
    });

    if( this.groupReservationForPay.dataGroup.selectedPayments.length <=0){
      return;
    }
    else{

      this.groupReservationForPay.dataGroup.selectedPayments.forEach(element => {
        if(element.selectedWayOfPayment != null) {
          var swp = this.banksArray.find(i=>i.bankPaymentTypeId == element.selectedWayOfPayment.bankPaymentTypeId);
          element.selectedWayOfPayment = swp;
        }
      });

      this.step = 3.1;
    }
    
  }


  sendPayment = () => {
    this.bankSelectWarn = false;
    this.manualSaveModeWarn = false;
    var hasError = false;
    var parcelas = [];
    var extras = [];

    var datapost = this.groupReservationForPay.dataGroup.selectedPayments;//this.groupReservationForPay.dataGroup.onlinePayments.concat(this.groupReservationForPay.dataGroup.balconyPayments);

    var hasSendPagCerto = datapost[0].manualSaveMode === 'sendLink' || datapost[0].manualSaveMode === 'viewLink';

    console.log('datapost', datapost);

    this.paymentPosted = true;

    datapost.forEach(payment => {

      if (payment.selected == true) {

        payment.payments.forEach(item => {

          var releaseDate = payment.releaseDate
          var instalmentNumber = payment.instalmentNumber ? payment.instalmentNumber : 1;

          if (!payment.selectedWayOfPayment) {
            this.bankSelectWarn = true;
          }

          if(!payment.manualSaveMode || payment.manualSaveMode == 'undefined'){
            this.manualSaveModeWarn = true;
          }

          if (item.instalmentId != null) {
            //verificacao da confirmacao de forma de pagamento
            if (!this.bankSelectWarn || hasSendPagCerto == true) {
              var aux_parcela = {
                "instalmentId": item.instalmentId,
                "paymentDate": moment().format('YYYY-MM-DD') + 'T00:00:00',
                "instalmentNumber": instalmentNumber,
              }
              if(hasSendPagCerto == false){
                if(!releaseDate)
                {
                  hasError = true;
                }
                else {
                  aux_parcela["releaseDate"] = releaseDate.format('YYYY-MM-DD') + 'T00:00:00';
                  aux_parcela["bankPaymentTypeId"] = payment.selectedWayOfPayment.bankPaymentTypeId;
                }
               
              } 
              // else {
              //   aux_parcela["bankPaymentTypeId"] = 0;
              // }
              parcelas.push(aux_parcela);
            }
          }

          if (item.additionalFeeId != null) {
            //verificacao da confirmacao de forma de pagamento
            if (!this.bankSelectWarn || hasSendPagCerto == true) {
              var aux_extra = {
                "additionalFeeId": item.additionalFeeId,
                "reservationId": item.reservationId,
                "paymentDate": moment().format('YYYY-MM-DD') + 'T00:00:00',
                "instalmentNumber": instalmentNumber,
              }
              if(hasSendPagCerto == false){
                if(!releaseDate)
                {
                  hasError = true;
                } else {
                  aux_extra["releaseDate"] = releaseDate.format('YYYY-MM-DD') + 'T00:00:00';
                  aux_extra["bankPaymentTypeId"] = payment.selectedWayOfPayment.bankPaymentTypeId;
                }

              } 
              // else {
              //   aux_extra["bankPaymentTypeId"] = 0;
              // }
              extras.push(aux_extra);
            }
          }

        });

      }

    });


    // MANUAL(0),
    // BUKLY(1),
    // GATEWAY(2),
    // GATEWAY_LINK_CLIENT(3);
    // verificacao da confirmacao de forma de pagamento
    if ((!this.bankSelectWarn || hasSendPagCerto == true) && !this.manualSaveModeWarn && !hasError) {
      console.log(extras);
      this.loading = true

      if(datapost[0].manualSaveMode === 'viewLink' || datapost[0].manualSaveMode === 'sendLink'){

        this.reservationPaymentService.sendPaymentsZoop(parcelas, extras, (datapost[0].manualSaveMode === 'sendLink'), this.selectedEstablishment.establishmentId).subscribe(data => {
          this.loading = false
          switch (data.code) {
            case 200:
              {
                if(datapost[0].manualSaveMode === 'viewLink')
                {
                  this.openZoopLink(data.object.groupPaymentId);
                  //this.resetSteep();
                } else {
                  this.onReloadData.emit();
                }

              }
              break;
          }
        },
          (error: any) => {
            this.loading = false;
            switch (error.status) {
              case 422:
                this.notifier.notify( "error", error.error.message);
                break;

              default:
                this.notifier.notify( "error", this.translate.instant('error_msg.error_undefined')); 
            }
        });
        

      } else {

        if(this.bankSelectWarn) {
          this.loading = false
          return;
        }

        this.reservationPaymentService.sendPayments(this.selectedMode == 'GT' ? 1 : 0, datapost[0].authorizationId, parcelas, extras).subscribe(data => {
          this.loading = false
          switch (data.code) {
            case 200:
              this.onReloadData.emit();
              break;
          }
        },
          (err) => {
            this.loading = false
            console.log('errPayment', err);

            if(err.error && err.error.code == 422 && err.error.message){
    
              if(err.error.message.indexOf('PMS:')> -1) {
                Swal.fire("O'ops...", err.error.message.replace('PMS:', ''), 'error');
                this.resetSteep();
                return;
              }
              
              var errObj;
    
              try {
                errObj = JSON.parse(err.error.message);
              } catch (error) {
                Swal.fire("O'ops...", this.translate.instant(`status_code.404`), 'error');
                return;
              }
    
              if(errObj) {
                this.showZoopError(errObj);
              }
    
              console.log(errObj);
            }
            else {
              this.notifier.notify( "error", this.translate.instant('error_msg.error_undefined')); 
            }
            
        });

      }

      
    }
  }


  resetSteep() {

    this.step = 1;
    this.bankSelectWarn = false;
    this.selectedMode = '';
    this.sendingValue = 0;
    this.onReloadData.emit();

  }

  showZoopError(error){
    if(error.status_code == 500
      || error.status_code == 408
      || error.status_code == 404
      || error.status_code == 401) {
        Swal.fire("O'ops...", this.translate.instant(`card_errors.status_code.${error.status_code}`), 'error');
    } else {
      Swal.fire("O'ops...", this.translate.instant(`card_errors.${error.type}.${error.category}`), 'error');
    }

    this.resetSteep();
  }


  ressendEmailZoop(groupPaymentId){
    Swal.fire({
      title: this.translate.instant('pag_certo.ressend_email_label'), 
      position: 'top',
      inputValue: this.responsible.email,
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: this.translate.instant('send'),
      cancelButtonText: this.translate.instant('cancel'),
      showLoaderOnConfirm: true,
      preConfirm: (email) => {
        return this.postressendEmailZoop(groupPaymentId, email)
          .then(resolveData => {
            return resolveData;
          }, error => {
            Swal.showValidationMessage(this.translate.instant('pag_certo.error_send_email'));
          })
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.value) {
        Swal.fire({title: this.translate.instant('pag_certo.send_link_ok_msg'), position: 'top', icon: 'success'})
      }
    })
  }


  postressendEmailZoop(groupPaymentId, email): Promise<any> {
    return new Promise((resolve, reject) => {
      this.loading = true;
      this.reservationPaymentService.ressendEmailZoop(groupPaymentId, email, this.responsible.name, this.selectedEstablishment.establishmentId).subscribe(ret => {
        this.loading = false;
        switch (ret.code) {
          case 200:
            {
              resolve(ret.object);
              break;
            }
          default:
            reject();
        }
      }, err => {
        this.loading = false;
        this.notifier.notify( "error", this.translate.instant('error_msg.error_undefined'));
        reject();
      })
    });
  }

  selectThisInstalment = parcela => {
    
    if (parcela.selected != true) {

      if (this.groupReservationForPay.dataGroup.onlinePayments.find(i => i.selected == true) != null && parcela.online == false) {
        return;
      }

      if (this.groupReservationForPay.dataGroup.balconyPayments.find(i => i.selected == true) != null && parcela.online == true) {
        return;
      }

      parcela.selected = true;
      parcela.paymentDate = moment();
      parcela.releaseDate = moment();
    }
    else {
      parcela.selected = false;
      parcela.paymentDate = '';
      parcela.releaseDate = '';
    }

    this.sumValues();
  }

  sumValues() {
    this.sendingValue = 0;
    this.groupReservationForPay.dataGroup.onlinePayments.forEach(element => {
      if (element.selected == true)
        this.sendingValue += element.totalPaymentValue;
    });

    this.groupReservationForPay.dataGroup.balconyPayments.forEach(element => {
      if (element.selected == true)
        this.sendingValue += element.totalPaymentValue;
    });
  }

}
