import { Routes } from "@angular/router";
// Guards
import { AuthGuard } from "./auth.guard";
import { DashboardPageGuard, OwnerDashboardPageGuard } from "./guards/page.guard";

// Pages
import { LoginComponent } from "./pages/login/login.component";
import { ChoseCompanyComponent } from "./pages/chose-company/chose-company.component";
import { RecoveryPasswordComponent } from "./pages/recovery-password/recovery-password.component";
import { ResetPasswordComponent } from "./pages/reset-password/reset-password.component";
import { DashboardComponent } from "./pages/dashboard/dashboard.component";
import { ReservationsComponent } from "./pages/reservations/reservations.component";
import { ReservationResponsableComponent } from "./pages/reservation-responsable/reservation-responsable.component";
import { ReservationGuestComponent } from "./pages/reservation-guest/reservation-guest.component";
import { ReservationPaymentComponent } from "./pages/reservation-payment/reservation-payment.component";
import { ReservationSummaryComponent } from "./pages/reservation-summary/reservation-summary.component";
import { TariffComponent } from "./pages/tariff/tariff.component";
import { PortadorComponent } from "./pages/portador/portador.component";
import { TipoPagamentoComponent } from "./pages/tipo-pagamento/tipo-pagamento.component";
import { UsersComponent } from "./pages/users/users.component";
import { SelfRegistrateComponent } from "./pages/self-registrate/self-registrate.component";
import { PdvComponent } from "./pages/pdv/pdv.component";
import { AvailabilityComponent } from "./pages/availability/availability.component";
import { ReportFinancialComponent } from "./pages/report-financial/report-financial.component";
import { ReportOccupationComponent } from "./pages/report-occupation/report-occupation.component";
import { UnitsComponent } from "./pages/units/units.component";
import { CategoriesComponent } from "./pages/categories/categories.component";
import { ReportReverseComponent } from "./pages/report-reverse/report-reverse.component";
import { ReportActivityComponent } from "./pages/report-activity/report-activity.component";
import { EstablishmentPolicyComponent } from "./pages/establishment-policy/establishment-policy.component";
import { ChannelManagerComponent } from "./pages/channel-manager/channel-manager.component";
import { SettingsBuklyComponent } from "./pages/settings-bukly/settings-bukly.component";
import { HotelSettingsPageComponent } from "./pages/hotel-settings/hotel-settings-page.component";
import { ReportBillingComponent } from "./pages/report-billing/report-billing.component";
import { OccupationMapPageComponent } from "./pages/occupation-map-page/occupation-map-page.component";
import { ClientCardPageComponent } from "./pages/client-card-page/client-card-page.component";
import { ActivateUserComponent } from "./pages/activate-user/activate-user.component";
import { HomePageComponent } from "./pages/home-page/home-page.component";
import { CreateHotelComponent } from './pages/create-hotel/create-hotel.component';
import { CreateCompanyComponent } from './pages/create-company/create-company.component';
import { UnitProblemPageComponent } from './pages/unit-problem-page/unit-problem-page.component';

// Layouts
import { DashboardAppComponent } from "./layouts/dashboard-app/dashboard-app.component";
import { ReportClosingAptComponent } from './pages/report-closing-of-apartments/report-closing-of-apartments.component';
import { ReportSalesComponent } from './pages/report-sales/report-sales.component';
import { ReportNoPaymentsComponent } from './pages/report-reserve-no-payments/report-reserve-no-payments.component';
import { ReservationsListComponent } from './pages/reservation-list/reservation-list.component';
import { ReportAutenticcoReservationsComponent } from './pages/report-reserve-autenticco/report-reserve-autenticco.component';
import { BuildingsComponent } from './pages/buildings/buildings.component';
import { OtasConfigComponent } from './pages/otas-config/otas-config.component';
import { ReportInvoicesComponent } from './pages/report-invoices/report-invoices.component';
import { OwnerDashboardComponent } from './pages/dashboard-owner/dashboard-owner.component';

export const appRoutes: Routes = [

    { path: '', redirectTo: '/chose-company', pathMatch: 'full' },
    {
        path: "app/:establishmentId",
        component: DashboardAppComponent,
        children: [
            { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
            {
                path: "dashboard",
                component: DashboardComponent,
                canActivate: [AuthGuard, DashboardPageGuard]
            },
            {
                path: "ownerdashboard",
                component: OwnerDashboardComponent,
                canActivate: [AuthGuard, OwnerDashboardPageGuard]
            },
            {
                path: "home",
                component: HomePageComponent,
                canActivate: [AuthGuard]
            },

            {
                path: "unit/problems",
                component: UnitProblemPageComponent,
                canActivate: [AuthGuard]
            },

            {
                path: "unit/OwnerProblems",
                component: UnitProblemPageComponent,
                canActivate: [AuthGuard]
            },

            {
                path: "hotel/create",
                component: CreateHotelComponent,
                canActivate: [AuthGuard]
            },

            {
                path: "company/create",
                component: CreateCompanyComponent,
                canActivate: [AuthGuard]
            },

            {
                path: "reservations",
                component: ReservationsComponent,
                canActivate: [AuthGuard]
            },

            {
                path: "reservations/:pageName/:reservationId",
                component: ReservationsComponent,
                canActivate: [AuthGuard]
            },

            {
                path: "reservations/:pageName/:reservationId/:validateCheckin",
                component: ReservationsComponent,
                canActivate: [AuthGuard]
            },

            {
                path: "reservations/responsable",
                component: ReservationResponsableComponent,
                canActivate: [AuthGuard]
            },
            {
                path: "reservations/payment",
                component: ReservationPaymentComponent,
                canActivate: [AuthGuard]
            },
            {
                path: "reservations/guest",
                component: ReservationGuestComponent,
                canActivate: [AuthGuard]
            },
            {
                path: "reservations/summary",
                component: ReservationSummaryComponent,
                canActivate: [AuthGuard]
            },
            {
                path: "reservations/list",
                component: ReservationsListComponent,
                canActivate: [AuthGuard]
            },
            {
                path: "tariff",
                component: TariffComponent,
                canActivate: [AuthGuard]
            },
            {
                path: "portador",
                component: PortadorComponent,
                canActivate: [AuthGuard]
            },
            {
                path: "tipo-pagamento",
                component: TipoPagamentoComponent,
                canActivate: [AuthGuard]
            },
            {
                path: "employee",
                component: UsersComponent,
                canActivate: [AuthGuard]
            },

            {
                path: "pdv",
                component: PdvComponent,
                canActivate: [AuthGuard]
            },
            {
                path: "availability",
                component: AvailabilityComponent,
                canActivate: [AuthGuard]
            },
            {
                path: "occupation",
                component: OccupationMapPageComponent,
                canActivate: [AuthGuard]
            },
            {
                path: "occupation/map",
                component: OccupationMapPageComponent,
                canActivate: [AuthGuard]
            },
            {
                path: "units",
                component: UnitsComponent,
                canActivate: [AuthGuard]
            },
            {
                path: "buildings",
                component: BuildingsComponent,
                canActivate: [AuthGuard]
            },
            {
                path: "categories",
                component: CategoriesComponent,
                canActivate: [AuthGuard]
            },
            {
                path: "otafees",
                component: OtasConfigComponent,
                canActivate: [AuthGuard]
            },
            {
                path: "report/financial",
                component: ReportFinancialComponent,
                canActivate: [AuthGuard]
            },
            {
                path: "report/invoices",
                component: ReportInvoicesComponent,
                canActivate: [AuthGuard]
            },
            {
                path: "report/billing",
                component: ReportBillingComponent,
                canActivate: [AuthGuard]
            },
            {
                path: "report/occupation",
                component: ReportOccupationComponent,
                canActivate: [AuthGuard]
            },
            {
                path: "report/reverse",
                component: ReportReverseComponent,
                canActivate: [AuthGuard]
            },
            {
                path: "report/activity",
                component: ReportActivityComponent,
                canActivate: [AuthGuard]
            },
            {
                path: "report/closing",
                component: ReportClosingAptComponent,
                canActivate: [AuthGuard]
            },
            {
                path: "report/sales",
                component: ReportSalesComponent,
                canActivate: [AuthGuard]
            },
            {
                path: "report/nopay",
                component: ReportNoPaymentsComponent,
                canActivate: [AuthGuard]
            },
            {
                path: "report/autenticco/reservations/:companyId",
                component: ReportAutenticcoReservationsComponent,
                canActivate: [AuthGuard]
            },
            {
                path: "establishment-policy",
                component: EstablishmentPolicyComponent,
                canActivate: [AuthGuard]
            },
            {
                path: "channel-manager",
                component: ChannelManagerComponent,
                canActivate: [AuthGuard]
            },
            {
                path: "channel-manager/bukly",
                component: SettingsBuklyComponent,
                canActivate: [AuthGuard]
            },
            {
                path: "settings/hotel",
                component: HotelSettingsPageComponent,
                canActivate: [AuthGuard]
            },
            {
                path: "client/wallet",
                component: ClientCardPageComponent,
                canActivate: [AuthGuard]
            },
            { path: '', redirectTo: '/home', pathMatch: "full" }
        ]
    },
    {
        path: "chose-company",
        component: ChoseCompanyComponent,
        canActivate: [AuthGuard]
    },

    {
        path: "recovery-password",
        component: RecoveryPasswordComponent
    },
    {
        path: "login",
        component: LoginComponent
    },

    {
        path: "reset-password/:token",
        component: ResetPasswordComponent
    },

    {
        path: "self-registrate/:token",
        component: SelfRegistrateComponent
    },
    {
        path: "activate-user/:token",
        component: ActivateUserComponent
    },

    // otherwise redirect to home
    { path: "**", redirectTo: "" }
];