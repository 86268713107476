import { Injectable } from '@angular/core';
import {Observable} from 'rxjs/Rx';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse  } from '@angular/common/http';
import {ResponseFormat} from '@app/types/response-format';

@Injectable()
export class EditUserService {

  constructor(private http : HttpClient) { }

	editUser(user){
		var param = {
			"email": user.email,
			"name": user.name,
			"phone": user.phone,
			"street": user.street,
			"neighborhood": user.neighborhood,
			"number": user.number,
			"city": user.city,
			"state": user.state,
			"complement": user.complement,
			"zipCode": user.zipCode
		};
		return this.http.put<ResponseFormat>('vivakey/rest/employee', param)
	}

}
