import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { SelfRegistrateService } from './self-registrate.service'

@Component({
  selector: 'app-self-registrate',
  templateUrl: './self-registrate.component.html',
  styleUrls: ['../../components/views/edit-user-form/edit-user-form.component.css','./self-registrate.component.css']
})
export class SelfRegistrateComponent implements OnInit 
{

    constructor(private route: ActivatedRoute, private selfRegistrateService: SelfRegistrateService, private notifier: NotifierService) 
    { 
        sessionStorage.removeItem('reservation_filter');
        localStorage.removeItem('currentUser')
        sessionStorage.removeItem('currentMenu');
        sessionStorage.removeItem('currentMenuCategory');
    }
  
    private sub: any;
    token: string;
    step: any = 0;
    model : any = {};
    cpfMask : any = [/\d/, /\d/,/\d/,'.',/\d/,/\d/,/\d/,'.',/\d/, /\d/,/\d/, '-',/\d/,/\d/];
    userInfo : any = {};
    loading: boolean = false;
    invalidPassword: boolean = false;

  ngOnInit() 
  {
        this.route.params.subscribe(params => 
        {
            console.log(window.location.href.toString().split("code=")[1]);
            this.token = window.location.href.toString().split("code=")[1];
            this.selfRegistrateService.authToken(this.token).subscribe(data => 
            { 
                switch (data.code) 
                {
                    case 200:
                        this.step = 1;
                        this.userInfo = data.object;
                        this.model.name = data.object.name;
                        this.model.username = data.object.username;
                        this.model.email = data.object.email;
                        console.log(data);
                        break;
                }
            }, error => console.log("Error", error));
        }, error => console.log("Error", error));
    } 

    goToStep2 = () => this.step = 2;

    closeEditUserInfoNoLoading() {
        // TODO
        // Check this method
    }

    validatePass() {
        const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[$*&@#])[0-9a-zA-Z$*&@#]{8,255}$/;
        this.invalidPassword = !regex.test(this.model.password);
        console.log(this.invalidPassword);
    }

    togglePassword = confirmacao =>
    {
        if (confirmacao)
            var aux = document.getElementById("newPasswordconfirmation_input").getAttribute("type");

            if (aux == "text"){
            document.getElementById("newPasswordconfirmation_input").setAttribute("type","password");
            }
            if (aux == "password"){
            document.getElementById("newPasswordconfirmation_input").setAttribute("type","text");
            }
        else{
            var aux = document.getElementById("newPassword_input").getAttribute("type");
            if (aux == "text"){
            document.getElementById("newPassword_input").setAttribute("type","password");
            }
            if (aux == "password"){
            document.getElementById("newPassword_input").setAttribute("type","text");
            } 
        }
    }

    sendUserInfo = () =>
    {
        this.selfRegistrateService.sendUser(this.model, this.token ).subscribe(data => { 
            switch (data.code) {
            case 200:
                    this.step = 3;
            break;
            default:
            break;
            }
        },  
        (error:any) =>{
            var body = JSON.parse(error._body);
            if(body && body.message) 
            {
                this.notifier.notify('error', body.message);
            }   
        }) 
    }  
}
