import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import createAutoCorrectedDatePipe from "text-mask-addons/dist/createAutoCorrectedDatePipe";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DependentFormService } from "./service/dependent-form.service";
import { NotifierService } from "angular-notifier";
import { Router } from "@angular/router";
import masks from "../../../utils/masks";
import { MustMatch } from "../../../utils/validators/MustMatch";
import { AppValidator } from "../../../utils/validators/AppValidator";
// import { handleRegister } from "./handleRegister";
import { handleRegister } from "./handleRegister";
import { NatDataService } from '../national-data-form/national-data.service';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from '@src/app/common-service';

@Component({
	selector: "app-dependent-form",
	templateUrl: "./dependent-form.component.html",
	styleUrls: ["./dependent-form.component.scss"]
})
export class DependentFormComponent implements OnInit {
	@Input("isForeign") isForeign: boolean;
	@Input("document") document: string;
	@Output() onRegister: EventEmitter<any> = new EventEmitter();
	@Input("type") type: string;
	@Input("mainGuestId") mainGuestId: any;
	@Input("allowPhotoUpload") allowPhotoUpload: boolean = true;

	// Forms
	submitted: boolean = false;
	form0: FormGroup;
	form1: FormGroup;
	form1_phone: string;
	contactPhoneinvalid: boolean;

	form2: FormGroup;

	public profileImage: any;
	public documentImage: any;

	// Input Masks
	masks = masks;
	public autoCorrectedDatePipe = createAutoCorrectedDatePipe("dd/mm/yyyy");

	// UI/UX Variables
	passwordVisible: boolean = false;
	currentStep: number = 0;
	loading: boolean = false;

	// Selects options
	countriesOptions: any = [];
	genderOptions = [
		{ name: "Não definido", value: null },
		{ name: "Masculino", value: 0 },
		{ name: "Feminino", value: 1 }
	];

	constructor(
		private router: Router,
		private formBuilder: FormBuilder,
		private service: DependentFormService,
		private natDtService: NatDataService,
		private notifier: NotifierService,
		private translate: TranslateService,
		private commonService: CommonService,
	) {}

	ngOnInit() {
		const { isForeign } = this;

		// Form of first step
		this.form0 = this.formBuilder.group({
			documentNumber: [
				{
					value: !isForeign ? this.document : "",
					disabled: !isForeign
				},
				Validators.required
			],
			name: ["", [Validators.required, Validators.minLength(3)]],
			identityNumber: [
				"",
				isForeign ? [] : [Validators.required, Validators.minLength(3)]
			],
			emitter: ["", isForeign ? [] : [Validators.required]],
			birthday: ["", [Validators.required, AppValidator.date()]],
			gender: [null],
			vocation: ["", [Validators.required, Validators.minLength(3)]],
			nationality: [
				{
					value: isForeign ? "" : "BRASIL",
					disabled: !isForeign
				},
				[Validators.required]
			]
		});

		// Form of second step
		this.form1 = this.formBuilder.group(
			{
				phone: [
					"",
					//[Validators.required, AppValidator.phoneAndCelphone()]
				],
				email: [
					{
						value: "",
						disabled: isForeign
					},
					[Validators.required, Validators.email]
				],
				confirmEmail: ["", [Validators.required, Validators.email]]
				// password: ["", [Validators.required, Validators.minLength(6)]],
				// confirmPassword: [
				// 	"",
				// 	[Validators.required, Validators.minLength(6)]
				// ]
			},
			{
				validator: [
					MustMatch("email", "confirmEmail")
					// MustMatch("password", "confirmPassword")
				]
			}
		);

		if (isForeign) {
			this.form1.controls.email.setValue(this.document);
		}

		// Form of third step
		const zipcodeValidators = isForeign
			? [Validators.required]
			: [Validators.required, AppValidator.cep];

		this.form2 = this.formBuilder.group({
			zipcode: ["", zipcodeValidators],
			state: [
				"",
				[
					Validators.required,
					Validators.minLength(2),
					Validators.maxLength(5)
				]
			],
			city: ["", [Validators.required, Validators.minLength(3)]],
			country: [!isForeign ? "BRASIL" : undefined, [Validators.required]],
			street: ["", [Validators.required, Validators.minLength(3)]],
			number: ["", [Validators.required, Validators.pattern(/[0-9]/)]],
			complement: [""]
		});

		this.getCountries();
	}

	nationalityChanged(event) {
		console.log('event', event);
		if(event.value === 'BRASIL') {
			this.form2.controls['country'].setValue('BRASIL');
		}	
	}

	getCountries() {
		this.loading = true;
		this.natDtService
		  .getCountries('')
		  .subscribe(
			data => {
			  if (data.code === 200 && data.object) {
				this.countriesOptions = data.object;
			  }
			  this.loading = false
			},
			error => {
			  this.loading = false
			  this.notifier.notify('error', this.translate.instant('error_msg.error_data_loading'))
			}
		  );
	}

	// get form controls
	f(n) {
		return this[`form${n}`].controls;
	}

	// page step controls
	onKeydown(event) {
		if (event.key === "Enter") {
			this.goToNextStep();
		}
	}

	onCep(cep) {
		if (this.isForeign && this.f(2).country.value !== "BRASIL") {
			return;
		}
		cep = cep.replace(/\D/g, "");
		if (cep.length === 8) {
			this.loading = true;

			this.commonService.checkZipCode(cep).subscribe(ret => {
				console.log(ret);
				this.loading = false;
				if (ret.erro == true) {
					console.log('CEP Inválido!');
				} else {
					this.f(2).city.setValue(ret.localidade);
					this.f(2).state.setValue(ret.uf);
					this.f(2).street.setValue(`${ret.logradouro}, ${ret.bairro}`);
				}
			}, err => {
				this.loading = false;
				this.translate.instant('error_msg.cep_not_found');
			});
		}
	}

	goToNextStep() {
		this.submitted = true;

		this.form1.controls.phone.setValue(this.form1_phone);

		if (this.currentStep === 0 && this.type != "dependent") {
			if (!this.allowPhotoUpload || (this.documentImage && this.profileImage)) {
				this.submitted = false;
				this.currentStep += 1;
				return true;
			} else {
				return false;
			}
		}

		if (
			(this[`form${this.currentStep}`] && this[`form${this.currentStep}`].invalid)
			|| 
			(this.currentStep== 1 && this.contactPhoneinvalid == true)
		) {
			return false;
		}

		if (this.currentStep < 7) {
			this.submitted = false;
			this.currentStep += 1;
		}
	}

	goToBackStep() {
		if (this.currentStep > 0) {
			this.currentStep -= 1;
		}
	}

	showNextStepBtn() {
		if (this.currentStep === 3 && !this.profileImage) {
			return false;
		}

		if (this.currentStep === 5 && !this.documentImage) {
			return false;
		}

		return true;
	}
	// end of page step controls

	// register and handle register
	register = () => handleRegister(this);

	// toggles
	togglePasswordVisibility() {
		this.passwordVisible = !this.passwordVisible;
		this.f(1).password.setValue("");
		if (!this.passwordVisible) {
			this.f(1).confirmPassword.setValue("");
		}
	}
	// on change emitters
	onChangePassword(e) {
		if (this.passwordVisible) {
			this.f(1).confirmPassword.setValue(e.target.value);
		}
	}
	onChangeProfileImage = event => (this.profileImage = event.image);
	onChangeDocumentImage = event => (this.documentImage = event.image);
	// end of on change emitters
}
