import { Component, OnInit } from '@angular/core';
import { LoginService } from '../login/login.service';
import { ChoseCompanyService } from '../chose-company/chose-company.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { CreateHotelService } from '../create-hotel/create-hotel.service';
import { validateCNPJ } from '../../utils/validators'
import { MatDialog, MatDialogRef } from '@angular/material';
import { CreateCompanyModalComponent } from '@src/app/components/views/create-company-modal/create-company-modal.component';
import { TouchSequence } from 'selenium-webdriver';
import { CreateEstablishmentModalComponent } from '@src/app/components/views/create-establishment-modal/create-establishment-modal.component';
import { AppStateService, ReloadCompanyEventCmd } from '@src/app/layouts/app-layout.service';


declare var $ :any;

@Component({
  selector: 'app-chose-company',
  templateUrl: './chose-company.component.html',
  styleUrls: ['./chose-company.component.css']
})

export class ChoseCompanyComponent implements OnInit 
{
    currentUser: any;
    step : any;
    companies : any = [];
    selectedCompany: any = {};
    establishments: any = [];
    selectedEstablishment: any = {};
    loading: boolean = true;

    createCompanyDialogRef: MatDialogRef<CreateCompanyModalComponent>;
    createEstablishmentDialogRef: MatDialogRef<CreateEstablishmentModalComponent>;


    constructor (
        public loginservice : LoginService, 
        private choseservice : ChoseCompanyService, 
        private hotelService: CreateHotelService,
        public appStateService: AppStateService,
        private router : Router,
        private notifier: NotifierService,
        public dialog: MatDialog
        ) { }



    ngOnInit() 
    {
        this.loginservice.getUserInfo
        this.currentUser = this.loginservice.getCurrentUserInfo();
        const token = JSON.parse(localStorage.getItem('currentUser'));


        setTimeout(() => this.loading = false, 1000); 

        this.selectedCompany.companyId = null;
        this.selectedEstablishment.establishmentId = null;

        this.getCompanyes();
    }

    logout = () => this.loginservice.logout();


    selectCompany(company) {   
        this.selectedCompany = company
        setTimeout(() => {
            this.goToStep2();
        }, 100);
    }

    selectEstablishment(establishment) {
        console.log('goToDashboard...');
        this.selectedEstablishment = establishment;
        setTimeout(() => {
            this.goToDashboard(establishment.establishmentId);
        }, 300);
    } 

    
    getCompanyes(){
        this.choseservice.getCompanies().then(data => 
            { 
                switch (data.code) 
                {
                    case 200:
                    this.companies = data.object;
                    this.step = 1;
                    break;
                    case 218:
                    console.log('nao possui lojas');
                    this.step = 0;
                    break;
                    default:
                    break;
                }
            },  
            error => console.log("Error")); 
    }

    getEstablishment(){
        this.choseservice.getEstablishments(this.selectedCompany.companyId).then(data => 
            { 
                switch (data.code) 
                {
                    case 200:
                    this.establishments = data.object;
                    break;
                    case 217:
                    this.establishments = data.object;
                    break;
                    default:
                    break;
                }
            },  
            error => console.log("Error")); 
    }

    goToDashboard = (currentEstablishmentId) => 
    {
        this.choseservice.putEstablishment(currentEstablishmentId).subscribe(data => {
            switch (data.code) {
                case 200:
                    {
                        sessionStorage.setItem('lastDashboardData', null);
                        sessionStorage.setItem('lastOwnerDashboardData', null);

                        this.appStateService.onSelecCompany.emit(this.selectedCompany);

                        var cmd = new ReloadCompanyEventCmd();
                        cmd.establishmentId = currentEstablishmentId;
                        this.appStateService.onSelecEstabishment.emit(cmd);

                        setTimeout(() => {
                            this.router.navigate([`app/${currentEstablishmentId}/home`]);
                        }, 500);

                        break;
                    }

                default:
                    break;
            }
        },
            error => console.log("Error"));
    }

    goToStep2 = () =>
    {
        this.loading = true;
        setTimeout(() => this.loading = false, 1000);   
        this.step = 2;        
        this.getEstablishment();
    }


    openAddCompany(){

        this.createCompanyDialogRef = this.dialog.open(CreateCompanyModalComponent, {
            panelClass: 'mat-dialog-fix',
            disableClose: false,
            data : { }
        });

        //this.createCompanyDialogRef.componentInstance.title = 'Excluír?';


        this.createCompanyDialogRef.afterClosed().subscribe(result => {
            if (result === true) {
                this.getCompanyes();
            }
            this.createCompanyDialogRef = null;
        });

    }

    openAddEstablishment(){

        this.createEstablishmentDialogRef = this.dialog.open(CreateEstablishmentModalComponent, {
            panelClass: 'mat-dialog-fix',
            disableClose: false,
            data : this.selectedCompany
        });

        this.createEstablishmentDialogRef.componentInstance.selectedCompany = this.selectedCompany;

        this.createEstablishmentDialogRef.afterClosed().subscribe(result => {
            if (result === true) {
                this.getEstablishment();
            }
            this.createEstablishmentDialogRef = null;
        });

    }




}
