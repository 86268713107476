import {
	Component,
	OnInit,
	Input,
	Output,
	EventEmitter,
	ViewChild,
	TemplateRef
} from "@angular/core";
import createAutoCorrectedDatePipe from "text-mask-addons/dist/createAutoCorrectedDatePipe";
import { ConfirmCardType } from "./types";
import masks from "../../../utils/masks";
import * as moment from "moment";
import * as validators from "../../../utils/validators";
import { NotifierService } from "angular-notifier";
import { translateConfirmDataToBody, translateConfirmDataToUpdateBody } from "../../../utils/translators";
import { DependentFormService } from "../dependent-form/service/dependent-form.service";
import { NatDataService } from '../national-data-form/national-data.service';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from '@src/app/common-service';
import { MatDialog, MatDialogRef } from "@angular/material";


@Component({
	selector: "app-confirm-card",
	templateUrl: "./confirm-card.component.html",
	styleUrls: ["./confirm-card.component.scss"]
})
export class ConfirmCardComponent implements OnInit {

	@Input("isForeign") isForeign: any;
	@Input("confirmData") confirmData: any;
	@Input("canEdit") canEdit: boolean = true;
	@Output("onSave") onSave: EventEmitter<any> = new EventEmitter();

	@Input("type") type: string = 'mainGuest';
	@Input('allowPhotoUpload') allowPhotoUpload: boolean = true;

	// Masks
	masks = masks;
	public autoCorrectedDatePipe = createAutoCorrectedDatePipe("dd/mm/yyyy");

	editData: any;
	password: string = "";

	// UI/UX Variables
	loading: boolean = false;
	editUpperPart: boolean = true;
	editBackPart: boolean = true;
	passwordVisible: boolean = false;

	profilePhoto: any
	documentPhoto: any
	previewPhoto: any
	showDocumentPhoto = false;

	// Selects options
	countriesOptions: any = [];
	genderOptions = [
		{ name: "Não definido", value: null },
		{ name: "Masculino", value: 0 },
		{ name: "Feminino", value: 1 }
	];

	gender: any;

	constructor(
		private dependent: DependentFormService,
		private notifier: NotifierService,
		private natDtService: NatDataService,
		private commonService: CommonService,
		public translate: TranslateService,
		public dialog: MatDialog,
	) {}

	ngOnInit() {
		this.getCountries();
		this.initialize();
	}

	defaultErrorHandler = (notifier, error) => {
		this.notifier.notify( "error", this.translate.instant('error_msg.error_undefined'));
	}

	initialize() {
		
		setTimeout(() => {
			this.editData = this.confirmData;

			console.log('editData', this.editData)

			this.editBackPart = false;
			this.editUpperPart = false;


			if (this.confirmData.profilePhotoId) {
				this.commonService.getImage(this.confirmData.profilePhotoId)
					.subscribe((imgData) => {
						if (imgData.keyName) {
							this.profilePhoto = imgData.keyName
						}
					}, error => {
					})
			}
	
			if (this.confirmData.documentPhotoId) {
				this.commonService.getImage(this.confirmData.documentPhotoId)
					.subscribe((imgData) => {
						if (imgData.keyName) {
							this.documentPhoto = imgData.keyName
						}
					}, error => {
					})
			}

		}, 100);

		

	}

	notify(message) {
		this.notifier.notify("default", message);
	}

	nationalityChanged(event) {
		console.log('event', event);
		if(event.value === 'BRASIL') {
			this.editData.nationality = 'BRASIL';
		}	
	}

	getCountries() {
		this.loading = true;
		this.natDtService
		  .getCountries('')
		  .subscribe(
			data => {
			  if (data.code === 200 && data.object) {
				this.countriesOptions = data.object;
			  }
			  this.loading = false
			},
			error => {
			  this.loading = false
			  this.notifier.notify('error', 'Houve um erro interno, tente novamente mais tarde.')
			}
		  );
	}


	save(typePart = "upperPart") {
		const { editData } = this;
		let errors = [];
		if (typePart === "upperPart") {
			// if (
			// 	editData.documentType === "CPF" &&
			// 	!validators.validateCPF(editData.documentNumber)
			// ) {
			// 	errors.push("CPF");
			// } else if (!validators.required(editData.documentNumber)) {
			// 	errors.push("Nº de Documento");
			// }

			// if (!validators.validateDate(editData.birthday)) {
			// 	errors.push("Data de Nascimento");
			// }

			if (!validators.validateEmail(editData.email)) {
				errors.push("Email");
			}

			if (!validators.required(editData.name)) {
				errors.push("Name");
			}

			if (this.type === "mainGuest"|| this.type === "dependent"){
				if (!validators.required(editData.phone)) {
					errors.push("Telefone");
				}
			}

			// if (!validators.required(editData.city)) {
			// 	errors.push("Cidade");
			// }
			// if (!validators.required(editData.zipcode)) {
			// 	errors.push("ZipCode");
			// }
			// if (!validators.required(editData.state)) {
			// 	errors.push("Estado");
			// }
			// if (!validators.required(editData.country)) {
			// 	errors.push("País");
			// }

		}

		if (errors.length === 0) {
			this.finish();
		} else {
			this.notify(`Campos incorretos: ${errors.join(", ")}`);
		}
	}

	finish() {
		this.loading = true;
		let body: any = translateConfirmDataToUpdateBody(this.editData, this.type);

		if (this.type === "dependent") {

			this.dependent.saveDependent(this.confirmData.dependentId, body).subscribe(
				resp => {
					this.onSaved(resp);
				},
				error => {
					this.loading = false;
					this.defaultErrorHandler(this.notifier, error);
				}
			);

		} 
		else if (this.type === "mainGuest") {
			
			this.dependent.saveMainGuest(this.confirmData.mainGuestId, body).subscribe(
				resp => {
					this.onSaved(resp);
				},
				error => {
					this.loading = false;
					this.defaultErrorHandler(this.notifier, error);
				}
			);
		}
		else {
			
			this.dependent.saveResponsible(this.confirmData.responsibleId, body).subscribe(
				resp => {
					this.onSaved(resp);
				},
				error => {
					this.loading = false;
					this.defaultErrorHandler(this.notifier, error);
				}
			);
		}

	}

	onSaved(resp) {
		this.loading = false;
		this.editUpperPart = false;
		this.editBackPart = false;

		if(resp.code == 200) {
			this.confirmData = this.editData;
			this.onSave.emit(this.editData);
			this.notifier.notify( "success", this.translate.instant('data_edited'));
			this.editResponsableModalDialogRef.close();
		}
		else if(resp.message) {
			this.notifier.notify( "error", resp.message);
		}
		else{
			this.notifier.notify( "error", this.translate.instant('error_msg.error_undefined'));
		}

		
	}

	onCep(cep) {
		let country = this.editData.country ? this.editData.country.toLowerCase() : '';
		if (country != "brasil" && country != "brazil") {
			return;
		}
		cep = cep.replace(/\D/g, "");
		if (cep.length === 8) {
			this.loading = true;
			this.commonService.checkZipCode(cep).subscribe(ret => {
				console.log(ret);
				this.loading = false;
				if (ret.erro == true) {
					console.log('CEP Inválido!');
				} else {
					this.editData.city = ret.localidade;
					this.editData.state = ret.uf;
					this.editData.street = `${ret.logradouro}, ${ret.bairro}`;
				}
			}, err => {
				this.loading = false;
				this.translate.instant('error_msg.cep_not_found');
			});
		}
	}

	cancelEdit() {
		this.editData = Object.assign({}, this.confirmData);
	}

	checkPasswordKeyDown(event) {
		if (event.key === "Enter") {
			this.finish();
		}
	}

	setGender(option) {
		this.editData.gender = option.value;
	}

	setBirthday(value) {
		this.editData.birthday = moment(value, "DD/MM/YYYY");
	}

	showPhoto(imageId) {
		this.loading = true;
			this.commonService.getImage(imageId).subscribe(
				(imgData) => {
					this.previewPhoto = imgData.keyName;
					this.showDocumentPhoto = true;
				},
				(error) => {
					this.loading = false;
					console.log('erro ao carregar foto !', error)
				},
				() => {
					this.loading = false;
				});
	}

	onChangeProfileImage(event) {
		console.log(event);
		if (event.image) {

			this.loading = true;
			this.commonService.editProfilePhoto(event.image, this.confirmData.mainGuestId).subscribe(
				(data) => {
					console.log(data.object);
					this.loading = false;
					this.editData.profilePhotoId = data.object;
					this.profilePhoto = event.image;
					
					//let body: any = translateConfirmDataToUpdateBody(this.editData, this.type);

					// this.loading = true;
					// this.dependent.saveMainGuest(this.confirmData.mainGuestId, body).subscribe(
					// 	resp => {
					// 		this.profilePhoto = event.image;
					// 	},
					// 	error => {
					// 		this.loading = false;
					// 		this.defaultErrorHandler(this.notifier, error);
					// 		this.initialize();
					// 	}
					// );

				},
				(error) => {
					console.log('erro ao carregar logo');
					this.initialize();
				},
				() => {
					this.loading = false;
				});

		}

		
	};


	onChangeDocumentImage(event) {
		console.log(event);
		if (event.image) {

			this.loading = true;
			this.commonService.editDocumentPhoto(event.image, this.confirmData.mainGuestId).subscribe(
				(data) => {
					console.log(data.object);
					this.loading = false;
					this.editData.documentPhotoId = data.object;
					this.documentPhoto = event.image;
					
					// let body: any = translateConfirmDataToUpdateBody(this.editData, this.type);

					// this.loading = true;
					// this.dependent.saveMainGuest(this.confirmData.mainGuestId, body).subscribe(
					// 	resp => {
					// 		this.documentPhoto = event.image;
					// 	},
					// 	error => {
					// 		this.loading = false;
					// 		this.defaultErrorHandler(this.notifier, error);
					// 		this.initialize();
					// 	}
					// );

				},
				(error) => {
					console.log('erro ao carregar logo');
					this.initialize();
				},
				() => {
					this.loading = false;
				});

		}

		
	};


	editResponsableModalDialogRef: MatDialogRef<any>;
	editResponsableDetails = (editResponsableModal) =>
    {
	 	this.editResponsableModalDialogRef = this.dialog.open(editResponsableModal);
    };


	

}
