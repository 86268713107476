import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-status-button',
  templateUrl: './status-button.component.html',
  styleUrls: ['./status-button.component.css']
})
export class StatusButtonComponent implements OnInit {

  @Input('status') status: string = 'no';
  @Input('noMessage') noMessage: string = this.translate.instant('status_message.no_message');
  @Input('loadingMessage') loadingMessage: string = this.translate.instant('status_message.changing');
  @Input('doneMessage') doneMessage: string = this.translate.instant('status_message.activated');
  @Input('disabled') disabled: boolean = false;
  @Input('size') size: string = '';
  @Input('date') date: string;

  constructor(private translate: TranslateService) {

  }

  ngOnInit() {
  }

}
