import { Injectable } from '@angular/core';
import {Observable} from 'rxjs/Rx';
import * as moment from 'moment';
import {ResponseFormat} from '@app/types/response-format';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse  } from '@angular/common/http';

@Injectable()
export class ReservationGuestService {

  constructor(private http : HttpClient) { }


  getReservationGuests(reservationId){
     return this.http.get<ResponseFormat>('vivakey/rest/reservation/getGuests/'+reservationId)
  }

  makeResponsableGuest(reservationId, responsableId){
    var body = {
      "basicRegistrationId": responsableId,
      "reservationId": reservationId
    };
    return this.http.put<ResponseFormat>('vivakey/rest/reservation/guest/',body)
  }

   assignGuestToReservation(responsableId,reservationId){
     var body = {
      "basicRegistrationId": responsableId,
      "reservationId": reservationId
     };
      return this.http.put<ResponseFormat>('vivakey/rest/reservation/guest', body)
  }

   createGuest(name, email){
    var body = 
    {
      "id": "",
      "documentNumber": "",
      "documentType": 3,
      "mobilePhoneNumber": "",
      "phoneNumber": "",
      "email": email,
      "birthDate": "",
      "nickname": "",
      "city": "",
      "state": "",
      "street": "",
      "number": "",
      "neighborhood": "",
      "zipCode": "",
      "rg": "",
      "gender": 0,
      "expeditor": "",
      "stateNumberRegister": "",
      "cityNumberRegister": "",
      "contact": "",
      "foundationDate": "",
      "area": "",
      "country": "",
      "name": name
    }

    return this.http.put<ResponseFormat>('vivakey/rest/basicRegistration', body)
  }

  removeGuest(guestId,reservationId){
    return this.http.delete<ResponseFormat>('vivakey/rest/reservation/guest/'+reservationId+'/'+guestId)
  }
}
