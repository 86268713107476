import { Component, OnInit } from '@angular/core';
import { LoginService } from '../login/login.service';
import { Http, Headers, Response } from '@angular/http';
import { Router, ActivatedRoute } from '@angular/router';
import { CPFValidator } from '@tools/Validator';

@Component({
  selector: 'app-recovery-password',
  templateUrl: './recovery-password.component.html',
  styleUrls: ['./recovery-password.component.css', '../login/login.component.css']
})

export class RecoveryPasswordComponent implements OnInit 
{
  model: any = {};
  invalidCpf: boolean;
  notFoundCpf: boolean;
  step: any;
  cpfMask : any = [/\d/, /\d/,/\d/,'.',/\d/,/\d/,/\d/,'.',/\d/, /\d/,/\d/, '-',/\d/,/\d/];
  
  constructor(private loginservice : LoginService) 
  { 
  	this.notFoundCpf = false;
  	this.step = 1;
  }

  ngOnInit() { }

  recoveryPassword = () => 
  {
    if (!CPFValidator(this.model.username))
      return this.invalidCpf = true;

    this.notFoundCpf = false;
    this.invalidCpf = false;
    
    this.loginservice.recoveryPassword(this.model.username).subscribe(data => 
    { 
      console.log(data);
      switch(data.code)
      {
        case 200:
          this.step = 2;
          break;
        case 221:
          this.notFoundCpf = true;
      }
  	},
    (error:any) =>
    {
        console.log("Error");
  		this.notFoundCpf = true;
    });
  }

}
