import { Component, OnInit } from "@angular/core";
import { ChannelManagerService } from "./channel-manager.service";
import * as _ from "lodash";
import { AppStateService } from "@src/app/layouts/app-layout.service";
declare var $: any;

@Component({
  selector: "app-channel-manager",
  templateUrl: "./channel-manager.component.html",
  styleUrls: [
    "../reservation-guest/reservation-guest.component.css",
    "../portador/portador.component.css",
    "../pdv/pdv.component.css",
    "./channel-manager.component.css"
  ]
})
export class ChannelManagerComponent implements OnInit {
  tariffOpt: any = [];
  response: any = {};
  siroMap: any;
  establishment: any;

  constructor(private channelManagerService: ChannelManagerService, private appStateService: AppStateService) {}

  ngOnInit() {
    this.establishment = this.appStateService.currentEstablishment;

    this.channelManagerService
      .getParrUrl(this.establishment.establishmentId)
      .subscribe(
        data => {
          switch (data.code) {
            case 200:
              console.log(data.object);
              data.object.siroRateList = _.uniqWith(
                data.object.siroRateList,
                _.isEqual
              );
              this.response = data.object;
              break;
            default:
              break;
          }
        },
        (error: any) => {
          console.log("Error");
        }
      );
  }

  saveSiroMap() {
    console.log(this.response.categorys);
    var postArr = [];
    for (let i = this.response.categorys.length - 1; i >= 0; i--) {
      for (
        let j = this.response.categorys[i].siroMapping.length - 1;
        j >= 0;
        j--
      ) {
        if (
          this.response.categorys[i].siroMapping[j].selectedSiroCategory ||
          this.response.categorys[i].siroMapping[j].selectedSiroRatePlan
        ) {
          var objPostSiroMapping = {};
          if (this.response.categorys[i].siroMapping[j].selectedSiroCategory) {
            objPostSiroMapping = {
              categoryId: this.response.categorys[i].categoryId,
              establishmentId: this.establishment.establishmentId,
              channelId: this.response.categorys[i].siroMapping[j].channelId,
              siroChannelId: this.response.categorys[i].siroMapping[j]
                .siroChannelId,
              siroCategoryId: this.response.categorys[i].siroMapping[j]
                .selectedSiroCategory
            };
          }
          if (this.response.categorys[i].siroMapping[j].selectedSiroCategory) {
            objPostSiroMapping = {
              categoryId: this.response.categorys[i].categoryId,
              establishmentId: this.establishment.establishmentId,
              channelId: this.response.categorys[i].siroMapping[j].channelId,
              siroChannelId: this.response.categorys[i].siroMapping[j]
                .siroChannelId,
              siroRateId: this.response.categorys[i].siroMapping[j]
                .selectedSiroRatePlan
            };
          }
          if (
            this.response.categorys[i].siroMapping[j].selectedSiroCategory &&
            this.response.categorys[i].siroMapping[j].selectedSiroRatePlan
          ) {
            objPostSiroMapping = {
              categoryId: this.response.categorys[i].categoryId,
              establishmentId: this.establishment.establishmentId,
              channelId: this.response.categorys[i].siroMapping[j].channelId,
              siroChannelId: this.response.categorys[i].siroMapping[j]
                .siroChannelId,
              siroCategoryId: this.response.categorys[i].siroMapping[j]
                .selectedSiroCategory,
              siroRateId: this.response.categorys[i].siroMapping[j]
                .selectedSiroRatePlan
            };
          }
          postArr.push(objPostSiroMapping);
        }
      }
    }
    if (postArr.length > 0) {
      this.channelManagerService.postParrUrl(postArr).subscribe(
        data => {
          switch (data.code) {
            case 200:
              $("#modalChannelManagerSaveSucess").modal("show");
              break;
            default:
              break;
          }
        },
        (error: any) => {
          console.log("Error");
        }
      );
    }
  }
}
