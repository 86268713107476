import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { IMyDpOptions, IMyDateModel } from 'mydatepicker';
import * as moment from 'moment';

@Component({
	selector: 'app-datepicker',
	templateUrl: './datepicker.component.html',
	styleUrls: ['./datepicker.component.css']
})
export class DatepickerComponent implements OnInit {

	@Input('value') value: string;
	@Output() onChange: EventEmitter<any> = new EventEmitter();

	date: any;

	datePickerOption: IMyDpOptions = {
		dateFormat: 'dd/mm/yyyy',
	};

	constructor() { }

	ngOnInit() {
		if(this.value) {
			const inputedDate = moment(this.value, 'DD/MM/YYYY');
			if(inputedDate.isValid()) {
				this.date = { date: { year: inputedDate.year(), month: inputedDate.month() + 1, day: inputedDate.date() } };
			}
		}
	}

	onDateChanged(event: IMyDateModel) {
		this.onChange.emit(event.formatted)
    }

}
