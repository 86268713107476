import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { ReportAutenticcoReservationsService } from "./report-reserve-autenticco.service";
import moment from "moment-timezone";
import { NotifierService } from 'angular-notifier';
import { TranslateService } from '@ngx-translate/core';
import { UsersService } from '../users/users.service';
import { AppStateService } from "@src/app/layouts/app-layout.service";

const salesData = require("../../utils/datas/sales-report.json");

@Component({
    selector: "app-report-reserve-autenticco",
    templateUrl: "./report-reserve-autenticco.component.html",
    styleUrls: ["./report-reserve-autenticco.component.css"]
})
export class ReportAutenticcoReservationsComponent implements OnInit {

    reportData: any[] = [];
    reportDataLength: number;
    params = {};   
    selectStartDate: any;
    selectFinishDate: any;
    diffDays:number;
    establishment: any;
    showFilters: boolean = false;
    emptyDateWarn: boolean = false;
    loadingData: boolean = false;

    company: any;
    companyId: number;

    constructor(
        private appStateService: AppStateService,
        private route: ActivatedRoute,
        private router: Router,
        private notifier: NotifierService,
        private reportService: ReportAutenticcoReservationsService,
        private translate: TranslateService,
        private usersService: UsersService
    ) { 

        this.route.params.subscribe(params => {
            this.companyId = params['companyId']; 
            
            if(this.usersService.isManagerFromCompanyId(this.companyId) == false){
                this.router.navigate([`home`]);
            }

          });

    }

    ngOnInit() {

        this.establishment = this.appStateService.currentEstablishment;

        this.selectStartDate = moment(moment().subtract("31", "days").format("YYYY-MM-DD"));
        this.selectFinishDate = moment(moment().format("YYYY-MM-DD"));
        this.diffDays = this.selectFinishDate.diff(this.selectStartDate, 'days');
        
        this.filterReport();
        this.getCompanyName();
    }

    startDateChanged(){
        this.diffDays = this.selectFinishDate.diff(this.selectStartDate, 'days');

        if(this.diffDays > 31){
            this.selectFinishDate = moment(moment(this.selectStartDate).add('31', 'days').format('YYYY-MM-DD'));
        }
        this.filterReport();
    }

    finishDateChanged(){
        this.diffDays = this.selectFinishDate.diff(this.selectStartDate, 'days');

        if(this.diffDays > 31){
            this.selectStartDate = moment(moment(this.selectFinishDate).subtract('31', 'days').format('YYYY-MM-DD'));
        }
        this.filterReport();
    }

    getCompanyName() {
        this.reportService
            .getCompanyes()
            .subscribe(
                data => {
                    
                    switch (data.code) {
                        case 200:
                            this.company =  data.object.find(i=>i.companyId == this.companyId);
                           
                            break;
                        default:
                            break;
                    }

                    this.loadingData = false
                },
                error => {
                    this.loadingData = false
                    this.notifier.notify('error', this.translate.instant('error_msg.error_getdata' ))
                }
            );
    }


    // Download the excel file
    downloadReport() {

        this.loadingData = true

        this.updateParams();

        var reportParams = {
            initDate: this.selectStartDate.format('YYYY-MM-DD'),
            endDate: this.selectFinishDate.format('YYYY-MM-DD'),
            companyId: this.companyId,
            report: true
        };


        this.reportService
            .getExcelReport(reportParams)
            .subscribe(
                resp => {
                    const keys = resp.headers.keys();
                    var headers = keys.map(key => `${key}: ${resp.headers.get(key)}`);
                    console.log("headers: ", headers);
                    var reporturl =  resp.headers.get('reporturl');

                    var message = resp.body.message;
                    if (message) {
                        window.location.href = message.replace('reporturl: ', '');
                    } else {
                        this.notifier.notify('error', this.translate.instant('error_msg.error_getdata' ))
                    }
                    this.loadingData = false
                },
                error => {
                    this.loadingData = false
                    this.notifier.notify('error', this.translate.instant('error_msg.error_getdata' ))
                }
            );

        this.filterReport();
    }




    updateParams() {

        this.showFilters = false;
        ////autenticco/reservation?establishmentId=1&initDate=2020-01-01&endDate=2020-02-05&report=true
        this.params = {
            companyId: this.companyId,
            initDate: this.selectStartDate.format('YYYY-MM-DD'),
            endDate: this.selectFinishDate.format('YYYY-MM-DD'),

        };

        // if(this.channelId && this.channelId !== 0){
        //     this.params["channelId"] = this.channelId;
        //      this.showFilters = true;
        // }
    }

    filterReport = () => {
        this.diffDays = this.selectFinishDate.diff(this.selectStartDate, 'days');
        
        if (!this.selectStartDate || !this.selectFinishDate) {
            this.emptyDateWarn = true;
        } else {
            this.loadingData = true;

            this.updateParams();
            this.reportDataLength = 0;

            this.reportService
                .filterReport(
                    this.params
                )
                .subscribe(
                    data => {
                        console.log(data);
                        switch (data.code) {
                            case 200:
                                this.reportData = data.object;
                                this.reportDataLength = this.reportData.length;
                                
                                break;
                            case 218:
                                console.log("nao possui categorias");
                                break;
                            default:
                                break;
                        }
                        this.loadingData = false;
                    },
                    error => {
                        console.log("Error");
                        this.notifier.notify('error', this.translate.instant('error_msg.error_getdata' ))
                        this.loadingData = false;
                    }
                );
        }
    };

}
