import * as moment from "moment"

const origins = {
	"ABN" : "Airbnb",
	"EXP": "Expedia",
	"BDC": "Booking",
	"BKY": "Bukly",
	"BALCAO": "Balcão",
}

const paymentTypes = {
	"MANUAL": "Pagamento Manual Operador",
	"BUKLY": "Gateway de pagamento",
	"GATEWAY": "Pagamento Link Operador",
	"GATEWAY_LINK_CLIENT": "Pagamento pelo Link",
}

export const translateOrigin = (name) => {
	return origins[name] || name
}

export const translatePaymentType = (name) => {
	return paymentTypes[name] || name
}

export const translatedOriginToCode = (origin) => {

	let result = origin
	Object.keys(origins).map((key, index) => {
		if(origins[key] == origin) {
			result = key
		}
	})

	return result;
}

export const strLimit = (str, limit) => {
	return str.length > limit ? str.substr(0, limit-3) + '...' : str }


export const dataURItoBlob = (dataURI) => {
	// convert base64/URLEncoded data component to raw binary data held in a string
	var byteString;
	if (dataURI.split(',')[0].indexOf('base64') >= 0) {
		byteString = atob(dataURI.split(',')[1]);
	} else {
		byteString = unescape(dataURI.split(',')[1]);
	}

	// separate out the mime component
	var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

	// write the bytes of the string to a typed array
	var ia = new Uint8Array(byteString.length);
	for (var i = 0; i < byteString.length; i++) {
		ia[i] = byteString.charCodeAt(i);
	}

	return new Blob([ia], {type:mimeString});
}

export const removeChars = (str) =>{

	var retValue = '';
	var regex = /(\d+)/;
	var match; 

	while (match = regex.exec(str)) {
		retValue  = retValue + match[0];
	}

	return retValue;
}

export const last12Months = (formated?) => {
	var resp = [];
	var currentDate = moment(moment().format("YYYY-MM-DD"));
	for (let index = 1; index <= 12; index++) {

		currentDate = moment(moment().subtract(index, "months").format("YYYY-MM-DD"));

		var inicialDay = moment(currentDate).startOf('month').format('YYYY-MM-DD');
		var finalDay = moment(currentDate).endOf('month').format('YYYY-MM-DD');

		var desc = moment(currentDate).format("MM/YYYY");

		resp.push({
			start: formated ? inicialDay : moment(inicialDay),
			end: formated ? finalDay : moment(finalDay),
			month: desc
		});
	}

	return resp;
}