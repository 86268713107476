export const activities = [
    { 
        method: 'AdditionalFeeRestService_addAdditionalFees', 
        formatted: "O usuário {nome} adicionou uma taxas extra"
    }, 
    { 
        method: 'AdditionalFeeRestService_deleteAdditionalFees', 
        formatted: "O usuário {nome} removeu uma taxas extra"
    },
    { 
        method: 'AreasGroupsMagikeyRestService_addAreasGroupsMagikeys', 
        formatted: "O usuário {nome} adicionou os identificadores de áreas do Magikey"
    },
    { 
        method: 'AreasGroupsMagikeyRestService_deleteAreasGroupsMagikeys', 
        formatted: "O usuário {nome} removeu os identificadores de áreas do Magikey"
    },
    { 
        method: 'BankPaymentTypeRestService_addBanks', 
        formatted: "O usuário {nome} adicionou tipos de pagamentos"
    }, 
    { 
        method: 'BankPaymentTypeRestService_deleteBanks', 
        formatted: "O usuário {nome} removeu tipos de pagamentos"
    },
    { 
        method: 'BankRestService_addBanks', 
        formatted: "O usuário {nome} adicionou portadores"
    }, 
    { 
        method: 'BankRestService_deleteBanks', 
        formatted: "O usuário {nome} removeu portadores"
    },
    { 
        method: 'InstalmentRestService_add', 
        formatted: "O usuário {nome} adicionou novas parcelas"
    }, 
    { 
        method: 'EstablishmentRestService_addObservation', 
        formatted: "O usuário {nome} adicionou uma nova observação de parcelas / de política"
    },
    { 
        method: 'EstablishmentRestService_sendEmail', 
        formatted: "O usuário {nome} enviou o email com as políticas"
    },
    { 
        method: 'EmployeeRestService_atualizeEmployee', 
        formatted: "O usuário {nome} atualizou novoss usuários"
    }, 
    { 
        method: 'EmployeeRestService_deleteEmployee', 
        formatted: "O usuário {nome} deletou novoss usuários"
    }, 
    { 
        method: 'EmployeeRestService_deleteCompanyEstablishmentJobTitle', 
        formatted: "o usuário {nome} deletou permissões de empresa"
    }, 
    { 
        method: 'EmployeeRestService_deleteEmployeeEstablishmentJobTitle', 
        formatted: "o usuário {nome} deletou permissões de estabelecimento"
    }, 
    { 
        method: 'EmployeeRestService_atualizeEmployeeAutoRegister', 
        formatted: "Foi gerado um auto registro para o usuário {nome}"
    }, 
    { 
        method: 'EmployeeRestService_activateEmployee', 
        formatted: "Foi ativado um auto registro para o usuário {nome}"
    },  
    { 
        method: 'EmployeeRestService_addEmployee', 
        formatted: "Foi gerado um novo registro para o usuário {nome}" 
    }, 
    { 
        method: 'EmployeeRestService_resetPassword', 
        formatted: "Foi requisitada uma modificação de senha"
    },
    { 
        method: 'ChannelManagerOverbookingRestService_addChannelManagerReservation', 
        formatted: "O usuário {nome} alterou reservas pendentes"
    }, 
    { 
        method: 'CategoryRestService_addCategories', 
        formatted: "O usuário {nome} adicionou categoria"
    },
    { 
        method: 'CategoryRestService_deleteCategorys', 
        formatted: "O usuário {nome} deletou categoria"
    }, 
    { 
        method: 'BuklyRestService_addBuklyConfig', 
        formatted: "O usuário {nome} adicionou configuração de usuário do Bukly"
    }, 
    { 
        method: 'BuklyRestService_addBuklyDataRoomConfiguration', 
        formatted: "O usuário {nome} adicionou configuração de quarto do Bukly"
    }, 
    { 
        method: 'BuklyRestService_deleteBanks', 
        formatted: "O usuário {nome} deletou configuração de quarto do Bukly"
    },
    { 
        method: 'UnitRestService_addUnits', 
        formatted: "O usuário {nome} adicionou configuração do uma unidade"
    }, 
    { 
        method: 'UnitRestService_editUnits', 
        formatted: "O usuário {nome} editou configuração do uma unidade"
    }, 
    { 
        method: 'UnitRestService_deleteUnits', 
        formatted: "O usuário {nome} deletou configuração do uma unidade"
    },
    { 
        method: 'TariffRestService_add', 
        formatted: "O usuário {nome} adicionou Tariffário"
    }, 
    { 
        method: 'TariffRestService_update', 
        formatted: "O usuário {nome} editou Tariffário"
    }, 
    { 
        method: 'SiroRestService_addReservation', 
        formatted: "Siro > Adicionar"
    }, 
    { 
        method: 'SiroRestService_addSiroConfig', 
        formatted: "Siro > Adicionar Configuração"
    }, 
    { 
        method: 'ResponsibleRestService_addDetail', 
        formatted: "O usuário {nome} adicionou Detalhes ao responsável"
    }, 
    { 
        method: 'ReservationRestService_add', 
        formatted: "O usuário {nome} adicionou uma reserva"
    }, 
    { 
        method: 'ReservationRestService_addReprocess', 
        formatted: "O usuário {nome} reprocessou uma reserva"
    }, 
    { 
        method: 'ReservationRestService_addBroken', 
        formatted: "O usuário {nome} adicionou uma reserva quebrada"
    }, 
    { 
        method: 'ReservationRestService_transfer', 
        formatted: "O usuário {nome} transferiu uma reserva"
    }, 
    { 
        method: 'ReservationRestService_transferPreReservation', 
        formatted: "O usuário {nome} transferiu uma pré-reserva"
    },
    { 
        method: 'ReservationRestService_editPreReservation', 
        formatted: "O usuário {nome} editou uma pré-reserva"
    },  
    { 
        method: 'ReservationRestService_addPre', 
        formatted: "O usuário {nome} adicionou uma pré-reserva"
    }, 
    { 
        method: 'ReservationRestService_updatePre', 
        formatted: "O usuário {nome} atualizou uma pré-reserva"
    }, 
    { 
        method: 'ReservationRestService_addResponsible', 
        formatted: "O usuário {nome} adicionou um novo responsável"
    }, 
    { 
        method: 'ReservationRestService_addObservation', 
        formatted: "O usuário {nome} adicionou uma observação na reserva"
    }, 
    { 
        method: 'ReservationRestService_updateOccupationMapParameters', 
        formatted: "O usuário {nome} editou uma configuração do mapa de ocupação"
    }, 
    { 
        method: 'ReservationRestService_addStaffComment', 
        formatted: "O usuário {nome} adicionou um comentário"
    }, 
    { 
        method: 'ReservationRestService_addGuest', 
        formatted: "O usuário {nome} adicionou um hóspede"
    }, 
    { 
        method: 'ReservationRestService_addDependent', 
        formatted: "O usuário {nome} adicionou um dependente"
    }, 
    { 
        method: 'ReservationRestService_addDependentQuantity', 
        formatted: "O usuário {nome} adicionou um número de dependentes"
    }, 
    { 
        method: 'ReservationRestService_deleteReservation', 
        formatted: "O usuário {nome} cancelou uma reserva"
    }, 
    {
        method: 'ReservationRestService_deleteReservationAdditionalFee',
        formatted: "O usuário {nome} deletou uma taxa extra"
    },
    {
        method: 'ReservationRestService_finalizeReservation',
        formatted: "O usuário {nome} finalizou a inserçõo de uma reserva"
    },
    {
        method: 'ReservationRestService_deleteGuest',
        formatted: "O usuário {nome} deletou um hóspede"
    },
    { 
        method: 'ReservationRestService_deletePreReservation', 
        formatted: "O usuário {nome} cancelou uma pré-reserva"
    }, 
    { 
        method: 'ReservationRestService_siroChannelReservationTokenUpdate', 
        formatted: "Reserva > Atualizar Siro Token"
    }, 
    { 
        method: 'PosRestService_addPoss', 
        formatted: "O usuário {nome} adicionou um Ponto de Vendas"
    }, 
    { 
        method: 'PosRestService_deletePoss', 
        formatted: "O usuário {nome} deletou um Ponto de Vendas"
    }, 
    { 
        method: 'PaymentRestService_add', 
        formatted: "O usuário {nome} adicionou um pagamento"
    }, 
    { 
        method: 'PaymentRestService_reversal', 
        formatted: "O usuário {nome} extornou um pagamento"
    }, 
    { 
        method: 'OAuthRestService_expireToken', 
        formatted: "Foi pedido um logout"
    }, 
    { 
        method: 'MainGuestRestService_addDetail', 
        formatted: "O usuário {nome} adicionou um hospede principal"
    },
    { 
        method: 'EmployeeRestService_resendActivationEmail', 
        formatted: "Empregado > Enviar E-mail de Ativação"
    }
];

