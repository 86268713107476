import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ResponseFormat } from '@src/app/types/response-format';

@Injectable()
export class ClientCardService
{

	constructor(private http: HttpClient) 
	{ 
	}

	get = (establishmentId, _params) => {
		let params = new HttpParams();
		Object.keys(_params).map((key, index) => {
			params = params.append(key, _params[key])
		})
		return this.http.get<ResponseFormat>(`vivakey/rest/client-wallet/by/establishment/${establishmentId}`, {
			params: params
		})
	}

	getExcelReport = (establishmentId, _params) => {
		let params = new HttpParams();
		Object.keys(_params).map((key, index) => {
			params = params.append(key, _params[key])
		})
		return this.http.get<ResponseFormat>(`vivakey/rest/client-wallet/report/by/establishment/${establishmentId}`, {
			params: params
		})
	}

	getReservations(clientId, establishmentId, personType) {
		return this.http.get<ResponseFormat>(`vivakey/rest/client-wallet/reservations/by/client/${clientId}/by/establishment/${establishmentId}/personType/${personType}`)
	}

	getMediaContent(id) {
		return this.http.get<ResponseFormat>(`vivakey/rest/mediacontent/${id}`);
	}

}
