import { Component, OnInit } from '@angular/core';
import { UsersService } from '../users/users.service';
import { ReportActivityService } from './report-activity.service';
import * as moment from 'moment';
import { activities } from './activities'; // data
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppStateService } from '@src/app/layouts/app-layout.service';

@Component({
    selector: 'app-report-activity',
    templateUrl: './report-activity.component.html',
    styleUrls: [
        '../report-occupation/report-occupation.component.css',
        '../report-financial/report-financial.component.css',
        '../report-reverse/report-reverse.component.css', 
        './report-activity.component.css'
        ]
})
export class ReportActivityComponent implements OnInit 
{
    usersList : any = [];
    selectEmployee : any;
    selectStartDate : any;
    selectFinishDate : any;
    logList : any = [];
    emptyDateWarn: boolean = false;

    constructor( 
        private appStateService: AppStateService,
        private usersService: UsersService, 
        private router: Router, 
        private reportActivityService: ReportActivityService,
        private translate: TranslateService,
    ) { }

    ngOnInit() 
    {
        var trad_obs = {};

        activities.forEach(act => {
            trad_obs[act.method] = act.formatted;
        });

        console.log("trad_obs:", JSON.stringify(trad_obs));

        this.usersService.getUsersFromCompany().subscribe(data => 
        {
            console.log("USERS", data);
            switch (data.code) 
            {
                case 200:
                    this.usersList = [
                    {name: this.translate.instant('all'), employeeId: 0},
                    ...data.object];
                    break;
            }
        }, error => console.log("Error", error));

        this.selectEmployee = JSON.parse(localStorage.getItem('currentUserInfo')).employeeId;
        this.selectStartDate = moment().subtract(1, 'month').format("YYYY-MM-DD"); 
        this.selectFinishDate = moment().format("YYYY-MM-DD");

        this.filterActivityReport();
    }

    filterActivityReport = () =>
    {
        console.log("sending", {
            "EmployeeId": this.selectEmployee,
            "StartDate": this.selectStartDate,
            "FinishDate": this.selectFinishDate
        });

        this.reportActivityService.getActivityReport(this.selectEmployee, this.selectStartDate, this.selectFinishDate).subscribe(data => 
        {
            console.log("LOG", data);
            switch (data.code) 
            {
                case 200:
                    for (let i = data.object.length - 1; i >= 0; i--) 
                    {    
                        data.object[i].logDateFormatted = moment(data.object[i].logDate).format("DD/MM/YYYY");
                        data.object[i].logTime = moment(data.object[i].logDate).format("HH:mm:ss");
                        data.object[i].logMethodFormatted = data.object[i].logDescription;
                        data.object[i].isPreReservation = data.object[i].logMethod.includes('PreReservation') || data.object[i].logMethod.includes('addPre')
                        if(data.object[i].isPreReservation) {
                            if(data.object[i].logMethod.includes('updatePreReservation')) {
                                data.object[i].isPreReservation = false
                            }
                        }
                    }
                    this.logList = data.object;
                break;
            }
        }, error => console.log("Error", error));
    }

    formatLog = (logMethod, name, logDescription) =>
    {     
        var text_translated = this.translate.instant(`log_act.${logMethod}`);
        if(text_translated && text_translated != `log_act.${logMethod}`) {
            return text_translated.replace("{nome}", name).replace("{name}", name); 
        }
       
        return (logDescription ? logDescription : logMethod ).replace("{nome}", name).replace("{name}", name);
    }

    goToReservationDetails = log =>
    {
        this.router.navigate([`app/${this.appStateService.currentEstablishmentId}/reservations/summary/${log.reservationId}`]);
    }
}
