import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse  } from '@angular/common/http';
import { ResponseFormat } from '@app/types/response-format'

@Injectable({
  providedIn: 'root'
})
export class CreateHotelService {

constructor(private http : HttpClient) { }

  createCompany = (body) => {
  	return this.http.post<ResponseFormat>(`vivakey/rest/company`, body);
  }

  createHotel = (body) => {
  	return this.http.post<ResponseFormat>(`vivakey/rest/establishment`, body);
  }


  getEmployees = () =>
	{
		return this.http.get<ResponseFormat>('vivakey/rest/employee/getEmployeesGeneral');
  }

  getCompanyes = () =>
	{
		return this.http.get<ResponseFormat>('vivakey/rest/company/getCompanies');
  }
  

}
