import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Rx';
import * as moment from 'moment';
import { ResponseFormat } from '@app/types/response-format';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';


@Injectable()
export class ReportClosingAptService {

  constructor(private http: HttpClient) { }

  appendParams(_params): HttpParams{
		let params = new HttpParams();
		Object.keys(_params).map((key, index) => {
			params = params.append(key, _params[key])
		})
		return params;
  }
  
  
  filterReport(establishmentId, startDate, endDate, _params) {

    return this.http.get<ResponseFormat>('vivakey/rest/reservation/billing/' + establishmentId + '/' + startDate + 'T00:00:00.000-0700/' + endDate + 'T00:00:00.000-0700', {
      params: this.appendParams(_params)
    })
  }

  getExcelReport = (establishmentId, startDate, endDate, _params) => {
    return this.http.get<ResponseFormat>('vivakey/rest/reservation/billing/' + establishmentId + '/' + startDate + 'T00:00:00.000-0700/' + endDate + 'T00:00:00.000-0700', {
      observe: 'response',
      params: this.appendParams(_params)
    })
	}


  getPos(estabId) {
    return this.http.get<ResponseFormat>('vivakey/rest/pos/getPoses/' + estabId)
  }

  getTaxes(posId) {
    return this.http.get<ResponseFormat>('vivakey/rest/additionalFee/getAdditionalFeees/' + posId)//+ "?status=1")
  }

}
