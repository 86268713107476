import { Injectable } from '@angular/core';
import {Observable} from 'rxjs/Rx';
import * as moment from 'moment';
import {ResponseFormat} from '@app/types/response-format';
import { HttpClient  } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { AppStateService } from '@src/app/layouts/app-layout.service';

interface DateType {
    dia_num: string;
    mes_num: string;
    ano_num: string;
    mes_txt: string;
    dia_txt: string;
    status: string;
    qtdAvailable: number;
}

@Injectable()
export class AvailabilityService
{
    constructor(private http : HttpClient, public translate: TranslateService, private appStateService: AppStateService) { }

    getReservations = (startDate, quantityDays, categoria, canal) =>
    {
	    let queryparam = '';
	    const establishment = this.appStateService.currentEstablishment;

        if (categoria != 0 && canal != 0)
	        queryparam = `?categoryId=${categoria}&channelId=${canal}`;
        else
        {
            if (categoria != 0)
                queryparam = `?categoryId=${categoria}`;
            if (canal != 0)
                queryparam = `?channelId=${canal}`;
        }

	  	return this.http.get<ResponseFormat>('vivakey/rest/reservation/getReservations/' + establishment.establishmentId +'/' + startDate + 'T00:00:00.000-0700/' + quantityDays + queryparam);
  	}


    generateDaysToShow = (qtdDias, startDay) =>
    {
        const auxDias: DateType[] = [];

        for (let z = 0; z < qtdDias; z++)
        {
            let today;

            if (startDay)
                today =  moment(startDay,"YYYY-MM-DD");
            else
                today =  moment();

            const future = today.add(z, 'days').locale(this.translate.currentLang.replace('_', '-')).format('DD-MMMM-MM-YYYY-dddd').split('-');

            const day: DateType = {
                dia_num: future[0],
                mes_num: future[2],
                ano_num: future[3],
                mes_txt: future[1].substring(0, 3),
                dia_txt: future[4].substring(0, 3),
                status: '-',
                qtdAvailable: 0
            };

            auxDias.push(day);
        }
        return auxDias;
    }

    getDayTariffary = (days, tariffs, counter, unityIsActive) =>
    {
        for (let j = tariffs.length - 1; j >= 0; j--)
        {
            const tariffDay = tariffs[j].date.split("T")[0].split("-");
            //percorre o array de dias a serem exibidos comparando com tarifario em questao
            for (let k = days.length - 1; k >= 0; k--)
            {
                //se existir um dia com tarifario, atribuimos o has tariff como true
                if (days[k].dia_num == tariffDay[2] && days[k].mes_num == tariffDay[1])
                {
                    if (unityIsActive) 
                    {
                        days[k].status = 1;
                        counter.daysToShow[k].qtdAvailable++;
                    }
                }
            }
        }
        return days;
    }

    getDaysReservations = (days, reservations, counter, unityIsActive) =>
    {
        for (let j = reservations.length - 1; j >= 0; j--)
        {
            const reservationDay = reservations[j].date.split("T")[0].split("-");

            for (let k = days.length - 1; k >= 0; k--)
            {
                //se existir um dia com tarifa
                if (days[k].dia_num == reservationDay[2] && days[k].mes_num == reservationDay[1])
                {
                    days[k].status = unityIsActive ? 0 : '-';
                    counter.daysToShow[k].qtdAvailable > 0 && unityIsActive && counter.daysToShow[k].qtdAvailable--;
                }
            }
        }
        return days;
    }

    calcLockedDays(unity, counter) {
        let days = unity.daysToShow
        console.log('unity', unity)

        let unitLockeds = []

        unity.unity.unitLockeds.forEach((unitLocked) => {
            if(unitLocked.status === "ACTIVE") {
                unitLocked.initDate = moment(unitLocked.initDateofBlock, 'YYYY-MM-DD')
                unitLocked.endDate = moment(unitLocked.endDateOfBlock, 'YYYY-MM-DD').subtract(1, 'days')
                unitLockeds.push(unitLocked)
            }
        });

        for (let i = 0; i < days.length; i++) {
            const day = days[i]
            const date = moment(`${day.ano_num}-${day.mes_num}-${day.dia_num}`, 'YYYY-MM-DD')
            let locked = unitLockeds.find((unitLocked) => {
                return date.isSameOrAfter(unitLocked.initDate) && date.isSameOrBefore(unitLocked.endDate)
            })

            if(locked) {
                if(days[i].status == 1) {
                    // remove one from count
                    counter.daysToShow[i].qtdAvailable > 0 && counter.daysToShow[i].qtdAvailable--;
                }
                days[i].status = '-'

            }

            
        }

        return days
    }
}
