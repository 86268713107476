import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { ResponseFormat } from '@app/types/response-format'

@Injectable({
  providedIn: 'root'
})
export class ConfigOmieService {

  constructor(private http: HttpClient) { }


  getCities = (uf, appKey, appSecretKey, page) => {
    return this.http.get<ResponseFormat>(`vivakey/rest/omie/cities?filtrarPorUf=${uf}&appKey=${appKey}&appSecretKey=${appSecretKey}&pagina=${page}&registrosPorPagina=50`);
  }


  getCategories = (appKey, appSecretKey, page) => {
    return this.http.get<ResponseFormat>(`vivakey/rest/omie/categ?appKey=${appKey}&appSecretKey=${appSecretKey}&pagina=${page}&registrosPorPagina=50`);
  }

  getServices = (appKey, appSecretKey, page) => {
    return this.http.get<ResponseFormat>(`vivakey/rest/omie/servmunic?appKey=${appKey}&appSecretKey=${appSecretKey}&pagina=${page}&registrosPorPagina=50`);
  }

  getTributes = (appKey, appSecretKey, page) => {
    return this.http.get<ResponseFormat>(`vivakey/rest/omie/tipotrib?appKey=${appKey}&appSecretKey=${appSecretKey}&pagina=${page}&registrosPorPagina=50`);
  }

  getAccounts = (appKey, appSecretKey, page, maxRegs) => {
    return this.http.get<ResponseFormat>(`vivakey/rest/omie/cc?appKey=${appKey}&appSecretKey=${appSecretKey}&pagina=${page}&registrosPorPagina=${maxRegs}`);
  }

  getBanks = (establishmentId) => {
    return this.http.get<ResponseFormat>(`vivakey/rest/bank/getBanks/${establishmentId}`);
  }

  saveBank(bank, establishmentId){
    bank.establishmentId = establishmentId;
		return this.http.post<ResponseFormat>('vivakey/rest/bank',bank);
  }
  

}
