import { Component, OnInit, NgModule } from "@angular/core"; // Angular
import { HotelSettingsPageService } from "./hotel-settings-page.service"; // Service
import masks from "../../utils/masks";
import { SelectionModel } from "@angular/cdk/collections";
import { MatTableDataSource } from "@angular/material/table";
import { NotifierService } from "angular-notifier";

import { example } from "./hotel-settings-page.types"; // Type

import moment from "moment-timezone";
import { CommonService } from '@src/app/common-service';
import { TranslateService } from '@ngx-translate/core';
import { MatDialogRef, MatDialog } from '@angular/material';
import { ConfigurePaymentGatewayModalComponent } from '@src/app/components/views/config-payment-gateway/config-payment-gateway-modal.component';
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe'
import { ConfigureOmieDataModalComponent } from '@src/app/components/views/config-omie-data/config-omie-data-modal.component';
import { ConfigureAirBnbModalComponent } from '@src/app/components/views/config-airbnb/config-airbnb-modal.component';
import Swal from 'sweetalert2';
import { error } from "protractor";
import { OtaType } from "@src/app/components/views/config-airbnb/config-AirBnb.service";
import { AppStateService } from "@src/app/layouts/app-layout.service";

declare var $: any;

const backendFieldsToModelFields = {
    establishmentType: "establishmentType",
    information: "hotelInfo",
    addrWebsite: "site",
    helpEmail: "email",
    phone: "phone",
    cellPhone: "celphone",
    magikeyToken: "magikeyToken",
    tokenAmplifiqueme: "token_amplifique",
    address: "address",
    addrNumber: "number",
    city: "city",
    state: "state",
    country: "country",
    district: "district",
    cep: "cep",
    timeZone: "timezone",
    checkin: "checkIn",
    checkout: "checkOut",
    checkinWeb: "checkinWeb",
    urlItunes: "urlItunes",
    urlPlayStore: "urlPlayStore",
    urlWeb: "urlWeb",
    establishmentMediaIds: "establishmentMediaIds",
    chaveAcessoEmbratur: "chaveAcessoEmbratur",
    lastMinuteHour: "lastMinuteHour",
    sellerId: "sellerId",
    linkMotorReservaEstablishment: "linkMotorReservaEstablishment",
    minimalPrice: "minimalPrice"
};

const parseModelToBody = model => {
    var body:any = {};
    Object.keys(backendFieldsToModelFields).map((backendField, index) => {
        const modelField = backendFieldsToModelFields[backendField];
        body[backendField] = model[modelField];
    });

    console.log('body', body);
    body.phone = model['phone'].replace(/\s/g, "");
    // body.celphone = body.celphone.replace(/\s/g, "");

    console.log('body', body);

    return body;
};

const setModelFromObject = (model, object) => {
    Object.keys(backendFieldsToModelFields).map((backendField, index) => {
        const modelField = backendFieldsToModelFields[backendField];

        if (modelField === "checkinWeb") {
            model[modelField] = object[backendField] === true;
        } else {
            model[modelField] = object[backendField];
        }
    });

    return model;
};

@Component({
    selector: "app-hotel-settings-page",
    templateUrl: "./hotel-settings-page.component.html",
    styleUrls: [
        "../../app.component.css",
        "./hotel-settings-page.component.css",
        "../dashboard/dashboard.component.css"
    ]
})
export class HotelSettingsPageComponent implements OnInit {
    data: any = null;
    loading: boolean = true;

    percentualConfig = { prefix: '', suffix:'%', thousands: '.', decimal: ',', align : 'left', allowNegative : 'false' };

    // models
    currentLogo: string;
    currentEstPhoto: string;
    currentLogoId: number;
    currentEstPhotoId: number;

    banksArray: any[] = [];
    bankActivatedGateway: any;
    bankActivatedAirBnb: any;
    bankActivatedBooking: any;

    timezone: any = 0;
    checkIn: string = "00:00";
    checkOut: string = "00:00";
    checkinWeb: boolean = false;
    hasPaymentGateway: boolean = false;
    hasBuklyBookingAutomaticPayment: boolean = false;
    hasInvoiceOmie: boolean = false;
    hasOwnerInvoice: boolean = false;
    hasAutomaticPrice: boolean = false;
    hasAirbnbAutomaticPayment: boolean = false;
    hasBookingAutomaticPayment: boolean = false;
    hasSplit: boolean = false;
    sellerIdSplit: string = '';
    comissionSplit: number;
    minimalPrice: number;


    airbnbOtaTypeValue: OtaType =OtaType.Airbnb;
    bookingOtaTypeValue: OtaType =OtaType.Booking;

    omieConfig: any;
    hotelInfo: string = "";
    phone: string = "";
    celphone: string = "";
    magikeyToken: string = "";
    establishmentType: string = '';
    email: string = "";
    site: string = "";
    address: string = "";
    number: string = "";
    city: string = "";
    state: string = "";
    district: string = "";
    country: string = "";
    cep: string = "";
    urlItunes: string = "";
    urlPlayStore: string = "";
    urlWeb: string = "";
    establishmentMediaIds: any[] = [];
    chaveAcessoEmbratur: string = "";
    lastMinuteHour: any;
    lastMinuteHourMask = [/[0-2]/, /[0-9]/];
    lastMinuteAutoCorrectedDatePipe = createAutoCorrectedDatePipe("HH");

    mediaContentList: any[] = [];
    mediaContentListPhotos: any[] = [];
    addImageModel: any = {};

    timezones: any;
    fetchedServices: boolean = false

    initialModels: any;

    // channel
    loadingChannel: boolean = true;
    channelList: any = null;
    addChannel: boolean = false;
    editChannel: any = null;
    tariffName: any = "";
    tariffDescription: any = "";
    //selectedChannel_pagcertoSplitters: any[] = [];

    token_amplifique: string;
    hasVivaApp: boolean;
    sellerId: string;
    linkMotorReservaEstablishment: string;
    tokenAutomaticCheckin: string;

    defaultsUrls = {
        urlItunes:
            "https://apps.apple.com/us/app/vivakey/id1461678729?l=pt&ls=1",
        urlPlayStore:
            "https://play.google.com/store/apps/details?id=vivakey.vivakey.com.vivakey&hl=pt_BR",
        urlWeb:
            "https://pms.vivakey.com.br/checkin/vivapp/access"
    };

    public phoneMask = [ "(",/\d/,/\d/,")"," ",/\d/,/\d/,/\d/,/\d/,"-",/\d/,/\d/,/\d/,/\d/];
    public celphoneMask = masks.phone;
    selectedCompany: any;
    selectedEstablishment: any;

    // Select of services
    loadingServices: boolean = true;
    displayedColumns: string[] = ["select", "image", "name", "description"];
    dataSource = new MatTableDataSource<any>([]);
    initialSelection = new SelectionModel<any>(true, []);
    selection = new SelectionModel<any>(true, []);
    serviceCheckboxHasChanged: boolean = false;

    configurePaymentGatewayDialogRef: MatDialogRef<ConfigurePaymentGatewayModalComponent>;
    configureOmieDataModalComponentDialogRef: MatDialogRef<ConfigureOmieDataModalComponent>;
    configureAirBnbModalComponentDialogRef: MatDialogRef<ConfigureAirBnbModalComponent>;

    constructor(private hotelSettingsService: HotelSettingsPageService,
        public appStateService: AppStateService,
        private commonService: CommonService,
        private notifier: NotifierService,
        private translate: TranslateService,
        public dialog: MatDialog) { }

    ngOnInit() {
        this.timezones = moment.tz.names();

        this.selectedCompany = this.appStateService.currentCompany
        this.selectedEstablishment = this.appStateService.currentEstablishment;

        this.hotelSettingsService.getSettings(this.selectedEstablishment.establishmentId).subscribe(data => { });

        this.getSettings();

    }
    
    copyClipboard(inputElement){
        inputElement.select();
        document.execCommand('copy');
        //inputElement.setSelectionRange(0, 0);
        Swal.fire(this.translate.instant('hotel.token_automatic_copied'), '', 'success');
    }

    dataLoaded: boolean = false;

    getSettings() {
        this.hotelSettingsService
            .getEstablishments(this.selectedCompany.companyId)
            .subscribe(
                data => {
                    switch (data.code) {
                        case 200:
                            if (data.object) {

                                var establishment = data.object.find(i => i.establishmentId == this.selectedEstablishment.establishmentId)

                                this.mediaContentList = establishment.mediaContent;
                                this.hasPaymentGateway = establishment.establishmentConfig.hasPaymentGateway;
                                this.hasBuklyBookingAutomaticPayment = establishment.establishmentConfig.hasBuklyBookingAutomaticPayment;
                                this.hasOwnerInvoice = establishment.establishmentConfig.hasOwnerInvoice;
                                this.hasAutomaticPrice = establishment.establishmentConfig.hasAutomaticPrice;
                                this.omieConfig = establishment.establishmentConfig.omieConfig;
                                this.hasInvoiceOmie = this.omieConfig.hasInvoiceOmie;
                                this.hasAirbnbAutomaticPayment = establishment.establishmentConfig.hasAirbnbAutomaticPayment;
                                this.hasBookingAutomaticPayment = establishment.establishmentConfig.hasBookingAutomaticPayment;
                                this.hasSplit = establishment.establishmentConfig.hasSplit;
                                this.sellerIdSplit = establishment.establishmentConfig.sellerIdSplit;
                                this.comissionSplit = establishment.establishmentConfig.comissionSplit;

                                this.tokenAutomaticCheckin = establishment.tokenAutomaticCheckin;
                                                  

                                setModelFromObject(this, establishment.establishmentConfig);
                                this.initialModels = {};
                                this.initialModels = setModelFromObject(
                                    this.initialModels,
                                    establishment.establishmentConfig
                                );
                                this.refreshHasVivaApp();

                                this.magikeyToken = establishment.magikeyToken;
                                this.token_amplifique = establishment.tokenAmplifiqueme;   
                                this.establishmentType = establishment.establishmentType;
                                this.chaveAcessoEmbratur = establishment.chaveAcessoEmbratur;
                                this.lastMinuteHour = establishment.lastMinuteHour;

                                if (this.establishmentMediaIds == null) {
                                    this.establishmentMediaIds = [];
                                }

                                this.getLogo();
                                this.getBanks();

                                this.dataLoaded = true;

                            } else {
                                this.initialModels = {};
                                const body = parseModelToBody(this);
                                this.initialModels = setModelFromObject(
                                    this.initialModels,
                                    body
                                );

                                this.dataLoaded = true;
                            }
                            break;
                        default:
                        // TODO:
                        // Handle errors
                    }
                },
                error => {
                    // TODO:
                    // Handle errors
                    console.log("erro");
                },
                () => {
                    this.loading = false;
                }
            );
    }

    getBanks() {
        this.hotelSettingsService
            .getBanks(this.selectedEstablishment.establishmentId)
            .subscribe(
                data => {
                    switch (data.code) {
                        case 200:
                            if (data.object) {
                                this.banksArray = data.object;

                                console.log('this.banksArray', this.banksArray);

                                this.bankActivatedGateway = this.banksArray.find(i=>i.isPaymentGateway === true);
                                this.bankActivatedAirBnb = this.banksArray.find(i=>i.isAirbnbAutomaticPayment === true);
                                this.bankActivatedBooking = this.banksArray.find(i=>i.isBookingAutomaticPayment === true);
                            }
                            break;
                        default:
                    }
                },
                error => {
                    console.log("erro");
                },
                () => {
                    this.loading = false;
                }
            );
    }

    getLogo() {

        this.mediaContentList.sort((a, b) => (<number>a.mediaContentId > <number>b.mediaContentId) ? 1 : -1).forEach(element => {

            if (element.active == true) {
                this.commonService.getImage(element.mediaContentId).subscribe(
                    (data) => {
                        console.log('logoData:', data);
                        this.mediaContentListPhotos.push(data);

                        if (data.type == "ESTABLISHMENT_LOGO") {
                            this.currentLogo = data.keyName;
                            this.currentLogoId = data.id;
                        }

                        if (data.type == "ESTABLISHMENT_PHOTO") {
                            this.currentEstPhoto = data.keyName
                            this.currentEstPhotoId = data.id;
                        }
                    },
                    (error) => {
                        console.log('erro ao carregar logo')
                    },
                    () => {
                        this.loading = false;
                    });
            }

        });


    }



    onChangeLogo(event) {
        console.log(event);
        if (event.image) {
            this.commonService.saveImage({ data: event.image, contentType: "ESTABLISHMENT_LOGO" }, "establishmentLogo").subscribe(
                (data) => {
                    console.log(data.object);
                    this.currentLogoId = data.object;
                    this.establishmentMediaIds.push(data.object);
                },
                (error) => {
                    console.log('erro ao carregar logo')
                },
                () => {
                    this.loading = false;
                });
        }
    }

    onChangeEstPhoto(event) {
        console.log(event);
        if (event.image) {
            this.commonService.saveImage({ data: event.image, contentType: "ESTABLISHMENT_PHOTO" }, "establishmentPhoto").subscribe(
                (data) => {
                    console.log(data.object);
                    this.currentEstPhotoId = data.object;
                    this.establishmentMediaIds.push(data.object);
                },
                (error) => {
                    console.log('erro ao carregar logo')
                },
                () => {
                    this.loading = false;
                });

        }
    }



    // getServides
    getServices() {
        if (this.fetchedServices) {
            return
        }
        this.loadingServices = true;
        this.hotelSettingsService.getServices().subscribe(
            data => {
                switch (data.code) {
                    case 200:
                        this.dataSource = new MatTableDataSource<any>(
                            data.object
                        );

                        this.hotelSettingsService
                            .getProvidedServices(
                                this.selectedEstablishment.establishmentId
                            )
                            .subscribe(
                                data => {
                                    if (
                                        data.code === 200 &&
                                        data.object &&
                                        data.object.length > 0
                                    ) {
                                        this.dataSource.data.forEach(row => {
                                            let index = data.object.findIndex(
                                                _ => _.id == row.id
                                            );
                                            if (index > -1) {
                                                this.selection.select(row);
                                            }
                                        });
                                    }
                                    this.loadingServices = false;
                                    this.fetchedServices = true
                                },
                                error => {
                                    this.loadingServices = false;
                                }
                            );

                        break;
                    default:
                    // TODO:
                    // Handle errors
                }
            },
            error => {
                // TODO:
                // Handle errors
                this.loadingServices = false;

                console.log("error");
            },
            () => { }
        );
    }


    getChannels() {
        this.loadingChannel = true;
        this.hotelSettingsService
            .getChannels(this.selectedEstablishment.establishmentId)
            .subscribe(
                data => {
                    switch (data.code) {
                        case 200:
                            this.channelList = data.object;
                            this.loadingChannel = false;

                            break;
                        default:
                        // TODO:
                        // Handle errors
                    }
                },
                error => {
                    // TODO:
                    // Handle errors
                    this.loadingChannel = false;
                    console.log("error");
                },
                () => { }
            );
    }

    hasSomeChange() {
        if (this.initialModels) {
            let has = false;
            Object.keys(this.initialModels).forEach((key, index) => {
                const value = this.initialModels[key];

                if (value !== this[key]) {
                    has = true;
                    return true;
                }
            });
            return has;
        } else {
            return true;
        }
    }

    refreshTokenAutomaticCheckin() {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn'
            },
            buttonsStyling: false
        })

        swalWithBootstrapButtons.fire({
            position: 'center',
            title: this.translate.instant('hotel.confirm_refresh_token'),
            text: this.translate.instant('occupation.confirm_checkin_checkout_info'),
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: this.translate.instant('yes'),
            cancelButtonText: this.translate.instant('no'),
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                this.loading = true;
                this.hotelSettingsService.refreshTokenAutomaticCheckin(this.selectedEstablishment.establishmentId).subscribe(ret => {
                    this.loading = false;
                    if(ret.code == 200){
                        this.getSettings();
                    }
                }, error => {
                    this.loading = false;
                });
            } else if (result.dismiss === Swal.DismissReason.cancel) {

            }
        });

    }

    save() {



        this.establishmentMediaIds = [];
        if (this.currentLogoId) {
            this.establishmentMediaIds.push(this.currentLogoId);
        }

        //Comment in future, for manage list of images.
        if (this.currentEstPhotoId) {
            this.establishmentMediaIds.push(this.currentEstPhotoId);
        }

        console.log("establishmentMediaIds", this.establishmentMediaIds);


        this.loading = true;
        this.lastMinuteHour = this.lastMinuteHour.split(':')[0] + ':00';

        const body = parseModelToBody(this);
        
        this.hotelSettingsService
            .postSettings(this.selectedEstablishment.establishmentId, body)
            .subscribe(
                data => {

                    if(data.code == 200 || data.code == 201){
                        this.initialModels = setModelFromObject(
                            this.initialModels,
                            body
                        );

                        this.selectedEstablishment.establishmentConfig.timeZone = this.timezone;
                        localStorage.setItem(
                            "selectedEstablishment",
                            JSON.stringify(this.selectedEstablishment)
                        );

                        this.loading = false;
                    }

                },
                error => {
                    // TODO:
                    // Handle errors
                    this.loading = false;
                    console.log("error");
                }
            );
    }

    onChangeCheckin(val) {
        this.checkIn = val;
    }

    onChangeCheckout(val) {
        this.checkOut = val;
    }

    onChangeLastMinuteHour(val) {
        this.lastMinuteHour = val;
    }
    
    toggleHasVivaApp() {
        if (this.areDefaultLinks()) {
            this.urlItunes = "";
            this.urlPlayStore = "";
            this.urlWeb = "";
        } else {
            this.urlItunes = `${this.defaultsUrls.urlItunes}`;
            this.urlPlayStore = `${this.defaultsUrls.urlPlayStore}`;
            this.urlWeb = `${this.defaultsUrls.urlWeb}`;
        }
    }

    areDefaultLinks() {
        return (
            this.defaultsUrls.urlItunes == this.urlItunes &&
            this.defaultsUrls.urlPlayStore == this.urlPlayStore &&
            this.defaultsUrls.urlWeb == this.urlWeb
        );
    }

    refreshHasVivaApp() {
        if (this.areDefaultLinks()) {
            this.hasVivaApp = true;
        } else {
            this.hasVivaApp = false;
        }
    }

    editSelectedChannel(channel){
        this.editChannel = channel;
    }
    // Channel
    saveChannel() {
        this.loadingChannel = true;

        // var pagcertoSplitters = [].map.call(this.editChannel.pagcertoSplitters, function(obj) {
        //     return { 
        //         pagcertoSplitterId: obj.pagcertoSplitterId,
        //         splitterExternalId: obj.splitterExternalId,
        //         salesCommission: obj.salesCommission,
        //     };
        // });


        const body = {
            channelId: 0,
            name: this.tariffName,
            description: this.tariffDescription,
            url: "",
            siroName: "",
            buklyChannelCode: "",
            //pagcertoSplitters: pagcertoSplitters
        };

        this.hotelSettingsService
            .saveChannel(this.selectedEstablishment.establishmentId, body)
            .subscribe(
                data => {
                    this.loadingChannel = false;
                    if (data.code === 200) {
                        this.getChannels();
                        this.addChannel = false;
                        this.tariffName = '';
                        this.tariffDescription = '';
                    }
                },
                error => {
                    // TODO:
                    // Handle errors
                    this.loadingChannel = false;
                }
            );
    }
    updateChannel() {
        if (!this.editChannel) {
            return;
        }

        if (this.editChannel.name == '' || this.editChannel.name == null
        || this.editChannel.description == '' || this.editChannel.description == null) {
            return;
        }

        this.loadingChannel = true;

        // var pagcertoSplitters = [].map.call(this.editChannel.pagcertoSplitters, function(obj) {
        //     return { 
        //         pagcertoSplitterId: obj.pagcertoSplitterId,
        //         splitterExternalId: obj.splitterExternalId,
        //         salesCommission: obj.salesCommission,
        //     };
        // });

          
        const body = {
            channelId: this.editChannel.channelId,
            name: this.editChannel.name,
            description: this.editChannel.description,
            url: this.editChannel.url,
            siroName: this.editChannel.siroName,
            buklyChannelCode: this.editChannel.buklyChannelCode,
            //pagcertoSplitters: pagcertoSplitters
        };

        this.hotelSettingsService
            .updateChannel(this.editChannel.channelId, body)
            .subscribe(
                data => {
                    this.loadingChannel = false;
                    if (data.code === 200) {

                        const index = this.channelList.findIndex(
                            _ => _.channelId == this.editChannel.channelId
                        );
                        if (index > -1) {
                            this.channelList[index] = this.editChannel;
                        }
                        this.editChannel = null;
                    }
                },
                error => {
                    // TODO:
                    // Handle errors
                    this.loadingChannel = false;
                }
            );
    }
    deleteChannel(channel) {
        if (confirm(this.translate.instant('channels.confirm_delete'))) {
            this.hotelSettingsService
                .deleteChannel(channel.channelId)
                .subscribe(
                    data => {
                        if (data.code === 200) {
                            const index = this.channelList.findIndex(
                                _ => _.channelId == channel.channelId
                            );
                            if (index > -1) {
                                this.channelList.splice(index, 1);
                            }
                        }
                    },
                    error => {
                        // TODO:
                        // Handle errors
                    }
                );
        }
    }

    // addSplitter(){

    //     var pendingAdd = false;
    //     this.selectedChannel_pagcertoSplitters.forEach(element => {
    //         if(element.addEditMode == true){
    //             pendingAdd = true;
    //         }
    //     });

    //     if(pendingAdd){
    //         return;
    //     }

    //     this.selectedChannel_pagcertoSplitters.push({
    //         addEditMode: true,
    //         splitterExternalId: '',
    //         salesCommission: 0
    //     });
    // }

    // deleteSplitter(item) {
    //     const index = this.selectedChannel_pagcertoSplitters.findIndex(i => i == item);
    //     if (index > -1) {
    //         this.selectedChannel_pagcertoSplitters.splice(index, 1);
    //     }
    // }

    saveSplitter(item) {
        if(item.splitterExternalId && item.salesCommission) {
            item.addEditMode = false;
        }
    }

    cancel() {
        Object.keys(this.initialModels).map((objectKey, index) => {
            let value = this.initialModels[objectKey];
            this[objectKey] = value;
        });
        this.refreshHasVivaApp();
    }

    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected === numRows;
    }
    masterToggle() {
        this.isAllSelected()
            ? this.selection.clear()
            : this.dataSource.data.forEach(row => this.selection.select(row));
    }

    toggledRow(row) {
        this.serviceCheckboxHasChanged = true;
        this.selection.toggle(row);
    }

    saveService() {
        const body = {
            servicesProvided: this.selection.selected
        };

        this.loadingServices = true
        this.hotelSettingsService
            .putServices(this.selectedEstablishment.establishmentId, body)
            .subscribe(data => {
                this.loadingServices = false
                this.notifier.notify('success', this.translate.instant('channels.success_saved'))
            }, error => {
                this.loadingServices = false
                this.notifier.notify('error', this.translate.instant('channels.error_save'))
            });
    }

    /** The label for the checkbox on the passed row */
    checkboxLabel(row?: any): string {
        if (!row) {
            return `${this.isAllSelected() ? "select" : "deselect"} all`;
        }
        return `${
            this.selection.isSelected(row) ? "deselect" : "select"
            } row ${row.position + 1}`;
    }

          
    openConfigGateway(event){
        if(this.hasPaymentGateway == true){
            this.configurePaymentGatewayDialogRef = this.dialog.open(ConfigurePaymentGatewayModalComponent, {
                //panelClass: 'mat-dialog-large',
                disableClose: false,
                data : { sellerId: this.sellerId }
            });
    
            //this.configurePaymentGatewayDialogRef.componentInstance.title = 'Excluír?';
    
            this.configurePaymentGatewayDialogRef.afterClosed().subscribe(result => {
                console.log('result', result);
                this.sellerId = result.sellerId;
                this.save();
                //this.getSettings();
                this.configurePaymentGatewayDialogRef = null;
            });
        }
        else {
            this.deactivateGateway();
        }

    }

    toogle_BuklyBookingAutomaticPayment(event) {
        this.loading = true
        this.hotelSettingsService.setBuklybookingautopayment(this.selectedEstablishment.establishmentId, this.hasBuklyBookingAutomaticPayment).subscribe((data) => {

            this.loading = false

            if(this.hasBuklyBookingAutomaticPayment)
                this.notifier.notify('success', this.translate.instant('successfully_activated'));
            else
                this.notifier.notify('success', this.translate.instant('successfully_deactivated'));

        }, error => {
           
            if(this.hasBuklyBookingAutomaticPayment)
                this.notifier.notify('error', this.translate.instant('failed_activate'));
            else
                this.notifier.notify('error', this.translate.instant('failed_deactivate'));


            this.loading = false
            this.hasBuklyBookingAutomaticPayment = !this.hasBuklyBookingAutomaticPayment;
        });
    }

    openConfigAirBnb(event, otaType: OtaType){

        var statusFlag: boolean = null;

        switch (otaType) {
            case OtaType.Airbnb:
                statusFlag = this.hasAirbnbAutomaticPayment;
                break;
            
            case OtaType.Booking:
                statusFlag = this.hasBookingAutomaticPayment;
                break;
        
            default:
                break;
        }

        if(statusFlag == true){
            this.configureAirBnbModalComponentDialogRef = this.dialog.open(ConfigureAirBnbModalComponent, {
                //panelClass: 'mat-dialog-large',
                disableClose: false,
                data : { }
            });
    
            this.configureAirBnbModalComponentDialogRef.componentInstance.otaType = otaType;
    
            this.configureAirBnbModalComponentDialogRef.afterClosed().subscribe(result => {
                this.getSettings();
                this.configureAirBnbModalComponentDialogRef = null;
            });
        }
        else {
            this.deactivateAirbnbAutomaticPayment(otaType);
        }

    }


    openConfigOmie(event){
        if(this.hasInvoiceOmie == true){
            this.configureOmieDataModalComponentDialogRef = this.dialog.open(ConfigureOmieDataModalComponent, {
                //panelClass: 'mat-dialog-large',
                disableClose: false,
                data : this.omieConfig
            });

    
            //this.configureOmieDataModalComponentDialogRef.componentInstance.title = 'Excluír?';
    
            this.configureOmieDataModalComponentDialogRef.afterClosed().subscribe(result => {
                
                if(this.configureOmieDataModalComponentDialogRef.componentInstance.validated === true){
                    var retModel = this.configureOmieDataModalComponentDialogRef.componentInstance.model;
                    console.log('dataRes:', retModel);
                    this.omieConfig = retModel;
                    this.hasInvoiceOmie = true;
                    this.omieConfig.hasInvoiceOmie = true;
                    this.saveOmie(true);
                }
                else{
                    this.hasInvoiceOmie = false;
                    this.omieConfig.hasInvoiceOmie = false;
                }
                
                //this.getSettings();
                this.configureOmieDataModalComponentDialogRef = null;
            });
        }
        else {
            this.hasInvoiceOmie = false;
            this.omieConfig.hasInvoiceOmie = false;
            this.saveOmie(false);
        }

    }

    setOwnerInvoice() {
        this.hotelSettingsService.setOwnerInvoice(this.selectedEstablishment.establishmentId, this.hasOwnerInvoice).subscribe((data) => {
            this.loading = false

            if(this.hasOwnerInvoice == true) {
                this.notifier.notify('success', this.translate.instant('hotel.hasOwnerInvoice_activated'));
            } else {
                this.notifier.notify('info', this.translate.instant('hotel.hasOwnerInvoice_deactivated'));
            } 
            
        }, error => {
            this.loading = false
            this.hasOwnerInvoice = !this.hasOwnerInvoice;
            this.notifier.notify('error', this.translate.instant('hotel.hasOwnerInvoice_error'))
        });
    }

    setAutomaticPrice() {
        this.hotelSettingsService.setAutomaticPrice(this.selectedEstablishment.establishmentId, this.hasAutomaticPrice).subscribe((data) => {
            this.loading = false

            if(this.hasAutomaticPrice == true) {
                this.notifier.notify('success', this.translate.instant('hotel.hasAutomaticPrice_activated'));
            } else {
                this.notifier.notify('info', this.translate.instant('hotel.hasAutomaticPrice_deactivated'));
            } 
            
        }, error => {
            this.loading = false
            this.hasAutomaticPrice = !this.hasAutomaticPrice;
            this.notifier.notify('error', this.translate.instant('hotel.hasAutomaticPrice_error'))
        });
    }

    saveOmie(activate: boolean) {
        this.hotelSettingsService.saveOmieConfig02(this.selectedEstablishment.establishmentId, this.omieConfig, activate).subscribe((data) => {
            this.loading = false

            if(activate == true) {
                this.notifier.notify('success', this.translate.instant('config_omie.activated'));
            } else {
                this.notifier.notify('info', this.translate.instant('config_omie.deactivated'));
            } 
            
        }, error => {
            this.loading = false
            this.hasInvoiceOmie = !activate;
            this.notifier.notify('error', this.translate.instant('config_omie.error_save'))
        });
    }


    deactivateAirbnbAutomaticPayment(otaType: OtaType) {

        this.loading = true
        this.hotelSettingsService.deactivateAirbnbAutomaticPayment(this.selectedEstablishment.establishmentId, otaType).subscribe((data) => {
            this.notifier.notify('info', this.translate.instant('airbnb.deactivated_gateway'));
            this.loading = false

            switch (otaType) {
                case OtaType.Airbnb:
                    this.hasAirbnbAutomaticPayment = false;
                    break;

                case OtaType.Booking:
                    this.hasBookingAutomaticPayment = false;
                    break;
            
                default:
                    break;
            }

            this.getBanks();
        }, error => {
            this.loading = false
            this.notifier.notify('error', this.translate.instant('airbnb.error_deactivate_gateway'))
        });
    }


    deactivateGateway() {
        this.loading = true
        this.hotelSettingsService.deactivateGateway(this.selectedEstablishment.establishmentId).subscribe((data) => {
            this.notifier.notify('info', this.translate.instant('payment-gateway.deactivated_gateway'));
            this.loading = false
            this.hasPaymentGateway = false;
            this.hasBuklyBookingAutomaticPayment = false;
            this.toogle_BuklyBookingAutomaticPayment(null);
            this.getBanks();
        }, error => {
            //this.hasPaymentGateway = true;
            this.loading = false
            this.notifier.notify('error', this.translate.instant('payment-gateway.error_deactivate_gateway'))
        });
    }
}
