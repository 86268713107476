import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { ResponseFormat } from '@app/types/response-format';
import { NotifierService } from 'angular-notifier';
import { TranslateService } from '@ngx-translate/core';
import { AppStateService } from '@src/app/layouts/app-layout.service';

@Injectable()
export class OtasService {

    public establishment: any;

    public data: any[] = [];
    public dataBuildings: any[] = [];

    public buildings: any[] = [];

    public otaContract: any[] = [];
    public otaRestrictsContract: any[] = [];

    constructor(private http: HttpClient,
        private notifier: NotifierService,
        private translate: TranslateService,
        private appStateService: AppStateService) {
        this.establishment = this.appStateService.currentEstablishment;
    }


    loadDependencesData(): Promise<any> {

        return new Promise((resolve, reject) => {
            this.loadEstablishment_OtaContracts().then(response_ctos => {

                this.loadEstablishment_OtaRestrictsContract().then(response_rest_ctos => {

                    this.loadBuildings().then(respBuild => {
                        resolve();
                    }, error => {
                        reject();
                    });


                }, error => {
                    reject();
                });

            }, error => {
                reject();
            })
        });

    }

    loadBuildings(): Promise<any> {

        return new Promise((resolve, reject) => {
            this.getBuildings(this.establishment.establishmentId).subscribe(resp => {
                switch (resp.code) {
                    case 200:
                        {
                            this.buildings = resp.object;
                            resolve(resp.object);
                            break;
                        }
                    default:
                        reject();
                }
            }, err => {
                this.notifier.notify('error', this.translate.instant('error_msg.error_getdata'))
                reject();
            })
        });
    }


    loadEstablishment_OtaContracts(): Promise<any> {

        return new Promise((resolve, reject) => {
            this.getEstablishment_OtaContracts().subscribe(resp => {
                switch (resp.code) {
                    case 200:
                        {
                            this.otaContract = resp.object;
                            resolve(resp.object);
                            break;
                        }
                    default:
                        reject();
                }
            }, err => {
                this.notifier.notify('error', this.translate.instant('error_msg.error_getdata'))
                reject();
            })
        });
    }

    loadEstablishment_OtaRestrictsContract(): Promise<any> {

        return new Promise((resolve, reject) => {
            this.getEstablishment_OtaRestrictsContract().subscribe(resp => {
                switch (resp.code) {
                    case 200:
                        {
                            this.otaRestrictsContract = resp.object;
                            resolve(resp.object);
                            break;
                        }
                    default:
                        reject();
                }
            }, err => {
                this.notifier.notify('error', this.translate.instant('error_msg.error_getdata'))
                reject();
            })
        });
    }

    loadOtasFees(): Promise<any> {

        return new Promise((resolve, reject) => {
            this.getEstablishment_Otas(this.establishment.establishmentId).subscribe(resp => {
                switch (resp.code) {
                    case 200:
                        {
                            this.data = resp.object;
                            resolve(resp.object);
                            break;
                        }
                    default:
                        reject();
                }
            }, err => {
                this.notifier.notify('error', this.translate.instant('error_msg.error_getdata'))
                reject();
            })
        });
    }


    loadBuildingFees(buildingId): Promise<any> {

        return new Promise((resolve, reject) => {
            this.getBuildingsConfig_Otas(buildingId).subscribe(resp => {
                switch (resp.code) {
                    case 200:
                        {
                            this.dataBuildings = resp.object;
                            resolve(resp.object);
                            break;
                        }
                    default:
                        reject();
                }
            }, err => {
                this.notifier.notify('error', this.translate.instant('error_msg.error_getdata'))
                reject();
            })
        });
    }


    getBuildings = (establishmentId) => this.http.get<ResponseFormat>(`vivakey/rest/building/getBuildings?establishmentId=${establishmentId}`);

    getEstablishment_Otas = (establishmentId) => this.http.get<ResponseFormat>(`vivakey/rest/otaestablishmentconfig/getOtaEstablishmentConfig?establishmentId=${establishmentId}`)
    getEstablishment_OtaContracts = () => this.http.get<ResponseFormat>(`vivakey/rest/otaestablishmentconfig/getOtaContract`)
    getEstablishment_OtaRestrictsContract = () => this.http.get<ResponseFormat>(`vivakey/rest/otaestablishmentconfig/getOtaRestrictsContract`)

    getBuildingsConfig_Otas = (buildingId) => this.http.get<ResponseFormat>(`vivakey/rest/otabuildingconfig/getOtaBuildingConfig?buildingId=${buildingId}`)


    updatetEstablishment_Otas(id, item) {
        return this.http.put<ResponseFormat>(`vivakey/rest/otaestablishmentconfig/editOtaEstablishmentConfig?otaEstablishmentConfigId=${id}`, item
        );
    }

    updatetBuildingsConfig_Otas(id, item) {
        return this.http.put<ResponseFormat>(`vivakey/rest/otabuildingconfig/editOtaBuildingConfig?otaBuildingConfigId=${id}`, item
        );
    }

}
