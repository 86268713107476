import { Component, OnInit, ViewChild } from "@angular/core"
import { NotifierService } from 'angular-notifier';
import { OccupationMapService } from './occupation-map.service'
import moment from 'moment-timezone';
import { MatDialogRef, MatDialog, MatTabGroup } from '@angular/material';
import { ReportProblemModalComponent } from '@src/app/components/views/report-problem-modal/report-problem-modal.component';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { HotelSettingsPageService } from "../hotel-settings/hotel-settings-page.service";
import { AppStateService } from "@src/app/layouts/app-layout.service";

declare var $: any;

@Component({
	selector: "app-occupation-map-page",
	templateUrl: "./occupation-map-page.component.html",
	styleUrls: [
		"./occupation-map-page.component.css"
	]
})
export class OccupationMapPageComponent implements OnInit {

	loading: boolean = false;
	data: any;

	selectedEstablishment: any;
	activeSelect: any;
	roomStatusOptions;

	freeUnits: any = [];

	timezone: any;


	// Models
	currentDay: any;


	reportProblemModalComponentDialogRef: MatDialogRef<ReportProblemModalComponent>;

	constructor(
		private appStateService: AppStateService,
		private service: OccupationMapService,
		private hotelSettingsService: HotelSettingsPageService,
		private notifier: NotifierService,
		public dialog: MatDialog,
		private translate: TranslateService,) { }

	ngOnInit() {

		this.selectedEstablishment = this.appStateService.currentEstablishment;

		this.timezone = this.selectedEstablishment.establishmentConfig.timeZone
		this.currentDay = moment.tz(this.timezone)

		this.translateOptions();
		this.translate.onLangChange.subscribe(() => {
			console.log('lang:', this.translate.currentLang);
			this.translateOptions();
		});

		this.loading = true;
		setTimeout(() => {
			this.query();
		}, 500);

	}


	tabLoad(tabGroup) {
		this.tabGroup = tabGroup;
		console.log('tabGroup', this.tabGroup);
	}

	@ViewChild('tabGroup') tabGroup: MatTabGroup;
	public selected: number = 0;
	private swipeCoord?: [number, number];
	private swipeTime?: number;
	swipe(e: TouchEvent, when: string): void {
		const coord: [number, number] = [e.changedTouches[0].clientX, e.changedTouches[0].clientY];
		const time = new Date().getTime();

		if (when === 'start') {
			this.swipeCoord = coord;
			this.swipeTime = time;
		} else if (when === 'end') {
			const direction = [coord[0] - this.swipeCoord[0], coord[1] - this.swipeCoord[1]];
			const duration = time - this.swipeTime;

			if (duration < 1000 //
				&& Math.abs(direction[0]) > 30 // Long enough
				&& Math.abs(direction[0]) > Math.abs(direction[1] * 3)) { // Horizontal enough
				const swipe = direction[0] < 0 ? 'next' : 'previous';

				switch (swipe) {
					case 'previous':
						if (this.selected > 0) { this.selected--; }
						break;
					case 'next':
						if (this.selected < 1) { this.selected++; }
						break;
				}
			}
		}
	}

	translateOptions() {
		this.roomStatusOptions = [
			{ name: this.translate.instant('occupation-map.not_cleaned'), value: 1, key: 'NOT_CLEANED' },
			{ name: this.translate.instant('occupation-map.cleaning'), value: 2, key: 'CLEANING' },
			{ name: this.translate.instant('occupation-map.cleaned'), value: 0, key: 'CLEANED' },
		];
	}

	formatDate(date) {
		return moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY')
	}

	isToday() {
		return this.currentDay.isSame(moment.tz(this.timezone), 'day');
	}

	isFuture() {
		return this.currentDay.isAfter(moment.tz(this.timezone), 'day')
	}

	toDate(date) {
		return moment.tz(date.substr(0, 10), this.timezone)
	}

	query() {
		this.loading = true
		this.data = null
		this.service.getFreeUnitsNotClean(this.selectedEstablishment.establishmentId, this.currentDay.format('YYYY-MM-DD')).subscribe(
			(data) => {
				console.log('Data of free units not clean', data)
				if (data.object) {
					this.freeUnits = []
					data.object.forEach((unit, i) => {
						unit.cleanStatus = this.roomStatusOptions.find(_ => _.key == unit.cleanStatus)
						unit.cleanStatusOld = unit.cleanStatus
						unit.isChanging = false
						this.freeUnits.push(unit)
					})
				}
				window.scroll(0, 0);
			}, error => {
				this.notifier.notify('error', this.translate.instant('error_msg.error_getdata'))
			})
		this.service.getOccupationMap(this.selectedEstablishment.establishmentId, this.currentDay.format('YYYY-MM-DD')).subscribe(
			(data) => {
				console.log('Data of occupation map', data)
				this.loading = false
				window.scroll(0, 0);
				if (data.code === 200 && data.object) {

					// taking returned reservations and putting them in 
					// respectively checkinReservation and checkoutReservation
					data.object.lines = []

					if (data.object.occupationMapUnits) {
						data.object.occupationMapUnits.forEach((line, index) => {

							const { unit } = line // has unitId

							let someReservation = false
							let stopLookingForReservations = false

							line.occupationMapReservations.forEach((reservation) => {

								if (stopLookingForReservations) {
									return
								}

								const checkinDate = this.toDate(reservation.checkinDate)
								const checkoutDate = this.toDate(reservation.checkoutDate)

								if (reservation.checkinActionDate) {
									var checkinActionDate = reservation.checkinActionDate.substring(0, 19);
									checkinActionDate = moment.parseZone(checkinActionDate)
									line.checkinActionDate_Formated = checkinActionDate.utcOffset(this.selectedEstablishment.establishmentConfig.zoneId).format("DD/MM/YYYY HH:mm");
								}

								if (reservation.checkoutActionDate) {
									var checkoutActionDate = reservation.checkoutActionDate.substring(0, 19);
									checkoutActionDate = moment.parseZone(checkoutActionDate)
									line.checkoutActionDate_Formated = checkoutActionDate.utcOffset(this.selectedEstablishment.establishmentConfig.zoneId).format("DD/MM/YYYY HH:mm");
								}


								// check if it is broken reservation
								if (reservation.reservationUnits.length > 1) {

									console.info('some broken reservation', line)

									let currentBrokenUnit = null
									let currentBrokenUnitIndex = null
									reservation.reservationUnits.forEach((reservationUnit, index) => {

										if (currentBrokenUnit !== null) {
											return
										}

										// stop if it's the last unit
										if (reservation.reservationUnits.length === index + 1) {
											currentBrokenUnit = reservationUnit
											currentBrokenUnitIndex = index
											return
										}

										const currentUnitDay = this.toDate(reservationUnit.checkinDate)
										const nextUnitDay = this.toDate(reservation.reservationUnits[index + 1].checkinDate)

										if (this.currentDay.isSameOrAfter(currentUnitDay, 'day') && this.currentDay.isBefore(nextUnitDay, 'day')) {
											currentBrokenUnit = reservationUnit
											currentBrokenUnitIndex = index
										}
									})

									console.info('currentBrokenUnit', currentBrokenUnit)

									// if the broken reservation has some part of the current day on this unit
									if (currentBrokenUnit && currentBrokenUnit.unit.unitId == unit.unitId) {


										// if is the first unit and checkin date is the same then it's checkin
										if (currentBrokenUnitIndex === 0 && this.currentDay.isSame(checkinDate, 'day')) {
											line.checkinReservation = reservation
											someReservation = true
										} else if (currentBrokenUnitIndex + 1 === reservation.reservationUnits.length
											&& this.currentDay.isSame(checkoutDate, 'day')) {
											// if is the last broken unit and it's on final date then it's checkout
											line.checkoutReservation = reservation
											someReservation = true
										} else if (this.currentDay.isSame(this.toDate(currentBrokenUnit.checkinDate), 'day')) {
											// this reservation was broken to this day
											line.brokenReservation = {
												...reservation,
												lastBrokenUnit: reservation.reservationUnits[currentBrokenUnitIndex - 1],
												currentBrokenUnit
											}
											someReservation = true
										} else {
											line.middleReservation = reservation
											someReservation = true
											stopLookingForReservations = true
										}

									} else {
										// find the index of the broken unit that is going to if it exists
										const toBrokenUnitIndex = reservation.reservationUnits.findIndex(
											_ => this.toDate(_.checkinDate).isSame(this.currentDay, 'day')
										)


										// if found and isn't the first
										if (toBrokenUnitIndex > 0) {
											// check if the last unit is the same current unit
											const unitBeforeChange = reservation.reservationUnits[toBrokenUnitIndex - 1]
											console.log('unitBeforeChange', unitBeforeChange)
											console.log('and corrent unit', unit)
											if (unitBeforeChange.unit.unitId == unit.unitId) {
												someReservation = true
												line.nextBrokenReservation = {
													...reservation,
													toBrokenUnit: reservation.reservationUnits[toBrokenUnitIndex]
												}

												console.log('nextBrokenReservation', line.nextBrokenReservation)
											}
										}
									}

								} else {

									if (checkinDate.isSame(this.currentDay, 'day')) {
										// setting the current reservation checkin
										line.checkinReservation = reservation
									} else if (checkoutDate.isSame(this.currentDay, 'day')) {
										line.checkoutReservation = reservation
									} else {
										line.middleReservation = reservation
										stopLookingForReservations = true
									}

									someReservation = true
								}

							})

							if (!someReservation) {
								console.log('supressed line', line)
								return
							}

							if (line.middleReservation === undefined
								&& line.checkoutReservation === undefined
								&& line.checkinReservation === undefined
								&& line.nextBrokenReservation === undefined
								&& line.brokenReservation === undefined) {
								console.log('supressed line [2]', line)
								return
							}

							let currentReservation
							let currentReservationType

							if (line.middleReservation) {
								currentReservation = line.middleReservation
								currentReservationType = 'middle'
							} else if (line.brokenReservation) {
								currentReservation = line.brokenReservation
								currentReservationType = 'broken'
							} else {
								if (line.checkinReservation) {
									currentReservation = line.checkinReservation
									currentReservationType = 'checkin'
								} else if (line.checkoutReservation) {
									currentReservation = line.checkoutReservation
									currentReservationType = 'checkout'
								}
							}

							if (!currentReservation) {
								currentReservation = line.nextBrokenReservation
								currentReservationType = 'broken'
							}

							const fx = this.getFixedReservation(currentReservation)
							line.currentDayPos = this.getCurrentDayPos(fx.day, fx.checkin, fx.checkout, currentReservationType)
							line.currentReservation = currentReservation

							if (!line.unit.cleanStatus) {
								line.unit.cleanStatus = 'CLEANED'
							}

							line.unit.cleanStatus = this.roomStatusOptions.find(_ => _.key == line.unit.cleanStatus)
							line.unit.cleanStatusOld = line.unit.cleanStatus
							line.unit.isChanging = false

							data.object.lines.push(line)
						})
					}

					console.log('Data of occupation map post processed', data.object)

					this.data = data.object
				} else {
					this.notifier.notify('error', this.translate.instant('error_msg.error_getdata'))
				}
			},
			(error) => {
				this.loading = false
				this.notifier.notify('error', this.translate.instant('error_msg.error_undefined'))
			})
	}

	formattedDay() {
		return this.currentDay ? this.currentDay.locale('pt-BR').format('LL') : ' '
	}

	getFixedReservation(reservation) {
		const checkin = this.toDate(reservation.checkinDate)
		return {
			checkin,
			checkout: this.toDate(reservation.checkoutDate),
			day: this.currentDay.diff(checkin, 'days') + 1
		}
	}

	getCurrentDayPos(day, checkin, checkout, type) {
		return { day, label: type === 'checkout' ? this.translate.instant('last_day') : `${day}º ${this.translate.instant('day')}`, between: `${checkin.format('DD/MM/YYYY')} - ${checkout.format('DD/MM/YYYY')}` }
	}

	translateStatusForBtn(status) {
		return status === true ? 'done' : (status === null ? 'loading' : 'no')
	}

	// check
	check(index, key, type) {
		const line = this.data.lines[index]
		const lastStatus = this.data.lines[index][key][type]

		if (lastStatus == true) {
			return;
		}

		if (!this.shouldCheckOnLine(line, type)) {
			return;
		}

		const swalWithBootstrapButtons = Swal.mixin({
			customClass: {
				confirmButton: 'btn btn-primary',
				cancelButton: 'btn'
			},
			buttonsStyling: false
		})

		swalWithBootstrapButtons.fire({
			position: 'center',
			title: (type == "checkin" ? this.translate.instant('occupation.confirm_checkin') : this.translate.instant('occupation.confirm_checkout')),
			text: this.translate.instant('occupation.confirm_checkin_checkout_info'),
			icon: 'question',
			showCancelButton: true,
			confirmButtonText: this.translate.instant('occupation.ok_confirm'),
			cancelButtonText: this.translate.instant('cancel'),
			reverseButtons: true
		}).then((result) => {
			if (result.value) {
				this.executeCheck(index, key, type);
			} else if (result.dismiss === Swal.DismissReason.cancel) {

			}
		});


	}

	executeCheck(index, key, type) {
		const reservation = this.data.lines[index][key]
		const lastStatus = this.data.lines[index][key][type]
		this.data.lines[index][key][type] = null

		this.service.check(reservation.reservationId, type, lastStatus ? 'false' : 'true').subscribe(
			(data) => {
				if (data.code === 200) {
					this.data.lines[index][key][type] = !lastStatus;

					if (data.object && data.object.code == 422) {
						Swal.fire(`<b>Checkout Realizado!<br>Alertas Retornados Abaixo: </b>`, data.object.message, 'info');
					}
				}
				else if (data.message) {
					this.data.lines[index][key][type] = lastStatus
					this.notifier.notify('error', data.message)
				} else {
					this.data.lines[index][key][type] = lastStatus
					this.notifier.notify('error', `${this.translate.instant('error_msg.error_edit')} ${type}`)
				}
			},
			(error) => {
				console.log(error);
				if (error.error.message) {
					this.data.lines[index][key][type] = lastStatus
					this.notifier.notify('error', error.error.message)
				} else {
					this.data.lines[index][key][type] = lastStatus
					this.notifier.notify('error', `${this.translate.instant('error_msg.error_edit')} ${type}`)
				}
			})
	}

	// disable or not the check button under certain circumstances
	shouldCheckOnLine(line, type) {
		if (this.isFuture()) {
			return false
		}

		if (type == 'checkin') {

			if (line.checkinReservation &&
				(!line.checkoutReservation || line.checkoutReservation.checkout)
			) {
				return true
			}
		} else {
			if (line.checkoutReservation) {
				return true
			}
		}
		return false
	}

	generateClassForMtag(reservation, hasMarginTop = false, hasMarginBottom = false) {
		return {
			'mt5': hasMarginTop,
			'mb5': hasMarginBottom,
			'red': reservation.reservationStatus == 'NO_PAYMENT',
			'yellow': reservation.reservationStatus == 'PENDING_PAYMENT',
			'green': reservation.reservationStatus == 'FULL_PAYMENT'
		}
	}

	generateBrokenReservationText(line, type) {
		let text = this.isFuture() ? this.translate.instant('occupation.will_be_transferred') : this.translate.instant('occupation.transferred');
		if (type === 'leaving') {
			return `${this.translate.instant('occupation.msg_trans_leav_01')} ${text} ${this.translate.instant('occupation.msg_trans_leav_02')} ${line.nextBrokenReservation.toBrokenUnit.unit.name}.`
		} else {
			return `${this.translate.instant('occupation.msg_trans_01')} ${text} ${this.translate.instant('of')} ${line.brokenReservation.lastBrokenUnit.unit.name} ${this.translate.instant('occupation.msg_trans_02')}.`
		}
	}

	shortName(name: string, suffix = '') {
		let splittedName = name.split(' ')
		if (name.length > 17) {
			if (splittedName.length > 1) {
				if (splittedName.length > 2) {
					return this.shortName(`${splittedName[0]} ${splittedName[1]}`, '...')
				} else {
					return `${name.substr(0, 14)}...`
				}
			} else {
				return `${name.substr(0, 14)}...`
			}
		}
		return `${name}${suffix}`
	}

	// clean status functions
	translateCleanStatus(status) {
		return this.roomStatusOptions.find(_ => _.key == status)
	}

	changedCleanStatus(selectedOption, i) {
		const line = this.data.lines[i]

		if (selectedOption.key == line.unit.cleanStatusOld.key) {
			console.log('no changes on changed clean status')
			return;
		}
		console.log('trying to change the clean status')

		this.data.lines[i].unit.isChanging = true
		this.service.changeCleanStatus(line.unit.unitId, selectedOption.value).subscribe(
			(data) => {
				if (data.code === 200) {
					this.data.lines[i].unit.cleanStatusOld = selectedOption
					this.data.lines[i].unit.isChanging = false
				} else {
					this.data.lines[i].unit.cleanStatus = this.data.lines[i].unit.cleanStatusOld
					this.data.lines[i].unit.isChanging = false
					this.notifier.notify('error', this.translate.instant('error_msg.error_edit'))
				}

				switch (selectedOption.value) {
					case 0:
					  return 'fas fa-circle text-success';
					case 1:
					  return 'fas fa-circle text-warning';
					case 2:
					  return 'fas fa-circle text-danger';
					default:
					  return 'fas fa-circle text-muted';
				  }
			
			},
			(error) => {
				this.data.lines[i].unit.cleanStatus = this.data.lines[i].unit.cleanStatusOld
				this.data.lines[i].unit.isChanging = false
				this.notifier.notify('error', this.translate.instant('error_msg.error_edit'))
			}
		)
	}


	changedCleanStatusOfUnit(selectedOption, i) {
		const unit = this.freeUnits[i]

		if (selectedOption.key == unit.cleanStatusOld.key) {
			console.log('no changes on changed clean status')
			return;
		}
		console.log('trying to change the clean status')

		this.freeUnits[i].isChanging = true
		this.service.changeCleanStatus(unit.unitId, selectedOption.value).subscribe(
			(data) => {
				if (data.code === 200) {
					this.freeUnits[i].cleanStatusOld = selectedOption
					this.freeUnits[i].isChanging = false
				} else {
					this.freeUnits[i].cleanStatus = this.freeUnits[i].cleanStatusOld
					this.freeUnits[i].isChanging = false
					this.notifier.notify('error', this.translate.instant('error_msg.error_edit'))
				}
			},
			(error) => {
				this.freeUnits[i].cleanStatus = this.freeUnits[i].cleanStatusOld
				this.freeUnits[i].isChanging = false
				this.notifier.notify('error', this.translate.instant('error_msg.error_edit'))
			}
		)
	}

	goToNextDay = () => this.currentDay && this.currentDay.add(1, 'days') && this.query()
	goBackADay = () => this.currentDay && this.currentDay.subtract(1, 'days') && this.query()

	reportProblem(unit) {
		this.reportProblemModalComponentDialogRef = this.dialog.open(ReportProblemModalComponent, {
			panelClass: 'mat-dialog-fix',
			disableClose: false,
			data: { selectedUnit: unit }
		});

		//this.reportProblemModalComponentDialogRef.componentInstance.units = this.units;

		this.reportProblemModalComponentDialogRef.afterClosed().subscribe(result => {
			if (result === true) {
				this.query();
			}
			this.reportProblemModalComponentDialogRef = null;
		});

	}

	showObs(reservation) {
		Swal.fire(`${this.translate.instant('reservation_obs')} ${reservation.reservationId}`, `<br>${(reservation.observation || this.translate.instant('no_observation'))}<br>`, 'info');
	}

}
