import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { ResponseFormat } from '@app/types/response-format';
import { AppStateService } from '@src/app/layouts/app-layout.service';


@Injectable()
export class ReportActivityService 
{
    constructor(private http : HttpClient, private appStateService: AppStateService) { }

    getActivityReport = (employeeId, initialDate, finalDate) => 
    {
    	const establishment = this.appStateService.currentEstablishment;

        const request = `vivakey/rest/log/getLogsByAction/${establishment.establishmentId}/${employeeId}/${initialDate}T00:00:00.000-0300/${finalDate}T00:00:00.000-0300`;
        return this.http.get<ResponseFormat>(request);
    }
}
