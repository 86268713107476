import { Component, OnInit } from "@angular/core";
import moment from "moment-timezone";
import { NotifierService } from 'angular-notifier';
import { TranslateService } from '@ngx-translate/core';
import { GroupByPipe } from '@src/app/pipes/group-itens-pipe';
import { MatDialogRef, MatDialog } from '@angular/material';
import { ReportFinancialDetailsModalComponent } from '@src/app/components/views/report-financial-details-modal/report-financial-details-modal.component';
declare var $: any;

import { translateOrigin, translatePaymentType } from '@utils/helpers'
import { ReportInvoicesService } from './report-invoices.service';
import { AppStateService } from "@src/app/layouts/app-layout.service";


@Component({
    selector: "app-report-invoices",
    templateUrl: "./report-invoices.component.html",
    styleUrls: ["./report-invoices.component.css"]
})
export class ReportInvoicesComponent implements OnInit {
    
    repData: any[] = [];

    selectStartDate: any;
    selectFinishDate: any;
    establishment: any;
    mainGuest: string = '';
    nameInvoice: string = '';
    documentInvoice: string = '';

    emptyDateWarn: boolean = false;
    loadingData: boolean = false;

    totalGrossValue: number = 0;
    totalNetValue: number = 0;

    
    reportFinancialDetailsModalComponent: MatDialogRef<ReportFinancialDetailsModalComponent>;

    constructor(
        private appStateService: AppStateService,
        private notifier: NotifierService,
        private reportFinancialService: ReportInvoicesService,
        private translate: TranslateService,
        public dialog: MatDialog
    ) {}

    ngOnInit() {

        this.establishment = this.appStateService.currentEstablishment;

        this.selectStartDate = moment(moment().subtract("1", "days").format("YYYY-MM-DD"));
        this.selectFinishDate = moment(moment().format("YYYY-MM-DD"));
        this.filterReport();
    }

    test() {
        console.log("selectStartDate", this.selectStartDate);
    }


    // Download the excel file
	downloadReport() {

		this.loadingData = true

		this.reportFinancialService
			.filterReport(
                this.establishment.establishmentId,
                    this.selectStartDate.format('YYYY-MM-DD'),
                    this.selectFinishDate.format('YYYY-MM-DD'),
                    this.mainGuest,
                    this.nameInvoice,
                    this.documentInvoice,
                    true
            )
			.subscribe(
				data => {
					console.log("Download link", data);
					if(data.code === 200 && data.message) {
                        
                        window.location.href = data.message.replace('reporturl: ', '');

					} else {
						this.notifier.notify('error', this.translate.instant('error_msg.error_getdata' ))
					}
					this.loadingData = false
				},
				error => {
					this.loadingData = false
					this.notifier.notify('error', this.translate.instant('error_msg.error_getdata' ))
				}
            );
            
        this.filterReport();
	}

    filterReport = () => {
        if (!this.selectStartDate || !this.selectFinishDate) {
            // this.emptyDateWarn = true;
        } else {
            this.loadingData = true;
            this.emptyDateWarn = false;

            this.totalGrossValue = 0;
            this.totalNetValue = 0;
            this.repData = null;

            this.reportFinancialService
                .filterReport(
                    this.establishment.establishmentId,
                    this.selectStartDate.format('YYYY-MM-DD'),
                    this.selectFinishDate.format('YYYY-MM-DD'),
                    this.mainGuest,
                    this.nameInvoice,
                    this.documentInvoice
                )
                .subscribe(
                    data => {
                        console.log(data);
                        switch (data.code) {
                            case 200:
                                this.repData = data.object;
                                break;
                            default:
                                break;
                        }
                        this.loadingData = false;
                    },
                    error => {
                        console.log("Error");
                        this.notifier.notify('error', this.translate.instant('error_msg.error_getdata' ))
                        this.loadingData = false;
                    }
                );
        }
    };


   
    getReservationFullPaymentInfo = (item) => {      
        
        this.reportFinancialDetailsModalComponent = this.dialog.open(ReportFinancialDetailsModalComponent, {
            //panelClass: 'mat-dialog-large',
            disableClose: false,
            data : { item: item }
        });

        this.reportFinancialDetailsModalComponent.afterClosed().subscribe(result => {
            if (this.reportFinancialDetailsModalComponent.componentInstance.resultDialog === 'reverse') {
                //this.reversalReason = '';
                //$('#reversalModal').modal('show');
                this.filterReport();
            }
            this.reportFinancialDetailsModalComponent = null;
        });

    }

}