import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ResponseFormat } from '@app/types/response-format';
import { RoomConfig, InitialCofig, ExistentConfig } from './setting-bukly.types'; // Types
import { AppStateService } from '@src/app/layouts/app-layout.service';

@Injectable()
export class SettingsBuklyService 
{

    constructor(private http: HttpClient, private appStateService: AppStateService) {
    }

	getDataInitialConfig = () => {
        let establishment = this.appStateService.currentEstablishment;
        return this.http.get<ResponseFormat>('vivakey/rest/bukly/getInitialConfiguration/' + establishment.establishmentId)
    }

	getDataRoomConfig = (buklyHotelId) =>{
        let establishment = this.appStateService.currentEstablishment;
        return this.http.get<ResponseFormat>(`vivakey/rest/bukly/getDataRoomConfiguration/${establishment.establishmentId}/${buklyHotelId}`);
    }
		
    saveInitialConfig = (initialConfig: InitialCofig) =>
    {
        let establishment = this.appStateService.currentEstablishment;
        const payload = {
            buklyHotelId: initialConfig.buklyHotelId,
            establishmentId: establishment.establishmentId,
            user: initialConfig.user,
            password: initialConfig.password,
            hotelCode: initialConfig.hotelCode
        };

		console.log('salvando initial config...', payload);
		return this.http.post<ResponseFormat>('vivakey/rest/bukly/saveInitialConfiguration', payload);
    }
    
    saveRoomConfig = (roomConfig: ExistentConfig[]) =>
    {
		console.log('salvando room config...', roomConfig);
		return this.http.post<ResponseFormat>('vivakey/rest/bukly/saveDataRoomConfiguration', roomConfig);
	}

    deleteRoomConfig = (categoryId, channelId) =>
    {
        return this.http.delete<ResponseFormat>(`vivakey/rest/bukly/${categoryId}/${channelId}`);
    }

    deleteConfig = (buklyHotelId) =>
    {
        return this.http.delete<ResponseFormat>(`vivakey/rest/bukly/buklyhotel/${buklyHotelId}`);
    }
}
