import { APP_BASE_HREF, registerLocaleData } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { NgModule, LOCALE_ID } from "@angular/core";
import { AppComponent } from "./app.component";
import { DatePipe } from "@angular/common";
import { HTTP_INTERCEPTORS } from "@angular/common/http";

// Modules
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from "@angular/http";
import { RouterModule } from "@angular/router";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { CurrencyMaskModule } from "ng2-currency-mask";
import { TextMaskModule } from "angular2-text-mask";
import { MyDateRangePickerModule } from "mydaterangepicker";
import { MyDatePickerModule } from "mydatepicker";
import { QuillModule } from "ngx-quill";
import { NotifierModule } from "angular-notifier";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgScrollbarModule } from "ngx-scrollbar";
import { TooltipModule } from 'ng2-tooltip-directive';

// Guards
import { AuthGuard } from "./auth.guard";
import { DashboardPageGuard, OwnerDashboardPageGuard } from "./guards/page.guard";

// Views
import { EditUserFormComponent } from "./components/views/edit-user-form/edit-user-form.component";
import { LoadingComponent } from "./components/views/loading/loading.component";
import { LoadingInlineComponent } from "./components/views/loading-inline/loading-inline.component";
import { CheckinFormComponent } from './components/views/checkin-form/checkin-form.component';
import { DependentFormComponent } from './components/views/dependent-form/dependent-form.component';
import { ConfirmCardComponent } from './components/views/confirm-card/confirm-card.component';
import { InvoiceFormComponent } from './components/views/invoice-form/invoice-form.component';
import { URLImagePickerComponent } from './components/inputs/url-image-picker/url-image-picker.component';
import { CreateHotelComponent } from './pages/create-hotel/create-hotel.component';
import { CreateCompanyComponent } from './pages/create-company/create-company.component';
import { UnitProblemPageComponent } from './pages/unit-problem-page/unit-problem-page.component';


// Inputs
import { SearchComponent } from "./components/inputs/search/search.component";
import { TimepickerComponent } from "./components/inputs/timepicker/timepicker.component";
import { ImagePickerComponent } from "./components/inputs/image-picker/image-picker.component";
import { DatepickerComponent } from "./components/inputs/datepicker/datepicker.component";
import { SelectComponent } from "./components/inputs/select/select.component";

// Services
import { LoginService } from "./pages/login/login.service";
import { TokenService } from "./token.service";
import { ChoseCompanyService } from "./pages/chose-company/chose-company.service";
import { EditUserService } from "./components/views/edit-user-form/edit-user.service";
import { ReservationsService } from "./pages/reservations/reservations.service";
import { ReservationResponsableService } from "./pages/reservation-responsable/reservation-responsable.service";
import { ReservationGuestService } from "./pages/reservation-guest/reservation-guest.service";
import { ReservationPaymentService } from "./pages/reservation-payment/reservation-payment.service";
import { ReservationSummaryService } from "./pages/reservation-summary/reservation-summary.service";
import { TariffService } from "./pages/tariff/tariff.service";
import { PdvService } from "./pages/pdv/pdv.service";
import { AvailabilityService } from "./pages/availability/availability.service";
import { ReportOccupationService } from "./pages/report-occupation/report-occupation.service";
import { ReportFinancialService } from "./pages/report-financial/report-financial.service";
import { CategoriesService } from "./pages/categories/categories.service";
import { UnitsService } from "./pages/units/units.service";
import { ReportReverseService } from "./pages/report-reverse/report-reverse.service";
import { ReportActivityService } from "./pages/report-activity/report-activity.service";
import { EstablishmentPolicyService } from "./pages/establishment-policy/establishment-policy.service";
import { ChannelManagerService } from "./pages/channel-manager/channel-manager.service";
import { SettingsBuklyService } from "./pages/settings-bukly/settings-bukly.service";
import { HotelSettingsPageService } from "./pages/hotel-settings/hotel-settings-page.service";
import { ReportBillingService } from "./pages/report-billing/report-billing.service";
import { ClientCardService } from "./pages/client-card-page/client-card.service";
import { OccupationMapService } from "./pages/occupation-map-page/occupation-map.service";
import { PortadorService } from "./pages/portador/portador.service";
import { SelfRegistrateService } from "./pages/self-registrate/self-registrate.service";
import { UsersService } from "./pages/users/users.service";
import { TipoPagamentoService } from "./pages/tipo-pagamento/tipo-pagamento.service";
import { ReportClosingAptService } from './pages/report-closing-of-apartments/report-closing-of-apartments.service';
import { ReportSalesService } from './pages/report-sales/report-sales.service';
import { ReportNoPaymentsService } from './pages/report-reserve-no-payments/report-reserve-no-payments.service';


// Pages
import { LoginComponent } from "./pages/login/login.component";
import { ChoseCompanyComponent } from "./pages/chose-company/chose-company.component";
import { RecoveryPasswordComponent } from "./pages/recovery-password/recovery-password.component";
import { ResetPasswordComponent } from "./pages/reset-password/reset-password.component";
import { DashboardComponent } from "./pages/dashboard/dashboard.component";
import { ReservationsComponent } from "./pages/reservations/reservations.component";
import { ChangeCompanyComponent } from "./pages/change-company/change-company.component";
import { ReservationResponsableComponent } from "./pages/reservation-responsable/reservation-responsable.component";
import { ReservationGuestComponent } from "./pages/reservation-guest/reservation-guest.component";
import { ReservationPaymentComponent } from "./pages/reservation-payment/reservation-payment.component";
import { ReservationSummaryComponent } from "./pages/reservation-summary/reservation-summary.component";
import { PortadorComponent } from "./pages/portador/portador.component";
import { TipoPagamentoComponent } from "./pages/tipo-pagamento/tipo-pagamento.component";
import { UsersComponent } from "./pages/users/users.component";
import { SelfRegistrateComponent } from "./pages/self-registrate/self-registrate.component";
import { PdvComponent } from "./pages/pdv/pdv.component";
import { ActivateUserComponent } from "./pages/activate-user/activate-user.component";
import { AvailabilityComponent } from "./pages/availability/availability.component";
import { ReportFinancialComponent } from "./pages/report-financial/report-financial.component";
import { ReportOccupationComponent } from "./pages/report-occupation/report-occupation.component";
import { UnitsComponent } from "./pages/units/units.component";
import { CategoriesComponent } from "./pages/categories/categories.component";
import { ReportReverseComponent } from "./pages/report-reverse/report-reverse.component";
import { ReportActivityComponent } from "./pages/report-activity/report-activity.component";
import { EstablishmentPolicyComponent } from "./pages/establishment-policy/establishment-policy.component";
import { ChannelManagerComponent } from "./pages/channel-manager/channel-manager.component";
import { SettingsBuklyComponent } from "./pages/settings-bukly/settings-bukly.component";
import { HotelSettingsPageComponent } from "./pages/hotel-settings/hotel-settings-page.component";
import { ReportBillingComponent } from "./pages/report-billing/report-billing.component";
import { OccupationMapPageComponent } from "./pages/occupation-map-page/occupation-map-page.component";
import { ClientCardPageComponent } from "./pages/client-card-page/client-card-page.component";
import { TariffComponent } from "./pages/tariff/tariff.component";
import { HomePageComponent } from "./pages/home-page/home-page.component";
import { ReportProblemModalComponent } from './components/views/report-problem-modal/report-problem-modal.component';
import { CreateCompanyModalComponent } from './components/views/create-company-modal/create-company-modal.component';
import { CreateEstablishmentModalComponent } from './components/views/create-establishment-modal/create-establishment-modal.component';
import { ReportSalesComponent } from './pages/report-sales/report-sales.component';
import { ReportNoPaymentsComponent } from './pages/report-reserve-no-payments/report-reserve-no-payments.component';



// Others
import { ResponseFormat } from "@app/types/response-format";
import { TokenInterceptor } from "./token.interceptor";
import { ImageCompressService } from 'ng2-image-compress';
import { CKEditorModule } from "ng2-ckeditor";
import localePt from '@angular/common/locales/pt';
import { CheckinFormService } from './components/views/checkin-form/service/checkin-form.service';
import { DependentFormService } from './components/views/dependent-form/service/dependent-form.service';

registerLocaleData(localePt);

// Layout
import { BaseAppComponent } from "./layouts/base-app/base-app.component";
import { DashboardAppComponent } from "./layouts/dashboard-app/dashboard-app.component";

import { appRoutes } from "./router";
import { ShadowedCardComponent } from "./components/views/shadowed-card/shadowed-card.component";

// Material
import {
    MAT_DATE_FORMATS,
    MatButtonModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    MatTableModule,
    MatRadioModule,
    MatCheckboxModule,
    MatIconModule,
    MatToolbarModule,
    MatAutocompleteModule,
    MatGridListModule,
    MatMenuModule,
    MatCardModule,
    MatDividerModule,
    MatListModule,
    MatTabsModule,
    MatChipsModule
} from "@angular/material";
import { MatMomentDateModule, MAT_MOMENT_DATE_FORMATS } from "@angular/material-moment-adapter";
import { CommonService } from './common-service';
import { GenderTransPipe } from './pipes/gender-pipe';
import { ShortDateFormatNoUTC } from './pipes/date-pipe';
import { ReportClosingAptComponent } from './pages/report-closing-of-apartments/report-closing-of-apartments.component';
import { NationalDataComponent } from './components/views/national-data-form/national-data-form.component';
import { NatDataService } from './components/views/national-data-form/national-data.service';
import { ReservationsListComponent } from './pages/reservation-list/reservation-list.component';
import { ReservationsListService } from './pages/reservation-list/reservation-list.service';
import { ResendEmailModalComponent } from './components/views/resend-email-modal/resend-email-modal.component';
import { EditCardModalComponent } from './components/views/edit-card-modal/edit-card-modal.component';
import { NgxMaskModule } from 'ngx-mask'
import { ConfigurePaymentGatewayModalComponent } from './components/views/config-payment-gateway/config-payment-gateway-modal.component';
import { ReservationPaymentModalComponent } from './components/views/reservation-payment-modal/reservation-payment-modal.component';
import { ReservationPaymentReversalModalComponent } from './components/views/reservation-payment-reversal-modal/reservation-payment-reversal-modal.component';
import { ReportAutenticcoReservationsComponent } from './pages/report-reserve-autenticco/report-reserve-autenticco.component';
import { ReportAutenticcoReservationsService } from './pages/report-reserve-autenticco/report-reserve-autenticco.service';
import { GroupByPipe } from './pipes/group-itens-pipe';
import { SetAutomaticCalcModalComponent } from './components/views/set-automatic-calc/set-automatic-calc-modal.component';
import { OrderPipe } from './pipes/sort-by.pipe';
import { ReportFinancialDetailsModalComponent } from './components/views/report-financial-details-modal/report-financial-details-modal.component';
import { ConfigureOmieDataModalComponent } from './components/views/config-omie-data/config-omie-data-modal.component';
import { ConfigOmieService } from './components/views/config-omie-data/config-omie.service';
import {MatSelectInfiniteScrollModule} from 'ng-mat-select-infinite-scroll';
import { FixResErrorsModalComponent } from './components/views/fix-reservation-error-modal/fix-reservation-error-modal.component';
import { SharedModule } from './shared/shared.module';
import { AccordionNavDirective } from './layouts/sidenav/sidenav-menu/accordion-nav.directive';
import { AppendSubmenuIconDirective } from './layouts/sidenav/sidenav-menu/append-submenu-icon.directive';
import { HighlightActiveItemsDirective } from './layouts/sidenav/sidenav-menu/highlight-active-items.directive';
import { AppSidenavMenuComponent } from './layouts/sidenav/sidenav-menu/sidenav-menu.component';
import { AutoCloseMobileNavDirective } from './layouts/sidenav/auto-close-mobile-nav.directive';
import { AppSidenavComponent } from './layouts/sidenav/sidenav.component';
import { ToggleOffcanvasNavDirective } from './layouts/sidenav/toggle-offcanvas-nav.directive';
import { AppHeaderComponent } from './layouts/header/header.component';
import { DashboardMenuService } from './layouts/sidenav/sidenav-menu/dashboard-menu.service';
import { SchedulerComponent } from './components/views/scheduler/scheduler.component';
import { DayPilotModule, DayPilotSchedulerComponent } from 'daypilot-pro-angular';
import { FormEditDirective } from './components/views/scheduler/form-container.directive';
import { BuildingsComponent } from './pages/buildings/buildings.component';
import { BuildingsService } from './pages/buildings/buildings.service';
import { OtasService } from './pages/otas-config/otas-config.service';
import { OtasConfigComponent } from './pages/otas-config/otas-config.component';
import { ConfigureAirBnbModalComponent } from './components/views/config-airbnb/config-airbnb-modal.component';
import { ReportInvoicesService } from './pages/report-invoices/report-invoices.service';
import { ReportInvoicesComponent } from './pages/report-invoices/report-invoices.component';
import { OwnerDashboardComponent } from './pages/dashboard-owner/dashboard-owner.component';
import {MatExpansionModule} from '@angular/material/expansion';
import { NgcFloatButtonModule } from "./components/inputs/ngc-float-button/ngc-float-button.module";
import { StatusButtonComponent } from "./components/inputs/status-button/status-button.component";
import { OrignFormatPipe } from "./pipes/translate-pipes";
import { IntPhoneMaskDirective } from "./components/directives/phone-input-directive";
import { PhoneInputComponent } from './components/inputs/phone-input/phone-input.component';
import { AwesomeTooltipDirective } from "./components/directives/tooltip/tooltip.directive";
import { TooltipBoxComponent } from "./components/directives/tooltip/tooltip.component";
import { PropertyOwnerComponent } from "./pages/property-owner/property-owner.component";
import { PropertyOwnerService } from "./pages/property-owner/property-owner.service";


const MaterialToImport = [
    MatButtonModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    MatTableModule,
    MatRadioModule,
    MatCheckboxModule,
    MatMomentDateModule,
    MatIconModule,
    MatAutocompleteModule,
    MatGridListModule,
    MatToolbarModule,
    MatMenuModule,
    MatCardModule,
    MatSelectInfiniteScrollModule,
    MatDividerModule,
    MatListModule,
    MatTabsModule,
    MatExpansionModule,
    MatChipsModule
];

export const HttpLoaderFactory = (http: HttpClient) => {
    return new TranslateHttpLoader(http);
};

@NgModule({
    declarations: [
        AppComponent,

        AppHeaderComponent,
        AccordionNavDirective,
        AppendSubmenuIconDirective,
        HighlightActiveItemsDirective,
        AppSidenavMenuComponent,
        AutoCloseMobileNavDirective,
        AppSidenavComponent,
        ToggleOffcanvasNavDirective,

        IntPhoneMaskDirective,
        AwesomeTooltipDirective,
        TooltipBoxComponent,
        
        LoginComponent,
        ChoseCompanyComponent,
        RecoveryPasswordComponent,
        ResetPasswordComponent,
        DashboardComponent,
        OwnerDashboardComponent,
        SearchComponent,
        EditUserFormComponent,
        ChangeCompanyComponent,
        ReservationsComponent,
        ReservationResponsableComponent,
        LoadingComponent,
        LoadingInlineComponent,
        ReservationGuestComponent,
        ReservationPaymentComponent,
        ReservationSummaryComponent,
        ReservationsListComponent,
        TariffComponent,
        PortadorComponent,
        TipoPagamentoComponent,
        UsersComponent,
        PropertyOwnerComponent,
        SelfRegistrateComponent,
        PdvComponent,
        ActivateUserComponent,
        AvailabilityComponent,
        ReportFinancialComponent,
        ReportInvoicesComponent,
        ReportOccupationComponent,
        UnitsComponent,
        CategoriesComponent,
        ReportReverseComponent,
        ReportActivityComponent,
        EstablishmentPolicyComponent,
        ChannelManagerComponent,
        SettingsBuklyComponent,
        HotelSettingsPageComponent,
        TimepickerComponent,
        ImagePickerComponent,
        URLImagePickerComponent,
        ReportBillingComponent,
        ReportClosingAptComponent,
        ReportSalesComponent,
        ReportNoPaymentsComponent,
        ReportAutenticcoReservationsComponent,
        DatepickerComponent,
        OccupationMapPageComponent,
        SelectComponent,
        StatusButtonComponent,
        ClientCardPageComponent,
        BaseAppComponent,
        DashboardAppComponent,
        ShadowedCardComponent,
        HomePageComponent,
        CheckinFormComponent,
        DependentFormComponent,
        ConfirmCardComponent,
        InvoiceFormComponent,
        NationalDataComponent,
        CreateHotelComponent,
        CreateCompanyComponent,
        UnitProblemPageComponent,
        CreateCompanyModalComponent,
        CreateEstablishmentModalComponent,
        EditCardModalComponent,
        ReportProblemModalComponent,
        ResendEmailModalComponent,
        ConfigurePaymentGatewayModalComponent,
        ConfigureAirBnbModalComponent,
        ConfigureOmieDataModalComponent,
        ReservationPaymentModalComponent,
        ReservationPaymentReversalModalComponent,
        ReportFinancialDetailsModalComponent,
        SetAutomaticCalcModalComponent,
        FixResErrorsModalComponent,

        GenderTransPipe,
        ShortDateFormatNoUTC,
        GroupByPipe,
        OrderPipe,
        OrignFormatPipe,

        SchedulerComponent,
        FormEditDirective,
        BuildingsComponent,
        OtasConfigComponent,
        PhoneInputComponent 
    ],
    imports: [
        BrowserModule,
        FormsModule,
        SharedModule,
        ReactiveFormsModule,
        HttpModule,
        CurrencyMaskModule,
        NgScrollbarModule,
        TextMaskModule,
        HttpClientModule,
        CKEditorModule,
        MyDateRangePickerModule,
        MyDatePickerModule,
        NgxMaskModule.forRoot(),
        DayPilotModule,
        
        NotifierModule.withConfig({
            position: {
                horizontal: { position: "right" },
                vertical: { position: "top" }
            }
        }),
        BrowserAnimationsModule,
        ...MaterialToImport,
        TooltipModule,
        QuillModule,
        RouterModule.forRoot(appRoutes,{
            paramsInheritanceStrategy: 'always'
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        NgbModule.forRoot(),
        NgcFloatButtonModule,
        //ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
    ],
    exports     : [
        GenderTransPipe,
        ShortDateFormatNoUTC,
        OrderPipe,
        OrignFormatPipe
    ],
    providers: [
        LoginService,
        AuthGuard,
        DashboardPageGuard,
        OwnerDashboardPageGuard,
        TokenService,
        ChoseCompanyService,
        DashboardMenuService,
        ReservationsService,
        DatePipe,
        EditUserService,
        ReservationResponsableService,
        ReservationGuestService,
        ReservationPaymentService,
        ReservationSummaryService,
        ReservationsListService,
        TariffService,
        PortadorService,
        UsersService,
        SelfRegistrateService,
        TipoPagamentoService,
        PdvService,
        ReportOccupationService,
        ReportFinancialService,
        ReportReverseService,
        ReportActivityService,
        ReportClosingAptService,
        AvailabilityService,
        CategoriesService,
        ChannelManagerService,
        UnitsService,
        BuildingsService,
        ResponseFormat,
        EstablishmentPolicyService,
        SettingsBuklyService,
        HotelSettingsPageService,
        CommonService,
        ReportBillingService,
        ReportSalesService,
        ReportNoPaymentsService,
        ReportAutenticcoReservationsService,
        ReportInvoicesService,
        PropertyOwnerService,
        
    
        ClientCardService,
        OccupationMapService,
        CheckinFormService,
        DependentFormService,
        ImageCompressService,
        NatDataService,
        ConfigOmieService,
        OtasService,


        { provide: LOCALE_ID, useValue: "pt-BR" },
        {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
        //{ provide: DateAdapter, useClass: AppDateAdapter },
        //{ provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true
        },
        { provide: APP_BASE_HREF, useValue: "/" }
    ],

    entryComponents: [
        CreateCompanyModalComponent,
        CreateEstablishmentModalComponent,
        ReportProblemModalComponent,
        ResendEmailModalComponent,
        EditCardModalComponent,
        ConfigurePaymentGatewayModalComponent,
        ConfigureAirBnbModalComponent,
        ConfigureOmieDataModalComponent,
        SetAutomaticCalcModalComponent,
        ReportFinancialDetailsModalComponent,
        FixResErrorsModalComponent,
        DayPilotSchedulerComponent,
        TooltipBoxComponent
        
    ],

    bootstrap: [AppComponent]
})
export class AppModule {}
