import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ChoseCompanyService } from '../pages/chose-company/chose-company.service';

@Injectable({
	providedIn: 'root'
})
export class AppStateService {
	protected _currentTheme: string;
	protected _sideRecoil: string;

	public currentEstablishmentId : string;

	public currentCompany : any;
	public currentEstablishment : any;

	public onCurrentEstablishmentLoaded: EventEmitter<any> = new EventEmitter<any>();

	public AppConfig: any;

	public onEnterChangeCompanies: EventEmitter<any> = new EventEmitter<any>();

	public onSelecCompany: EventEmitter<any> = new EventEmitter<any>();
	public onSelecEstabishment: EventEmitter<ReloadCompanyEventCmd> = new EventEmitter<ReloadCompanyEventCmd>();


	constructor(public translate: TranslateService, private router: Router, private choseCompanyService: ChoseCompanyService) {

		this.onSelecCompany.subscribe((company: any) => {
			this.onSelectedCompany(company);
		});

		this.onSelecEstabishment.subscribe((cmd: ReloadCompanyEventCmd) => {
			if(cmd.establishmentId)
				this.onSelectedEstabishment(cmd);
		});

		const curTheme = localStorage.getItem('currentTheme');
		const sideRecoil = localStorage.getItem('sideRecoil');
		const navCollapsed = localStorage.getItem('navCollapsed');

		if(curTheme === 'light' || curTheme === 'dark') {
			this._currentTheme = curTheme
		} else {
			this._currentTheme = 'light';
		}

		this._sideRecoil = sideRecoil === 'recoil' ? 'recoil' : 'no';

		var stConfig = localStorage.getItem('vivaKeyConfig');
        if (stConfig) {
            this.AppConfig = JSON.parse(stConfig);
        }
        else {
            this.AppConfig = {
                brand: 'VivaKey',
                layoutBoxed: false,               // true, false
                navCollapsed: true,              // true, false
                navBehind: false,                 // true, false
                fixedHeader: true,                // true, false
                sidebarWidth: 'small',           // small, middle, large
                theme: 'dark',                   // light, gray, dark
                colorOption: '21',                // 11,12,13,14,15,16; 21,22,23,24,25,26; 31,32,33,34,35,36
                AutoCloseMobileNav: true,         // true, false. Automatically close sidenav on route change (Mobile only)
                productLink: 'https://www.vivakey.com.br/'
            };

            this.saveConfig()
        }

	}


	private async onSelectedCompany(company){
		this.currentCompany = company;
		//this.establishmentslist = (await this.choseCompanyService.getEstablishments(company.companyId)).object;
	}

	private async onSelectedEstabishment(cmd: ReloadCompanyEventCmd) {
		console.log('change establishmentId..', cmd.establishmentId);
		this.currentEstablishmentId = cmd.establishmentId;

		//Carregar estabelecimento pelo ID, e trazer a compania junto
		var getEstb = (await this.choseCompanyService.getEstablishment(cmd.establishmentId));
		
		this.currentCompany = getEstb.company;
		this.currentEstablishment = getEstb;
		this.onCurrentEstablishmentLoaded.emit(this.currentEstablishment);

		//Selecionar Company
		console.log('this.currentCompany', this.currentCompany);
		console.log('this.currentEstablishment', this.currentEstablishment);
	}

	reloadEstablishment(): Promise<any> {
		return new Promise((resolve, reject) => {
			this.choseCompanyService.getEstablishment(this.currentEstablishmentId).then(resp => {
				this.currentEstablishment = resp;
				this.currentCompany = resp.company;
				this.onCurrentEstablishmentLoaded.emit(this.currentEstablishment);
				resolve(this.currentEstablishment);
			}, err => {
				reject();
			});
		});
	}


	asCompanyFromEstablishmentId(establishmentId, currentUrl: string = ''): Promise<boolean> {

		//Páginas que são obrigatório recarregar o estabelecimento
		var forceReload = currentUrl && (
			currentUrl.indexOf('occupation') > -1
			|| currentUrl.indexOf('portador') > -1
			|| currentUrl.indexOf('tariff') > -1
			|| currentUrl.indexOf('units') > -1
		)

		if(this.currentEstablishmentId != establishmentId)
			forceReload = true;

		return new Promise<boolean>((resolve) => {

			if(!this.currentCompany || !this.currentEstablishment || forceReload) {
				//Carregar Dados do Estabelecimento
				this.choseCompanyService.getEstablishment(establishmentId).then(resp => {
					this.currentEstablishmentId = establishmentId;
					this.currentEstablishment = resp;
					this.currentCompany = resp.company;
					this.onCurrentEstablishmentLoaded.emit(this.currentEstablishment);
					//console.log('Rota Autorizada, unidade carregada');
					//console.log('currentCompany', this.currentCompany.name + ' - ' +  this.currentEstablishment.name);
					resolve(this.hasPermission(this.currentCompany.companyId, establishmentId));	
				}, err => {
					resolve(false);
					//console.log('Rota Não Autorizada, Falha ao carregar unidade');
					this.router.navigate(['login']);	 
				});

			} else {
				resolve(this.hasPermission(this.currentCompany.companyId, establishmentId));	

				//console.log('Rota Autorizada..');
				//console.log('currentCompany', this.currentCompany.name + ' - ' +  this.currentEstablishment.name);
			}



		});
	}

	hasPermission(companyId, establishmentId) : boolean
	{
		var currentUserInfo = JSON.parse(localStorage.getItem('currentUserInfo'));
        var companyPermission = currentUserInfo.companyJobTitles.find(i=>i.companyJobTitle.company.companyId == companyId);

		if(companyPermission) {
			return true;
		} else {
			var establishmentPermission = currentUserInfo.establishmentJobTitles.find(i=>i.establishmentJobTitle.establishment.establishmentId == establishmentId);
			if(!establishmentPermission) {
			  //Não tem permissão para acessar esse estabelecimento
			  this.router.navigate(['login']);
			  return false;
			} else 
				return true;
		}
	}

	setLanguage(lang) {

		if(localStorage.getItem('currLang') == lang) {
			return;
		}

		this.translate.setDefaultLang(lang);
		this.translate.use(lang);
		localStorage.setItem('currLang', lang);
	
		if ((this.router.url.indexOf('occupation') > -1)
		  || (this.router.url.indexOf('reservations') > -1)
		  || (this.router.url.indexOf('availability') > -1)) {
		  window.location.reload();
		}
	  }

	saveConfig(){
		localStorage.setItem('vivaKeyConfig', JSON.stringify(this.AppConfig));
	}

	get currentTheme():string {
		return this._currentTheme;
	}

	set currentTheme(value: string) {
		localStorage.setItem('currentTheme', value)
		this._currentTheme = value;
	}

	get sideRecoil():string {
		return this._sideRecoil;
	}

	set sideRecoil(value: string) {
		localStorage.setItem('sideRecoil', value)
		this._sideRecoil = value;
	}
}


export class ReloadCompanyEventCmd{
	public establishmentId : any;
}