import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { ChoseCompanyService } from "../chose-company/chose-company.service";
import { ReservationsService } from "../reservations/reservations.service";
import { ReportNoPaymentsService } from "./report-reserve-no-payments.service";
import moment from "moment-timezone";
import { NotifierService } from 'angular-notifier';
import { TranslateService } from '@ngx-translate/core';
import { AppStateService } from "@src/app/layouts/app-layout.service";

const salesData = require("../../utils/datas/sales-report.json");

@Component({
    selector: "app-report-reserve-no-payments",
    templateUrl: "./report-reserve-no-payments.component.html",
    styleUrls: ["./report-reserve-no-payments.component.css"]
})
export class ReportNoPaymentsComponent implements OnInit {

    reportData: any;
    params = {};
    selectStartDate: any;
    selectFinishDate: any;
    establishment: any;
    reportMode: number = 0;
    step: any = 0;
    showFilters: boolean = false;
    emptyDateWarn: boolean = false;
    loadingData: boolean = false;

    totalGrossValue: number = 0;
    totalNetValue: number = 0;

    constructor(
        private appStateService: AppStateService,
        private router: Router,
        private notifier: NotifierService,
        private reportFinancialService: ReportNoPaymentsService,
        private translate: TranslateService,
    ) { }

    ngOnInit() {
        this.establishment = this.appStateService.currentEstablishment;

        this.selectStartDate = moment(moment().subtract("1", "months").format("YYYY-MM-DD"));
        this.selectFinishDate = moment(moment().format("YYYY-MM-DD"));
        this.filterReport();
    }

    test() {
        console.log("selectStartDate", this.selectStartDate);
    }


    // Download the excel file
    downloadReport() {

        this.loadingData = true

        this.updateParams();

        var reportParams = {
            initDate: this.selectStartDate.format('YYYY-MM-DD'),
            endDate: this.selectFinishDate.format('YYYY-MM-DD'),
            establishmentId: this.establishment.establishmentId,
            report: true
        };


        this.reportFinancialService
            .getExcelReport(reportParams)
            .subscribe(
                resp => {
                    const keys = resp.headers.keys();
                    var headers = keys.map(key => `${key}: ${resp.headers.get(key)}`);
                    console.log("headers: ", headers);
                    var reporturl =  resp.headers.get('reporturl');

                    var message = resp.body.message;
                    if (message) {
                        window.location.href = message.replace('reporturl: ', '');
                    } else {
                        this.notifier.notify('error', this.translate.instant('error_msg.error_getdata' ))
                    }
                    this.loadingData = false
                },
                error => {
                    this.loadingData = false
                    this.notifier.notify('error', this.translate.instant('error_msg.error_getdata' ))
                }
            );

        this.filterReport();
    }




    updateParams() {

        this.showFilters = false;
        //?initDate=2019-09-20&endDate=2019-10-20&categoryId=6&channelId=3&establishmentId=1
        this.params = {
            establishmentId: this.establishment.establishmentId,
            initDate: this.selectStartDate.format('YYYY-MM-DD'),
            endDate: this.selectFinishDate.format('YYYY-MM-DD'),

        };

        // if(this.channelId && this.channelId !== 0){
        //     this.params["channelId"] = this.channelId;
        //      this.showFilters = true;
        // }
    }

    filterReport = () => {
        if (!this.selectStartDate || !this.selectFinishDate) {
            // this.emptyDateWarn = true;
        } else {
            this.loadingData = true;
            this.emptyDateWarn = false;

            this.totalGrossValue = 0;
            this.totalNetValue = 0;

            this.updateParams();


            //this.reportData = salesData.object.reportData;

            this.reportFinancialService
                .filterReport(
                    this.params
                )
                .subscribe(
                    data => {
                        console.log(data);
                        switch (data.code) {
                            case 200:
                                this.reportData = data.object;
                                
                                break;
                            case 218:
                                console.log("nao possui categorias");
                                break;
                            default:
                                break;
                        }
                        this.loadingData = false;
                    },
                    error => {
                        console.log("Error");
                        this.notifier.notify('error', this.translate.instant('error_msg.error_getdata' ))
                        this.loadingData = false;
                    }
                );
        }
    };

    toggleReportMode = report => {
        this.reportMode = report;
        return this.filterReport();
    };

    closeWarningDate = () => (this.emptyDateWarn = false);
}
