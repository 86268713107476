import { Component, OnInit } from '@angular/core';
import { EditUserService } from './edit-user.service';
import { LoginService } from '@pages/login/login.service';
declare var jquery:any;
declare var $ :any;

@Component({
  selector: 'app-edit-user-form',
  templateUrl: './edit-user-form.component.html',
  styleUrls: ['./edit-user-form.component.css']
})
export class EditUserFormComponent implements OnInit {
 model : any = {};
 userInfo: any;
 loading : boolean = false;
 cpfMask : any = [/\d/, /\d/,/\d/,'.',/\d/,/\d/,/\d/,'.',/\d/, /\d/,/\d/, '-',/\d/,/\d/];
 
  constructor(private editUserService : EditUserService, private loginService : LoginService) { }

  ngOnInit() {
  	this.userInfo = JSON.parse(localStorage.getItem('currentUserInfo'));
  	this.model.name = this.userInfo.name;
  	this.model.username = this.userInfo.username;
    this.model.email = this.userInfo.email;
    this.model.phone = this.userInfo.phone;
    this.model.street = this.userInfo.street;
    this.model.neighborhood = this.userInfo.neighborhood;
    this.model.number = this.userInfo.number;
    this.model.state = this.userInfo.state;
    this.model.city = this.userInfo.city;
    this.model.complement = this.userInfo.complement;
  	this.model.zipCode = this.userInfo.zipCode;
  }
  closeEditUserInfo() {
    var that = this;
    this.loading = true;
    setTimeout(function () {
      that.loading = false;
      $("#editUserInfo").fadeOut();
    }, 1000); 
  } 

  closeEditUserInfoNoLoading(){
    this.userInfo = JSON.parse(localStorage.getItem('currentUserInfo'));
    this.model.name = this.userInfo.name;
    this.model.username = this.userInfo.username;
    this.model.email = this.userInfo.email;
    this.model.phone = this.userInfo.phone;
    this.model.street = this.userInfo.street;
    this.model.neighborhood = this.userInfo.neighborhood;
    this.model.number = this.userInfo.number;
    this.model.state = this.userInfo.state;
    this.model.city = this.userInfo.city;
    this.model.complement = this.userInfo.complement;
    this.model.zipCode = this.userInfo.zipCode;
      $("#editUserInfo").fadeOut();
  }

  editUserProfile(){
      console.log('will submit');
      this.editUserService.editUser(this.model).subscribe(data => {
       switch (data.code) {
         case 200:
             this.loginService.getUserInfo().subscribe(data =>{
               data.object.employee = {
                 ...data.object.employee,
                 establishmentJobTitles: data.object.establishmentJobTitle,
                 companyJobTitles: data.object.companyJobTitle,
               }
               localStorage.setItem('currentUserInfo', JSON.stringify(data.object.employee));
             },
            (error:any) =>{
              console.log("Error");
            });
             $('#editProfileSuccess').modal('show');
         break;
         default: 
         break;
                }
       },  
     (error:any) =>{
      console.log("Error");
    });
  }
}
