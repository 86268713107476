import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router, NavigationEnd } from '@angular/router';
import { AppStateService, ReloadCompanyEventCmd } from '../../app-layout.service';
import { LoginService } from '@src/app/pages/login/login.service';
import { DashboardMenuService } from './dashboard-menu.service';
import { MenuItems } from "./menu-items";
declare var $: any;

function MatchPathname(a, b) {
  a = a.replace(/^\/+/g, "");
  b = b.replace(/^\/+/g, "");
  return a == b;
}


@Component({
  selector: 'my-app-sidenav-menu',
  styles: [],
  templateUrl: './sidenav-menu.component.html'
})

export class AppSidenavMenuComponent implements OnInit {

  public AppConfig: any;

  linkColor: string = "#a1a1a1";
  linkColorActive: string = "#fafafa";

  lastEstablishmentId;

  menuItems: any;
  activeMenu: string = "";
  opennedMenu: string = null;
  showMenu: boolean = true;

  constructor(
    public translate: TranslateService,
    public appStateService: AppStateService,
    private loginService: LoginService,
    private dashboardMenuService: DashboardMenuService,
    private router: Router) {

    this.AppConfig = appStateService.AppConfig;

    this.router.events.subscribe(event => {

      if (event instanceof NavigationEnd) {
        //this.AppConfig = appStateService.AppConfig;
        //console.log('event.url', event.url);
      }
      else {
        return;
      }

    });


    appStateService.onCurrentEstablishmentLoaded.subscribe((resp) => {

      if (this.lastEstablishmentId == appStateService.currentEstablishmentId) {
        return;
      }

      this.lastEstablishmentId = appStateService.currentEstablishmentId;

      this.dashboardMenuService
        .getCategories(this.lastEstablishmentId)
        .subscribe(
          data => {
            switch (data.code) {
              case 200:
                let menuItems = [];
                const currentPathname = location.pathname;
                let addedActive = false;
                data.object.forEach((menuItem, index) => {
                  let menu = {
                    ...menuItem.menu,
                    location: "dashboard",
                    icon: "",
                    children: null,
                    isOpen: false
                  };

                  const _menuItem = MenuItems.find(
                    _ => _.name == menu.name
                  );
                  if (_menuItem) {
                    menu.location = `app/${this.appStateService.currentEstablishmentId}/${_menuItem.location}`;
                    menu.icon = _menuItem.icon;
                  }

                  if (
                    !addedActive &&
                    MatchPathname(
                      currentPathname,
                      menu.location
                    )
                  ) {
                    this.activeMenu = menu.name;
                    this.opennedMenu = menu.name;
                    addedActive = true;
                  }

                  if (
                    menuItem.menuItens &&
                    (menuItem.menuItens.length > 1 || menuItem.menuItens[0].name != menu.name)
                  ) {
                    let children = [];
                    menuItem.menuItens.forEach(child => {
                      const _menuItem = MenuItems.find(
                        _ => _.name == child.name
                      );
                      if (_menuItem) {
                        child.location = `app/${this.appStateService.currentEstablishmentId}/${_menuItem.location}`;

                        if (
                          !addedActive &&
                          MatchPathname(
                            currentPathname,
                            _menuItem.location
                          )
                        ) {
                          this.activeMenu = menu.name;
                          this.opennedMenu = menu.name;
                          addedActive = true;
                        }
                      }
                      children.push(child);
                    });
                    menu.children = children;
                  }

                  menuItems.push(menu);
                });

                let dashboardMenu = menuItems.find(
                  _ => _.name === "dashboard"
                );

                //console.log("dashboardMenu", dashboardMenu);

                if (
                  (this.router.url === "/dashboard" ||
                    this.router.url === "/") &&
                  !dashboardMenu
                ) {
                  this.router.navigate([`app/${this.appStateService.currentEstablishmentId}/home`]);
                }
                this.menuItems = menuItems;
                this.dashboardMenuService.menuItems = menuItems;

                //console.log("menu", this.menuItems);

                break;
              case 219:
                console.log("no permission");
                break;
            }
          },
          error => console.log("Error", error)
        );

    });

  }


  ngOnInit() {


  }

  generateMenuClass(menu) {
    return {
      active: this.activeMenu == menu.name,
      open: this.opennedMenu == menu.name
    };
  }

  toggleTheme() {
    this.AppConfig.theme =
      this.AppConfig.theme === "light" ? "dark" : "light";

    this.appStateService.saveConfig();
  }
  toggleRecoil() {
    this.appStateService.sideRecoil =
      this.appStateService.sideRecoil === "recoil" ? "no" : "recoil";
  }

  get currentTheme(): string {
    return this.appStateService.currentTheme;
  }

  clickedMenu(index) {
    const menu = this.menuItems[index];
    if (this.opennedMenu) {
      if (this.opennedMenu == menu.name) {
        this.opennedMenu = null;
        return;
      }
    }
    if (menu.children) {
      this.opennedMenu = menu.name;
    } else {
      this.activeMenu = menu.name;
      this.router.navigate([menu.location]);
    }
  }

  clickedSubMenu(menu, child) {
    if (child.location) {
      this.activeMenu = menu.name;
      this.router.navigate([child.location]);
    }
  }

  toggleMenu = () => {
    this.showMenu = !this.showMenu;
    var internalContainer = document.getElementsByClassName(
      "internal_container"
    )[0];
    if (internalContainer) {
      if (this.showMenu) {
        console.log("added class");
        internalContainer.classList.remove("min");
      } else {
        console.log("removed class");
        internalContainer.classList.add("min");
      }
    }
  };

  getMenuName(item, currentLang) {
    if (currentLang == 'en') {
      return item.enUsVisibleName;
    } else {
      return item.visibleName;
    }
  }

  openChangeCompany = () => {
    const vph = $(document).height()
    $('#changeCompany').css({ 'height': vph + 'px' });
    $("#changeCompany").fadeIn();
  }


  logout = () => this.loginService.logout();


}
