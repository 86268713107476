import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { ResponseFormat } from '@app/types/response-format'
import { AppStateService } from '@src/app/layouts/app-layout.service';

@Injectable({
  providedIn: 'root'
})
export class ConfigPaymentGatewayService {

  constructor(private http: HttpClient, private appStateService: AppStateService) { }


  getBanks = () => {
    const establishment = this.appStateService.currentEstablishment;
    return this.http.get<ResponseFormat>('vivakey/rest/bank/getBanks/' + establishment.establishmentId);
  }

  getBankPaymentType = (establishmentId, bankId, isPaymentGateway) => this.http.get<ResponseFormat>(`vivakey/rest/bankPaymentType/getBankPaymentTypes/${establishmentId}?bankId=${bankId}&isPaymentGateway=${isPaymentGateway}`)

  saveBankPaymentType = (body) => {
    if (body.bankPaymentTypeId != null)
      return this.http.post<ResponseFormat>(`vivakey/rest/bankPaymentType`, body);
    else
      return this.http.post<ResponseFormat>(`vivakey/rest/bankPaymentType`, body);

  }

  activateGateway = (establishmentId, bankId) => {
    ///establishmentConfig/establishment/{establishmentId}/paymentgateway/true/bank/{bankId}
    return this.http.put<ResponseFormat>(`vivakey/rest/establishmentConfig/establishment/${establishmentId}/paymentgateway/true/bank/${bankId}`, {});
  }


}
