import { Component, OnInit } from '@angular/core';
import { ChoseCompanyService } from '../chose-company/chose-company.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialogRef, MatDialog } from '@angular/material';
import { CreateCompanyModalComponent } from '@src/app/components/views/create-company-modal/create-company-modal.component';
import { CreateEstablishmentModalComponent } from '@src/app/components/views/create-establishment-modal/create-establishment-modal.component';
import { UsersService } from '../users/users.service';
import { AppStateService, ReloadCompanyEventCmd } from '@src/app/layouts/app-layout.service';
declare var jquery:any;
declare var $ :any;

@Component({
  selector: 'app-change-company',
  templateUrl: './change-company.component.html',
  styleUrls: ['./change-company.component.css', '../chose-company/chose-company.component.css']
})

export class ChangeCompanyComponent implements OnInit 
{
	step : any;	
	companies : any;
	newSelectedCompany : any = {};	
    newSelectedEstablishment : any = {};	
    establishments: any;
    lastCompany: any;
    lastEstablishment: any;
    currentUser: any;
    isManager: boolean = false;

    createCompanyDialogRef: MatDialogRef<CreateCompanyModalComponent>;
    createEstablishmentDialogRef: MatDialogRef<CreateEstablishmentModalComponent>;


    constructor(private choseservice : ChoseCompanyService, private router : Router,  public dialog: MatDialog, public usersService: UsersService, public appStateService: AppStateService) {

        this.appStateService.onEnterChangeCompanies.subscribe(ev => {
            this.isManager = usersService.isManager();
            this.newSelectedCompany = {};
            this.newSelectedEstablishment = {};
        });
     }

    ngOnInit() 
    {
        this.lastEstablishment = this.appStateService.currentEstablishment;
        this.lastCompany = this.appStateService.currentCompany;
        this.step = 1;
        this.newSelectedEstablishment.establishmentId = null;
        this.newSelectedCompany.companyId = null;

        this.currentUser = JSON.parse(localStorage.getItem('currentUserInfo'));
        
       this.getCompanyes();
    }


    getCompanyes(){
        this.choseservice.getCompanies().then(data => 
            { 
                switch (data['code']) 
                {
                    case 200:
                    this.companies = data['object'];
                    this.step = 1;
                    break;
                    case 218:
                    console.log('nao possui lojas');
                    this.step = 0;
                    break;
                    default:
                    break;
                }
            },
            error => console.log("Error")); 
    }

    getEstablishments(){
        this.choseservice.getEstablishments(this.newSelectedCompany.companyId).then(data => 
            {     
                switch (data['code']) 
                {
                    case 200:
                    this.establishments = data['object'];
                    break;
                    case 217:
                    this.establishments = data['object'];
                    break;
                    default:
                    break;
                }
            },  
            error => console.log("Error"));
    }
    
    selectCompany = company =>
    {
        this.newSelectedCompany = company;    
        this.getEstablishments();
        this.newSelectedEstablishment.establishmentId = null; 
    }

    selectEstablishment (establishment) {
        this.newSelectedEstablishment = establishment;
        
        setTimeout(() => {
            this.submitCompEstabChange();
        }, 300); 
    }

    closeChangeCompany = () => $("#changeCompany").fadeOut();

    goToStep2 = () => this.step = 2;


    
    openAddCompany(){

        this.createCompanyDialogRef = this.dialog.open(CreateCompanyModalComponent, {
            panelClass: 'mat-dialog-fix',
            disableClose: false,
            data : { }
        });

        //this.createCompanyDialogRef.componentInstance.title = 'Excluír?';


        this.createCompanyDialogRef.afterClosed().subscribe(result => {
            if (result === true) {
                this.getCompanyes();
            }
            this.createCompanyDialogRef = null;
        });

    }

    openAddEstablishment(){

        this.createEstablishmentDialogRef = this.dialog.open(CreateEstablishmentModalComponent, {
            panelClass: 'mat-dialog-fix',
            disableClose: false,
            data : this.newSelectedCompany
        });

        this.createEstablishmentDialogRef.componentInstance.selectedCompany = this.newSelectedCompany;

        this.createEstablishmentDialogRef.afterClosed().subscribe(result => {
            if (result === true) {
                this.getEstablishments();
            }
            this.createEstablishmentDialogRef = null;
        });

    }

    submitCompEstabChange = () => {

        this.choseservice.putEstablishment(this.newSelectedEstablishment.establishmentId).subscribe(data => {
            switch (data.code) {
                case 200:
                    {
                        sessionStorage.setItem('lastDashboardData', null);
                        sessionStorage.setItem('lastOwnerDashboardData', null);

                        this.appStateService.onSelecCompany.emit(this.newSelectedCompany);

                        var cmd = new ReloadCompanyEventCmd();
                        cmd.establishmentId = this.newSelectedEstablishment.establishmentId;
                        this.appStateService.onSelecEstabishment.emit(cmd);

                        setTimeout(() => {
                            this.router.navigate([`app/${this.newSelectedEstablishment.establishmentId}/home`]).finally(() => {
                                window.location.reload();
                            });
                        }, 500);

                        break;
                    }

                default:
                    break;
            }
        },
            error => console.log("Error"));
    }

    canGoRptReservations(companyId){
        this.router.navigate([`report/autenticco/reservations/${companyId}`]).finally(()=>{
            this.closeChangeCompany();
        });
    }


}
