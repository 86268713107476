import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, AbstractControl } from "@angular/forms";
import { AppValidator } from "../../../utils/validators/AppValidator";
import masks from "../../../utils/masks";
import { NotifierService } from 'angular-notifier'
import { Observable } from 'rxjs';
import { NatDataService } from './national-data.service';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/delay';
import { MatAutocompleteSelectedEvent } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';

const countriesOptions = require("../../../utils/datas/countries.json");

@Component({
  selector: 'app-national-data-form',
  templateUrl: './national-data-form.component.html',
  styleUrls: ['./national-data-form.component.scss']
})
export class NationalDataComponent implements OnInit {

  @Input('invoiceData') invoiceData: any;
  @Input('reservationId') reservationId: any;
  @Output() onSave: EventEmitter<any> = new EventEmitter();
  @Output() onPrevious: EventEmitter<any> = new EventEmitter();

  loading: boolean = false

  // Forms
  submitted: boolean = false;
  form0: FormGroup;

  // Masks
  masks = masks

  // UI/UX Variables
  countriesOptions = countriesOptions;

  findCitiesOfOrigin: Observable<any[]>; // async pipe needs to be an Observable
  findCitiesOfDestiny: Observable<any[]>;
  citiesContent: any[] = [];

  countries: any[] = [];
  transports: any[] = [];
  transportReasons: any[] = [];

  invalidData = false;
  validCitieOfOrigin = true;
  validCitieDestiny = true;


  constructor(private formBuilder: FormBuilder,
    private notifier: NotifierService,
    private service: NatDataService,
    private translate: TranslateService,) {

    // Form of first step
    this.form0 = this.formBuilder.group({

      transportOrig: ['', [Validators.required]],
      transportReason: ['', [Validators.required]],// motivo Transporte

      countryOfOrigin: ['', [Validators.required]],
      citieOfOrigin: new FormControl(null),

      destinyCountry: ['', [Validators.required]],
      citieDestiny: new FormControl(null),

      citieOfOriginModel: new FormControl(null),
      citieDestinyModel: new FormControl(null),
    }, { validator: this.validateDays.bind(this) });

  }

  ngOnInit() {

    this.getCountries();
    this.getTransports();
    this.getReasons();

    this.findCitiesOfOrigin = this.form0.get('citieOfOrigin').valueChanges
      .debounceTime(400)
      .do(value => {
        this.service.getCities(value).subscribe((res: any) => { this.citiesContent = res.object; });
      }).delay(500).map(() => this.citiesContent);


    this.findCitiesOfDestiny = this.form0.get('citieDestiny').valueChanges
      .debounceTime(400)
      .do(value => {
        this.service.getCities(value).subscribe((res: any) => { this.citiesContent = res.object; });
      }).delay(500).map(() => this.citiesContent);

  }

  previous() {
    this.onPrevious.emit();
  }

  validateDays(c: AbstractControl) {

    if (c.get('countryOfOrigin').value.embraturCbCountryId == 1058) {

      var citieOfOrigin = c.get('citieOfOrigin').value;

      if (citieOfOrigin && citieOfOrigin.embraturIbgeCityId) {
        return null;
      }
      else {
        return {
          validCitieOfOrigin: false
        };
      }
    }

    if (c.get('destinyCountry').value.embraturCbCountryId == 1058) {

      var citieDestiny = c.get('citieDestiny').value;
      if (citieDestiny && citieDestiny.embraturIbgeCityId) {
        return null;
      }
      else {
        return {
          validCitieDestiny: false
        };
      }

    }
    return null;
  }

  getCountries() {
    this.loading = true;
    this.service
      .getCountries('')
      .subscribe(
        data => {
          if (data.code === 200 && data.object) {
            this.countries = data.object;
          }
          this.loading = false
        },
        error => {
          this.loading = false
          this.notifier.notify('error',  this.translate.instant('error_msg.error_data_loading'))
        }
      );
  }

  getTransports() {
    this.loading = true;
    this.service
      .getTransports('')
      .subscribe(
        data => {
          if (data.code === 200 && data.object) {
            this.transports = data.object;
          }
          this.loading = false
        },
        error => {
          this.loading = false
          this.notifier.notify('error',  this.translate.instant('error_msg.error_data_loading'))
        }
      );
  }

  getReasons() {
    this.loading = true;
    this.service
      .getReasons('')
      .subscribe(
        data => {
          if (data.code === 200 && data.object) {
            this.transportReasons = data.object;
          }
          this.loading = false
        },
        error => {
          this.loading = false
          this.notifier.notify('error',  this.translate.instant('error_msg.error_data_loading'))
        }
      );
  }

  citieDisplayFn(citie): string {
    console.log(citie);
    return citie ? (citie.name + ' - ' + citie.uf) : "";
  }

  f(n) {
    return this[`form${n}`].controls
  }

  citieOfOriginOnSelect(event: MatAutocompleteSelectedEvent): void {
    this.form0.controls['citieOfOriginModel'].setValue(event.option.value)
  }

  citieDestinyOnSelect(event: MatAutocompleteSelectedEvent): void {
    this.form0.controls['citieDestinyModel'].setValue(event.option.value)
  }

  next() {
    var values = this.form0.getRawValue();
    this.invalidData = false;

    this.validCitieOfOrigin = true;
    this.validCitieDestiny = true;

    if (values.countryOfOrigin.embraturCbCountryId == 1058) {
      if (!(values.citieOfOrigin && values.citieOfOrigin.embraturIbgeCityId)) {
        this.invalidData = true;
        this.validCitieOfOrigin = false;
      }
    }

    if (values.destinyCountry.embraturCbCountryId == 1058) {
      if (!(values.citieDestiny && values.citieDestiny.embraturIbgeCityId)) {
        this.invalidData = true;
        this.validCitieDestiny = false;
      }
    }

    console.log('nationalData:', values);

    if (this.form0.valid && this.invalidData == false) {
      this.submitted = true
      var postValues = {
        "snmotvia": values.transportReason.embraturReasonId,
        "sntiptran": values.transportOrig.embraturTransportId,
        "bgstdscpais": values.countryOfOrigin.embraturCbCountryId,
        //"snidcidadeibge": values.citieOfOrigin.embraturIbgeCityId,
        "bgstdscpaisdest": values.destinyCountry.embraturCbCountryId,
        //"snidcidadeibgedest": values.citieDestiny.embraturIbgeCityId
      };

      postValues["reservationId"] = this.reservationId;

      if (values.citieOfOrigin && values.citieOfOrigin.embraturIbgeCityId) {
        postValues["snidcidadeibge"] = values.citieOfOrigin.embraturIbgeCityId;
      }

      if (values.citieDestiny && values.citieDestiny.embraturIbgeCityId) {
        postValues["snidcidadeibgedest"] = values.citieDestiny.embraturIbgeCityId;
      }

      this.save(postValues);
    }
  }

  save(postValues) {
    this.loading = true;

    this.service
      .postEmbraturData(postValues).subscribe((ret_data: any) => {

        console.log(ret_data);
        this.onSave.emit({ embratur_data: postValues });
        this.loading = false
      },
        error => {
          this.loading = false
          this.notifier.notify('error',  this.translate.instant('error_msg.error_undefined'))
        });
  }

}
