import { Directive, ElementRef, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { AppStateService } from '../app-layout.service';
declare const $ :any;

// Mobile only: automatically close sidebar on route change.
@Directive({ selector: '[myAutoCloseMobileNav]' })
export class AutoCloseMobileNavDirective implements OnInit {
  el: ElementRef;
  router: Router;
  public AppConfig: any;

  constructor(el: ElementRef, router: Router, appStateService: AppStateService) {
    this.el = el;
    this.router = router;
    this.AppConfig = appStateService.AppConfig;
  }

  ngOnInit() {
    const $body = $('#body');
    const $menuButton = $('#menuButton');

    if (this.AppConfig.AutoCloseMobileNav) {
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          setTimeout(() => {
            // console.log('NavigationEnd:', event);
            $body.removeClass('sidebar-mobile-open');
            $menuButton.empty();
            $menuButton.append('<span class="mat-button-wrapper"><i class="material-icons">menu</i></span>');

          }, 0);
        }
      });
    }

  }
}
