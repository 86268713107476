import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe'

@Component({
	selector: 'app-timepicker',
	templateUrl: './timepicker.component.html',
	styleUrls: ['./timepicker.component.css']
})
export class TimepickerComponent implements OnInit {

	@Input('value') value: string = '00:00';
	@Input('label') label: string = '';
	@Output() onChange: EventEmitter<any> = new EventEmitter();

	public mask = [/[0-2]/, /[0-9]/, ':', /[0-5]/, /[0-9]/];

	public autoCorrectedDatePipe = createAutoCorrectedDatePipe('HH:MM');

	constructor() { }

	ngOnInit() {
	}

	fireChange() {
		this.onChange.emit(this.value);
	}

}
