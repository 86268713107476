import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ResponseFormat } from '@src/app/types/response-format';
import { dataURItoBlob } from '@src/app/utils/helpers';
import { AppStateService } from '@src/app/layouts/app-layout.service';


@Injectable()
export class DependentFormService {

	public cpf: any = "127.494.616-69"; // should not be pre-setted
	public email: any; // = "carlos@carlos.com"; // should not be pre-setted
	public isForeign: boolean; // = true; // should not be pre-setted
	constructor(private http: HttpClient, private appStateService: AppStateService,) { }


	getMainGuestLog = (mainGuestId) => {
		return this.http.get<any>(`vivakey/rest/log/getlogsbymainguest/${mainGuestId}`);
	}

	getPersonData = (Id, type = "mainGuest") => {

		switch (type) {
			case 'mainGuest':
				return this.http.get<any>(`vivakey/rest/mainguest/${Id}`)
				break;

			case 'dependent':
				return this.http.get<any>(`vivakey/rest/dependent/getDependentId/${Id}`)
				break;

			case 'responsible':
				return this.http.get<any>(`vivakey/rest/responsible/${Id}`)
				break;
		
			default:
				return this.http.get<any>(`vivakey/rest/mainguest/${Id}`)
		}
	
		
	}

	getResponsibleAdreess = (responsibleId) => {
		return this.http.get<any>(`vivakey/rest/address/getByResponsibleId/${responsibleId}`)
	}

	addMainGuest = (body) => {
		var selectedEstablishment = this.appStateService.currentEstablishment;
		return this.http.post<ResponseFormat>(`vivakey/rest/mainguest/${selectedEstablishment.establishmentId}`, body)
	}

	addDependent = (mainGuestId, body) => {
		return this.http.post<ResponseFormat>(`vivakey/rest/dependent/mainguest/${mainGuestId}`, body)
	}

	saveMainGuest = (mainGuestId, body) => {
		console.log('body', body)
		var selectedEstablishment = this.appStateService.currentEstablishment;
		return this.http.put<ResponseFormat>(`vivakey/rest/mainguest/${mainGuestId}/${selectedEstablishment.establishmentId}`, body)
	}

	saveDependent = (dependentId, body) => {
		return this.http.put<ResponseFormat>(`vivakey/rest/dependent/${dependentId}`, body)
	}

	saveResponsible = (responsibleId, body) => {
		return this.http.put<ResponseFormat>(`vivakey/rest/responsible`, body)
	}

	getMediaContent(id) {
		return this.http.get(`vivakey/rest/mediacontent/${id}`);
	}

	// sendPhoto(formData) {
	// 	return this.http.post(`vivakey/rest/mediacontent`, formData);
	// }

	saveImage = (body, typeUrl) => {

		var imgBlob = dataURItoBlob(body.data);

		console.log("imgBlob: ", JSON.stringify(imgBlob));

		const formData = new FormData();
		formData.append('file', imgBlob);
		//formData.append('type', body.contentType);

		return this.http.post<ResponseFormat>(
			'vivakey/rest/mediacontent/' + typeUrl,
			formData
		)
	};

}
