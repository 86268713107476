import { Injectable } from '@angular/core';
import {Observable} from 'rxjs/Rx';
import * as moment from 'moment';
import {ResponseFormat} from '@app/types/response-format';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse  } from '@angular/common/http';


@Injectable()
export class ReportFinancialService {

  constructor(private http : HttpClient) { }

  filterReport(establishmentId, startDate, endDate, reportMode, _params){
    let params = new HttpParams();
		Object.keys(_params).map((key, index) => {
			params = params.append(key, _params[key])
    })
    
    var level = 0;

    if(reportMode === 1) {
      level = 1;
    }
      
    return this.http.get<ResponseFormat>('vivakey/rest/payment/getCashFlow/report/'+establishmentId+'/'+startDate+'T00:00:00.000-0700/'+endDate+'T00:00:00.000-0700/'+level, {
			params: params
		})
  }

  getExcelReport = (establishmentId, initialDate, finalDate, level, _params) => {
		let params = new HttpParams();
		Object.keys(_params).map((key, index) => {
			params = params.append(key, _params[key])
    })
		return this.http.get<ResponseFormat>(`vivakey/rest/payment/getCashFlow/exportReport/${establishmentId}/${initialDate}/${finalDate}/${level}`, {
			params: params
		})
	}

  getPaymentInfo = groupId => this.http.get<ResponseFormat>('vivakey/rest/payment/' + groupId)
  

  sendReverse = (groupPaymentId, reversalReason, instalments, extras) =>
    {
        const body = {
            groupPaymentId: groupPaymentId,
            reason: reversalReason,
            paymentReversalInstalmentsRequests: instalments,
            paymentReversalAdditionalFeesRequests: extras
        };

        return this.http.put<ResponseFormat>('vivakey/rest/payment/reversal', body);
    }

    saveConciliation(body){
      return this.http.post<ResponseFormat>('vivakey/rest/receivables/payments', body);
    }

    editExpectedDate(data){
      return this.http.put<ResponseFormat>(`vivakey/rest/payment/estimate`, data);
    }

}
