import { Component, OnInit } from '@angular/core';
import { TipoPagamentoService } from './tipo-pagamento.service';
import { Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { TranslateService } from '@ngx-translate/core';
declare var $ :any;

@Component({
    selector: 'app-tipo-pagamento',
    templateUrl: './tipo-pagamento.component.html',
    styleUrls: [
        './tipo-pagamento.component.css'
    ]
})

export class TipoPagamentoComponent implements OnInit 
{
	paymentWayModel : any = {};
    listaTiposPagamento : any = [];
    listaTiposDeTipoesDePagamento : any = [];
    deletable : any = {};
    readOnly : boolean = false;
    filtro : any = {};
    portador: any;

    constructor(
        private router: Router, 
        private tipoPagamentoService: TipoPagamentoService,
        private notifier: NotifierService,
        private translate: TranslateService,
    ) { }

    ngOnInit() 
    {
        this.portador = JSON.parse(sessionStorage.getItem('currentPortador'));
        this.filtro.paymentType = "todos";
        this.filtro.status = "todos";
        this.getPaymentWays();
        this.getPaymentWaysTypes();
    }

    openAddForm = () =>
    {
        this.readOnly = false;
        this.paymentWayModel = {};
        $('#createPaymentWayModal').modal('show');
    }

    openEditForm = elementPosition =>
    {
        this.readOnly = false;
        
        const copy = Object.assign({}, this.listaTiposPagamento[elementPosition]);

        if (copy.status == "ACTIVE")
            copy.situacao = 1;
        else
            copy.situacao = 0; 
        
        for (let i = this.listaTiposDeTipoesDePagamento.length - 1; i >= 0; i--) 
        {
            if  (this.listaTiposDeTipoesDePagamento[i].paymentTypeId == copy.paymentType.paymentTypeId)
                copy.paymentType = this.listaTiposDeTipoesDePagamento[i];
        }

        this.paymentWayModel = copy; 
        
        if (this.paymentWayModel.cashFlow == "YES")
            this.paymentWayModel.cashFlow = true;
        else 
            this.paymentWayModel.cashFlow = false;

        $('#createPaymentWayModal').modal('show');
    }

    showDetails = elementPosition =>
    {
        this.readOnly = true;
        const copy = Object.assign({},this.listaTiposPagamento[elementPosition]);
        
        if (copy.status == "ACTIVE")
            copy.situacao = 1;
        else
            copy.situacao = 0; 
        
        for (let i = this.listaTiposDeTipoesDePagamento.length - 1; i >= 0; i--) 
        {
            if  (this.listaTiposDeTipoesDePagamento[i].paymentTypeId == copy.paymentType.paymentTypeId)
                copy.paymentType = this.listaTiposDeTipoesDePagamento[i];
        }

        this.paymentWayModel = copy; 
        
        if (this.paymentWayModel.cashFlow == "YES")
            this.paymentWayModel.cashFlow = true;
        else 
            this.paymentWayModel.cashFlow = false;
        
        $('#createPaymentWayModal').modal('show');
    }

    goToPaymentWay = () => this.router.navigate(['tipo-pagamento']);

    toDelete = elementPosition => this.deletable = Object.assign({}, this.listaTiposPagamento[elementPosition]); 

    getPaymentWays = () => 
    {
        this.tipoPagamentoService.getPaymentWays(this.filtro).subscribe(data => 
        {
            console.log("PaymentsWays", data);
            switch (data.code) 
            {
                case 200:
                    for (let i = data.object.length - 1; i >= 0; i--) 
                    {
                        if (data.object[i].status == 'ACTIVE')
                            data.object[i].statusPush = true;
                        else
                            data.object[i].statusPush = false;

                        if(data.object[i].brand != 'NONE'){
                            data.object[i].xType = data.object[i].paymentType.name + ' - ' + data.object[i].brand;
                        }
                        else {
                            data.object[i].xType = data.object[i].paymentType.name;
                        }
                        
                    }
                    this.listaTiposPagamento = data.object;
                    console.log('listaTiposPagamento', this.listaTiposPagamento);
                    break;
            }
        }, error => console.log("Error", error));
    }

    getPaymentWaysTypes = () =>
    {
        this.tipoPagamentoService.getPaymentWaysTypes().subscribe(data => 
        {
            switch (data.code) 
            {
                case 200:
                    this.listaTiposDeTipoesDePagamento = data.object;
                    console.log('listaTiposDeTipoesDePagamento', this.listaTiposDeTipoesDePagamento);
                    break;
            }
        }, error => console.log("Error", error));
    }

    savePaymentWay = () =>
    {
        var errors = [];
        if(this.paymentWayModel.paymentType.taxes == true ){

            if( this.paymentWayModel.brand == null || this.paymentWayModel.brand == 'NONE'){
                errors.push(this.translate.instant('portador.select_brand'));
            }
               
            if((this.paymentWayModel.cardTax || 0) <= 0){
                errors.push(this.translate.instant('portador.enter_tax'));
            }

            if(this.paymentWayModel.paymentType.agreesInstalment == true) {
                
                if((this.paymentWayModel.cardTax2to6 || 0) <= 0){
                    errors.push(this.translate.instant('portador.enter_tax_2to6'));
                }

                if((this.paymentWayModel.cardTax7to12 || 0) <= 0){
                    errors.push(this.translate.instant('portador.enter_tax_7to12'));
                }
            }

            if(this.paymentWayModel.numberDaysForPayment <= 0  || this.paymentWayModel.numberDaysForPayment == null ){
                errors.push(this.translate.instant('portador.enter_days_payment'));
            }
        }   

        if(errors.length > 0){
            errors.forEach(err => {
                this.notifier.notify('error', err);
            });
            return;
        }

        console.log(this.paymentWayModel);
        this.tipoPagamentoService.savePaymentWay(this.paymentWayModel).subscribe(data => 
        {        
            switch (data.code) 
            {
                case 200:
                    console.log('sucesso', data);
                    this.getPaymentWays();
                    if (this.paymentWayModel.bankPaymentTypeId) 
                    { 
                        $('#createPaymentWayModal').modal('hide')
                        setTimeout(() => $('#modalEditarSucesso').modal('show'), 500);
                    } 
                    else 
                    {
                        $('#createPaymentWayModal').modal('hide')
                        setTimeout(() => $('#modalCadastroSucesso').modal('show'), 500);
                    }
                    break;
            }
        }, error => console.log("Error", error));
    }

    deletePaymentWay = () =>
    {
        this.tipoPagamentoService.deletePaymentWay(this.deletable.bankPaymentTypeId).subscribe(data => 
        {
            console.log(data);
            switch (data.code) 
            {
                case 200:
                    this.getPaymentWays();
                    $('#modalConfirmDelete').modal('hide')
                    setTimeout(() => $('#modalDeleteSucesso').modal('show'), 500);
                    break;
                case 246:
                    $('#modalConfirmDelete').modal('hide')
                    setTimeout(() => $('#modalDeleteError').modal('show'), 500);
                    break;
            }
        }, error => console.log("Error", error));  
    }

    pushedButton = elementPosition =>
    {
        const copy = Object.assign({}, this.listaTiposPagamento[elementPosition]);
        copy.situacao = 0;
        console.log(copy);
        
        if (copy.statusPush)
            copy.situacao = 1;  
        
        this.tipoPagamentoService.savePaymentWay(copy).subscribe(data => 
        {
            console.log(data.object);
            switch (data.code) 
            {
                case 200:
                    this.getPaymentWays();
                    break;
            }
        }, error => console.log("Error", error));
    }
}
