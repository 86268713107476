import { Component, OnInit } from "@angular/core";
import { TariffService } from "./tariff.service";
import { LoginService } from "../login/login.service";
import { IMyDrpOptions } from "mydaterangepicker";
import {
    IMyDpOptions,
    IMyDefaultMonth,
    IMyDateModel,
    IMyDate
} from "mydatepicker";
import { ReservationsService } from "../reservations/reservations.service";
import * as moment from "moment";
import { strLimit } from "@utils/helpers";
import { TranslateService } from '@ngx-translate/core';
import { MatDialogRef, MatDialog } from '@angular/material';
import { SetAutomaticCalcModalComponent } from '@src/app/components/views/set-automatic-calc/set-automatic-calc-modal.component';
import { NotifierService } from 'angular-notifier';
import Swal from 'sweetalert2';
import { getAutomaticCalcInfo } from '@src/app/utils/translators';
import { HotelSettingsPageService } from '../hotel-settings/hotel-settings-page.service';
import { AppStateService } from "@src/app/layouts/app-layout.service";

declare var jquery: any;
declare var $: any;

interface Category {
    name: string;
    categoryId: number;
}

interface Channel {
    name: string;
    channelId: number;
}

class autCalcItem{
    automaticTariff: boolean;
    categoryId: number;
    channelId: number;
}

@Component({
    selector: "app-tariff",
    templateUrl: "./tariff.component.html",
    styleUrls: ["./tariff.component.css"]
})
export class TariffComponent implements OnInit {

    selectedEstablishment: any;
    loading: boolean = false;
    hasTariff: boolean = false;
    addTariff: boolean = false;
    noTariffs: boolean = false;
    categories: Category[] = [];
    categoriesAdd: Category[] = [];
    range: any = [];
    selectCategoria = 0;
    tariffs: any = null;
    tariffsShow: any = [];
    daysToShow: any = [];
    selectStartDate: any;
    selectFinishtDate: any;
    establishment: any;
    channelList: any = [];
    channels: Channel[] = [];
    selectChannel = 0;
    strLimit = strLimit

    editingChannel: any;
    compareEditingChannel: any;

    selectedCategory: any;
    disabled: boolean = true;
    calendaries: any = [1, 2, 3];

    dayLabels;
    monthLabels;
    monthL_labels;
    myDateRangePickerOptions: IMyDrpOptions;

    myDatePickerOptions: any = [];
    defaultMonth: any = [];
    public model: any = "";
    public datePickerModel: any = [{}];
    today: IMyDate = {
        day: new Date().getDate() - 1,
        month: new Date().getMonth() + 1,
        year: new Date().getFullYear()
    };

    public automaticCalculationList: autCalcItem[] = [];
    setAutomaticCalcModalDialogRef: MatDialogRef<SetAutomaticCalcModalComponent>;
   
    constructor(
        private appStateService: AppStateService,
        private translate: TranslateService,
        public dialog: MatDialog,
        private notifier: NotifierService,
        private tariffService: TariffService,
        private reservationsService: ReservationsService,
        private loginservice: LoginService,
        private hotelSettingsService: HotelSettingsPageService,
    ) {
        this.selectedEstablishment = this.appStateService.currentEstablishment;

        this.dayLabels = {
            su: this.translate.instant('days_of_week.su'),
            mo: this.translate.instant('days_of_week.mo'),
            tu: this.translate.instant('days_of_week.tu'),
            we: this.translate.instant('days_of_week.we'),
            th: this.translate.instant('days_of_week.th'),
            fr: this.translate.instant('days_of_week.fr'),
            sa: this.translate.instant('days_of_week.sa')
        }
    
        this.monthLabels = {
            1: this.translate.instant('months.jan'),
            2: this.translate.instant('months.fev'),
            3: this.translate.instant('months.mar'),
            4: this.translate.instant('months.abr'),
            5: this.translate.instant('months.mai'),
            6: this.translate.instant('months.jun'),
            7: this.translate.instant('months.jul'),
            8: this.translate.instant('months.ago'),
            9: this.translate.instant('months.set'),
            10: this.translate.instant('months.out'),
            11: this.translate.instant('months.nov'),
            12: this.translate.instant('months.dez')
        }
    
        this.monthL_labels = {
            1: this.translate.instant('monthsL.Jan' ),
            2: this.translate.instant('monthsL.fev' ),
            3: this.translate.instant('monthsL.mar' ),
            4: this.translate.instant('monthsL.abr' ),
            5: this.translate.instant('monthsL.mai' ),
            6: this.translate.instant('monthsL.jun' ),
            7: this.translate.instant('monthsL.jul' ),
            8: this.translate.instant('monthsL.ago' ),
            9: this.translate.instant('monthsL.set' ),
            10: this.translate.instant('monthsL.out' ),
            11: this.translate.instant('monthsL.nov' ),
            12: this.translate.instant('monthsL.dez' )
        };
    
        this.myDateRangePickerOptions = {
            dateFormat: "dd/mm/yyyy",
            inline: true,
            showApplyBtn: false,
            selectBeginDateTxt: this.translate.instant('select_initial_date'),
            selectEndDateTxt: this.translate.instant('select_final_date'),
            sunHighlight: false,
            disableUntil: {
                day: new Date().getDate() - 1,
                month: new Date().getMonth() + 1,
                year: new Date().getFullYear()
            },
            dayLabels: this.dayLabels,
            monthLabels: this.monthLabels
        };

        this.selectCategoria = 0;
        this.selectedCategory = {
            name: this.translate.instant('select_category'),
            categoryId: 0
        };

    }

    ngOnInit() {
        
        this.selectChannel = 0;
        this.selectStartDate = moment();
        this.selectFinishtDate = moment().add( "days", 5);

        // inicia com 3 calendarios
        for (let i = 2; i >= 0; i--) {
            this.myDatePickerOptions.push({
                dateFormat: "dd/mm/yyyy",
                inline: true,
                monthSelector: false,
                yearSelector: false,
                showTodayBtn: false,
                sunHighlight: false,
                // disableUntil: this.today,
                dayLabels: this.dayLabels,
                monthLabels: this.monthL_labels
            });
        }

        this.establishment = this.appStateService.currentEstablishment;

        this.reservationsService
            .getCategories(this.establishment.establishmentId)
            .subscribe(
                data => {
                    switch (data.code) {
                        case 200:
                            for (let i = data.object.length - 1; i >= 0; i--) {
                                this.categories.push(data.object[i]);
                                this.categoriesAdd.push(data.object[i]);
                            }
                            break;
                    }
                },
                error => {
                    console.log("Error", error);
                }
            );

        this.reservationsService
            .getChannels(this.establishment.establishmentId)
            .subscribe(
                data => {
                    switch (data.code) {
                        case 200:
                            this.channelList = data.object;
                            for (let i = data.object.length - 1; i >= 0; i--) {
                                this.channels.push(data.object[i]);
                            }
                            break;
                    }
                },
                error => console.log("Error", error)
            );

        this.defaultMonth = [
            moment().format("MM/YYYY"),
            moment()
                .add(1, "month")
                .format("MM/YYYY"),
            moment()
                .add(2, "month")
                .format("MM/YYYY")
        ];



        const params = {
            startDate: this.selectStartDate.format("YYYY-MM-DD"),
            quantityDays: 30,
            category: this.selectCategoria,
            channel: this.selectChannel
        };

        this.reservationsService.getReservations(params).subscribe(
            data => {
                console.log(data.object);
                switch (data.code) {
                    case 200:
                        //percorre o retorno do servico de reservas
                        for (let i = data.object.length - 1; i >= 0; i--) {
                            //adicionar um array com os dias a serem exibidos  (linha na frente do nome da categoria, com tarifarios)
                            data.object[
                                i
                            ].daysToShow = this.reservationsService.generateDaysToShow(
                                30,
                                false
                            );

                            //percorre cada uma das tarifas
                            data.object[
                                i
                            ].daysToShow = this.reservationsService.getDayTariffary(
                                data.object[i].daysToShow,
                                data.object[i].tariffs
                            );
                        }
                        this.tariffsShow = data.object;
                        console.log(this.tariffsShow);
                        break;
                    case 219:
                        console.log("no permission");
                        break;
                }
            },
            error => console.log("Error")
        );
    }


    hasEditingField(day: any, fieldName: string, index): boolean{
        if(day[fieldName] != this.compareEditingChannel.days[index][fieldName]){
            return true;
        }else{
            return false;
        }
    }

    isDifferentValue(day: any, days: any, fieldName: string){
        var qtdDif:number = 0;
        days.forEach(d => {
            if(d[fieldName] != day[fieldName]){
                // if(fieldName == 'discountPriceValue'){
                //     if(d.discountPriceType == day.discountPriceType)
                //     {
                //         qtdDif++;
                //     }
                // }else {
                //     qtdDif++;
                // }
                qtdDif++;
            }
        });
        return qtdDif > (days.length / 3);
    }

    openAddTariff = () => {
        this.addTariff = true;
        this.selectedCategory = {};
        this.model = "";
        console.log(this.model);

        this.appStateService.reloadEstablishment().then(establishment=> {
            this.selectedEstablishment = establishment;
            this.getAutomaticTariffs_ForAddTariff(); 
        });

    };

    getAutomaticTariffs_ForAddTariff(){
        this.loading = true;
        this.tariffService
            .getAutomaticTariffs(this.establishment.establishmentId)
            .subscribe(data => {
                this.loading = false;
                this.automaticCalculationList = [];
                var list = [];
                if(data.object){
                    data.object.forEach(element => {
                        var it = new autCalcItem();
                        it.automaticTariff = element.automaticTariff;
                        it.categoryId = element.category.categoryId;
                        it.channelId = element.channel.channelId;
                        list.push(it);
                    });
                }           
                console.log('this.automaticCalculationList ', list );
                this.automaticCalculationList = list;
                $("#modalSelectDate").modal("show");

            },error => {
                this.loading = false;
            });

    }

    closeAddModal = () => {
        $("#modalSelectDate").modal("hide");
        this.iterateDateRange();
        this.makeConfirmationCalendars();
    };

    searchTariffary = startDate => {
        console.log(this.selectStartDate);
        console.log(this.selectFinishtDate);

        const dataInicio =
            this.selectStartDate.format("YYYY-MM-DD") + "T00:00:00.000-0300";

        const dataFim = this.selectFinishtDate
            ? this.selectFinishtDate.format("YYYY-MM-DD") + "T00:00:00.000-0300"
            : moment(startDate)
                  .add(18, "month")
                  .format("YYYY-MM-DD") + "T00:00:00.000-0300";

        this.loading = true;
        this.tariffService
            .getTariffary(
                this.establishment.establishmentId,
                dataInicio,
                dataFim,
                this.selectChannel,
                this.selectCategoria
            )
            .subscribe(
                data => {
                    console.log(data);
                    switch (data.code) {
                        case 200:
                            let dateAux;
                            this.noTariffs = data.object.length == 0;
                            for (let i = 0; i < data.object.length; i++) {
                                for (
                                    let j = 0;
                                    j < data.object[i].days.length;
                                    j++
                                ) {
                                    dateAux = moment(
                                        data.object[i].days[j].date
                                    )
                                        .locale(this.translate.currentLang.replace('_', '-'))
                                        .format("DD-MMMM-dddd")
                                        .split("-");
                                    data.object[i].days[j].day = dateAux[0];
                                    data.object[i].days[
                                        j
                                    ].month = dateAux[1].substring(0, 3);
                                    data.object[i].days[
                                        j
                                    ].week = dateAux[2].substring(0, 3);
                                }
                            }
                            this.tariffs = data.object;
                            break;
                    }
                    this.loading = false;
                },
                error => {
                    this.loading = false;
                    console.log("Error");
                }
            );
    };

    getTariffary = (startDate, finalDate) => {
        const dataInicio = startDate + "T00:00:00.000-0300";
        const dataFinal = finalDate + "T00:00:00.000-0300";

        console.log(
            "this.establishment.establishmentId",
            this.establishment.establishmentId,
            "dataInicio",
            dataInicio,
            "dataFinal",
            dataFinal,
            "this.selectCategoria",
            this.selectCategoria,
            "this.selectChannel",
            this.selectChannel
        );

        this.loading = true;
        this.tariffService
            .getTariffary(
                this.establishment.establishmentId,
                dataInicio,
                dataFinal,
                this.selectChannel,
                this.selectCategoria
            )
            .subscribe(
                data => {
                    console.log(data);
                    switch (data.code) {
                        case 200:
                            this.noTariffs = data.object.length == 0;
                            let dateAux;
                            for (let i = 0; i < data.object.length; i++) {
                                for (
                                    let j = 0;
                                    j < data.object[i].days.length;
                                    j++
                                ) {
                                    if(data.object[i].days[j].discountPriceType == null){
                                        data.object[i].days[j].discountPriceType = 'DISCOUNTTYPE_PERCENTUAL';
                                    }
                                    
                                    dateAux = moment(
                                        data.object[i].days[j].date
                                    )
                                        .locale(this.translate.currentLang.replace('_', '-'))
                                        .format("DD-MMMM-dddd")
                                        .split("-");
                                    data.object[i].days[j].day = dateAux[0];
                                    data.object[i].days[
                                        j
                                    ].month = dateAux[1].substring(0, 3);
                                    data.object[i].days[
                                        j
                                    ].week = dateAux[2].substring(0, 3);
                                }
                            }
                            this.tariffs = data.object;
                            break;
                    }
                    this.loading = false;
                },
                error => {
                    this.loading = false;
                    console.log("Error");
                }
            );
    };

    makeConfirmationCalendars = () => {
        this.calendaries.length = 0;
        this.myDatePickerOptions.length = 0;
        this.defaultMonth.length = 0;
        const startDate =
            this.model.beginDate.year + "-" + this.model.beginDate.month;
        const finishDate =
            this.model.endDate.year + "-" + this.model.endDate.month;
        const startDateMoment = moment(startDate);
        const finishDateMoment = moment(finishDate);
        let index = 0;

        while (finishDateMoment >= startDateMoment) {
            const dateAux = startDateMoment.format("MM/YYYY");
            const date = dateAux.split("/");
            this.calendaries.push(1);
            this.myDatePickerOptions.push({
                dateFormat: "dd/mm/yyyy",
                inline: true,
                monthSelector: false,
                yearSelector: false,
                showTodayBtn: false,
                disableSince: this.range[index][this.range[index].length - 1],
                disableUntil: this.range[index][0],
                sunHighlight: false,
                markDates: [
                    { dates: this.range[index], color: "rgb(232, 91, 166)" }
                ],
                dayLabels: this.dayLabels,
                monthLabels: this.monthL_labels
            });

            this.defaultMonth.push(dateAux);
            startDateMoment.add(1, "month");
            index++;
        }
    };

    iterateDateRange = () => {
        const startDate =
            this.model.beginDate.year +
            "-" +
            this.model.beginDate.month +
            "-" +
            this.model.beginDate.day;
        const finishDate =
            this.model.endDate.year +
            "-" +
            this.model.endDate.month +
            "-" +
            this.model.endDate.day;
        this.range = [];
        const startDateMoment = moment(startDate);
        const finishDateMoment = moment(finishDate).add(1, "day");
        const auxRange = [];

        let index = 0;
        let currentMonth = this.model.beginDate.month;

        while (finishDateMoment > startDateMoment) {
            const dateAux = startDateMoment
                .format("YYYY-M-DD-MM-YY")
                .split("-");

            if (parseInt(dateAux[3]) != currentMonth) {
                currentMonth = parseInt(dateAux[3]);
                index++;
            }
            if (!this.range[index]) this.range[index] = [];

            this.range[index].push({
                year: parseInt(dateAux[0]),
                month: parseInt(dateAux[3]),
                day: parseInt(dateAux[2])
            });
            startDateMoment.add(1, "day");
        }
    };

    openChoseChannelModal = () => {
        for (let i = this.channelList.length - 1; i >= 0; i--) {
            var ch = this.channelList[i];
            ch.selected = false;

            var automatic = this.automaticCalculationList.find(i=>i.categoryId == this.selectedCategory.categoryId && i.channelId == ch.channelId) ;
            if(automatic != null
                && automatic.automaticTariff == true
                && this.selectedEstablishment.automaticCalculation == true){
                ch.automaticTariff = true;
                ch.value = null;
                ch.baseValue = 0;
                ch.discountPriceValue = 0;
                ch.lastMinuteValue = 0;
                ch.discountPriceType = 'DISCOUNTTYPE_VALUE';
            }else {
                ch.automaticTariff = false;
                ch.value = 0;
                ch.baseValue = null;
                ch.discountPriceValue = null;
                ch.lastMinuteValue = null;
                ch.discountPriceType = null;
            }
            
        }
        $("#choseChannelModal").modal("show");
    };

    openSetAutomaticCalcModal(tariff){

        if(tariff.automaticTariff == true){
            this.setAutomaticCalcModalDialogRef = this.dialog.open(SetAutomaticCalcModalComponent, {
                panelClass: 'mat-dialog-small',
                disableClose: false,
                data : { tariff: tariff }
              });
    
            this.setAutomaticCalcModalDialogRef.afterClosed().subscribe(result => {
                if(result === true){
                    this.searchTariffary(null);
                }
                else{
                    tariff.automaticTariff = false;
                }
                this.setAutomaticCalcModalDialogRef = null;
            });

        }
        else {

            this.loading = true;
            this.tariffService.disableAutomaticCalc(tariff.channel.channelId, tariff.category.categoryId, this.selectedEstablishment.establishmentId,)
            .subscribe((data) => {
                this.loading = false
                this.notifier.notify('success', this.translate.instant('set_automatic_calc.disabled_successfully'));
         
            }, error => {
                this.loading = false
                this.notifier.notify('error', this.translate.instant('set_automatic_calc.error_saving'))
            });

        }
    }

    sendTariff = () => {
        const selectedChannels = [];
        this.loading = true;
        for (let i = this.channelList.length - 1; i >= 0; i--) {
            var ch = this.channelList[i];
            if (ch.selected == true) {
                if (ch.automaticTariff == true) {
                    selectedChannels.push({
                        channelId: ch.channelId,
                        baseValue: parseFloat(ch.baseValue).toFixed(2),
                        discountPriceValue: parseFloat(ch.discountPriceValue).toFixed(2),
                        lastMinuteValue: parseFloat(ch.lastMinuteValue).toFixed(2),
                        discountPriceType: ch.discountPriceType
                    });
                } else {
                    selectedChannels.push({
                        channelId: ch.channelId,
                        value: parseFloat(ch.value).toFixed(2)
                    });
                }

            }
        }

        const initialDate =
            this.model.beginDate.year +
            "-" +
            ("0" + this.model.beginDate.month).slice(-2) +
            "-" +
            ("0" + this.model.beginDate.day).slice(-2) +
            "T00:00:00.000-0300";
        const finalDate =
            this.model.endDate.year +
            "-" +
            ("0" + this.model.endDate.month).slice(-2) +
            "-" +
            ("0" + this.model.endDate.day).slice(-2) +
            "T00:00:00.000-0300";
        const request = {
            initialDate: initialDate,
            finalDate: finalDate,
            categoryId: this.selectedCategory.categoryId,
            channelValues: selectedChannels,
            daysOfWeek: [1, 2, 3, 4, 5, 6, 7]
        };

        console.log("request", request);

        this.tariffService.sendTariff(request).subscribe(
            data => {
                console.log(data);
                switch (data.code) {
                    case 200:
                        this.getTariffary(
                            this.selectStartDate.format("YYYY-MM-DD"),
                            this.selectFinishtDate.format("YYYY-MM-DD")
                        );
                        this.cancelAddTariff();
                        $("#choseChannelModal").modal("hide");
                        setTimeout(
                            () => $("#modalCreateTariffSucess").modal("show"),
                            500
                        );
                        break;
                }
                this.loading = false;
            },
            error => {
                console.log("Error", error);
                this.loading = false;
            }
        );
    };

    cancelAddTariff = () => (this.addTariff = false);

    editChannel = tarifario => {
        this.editingChannel = tarifario;
        this.compareEditingChannel = JSON.parse(JSON.stringify(tarifario));
        $("#editChannel").modal("show");
    };


    
    confirmSendChannelbukly(tarifario) {
        const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn'
        },
        buttonsStyling: false
        })

        swalWithBootstrapButtons.fire({
        position: 'center',
        title: this.translate.instant('tariffary.send_bukly_confirm'),
        text: this.translate.instant('occupation.confirm_checkin_checkout_info'),
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: this.translate.instant('yes'),
        cancelButtonText: this.translate.instant('no'),
        reverseButtons: true
        }).then((result) => {
        if (result.value) {   
            this.sendChannelbukly(tarifario);
        } else if (result.dismiss === Swal.DismissReason.cancel) {

        }
        });
    }


    sendChannelbukly = tarifario => {
        this.loading = true;
        this.tariffService.sendTariffToBukly(tarifario.category.categoryId, tarifario.channel.channelId).subscribe(resp=>{
            this.loading = false;
            switch (resp.code) {
                case 200:
                    this.notifier.notify('success', this.translate.instant('tariffary.send_bukly_ok_message'));
                    break;
                case 422:
                    this.notifier.notify('error', resp.message);
                    break;
                default:
                    break;
            }
        }, err => {
            this.loading = false;
            if(err.error && err.error.code == 422 && err.error.message){
                this.notifier.notify('error', err.error.message);
            }
        });
    };

    tariffsByDays: any;
    editTariffsByDays () {
        this.tariffsByDays = {
            categoryId: this.editingChannel.category.categoryId,
            channelId: this.editingChannel.channel.channelId,
            // sundayPrice: 0,
            // mondayPrice: 0,
            // tuesdayPrice: 0,
            // wednesdayPrice: 0,
            // thursdayPrice: 0,
            // fridayPrice: 0,
            // saturdayPrice: 0,
            initDate: moment(),
            endDate: null
        };

        $("#editChannel").modal("hide");

        $("#editChannel_LargeScaleModal").modal("show");
    };

    saveTariffsByDays() {

        var body = {
            categoryId: this.tariffsByDays.categoryId,
            channelId: this.tariffsByDays.channelId,
            initDate: moment(this.tariffsByDays.initDate).format("YYYY-MM-DD")
        }

        if (this.tariffsByDays.endDate)
            body["endDate"] =  moment(this.tariffsByDays.endDate).format("YYYY-MM-DD");

        if (this.tariffsByDays.sundayPrice)
            body["sundayPrice"] = this.tariffsByDays.sundayPrice;

        if (this.tariffsByDays.mondayPrice)
            body["mondayPrice"] = this.tariffsByDays.mondayPrice;

        if (this.tariffsByDays.tuesdayPrice)
            body["tuesdayPrice"] = this.tariffsByDays.tuesdayPrice;

        if (this.tariffsByDays.wednesdayPrice)
            body["wednesdayPrice"] = this.tariffsByDays.wednesdayPrice;

        if (this.tariffsByDays.thursdayPrice)
            body["thursdayPrice"] = this.tariffsByDays.thursdayPrice;

        if (this.tariffsByDays.fridayPrice)
            body["fridayPrice"] = this.tariffsByDays.fridayPrice;

        if (this.tariffsByDays.saturdayPrice)
            body["saturdayPrice"] = this.tariffsByDays.saturdayPrice;
  


        this.loading = true;
        this.tariffService.saveTariffsByDays(body).subscribe(
            data => {
                console.log(data);
                switch (data.code) {
                    case 200:
                        
                        $("#editChannel_LargeScaleModal").modal("hide");
                        this.searchTariffary(null);
                        break;
                    default:
                        this.loading = false;
                        break;
                }
            },
            error => {
                this.loading = false;
                if(error.error) {
                    var data = error.error;
                    if(data.message == 'tariff_not_continuous') {
                        Swal.fire("O'ops", this.translate.instant('tariffary.tariff_not_continuous') );
                    }
                    else if(data.code == 422 && data.message) {
                        this.notifier.notify('error', data.message);
                    }
                }

                console.log("Error", error);
            }
        );
    }

    editDay = day => (day.isEditing = true);

    sendEditedChannel = channel => {
        this.loading = true;

        const editingDays = [];

        channel.days.forEach(day => {
            
        });

        for (let i = channel.days.length - 1; i >= 0; i--) {

            var day = channel.days[i];
            var dayCompare = this.compareEditingChannel.days[i];

            if(channel.automaticTariff == true && channel.hasEstablishmentAutomaticCalculation == true){

                if(day.manual == false
                    && ( 
                       day.baseValue != dayCompare.baseValue
                    || day.lastMinuteValue != dayCompare.lastMinuteValue
                    || day.discountPriceValue != dayCompare.discountPriceValue
                    || day.discountPriceType != dayCompare.discountPriceType
                    || day.lastMinuteActive != dayCompare.lastMinuteActive
                    || day.manual != dayCompare.manual)){
    
                    editingDays.push({
                        date: day.date,
                        baseValue: parseFloat(day.baseValue).toFixed(2),
                        lastMinuteValue: parseFloat(day.lastMinuteValue).toFixed(2),
                        discountPriceValue: parseFloat(day.discountPriceValue).toFixed(2),
                        discountPriceType: day.discountPriceType,
                        lastMinuteActive: day.lastMinuteActive,
                        manual: false
                    });
                }

                else if(day.manual == true
                    && (day.price != dayCompare.price || day.manual != dayCompare.manual) ){
    
                    editingDays.push({
                        date: day.date,
                        price: parseFloat(day.price).toFixed(2),
                        manual: true
                    });
                }



            }
            else{

                if(day.price != dayCompare.price){
    
                    editingDays.push({
                        date: day.date,
                        price: parseFloat(day.price).toFixed(2),
                    });
                }

            }
           

        }

        const body = {
            categoryId: channel.category.categoryId,
            channelId: channel.channel.channelId,
            days: editingDays
        };

        console.log("body", body);

        this.tariffService.editTariff(body).subscribe(
            data => {
                console.log(data);
                switch (data.code) {
                    case 200:
                        this.getTariffary(
                            this.selectStartDate.format("YYYY-MM-DD"),
                            this.selectFinishtDate.format("YYYY-MM-DD")
                        );
                        $("#editChannel").modal("hide");
                        setTimeout(
                            () => $("#modalEditTariffSucess").modal("show"),
                            500
                        );
                        break;
                    default:
                        this.loading = false;
                        break;
                }
            },
            error => {
                this.loading = false;
                console.log("Error", error);
            }
        );
    };

    showInfoCalc(){
        Swal.fire({
          title: '<strong><u>' + this.translate.instant('info') + '</u></strong>',
          html: getAutomaticCalcInfo(),
          width: 650,
          showCloseButton: true,
          showCancelButton: false,
          focusConfirm: false,
          confirmButtonText:
            '<i class="fa fa-thumbs-up"></i> OK!',
        })
      }
}
