import { Directive, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { NgControl, NgModel} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material';
import { AsYouType, CountryCode, format, parsePhoneNumber } from 'libphonenumber-js'


export class CountryView {
    countryCode: CountryCode;
    data: any;
}

const countriesOptions = require("./contries.json");

@Directive({
    selector: '[intPhoneMask]',
    exportAs: 'intPhoneMask',
    providers: [
        NgModel,
    ],
})
export class IntPhoneMaskDirective implements  OnInit{

    @Input() @Output() public countryCode: CountryCode;

    @Input() public parent: any;

    @Output() formatedNumber: string;

    @Output() countriesList: CountryView[] = [];

    @Output() currentView: CountryView;

    @Output() onValueChange = new EventEmitter<string>(); 

    @Input() invalid: boolean;
    @Output() invalidChange = new EventEmitter<boolean>();

    inputingData: boolean = false;


    constructor(public el: ElementRef, public ngControl: NgControl, private ngModel: NgModel) {

        // this.ngControl.valueChanges.subscribe(value => {
        //     //console.log('resp...', resp)
        //     if (!this.countryCode) {
        //         this.identifyCountry(value);
        //     }
        // });

        countriesOptions.forEach(element => {
            this.countriesList.push({ countryCode: element.iso as CountryCode, data: element });
        });

        this.ngControl.statusChanges.subscribe(resp => {


            if(!this.ngControl.control.value && !this.inputingData)
            {
                this.ngControl.control.setValue('+55');
                this.currentView = this.countriesList.find(i=>i.countryCode == 'BR');
                this.countryCode = this.currentView.countryCode;
            }

            if (!this.countryCode) {
                // console.log('statusChanges', resp);
                // console.log('this.ngControl.value', this.ngControl.value);
                console.log('identifyCountry', resp);
                this.identifyCountry(this.ngControl.value);
                if(this.invalid == false) {
                    this.onValueChange.emit(this.formatedNumber);
                }
            }

            if(!this.currentView){
                console.log('identifyCountry for currentView', resp);
                this.identifyCountry(this.ngControl.value);
            }
        });
    }

    ngOnInit(): void {
        //console.log('parent', this.parent);
    }

    readonly errorStateMatcher: ErrorStateMatcher = {
        isErrorState: (ctrl) => (this.invalid)
    };
      
    public changed(option) {
        console.log(option);

        this.inputingData = true;

        setTimeout(() => {
            this.el.nativeElement.focus();

            var fNumber = this.ngControl.value || '';
            var newOption = this.countriesList.find(i => i.countryCode == option.countryCode);
    
            if (this.currentView) {
                fNumber = fNumber.replace(this.currentView.data.code, newOption.data.code);
            }
    
            if (fNumber.indexOf(newOption.data.code) < 0) {
                fNumber = newOption.data.code + (fNumber || '').replace("+", "");
            }
    
            if((fNumber || '') == '') {
                fNumber = newOption.data.code;
            }
    
            this.currentView = this.countriesList.find(i => i.countryCode == option.countryCode);
            this.countryCode = newOption.countryCode;
    
            this.onInputChange(fNumber, false);
    
            if(fNumber == newOption.data.code){
                this.ngControl.control.setValue(fNumber);
                this.formatedNumber = fNumber;
                this.onValueChange.emit(fNumber);
            } else{
                this.ngControl.control.setValue(this.formatedNumber);
                this.onValueChange.emit(this.formatedNumber);
            }
            
        }, 200);

        setTimeout(() => {
            this.el.nativeElement.focus();
        }, 500);
    }

    @HostListener('ngModelChange', ['$event'])
    onModelChange(event) {
        this.onInputChange(event, false);
    }

    @HostListener('keydown.backspace', ['$event'])
    keydownBackspace(event) {
        this.onInputChange(event.target.value, true);
    }

    identifyCountry(number: string) {
        number = (number || '').replace('+', '');
        const phoneNumber = parsePhoneNumber(number.indexOf('+') < 0 ? '+' + number : number);
        this.formatedNumber = phoneNumber.format("INTERNATIONAL");
        //console.log('formatedNumber....', this.formatedNumber);
        const asYouType = new AsYouType();
        asYouType.input(this.formatedNumber);
        //console.log(asYouType);

        this.countryCode = asYouType.country;
        this.currentView = this.countriesList.find(i => i.countryCode == this.countryCode);
        this.invalid = !phoneNumber.isValid();
        this.invalidChange.emit(this.invalid);
    }

    onInputChange(event, backspace) {

        this.inputingData = true;

        let asYouType: AsYouType = new AsYouType(this.countryCode);

        event = event || '';
        let newVal = event;

        if (asYouType) {

            if (event != '+') {
                newVal = event.replace(/\D/g, '');
            }

            if (backspace && newVal.length <= 6) {
                newVal = newVal.substring(0, newVal.length - 1);
            }
            newVal = asYouType.input(newVal);
        }

        newVal = newVal.indexOf('+') < 0 ? '+' + newVal : newVal;
        //console.log('newVal', newVal);
        this.ngControl.valueAccessor.writeValue(newVal);
        
        try { this.identifyCountry(newVal); } catch (error) { }
        this.onValueChange.emit(newVal);
    }


}