import { Injectable } from '@angular/core';
import {Observable} from 'rxjs/Rx';
import * as moment from 'moment';
import {ResponseFormat} from '@app/types/response-format';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse  } from '@angular/common/http';
import { ReservationPost } from '@app/types/Reservation'; // Type
import { translateOrigin, translatedOriginToCode } from '@utils/helpers'
import { TranslateService } from '@ngx-translate/core';
import { AppStateService } from '@src/app/layouts/app-layout.service';

@Injectable()
export class ReservationsService
{
    constructor(private http : HttpClient, public translate: TranslateService, private appStateService: AppStateService) { }

    getReservations = (params) =>
    {
        let queryparam = '';
        const establishment = this.appStateService.currentEstablishment;
        let { startDate, category, channel, quantityDays } = params;

        if (category != 0 && channel != 0)
            queryparam = "?categoryId=" + category + "&channelId=" + channel;
        else if (category != 0)
            queryparam = "?categoryId=" + category;
        else if (channel != 0)
            queryparam = "?channelId=" + channel;

        quantityDays = parseInt(quantityDays) + 1;

        return this.http.get<ResponseFormat>('vivakey/rest/reservation/getReservations/' + establishment.establishmentId + '/' + startDate + 'T00:00:00.000-0700/' + quantityDays + queryparam);
    }

    getReservationSummaryInfo(reservationId ) {
		return this.http.get<ResponseFormat>('vivakey/rest/reservation/getReservation/details/' + reservationId);
	}

    getOTAPendingReservations = establishmentId => this.http.get<ResponseFormat>('vivakey/rest/channelManagerOverbooking/getChannelManagerOverbookings/'+establishmentId);

    getInvoicePendingErrors = establishmentId => this.http.get<ResponseFormat>('vivakey/rest/omieerro/'+establishmentId);

    getIntegrationErrorMagikey = establishmentId => this.http.get<ResponseFormat>('vivakey/rest/reservation/getintegrationerrormagikey/'+establishmentId);

    getValidatedCheckin = establishmentId => this.http.get<ResponseFormat>('vivakey/rest/reservation/getnotvalidatedcheckin/'+establishmentId);

    getReservationsNotCheckout = establishmentId => this.http.get<ResponseFormat>('vivakey/rest/reservation/getreservationnotcheckout/'+establishmentId);

    getResponsibleNoDocument = establishmentId => this.http.get<ResponseFormat>('vivakey/rest/reservation/getreservationbyresponsiblenodocument/'+establishmentId);


    getChannels = (establishmentId) => this.http.get<ResponseFormat>(`vivakey/rest/channel/channelByEstablishment/${establishmentId}`)

    getCategories = establishmentId => this.http.get<ResponseFormat>('vivakey/rest/category/getCategories/'+establishmentId);

    getReservationTariff = (startDay, finishDay, categoryId, channelId) =>
    {
        const param = (channelId != 0)
            ? '?categoryId=' + categoryId + '&channelId=' + channelId
            : '?categoryId=' + categoryId;
        const establishment = this.appStateService.currentEstablishment;
        const start_day  = startDay.ano + '-' + startDay.mes + '-' + startDay.day;
        const finish_day= finishDay.nightYear + '-' + finishDay.nightMonth + '-' + finishDay.night;

        return this.http.get<ResponseFormat>('vivakey/rest/tariff/getTariffs/'+establishment.establishmentId+'/'+start_day+'T00:00:00.000-0700/'+finish_day+'T00:00:00.000-0700'+param)
    }

    getReservationTariff_FromDateFormated = (start, finish, categoryId, channel) =>
    {
        const param = (channel)
            ? '?categoryId=' + categoryId + '&channelId=' + channel.channelId
            : '?categoryId=' + categoryId;
        const establishment = this.appStateService.currentEstablishment;

        return this.http.get<ResponseFormat>('vivakey/rest/tariff/getTariffs/'+establishment.establishmentId+'/'+start+'T00:00:00.000-0700/'+finish+'T00:00:00.000-0700'+param)
    }

    getTransferChannels = (startDay, finishDay, categoryId, channelId) =>
    {
        startDay = moment(startDay, 'YYYY-MM-DD').format("YYYY-MM-DD");
        finishDay = moment(finishDay, 'YYYY-MM-DD').format("YYYY-MM-DD");

        const param = (channelId != 0)
            ? '?categoryId=' + categoryId + '&channelId=' + channelId
            : '?categoryId=' + categoryId;

        const establishment = this.appStateService.currentEstablishment;
        return this.http.get<ResponseFormat>('vivakey/rest/tariff/getTariffs/'+establishment.establishmentId+'/'+startDay+'T00:00:00.000-0700/'+finishDay+'T00:00:00.000-0700'+param)
    }

    makeReservationTransfer = reservation => this.http.put<ResponseFormat>('vivakey/rest/reservation', reservation);

    makePreReservationTransfer = (preReservation, unitIds) =>
    {
        //envia um dia a menos
        preReservation.end_date = moment(preReservation.end_date, 'YYYY-MM-DD').subtract(1,'days').format('YYYY-MM-DD');
        const body = {
            reservationId: preReservation.reservationId,
            observation: preReservation.observation,
            initialDate: preReservation.start_date + 'T00:00:00.000-0700',
            unitIds: unitIds,
            responsibleId: null,
            reservationPrice: preReservation.price,
            channelId: preReservation.channel.channelId,
            finalDate: preReservation.end_date + 'T00:00:00.000-0700'
        }
        console.log(body);
        return this.http.put<ResponseFormat>('vivakey/rest/reservation/preReservation/tranfer', body)
    }

    generateDaysToShow = (qtdDias, startDay) =>
    {
        const auxDias = [];

        for (let z = 0; z < qtdDias; z++)
        {
            const today = startDay ? moment(startDay, "YYYY-MM-DD") : moment();
            const todayaux = startDay ? moment(startDay, "YYYY-MM-DD") : moment();
            const future = today.add(z, 'days').locale(this.translate.currentLang.replace('_', '-')).format('DD-MMMM-MM-YYYY-ddd').split('-');
            const yesterday = todayaux.add(z-1, 'days').locale(this.translate.currentLang.replace('_', '-')).format('DD-MMMM-MM-YYYY').split('-');

            const day = {
                dia: future[0],
                mes: future[1].substring(0, 3),
                ano: future[3],
                diab: yesterday[0],
                mesb: yesterday[2],
                anob: yesterday[3],
                mes_num: future[2],
                week: future[4],
                hasTariff: false,
                checkOutDay: false,
                tariffValue: '',
                hasReservation: false,
                dayBeforeHasReservation: false
            };

            auxDias.push(day);
        }
        return auxDias;
    }

    getDayTariffary = (days, tariffs) =>
    {
        for (let j = tariffs.length - 1; j >= 0; j--)
        {
            //explode para pegar as datas
            let tariffDay = tariffs[j].date.split("T")[0];
            tariffDay = tariffDay.split("-");
            const minTariff = tariffs[j].minValue;
            const maxTariff = tariffs[j].maxValue;

            if (tariffs[j].minValue == tariffs[j].maxValue)
                var value = "R$"+tariffs[j].minValue;
            else
                var value = "R$"+tariffs[j].minValue+"/<br>R$"+tariffs[j].maxValue;

            //percorre o array de dias a serem exibidos comparando com tarifario em questao
            for (let k = days.length - 1; k >= 0; k--)
            {
                //se existir um dia com tarifario, atribuimos o has tariff como true
                if (days[k].dia == tariffDay[2] && days[k].mes_num == tariffDay[1])
                {
                    days[k].hasTariff = true;
                    days[k].tariffValue = value;
                    days[k].minTariff = minTariff;
                    days[k].maxTariff = maxTariff;
                }
            }
        }
        return days;
    }

    getDaysReservations = (days, reservations) =>
    {
        for (let j = reservations.length - 1; j >= 0; j--)
        {
            //explode para pegar as datas
            let reservationDay = reservations[j].date.split("T")[0];
            reservationDay = reservationDay.split("-");
            const reservationStatus = reservations[j].reservationStatus;
            const responsibleHasEmail = reservations[j].responsibleHasEmail;
            const reservationDateType = reservations[j].reservationDateType;
            const reservationId = reservations[j].reservationId;

            for (let k = days.length - 1; k >= 0; k--)
            {
                //se existir um dia com tarifa, setamos o has hasReservation
                if (days[k].dia == reservationDay[2] && days[k].mes_num == reservationDay[1])
                {
                    days[k].hasReservation = true;
                    days[k].reservationId = reservationId;
                    days[k].reservationStatus = reservationStatus;
                    days[k].responsibleHasEmail = responsibleHasEmail;
                    days[k].reservationDateType = reservations[j].reservationDateType;
                    days[k].checkinMobile = reservations[j].checkinMobile;
                }

                //logica do dia anterior
                if (days[k].diab == reservationDay[2] && days[k].mesb == reservationDay[1])
                {
                    if (!days[k].hasReservation)
                    {
                        days[k].hasReservation = false;
                        days[k].checkOutDay = true;
                        days[k].responsibleHasEmail = responsibleHasEmail;
                        days[k].reservationStatus = reservationStatus;
                        days[k].lastReservationId = reservationId;
                        days[k].lastCheckinMobile = reservations[j].checkinMobile;
                    }
                    else
                    {
                        days[k].dayBeforeHasReservation = true;
                        days[k].dayBeforeReservationStatus = reservationStatus;
                        days[k].dayBeforeDateType = reservationDateType;
                        days[k].dayBeforeReservationId = reservationId;
                        days[k].dayBeforeHasEmail = responsibleHasEmail;
                        days[k].lastCheckinMobile = reservations[j].checkinMobile;
                    }
                }
            }
        }
        return days;
    }

    makeReservation = (reservation: ReservationPost) => this.http.post<ResponseFormat>('vivakey/rest/reservation/', reservation);

    makeCheckout = (reservationId) => {
        return this.http.put<ResponseFormat>(`vivakey/rest/reservation/checkinout/${reservationId}/checkout/true`, {});
    }
    
    getReservationInfo = reservationId => this.http.get<ResponseFormat>('vivakey/rest/reservation/getReservation/' + reservationId);

    getPreReservationInfo = reservationId => this.http.get<ResponseFormat>('vivakey/rest/reservation/getPreReservation/details/' + reservationId);

    cancelReservation = (reservationId, reverse, reason) =>
    {
        let options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json', }),
            body: {
                reversePayment: reverse,
                reason,
            },
        };

        return this.http.delete<ResponseFormat>(`vivakey/rest/reservation/${reservationId}`, options);
    }

    getUnits = (companyId, establishmentId, categoryId) =>
    {
        let param = '?';
        if (companyId)
            param += 'companyId='+companyId+'&';
        if (establishmentId)
            param += 'establishmentId='+establishmentId+'&';
        if (categoryId)
            param += 'categoryId='+categoryId;
        return this.http.get<ResponseFormat>('vivakey/rest/unit/getUnits/' + param);
    }

    makePreReservation = model => this.http.post<any>('vivakey/rest/reservation/preReservation', model);

    deletePreReservation = reservationId => this.http.delete<ResponseFormat>('vivakey/rest/reservation/preReservation/' + reservationId);

    sendEditPreReservation = model => this.http.put<ResponseFormat>('vivakey/rest/reservation/preReservation', model);

    sendOwnerReservation = model => this.http.post<ResponseFormat>('vivakey/rest/reservation/ownerreservation', model);

    confirmReservation = (reservationId, responsableId) =>
    {
        const body = {};
        return this.http.put<ResponseFormat>('vivakey/rest/reservation/effect/preReservation/'+reservationId+'/?responsibleId='+responsableId, body);
    }

    getDisponibleUnits = (categoryId, establishmentId, initialDate, finalDate) =>
    {
        initialDate = moment(initialDate, 'YYYY-MM-DD').format("YYYY-MM-DD");
        finalDate = moment(finalDate, 'YYYY-MM-DD').subtract(1,'days').format('YYYY-MM-DD');

        initialDate +=  'T00:00:00.000-0700';
        finalDate +=  'T00:00:00.000-0700';
        return this.http.get<ResponseFormat>('vivakey/rest/unit/getDisponibleUnits/'+establishmentId+'/'+initialDate+'/'+finalDate+'/?categoryId='+categoryId);
    }

    // CHANNEL MANAGER RESERVATION SERVICES

    dismissOTAPendingReservations = paylod => this.http.put<ResponseFormat>('vivakey/rest/channelManagerOverbooking', paylod);

    reprocessReservation = model => this.http.post<ResponseFormat>('vivakey/rest/reservation/reprocessReservation', model);

    reprocessChannelManagerReservation = (channelManagerReservationRec) =>
    {
        console.log("Sending Transfer", channelManagerReservationRec);

        const finalDate = moment(channelManagerReservationRec.checkoutDateOld, 'YYYY-MM-DD').subtract(1, 'days').format('YYYY-MM-DDTHH:mm:ss');
        const model = {
            categoryId: channelManagerReservationRec.category.categoryId,
            finalDate: finalDate,
            channelId: channelManagerReservationRec.channel.channelId,
            unitIds: [0],
            responsibleId: channelManagerReservationRec.responsible.responsibleId,
            channelManagerReservationId: channelManagerReservationRec.channelManagerReservationId,
            initialDate: moment(channelManagerReservationRec.checkinDateOld, 'YYYY-MM-DD').format('YYYY-MM-DDTHH:mm:ss'),
            buklyId: channelManagerReservationRec.buklyId || null,
            salesChannel: translatedOriginToCode(channelManagerReservationRec.salesChannel)
        };

        return this.reprocessReservation(model);
    }

    getDisponibleCategories = (channelManagerReservationRec) =>
    {
        const finalDate = channelManagerReservationRec.checkoutDateOld;
        const establishment = this.appStateService.currentEstablishment;
        const initialDate = channelManagerReservationRec.checkinDateOld;

        return this.http.get<ResponseFormat>('vivakey/rest/category/getDisponibleCategory/'+establishment.establishmentId+'/'+initialDate+'/'+finalDate);
    }

    getUnitDispnibilityDayByDay = (categoryId, initialDate, finalDate) => this.http.get<ResponseFormat>('vivakey/rest/unit/getDisponibleUnitsDayByDay/'+categoryId+'/'+initialDate+'/'+finalDate);

    postBrokenReservation = model => this.http.post<ResponseFormat>('vivakey/rest/reservation/brokenReservation', model);

    pendingReservatioHistory = buklyId => this.http.get<ResponseFormat>(`vivakey/rest/channelManagerOverbooking/getChannelManagerOverbookingsByBuklyId/${buklyId}`);

    pendingReservationCreated = (buklyId, establishmentId) => this.http.get<ResponseFormat>(`vivakey/rest/reservation/getReservationsFromBukly/${establishmentId}/${buklyId}`);

    getLockedUnits(establishmentId, startDate, endDate) {
        return this.http.get<ResponseFormat>(`vivakey/rest/unit-locked/establishment/${establishmentId}/${startDate}/${endDate}`)
    }

    lockUnit(unitId, body) {
        return this.http.post<ResponseFormat>(`vivakey/rest/unit-locked/unit/${unitId}`, body)
    }

    unlockUnit(unitLockedId) {
        return this.http.put<ResponseFormat>(`vivakey/rest/unit-locked/unit-lock/${unitLockedId}`, {})
    }

    updateCheckinCheckout_Date  = (reservationId, newTime, editTime, resizeWithoutReversing) => {
        if(editTime == "checkin") {
            return this.http.put<ResponseFormat>(`vivakey/rest/reservation/resize/${reservationId}?newDateCheckin=${newTime}${ resizeWithoutReversing ? '&resizeWithoutReversing=true' : '' }`, {});
        }

        else {
            return this.http.put<ResponseFormat>(`vivakey/rest/reservation/resize/${reservationId}?newDateCheckout=${newTime}${ resizeWithoutReversing ? '&resizeWithoutReversing=true' : '' }`, {});
        }
    }
}