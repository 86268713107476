import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse  } from '@angular/common/http';
import { ResponseFormat } from '@app/types/response-format'
@Injectable()
export class OccupationMapService {

  constructor(private http : HttpClient) { }

  getOccupationMap = (establishmentId, date) => this.http.get<ResponseFormat>(`vivakey/rest/reservation/getOccupationMap/${establishmentId}/${date}`);
  getFreeUnitsNotClean = (establishmentId, date) => this.http.get<ResponseFormat>(`vivakey/rest/reservation/getFreeUnitsNotClean/${establishmentId}/${date}`);

  check = (reservationId, type, value) => {
  	return this.http.put<ResponseFormat>(`vivakey/rest/reservation/checkinout/${reservationId}/${type}/${value}`, {});
  }

  changeCleanStatus = (unitId, cleanStatus) => {
    console.log('end point mudança de status')
  	return this.http.put<ResponseFormat>(`vivakey/rest/unit/changeCleanStatus/${unitId}/${cleanStatus}`, {});
  }

  reportProblem = (body) => {
  	return this.http.post<ResponseFormat>(`vivakey/rest/unitProblem`, body);
  }

}
