import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { ReservationsService } from "../reservations/reservations.service";
import { ReportClosingAptService } from "./report-closing-of-apartments.service";
import moment from "moment-timezone";
import { NotifierService } from 'angular-notifier';
import { last12Months, strLimit } from "@utils/helpers";
import { TranslateService } from '@ngx-translate/core';
import { AppStateService } from "@src/app/layouts/app-layout.service";

const clApartmentsData = require("../../utils/datas/closing-of-apartments-data.json");

@Component({
    selector: "app-report-closing-of-apartments",
    templateUrl: "./report-closing-of-apartments.component.html",
    styleUrls: ["./report-closing-of-apartments.component.css"]
})
export class ReportClosingAptComponent implements OnInit {

    taxList: any[] = [];
    taxListLabels: any[] = [];

    periodList: any[] = [];
    selectedMonth: any;
    reportData: any = [];
    establishment: any;
    loadingData: boolean = false;

    strLimit = strLimit


    constructor(
        private appStateService: AppStateService,
        private router: Router,
        private notifier: NotifierService,
        private reservationsService: ReservationsService,
        private reportFinancialService: ReportClosingAptService,
        private translate: TranslateService,
    ) { }

    ngOnInit() {

        this.periodList = last12Months();

        this.establishment = this.appStateService.currentEstablishment;

        this.getTaxesFromPosId();
        this.filterReport();
    }


    // Download the excel file
    downloadReport() {
        if (!this.establishment) {
            return;
        }

        var reportParams = {
            initDate: this.selectedMonth.start.format('YYYY-MM-DD'),
            endDate: this.selectedMonth.end.format('YYYY-MM-DD'),
            establishmentId: this.establishment.establishmentId,
            report: true,
            byUnits: true
        };

        this.loadingData = true
        this.reportFinancialService
            .getExcelReport(this.establishment.establishmentId,
                this.selectedMonth.start.format('YYYY-MM-DD'),
                this.selectedMonth.end.format('YYYY-MM-DD'),
                reportParams)
            .subscribe(
                resp => {
                    const keys = resp.headers.keys();
                    var headers = keys.map(key => `${key}: ${resp.headers.get(key)}`);
                    console.log("headers: ", headers);
                    var reporturl =  resp.headers.get('reporturl');

                    var message = resp.body.message;
                    if (message) {
                        window.location.href = message.replace('reporturl: ', '');
                    } else {
                        this.notifier.notify('error', this.translate.instant('error_msg.error_getdata' ))
                    }
                    this.loadingData = false
                },
                error => {
                    this.loadingData = false
                    this.notifier.notify('error', this.translate.instant('error_msg.error_getdata' ))
                }
            );


    }



    getTaxesFromPosId() {
        this.reportFinancialService.getPos(this.establishment.establishmentId).subscribe(
            data => {
                console.log(data);
                switch (data.code) {
                    case 200:
                        if (data.object && data.object.length >= 1)
                            this.getTaxes(data.object[0].posId);
                        break;
                    default:
                        this.loadingData = false;
                        break;
                }
            },
            error => {
                this.loadingData = false;
                console.log("Error", error);
            }
        );
    }

    getTaxes = (posId) => {

        var labTotal = this.translate.instant('total');
        var labPayed = this.translate.instant('payed');

        this.taxListLabels = [];

        this.loadingData = true;
        this.reportFinancialService.getTaxes(posId).subscribe(
            data => {
                console.log(data);
                switch (data.code) {
                    case 200:
                        for (let i = data.object.length - 1; i >= 0; i--) {
                            if (data.object[i].status == "ACTIVE")
                                data.object[i].statusPush = true;
                            else data.object[i].statusPush = false;

                            this.taxListLabels = [{ additionalFeeId: data.object[i].additionalFeeId, label: labPayed, type: "paid" }].concat(this.taxListLabels);
                            this.taxListLabels = [{ additionalFeeId: data.object[i].additionalFeeId, label: labTotal, type: "ammount" }].concat(this.taxListLabels);
                        }
                        this.loadingData = false;
                        this.taxList = data.object;

                        console.log('this.taxListLabels ', this.taxListLabels);

                        break;
                    default:
                        this.loadingData = false;
                        break;
                }
            },
            error => {
                this.loadingData = false;
                console.log("Error", error);
            }
        );
    };

    getFeeValueOfCategory(categ: any, additionalFeeId: number, type: string): number {



        var values = Object.assign([], categ.additionalFeeValues);

        if (values && additionalFeeId) {

            var sumValue = values.find(v => v.additionalFeeId == additionalFeeId);

            if (sumValue) {
                switch (type) {
                    case 'ammount':
                        return sumValue.totalAmmount;
                    case 'paid':
                        return sumValue.totalPaid;
                    default:
                        return 0;
                }
            }


        }
        return 0;
    }

    filterReport = () => {


        if (!this.selectedMonth) {
            // this.emptyDateWarn = true;
        } else {

            console.log('selectedMonth', this.selectedMonth);
            this.loadingData = true;

            var params = { byUnits: true };

            var auxData: any = {};

            this.reportFinancialService
                .filterReport(
                    this.establishment.establishmentId,
                    this.selectedMonth.start.format('YYYY-MM-DD'),
                    this.selectedMonth.end.format('YYYY-MM-DD'),
                    params
                )
                .subscribe(
                    data => {
                        console.log(data);
                        switch (data.code) {
                            case 200:

                                auxData = {
                                    billingTotal: data.object.value,
                                    billingPaid: (data.object.valuePayed ? data.object.valuePayed : 0),
                                    billingRemaining: data.object.valueDiff,
                                    reservationsCount: data.object.numberOfReservations,
                                    reservationsCountGuests: data.object.guestsQuantity,
                                    dailyAverage: data.object.averageDaily,
                                    occupancyTax: data.object.occupancyRate,
                                    daily: data.object.daily,
                                    additionalFeeValues: []
                                };

                                if (data.object.additionalFeeBilling) {
                                    this.taxList.forEach(tax => {
                                        var taxValue = data.object.additionalFeeBilling[tax.additionalFeeId];
                                        if (taxValue) {
                                            auxData.additionalFeeValues.push(
                                                {
                                                    additionalFeeId: taxValue.additionalFee.additionalFeeId,
                                                    name: taxValue.additionalFee.name,
                                                    totalAmmount: taxValue.total,
                                                    totalPaid: taxValue.payed ? taxValue.payed : 0
                                                }
                                            );
                                        }
                                    });
                                }


                                if (data.object.byCategory) {
                                    auxData["categories"] = [];


                                    data.object.byCategory.forEach(category => {

                                        var jcatg = {
                                            categoryId: category.categoryId,
                                            categoryName: category.categoryName,
                                            billingTotal: category.value,
                                            billingPaid: (category.valuePayed ? category.valuePayed : 0),
                                            billingRemaining: category.valueDiff,
                                            reservationsCount: category.numberOfReservations,
                                            reservationsCountGuests: category.guestsQuantity,
                                            dailyAverage: category.averageDaily,
                                            occupancyTax: category.occupancyRate,
                                            daily: category.daily,
                                            additionalFeeValues: [],
                                            byApartment: []
                                        };

                                        if (category.additionalFeeBilling) {

                                            this.taxList.forEach(tax => {
                                                var taxValue = category.additionalFeeBilling[tax.additionalFeeId];
                                                if (taxValue) {
                                                    jcatg.additionalFeeValues.push(
                                                        {
                                                            additionalFeeId: taxValue.additionalFee.additionalFeeId,
                                                            name: taxValue.additionalFee.name,
                                                            totalAmmount: taxValue.total,
                                                            totalPaid: taxValue.payed ? taxValue.payed : 0
                                                        }
                                                    );
                                                }
                                            });
                                        }

                                        if (data.object.byUnit) {
                                            data.object.byUnit.forEach(unit => {

                                                if (unit.categoryId == category.categoryId) {

                                                    var jUnit: any = {
                                                        unitId: unit.unitId,
                                                        apartmentName: unit.unitName,
                                                        categoryId: category.categoryId,
                                                        billingTotal: unit.value,
                                                        billingPaid: (unit.valuePayed ? unit.valuePayed : 0),
                                                        billingRemaining: unit.valueDiff,
                                                        reservationsCount: unit.numberOfReservations,
                                                        reservationsCountGuests: unit.guestsQuantity,
                                                        dailyAverage: unit.averageDaily,
                                                        occupancyTax: unit.occupancyRate,
                                                        daily: unit.daily,
                                                        additionalFeeValues: [],
                                                    };

                                                    this.taxList.forEach(tax => {
                                                        var taxValue = unit.additionalFeeBilling[tax.additionalFeeId];
                                                        if (taxValue) {
                                                            jUnit.additionalFeeValues.push(
                                                                {
                                                                    additionalFeeId: taxValue.additionalFee.additionalFeeId,
                                                                    name: taxValue.additionalFee.name,
                                                                    totalAmmount: taxValue.total,
                                                                    totalPaid: taxValue.payed ? taxValue.payed : 0
                                                                }
                                                            );
                                                        }
                                                    });


                                                    jUnit.additionalFeeValues_Sum = [];

                                                    this.taxList.forEach(cadAdFee => {
                                
                                                        var feeVL = jUnit.additionalFeeValues.find(i => i.additionalFeeId == cadAdFee.additionalFeeId);
                                
                                                        jUnit.additionalFeeValues_Sum.push(
                                                            {
                                                                additionalFeeId: (feeVL ? feeVL.additionalFeeId : null),
                                                                type: 'totalAmmount',
                                                                value: (feeVL ? feeVL.totalAmmount : 0),
                                                            });
                                
                                                            jUnit.additionalFeeValues_Sum.push(
                                                            {
                                                                additionalFeeId: (feeVL ? feeVL.additionalFeeId : null),
                                                                type: 'totalPaid',
                                                                value: (feeVL ? feeVL.totalPaid : 0),
                                                            });
                                
                                                    });


                                                    jcatg.byApartment.push(jUnit);
                                                }
                                            });
                                        }

                                        auxData.categories.push(jcatg);

                                    });

                                }

                                this.reportData = auxData;

                                console.log("reportData", auxData);
                                console.log("reportDataString", JSON.stringify(auxData));

                                break;
                            case 218:
                                console.log("nao possui categorias");
                                break;
                            default:
                                break;
                        }
                        this.loadingData = false;
                    },
                    error => {
                        this.notifier.notify('error', this.translate.instant('error_msg.error_getdata' ))
                        console.log("Error");
                        this.loadingData = false;
                    }
                );


        }
    };

}
