import { Component, OnInit } from '@angular/core';
import { ReportBillingService } from './report-billing.service'; // Service
import { translateOrigin, translatedOriginToCode } from '@utils/helpers'
import * as moment from 'moment';
import { NotifierService } from 'angular-notifier';
import { TranslateService } from '@ngx-translate/core';
import { AppStateService } from '@src/app/layouts/app-layout.service';


@Component({
	selector: 'app-report-billing',
	templateUrl: './report-billing.component.html',
	styleUrls: [
	'../../app.component.css',
	'./report-billing.component.css',
	]
})
export class ReportBillingComponent implements OnInit {
  translateOrigin = translateOrigin;
  selectStartDate: any;
  selectFinishDate: any;
  selectedEstablishment: any;
  loading: boolean = false;
  data: any;

  constructor(
	  private reportBillingService: ReportBillingService,
	  private notifier: NotifierService,
	  private translate: TranslateService,
	  private appStateService: AppStateService
  	) {

  }

  ngOnInit() {
  	this.selectedEstablishment = this.appStateService.currentEstablishment;

  	this.selectFinishDate = moment()
	this.selectStartDate = moment()

  }

  formatPrice = (value) => {
	return (value || 0).toLocaleString(this.translate.currentLang.replace('_', '-'), { style: 'currency', currency: this.translate.currentLang == 'pt_br' ? 'BRL' : 'USD' });
  }

  query() {
    this.loading = true;
  	this.reportBillingService.query(
  		this.selectedEstablishment.establishmentId,
  		this.selectStartDate.format('YYYY-MM-DD'),
  		this.selectFinishDate.format('YYYY-MM-DD'),
  		).subscribe(
  		(data) => {
  			console.log(data)
  			switch (data.code) 
  			{
  				case 200:
  				this.data = data.object
  				break;
  				default:
  				// TODO:
  				// Handle errors
  			}
  		},
  		(error) => {
  			// TODO:
			  // Handle errors
			this.notifier.notify('error', this.translate.instant('error_msg.error_getdata' ))
  			console.log('erro')
  		},
  		() => {
  			this.loading = false;
  		});
  }

}
