import { Component, OnInit, ViewChild, HostBinding, ViewEncapsulation, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ReservationsService } from './reservations.service';
import { CategoriesService } from '../categories/categories.service';
import { PendingReservation } from '@app/types/PendingReservation';
import { translateOrigin, translatedOriginToCode } from '@utils/helpers'
import masks from '@utils/masks'
import { IMyDpOptions } from 'mydatepicker';
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe'
import moment from 'moment-timezone';
import { NotifierService } from 'angular-notifier';
import { TranslateService } from '@ngx-translate/core';
import { SchedulerComponent } from '@src/app/components/views/scheduler/scheduler.component';
import { DayPilotSchedulerComponent, DayPilot } from 'daypilot-pro-angular';
import { stringify } from 'querystring';
import { OrderPipe } from '@src/app/pipes/sort-by.pipe';
import Swal from 'sweetalert2';
import { MatDialogRef, MatDialog } from '@angular/material';
import { FixResErrorsModalComponent } from '@src/app/components/views/fix-reservation-error-modal/fix-reservation-error-modal.component';
import { BreakpointObserver } from '@angular/cdk/layout';
import { GroupByPipe } from '@src/app/pipes/group-itens-pipe';
import { gData } from '@src/app/pipes/g-data';
import {
    trigger,
    state,
    style,
    animate,
    transition,
    // ...
} from '@angular/animations';
import { flashAnimation } from 'angular-animations';
import { AppStateService } from '@src/app/layouts/app-layout.service';
import { SharedAnimations } from '@src/app/shared/animations/shared-animations';

const autoCorrectedTimePipe = createAutoCorrectedDatePipe('HH:MM')

declare var $: any;

interface Channel {
    name: string;
    channelId: number;
    buklyChannelCode: any
}

interface Category {
    name: string;
    categoryId: number;
}

interface PreReservation {
    reservationId?: number;
    checkin: Date;
    checkin_formatted?: string,
    checkout: Date;
    checkout_formatted?: string,
    finalDate: Date;
    unit: string;
    unitIds: number[];
    reservationPrice: number;
    channel: Channel;
    guestQuantities?: number[];
    responsibleId?: number;
    responsibleName: string;
    responsibleEmai: string;
    responsiblePhone: string;
    observation?: string;
    category: Category;
    limit_date?: string;
    limit_time?: string;
    deadline?: string;
    editing: boolean;
}

interface BlockModel {
    checkin: any;
    checkout: any;
    checkinFormatted?: string,
    checkoutFormatted?: string,
    blockedIn?: any,
    unit: string;
    unitId: number;
    category: Category;
    reason: string;
    responsible?: any;
    unitLockedId?: any;
    unitName: string;
}
function validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

@Component({
    selector: 'app-reservations',
    templateUrl: './reservations.component.html',
    styleUrls: [
        './reservations.component.css'
    ],

    animations: [
        SharedAnimations,
        flashAnimation(),
        trigger('alertLoaded', [
            // ...
            state('red', style({
                //opacity: 1,
                color: 'red',
                //fontSize: '38px',
                //width: '35px',
                //height: '35px'
            })),
            state('normal', style({
                //opacity: 0.5,
                color: '',
                //fontSize: '32px',
                //width: '24px',
                //height: '24px'
            })),
            transition('red => normal', [
                animate('1s')
            ]),
            transition('normal => red', [
                animate('0.5s')
            ]),
        ]),
    ],
    encapsulation: ViewEncapsulation.None
})

export class ReservationsComponent implements OnInit, OnDestroy {
    tabSelectedIndex = 0;
    initialLoaded = false;
    hasNewAlerts = false;

    isSmallScreen = false;
    console = console;
    translateOrigin = translateOrigin;
    quantityDays: any;
    reservations: any;
    //daysToShow: any = [];
    showCategory: any = [];
    categories: Category[] = [];
    selectCategoria: any;
    selectDias: any;
    selectStartDate: any;
    modalInfo: any = {};
    channels: Channel[] = [];
    selectChannel: any;
    selectSource: any = 'BALCAO';

    startReservationDay: any = {};
    minChekOutDate: any;

    finishReservationDay: any = {};
    finishReservationDate: any;
    startReservationDate: any;

    setFinishReservationDate() {

        var end = moment(this.finishReservationDate);
        const end_yesterday = moment(this.finishReservationDate).subtract(1, 'days');

        this.finishReservationDay = {
            day: end.format('DD'),
            month: end.format('MM'),
            year: end.format('YYYY'),
            night: end_yesterday.format('DD'),
            nightMonth: end_yesterday.format('MM'),
            nightYear: end_yesterday.format('YYYY'),
            unitId: this.finishReservationDay.unitId,
            categoryId: this.finishReservationDay.categoryId,
        };

        this.loadTariffsToReservation(
            `${this.startReservationDay.ano}-${this.startReservationDay.mes}-${this.startReservationDay.day}`,
            moment(end_yesterday).format('YYYY-MM-DD'), this.finishReservationDay.categoryId
        ).then(ret => { }, err => { });
    }

    channelList: any[] = [];

    reservationSelectedUnit: any = {};
    reservationInfoModal: any = {};
    makingReservationPrice: any;
    readOnly: any;
    makingReservationGuestsQuantity: any;
    editingChannel: boolean = false;
    preReservationModel: PreReservation;
    blockModel: BlockModel;
    blockedDays: BlockModel[] = [];
    currentBlockedDetail: any = null;
    transferModel: any = {};
    transferModel_ReservationData: any;
    transferableCategories: any[] = [];
    disponibleTransferUnits: any[] = [];
    multipleModal: any[] = [];
    multipleUnits: any[] = [];



    reservationPrice: any[] = [];
    channelManagerReservationAction: any;
    channelManagerReservationRec: any;
    transferableCategoriesCM: any[] = [];
    ChannelManagerCategoryTransfer: any;
    channelManagerReservationId: number;
    pendingReservation: {
        reservationId: number;
        channelManagerReservationId: number;
        groupReservationId: any;
        reservationStatus: any;
    };
    pendingReservationIdToCancel: number;
    availableDays: any;
    createBrokenValid: boolean = false;
    peddingReservationOTAOpen: boolean = true;
    peddingReservationErrorsOpen: boolean = true;
    errorTitle: string = "";
    errorSubtitle: string = "";
    successTitle: string = "";
    successSubtitle: string = "";
    PendingReservationCreated: any[] = [];
    PendingReservationHistory: any[] = [];

    phoneMask = masks.phone
    timeMask: any = [/[0-2]/, /\d/, ":", /[0-5]/, /\d/];
    autoCorrectedTimePipe = autoCorrectedTimePipe;

    contactLong: boolean = false;
    contactAlredyLong: boolean = false;
    cepAutoComplete: any;
    lastMapResponse: any;

    selectedEstablishment: any;
    selectedCompany: any;
    userInfo: any;
    currentUser_IsManager: boolean = false;

    loading: boolean = false;
    cancelReason: string = ''
    daysWithoutUnits: any;

    fixResErrorsModalComponentDialogRef: MatDialogRef<FixResErrorsModalComponent>;
    questionDialogRef: MatDialogRef<any>;


    @ViewChild('scheduler')
    scheduler: SchedulerComponent;


    scheduler_resources: any = [];
    scheduler_events: any;
    scheduler_reservations: any = [];

    ckeditor_config = {
        language: this.translate.currentLang == 'pt_br' ? 'pt-br ' : 'en-us',
        removePlugins: 'elementspath,save',
        toolbar: [
            { name: 'styles', items: ['Font', 'FontSize'] },
            { name: 'basicstyles', items: ['Bold', 'Italic', 'Underline', '-'] }]
    };


    currentTemplateModal: any;
    currentReservation: any;
    pageDatailsName: string = null;
    currentReservationEffectCommand: boolean;

    @ViewChild('reservationResponsableTemplate')
    reservationResponsableTemplate;

    @ViewChild('reservationPaymentTemplate')
    reservationPaymentTemplate;

    @ViewChild('reservationSummaryTemplate')
    reservationSummaryTemplate;

    validateCheckin: boolean = false;

    constructor(
        private appStateService: AppStateService,
        private reservationsService: ReservationsService,
        private router: Router,
        public route: ActivatedRoute,
        private notifier: NotifierService,
        private categoriesService: CategoriesService,
        public translate: TranslateService,
        public dialog: MatDialog,
        public dialogQuestion: MatDialog,
        public breakpointObserver: BreakpointObserver) {
        this.isSmallScreen = breakpointObserver.isMatched('(max-width: 599px)');

        this.route.params.subscribe(params => {

            var reservationId = params['reservationId'];
            var pageName = params['pageName'];

            if (reservationId && pageName) {
                this.currentReservation = { reservationId: reservationId }
                this.pageDatailsName = pageName;
            }

            if (params['validateCheckin'] === 'true') {
                this.validateCheckin = true;
            }


        });

    }

    openPageDetails() {
        setTimeout(() => {
            switch (this.pageDatailsName) {
                case 'responsable':
                    this.currentReservationEffectCommand = false;
                    this.goResponsableDetails()
                    break;

                case 'payment':
                    this.goPaymentDetails();
                    break;

                case 'summary':
                    this.goSummaryDetails(this.validateCheckin);
                    break;

                default:
                    break;
            }
        }, 500);
    }

    goResponsableDetails() {
        this.responsableAutoEdit = false;
        this.currentTemplateModal = this.reservationResponsableTemplate;
        //return this.router.navigate([`app/${this.appStateService.currentEstablishmentId}/reservations/responsable/${this.currentReservation.reservationId}`],  { replaceUrl:true });
    }

    goPaymentDetails() {
        this.currentTemplateModal = this.reservationPaymentTemplate;
    }

    goSummaryDetails(validate: boolean = false) {
        this.validateCheckin = validate;
        this.currentTemplateModal = this.reservationSummaryTemplate;
    }

    goToReservationSummaryInfo(reservationId, mode) {
        this.validateCheckin = (mode === 'validate');
        this.currentReservation = { reservationId: reservationId }
        this.currentTemplateModal = this.reservationSummaryTemplate;
    }

    closePageDetails() {
        console.log('route.params', this.route.params);
        if (this.pageDatailsName) {
            this.router.navigate([`app/${this.appStateService.currentEstablishmentId}/reservations`]);
        } else {
            this.currentTemplateModal = null;
            this.filtrarMapa();
        }
    }


    ngOnInit() {
        //sessionStorage.setItem('currentReservationGuestsQuantity', '1');

        //inicializacao dos models para nao estourar exception
        this.modalInfo.room = {};
        this.modalInfo.room.unity = {};
        this.modalInfo.category = {};
        this.makingReservationGuestsQuantity = 1;

        this.preReservationModel = {
            checkin: null,
            checkout: null,
            finalDate: null,
            unit: "",
            unitIds: [],
            reservationPrice: 0,
            channel: {
                channelId: 0,
                name: "",
                buklyChannelCode: null
            },
            responsibleName: "",
            responsibleEmai: "",
            responsiblePhone: "",
            category: {
                categoryId: 0,
                name: ""
            },
            editing: false
        };

        this.categories.push({
            name: this.translate.instant('loading'),
            categoryId: 0
        });

        this.selectedCompany = this.appStateService.currentCompany;
        this.selectedEstablishment = this.appStateService.currentEstablishment;
        this.userInfo = JSON.parse(localStorage.getItem('currentUserInfo'));
        ////console.log('userInfo', this.userInfo)

        const params = JSON.parse(sessionStorage.getItem('reservation_filter'));

        if (params && !moment(params.startDate, 'DD/MM/YYYY').isValid()) {
            params.startDate = moment().format('DD/MM/YYYY')
            sessionStorage.setItem('reservation_filter', JSON.stringify(params));
        }

        this.selectChannel = null;
        this.selectCategoria = params ? params.category : 0;
        this.selectStartDate = params ? moment(params.startDate, 'DD/MM/YYYY') : moment();
        this.quantityDays = params ? params.quantityDays : 10;

        //console.log('params', params)

        this.setcurrentUser_IsManager()
        this.setCurrent_User_Is_Owner_Of_Estabilishment();
        this.setCurrent_User_Is_Room_Maid();


        //busca dos servicos para o filtro select
        this.reservationsService.getChannels(this.selectedEstablishment.establishmentId).subscribe(data => {
            switch (data.code) {
                case 200:
                    this.channels = [];

                    for (let i = data.object.length - 1; i >= 0; i--) {
                        this.channels.push(data.object[i]);
                    }

                    var balcao = this.channels.find(i => i.buklyChannelCode == null);
                    if (balcao) {
                        this.selectChannel = balcao;
                    }

                    //console.log('this.channels', this.channels)
                    //console.log('this.selectChannel', this.selectChannel)
                    break;
            }
        }, error => console.log("Error"));


        this.getReservations();
        this.getOTAPendingReservations();
        this.getInvoicePendingErrors();
        this.getValidatedCheckin();
        this.getIntegrationErrorMagikey();
        this.getReservationsNotCheckout();
        this.getResponsibleNoDocument();

        this.getOTAPendingReservationsInterval = setInterval(() => {
            this.getReservations();
            this.getOTAPendingReservations();
            this.getInvoicePendingErrors();
            this.getValidatedCheckin();
            this.getIntegrationErrorMagikey();
            this.getReservationsNotCheckout();
            this.getResponsibleNoDocument();
        }, 60000);

        this.reservationsService.getCategories(this.selectedEstablishment.establishmentId).subscribe(data => {
            //console.log("GetCategories", data.object);

            switch (data.code) {
                case 200:
                    this.categories = [];
                    for (let i = data.object.length - 1; i >= 0; i--)
                        this.categories.push(data.object[i]);
                    break;
                default:
                    break;
            }
        }, error => console.log("Error"));

        $('.modal').on('hidden.bs.modal', this.removeSelectedDay);
    }

    current_User_Is_Owner_Of_Estabilishment: boolean = false;
    setCurrent_User_Is_Owner_Of_Estabilishment() {
        if (this.userInfo && this.userInfo.establishmentJobTitles instanceof Array) {
            // Check if user is Owner at current establishment
            let jobTitle = this.userInfo.establishmentJobTitles.find(
                job => job.establishmentJobTitle.establishment.establishmentId == this.selectedEstablishment.establishmentId
            )

            if (jobTitle && jobTitle.establishmentJobTitle) {
                var name = (jobTitle.establishmentJobTitle.name || '').toLowerCase();
                if (name.indexOf('proprietário') >= 0 || name.indexOf('proprietario') >= 0) {
                    this.current_User_Is_Owner_Of_Estabilishment = true;
                    return true;
                }
            }
        }
        return false;
    }
    current_User_Is_Room_Maid: boolean = false;
    setCurrent_User_Is_Room_Maid() {
        if (this.userInfo && this.userInfo.establishmentJobTitles instanceof Array) {
            // Check if user is Owner at current establishment
            let jobTitle = this.userInfo.establishmentJobTitles.find(
                job => job.establishmentJobTitle.establishment.establishmentId == this.selectedEstablishment.establishmentId
            )

            if (jobTitle && jobTitle.establishmentJobTitle) {
                var name = (jobTitle.establishmentJobTitle.name || '').toLowerCase();
                if (name.indexOf('camareira') >= 0) {
                    this.current_User_Is_Room_Maid = true;
                    return true;
                }
            }
        }
        return false;
    }

    getOTAPendingReservationsInterval: any;
    ngOnDestroy() {
        clearInterval(this.getOTAPendingReservationsInterval);
    }

    openModal(dialogTemplate) {
        this.dialog.open(dialogTemplate, {
            //panelClass: 'mat-dialog-large',
            disableClose: true
        });
    }

    openQuestionModal(dialogTemplate) {
        this.questionDialogRef = this.dialogQuestion.open(dialogTemplate, {
            //panelClass: 'mat-dialog-large',
            disableClose: true
        });
    }

    tabChangeEvent(event) {
        if (event.index == 1) {
            setTimeout(() => {
                this.hasNewAlerts = false;
            }, 1000);
        }
    }

    onDoneNewAlerts(event: AnimationEvent): void {
        // console.log(event);


        if (this.tabSelectedIndex == 0 &&
            (this.pendingReservationsOTA.length > 0
                || this.pendingReservationsErrors.length > 0
                || this.notValidatedCheckinData.length > 0
                || this.reservationsNotCheckout.length > 0
                || this.responsibleNoDocument.length > 0)) {
            this.hasNewAlerts = event['toState'] === 'red' ? false : true;
        } else {
            this.hasNewAlerts = false;
        }


    }

    setcurrentUser_IsManager = () => {
        if (this.userInfo && this.selectedCompany && this.userInfo) {

            if (this.userInfo.companyJobTitles instanceof Array) {

                // Check if user is manager at current company
                let jobTitle = this.userInfo.companyJobTitles.find(
                    job => job.companyJobTitle.company.companyId == this.selectedCompany.companyId
                )

                if (jobTitle) {
                    if (jobTitle.companyJobTitle.manager) {
                        this.currentUser_IsManager = true
                    }
                }
            }

            if (this.userInfo.establishmentJobTitles instanceof Array) {

                // Check if user is manager at current establishment
                let jobTitle = this.userInfo.establishmentJobTitles.find(
                    job => job.establishmentJobTitle.establishment.establishmentId == this.selectedEstablishment.establishmentId
                )

                if (jobTitle) {
                    if (jobTitle.establishmentJobTitle.manager) {
                        this.currentUser_IsManager = true
                    }
                }
            }
        }

    }


    pendingReservationsOTA: any[] = [];
    getOTAPendingReservations = () => {
        this.reservationsService.getOTAPendingReservations(this.selectedEstablishment.establishmentId).subscribe(data => {
            //console.log("getOTAPendingReservations - From API", data.object);
            switch (data.code) {
                case 200: {

                    //data.object = [];

                    if (data.object) {
                        if (this.pendingReservationsOTA && data.object.length > 0 && this.pendingReservationsOTA.length != data.object.length)
                            this.hasNewAlerts = true;
                    }

                    this.pendingReservationsOTA = [];


                    for (let i = data.object.length - 1; i >= 0; i--) {
                        data.object[i].checkinDateOld = data.object[i].checkinDate;
                        data.object[i].checkoutDateOld = data.object[i].checkoutDate;
                        data.object[i].checkinDate = moment(data.object[i].checkinDate, 'YYYY-MM-DD').format('DD/MM/YYYY');
                        data.object[i].checkoutDate = moment(data.object[i].checkoutDate, 'YYYY-MM-DD').format('DD/MM/YYYY');
                        data.object[i].creationtDate = moment(data.object[i].creationtDate).format('DD/MM/YYYY');
                        data.object[i].reservation = data.object[i].reservation ? data.object[i].reservation : { buklyId: "" };

                        data.object[i].overbookingTypeDesc = this.translatePendingStatus(data.object[i].overbookingType);

                        this.pendingReservationsOTA.push(data.object[i]);
                    }
                    //console.log('getOTAPendingReservations',  this.pendingReservationsOTA);
                    break;
                }
            }
        }, error => console.log("Error", error));
    }


    pendingReservationsErrors: any[] = [];
    getInvoicePendingErrors = () => {
        this.reservationsService.getInvoicePendingErrors(this.selectedEstablishment.establishmentId).subscribe(data => {
            switch (data.code) {
                case 200:

                    if (data.object) {
                        if (this.pendingReservationsErrors && data.object.length > 0 && this.pendingReservationsErrors.length != data.object.length)
                            this.hasNewAlerts = true;
                    }

                    this.pendingReservationsErrors = data.object;

                    //console.log('pendingReservationsErrors',  this.pendingReservationsErrors);
                    break;
            }
        }, error => console.log("Error", error));
    }

    notValidatedCheckinData: any[] = [];
    getValidatedCheckin = () => {
        this.reservationsService.getValidatedCheckin(this.selectedEstablishment.establishmentId).subscribe(data => {
            switch (data.code) {
                case 200:

                    if (this.notValidatedCheckinData && data.object.length > 0 && this.notValidatedCheckinData.length != data.object.length)
                        this.hasNewAlerts = true;

                    this.notValidatedCheckinData = data.object;

                    //console.log('notValidatedCheckinData',  this.notValidatedCheckinData);
                    break;
            }
        }, error => console.log("Error", error));
    }


    reservationsNotCheckout: any[] = [];
    getReservationsNotCheckout = () => {
        this.reservationsService.getReservationsNotCheckout(this.selectedEstablishment.establishmentId).subscribe(data => {
            switch (data.code) {
                case 200:

                    if (this.reservationsNotCheckout && data.object.length > 0 && this.reservationsNotCheckout.length != data.object.length)
                        this.hasNewAlerts = true;

                    this.reservationsNotCheckout = data.object;

                    //console.log('reservationsNotCheckout',  this.reservationsNotCheckout);
                    break;
            }
        }, error => console.log("Error", error));
    }


    responsibleNoDocument: any[] = [];
    getResponsibleNoDocument = () => {
        this.reservationsService.getResponsibleNoDocument(this.selectedEstablishment.establishmentId).subscribe(data => {
            switch (data.code) {
                case 200:

                    if (this.responsibleNoDocument && data.object.length > 0 && this.responsibleNoDocument.length != data.object.length)
                        this.hasNewAlerts = true;

                    this.responsibleNoDocument = data.object;

                    //console.log('responsibleNoDocument',  this.responsibleNoDocument);
                    break;
            }
        }, error => console.log("Error", error));
    }

    onSaveResponsible() {
        if (this.responsableAutoEdit == true)
            this.getResponsibleNoDocument();
    }


    integrationErrorsMagikey: any = [];
    getIntegrationErrorMagikey = () => {
        this.reservationsService.getIntegrationErrorMagikey(this.selectedEstablishment.establishmentId).subscribe(data => {
            switch (data.code) {
                case 200:
                    if (data.object) {
                        if (this.integrationErrorsMagikey && data.object.length > 0 && this.integrationErrorsMagikey.length != data.object.length)
                            this.hasNewAlerts = true;
                    }
                    this.integrationErrorsMagikey = data.object;
                    break;
            }
        }, error => console.log("Error", error));
    }

    responsableAutoEdit: boolean = false;
    editResponsibleByReservation(reservationId) {
        this.responsableAutoEdit = true;
        this.currentReservation = { reservationId: reservationId }
        this.currentTemplateModal = this.reservationResponsableTemplate;
    }

    translatePendingStatus = overbookingType => {
        switch (overbookingType) {
            case 'NEW_SUCCESS':
                return this.translate.instant('overbooking_type.new_success');

            case 'NEW_PENDING':
                return this.translate.instant('overbooking_type.new_pending');

            case 'CANCELED_SUCCESS':
                return this.translate.instant('overbooking_type.canceled_success');

            case 'CANCELED_PENDING':
                return this.translate.instant('overbooking_type.canceled_pending');

            case 'UPDATED_SUCCESS':
                return this.translate.instant('overbooking_type.modifield_success');

            case 'UPDATED_PENDING':
                return this.translate.instant('overbooking_type.modifield_pending');

            case 'OPERATION_BUG':
                return this.translate.instant('overbooking_type.error');

            case 'NEW_CARD_SUCCESS':
                return this.translate.instant('overbooking_type.new_card_success');

            case 'NEW_CARD_SUCCESS':
                return this.translate.instant('overbooking_type.new_card_pending');

            default:
                return overbookingType;
        }
    }

    getReservations = () => {
        //this.daysToShow = this.generateDaysToShow(this.quantityDays, this.selectStartDate.format('DD/MM/YYYY'));

        const params = {
            // Adiciona um dia para poder ver o dia anterior ao primeiro dia selecionado
            startDate: moment(this.selectStartDate).subtract(1, 'days').format("YYYY-MM-DD"),
            quantityDays: this.quantityDays,
            category: this.selectCategoria,
            channel: 0,//this.selectChannel
        };

        //busca reservas (montando o objeto) para o mapa de reserva
        this.reservationsService.getReservations(params).subscribe(data => {
            //console.log("GetReservations", data.object);
            this.loading = false
            switch (data.code) {
                case 200:
                    this.getLockedUnits(() => {
                        this.mountReservationMap(data.object);
                        this.initializeScheduler(data.object);
                        if (this.initialLoaded == false) {
                            this.initialLoaded = true;
                            setTimeout(() => {
                                if ((this.notValidatedCheckinData && this.notValidatedCheckinData.length > 0)
                                    || (this.pendingReservationsErrors && this.pendingReservationsErrors.length > 0)
                                    || (this.pendingReservationsOTA && this.pendingReservationsOTA.length > 0)
                                    || (this.reservationsNotCheckout && this.reservationsNotCheckout.length > 0)
                                    || (this.responsibleNoDocument && this.responsibleNoDocument.length > 0)) {

                                    var tabSelectedIndexAutoChanged = sessionStorage.getItem('tabSelectedIndexAutoChanged');
                                    if (!tabSelectedIndexAutoChanged) {
                                        this.tabSelectedIndex = 1;
                                        sessionStorage.setItem('tabSelectedIndexAutoChanged', 'true');
                                    }


                                }

                            }, 500);
                        }
                    });
                    break;
                case 219:
                    console.log('no permission');
                    break;
            }
            this.loading = false
        }, error => {
            this.loading = false
            this.notifier.notify('error', this.translate.instant('reservations.error_load_map'))
            console.log("Error", error)
        });
    }

    collapsedItens: any[] = [];
    initializeScheduler(data) {

        var myunits = [];
        if (this.current_User_Is_Owner_Of_Estabilishment === true) {
            //Filter only units of current user
            var filterData = [];
            data.forEach(element => {

                var copyUnits = JSON.parse(JSON.stringify(element.units));
                copyUnits.forEach(unit => {

                    if (unit.unity && (!unit.unity.employee || (unit.unity.employee && unit.unity.employee.employeeId != this.userInfo.employeeId))) {
                        //Remove unit
                        var findUnit = element.units.find(i => i.unity.unitId == unit.unity.unitId);
                        var indRemove = element.units.indexOf(findUnit);
                        if (indRemove >= 0)
                            element.units.splice(indRemove, 1);
                    } else {
                        myunits.push(unit.unity);
                    }

                });

            });
        }
        console.log('OwnerUnits', myunits);

        var orderPipe = new OrderPipe();
        var data = orderPipe.transform(data, 'category.name', false, true);

        this.collapsedItens = [];
        this.scheduler_resources.forEach(element => {

            if (element.expanded == false) {
                this.collapsedItens.push(element);
            }

            if (element.building != null) {
                element.children.forEach(cat => {
                    if (cat.expanded == false) {
                        this.collapsedItens.push(cat);
                    }
                });
            }
        });

        //console.log('collapsedItens', this.collapsedItens);

        this.scheduler_resources = [];
        this.scheduler_events = [];
        this.scheduler_reservations = [];

        //console.log('blockedDays', this.blockedDays);
        //console.log('data', data);

        this.blockedDays.forEach(item => {
            var id = item.unitLockedId.toString() + item.checkinFormatted;
            if (this.scheduler_events.find(i => i.id == id) == null) {
                this.scheduler_events.push(
                    {
                        id: item.unitLockedId.toString() + item.checkinFormatted,
                        resource: 'UN' + item.unitId,
                        start: moment(item.checkin).format('YYYY-MM-DD') + 'T12:00:00',
                        end: moment(item.checkout).format('YYYY-MM-DD') + 'T12:00:00',
                        text: 'R ' + item.unitLockedId,
                        color: '#0d0d0d', //'#e69138',
                        unitLocked: item
                    });
            }
        });


        data.forEach(element => {
            var totalHidded = 0;
            element.units.forEach(item => {
                if (item.unity.hideOnReservationMap === true)
                    totalHidded++;
            });
            if (totalHidded >= element.units.length)
                element.hideOnReservationMap = true;
            else
                element.hideOnReservationMap = false;
        });

        var hasBuilding = false;
        data.forEach(element => {
            if (element.category && element.category.building) {
                hasBuilding = true;
            }
        });

        data.forEach(element => {

            if (hasBuilding == true && element.category.building == null) {
                element.category.building = {
                    buildingId: 0,
                    nameBuilding: "Prédio Não Definido"
                };
            }

            if (element.hideOnReservationMap == false) {

                var cat = {
                    name: element.category.name,
                    id: 'CAT' + element.category.categoryId.toString(),
                    expanded: true,
                    isGroup: true,
                    category: element.category,
                    tariffs: element.tariffs,
                    children: [],
                };

                var hasExpCat = this.collapsedItens.find(i => i.id == cat.id);
                if (hasExpCat != null) {
                    cat.expanded = false;
                }

                element.units.forEach(item => {
                    if (item.unity.hideOnReservationMap == null || item.unity.hideOnReservationMap == false) {
                        cat.children.push(
                            {
                                name: (this.isSmallScreen ? item.unity.number : item.unity.name),
                                id: 'UN' + item.unity.unitId.toString(),
                                categoryId: element.category.categoryId,
                                unity: item.unity,
                                unavailable: item.unity.status == 'INACTIVE' ? true : false,
                                excludedUnit: item.unity.excludedUnit
                            }
                        );

                        if (item.reservation) {
                            //Carregar todas as ocorrências de reservas
                            //** Duplicadas são reservas quebradas **
                            item.reservation.forEach(res => {
                                res.unitId = item.unity.unitId;
                                this.scheduler_reservations.push(res);
                            });
                        }
                    }




                });

                var building = element.category.building;

                if (building) {

                    var hasRes = this.scheduler_resources.find(i => i.building && i.building.buildingId == building.buildingId);
                    if (hasRes) {
                        hasRes.children.push(cat);
                    } else {
                        var bItem = {
                            name: building.nameBuilding,
                            id: 'BUI' + building.buildingId.toString(),
                            expanded: true,
                            isGroup: true,
                            building: building,
                            children: []
                        };

                        bItem.children.push(cat);


                        var hasExpBuind = this.collapsedItens.find(i => i.id == bItem.id);
                        if (hasExpBuind != null) {
                            bItem.expanded = false;
                        }

                        this.scheduler_resources.push(bItem);
                    }


                } else {
                    this.scheduler_resources.push(cat);
                }



            }

        });

        if (hasBuilding) {
            var orderBuild = new OrderPipe();
            this.scheduler_resources = orderBuild.transform(this.scheduler_resources, 'name', false, true);

        }



        var groupByPipe = new GroupByPipe(this.translate);
        var resGroup = groupByPipe.transform(this.scheduler_reservations, 'reservationId');

        resGroup.forEach(group => {

            var gResPipe = new GroupByPipe(this.translate);
            var resGItens = gResPipe.transform(group.itens, 'unitId');

            resGItens.forEach(unityG => {

                var currentSpaceIndex = 1;
                for (let index = 0; index < unityG.itens.length; index++) {

                    const currElement = unityG.itens[index];
                    currElement.currentSpaceIndex = unityG.name + "-" + currentSpaceIndex.toString();

                    const nextElement = unityG.itens[index + 1];
                    if (nextElement != null) {
                        var currDt = moment(currElement.date);
                        var nextDt = moment(nextElement.date);
                        var diffDays = nextDt.diff(currDt, 'days');

                        if (diffDays > 1) {
                            currentSpaceIndex++;
                        }

                        // if(currElement.reservationId == 4784){
                        //     console.log(currElement);
                        // }
                    }
                }

                ////console.log('unityG.itens', unityG.itens);
                var resFinalItens = gResPipe.transform(unityG.itens, 'currentSpaceIndex');

                resFinalItens.forEach(finalGroup => {

                    var initial = finalGroup.itens[0];
                    var final = finalGroup.itens[finalGroup.itens.length - 1];


                    this.scheduler_events.push(
                        {
                            id: initial.reservationId.toString() + '-' + finalGroup.name,
                            resource: 'UN' + initial.unitId.toString(),
                            unitId: initial.unitId,
                            start: moment(initial.date.substring(0, 10)).format('YYYY-MM-DD') + 'T12:00:00',
                            end: moment(final.date.substring(0, 10)).add("1", "days").format('YYYY-MM-DD') + 'T12:00:00',
                            text: 'R ' + initial.reservationId,
                            color: this.getColorValue(initial), //'#e69138',
                            reservation: initial,
                            isBroken: resGItens.length > 1
                        });

                });



            });

        });


        //console.log('this.scheduler_reservations', this.scheduler_reservations);
        //console.log('this.scheduler_events', this.scheduler_events);

        this.scheduler.initializeScheduler();
        this.openPageDetails();
    }

    getColorValue(res) {
        switch (res.reservationStatus) {
            case 'PRE_RESERVED':
                return '#e64b9d';
            case 'FULL_PAYMENT':
                return '#40c96a';
            case 'NO_PAYMENT':
                return '#e85454';
            case 'PENDING_PAYMENT':
                return '#f1e74c';
        }
    }


    strLimit(str, limit) {
        return str.length > limit ? str.substr(0, limit - 3) + '...' : str
    }

    showFilters() {
        $("#modal-filters").modal("show");
    }

    filtrarMapa = () => {
        this.loading = true
        //this.daysToShow = this.generateDaysToShow(this.quantityDays, this.selectStartDate.format('DD/MM/YYYY'));

        const params = {
            // Adiciona um dia para poder ver o dia anterior ao primeiro dia selecionado
            startDate: this.selectStartDate.format('DD/MM/YYYY'),
            quantityDays: this.quantityDays,
            category: this.selectCategoria,
            channel: 0,//this.selectChannel
        };

        sessionStorage.setItem("reservation_filter", JSON.stringify(params));

        params.startDate = moment(this.selectStartDate).subtract(1, 'days').format("YYYY-MM-DD"),

            this.reservationsService.getReservations(params).subscribe(data => {
                //console.log("Reservations", data.object);
                switch (data.code) {
                    case 200:
                        this.getLockedUnits(() => {
                            this.mountReservationMap(data.object);
                            this.initializeScheduler(data.object);
                            $("#modal-filters").modal("hide");
                        });
                        break;
                    case 219:
                        console.log('no permission');
                        break;
                }
                this.loading = false
            }, error => {
                this.loading = false
                this.notifier.notify('error', this.translate.instant('reservations.error_filter_map'))
                console.log("Error", error)
            });
    }

    mountReservationMap = reservation => {
        this.lastMapResponse = reservation


        reservation.forEach(element => {
            if (!element.units) {
                element.units = [];
            }
        });


        // //percorre o retorno do servico de reservas
        // for (let i = reservation.length - 1; i >= 0; i--)
        // {
        //     //adicionar um array com os dias a serem exibidos
        //     reservation[i].daysToShow = this.generateDaysToShow(this.quantityDays, this.selectStartDate.format('DD/MM/YYYY'));
        //     //percorre cada uma das tarifas
        //     reservation[i].daysToShow = this.reservationsService.getDayTariffary(reservation[i].daysToShow, reservation[i].tariffs);

        //     //percorre os quartos para gerar o array de dias dentro de cada um
        //     for (let y = reservation[i].units.length - 1; y >= 0; y--)
        //     {
        //         reservation[i].units[y].daysToShow = this.generateDaysToShow(this.quantityDays, this.selectStartDate.format('DD/MM/YYYY'), reservation[i].units[y].unity.unitId);
        //         reservation[i].units[y].daysToShow = this.reservationsService.getDayTariffary(reservation[i].units[y].daysToShow, reservation[i].tariffs);
        //         //caso tenha reserva percorre para preencher
        //         if (reservation[i].units[y].reservation) {
        //             reservation[i].units[y].daysToShow = this.reservationsService.getDaysReservations(reservation[i].units[y].daysToShow, reservation[i].units[y].reservation );
        //         }
        //     }
        // }

        var orderPipe = new OrderPipe();
        this.reservations = orderPipe.transform(reservation, 'category.name', false, true);

        this.setReservationsFlags();
        //console.log("Reservations", this.reservations);
    }

    setReservationsFlags() {
        this.reservations.forEach(res => {
            var rs = res.units.filter(i => i.unity.hideOnReservationMap == true);
            if (rs.length >= res.units.length) {
                res.hideOnReservationMap = true;
            } else {
                res.hideOnReservationMap = false;
            }
        });
    }

    showCateg = idCategory => this.showCategory.push(idCategory);

    hideCateg = idCategory => {
        const index = this.showCategory.indexOf(idCategory)
        if (index != -1)
            this.showCategory.splice(index, 1);
    }

    scrollMap(dir) {
        if (dir === 'left') {
            $("#reservation-map").scrollLeft($("#reservation-map").scrollLeft() - 200)
        } else {
            $("#reservation-map").scrollLeft($("#reservation-map").scrollLeft() + 200)
        }
    }

    reservationDetailsModal: any;
    /* #region Map Click */
    schedulerEventClicked(data, reservationDetailsModal, blockedDetailsModal) {
        this.reservationDetailsModal = reservationDetailsModal;
        //console.log('data:',data);

        if (data.unitLocked) {
            this.currentBlockedDetail = data.unitLocked;
            this.openModal(blockedDetailsModal);
            return;
        }

        if (data.reservation) {
            if (data.reservation.reservationStatus == "PRE_RESERVED")
                return this.showPreReservationDetails(data.reservation);
            else
                return this.showReservationDetails(data.reservation);
        }

    }

    onEventMoved(args, transferReservationModal) {
        console.log('Event:', args);

        if (this.current_User_Is_Owner_Of_Estabilishment)
            return this.filtrarMapa();

        if (args.data.reservation) {

            if (args.data.isBroken == true) {
                this.notifier.notify('error', `${this.translate.instant('error_msg.error_resize_broken_reservation')}`);
                this.filtrarMapa();
                return;
            }

            if (args.data.reservation.reservationStatus == "PRE_RESERVED") {
                this.notifier.notify('error', `${this.translate.instant('error_msg.error_resize_prereservation')}`);
                this.filtrarMapa();
                return;
            }

            var checkinDate = args.data.reservation.checkinDate.substring(0, 10);
            var checkoutDate = args.data.reservation.checkoutDate.substring(0, 10);
            var newStart = args.newStart.substring(0, 10);
            var newEnd = args.newEnd.substring(0, 10);

            if (args.newUnit) {
                //console.log('args.newCategory.category', args.newCategory.category);
                this.transferReservation(transferReservationModal, args.data.reservation.reservationId, args.newCategory.category, args.newUnit.unity, newStart, newEnd);
            }
            else
                this.filtrarMapa();
        }
        else {
            this.filtrarMapa();
        }
    }

    onEventResized(args) {

        if (this.current_User_Is_Owner_Of_Estabilishment)
            return this.filtrarMapa();

        if (args.data.reservation) {


            if (args.data.isBroken == true) {
                this.notifier.notify('error', `${this.translate.instant('error_msg.error_resize_broken_reservation')}`);
                this.filtrarMapa();
                return;
            }

            if (args.data.reservation.reservationStatus == "PRE_RESERVED") {
                this.notifier.notify('error', `${this.translate.instant('error_msg.error_resize_prereservation')}`);
                this.filtrarMapa();
                return;
            }


            var checkinDate = args.data.reservation.checkinDate.substring(0, 10);
            var checkoutDate = args.data.reservation.checkoutDate.substring(0, 10);
            var newStart = args.newStart.substring(0, 10);
            var newEnd = args.newEnd.substring(0, 10);
            var editTime = '';
            var newTime: any;

            if (checkinDate != newStart) {
                editTime = 'checkin';
                newTime = newStart;
            }
            else if (checkoutDate != newEnd) {
                editTime = 'checkout';
                newTime = newEnd;
            }

            if (editTime != '') {

                this.callResize(args.data.reservation.reservationId, newTime, editTime);

            }

        }
        else {
            this.filtrarMapa();
        }
    }

    callResize(reservationId, newTime, editTime, resizeWithoutReversing = false) {
        this.loading = true;
        this.reservationsService.updateCheckinCheckout_Date(reservationId, newTime, editTime, resizeWithoutReversing).subscribe(ret => {
            this.loading = false;
            if (ret.code === 200) {
                this.notifier.notify('success', `${this.translate.instant('date_of')} ${editTime} ${this.translate.instant('success_changed')}`)
                this.filtrarMapa();
            }
        }, error => {
            this.loading = false;
            this.filtrarMapa();

            if (!error.error) {
                this.notifier.notify('error', `${this.translate.instant('error_msg.error_change_date')} ${editTime}`)
                return;
            }

            if (error.error.object && error.error.object.resizeOnlyNotReversal === true) {
                this.confirmMinimizeResize(reservationId, newTime, editTime);
            }

        });
    }

    confirmMinimizeResize(reservationId, newTime, editTime) {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn'
            },
            buttonsStyling: false
        })
        swalWithBootstrapButtons.fire({
            position: 'center',
            title: this.translate.instant('resize_modal.confirm_text'),
            html:  this.translate.instant('resize_modal.info'),
            icon: 'question',
            showCancelButton: true,
            confirmButtonText:this.translate.instant('resize_modal.yes'),
            cancelButtonText: this.translate.instant('no'),
            reverseButtons: true
        }).then((result) => {
            if (result.value == true) {
                this.callResize(reservationId, newTime, editTime, true);
            } else if (
                result.dismiss === Swal.DismissReason.cancel) {
            }
        });
    }



    reservationModal: any;
    async schedulerRangeSelected(args, reservationModal) {

        this.reservationModal = reservationModal;
        //console.log('onTimeRangeSelected_Params: ', args);

        var start = moment(args.start, 'YYYY-MM-DD');
        var end = moment(args.end, 'YYYY-MM-DD');

        const start_yesterday = moment(args.start).subtract(1, 'days');
        const end_yesterday = moment(args.end).subtract(1, 'days');

        this.startReservationDay = {
            day: start.format('DD'),
            dia: start.format('DD'),
            mes: start.format('MM'),
            ano: start.format('YYYY'),
            mes_num: start.format('MM'),
            dayb: start_yesterday.format('DD'),
            mesb: start_yesterday.format('MM'),
            anob: start_yesterday.format('YYYY'),
            unitId: args.unitId
        };

        this.finishReservationDay = {
            day: end.format('DD'),
            month: end.format('MM'),
            year: end.format('YYYY'),
            night: end_yesterday.format('DD'),
            nightMonth: end_yesterday.format('MM'),
            nightYear: end_yesterday.format('YYYY'),
            unitId: args.unitId
        };
        this.minChekOutDate = moment(`${this.startReservationDay.ano}-${this.startReservationDay.mes}-${this.startReservationDay.day}`).add(1, 'days');
        this.startReservationDate = moment(`${this.startReservationDay.ano}-${this.startReservationDay.mes}-${this.startReservationDay.day}`);
        this.finishReservationDate = moment(`${this.finishReservationDay.year}-${this.finishReservationDay.month}-${this.finishReservationDay.day}`);


        //console.log('startReservationDay:', this.startReservationDay);
        //console.log('finishReservationDay:', this.finishReservationDay);


        if (args.infoResource == null) {
            this.mostrarModalCanais(this.startReservationDay, args.category.categoryId);
            this.scheduler.initializeScheduler();
            return;
        }


        var unityEvents = this.scheduler_events.filter(i => i.resource == args.unitId);

        var nodisp: boolean;
        var qtdays_selected_period = end.diff(start, 'days');
        //console.log('qtdays_selected_period: ', qtdays_selected_period);
        var lstDays = [];
        var dtAux = moment(args.start);
        lstDays.push(dtAux.format('YYYY-MM-DDTHH:mm:ss'));
        for (let i = 0; i < qtdays_selected_period; i++) {
            dtAux = moment(dtAux.add(1, 'day'));
            lstDays.push(dtAux.format('YYYY-MM-DDTHH:mm:ss'));



            unityEvents.forEach(event => {

                if (moment(dtAux.format('YYYY-MM-DDTHH:mm:ss')).isBetween(moment(event.start).format('YYYY-MM-DDTHH:mm:ss'), moment(event.end).format('YYYY-MM-DDTHH:mm:ss'))) {
                    nodisp = true;
                }

            });

        }

        //console.log('lstDays:', lstDays);

        if (nodisp == true) {
            Swal.fire(this.translate.instant('reservations.impossible_to_create'),
                this.translate.instant('reservations.unavailable_date'), 'warning');
            return;

        }



        this.modalInfo = null;
        if (args.infoResource && args.infoResource.unity) {
            this.modalInfo = {
                room: { unity: args.infoResource.unity },
                category: args.infoResource.unity.category
            };
        }


        this.finishReservationDay.categoryId = args.infoResource.categoryId;
        this.selectSource = 'BALCAO';

        var balcao = this.channels.find(i => i.buklyChannelCode == null);
        if (balcao) {
            this.selectChannel = balcao;
        } else {
            this.selectChannel = null;
            Swal.fire("Oop's", this.translate.instant('reservations.msg_error_no_channel'), 'error');
            return;
        }

        // ABRINDO MODAIS DE RESERVA

        var diffStart = moment().diff(moment(args.start), 'days');
        if(diffStart > 0){
            var respContinue = await Swal.fire({
                position: 'center',
                title: this.translate.instant('reservations.confirm_previous'),
                text: this.translate.instant('reservations.confirm_previous_info'),
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: this.translate.instant('occupation.ok_confirm'),
                cancelButtonText: 'Não',
                reverseButtons: true
              }).then();

            if(!respContinue.value) return;
        }
    


        this.loadTariffsToReservation(
            `${this.startReservationDay.ano}-${this.startReservationDay.mes}-${this.startReservationDay.day}`,
            moment(end_yesterday).format('YYYY-MM-DD'), args.infoResource.categoryId)
            .then(ret => {
                if (ret.modalOpen) {
                    //$(ret.modalOpen).modal("show");
                    this.openModal(this.reservationModal);
                }
            }, err => { });

    }

    refreshTariffs() {
        this.loadTariffsToReservation(
            `${this.startReservationDay.ano}-${this.startReservationDay.mes}-${this.startReservationDay.day}`,
            `${this.finishReservationDay.nightYear}-${this.finishReservationDay.nightMonth}-${this.finishReservationDay.night}`, this.finishReservationDay.categoryId
        ).then(ret => { }, err => { });
    }

    loadTariffsToReservation(start, end, categoryId): Promise<any> {

        return new Promise((resolve, reject) => {


            this.reservationsService.getReservationTariff_FromDateFormated(start, end, categoryId, this.selectChannel).subscribe(data => {
                //console.log('lista', data);
                switch (data.code) {
                    case 200:
                        {
                            this.readOnly = true;
                            this.reservationPrice = [];
                            this.makingReservationPrice = 0;
                            this.makingReservationGuestsQuantity = 1;

                            const checkinDate = moment(`${this.startReservationDay.ano}-${this.startReservationDay.mes}-${this.startReservationDay.day}`);
                            const checkoutDate = moment(`${this.finishReservationDay.year}-${this.finishReservationDay.month}-${this.finishReservationDay.day}`);
                            var totalDays = checkoutDate.diff(checkinDate, 'days');

                            for (let tariff of data.object) {
                                //console.log("THIS CHANNEL", tariff.channel.name)
                                this.reservationPrice.push(tariff.tariff);
                                this.makingReservationPrice += tariff.tariff.price;
                            }


                            //console.log('selectChannel', this.selectChannel);
                            //console.log('reservationPrice', this.reservationPrice);
                            //console.log('totalDays', totalDays);

                            resolve({ data: data, modalOpen: '#modal-reservation' });

                            if (data.object.length != totalDays) {
                                var channelName = this.selectChannel.name;
                                Swal.fire("Oop's", this.translate.instant('reservations.msg_error_tariffis_to_open_html').replace('[channel]', channelName), 'error');
                                this.selectChannel = null;
                            }

                            break;

                        }



                }
            }, error => {
                console.log("Error");
                reject(error);
            }
            );

        });
    }


    // validPeriod = (checkin, checkout, indexReservation, indexDays) =>
    // {
    //     //console.log('validPeriod', checkin)
    //     const checkinDate = moment(`${checkin.ano}-${checkin.mes}-${checkin.day}`);
    //     const checkoutDate = moment(`${checkout.ano}-${checkout.mes_num}-${checkout.dia}`);

    //     //console.log('checkinDate', checkinDate)
    //     //console.log('checkoutDate', checkoutDate)

    //     let blockedDay = this.blockedDays.find(bDay => {
    //         return bDay.unitId == checkin.unitId && bDay.checkin.isBetween(checkinDate, checkoutDate, 'days');
    //     });

    //     if(blockedDay) {
    //         return false
    //     }

    //     for(let i = 0; i < this.reservations[indexReservation].units[indexDays].daysToShow.length; i++)
    //     {
    //         const reservation = this.reservations[indexReservation].units[indexDays].daysToShow[i].hasReservation;
    //         const day = this.reservations[indexReservation].units[indexDays].daysToShow[i].dia;
    //         const month = this.reservations[indexReservation].units[indexDays].daysToShow[i].mes_num;
    //         const year = this.reservations[indexReservation].units[indexDays].daysToShow[i].ano;
    //         const date = moment(`${year}-${month}-${day}`);



    //         if (date.isAfter(checkinDate) && date.isBefore(checkoutDate) && reservation)
    //         {
    //             console.log("False em: ", this.reservations[indexReservation].units[indexDays].daysToShow[i]);
    //             return false;
    //         }

    //     }

    //     return true;
    // }

    removeSelectedDay = () => {
        this.startReservationDay = {};
        this.finishReservationDay = {};
        this.minChekOutDate = null;
        this.finishReservationDate = null;
    }

    /* #endregion */

    /* #region Reservation Manipulation */

    showReservationDetails = day => {
        this.removeSelectedDay();

        //console.log(day);
        this.loading = true;
        this.reservationsService.getReservationInfo(day.reservationId).subscribe(data => {
            this.loading = false;
            //console.log("Informações da Reserva", data);
            switch (data.code) {
                case 200:
                    const checkin = moment(data.object.checkinDate, 'YYYY-MM-DD').format("DD/MM/YYYY");
                    const finalDate = moment(data.object.finalDates[data.object.finalDates.length - 1], 'YYYY-MM-DD').format("DD/MM/YYYY");
                    const checkout = moment(data.object.checkoutDate, 'YYYY-MM-DD').format("DD/MM/YYYY");

                    let unitStr = '';

                    for (let i = 0; i < data.object.units.length; i++) {
                        if (unitStr == '')
                            unitStr += data.object.units[i].name;
                        else
                            unitStr += ', ' + data.object.units[i].name;
                    }

                    //console.log('unitStr', unitStr);

                    this.reservationInfoModal = {
                        reservationId: data.object.reservationId,
                        checkin: checkin,
                        finalDate: finalDate,
                        checkout: checkout,
                        price: data.object.price,
                        category: data.object.units[0].category,
                        channel: data.object.channel,
                        unit: unitStr,
                        unitIds: [data.object.units[0].unitId],
                        status: day.reservationStatus,
                        responsible: data.object.responsible.name,
                        guestQuantities: data.object.guestsQuantity,
                        buklyId: data.object.buklyId || null,
                        localizer: data.object.localizer,
                        groupReservation: data.object.groupReservation,
                        salesChannel: data.object.salesChannel
                    };

                    //console.log("IfnoModal", this.reservationInfoModal);

                    this.openModal(this.reservationDetailsModal);
                    break;
            }
        }, error => {
            this.loading = false;
            console.log("Error", error)

        });

    }

    makeReservation = () => {
        if (!this.selectChannel) {
            Swal.fire("Oop's", this.translate.instant('reservations.msg_error_no_channel'), 'error');
            return;
        }
        const unitsArray = [];
        unitsArray.push(this.modalInfo.room.unity.unitId);

        const model = {
            checkin: `${this.startReservationDay.day}/${this.startReservationDay.mes}/${this.startReservationDay.ano}`,
            finalDate: `${this.finishReservationDay.night}/${this.finishReservationDay.nightMonth}/${this.finishReservationDay.nightYear}`,
            checkout: `${this.finishReservationDay.day}/${this.finishReservationDay.month}/${this.finishReservationDay.year}`,
            price: this.makingReservationPrice,
            category: this.modalInfo.category,
            channel: this.selectChannel,
            unit: this.modalInfo.room.unity.name,
            unitIds: unitsArray,
            guestQuantities: this.makingReservationGuestsQuantity,
            salesChannel: this.selectSource
        };

        this.currentReservationEffectCommand = false;
        this.currentReservation = model;

        this.dialog.closeAll();
        //return this.router.navigate([`app/${this.appStateService.currentEstablishmentId}/reservations/responsable`]);
        this.goResponsableDetails();
    }

    editReservationResponsible(reservation, template) {
        this.currentReservation = reservation;
        this.goResponsableDetails();
        this.dialog.closeAll();
        //return this.router.navigate([`app/${this.appStateService.currentEstablishmentId}/reservations/responsable/${reservation.reservationId}`]);
    }

    editReservationPayments = reservation => {
        this.currentReservation = reservation;
        this.goPaymentDetails();
        this.dialog.closeAll();
    }

    reservationDetails = reservation => {
        this.currentReservation = reservation;
        this.goSummaryDetails();
        this.dialog.closeAll();
        //return this.router.navigate([`app/${this.appStateService.currentEstablishmentId}/reservations/summary/${reservation.reservationId}`]);
    }

    /* #endregion */

    // block/lock

    getLockedUnits(onFinish) {
        // Get locked units
        let startDate = moment(this.selectStartDate).format('YYYY-MM-DD')
        let endDate = moment(this.selectStartDate).add(this.quantityDays, 'days').format('YYYY-MM-DD')

        this.blockedDays = [];

        this.reservationsService.getLockedUnits(this.selectedEstablishment.establishmentId, startDate, endDate).subscribe(data => {
            if (data.code === 200) {
                data.object.forEach(lockedUnit => {

                    if (lockedUnit.status !== 'ACTIVE') {
                        return
                    }

                    let initDateofBlock = moment(lockedUnit.initDateofBlock, 'YYYY-MM-DD')
                    let endDateOfBlock = moment(lockedUnit.endDateOfBlock, 'YYYY-MM-DD')

                    let _blockedModel: BlockModel = {
                        checkin: initDateofBlock,
                        checkout: endDateOfBlock,
                        checkinFormatted: initDateofBlock.format('DD/MM/YYYY'),
                        checkoutFormatted: endDateOfBlock.format('DD/MM/YYYY'),
                        blockedIn: moment(lockedUnit.dateofAction),
                        unit: 'unitName',
                        unitId: lockedUnit.unitId,
                        unitLockedId: lockedUnit.unitLockedId,
                        category: null,
                        reason: lockedUnit.blockReason,
                        responsible: lockedUnit.employee,
                        unitName: lockedUnit.unit
                    }
                    this.blockedDays.push(_blockedModel);

                })
            } else {
                this.notifier.notify('error', this.translate.instant('reservations.error_load_bloked_unities'));
            }
            onFinish();
            this.loading = false
        }, error => {
            this.loading = false
            onFinish();
            this.notifier.notify('error', this.translate.instant('reservations.error_load_bloked_unities'));
        })
    }

    openBlockModal = (blockReservationModal) => {
        this.blockModel = {
            checkin: moment(new Date(this.startReservationDay.ano, this.startReservationDay.mes - 1, this.startReservationDay.day)),
            checkout: moment(new Date(this.finishReservationDay.year, this.finishReservationDay.month - 1, this.finishReservationDay.day)),
            blockedIn: moment(),
            unit: this.modalInfo.room.unity.name,
            unitId: this.modalInfo.room.unity.unitId,
            category: this.modalInfo.category,
            reason: '',
            unitName: this.modalInfo.category.name + '<BR>' + this.modalInfo.unit
        };

        this.blockModel.checkinFormatted = this.blockModel.checkin.format('DD/MM/YYYY');
        this.blockModel.checkoutFormatted = this.blockModel.checkout.format('DD/MM/YYYY');

        this.dialog.closeAll();

        this.openModal(blockReservationModal);
    }

    refreshReservations() {
        this.reservations = null;
        this.mountReservationMap(this.lastMapResponse)
        this.initializeScheduler(this.lastMapResponse);
    }

    blockReservation = () => {
        const body = {
            unitLockedId: 0,
            dateofAction: moment().format('YYYY-MM-DD'),
            initDateofBlock: this.blockModel.checkin.format('YYYY-MM-DD'),
            endDateOfBlock: this.blockModel.checkout.format('YYYY-MM-DD'),
            blockReason: this.blockModel.reason,
            responsibleId: this.userInfo.employeeId
        }

        this.loading = true

        //console.log('blockModel', this.blockModel)

        this.reservationsService.lockUnit(this.blockModel.unitId, body).subscribe(data => {
            if (data.code === 200) {
                this.blockModel.blockedIn = moment();
                this.blockModel.unitLockedId = data.object.unitLockedId;
                this.blockModel.responsible = this.userInfo;
                this.blockedDays.push(this.blockModel);
                this.blockModel = null;
                this.dialog.closeAll();
                this.removeSelectedDay();
                this.filtrarMapa();
            } else {
                this.notifier.notify('error', this.translate.instant('reservations.error_blocked_unity'));
            }
            this.loading = false
        },
            error => {
                this.loading = false
                this.notifier.notify('error', this.translate.instant('reservations.error_blocked_unity'));
            })

    }

    unblock = () => {
        this.reservationsService.unlockUnit(this.currentBlockedDetail.unitLockedId).subscribe(
            data => {
                if (data.code === 200) {
                    let current = this.currentBlockedDetail;
                    let index = this.blockedDays.findIndex(bDay => {
                        return bDay.unitId == current.unitId && bDay.checkin == current.checkin;
                    });

                    if (index > -1) {
                        this.blockedDays.splice(index, 1);
                    }

                    this.filtrarMapa();
                    this.dialog.closeAll();
                } else {
                    this.notifier.notify('error', this.translate.instant('reservations.error_unblock_unity'));
                }
            }, error => {
                this.notifier.notify('error', this.translate.instant('reservations.error_unblock_unity'));
            })

    }

    checkBlockedDay = (date, unitId) => {

        let checkin = null
        let checkout = null

        if (this.blockedDays && this.blockedDays.length > 0) {

            let blockedDay = this.blockedDays.find(bDay => {
                return bDay.unitId == unitId && bDay.checkin.isSame(date, 'day');
            });
            if (blockedDay) {
                checkin = { status: 1, blockedDay };
            }

            blockedDay = this.blockedDays.find(bDay => {
                return bDay.unitId == unitId && bDay.checkout.isSame(date, 'day');
            });

            if (blockedDay) {
                checkout = { status: 3, blockedDay };
            }

            if (checkin || checkout) {
                if (checkin && checkout) {
                    return { status: 4, blockedDay: checkin.blockedDay }
                } else if (checkin) {
                    return checkin
                } else if (checkout) {
                    return checkout
                }
            }

            blockedDay = this.blockedDays.find(bDay => {
                return bDay.unitId == unitId && date.isBetween(bDay.checkin, bDay.checkout, 'days');
            });

            if (blockedDay) {
                return { status: 2, blockedDay };
            }
        }
        return false;
    }

    shouldShowLastSphere(day, startReservationDay, room) {
        if (day.lastCheckinMobile) {
            const lastId = ((day.checkOutDay && !(startReservationDay.day == day.dia && startReservationDay.mes == day.mes_num && startReservationDay.unitId == room.unity.unitId)) ? day.lastReservationId : day.dayBeforeReservationId)
            if (!day.reservationId || lastId != day.reservationId) {
                return true
            }
        }
        return false
    }

    // generateDaysToShow = (qtdDias, startDay, verifyBlockedDay: any = false) =>
    // {
    //     const auxDias = [];

    //     for (let z = 0; z < qtdDias; z++)
    //     {
    //         const today = startDay ? moment(startDay, "DD/MM/YYYY") : moment();
    //         const todayaux = startDay ? moment(startDay, "DD/MM/YYYY") : moment();
    //         const future = today.add(z, 'days').locale(this.translate.currentLang.replace('_', '-')).format('DD-MMMM-MM-YYYY-ddd').split('-');
    //         const yesterday = todayaux.add(z-1, 'days').locale(this.translate.currentLang.replace('_', '-')).format('DD-MMMM-MM-YYYY').split('-');

    //         const day = {
    //             dia: future[0],
    //             mes: future[1].substring(0, 3),
    //             ano: future[3],
    //             diab: yesterday[0],
    //             mesb: yesterday[2],
    //             anob: yesterday[3],
    //             mes_num: future[2],
    //             week: future[4],
    //             hasTariff: false,
    //             checkOutDay: false,
    //             tariffValue: '',
    //             hasReservation: false,
    //             dayBeforeHasReservation: false,
    //             blockedStatus: null,
    //         };

    //         if(verifyBlockedDay) {
    //             let date = moment(new Date(parseInt(day.ano), parseInt(day.mes_num) - 1, parseInt(day.dia)));
    //             day.blockedStatus = this.checkBlockedDay(date, verifyBlockedDay);
    //         }

    //         auxDias.push(day);
    //     }
    //     return auxDias;
    // }

    /* #region Preservation */

    canSavePreServation() {
        if (this.preReservationModel) {
            if (this.preReservationModel.responsibleName.length > 2
                && validateEmail(this.preReservationModel.responsibleEmai)
                && this.preReservationModel.responsiblePhone.length > 2
                && this.preReservationModel.reservationPrice > 0
                && this.preReservationModel.limit_date && this.preReservationModel.limit_time
            ) {
                return true
            }
        }
        return false;
    }

    openPreReservationModal = (preReservationModal) => {
        if (!this.selectChannel) {
            Swal.fire("Oop's", this.translate.instant('reservations.msg_error_no_channel'), 'error');
            return;
        }

        this.preReservationModel = {
            checkin: new Date(this.startReservationDay.ano, this.startReservationDay.mes - 1, this.startReservationDay.day),
            finalDate: new Date(this.finishReservationDay.nightYear, this.finishReservationDay.nightMonth - 1, this.finishReservationDay.night),
            checkout: new Date(this.finishReservationDay.year, this.finishReservationDay.month - 1, this.finishReservationDay.day),
            unit: this.modalInfo.room.unity.name,
            unitIds: [this.modalInfo.room.unity.unitId],
            category: this.modalInfo.category,
            channel: this.selectChannel,
            reservationPrice: this.makingReservationPrice,
            guestQuantities: this.makingReservationGuestsQuantity,
            responsibleName: '',
            responsibleEmai: '',
            responsiblePhone: '',
            editing: false,
        };

        this.preReservationModel.checkin_formatted = moment(this.preReservationModel.checkin, 'YYYY-MM-DD').format('DD/MM/YYYY');
        this.preReservationModel.checkout_formatted = moment(this.preReservationModel.checkout, 'YYYY-MM-DD').format('DD/MM/YYYY');

        this.dialog.closeAll();
        this.openModal(preReservationModal);
        //console.log(this.preReservationModel);
    }

    showPreReservationDetails = day => {
        this.loading = true;
        this.reservationsService.getPreReservationInfo(day.reservationId).subscribe(data => {
            this.loading = false;
            //console.log("Preservation", data.object);

            switch (data.code) {
                case 200:
                    let unitStr = '';

                    for (let i = 0; i < data.object.units.length; i++) {
                        if (unitStr == '')
                            unitStr += data.object.units[i].name;
                        else
                            unitStr += ', ' + data.object.units[i].name;
                    }

                    //console.log('unitStr', unitStr);

                    this.reservationInfoModal = {
                        reservationId: data.object.reservationId,
                        checkout: moment(data.object.checkoutDate, 'YYYY-MM-DD').format("DD/MM/YYYY"),
                        checkin: moment(data.object.checkinDate, 'YYYY-MM-DD').format("DD/MM/YYYY"),
                        price: data.object.price,
                        category: data.object.units[0].category,
                        channel: data.object.channel,
                        unit: unitStr,
                        unitIds: [data.object.units[0].unitId],
                        status: day.reservationStatus,
                        responsible: data.object.responsibleName,
                        guestQuantities: data.object.guestsQuantity,
                    }
                    break;
            }
        }, error => {
            this.loading = false;
            console.log("Error")

        });
        this.openModal(this.reservationDetailsModal);
    }

    

    makePreReservation = () => {
        //console.log("PreReservationModel: ", this.preReservationModel);

        var limit_date_formated = moment(this.preReservationModel.limit_date).format('YYYY-MM-DD');
        const currentDeadline = moment(`${limit_date_formated}T${this.preReservationModel.limit_time}:00`, 'YYYY-MM-DDTHH:mm:ss')

        const model = {
            observation: this.preReservationModel.observation,
            deadline: `${currentDeadline.utcOffset(-3).format('YYYY-MM-DDTHH:mm:ss')}.000-0300`,
            responsibleName: this.preReservationModel.responsibleName,
            responsibleEmai: this.preReservationModel.responsibleEmai,
            responsiblePhone: this.preReservationModel.responsiblePhone,
            initialDate: moment(this.preReservationModel.checkin).format("YYYY-MM-DDTHH:mm:ss"),
            finalDate: moment(this.preReservationModel.finalDate).format("YYYY-MM-DDTHH:mm:ss"),
            unitIds: this.preReservationModel.unitIds,
            guestQuantities: [this.preReservationModel.guestQuantities],
            responsibleId: null,
            reservationPrice: this.preReservationModel.reservationPrice,
            channelId: this.preReservationModel.channel.channelId,
        };

        //console.log('PreReservationModel', this.preReservationModel);
        //console.log(model);

        this.loading = true;
        this.reservationsService.makePreReservation(model)
            .subscribe(data => {
                this.loading = false;
                //console.log(data);
                switch (data.code) {
                    case 200:
                        this.notifier.notify('success', this.translate.instant('reservations.pre-booking_made'));
                        this.startReservationDay = {};
                        this.finishReservationDay = {};
                        this.minChekOutDate = null;
                        this.finishReservationDate = null;
                        this.getReservations();
                        this.dialog.closeAll();
                        break;
                    case 228:
                        this.dialog.closeAll();
                        Swal.fire(this.translate.instant('reservations.impossible_to_create'),
                            this.translate.instant('reservations.unavailable_date'), 'warning');
                        this.removeSelectedDay();
                    default:
                        this.notifier.notify('error', this.translate.instant('reservations.error_pre-booking_made'));

                }
            }, error => {
                this.notifier.notify('error', this.translate.instant('reservations.error_pre-booking_made'));
                console.log("Error")
                this.loading = false;
            });
    }

    editPreReservation(reservationId, preReservationModal) {
        this.loading = true;
        this.reservationsService.getPreReservationInfo(reservationId).subscribe(data => {
            this.loading = false;
            //console.log("PreReservationData", data.object);
            switch (data.code) {
                case 200:
                    this.preReservationModel = {
                        reservationId: data.object.reservationId,
                        checkin: data.object.checkinDate,
                        checkout: data.object.checkoutDate,
                        finalDate: data.object.finalDates[data.object.finalDates.length - 1],
                        unit: data.object.units[0].name,
                        unitIds: [data.object.units[0].unitId],
                        reservationPrice: data.object.price,
                        channel: data.object.channel,
                        responsibleName: data.object.responsibleName,
                        responsibleEmai: data.object.responsibleEmail,
                        responsiblePhone: data.object.responsiblePhone,
                        guestQuantities: data.object.guestsQuantity,
                        observation: data.object.observation,
                        category: data.object.units[0].category,
                        limit_date: moment(data.object.deadline).format("YYYY-MM-DD"),
                        limit_time: moment(data.object.deadline).format("HH:mm"),
                        editing: true
                    };

                    this.preReservationModel.checkin_formatted = moment(this.preReservationModel.checkin, 'YYYY-MM-DD').format('DD/MM/YYYY');
                    this.preReservationModel.checkout_formatted = moment(this.preReservationModel.checkout, 'YYYY-MM-DD').format('DD/MM/YYYY');

                    //console.log(data.object);
                    this.dialog.closeAll();
                    this.openModal(preReservationModal);
                    break;
            }
        }, error => { console.log("Error"); this.loading = false; });
    }

    sendEditPreReservation = () => {
        var limit_date_formated = moment(this.preReservationModel.limit_date).format('YYYY-MM-DD');

        const currentDeadline = moment(`${limit_date_formated}T${this.preReservationModel.limit_time}:00`, 'YYYY-MM-DDTHH:mm:ss')

        const model = {
            reservationId: this.preReservationModel.reservationId,
            observation: this.preReservationModel.observation,
            deadline: `${currentDeadline.utcOffset(-3).format('YYYY-MM-DDTHH:mm:ss')}.000-0300`,
            responsibleName: this.preReservationModel.responsibleName,
            responsibleEmai: this.preReservationModel.responsibleEmai,
            responsiblePhone: this.preReservationModel.responsiblePhone,
            reservationPrice: this.preReservationModel.reservationPrice,
            guestsQuantity: this.preReservationModel.guestQuantities
        };

        //console.log('model', model);
        this.loading = true;
        this.reservationsService.sendEditPreReservation(model).subscribe(data => {
            this.loading = false;
            //console.log(data);
            switch (data.code) {
                case 200:
                    this.dialog.closeAll();
                    this.notifier.notify('success', this.translate.instant('reservations.edit_pre_success'));
                    this.getReservations();
                    break;
            }
        }, error => { console.log("Error"); this.loading = false; });
    }

    sendOwnerReservation = () => {
        const model = {
            initialDate: this.startReservationDate,
            finalDate: moment(this.finishReservationDate).subtract(1, 'days'),
            channelId: this.selectChannel.channelId,
            unitIds: [this.modalInfo.room.unity.unitId]
        };

        this.loading = true;
        this.reservationsService.sendOwnerReservation(model).subscribe(data => {
            this.loading = false;
            console.log(data);
            switch (data.code) {
                case 200:
                    this.dialog.closeAll();
                    this.notifier.notify('success', this.translate.instant('reservations.created_reservations'));
                    this.getReservations();
                    break;
            }
        }, error => { console.log("Error"); this.loading = false; });
    }


    confirmReservation = (reservationId) => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn'
            },
            buttonsStyling: false
        })
        swalWithBootstrapButtons.fire({
            position: 'center',
            title: this.translate.instant('pre_reservation.confirm_text'),
            text: "",
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: this.translate.instant('yes'),
            cancelButtonText: this.translate.instant('no'),
            reverseButtons: true
        }).then((result) => {
            if (result.value == true) {
                this.sendConfirmReservation(reservationId);
            } else if (
                result.dismiss === Swal.DismissReason.cancel) {
            }
        });
    }



    sendConfirmReservation = reservationId => {
        this.loading = true;
        this.reservationsService.getPreReservationInfo(reservationId).subscribe(data => {
            this.loading = false;
            switch (data.code) {
                case 200:
                    let limit_time = data.object.deadline.split('T')[1].split(':');
                    limit_time = limit_time[0] + ':' + limit_time[1];

                    let unitsArray = [];
                    unitsArray.push(data.object.units[0].unitId);

                    const model = {
                        // checkin: `${this.startReservationDay.day}/${this.startReservationDay.mes}/${this.startReservationDay.ano}`,
                        // finalDate: `${this.finishReservationDay.night}/${this.finishReservationDay.nightMonth}/${this.finishReservationDay.nightYear}`,
                        // checkout: `${this.finishReservationDay.day}/${this.finishReservationDay.month}/${this.finishReservationDay.year}`,
                        // guestQuantities: this.makingReservationGuestsQuantity,
                        reservationId: data.object.reservationId,
                        start_day: data.object.initialDate,
                        end_day: data.object.checkoutDate,
                        unit: data.object.units[0].name,
                        unitId: data.object.units[0].unitId,
                        unitIds: unitsArray,
                        price: data.object.price,
                        channel: data.object.channel,
                        responsibleName: data.object.responsibleName,
                        responsibleEmai: data.object.responsibleEmail,
                        responsiblePhone: data.object.responsiblePhone,
                        observation: data.object.observation,
                        category: data.object.units[0].category,
                        limit_date: data.object.deadline.split('T')[0],
                        limit_time: limit_time,
                        effect: true
                    };

                    this.currentReservationEffectCommand = true;
                    this.currentReservation = model;
                    this.goResponsableDetails();
                    this.dialog.closeAll();
                    break;
            }
        }, error => {
            this.loading = false;
        });
    }

    validPreReservationLimit = () => {
        // TO DO
        // Check that moment() it's taking the environment datetime
        if ($("#prereservation-limit_date").val().length >= 10 && moment().isAfter(moment($("#prereservation-limit_date").val()), 'day'))
            $("#prereservation-limit_date").val("");
    }

    /* #endregion */

    // openMultipleReservationModal = () =>
    // {
    //     this.multipleUnits = [];
    //     this.multipleUnits.push(this.modalInfo.room.unity.unitId);
    //     this.multipleModal.startDate = this.startReservationDay.ano + '-' + this.startReservationDay.mes + '-' + this.startReservationDay.day;
    //     this.multipleModal.finishDate = this.finishReservationDay.year + '-' + this.finishReservationDay.month + '-' + this.finishReservationDay.day;
    //     this.multipleModal.price = this.makingReservationPrice;

    //     $("#modal-reservation").modal("hide");
    //     $("#multipleReservationModal").modal('show');
    //     this.getDisponibleUnits(this.modalInfo.category.categoryId,  this.multipleModal.startDate, this.multipleModal.finishDate);
    // }

    // setMultipleResponsable = () =>
    // {
    //     const model = {
    //         start_day: this.startReservationDay,
    //         end_day: this.finishReservationDay,
    //         unit: this.translate.instant('reservations.multiple_reserve'),
    //         unitIds:  this.multipleUnits,
    //         category: this.modalInfo.category.name,
    //         categoryId: this.modalInfo.category.categoryId,
    //         channel: this.reservationSelectedChannel.channel.name,
    //         channelId: this.reservationSelectedChannel.channel.channelId,
    //         price:  this.multipleModal.price
    //     };
    //     sessionStorage.setItem('currentReservationGuestsQuantity',this.makingReservationGuestsQuantity);
    //     sessionStorage.setItem('currentReservation', JSON.stringify(model));
    //     $("#multipleReservationModal").modal('hide');
    //     this.router.navigate(['reservations/responsable']);
    // }

    setThisUnitMultipleReservation = unit => {
        const index = this.multipleUnits.indexOf(unit.unitId);

        if (index == -1)
            this.multipleUnits.push(unit.unitId);
        else
            this.multipleUnits.splice(index, 1);
        //console.log('unitsArr', this.multipleUnits);
    }

    transferReservation = (transferReservationModal, reservationId, newCategoryId, newUnit, newStart, newEnd) => {
        this.transferModel = {};
        this.transferModel_ReservationData = null;
        this.loading = true;
        this.reservationsService.getReservationInfo(reservationId).subscribe(data => {
            this.loading = false;
            //console.log("ReservationInfo", data.object);
            switch (data.code) {
                case 200:
                    this.transferModel_ReservationData = data.object;
                    this.transferModel = data.object;
                    this.transferModel.select_category = (newCategoryId ? newCategoryId.categoryId : this.transferModel.units[0].category.categoryId);
                    this.transferModel.start_date = newStart ? newStart : this.transferModel.checkinDate.split('T')[0];
                    this.transferModel.end_date = newEnd ? newEnd : this.transferModel.checkoutDate.split('T')[0];
                    this.transferModel.status = this.reservationInfoModal.status;

                    this.reservationSelectedUnit = newUnit ? newUnit : null;
                    this.disponibleTransferUnits = [];

                    if (newUnit) {
                        this.setThisUnit(newUnit);
                    }

                    this.dialog.closeAll();
                    this.openModal(transferReservationModal);

                    //console.log('dados', this.transferModel);

                    this.getCategoryChannels(
                        this.transferModel.start_date,
                        this.transferModel.end_date,
                        this.transferModel.select_category
                    );

                    this.getDisponibleUnits(
                        this.transferModel.select_category,
                        this.transferModel.start_date,
                        this.transferModel.end_date
                    );

                    this.getDisponibleCategories(
                        this.transferModel.channel.channelId,
                        this.transferModel.reservationId
                    );
                    break;
            }
        }, error => {
            this.loading = false;
        });
    }

    refresTransferDate = (range) => {
        var checkinDate = moment(this.transferModel_ReservationData.checkinDate);
        var checkoutDate = moment(this.transferModel_ReservationData.checkoutDate);
        var days = checkoutDate.diff(checkinDate, 'days');

        var start_date = moment(this.transferModel.start_date);
        var end_date = moment(this.transferModel.end_date);

        if (this.transferModel
            && this.transferModel.select_category
            && start_date.isValid()
            && end_date.isValid()) {

            if (range == 'start') {
                // if(end_date<= start_date){
                //     end_date = moment(start_date).add(days, 'days');
                //     this.transferModel.end_date = end_date.format('YYYY-MM-DD');
                // }

                // Sempre Mudar a Data de Check-out caso mude a data de checkin
                end_date = moment(start_date).add(days, 'days');
                this.transferModel.end_date = end_date.format('YYYY-MM-DD');
            }

            if (range == 'end') {
                if (end_date <= start_date) {
                    start_date = moment(end_date).add(-days, 'days');
                    this.transferModel.start_date = start_date.format('YYYY-MM-DD');
                }
            }

            this.refreshAvailableUnits();
        }
    }


    refreshAvailableUnits = () => {
        if (this.transferModel
            && this.transferModel.select_category) {

            this.disponibleTransferUnits = [];
            this.getCategoryChannels(this.transferModel.start_date, this.transferModel.end_date, this.transferModel.select_category);
            this.getDisponibleUnits(this.transferModel.select_category, this.transferModel.start_date, this.transferModel.end_date);
            this.getDisponibleCategories(this.transferModel.channel.channelId, this.transferModel.reservationId);
        }
    }

    getCategoryChannels = (startDate, finishDate, categoryId) => {
        this.reservationsService.getTransferChannels(startDate, finishDate, categoryId, 0).subscribe(data => {
            switch (data.code) {
                case 200:
                    this.channelList = [];
                    var channel = [];
                    var channelTariff = [];
                    var isInArray = false;
                    for (let i = data.object.length - 1; i >= 0; i--) {
                        isInArray = channel.includes(data.object[i].channel.channelId);
                        if (!isInArray) {
                            channel.push(data.object[i].channel.channelId);
                            this.channelList.push(data.object[i]);
                            channelTariff[data.object[i].channel.channelId] = data.object[i].tariff.price;
                        }
                    }
                    break;
            }
        }, error => console.log("Error", error));
    }

    getDisponibleUnits = (categoryId, startDate, finishDate) => {
        const establishment = this.appStateService.currentEstablishment;
        this.reservationsService.getDisponibleUnits(categoryId, establishment.establishmentId, startDate, finishDate).subscribe(data => {
            switch (data.code) {
                case 200:
                    for (let i = 0; i < data.object.length; i++) {
                        if (data.object[i].status == "ACTIVE")
                            this.disponibleTransferUnits.push(data.object[i]);
                    }

                    if (this.reservationSelectedUnit != null) {
                        var unitInList = this.disponibleTransferUnits.find(i => i.unitId == this.reservationSelectedUnit.unitId);
                        if (unitInList) {
                            this.reservationSelectedUnit = unitInList;
                        }
                    }

                    //console.log("DisponibleUnits", this.disponibleTransferUnits);
                    break;
            }
        }, error => console.log("Error", error));
    }

    getDisponibleCategories = (channelId, reservationId?, checkinDate?, checkoutDate?) => {
        const establishmentId = this.appStateService.currentEstablishment.establishmentId;

        this.categoriesService.getDisponibleCategories(establishmentId, channelId, reservationId, checkinDate, checkoutDate).subscribe(data => {
            //console.log("DisponibleCategories", data.object);

            switch (data.code) {
                case 200:
                    this.transferableCategories = data.object;
                    break;
            }
        }, error => console.log("Error", error));
    }

    makeReservationTransfer = () => {
        const unitIds = [];
        unitIds.push(this.reservationSelectedUnit.unitId);
        //console.log(this.transferModel);

        this.loading = true;

        const payload = {
            reservationId: this.transferModel.reservationId,
            observation: this.transferModel.observation,
            initialDate: moment(this.transferModel.start_date, 'YYYY-MM-DD').format("YYYY-MM-DDTHH:mm:ss.000-0300"),
            finalDate: moment(this.transferModel.end_date, 'YYYY-MM-DD').subtract(1, 'days').format("YYYY-MM-DDTHH:mm:ss.000-0300"),
            unitIds: unitIds,
            responsibleId: null,
            reservationPrice: this.transferModel.price,
            channelId: this.transferModel.channel.channelId,
        };

        //console.log("sending", payload);

        this.loading = true;

        this.reservationsService.makeReservationTransfer(payload).subscribe(data => {
            this.loading = false;
            //console.log(data);
            this.loading = false;

            switch (data.code) {
                case 200:
                    this.getReservations();
                    this.dialog.closeAll();
                    Swal.fire(this.translate.instant('reservations.transfer_success'), '', 'success');
                    break;
                case 228:
                    {
                        Swal.fire("Oop's", this.translate.instant('reservations.msg_error_transfer_no_channel'), 'error');
                        break;
                    }
                default:
                    {
                        this.errorTitle = "Transferência de Reserva";
                        this.errorSubtitle = "Não foi possível concluir a transferência";
                        this.dialog.closeAll();
                        this.notifier.notify('error', this.translate.instant('error_subtitle'));
                        break;
                    }
            }
        }, error => {
            this.loading = false;

        });
    }

    makePreReservationTransfer = () => {
        const auxArr = [];
        auxArr.push(this.reservationSelectedUnit.unitId);

        this.loading = true;

        this.reservationsService.makePreReservationTransfer(this.transferModel, auxArr).subscribe(data => {

            //console.log('makePreReservationTransfer', data)
            switch (data.code) {
                case 200:
                    this.getReservations();
                    this.dialog.closeAll();
                    Swal.fire(this.translate.instant('reservations.transfer_success'), '', 'success');
                    this.loading = false;
                    break;
            }
        }, error => { console.log("Error"); this.loading = false; });
    }



    /* #region Cancelar Reserva */

    openModalCancelReservation = (modalConfirmReservationCancel) => {
        this.cancelReason = ''
        //this.dialog.closeAll();
        setTimeout(() => this.openQuestionModal(modalConfirmReservationCancel), 500);
    }

    cancelReservation = (reservationId, reverse) => {

        if (reverse == true && this.reservationInfoModal.groupReservation.reservations.length > 1) {
            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn'
                },
                buttonsStyling: false
            })
            swalWithBootstrapButtons.fire({
                position: 'center',
                title: this.translate.instant('reservations.confirm_cancel_reservation_multiple_res_info'),
                text: "",
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: this.translate.instant('reservations.yes_cancel_reverse'),
                cancelButtonText: this.translate.instant('no'),
                reverseButtons: true
            }).then((result) => {
                if (result.value == true) {
                    this.executeCancelFromResevetionID(reservationId, reverse);
                } else if (
                    result.dismiss === Swal.DismissReason.cancel) {
                }
            });
        } else {
            this.executeCancelFromResevetionID(reservationId, reverse);
        }
    }

    executeCancelFromResevetionID(reservationId, reverse) {
        this.loading = true;
        this.reservationsService.cancelReservation(reservationId, reverse, this.cancelReason).subscribe(data => {
            this.loading = false;
            switch (data.code) {
                case 200:
                    this.getReservations();
                    this.dialog.closeAll();
                    this.questionDialogRef.close();
                    this.notifier.notify('success', this.translate.instant('reservations.success_cancel_reservation'));
                    this.loading = false;
                    break;
                default:
                    //this.questionDialogRef.close();
                    this.loading = false;
                    break
            }
        },
            error => {
                this.loading = false;
            });
    }
    /* #endregion */

    editReservationValue = () => this.readOnly = false;

    disableEditReservationValue = () => this.readOnly = true;

    closeReservationModal = () => {
        this.dialog.closeAll();
        this.startReservationDay = {};
        this.finishReservationDay = {};
        this.minChekOutDate = null;
        this.finishReservationDate = null;
    }

    closeShowChannelModal = () => $("#modal-channel").modal("hide");


    // setThisChannel = channel => this.reservationSelectedChannel = channel;

    setThisUnit = unit => this.reservationSelectedUnit = unit;



    mostrarModalCanais = (day, categoryId) => {
        //console.log(day);

        const date = {
            mes: day.mes_num,
            day: day.dia,
            ano: day.ano,
            night: day.dia,
            nightMonth: day.mes_num,
            nightYear: day.ano
        };

        this.reservationsService.getReservationTariff(date, date, categoryId, 0).subscribe(data => {
            //console.log(data.object);
            switch (data.code) {
                case 200:
                    this.channelList = [];
                    const channel = [];
                    const channelTariff = [];
                    let isInArray = false;
                    for (let i = data.object.length - 1; i >= 0; i--) {
                        isInArray = channel.includes(data.object[i].channel.channelId);
                        if (!isInArray) {
                            channel.push(data.object[i].channel.channelId);
                            this.channelList.push(data.object[i]);
                            channelTariff[data.object[i].channel.channelId] = data.object[i].tariff.price;
                        }
                    }
                    // this.reservationSelectedChannel.channel = {};
                    // this.reservationSelectedChannel.channel.channelId = false;
                    // this.reservationSelectedChannel.tariff = {};
                    $("#modal-channel").modal("show");
                    break;
            }
        }, error => console.log("Error"));
    }

    ChannelManagerReservationAction = (action, cm_reservation_record, modalConfirmChannelManagerReservation) => {

        //console.log("INFORMACAO QUE ESTA AQUI", cm_reservation_record);
        if (this.loading) { return; }

        this.channelManagerReservationAction = action;
        this.channelManagerReservationRec = cm_reservation_record;

        if (action != 'category_transfer') { return; }

        this.loading = true;

        const establishmentId = this.appStateService.currentEstablishment.establishmentId;
        this.categoriesService.getDisponibleCategories(
            establishmentId,
            cm_reservation_record.channel.channelId,
            false,
            cm_reservation_record.checkinDateOld,
            cm_reservation_record.checkoutDateOld
        ).subscribe(data => {
            this.loading = false;
            //console.log(data);
            switch (data.code) {
                case 200:
                    this.transferableCategoriesCM = data.object || [];
                    this.ChannelManagerCategoryTransfer = 0;
                    break;
            }
        }, error => {
            this.loading = false;
            //console.log('Error'); 
        });

        this.openModal(modalConfirmChannelManagerReservation);
    }

    dismissOverbooking = channelManagerReservationId => {
        //console.log("Dismissing", channelManagerReservationId);

        if (this.loading) return;

        this.loading = true;

        const payload = {
            channelManagerReservationId: channelManagerReservationId,
            channelMannagerReservationStatus: 2
        };

        this.reservationsService.dismissOTAPendingReservations(payload).subscribe(data => {
            this.loading = false;
            //console.log(data);
            switch (data.code) {
                case 200:
                    this.getOTAPendingReservations();
                    break;
            }
        }, error => { this.loading = false; console.log(error); });
    }

    confirmCancelPendingReservation = (reservation, modalConfirmPendingReservationCancel) => {
        this.pendingReservation = {
            reservationId: reservation.reservationId,
            channelManagerReservationId: reservation.channelManagerReservationId,
            groupReservationId: reservation.groupReservationId,
            reservationStatus: reservation.reservationStatus,
        };

        //console.log("Cancelar:", this.pendingReservation);

        this.cancelReason = ''
        this.openQuestionModal(modalConfirmPendingReservationCancel);
    }

    cancelPendingReservation = reverse => {
        if (reverse == true && this.pendingReservation.groupReservationId) {
            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn'
                },
                buttonsStyling: false
            })
            swalWithBootstrapButtons.fire({
                position: 'center',
                title: this.translate.instant('reservations.confirm_cancel_reservation_multiple_res_info'),
                text: "",
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: this.translate.instant('reservations.yes_cancel_reverse'),
                cancelButtonText: this.translate.instant('no'),
                reverseButtons: true
            }).then((result) => {
                if (result.value == true) {
                    this.executeCancel(reverse);
                } else if (
                    result.dismiss === Swal.DismissReason.cancel) {
                }
            });
        } else {
            this.executeCancel(reverse);
        }

    }

    executeCancel(reverse) {

        //console.log("Cancelando: ", this.pendingReservation.reservationId);
        this.loading = true;
        this.reservationsService.cancelReservation(this.pendingReservation.reservationId, reverse, this.cancelReason).subscribe(data => {
            this.loading = false;
            //console.log(data);
            switch (data.code) {
                case 200:
                    this.getOTAPendingReservations();
                    this.getReservations();
                    this.dismissOverbooking(this.pendingReservation.channelManagerReservationId)
                    this.questionDialogRef.close();
                    this.dialog.closeAll();
                    this.notifier.notify('success', this.translate.instant('reservations.success_cancel_reservation'));
                    break;
                default:
                    break
            }
        }, error => {
            this.loading = false;
        });
    }

    preventDefault = event => {
        event.preventDefault();
        event.stopPropagation();
    }

    reprocessReservation = reservation => {
        if (this.loading) { return; }

        this.loading = true;

        //console.log("Reservation to Process", reservation);
        const lastNight = moment(reservation.checkoutDateOld, 'YYYY-MM-DD').subtract(1, 'day').format('YYYY-MM-DDTHH:mm:ss');

        const model = {
            categoryId: reservation.category.categoryId,
            initialDate: moment(reservation.checkinDateOld, 'YYYY-MM-DD').format('YYYY-MM-DDTHH:mm:ss'),
            finalDate: lastNight,
            unitIds: null,
            channelId: reservation.channel.channelId,
            guestQuantities: [1],
            responsibleId: reservation.responsible.responsibleId,
            channelManagerReservationId: reservation.channelManagerReservationId,
            reservationPrice: reservation.price,
            buklyId: reservation.buklyId || null,
            salesChannel: translatedOriginToCode(reservation.salesChannel),
        };

        // console.log("Sending ReprocessReservation", model);

        this.loading = true
        this.reservationsService.reprocessReservation(model).subscribe(data => {
            this.loading = false;

            //console.log('reprocessReservation', data);
            this.loading = false
            switch (data.code) {
                case 200:
                    // this.dismissOverbooking(reservation.channelManagerReservationId);
                    this.getReservations();
                    this.getOTAPendingReservations();
                    this.dialog.closeAll();
                    this.notifier.notify('success', this.translate.instant('reservations.reprocess_reservation_success'));
                    break;
                default:
                    this.dialog.closeAll();
                    this.notifier.notify('error', this.translate.instant('reservations.reprocess_reservation_error'));
                    break;
            }
        }, error => {
            this.loading = false;
            //console.log('Error')
        });
    }

    reprocessChannelManagerReservation = () => {
        if (this.channelManagerReservationAction == 'category_transfer')
            this.channelManagerReservationRec.category.categoryId = this.ChannelManagerCategoryTransfer;

        this.loading = true;

        this.reservationsService.reprocessChannelManagerReservation(this.channelManagerReservationRec).subscribe(data => {
            //console.log(data);
            this.loading = false;

            switch (data.code) {
                case 200:
                    // this.dismissOverbooking(this.channelManagerReservationRec.channelManagerReservationId);
                    this.getReservations();
                    this.getOTAPendingReservations();
                    this.dialog.closeAll();
                    this.notifier.notify('success', this.translate.instant('reservations.reprocess_reservation_success'));
                    break;
                default:
                    //this.dialog.closeAll();
                    this.notifier.notify('error', this.translate.instant('reservations.reprocess_reservation_error'));
                    break;
            }
        }, error => { console.log("Error"); this.loading = false; });
    }

    viewPendingHistory = buklyId => {
        if (this.loading == true) return;

        console.log("ViewPendingHistory - BuklyId: ", buklyId);
        this.PendingReservationCreated = [];
        this.PendingReservationHistory = [];

        const establishment = this.appStateService.currentEstablishment;

        this.loading = true;
        this.reservationsService.pendingReservatioHistory(buklyId).subscribe(data => {
            this.loading = false;
            const pendings = data.object as PendingReservation[];

            //console.log("ViewPendingHistory - response", data);
            switch (data.code) {
                case 200:
                    for (let i = 0; i < pendings.length; i++) {
                        pendings[i].checkinDate = moment(pendings[i].checkinDate, 'YYYY-MM-DD').format("DD/MM/YYYY");
                        pendings[i].checkoutDate = moment(pendings[i].checkoutDate, 'YYYY-MM-DD').format("DD/MM/YYYY");
                        pendings[i].creationtDate = moment(pendings[i].creationtDate).format("DD/MM/YYYY HH:mm:ss");

                        pendings[i].overbookingTypeDesc = this.translatePendingStatus(pendings[i].overbookingType);

                        // if (pendings[i].overbookingType == "NEW_SUCCESS")
                        //     this.PendingReservationCreated.push(pendings[i]);

                        if (i > 0) {
                            let lastCreationDate = pendings[i - 1].creationtDate;
                            if (lastCreationDate === pendings[i].creationtDate) {
                                this.PendingReservationHistory[this.PendingReservationHistory.length - 1].data.push(pendings[i])
                            } else {
                                this.PendingReservationHistory.push({ index: this.PendingReservationHistory.length, data: [pendings[i]] });
                            }
                        } else {
                            this.PendingReservationHistory.push({ index: 0, data: [pendings[i]] });
                        }

                    }

                    $("#pendingReservationHistory").modal("show");
                    break
            }

        }, error => {
            this.loading = false;
            console.log("Erro", error)
        });

        this.reservationsService.pendingReservationCreated(buklyId, establishment.establishmentId).subscribe(data => {
            let reservations = data.object
            switch (data.code) {
                case 200:
                    for (let i = 0; i < reservations.length; i++) {
                        reservations[i].checkinDate = moment(reservations[i].checkinDate, 'YYYY-MM-DD').format("DD/MM/YYYY");
                        reservations[i].checkoutDate = moment(reservations[i].checkoutDate, 'YYYY-MM-DD').format("DD/MM/YYYY");
                    }
                    this.PendingReservationCreated = reservations
                    break
            }

        }, error => console.log("Erro", error));
    }

    confirmIgnoreModification = channelManagerReservationId => {
        this.channelManagerReservationId = channelManagerReservationId;

        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn'
            },
            buttonsStyling: false
        })
        swalWithBootstrapButtons.fire({
            position: 'center',
            title: this.translate.instant('reservations.modify_reservation'),
            text: this.translate.instant('reservations.confirm_ignore_modification'),
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: this.translate.instant('yes'),
            cancelButtonText: this.translate.instant('no'),
            reverseButtons: true
        }).then((result) => {
            if (result.value == true) {
                this.ignoreModification(channelManagerReservationId);
            } else if (
                result.dismiss === Swal.DismissReason.cancel) {
            }
        });

    }

    ignoreModification = channelManagerReservationId => {
        this.loading = true;

        const payload = {
            channelManagerReservationId: channelManagerReservationId,
            channelMannagerReservationStatus: 3,
            createSuccess: 2
        };

        this.reservationsService.dismissOTAPendingReservations(payload).subscribe(data => {
            this.loading = false;

            switch (data.code) {
                case 200:
                    this.notifier.notify('success', this.translate.instant('success_subtitle'));
                    this.getOTAPendingReservations();
                    break;
                default:
                    this.notifier.notify('error', this.translate.instant('error_subtitle'));
                    this.getOTAPendingReservations()
                    break;
            }
        }, error => {
            this.notifier.notify('error', this.translate.instant('error_subtitle'));
            console.log("Erro", error);
            this.loading = false;
        });
    }

    /* #region RERSERVA QUEBRADA */

    showBrokenReservationModal = (res, pickDaysBrokenReservationModal) => {
        if (this.loading) { return; }

        this.loading = true;

        this.channelManagerReservationRec = res;
        const lastNight = moment(res.checkoutDateOld, 'YYYY-MM-DD').subtract(1, 'days').format('YYYY-MM-DDTHH:mm:ss');
        //console.log(lastNight);

        this.reservationsService.getUnitDispnibilityDayByDay(res.category.categoryId, res.checkinDateOld, lastNight).subscribe(data => {
            this.loading = false;

            //console.log('Dias com unidades disponiveis', data);
            switch (data.code) {
                case 200:
                    for (let i = data.object.length - 1; i >= 0; i--) {
                        data.object[i].dateFormatted = moment(data.object[i].date, 'YYYY-MM-DD').format("DD/MM/YYYY");
                    }
                    this.availableDays = data.object;
                    this.createBrokenValid = false;
                    this.openModal(pickDaysBrokenReservationModal);
                    this.daysWithoutUnits = this.availableDays.find(i => i.units == null);

                    if (this.daysWithoutUnits != null && this.daysWithoutUnits.length > 0) {
                        this.loading = true;

                    }

                    //console.log("this.daysWithoutUnits: ", this.daysWithoutUnits);

                    break;
            }
        }, error => { this.loading = false; console.log('Error'); });
    }

    validBrokenReservationUnitsSelected = () => {
        this.createBrokenValid = true;

        this.availableDays.forEach(element => {
            if (!element.selectedUnit)
                return this.createBrokenValid = false;
        });
    }

    createBrokenReservation = () => {
        if (this.loading) return;

        this.loading = true;
        //console.log('cm res', this.channelManagerReservationRec);
        const brokenUnits = [];
        const availableDays_PostData = JSON.parse(JSON.stringify(this.availableDays));

        //console.log(this.availableDays);

        for (let i = 0; i < availableDays_PostData.length; i++) {
            const reservationUnit = {
                initialDate: availableDays_PostData[i].date,
                finalDate: availableDays_PostData[i].date,
                unitId: availableDays_PostData[i].selectedUnit ? availableDays_PostData[i].selectedUnit : 2
            };

            for (let j = i + 1; j < availableDays_PostData.length; j++) {
                if (availableDays_PostData[i].selectedUnit == availableDays_PostData[j].selectedUnit) {
                    reservationUnit.finalDate = availableDays_PostData[j].date;
                    availableDays_PostData.splice(j, 1);
                    j--;
                }
                else j = availableDays_PostData.length + 1;
            }
            brokenUnits.push(reservationUnit);
        }
        //console.log('days ', brokenUnits);

        const payload = {
            channelId: this.channelManagerReservationRec.channel.channelId,
            reservationPrice: this.channelManagerReservationRec.price,
            responsibleId: this.channelManagerReservationRec.responsible.responsibleId,
            salesChannel: translatedOriginToCode(this.channelManagerReservationRec.salesChannel),
            brokenUnits: brokenUnits,
            channelManagerReservationId: this.channelManagerReservationRec.channelManagerReservationId,
            buklyId: this.channelManagerReservationRec.buklyId || null
        };

        //console.log("Send Broken Reservation", payload);

        this.reservationsService.postBrokenReservation(payload).subscribe(data => {
            this.loading = false;
            //console.log('BrokenReservationData', data);
            switch (data.code) {
                case 200: {
                    // this.dismissOverbooking(this.channelManagerReservationRec.channelManagerReservationId);
                    this.getReservations();
                    this.getOTAPendingReservations();
                    this.notifier.notify('success', this.translate.instant('reservations.blocked_added'));
                    this.dialog.closeAll();
                    break;
                }
                default: {
                    this.notifier.notify('error', this.translate.instant('reservations.error_add_blocked'));
                    break;
                }
            }
        }, error => {
            this.loading = false;
            this.notifier.notify('error', this.translate.instant('reservations.error_add_blocked'));
        });
    }

    /* #endregion */

    collapsePendingReservatiosOTA = () => {
        if (this.peddingReservationOTAOpen) {
            $("#pendingReservationsOta").hide();
            this.peddingReservationOTAOpen = false;
        }
        else {
            $("#pendingReservationsOta").show();
            this.peddingReservationOTAOpen = true;
        }

    }

    tooglePendingReservatiosErrors = () => {
        if (this.peddingReservationErrorsOpen) {
            $("#pendingReservationsErrorsCard").hide();
            this.peddingReservationErrorsOpen = false;
        }
        else {
            $("#pendingReservationsErrorsCard").show();
            this.peddingReservationErrorsOpen = true;
        }

    }

    notValidatedCheckinDataCardOpened = true;
    toogleNotValidatedCheckinData = () => {
        if (this.notValidatedCheckinDataCardOpened) {
            this.notValidatedCheckinDataCardOpened = false;
        }
        else {
            this.notValidatedCheckinDataCardOpened = true;
        }

    }

    reservationsNotCheckoutCardOpened = true;
    toogleReservationsNotCheckout = () => {
        if (this.reservationsNotCheckoutCardOpened) {
            this.reservationsNotCheckoutCardOpened = false;
        }
        else {
            this.reservationsNotCheckoutCardOpened = true;
        }

    }

    responsibleNoDocumentCardOpened = true;
    toogleResponsibleNoDocumentCardOpened = () => {
        if (this.responsibleNoDocumentCardOpened) {
            this.responsibleNoDocumentCardOpened = false;
        }
        else {
            this.responsibleNoDocumentCardOpened = true;
        }

    }


    integrationErrorsMagikeyCardOpened = true;
    toogleIntegrationErrorsMagikey = () => {
        if (this.integrationErrorsMagikeyCardOpened) {
            this.integrationErrorsMagikeyCardOpened = false;
        }
        else {
            this.integrationErrorsMagikeyCardOpened = true;
        }

    }


    replaceBrToLineBreak(text) {
        text = (text != null ? text : '');
        return text.split("<br>").join("\n");
    }

    fixInvoiceError(item) {
        this.fixResErrorsModalComponentDialogRef = this.dialog.open(FixResErrorsModalComponent, {
            //panelClass: 'mat-dialog-large',
            disableClose: false,
            data: { item: item }
        });

        //this.fixResErrorsModalComponentDialogRef.componentInstance.title = 'Excluír?';

        this.fixResErrorsModalComponentDialogRef.afterClosed().subscribe(result => {
            this.getInvoicePendingErrors();
            this.fixResErrorsModalComponentDialogRef = null;
        });
    }

    makeReservationCheckout(reservationId) {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn'
            },
            buttonsStyling: false
        })

        swalWithBootstrapButtons.fire({
            position: 'center',
            title: this.translate.instant('occupation.confirm_checkout'),
            text: this.translate.instant('occupation.confirm_checkin_checkout_info'),
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: this.translate.instant('occupation.ok_confirm'),
            cancelButtonText: this.translate.instant('cancel'),
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                this.executeCheck(reservationId);
            } else if (result.dismiss === Swal.DismissReason.cancel) {

            }
        });
    }

    executeCheck(reservationId) {

        this.reservationsService.makeCheckout(reservationId).subscribe(
            (data) => {
                if (data.code === 200) {
                    if (data.object && data.object.code == 422) {
                        Swal.fire(`<b>Checkout Realizado!<br>Alertas Retornados Abaixo: </b>`, data.object.message, 'info');
                    }
                }
                else if (data.message) {
                    this.notifier.notify('error', data.message)
                } else {
                    this.notifier.notify('error', `${this.translate.instant('error_msg.error_edit')}`)
                }
            },
            (error) => {
                console.log(error);
                if (error.error.message) {
                    this.notifier.notify('error', error.error.message)
                } else {
                    this.notifier.notify('error', `${this.translate.instant('error_msg.error_edit')}`)
                }
            })
    }

}