import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse  } from '@angular/common/http';
import {ResponseFormat} from '@app/types/response-format';

@Injectable()
export class UnitsService 
{
    constructor(private http : HttpClient) { }

    //http://localhost:8080/vivakey/rest/unit/getUnitOwner?establishmentId=1

    getUnitOwner = (establishmentId) =>
    {
        return this.http.get<ResponseFormat>('vivakey/rest/unit/getUnitOwner/?establishmentId=' + establishmentId); 
    }

    getUnits = (establishmentId, categoryId?, unitTypeId?, name?, ownerId?) =>
    {
        let query = "";
        query += (categoryId) ? "&categoryId=" + categoryId : "";        
        query += (unitTypeId) ? "&unitTypeId=" + unitTypeId : ""; 
        query += (ownerId) ? "&employeeId=" + ownerId : "";  
        query += (name) ? "&name=" + name : "";     

        return this.http.get<ResponseFormat>('vivakey/rest/unit/getUnits/?establishmentId=' + establishmentId + query); 
    }

    saveUnit = (model, establishmentId) =>
    {
        console.log("sending this model", model);

        var body = {
            unitId : model.unitId && model.unitId != '' ?  model.unitId : null,
            
            establishmentId: establishmentId,
            categoryId: model.categoryId,
            employeeId: model.employeeId,
            propertyOwnerId: model.propertyOwnerId,

            name: model.name,
            description: model.description,
            status: model.unitId ? model.status_num : 1,
            
            number: model.number,

            magikey: model.magikey && model.magikey != '' ?  model.magikey : null,

            fixedPassword: model.fixedPassword && model.fixedPassword != '' ?  model.fixedPassword : null,

            smartlock: model.smartlock && model.smartlock != '' ?  model.smartlock : null,

            magikeyToken : model.magikeyToken && model.magikeyToken != '' ?  model.magikeyToken : null,


            excludedUnit: model.excludedUnit,

            hideOnReservationMap: model.hideOnReservationMap,
            
            commission: model.commission ? model.commission : 0,
            maintenanceFee: model.maintenanceFee ? model.maintenanceFee : 0,
            
        };    
        
        console.log(body);
        return this.http.post<ResponseFormat>('vivakey/rest/unit', body); 	
    }

    deleteUnit = unitId => this.http.delete<ResponseFormat>('vivakey/rest/unit/' + unitId);

    changeUnitStatus = (unitId, status) =>
    {
        const body = {
            unitId: unitId,
            status: status
        }
        return this.http.put<ResponseFormat>('vivakey/rest/unit/changeStatus', body); 	
    }

    getAreasGroupsMagikey = establishmentId => this.http.get<ResponseFormat>(`vivakey/rest/areasGroupsMagikey/getAreasGroupsMagikeys/${establishmentId}`);

    updateReservationsMagikey(unitId){
        return this.http.post<ResponseFormat>(`vivakey/rest/unit/updatereservationsmagikeybyunit/${unitId}`, {}); 	
    }
}
