import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AppValidator } from "../../../utils/validators/AppValidator";
import masks from "../../../utils/masks";
import { NotifierService } from 'angular-notifier'
import { TranslateService } from '@ngx-translate/core';
import { NatDataService } from '../national-data-form/national-data.service';
import { CommonService } from '@src/app/common-service';

@Component({
  selector: 'app-invoice-form',
  templateUrl: './invoice-form.component.html',
  styleUrls: ['./invoice-form.component.scss']
})
export class InvoiceFormComponent implements OnInit {

  @Input('initialValue') initialValue: any;
  @Output() onSave: EventEmitter<any> = new EventEmitter();

  loading: boolean = false
  isForeign: boolean = false

	// Forms
	submitted: boolean = false;
	form0: FormGroup;
	form1: FormGroup;

	// Masks
	masks = masks

	// UI/UX Variables
	currentStep: number = 0

  countriesOptions: any = [];
  contribuinteIcms_error = false;
  optanteSimplesNacional_error = false;


  constructor(private formBuilder: FormBuilder,
    private notifier: NotifierService,
    private natDtService: NatDataService,
    private translate: TranslateService,
    private commonService: CommonService,) { }

  ngOnInit() {

    let fillValues = false;
    const { initialValue } = this
    if(initialValue && typeof initialValue === 'object') {
      fillValues = true
    }

  	// Form of first step
  	this.form0 = this.formBuilder.group({

  		documentType: [fillValues ? initialValue.documentType : 'CPF', [Validators.required, Validators.minLength(3)]],
  		documentNumber: ['', [Validators.required]],
      name: [fillValues ? initialValue.name : '', [Validators.required, Validators.minLength(3)]],
      contribuinteIcms:  [fillValues ? initialValue.contribuinteIcms : null],
      optanteSimplesNacional:  [fillValues ? initialValue.optanteSimplesNacional : null],

  	});

    this.changeDocumentType(fillValues ? initialValue.documentNumber : '')

  	this.form1 = this.formBuilder.group({
			zipcode: [fillValues ? initialValue.zipcode : '', [Validators.required, AppValidator.cep]],
			state: [fillValues ? initialValue.state : '', [Validators.required, Validators.minLength(2), Validators.maxLength(5)]],
			city: [fillValues ? initialValue.city : '', [Validators.required, Validators.minLength(3)]],
			country: ["BRASIL", [Validators.required]],
			street: [fillValues ? initialValue.street : '', [Validators.required, Validators.minLength(3)]],
			number: [fillValues ? initialValue.number : '', [Validators.required, Validators.pattern(/[0-9]/)]],
			complement: [fillValues ? initialValue.complement : ''],
      noComplement: [fillValues ? initialValue.noComplement : false],
    });
    
    this.getCountries();
  }

  getCountries() {
		this.loading = true;
		this.natDtService
		  .getCountries('')
		  .subscribe(
			data => {
			  if (data.code === 200 && data.object) {
				this.countriesOptions = data.object;
			  }
			  this.loading = false
			},
			error => {
			  this.loading = false
			  this.notifier.notify('error', 'Houve um erro interno, tente novamente mais tarde.')
			}
		  );
  }
  
  f(n) {
  	return this[`form${n}`].controls
  }

  changeDocumentType(defaultDocumentNumber) {
  	this.f(0).documentNumber.setValue(defaultDocumentNumber)
    if(this.f(0).documentType.value === 'CPF') {
      this.f(0).documentNumber.setValidators([Validators.required, AppValidator.cpf])
    } else {
      this.f(0).documentNumber.setValidators([Validators.required, AppValidator.cnpj])
    }
  }

  onCep(cep) {
    if (this.isForeign && this.f(1).country.value !== "BRASIL") {
      return;
    }
    cep = cep.replace(/\D/g, "");
    if (cep.length === 8) {
      this.loading = true;

      this.commonService.checkZipCode(cep).subscribe(ret => {
				console.log(ret);
				this.loading = false;
				if (ret.erro == true) {
					console.log('CEP Inválido!');
				} else {
					this.f(1).city.setValue(ret.localidade);
					this.f(1).state.setValue(ret.uf);
					this.f(1).street.setValue(`${ret.logradouro}, ${ret.bairro}`);
				}
			}, err => {
				this.loading = false;
				this.translate.instant('error_msg.cep_not_found');
      });
    }
  }

  parseFormDataToObj() {
    let obj = {}

    Object.keys(this.f(0)).forEach(key => {
      obj[key] = this.f(0)[key].value;
    });

    Object.keys(this.f(1)).forEach(key => {
      obj[key] = this.f(1)[key].value
    });

    return obj
  }

  next() {
    this.submitted = true
    
    this.contribuinteIcms_error = false;
    this.optanteSimplesNacional_error = false;
    if(this.f(0)["documentType"].value == "CNPJ") {
      if(this.f(0)["contribuinteIcms"].value == null){
        this.contribuinteIcms_error = true;
        return;
      }
      if(this.f(0)["optanteSimplesNacional"].value == null){
        this.optanteSimplesNacional_error = true;
        return;
      }
    }

  	if(this.currentStep === 0) {
  		if(this.form0.valid) {
  			this.currentStep = 1
  			this.submitted = false
  		}
  	} else {
      if(this.form1.valid) {
        this.onSave.emit(this.parseFormDataToObj());
      }

  	}
  }

}
