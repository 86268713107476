import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { LoginService } from '@pages/login/login.service';
import { Router } from '@angular/router';
import { AppStateService } from './layouts/app-layout.service';
@Injectable()
export class AuthGuard implements CanActivate {
	constructor(private login : LoginService, private router : Router,  public appStateService: AppStateService) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,): Observable<boolean> | Promise<boolean> | boolean {
      
    if (this.login.getUserLoggedIn())
    {
      //console.log('next.params', next.params);

     
      
      if(next.params.establishmentId) {


        //Sempre que acessar uma nova rota:
        // sera disparado o evento de recarregar o estabelecimento caso seja obrigatório recarregar as configurações para essa página
        return this.appStateService.asCompanyFromEstablishmentId(next.params.establishmentId, next.url ? next.url[0].path : '');
      }
      else
        return true;
    }
		
    this.router.navigate(['login']);	   
    return false;
  }
}
