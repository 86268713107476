import { Injectable } from '@angular/core';
import {Observable} from 'rxjs/Rx';
import {ResponseFormat} from '@app/types/response-format';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse  } from '@angular/common/http';
// Import RxJs required methods
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';

@Injectable()
export class TariffService 
{
    constructor(private http : HttpClient) { }

    getTariffary = (establishment, startDate, endDate, selectChannel, selectCategoria) =>
    {
        let queryparam = '';
        if (selectChannel && selectCategoria)
            queryparam += '?channelId=' + selectChannel + '&categoryId=' + selectCategoria;
        else 
        {
            if (selectChannel)
                queryparam += '?channelId=' + selectChannel

            if (selectCategoria) 
                queryparam += '?categoryId=' + selectCategoria; 
        }
        
        const url = establishment + '/' + startDate + '/' + endDate + queryparam;
        console.log(`vivakey/rest/tariff/getTariffsMap/${url}`);
        return this.http.get<ResponseFormat>('vivakey/rest/tariff/getTariffsMap/' + url);
  	}

    getChannels = (establishmentId) => this.http.get<ResponseFormat>(`vivakey/rest/channel/channelByEstablishment/${establishmentId}`)

    getAutomaticTariffs = (establishmentId) => this.http.get<ResponseFormat>(`vivakey/rest/automaticcalculation?establishmentId=${establishmentId}`)

    sendTariff = body => 
    {
        console.log("saving...", body);
        return this.http.post<ResponseFormat>('vivakey/rest/tariff', body);
    }

    editTariff = body => this.http.put<ResponseFormat>('vivakey/rest/tariff', body);

    saveTariffsByDays = body => this.http.put<ResponseFormat>('vivakey/rest/tariff/tariffsByDays', body);

    sendTariffToBukly = (categoryId, channelId) => this.http.put<ResponseFormat>('vivakey/rest/tariff/sendTariffToBukly', { channelId: channelId, categoryId: categoryId })

    sendAutomaticCalc (channelId, categoryId, idEstablishment, body)
    {
        console.log("saving...", body);
        return this.http.put<ResponseFormat>(`vivakey/rest/automaticcalculation?channelId=${channelId}&categoryId=${categoryId}&idEstablishment=${idEstablishment}`, body);
    }

    disableAutomaticCalc (channelId, categoryId, idEstablishment)
    {
        var body = {
            baseValue: 0,
            discountPriceValue: 0,
            discountPriceType: 'DISCOUNTTYPE_VALUE', //DISCOUNTTYPE_VALUE  ||  DISCOUNTTYPE_PERCENTUAL
            lastMinuteValue: 0,
            overwrite: false,
            automaticTariff: false
          };

        console.log("saving...", body);
        return this.http.put<ResponseFormat>(`vivakey/rest/automaticcalculation?channelId=${channelId}&categoryId=${categoryId}&idEstablishment=${idEstablishment}`, body);
    }
}
