import { Component, OnInit } from '@angular/core';
import { ReservationGuestService } from './reservation-guest.service';
import { ReservationResponsableService } from '../reservation-responsable/reservation-responsable.service';
import { Router } from '@angular/router';
import { ReservationsService } from '../reservations/reservations.service';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from '@src/app/common-service';

declare var $ :any;

@Component({
    selector: 'app-reservation-guest',
    templateUrl: './reservation-guest.component.html',
    styleUrls: [
        '../reservation-responsable/reservation-responsable.component.css', 
        './reservation-guest.component.css'
    ]
})
export class ReservationGuestComponent implements OnInit 
{
    loading: boolean = false;
    
    currentReservation : any;
    reservationResponsable : any = [];
    reservationGuests : any = [];
    responsableSearch : any = [];
    
    //  FILTROS BUSCA DE RESPONSAVEIS
    docTypeOptions : any;
    nacionalityOptions: any;
    docNumber: any;
    selectDocType: any;
    selectNacionality: any;
    guestsQuantity: number;

    addResponsableDocType : any;

    // MODELS DOS FORMULARIOS DE ADD GUEST
    addUserCpfFormModel: any = {};
    addUserCnpjFormModel: any = {};
    addUserForeignFormModel: any = {};

    formStep2: any;
    selectedResponsable : any;
    readOnly: any;
    isEditing : any;
    alreadySearched : any;
    emailExists : any;
    documentExists : boolean = false;
    fastAddGuestName : string = '';
    fastAddGuestEmail : string = '';
    selectedGuest : any = {};
    minGuests : number = 1;
    invalidCpf : boolean = false;
    searchInvalidCpf : boolean = false;
    searchinvalidCpf : boolean = false;
    deletedGuest : boolean = false;
    cpfMask : any = [/\d/, /\d/,/\d/,'.',/\d/,/\d/,/\d/,'.',/\d/, /\d/,/\d/, '-',/\d/,/\d/];

    constructor(
        private reservationRespService: ReservationResponsableService, 
        private reservationsService: ReservationsService, 
        private reservationGuestService: ReservationGuestService, 
        private router: Router ,
        private translate: TranslateService,
        private commonService: CommonService,
    ) { }

    ngOnInit() 
    {
        this.reservationResponsable.push({});
        this.formStep2 = false;
        this.readOnly = false;
        this.isEditing = false;
        this.alreadySearched = false;
        this.emailExists  = false;

        this.nacionalityOptions = [  
            { 'value' : 'todos', 'label' : this.translate.instant('select') },
            { 'value' : 'br', 'label' :  this.translate.instant('brasilian') },
            { 'value' : 'foreign', 'label' : this.translate.instant('foreign') }
        ];

        this.docTypeOptions = [  
            { 'value' : 'todos', 'label' : this.translate.instant('select')  },
            { 'value' : 4, 'label' : 'Email' },
            { 'value' : 0, 'label' : 'CPF' },
            { 'value' : 2, 'label' : this.translate.instant('passport')  }
        ];

        this.currentReservation = sessionStorage.getItem('currentReservation');
        this.currentReservation = JSON.parse(this.currentReservation);


     
        this.reservationRespService.getReservationResponsables(this.currentReservation.reservationId,false,false).subscribe(data => 
        {
            switch (data.code) 
            {
                case 200:
                    this.reservationResponsable = data.object;
                    console.log(this.reservationResponsable);
                    var day;
                    if (this.reservationResponsable.length > 0) 
                    {
                        if (this.reservationResponsable[0].birthDate) 
                        { 
                            day = this.reservationResponsable[0].birthDate.split("T")[0].split("-");
                            day = day[0]+'-'+day[1]+'-'+day[2];
                            this.reservationResponsable[0].birthDate = day;
                        }
                    
                        if (this.reservationResponsable[0].foundationDate) 
                        { 
                            day = this.reservationResponsable[0].foundationDate.split("T")[0].split("-");
                            day = day[0]+'-'+day[1]+'-'+day[2];
                            this.reservationResponsable[0].foundationDate = day;
                        }
                    }
                break;
            }  
        }, error => console.log("Error"));
  
        this.getReservationGuests();
    }
  
    getReservationGuests = () =>
    {
        this.reservationGuestService.getReservationGuests(this.currentReservation.reservationId).subscribe(data => 
        {
            switch (data.code) 
            {
                case 200:
                    var stored = sessionStorage.getItem('currentReservationGuestsQuantity');
                    if (stored <=  data.object.length)
                        this.guestsQuantity = data.object.length;
                    else
                        this.guestsQuantity = parseInt(stored);
                    if (data.object.length > 1)
                        this.minGuests = data.object.length;
                    if (data.object.length == 0 && this.reservationResponsable.length > 0  && !this.deletedGuest) 
                    {
                        $('#modalMakeResponsableGuest').modal('show');
                        console.log(this.reservationGuests);
                    }
                    else 
                    {
                        for (let i = data.object.length - 1; i >= 0; i--) 
                        {
                            if (data.object[i].basicRegistrationDetail)  
                            {
                                // var birthDate = data.object[i].basicRegistrationDetail.birthDate.split("T")[0];
                                // var years =  moment().diff(moment(birthDate,"YYYY-MM-DD"), 'years');
                                // console.log(years);
                                if (data.object[i].guestType == 'CHILD')
                                    data.object[i].guestTypeStr = this.translate.instant('kid');
                                if (data.object[i].guestType == 'ADULT')
                                    data.object[i].guestTypeStr = this.translate.instant('adult');
                            }
                        }
                        this.reservationGuests = data.object;
                        console.log(this.reservationGuests);
                    }  
                break;
            }  
        }, error => console.log("Error"));
    }

    openSearchResponsable = () =>
    {
        this.selectDocType = 'todos';
        this.selectNacionality = 'todos';
        // this.docTypeOptions = [  
        //     {
        //       'value' : 'todos',
        //       'label' : 'Selecione...'
        //     },
        //     {
        //       'value' : 0,
        //       'label' : 'CPF' 
        //     },
        //     {
        //       'value' : 2,
        //       'label' : 'Passaporte' 
        //     }
        //   ];
        this.responsableSearch = [];
        this.alreadySearched = false;
        this.docNumber = '';
        $('#searchResponsableModal').modal('show');
    }

    cancelSearch = () => this.responsableSearch = [];

    searchReponsable = () =>
    {
        var selectedDocType;
        selectedDocType =  this.selectDocType;
        this.selectedResponsable = false;
        this.searchInvalidCpf == false
        if  (this.selectDocType == 'todos')
            selectedDocType = false;
        if (this.selectDocType != 'todos' || this.docNumber) 
        {
            if (this.selectDocType == 'cpf' ) 
            {
                this.searchInvalidCpf =  this.reservationRespService.validaCPF(this.docNumber)
                if (this.searchInvalidCpf == false)
                {
                    this.reservationRespService.getReservationResponsables(false,selectedDocType,this.docNumber).subscribe(data => 
                    {
                        switch (data.code) 
                        {
                            case 200:
                                this.responsableSearch = data.object;
                                this.alreadySearched = true;
                                console.log( this.responsableSearch);
                                break;
                        }  
                    }, error => console.log("Error"));
                }
            } 
        } 
        else 
        {
            this.alreadySearched = false;
            this.responsableSearch = [];
        } 
    }

    createResponsable = () =>
    {
        this.readOnly = false;
        this.isEditing = false;
        this.emailExists = false;
        this.formStep2 = false;
        this.addUserCpfFormModel = {};
        this.addUserCnpjFormModel = {};

        if (this.selectDocType == 0) 
        {
            console.log('eh cpf');
            this.addUserCpfFormModel.documentNumber = this.docNumber;
            this.addResponsableDocType = 'cpf';
        }

        if (this.selectDocType == 2)
        {
            this.addUserForeignFormModel.documentNumber = this.docNumber;
            this.addResponsableDocType = 'estrangeiro';
        }
        
        $('#searchResponsableModal').modal('hide');
        setTimeout(() => $('#createResponsableModal').modal('show'), 500);
    }

    AddSelectDocType = docType =>
    {
        this.addResponsableDocType = docType;
        this.formStep2 = false;
    }

    goToFormStep2 = () => this.formStep2 = true;

    backToStep1 = () => this.formStep2 = false;

  addNewResponsable(){
    var formData = {};
    if (this.addResponsableDocType == "cpf") {
       formData = this.addUserCpfFormModel;
    }
    if (this.addResponsableDocType == "cnpj") {
       formData = this.addUserCnpjFormModel;
    }
    if (this.addResponsableDocType == "estrangeiro") {
       formData = this.addUserForeignFormModel;
    }
  }

  setThisResponsable = responsableId => this.selectedResponsable = responsableId;

  selectResponsable(selectedResponsable){
       this.reservationGuestService.assignGuestToReservation(selectedResponsable, this.currentReservation.reservationId).subscribe(data => {
          console.log(data);
        switch (data.code) {
          case 200:
            this.getReservationGuests();
            $('#searchResponsableModal').modal('hide'); 
          break;
          }  
          },  
         (error:any) =>{
        console.log("Error");
        });
  }

    showResponsableDetails = index =>
    {
        this.readOnly = true;
        var day = this.reservationGuests[index].basicRegistrationDetail.birthDate.split("T")[0].split("-");
        day = day[0]+'-'+day[1]+'-'+day[2];
        this.reservationGuests[index].basicRegistrationDetail.birthDate = day;
        
        this.reservationGuests[index].basicRegistrationDetail.name = this.reservationGuests[index].name
        
        if (this.reservationGuests[index].basicRegistrationDetail.gender == 'MALE')
            this.reservationGuests[index].basicRegistrationDetail.gender = 0;
        
        if (this.reservationGuests[index].basicRegistrationDetail.gender == 'FEMALE')
            this.reservationGuests[index].basicRegistrationDetail.gender = 1;

        if (this.reservationGuests[index].basicRegistrationDetail.documentType == 'CPF') 
        {
            this.addResponsableDocType = 'cpf';
            this.addUserCpfFormModel = this.reservationGuests[index].basicRegistrationDetail;
        }

        if (this.reservationGuests[index].basicRegistrationDetail.documentType == 'FOREIGN')
        {
            this.addResponsableDocType = 'estrangeiro';
            this.addUserForeignFormModel = this.reservationGuests[index].basicRegistrationDetail;
        }

        $('#createResponsableModal').modal('show');
        $(".modal-backdrop.in").css('background-color', 'transparent');
    }

    editResponsableDetails = index =>
    {
        this.readOnly = false;
        this.isEditing = this.reservationGuests[index].basicRegistrationDetail.basicRegistrationDetailId;
        
        var day = this.reservationGuests[index].basicRegistrationDetail.birthDate.split("T")[0].split("-");
        day = day[0]+'-'+day[1]+'-'+day[2];
        this.reservationGuests[index].basicRegistrationDetail.birthDate = day;
        
        this.reservationGuests[index].basicRegistrationDetail.name = this.reservationGuests[index].name
        
        if (this.reservationGuests[index].basicRegistrationDetail.gender == 'MALE')
            this.reservationGuests[index].basicRegistrationDetail.gender = 0;
        if (this.reservationGuests[index].basicRegistrationDetail.gender == 'FEMALE')
            this.reservationGuests[index].basicRegistrationDetail.gender = 1;

        if (this.reservationGuests[index].basicRegistrationDetail.documentType == 'CPF') 
        {
            this.addResponsableDocType = 'cpf';
            this.addUserCpfFormModel = this.reservationGuests[index].basicRegistrationDetail;
        }

        if (this.reservationGuests[index].basicRegistrationDetail.documentType == 'FOREIGN')
        {
            this.addResponsableDocType = 'estrangeiro';
            this.addUserForeignFormModel = this.reservationGuests[index].basicRegistrationDetail;
        }
        console.log(this.reservationGuests[index].basicRegistrationDetail);
        $('#createResponsableModal').modal('show');
    }

    selectNacionalidade = nacionalidade =>
    {
        if (nacionalidade == 'br') 
        {
            this.docTypeOptions = [{ 'value' : 0, 'label' : 'CPF' }];
            this.selectDocType = 0;
        }
        if (nacionalidade == 'foreign')
        {
            this.docTypeOptions = [{'value' : 2,'label' : this.translate.instant('passport') }];
            this.selectDocType = 2;
        }
        if (nacionalidade == 'todos')
        {
            this.docTypeOptions = [  
                { 'value' : 'todos', 'label' : this.translate.instant('select') },
                { 'value' : 0, 'label' : 'CPF'  },
                { 'value' : 2, 'label' : this.translate.instant('passport')}
            ];
            this.selectDocType = 'todos';
        }
    } 

    clearDocNumber = () => this.docNumber = '';

    changeGuestQtd = () => sessionStorage.setItem('currentReservationGuestsQuantity',this.guestsQuantity.toString());

    makeResponsableGuest = () =>
    {
        this.reservationGuestService.makeResponsableGuest(this.currentReservation.reservationId, this.reservationResponsable[0].id).subscribe(data => 
        {
            switch (data.code) 
            {
                case 200:
                    this.getReservationGuests();
                    $('#modalMakeResponsableGuest').modal('hide');
                    break;
            }  
        }, error => console.log("Error"));
    }

    fastAdd = () =>
    {
        $('#searchResponsableModal').modal('hide');
        setTimeout(() => $('#modalfastAdd').modal('show'), 500);
    }

    selectGuestToRemove = guest =>
    {
        this.selectedGuest = guest;
        $('#modalConfirmDelete').modal('show');
    }

    cepAutocomplete = cep =>
    {
        if (cep && cep.length == 8 && this.addResponsableDocType == 'cpf') {
            this.commonService.checkZipCode(cep).subscribe(ret => {
				console.log(ret);
				this.loading = false;
				if (ret.erro == true) {
					console.log('CEP Inválido!');
				} else {
					this.addUserCpfFormModel.city = ret.localidade;
                    this.addUserCpfFormModel.state = ret.uf;
                    this.addUserCpfFormModel.street = ret.logradouro;
                    this.addUserCpfFormModel.neighborhood = ret.bairro;
				}
			}, err => {
				this.loading = false;
				this.translate.instant('error_msg.cep_not_found');
			});
        }
    }

    removeGuest = () =>
    {
        this.reservationGuestService.removeGuest(this.selectedGuest.basicRegistrationId, this.currentReservation.reservationId).subscribe(data => 
        {
            switch (data.code) 
            {
                case 200:
                    this.deletedGuest = true;
                    this.getReservationGuests();
                    $('#modalConfirmDelete').modal('hide');
                    setTimeout(() => $('#modalDeleteSucesso').modal('show'), 500);
                    break;
            }  
        }, error => console.log("Error"));
    }

    fastAddGuest = () =>
    {
        this.reservationGuestService.createGuest(this.fastAddGuestName, this.fastAddGuestEmail).subscribe(data => 
        {
            switch (data.code) 
            {
                case 200:
                    this.reservationGuestService.assignGuestToReservation(data.object.basicRegistrationId, this.currentReservation.reservationId).subscribe(data => 
                    {
                        switch (data.code) 
                        {
                            case 200:
                            this.getReservationGuests();
                            $('#modalfastAdd').modal('hide');
                            setTimeout(() => $('#modalCadastroSucesso').modal('show'), 500);
                            break;
                        }  
                    }, error => console.log("Error"));
                    break;
            }  
        }, error => console.log("Error"));
    }

    goToStep1 = () => this.router.navigate(['reservations/responsable']);

    reload = () => location.reload(true);

    cancelReservation()
    {
        this.reservationsService.cancelReservation(this.currentReservation.reservationId, 1, '').subscribe(data => 
        {
            switch (data.code) {
                case 200:
                $('#modalConfirmReservationCancel').modal('hide');
                this.router.navigate(['reservations']);  
                break;
            }
        }, error => console.log("Error"));
    }

    goToStep3 = () => this.router.navigate(['reservations/payment']);

    cancelAddUser = () =>
    {
        console.log('caiu certo');
        this.addUserCpfFormModel = {};
        this.addUserCnpjFormModel = {};
        this.addUserForeignFormModel = {};
        $('#createResponsableModal').modal('hide')
    }

    callValidaCPF = cpf => this.invalidCpf =  this.reservationRespService.validaCPF(cpf);
}

