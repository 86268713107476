import { Component, OnInit } from "@angular/core";
import { EstablishmentPolicyService } from "./establishment-policy.service";
import { NotifierService } from "angular-notifier";
import { TranslateService } from '@ngx-translate/core';
import { AppStateService } from "@src/app/layouts/app-layout.service";

@Component({
  selector: "app-establishment-policy",
  templateUrl: "./establishment-policy.component.html",
  styleUrls: [
  "../hotel-settings/hotel-settings-page.component.css"
  ]
})
export class EstablishmentPolicyComponent implements OnInit {
  
  loading: boolean = false;

  initialContent: any;
  content: any;
  establishment: any;

  ckeditor_config = {
    language: this.translate.currentLang == 'pt_br' ? 'pt-br ' : 'en-us',
    removePlugins: 'elementspath,save',
    toolbar: [
        {name: 'styles', items: ['Font', 'FontSize']},
        {name: 'basicstyles', items: ['Bold', 'Italic', 'Underline', '-']}] 
    };

  constructor(
    private establishmentPolicyService: EstablishmentPolicyService,
    private translate: TranslateService,
    private appStateService: AppStateService,
    private notifier: NotifierService
  ) {}

  ngOnInit() {
    this.establishment = this.appStateService.currentEstablishment;


    this.loading = true;
    setTimeout(() => {
      this.loadData();
    }, 200);

    
  }

  loadData(){
    this.establishmentPolicyService
    .getPolitics(this.establishment.establishmentId)
    .subscribe(
      data => {
        console.log("data", data);
        this.loading = false;
        if (data.code == 200) {
          this.initialContent = data.object.text;
          this.content = data.object.text;
        } else {
          this.notifier.notify("error", this.translate.instant('error_msg.error_getdata' ));
        }
      },
      error => {
        this.loading = false;
        this.notifier.notify("error", this.translate.instant('error_msg.error_getdata' ));
      }
    );
  }


  hasSomeChange() {
    return this.initialContent != this.content
  }

  cancel() {
    this.content = this.initialContent
  }

  savePolicy() {
    this.establishmentPolicyService
      .savePolitics(this.content, this.establishment.establishmentId)
      .subscribe(
        data => {
          if (data.code === 200) {
            this.initialContent = this.content;
            this.notifier.notify(
              "success",
              this.translate.instant('establishment-policy.success_saved')
            );
          } else {
            this.notifier.notify(
              "error",
              this.translate.instant('establishment-policy.error_save')
            );
          }
        },
        (error: any) => {
          this.notifier.notify("error", this.translate.instant('establishment-policy.error_save'));
        }
      );
  }
}
