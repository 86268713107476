import { Component, OnInit, Inject } from '@angular/core';
import { CreateHotelService } from '@src/app/pages/create-hotel/create-hotel.service';
import { NotifierService } from 'angular-notifier';
import { validateCNPJ } from '../../../utils/validators'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { removeChars } from '@src/app/utils/helpers';
import { ReservationsService } from '@src/app/pages/reservations/reservations.service';
import { UnitProblemPageService } from '@src/app/pages/unit-problem-page/unit-problem-page.service';
import { ReservationsListService } from '@src/app/pages/reservation-list/reservation-list.service';
import { TranslateService } from '@ngx-translate/core';
import { AppStateService } from '@src/app/layouts/app-layout.service';




@Component({
  selector: 'app-resend-email-modal',
  templateUrl: './resend-email-modal.component.html',
  styleUrls: ['./resend-email-modal.component.scss']
})
export class ResendEmailModalComponent implements OnInit {

  loading: boolean = false;
  establishment: any;



  reservation: any;
  email: string = '';
  emailOptional: string = '';


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private appStateService: AppStateService,
    private service: ReservationsListService,
    private notifier: NotifierService,
    public dialogRef: MatDialogRef<ResendEmailModalComponent>, 
    private translate: TranslateService,) {

      this.establishment = this.appStateService.currentEstablishment;

     }

  ngOnInit() {
    console.log('this.data', this.data);

    this.reservation = this.data.reservation;
    this.email = this.reservation.responsible.email;
    this.emailOptional = this.reservation.responsible.emailOptional;
  }

  saveOptionalEmail() {

    if(!this.emailOptional) {
      this.emailOptional = '';
    }

    this.service.saveOptionalEmail(this.reservation.reservationId, this.establishment.establishmentId, this.emailOptional).subscribe((data) => {
      this.loading = false
      if (data.object) {
        this.sendEmail();
      }
    }, error => {
      this.loading = false
      this.notifier.notify('error', this.translate.instant('error_msg.error_undefined'))
    });
  }


  sendEmail() {

    if (this.email && this.email.length > 0) {
      this.loading = true;
      this.service.sendEmail(this.reservation.reservationId, this.establishment.establishmentId).subscribe((data) => {
        this.loading = false
        this.notifier.notify('success', this.translate.instant('resend_email_modal.email_sended' ))
        this.dialogRef.close(true);

      }, error => {
        this.loading = false
        this.notifier.notify('error', this.translate.instant('error_msg.error_undefined'))
      })

    } else {
      this.notifier.notify('error', this.translate.instant('resend_email_modal.error_input_email'))
    }

  }

}
