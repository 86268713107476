import { Injectable } from '@angular/core';
import { Http, Headers, Response, RequestOptions } from '@angular/http';
import { TokenService } from '@app/token.service';

@Injectable()
export class SelfRegistrateService {

  constructor(private http: Http, private tokenService: TokenService) { }

  authToken = token =>
  {
  	console.log(token);
	let myHeaders = new Headers();
	myHeaders.append('token', token);
	let options = new RequestOptions({ headers: myHeaders});
	return this.http.get(`${this.tokenService.getBaseUrl()}/vivakey/rest/employee/getEmployeeOutsideSysmtem`, options)
		.map((response: Response) => {
			var result = response.json() ;
		return result;
		})
  }

  sendUser(user,token){
	let myHeaders = new Headers();
	myHeaders.append('token', token);
	let options = new RequestOptions({ headers: myHeaders});
	var body = {
		  "email": user.email,
		  "password": user.password,
		  "name": user.name,
		  "phone": user.phone,
		  "street": user.street,
		  "neighborhood": user.neighborhood,
		  "number": user.number,
		  "city": user.city,
		  "state": user.state,
		  "complement": user.complement,
		  "zipCode": user.zipCode
		}
		console.log(body);
	return this.http.put(`${this.tokenService.getBaseUrl()}/vivakey/rest/employee/autoRegister`, body, options)
		.map((response: Response) => {
			var result = response.json() ;
		return result;
		})
  }

  activateUser(token){
  	console.log(token);
  	let myHeaders = new Headers();
	myHeaders.append('token', token);
	let options = new RequestOptions({ headers: myHeaders});
	return this.http.put(`${this.tokenService.getBaseUrl()}/vivakey/rest/employee/activate`, null, options)
		.map((response: Response) => {
			var result = response.json() ;
		return result;
		})
  }
}
