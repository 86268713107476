import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { ImageCompressService, ResizeOptions } from 'ng2-image-compress';


@Component({
	selector: 'app-url-image-picker',
	templateUrl: './url-image-picker.component.html',
	styleUrls: ['./url-image-picker.component.scss']
})
export class URLImagePickerComponent implements OnInit {

	@Input('mediaContent') mediaContent: any = null;
	@Input('image') image: any = null;
	@Output() onChange: EventEmitter<any> = new EventEmitter();

	//@Input('maxHeight') maxHeight: string = '80px';
	@Input('maxWidth') maxWidth: string = '100%';
	@Input('allowUpload') allowUpload: boolean = true;

	dialogRef: MatDialogRef<any>;

	constructor(private imgCompressService: ImageCompressService, public dialog: MatDialog,) { }

	ngOnInit() {
	}

	preview(template) {
		this.dialogRef = this.dialog.open(template);
	}

	getStyle() {
		let noBgColor = !this.image || this.image === '' ? {} : { 'background-color': 'transparent' }
		return {
			'background-image': `url('${this.image}')`,
			...noBgColor
		}
	}

	changeImageFile(event) {
		if (event.target.files && event.target.files[0]) {


			var reader = new FileReader();
			this.image = null;
			let file = event.target.files[0]
			reader.readAsDataURL(event.target.files[0]); // read file as data url

			reader.onload = (ev) => { // called once readAsDataURL is completed
				this.image = reader.result;

				var option: ResizeOptions = new ResizeOptions();
				option.Resize_Quality = 60;
				
				ImageCompressService.filesToCompressedImageSourceEx(event.target.files, option).then(observableImages => {
					observableImages.subscribe((img) => {
						this.onChange.emit({ image: img.compressedImage.imageDataUrl, filename: file.name, mediaContent: this.mediaContent });
					}, (error) => {
						console.log("Error while image compress");
					}, () => {
						console.log("compress Finish");
					});

				});

			}






		}
	}
}
