import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({ name: 'genderTransPipe' })
export class GenderTransPipe implements PipeTransform {

    constructor(public translate: TranslateService) {}
    
    transform(value: number, args: any[] = []) {
        switch (value) {
            case 0:
                return this.translate.instant('genre.male')
            case 1:
                return this.translate.instant('genre.female')
            default:
                return value
        }
    }
}