import { Component, OnInit, Inject } from '@angular/core';
import { CreateHotelService } from '@src/app/pages/create-hotel/create-hotel.service';
import { NotifierService } from 'angular-notifier';
import { validateCNPJ } from '../../../utils/validators'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { TranslateService } from '@ngx-translate/core';
import { ConfigOmieService } from './config-omie.service';
const states = require("../../../utils/datas/states.json");
import Swal from 'sweetalert2';
import { OmieConfig } from './omie-config-model';
import { AppStateService } from '@src/app/layouts/app-layout.service';

@Component({
  selector: 'app-config-omie-data-modal',
  templateUrl: './config-omie-data-modal.component.html',
  styleUrls: ['./config-omie-data-modal.component.scss']
})
export class ConfigureOmieDataModalComponent implements OnInit {

  loading: boolean = false;
  selectedEstablishment: any;

  citiesList: any[] = [];
  statesList: any[] = [];
  categoriesList: any[] = [];
  servicesList: any[] = [];
  tribList: any[] = [];
  
  oldBankList: any[] = [];
  bankList: any[] = [];

  validated: boolean = false;
  banksCheked: boolean = false;
  step: number = 1;

  setCity: boolean = false;
  setCategory: boolean = false;
  setServMunic: boolean = false;
  setTribut: boolean = false;
  setAPI: boolean = false;

  ccodServMunReadonly: boolean = true;

  sendMode: string = 'cEnvLink';
  uf: string = '';

  percentualConfig = { prefix: '', suffix:'%', thousands: '.', decimal: ',', align : 'left', allowNegative : 'false' };

  public model : OmieConfig = {
    appKey: '',
    appSecret: '',
    omieConfigId: 1,
    hasInvoiceOmie: true,
    ccodServMun: "01015",
    cenvLink: "S",
    cenvRecibo: "N",
    cretemISS: {},
    ctribServ: null,
    ccodCateg: "1.01.02",
    ccidPrestServ: "SAO PAULO (SP)",
    ccodServLC116: "9.01",
    cnaoGerarFinanceiro: "N",
    naliqCOFINS: 0,
    naliqCSLL: 0,
    naliqIRRF: 0,
    naliqISS: 0,
    naliqPIS: 0,
    ntotDeducao: 0,
    hasHotelInvoiceOmieAutoSend: false
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private appStateService: AppStateService,
    private notifier: NotifierService,
    public service: ConfigOmieService,
    public dialogRef: MatDialogRef<ConfigureOmieDataModalComponent>,
    private translate: TranslateService,) {
    this.statesList = states;
  }

  ngOnInit() {
    this.selectedEstablishment = this.appStateService.currentEstablishment;
    
    Object.assign(this.model, this.data);

    this.model.cretemISS = this.model.cretemISS == "S" ? true : false;

    this.setAPI = (this.model.appKey == null || this.model.appKey === '' ? true : false);

    var state_splitted = (this.model.ccidPrestServ || '').split(' (', 2);
    if (state_splitted && state_splitted.length >= 2) {
      this.uf = state_splitted[1].replace(')', '');
    }

    this.sendMode = this.model.cenvLink == 'S' ? 'cEnvLink' : 'cEnvRecibo';

    if (this.setAPI == false) {
      this.getTributes();
    }
  }

  goBanks(){
    this.step = 2;
    this.checkBanks();
  }

  checkBanks(){
    this.getBanks().then(ret=>{
      this.bankList.forEach(element => {
        if(element.hasNCodCcManual == false || element.hasNCodCcManual == null){
          element.ncodCc = '';
        }
      });
      this.getAccounts();
    })
  }

  validateBanks(){ 
    if(this.bankList.find(i=>i.ncodCc == '' || i.ncodCc == null) == null){
      this.banksCheked = true;
    } else {
      this.banksCheked = false;
    }
  }

  confirmKey() {
    this.loading = true;
    this.service.getTributes(this.model.appKey, this.model.appSecret, 1).subscribe(ret => {
      this.loading = false;
      if (ret.code == 200 && ret.object != null) {
        this.setAPI = false;
        this.getTributes();
        this.servicesLoaded = false;
      }
      else {
        this.notifier.notify('error', this.translate.instant('config_omie.erro_set_key'));
      }
    }, err => { this.loading = false; })
  }

  onSelectState(event) {
    this.citiesList = [];
    this.cityCurrPage = 1;
    this.getCities();
  }


  setCities() {
    this.setCity = true;
    this.getCities();
  }
  onSelectCity(event) {
    this.setCity = false;
  }


  setCategories() {
    this.setCategory = true;
    this.getCategories();
    this.categoriesCurrPage = 1;
  }
  onSelectCateg(event) {
    this.setCategory = false;
  }


  setServices() {
    this.setServMunic = true;
    this.getServices();
  }
  onSelectServMunic(event) {
    this.setServMunic = false;
    var serv = this.servicesList.find(i => i.nIdCodServMun == event);
    if (serv) {
      this.model.ccodServLC116 = serv.cCodServLC116;
    }
  }


  setTributes() {
    this.setTribut = true;
    this.getTributes();
  }
  onSelectTributes(event) {
    this.setTribut = false;
  }

  cityCurrPage = 1;
  getCities(scroll = false) {
    if (scroll == true) {
      this.cityCurrPage++;
    }
    else {
      this.citiesList = [];
    }

    //this.loading = true;
    this.service.getCities(this.uf, this.model.appKey, this.model.appSecret, this.cityCurrPage).subscribe(ret => {
      this.loading = false;
      if (ret.code == 200 && ret.object.lista_cidades) {
        if (scroll == true) {
          this.citiesList = this.citiesList.concat(ret.object.lista_cidades);
        }
        else {
          this.citiesList = ret.object.lista_cidades;
        }
      }
    }, err => {
      this.loading = false;
    })
  }

  categoriesCurrPage = 1;
  getCategories(scroll = false) {
    if (scroll == true) {
      this.categoriesCurrPage++;
    }
    else {
      this.categoriesList = [];
    }
    this.service.getCategories(this.model.appKey, this.model.appSecret, this.categoriesCurrPage).subscribe(ret => {

      if (ret.code == 200 && ret.object.categoria_cadastro) {
        if (scroll == true) {
          this.categoriesList = this.categoriesList.concat(ret.object.categoria_cadastro);
        }
        else {
          this.categoriesList = ret.object.categoria_cadastro;
        }
      }
    }, err => { })
  }


  servCurrPage = 1;
  servicesLoaded = false;

  getServices(scroll = false) {
    if(this.servicesLoaded == true) {
      return;
    }
    
    if (scroll == true) {
      this.servCurrPage++;
    }
    else {
      this.servicesList = [];
    }
    this.service.getServices(this.model.appKey, this.model.appSecret, this.servCurrPage).subscribe(ret => {
      if (ret.code == 200 && ret.object && ret.object.cadastros) {
        if (scroll == true) {
          this.servicesList = this.servicesList.concat(ret.object.cadastros);
          if(this.servCurrPage >= ret.object.total_de_paginas){
            this.servicesLoaded = true;
            console.log('services as Loaded', this.servicesLoaded);
          }
        }
        else {
          this.servicesList = ret.object.cadastros;
        }
      }

      if(ret.code == 200 && ret.object == null && this.servCurrPage == 1){
        this.ccodServMunReadonly = false;
        this.setServMunic = false;
      }
    }, err => { })
  }



  getTributes() {
    this.tribList = [];
    this.loading = true;
    this.service.getTributes(this.model.appKey, this.model.appSecret, 1).subscribe(ret => {
      this.loading = false;
      if (ret.code == 200 && ret.object) {
        this.tribList = ret.object.cadastros;
      }
    }, err => {
      this.loading = false;
    })
  }


  onSelectOmieCC(bank, event){
    bank.hasNCodCcManual = true;
    this.service.saveBank(bank, this.selectedEstablishment.establishmentId).subscribe(ret => {
      this.validateBanks();
    }, err => { 
      bank.ncodCc = null;
      this.validateBanks();
      if(err.error && err.error.message){
        this.notifier.notify('error', err.error.message);
      }
    })
  }

  saveBank(bank){
    this.service.saveBank(bank, this.selectedEstablishment.establishmentId).subscribe(ret => {
      this.validateBanks();
    }, err => { 
      bank.ncodCc = null;
      this.validateBanks();
      if(err.error && err.error.message){
        this.notifier.notify('error', err.error.message);
      }
    })
  }

  getAccountsCount(): Promise<any> {

    return new Promise((resolve, reject) => {
      this.loading = true;
      this.service.getAccounts(this.model.appKey, this.model.appSecret, this.accountListCurrPage, 100).subscribe(ret => {
        this.loading = false;
        if (ret.code == 200 && ret.object) {      
          resolve(ret);
        }
        else{
          reject();
        }
      }, err => {
        this.loading = false;
        reject();
      })
    });
  }

  accountList: any[] = [];
  accountListCurrPage:number = 1;

  getAccounts() {
    this.getAccountsCount().then((ret) => {    
      if(ret.object.conta_corrente_lista){
        this.accountList = this.accountList.concat(ret.object.conta_corrente_lista);

        this.accountList.forEach(account => {
          if(account.conta_corrente && account.conta_corrente.indexOf('-') > 0)
          {
            var dt = account.conta_corrente.split('-');
            if(dt.length >= 2){
              account.conta_corrente = dt[0];
              account.conta_corrente_digito = dt[1];
            }
          }
        });

       

        if(this.accountList.length >= ret.object.total_de_registros)
        {
          console.log('this.accountList', this.accountList);

          this.bankList.forEach(b => {
            if(b.ncodCc == null || b.ncodCc == ''){
              var omieAccount = this.accountList.find(i=>i.conta_corrente == b.countNumber && i.conta_corrente_digito == b.digit && i.codigo_banco == b.code && i.codigo_agencia == b.agency);
              if(omieAccount != null){
                 b.ncodCc = omieAccount.nCodCC;
                 b.hasNCodCcManual = false;
              }
            }
          });

          this.saveBanks();
          this.validateBanks();
          if(this.banksCheked == false) {
            Swal.fire(this.translate.instant('attention'), '<span style="font-size: 16px;">' 
            + this.translate.instant('config_omie.null_id_info_msg') 
            + '</span>'
            , 'error');
          }
        }   
      }
      
      if(this.accountListCurrPage < ret.object.total_de_paginas){
        this.accountListCurrPage ++;
        this.getAccounts();
      }
    })
  }

  getBanks(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.loading = true;
      this.service.getBanks(this.selectedEstablishment.establishmentId).subscribe(ret => {
        this.loading = false;
        switch (ret.code) {
          case 200:
            {
              this.bankList = ret.object;
              this.oldBankList = JSON.parse(JSON.stringify(this.bankList));
              resolve(ret.object);
              break;
            }
          default:
            reject();
        }
      }, err => {
        this.loading = false;
        reject();
      })
    });
  }

  saveBanks() {
    var banksSaved = false;
    var totalSaved = 0;
    this.bankList.forEach(element => {

      var oldBank = this.oldBankList.find(i=>i.bankId == element.bankId);
      if(oldBank.ncodCc != element.ncodCc) {
        this.service.saveBank(element, this.selectedEstablishment.establishmentId).subscribe(ret => {
          totalSaved++;
          if(totalSaved>= this.bankList.length){
            banksSaved = true;
          }
        }, err => {
          element.ncodCc = null;
         })
      }

      
    });
  }

  confirm() {

    this.model.cenvLink = this.sendMode == 'cEnvLink' ? 'S' : 'N';
    this.model.cenvRecibo = this.sendMode == 'cEnvRecibo' ? 'S' : 'N';
    this.model.cretemISS = this.model.cretemISS == true ? 'S' : 'N';

    this.validated = true;
    this.dialogRef.close();
  }

  close(){
    this.dialogRef.close();
  }


}
