import { Injectable } from '@angular/core';
import { HttpClient  } from '@angular/common/http';
import {ResponseFormat} from '@app/types/response-format';

@Injectable()
export class PropertyOwnerService 
{
    constructor(private http : HttpClient) { }

    getData = (establishmentId) =>
    {
        return this.http.get<ResponseFormat>('vivakey/rest/propertyowner?establishmentId=' + establishmentId ); 
    }

    saveData = (data) =>
    {
        let body = {
            establishmentId: data.establishmentId,
            propertyOwnerId: data.propertyOwnerId,
            name: data.name,
            document: data.document.replace(/\D+/g, '')
        };

        if(body.propertyOwnerId)
            return this.http.put<ResponseFormat>('vivakey/rest/propertyowner', body); 	
        else
            return this.http.post<ResponseFormat>('vivakey/rest/propertyowner', body); 	
    }

    deleteData = propertyOwnerId => this.http.delete<ResponseFormat>('vivakey/rest/propertyowner/' + propertyOwnerId);

}
