import { Component, OnInit, TemplateRef } from '@angular/core';
import { UnitProblemPageService } from './unit-problem-page.service';
import { NotifierService } from 'angular-notifier';

import moment from 'moment-timezone'; 
import { MatDialogRef, MatDialog } from '@angular/material';
import { ReportProblemModalComponent } from '@src/app/components/views/report-problem-modal/report-problem-modal.component';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { AppStateService } from '@src/app/layouts/app-layout.service';

declare var $ :any;

@Component({
  selector: 'app-unit-problem-page',
  templateUrl: './unit-problem-page.component.html',
  styleUrls: ['./unit-problem-page.component.scss']
})
export class UnitProblemPageComponent implements OnInit {

  constructor(
    private appStateService: AppStateService,
  	private service: UnitProblemPageService,
    private notifier: NotifierService,
    public dialog: MatDialog,
    private router: Router,
    public translate: TranslateService,) { 
      this.selectStartDate = moment(moment().subtract("1", "months").format("YYYY-MM-DD"));
      this.selectFinishDate = moment(moment().format("YYYY-MM-DD"));
    }

  loading: any = true;

  establishment: any;
  selectedUnit: any;
  units: any = [];
  selectedStatus: any = null;
  statuses = [
  	{name: this.translate.instant('all' ), value: null},
  	{name: this.translate.instant('solved' ), value: true},
  	{name: this.translate.instant('unsolved' ), value: false},
  ];
  selectStartDate: any;
  selectFinishDate: any;
  data: any;

  currentProblem : any;
  currentUnitToReport : any;
  problemName : any;
  problemDesc : any;
  
  reportProblemModalComponentDialogRef: MatDialogRef<ReportProblemModalComponent>;
  currMask = { prefix: '', thousands: '.', decimal: ',', align : 'left', allowNegative : 'false' };
  hasMenuOwner = false;

  ngOnInit() {

    if(this.router.url.indexOf('OwnerProblems') > -1){
      this.hasMenuOwner = true;
    }

  	this.establishment = this.appStateService.currentEstablishment;
  	this.service.getUnits(this.establishment.establishmentId, this.hasMenuOwner.toString())
  	.subscribe(data => 
        {
          // inicia o filtro como todos e depois as unidades
        	//this.units = [{
        	//	name: this.translate.instant('all'),
        	//	unitId: 0
        	//}];
          this.loading = false

        	for (let i = data.object.length - 1; i >= 0; i--)
        		this.units.push(data.object[i])

          this.selectedUnit = 0;
          this.query();
        }, error => {
          this.loading = false

        });

    
   
  }

  query(fromBtn = false) {

    var params = {hasMenuOwner: this.hasMenuOwner};

    //this.selectedUnit, this.selectedStatus, 

    if( this.selectedUnit !== null && this.selectedUnit != 0 ) {
      params["unitId"]  = this.selectedUnit;
    }

    if( this.selectedStatus !== null ) {
      params["solved"]  = this.selectedStatus;
    }
    


    if(this.selectStartDate && this.selectFinishDate && moment(this.selectStartDate).isValid() && moment(this.selectFinishDate).isValid()) {
      this.loading = true
      this.service.getAllUnitProblems(this.establishment.establishmentId, this.selectStartDate.format('YYYY-MM-DD'), this.selectFinishDate.format('YYYY-MM-DD'), params)
      .subscribe((data) => {
        if(data.object) {
          this.data = data.object
          if(this.filtersDialog != null && fromBtn == true){
            this.filtersDialog.close();
          }
        }
      this.loading = false
        console.log('success', data)
      }, error => {
      this.loading = false

      })
    }
  }

  openProblemModal(problem, templateRef: TemplateRef<any>, view = '') {
    this.currentProblem = problem;
    this.currentProblem.date = moment(this.currentProblem.creationDate);
    this.currentProblem.view = view;

    if(this.currentProblem.solvedDate) {
      this.currentProblem.solvedDate = moment(this.currentProblem.solvedDate);
    }
    
      //$("#viewProblemModal").modal('show');
    this.dialog.open(templateRef);
  }

  filtersDialog: MatDialogRef<any>;
  openFilters(filtersTemplate) {
    this.filtersDialog = this.dialog.open(filtersTemplate);
  }

  openReportProblemModal() {
    // $("#reportProblemModal").modal('show');

    this.reportProblemModalComponentDialogRef = this.dialog.open(ReportProblemModalComponent, {
      panelClass: 'mat-dialog-fix',
      disableClose: false,
      data : { units: this.units }
    });

    this.reportProblemModalComponentDialogRef.componentInstance.units = this.units;

    this.reportProblemModalComponentDialogRef.afterClosed().subscribe(result => {
      if (result === true) {
          this.query();
      }
      this.reportProblemModalComponentDialogRef = null;
    });

  }


  closeModal() {
    this.dialog.closeAll();
  }

  solveProblem() {
    this.currentProblem.solved = true;
    this.loading = true
    this.service.saveUnitProblem(false, {
      unitProblemId: this.currentProblem.unitProblemId,
      solved: this.currentProblem.solved,
      laborValue: this.currentProblem.laborValue,
      maintenanceValue: this.currentProblem.maintenanceValue,
      textResolution: this.currentProblem.textResolution,
    }).subscribe(data => {
      this.notifier.notify('success', this.translate.instant('report_problem.success_saved'));
      this.loading = false;
      this.closeModal();
      this.query();
    }, error => {
      this.loading = false
      this.notifier.notify('error', this.translate.instant('error_msg.error_undefined'))
    })

  }

  saveUnitProblem() {

    this.loading = true

    this.service.saveUnitProblem(true, {

      unitProblemId: this.currentProblem.unitProblemId,
      solved: this.currentProblem.solved,
      creationDate: this.currentProblem.creationDate,
      description: this.currentProblem.description,
      laborValue: this.currentProblem.laborValue,
      maintenanceValue: this.currentProblem.maintenanceValue,
      textResolution: this.currentProblem.textResolution,
      title: this.currentProblem.title,

    }).subscribe(data => {
      this.notifier.notify('success', this.translate.instant('report_problem.success_saved'));
      this.loading = false;
      this.closeModal();
      this.query();
    }, error => {
      this.loading = false
      this.notifier.notify('error', this.translate.instant('error_msg.error_undefined'))
    })


  }

  reportProblem() {
  if(this.currentUnitToReport && this.problemName && this.problemDesc && this.problemName.length > 0 && this.problemDesc.length > 0) {
    this.loading = true;
    let body = {
      "title": this.problemName,
      "description": this.problemDesc,
      "unitId": this.currentUnitToReport,
    }
    this.service.reportProblem(body).subscribe((data) => {
      this.loading = false
      this.notifier.notify('success', this.translate.instant('report_problem.reported' ))
      $("#reportProblemModal").modal('hide');
      this.problemName = ''
      this.problemDesc = ''
    }, error => {
      this.loading = false
      this.notifier.notify('error', this.translate.instant('error_msg.error_undefined' ))
    })
  }
}
}
